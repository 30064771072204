import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import Stepper from "@mui/material/Stepper";
import { DefaultApi as AiBackendApi } from "../../app/api/generated/ai-service";
import {
  DefaultApi as PAApi,
  UpdatePersonGroupDTODataOfMinorsEnum,
  UpdatePurposeDTO,
  CategoryTypeTupleDTO
} from "../../app/api/generated/process-service";
import { apiEndpoints } from "../../app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../app/api/axios/loggedInAxiosProvider";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "../../app/contexts/overview-context";
import { COLLECTIONS } from "../../app/collections";
import { useUserAndTenantData } from "../../app/handlers/userAndTenant/user-tenant-context";
import { dataAssetClient, useDataTypeTreeManager } from "../../app/api/dataAssetApi";
import { useResources } from "../../app/contexts/resource-context";
import {
  AIStepProps,
  AwesomePropIcon,
  CheckCircleOutlinedPropIcon,
  CircleOutlinedPropIcon,
  CircularProcessPropIcon,
  FeedbackInput,
  InfoOutlinedPropIcon
} from "./AIIntegrationStep";
import { parse as bestEffortParse } from "best-effort-json-parser";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AutoFixNormalOutlined, WavingHandOutlined } from "@mui/icons-material";
import { AIIntegrationNextStepProps } from "./AIIntegrationNextStep";
import { AIStepAccordion } from "./AIStepAccordion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AIStepButton } from "./AIStepButton";
import { isAxiosErrorWithCode } from "../../app/api/axios/axiosErrorHandler";
import { RESOURCE_TYPE, RESOURCE_TYPES, createNewResource } from "../../app/handlers/resourceHandler";
import { DataAsset, DataAssetType, PersonGroup } from "app/api/generated/asset-service";
import { parseDataTypeCategoryPersonGroupLine } from "app/pages/questionnaires/processing-page/parseDataTypeCategoryPersonGroupLine";
import { isAxiosError } from "axios";

type AIResponse = {
  description?: string;
  purpose?: string;
  legal_basis?: string;
  legal_basis_description?: string;
  person_group?: string;
  data_of_minors?: UpdatePersonGroupDTODataOfMinorsEnum;
  potential_amount_of_data_subjects?: string;
  data_types?: string[];
  lb_contract_value?: string;
  lb_legal_obligation_value?: string;
  lb_verification_value?: string;
  lb_vital_interests_value?: string;
  lb_controller_interest_value?: string;
  lb_interest_of_the_data_subject_value?: string;
  lb_interest_balancing_value?: string;
  lb_consent_content_value?: string;
  lb_obtaining_consent_value?: string;
  lb_public_interest_value?: string;
  lb_default_value?: string;
};

interface TitlesAIResponse extends AIResponse {
  items?: Array<{
    name: string;
    description: string;
  }>;
}

const MAX_STREAM_RETRIES = 2;
let streamRetryCount = 0;

const aiClient = new AiBackendApi(undefined, apiEndpoints.aiUrl, defaultOTCAuthenticatedAxios());
const processClient = new PAApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

const createTitleStep = (onDone: () => void, t: (key: string) => string) => ({
  title: (
    <Box display="flex" justifyContent="space-between" alignItems="start">
      <Typography variant="subtitle2" textTransform="none" textAlign="start" mt={0.3}>
        {t("suggest-process")}
      </Typography>
      <IconButton size="small" onClick={onDone}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  ),
  icon: AwesomePropIcon,
  iconProps: { color: "primary" }
});

const cleanJSONResponse = (rawText: string) => {
  return rawText
    .replace(/^```json\n/, "")
    .replace(/\n```$/, "")
    .trim();
};

const normalizeName = (name: string) => name.trim().toLowerCase().replace(/\s+/g, " ");
const getExistingId = (error: unknown) => (isAxiosError(error) ? error.response?.data?.existingResourceId : undefined);

export const SuggestTitlesMetaview = ({ onDone }: { onDone?: () => void }) => {
  const { t, i18n } = useTranslation("ai-metaview");
  const { tenantData } = useUserAndTenantData();
  const dataTypeTreeManager = useDataTypeTreeManager();
  const { resources, translateById } = useResources();
  const dispatch = useOverviewDispatch();

  const [sessionId, setSessionId] = useState<string>(() => v4());
  const [feedbackInput, setFeedbackInput] = useState<string>("");
  const [feedbackError, setFeedbackError] = useState<string | null>(null);
  const [feedbackInputLoading, setFeedbackInputLoading] = useState<boolean>(false);
  const [suggestedPAs, setSuggestedPAs] = useState<
    {
      name: string;
      description: string;
      status: "creating" | "created" | null;
    }[]
  >([]);
  const [stepState, setStepState] = useState<"thinking" | "prompt" | "generation">("thinking");
  const [addingAll, setAddingAll] = useState<boolean>(false);

  const abortControllerRef = useRef<AbortController>(new AbortController());
  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  // Transition from "thinking" to "prompt" after 500ms.
  useEffect(() => {
    if (stepState === "thinking") {
      const timer = setTimeout(() => {
        setStepState("prompt");
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [stepState]);

  const createPa = useCallback(
    async ({ name }: { name: string }) => {
      const createPAResponse = await processClient.createPA({ name });
      const paId = createPAResponse.headers["x-resource-id"];
      if (!paId) throw new Error(t("error.no-pa-created"));
      return paId;
    },
    [t]
  );

  const createPurpose = useCallback(async ({ paId }: { paId: string }) => {
    const purposeId = v4();
    await processClient.createPurpose(paId, { id: purposeId });
    return purposeId;
  }, []);

  const legalBasisTranslatedNameToId = useMemo(() => {
    const legalBases = resources[RESOURCE_TYPES.LEGAL_BASIS] || [];
    return new Map(
      legalBases.map(lb => [lb.defaultResource ? translateById(RESOURCE_TYPES.LEGAL_BASIS, lb.id) : lb.nameKey, lb.id])
    );
  }, [resources, translateById]);

  const generateFields = useCallback(
    async <T extends string[]>({
      name,
      description,
      fields,
      maxRetries = 5,
      baseDelay = 3000,
      context,
      noContext = false
    }: {
      name: string;
      description: string;
      fields: T;
      maxRetries?: number;
      baseDelay?: number;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      context?: any;
      noContext?: boolean;
    }): Promise<{ [K in keyof T]: string }> => {
      const tenantContext = {
        tenantName: tenantData?.name || "",
        ...(context ? context : {})
      };
      let retries = 0;
      // Generate a new session ID for each request
      setSessionId(v4());
      while (retries < maxRetries) {
        try {
          const response = await aiClient.suggestFields(
            COLLECTIONS.PROCESSES,
            sessionId,
            {
              prompt: `${name}\n${description}`,
              generateFields: fields,
              tenantContext: noContext ? undefined : tenantContext
            },
            false,
            {
              timeout: 0,
              headers: {
                "Accept-Language": i18n.language,
                "Content-Type": "application/json"
              }
            }
          );
          const generatedFields = fields.map(field => response.data[field as keyof typeof response.data] as string) as {
            [K in keyof T]: string;
          };
          // console.log("generated fields: ", generatedFields);

          // wait for 1 second to avoid rate limiting
          await new Promise(resolve => setTimeout(resolve, 1000));

          return generatedFields;
        } catch (error) {
          const isRateLimitedError = isAxiosErrorWithCode(error, 429, "RateLimitError");
          const isBadRequestError = isAxiosErrorWithCode(error, 400, "ERR_BAD_REQUEST");
          if (retries >= maxRetries && !isRateLimitedError && !isBadRequestError) {
            // stop retrying for unknown error
            throw error;
          }
          console.error("rate limited or bad request error, retrying to get fields: ", fields);
          const delay = baseDelay * 2 ** retries;
          await new Promise(resolve => setTimeout(resolve, delay));
          retries++;
        }
      }
      throw new Error("Max retries reached");
    },
    [sessionId, tenantData?.name, i18n.language]
  );

  // new function to generate fields for PA
  const generatePAFields = useCallback(
    async ({ name, description }: { name: string; description: string }) => {
      const alreadyExistingLegalBasisNames = resources[RESOURCE_TYPES.LEGAL_BASIS].map(lb =>
        translateById(RESOURCE_TYPES.LEGAL_BASIS, lb.id)
      );
      return generateFields({
        name,
        description,
        fields: [
          "description",
          "purposeDescription",
          "legalBasisName",
          "legalBasisHowToProveDescription",
          "personGroupName",
          "dataOfMinorAsSmallLetterYesNoUnsureEnum",
          "dataTypeWithDataCategoryInSquareBracketAsArrayStrings",
          "potentialAmountOfDataSubjects"
        ],
        context: {
          alreadyExistingLegalBasisNames
        }
        // noContext: true // set noContext to true as personGroupsTree and dataTypes are not used in the prompt anymore
      });
    },
    [generateFields, resources, translateById]
  );

  // new function to handle legal basis
  const handleLegalBasis = useCallback(
    async (legalBasisName: string) => {
      let legalBasisId: string | undefined = legalBasisTranslatedNameToId.get(legalBasisName);
      if (!legalBasisId && legalBasisName) {
        try {
          const sanitizedNameKey = legalBasisName
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, "_")
            .replace(/_+/g, "_")
            .replace(/^_|_$/g, "");
          const existing = resources[RESOURCE_TYPES.LEGAL_BASIS].find(
            lb => lb.nameKey === sanitizedNameKey || translateById(RESOURCE_TYPES.LEGAL_BASIS, lb.id) === legalBasisName
          );
          if (existing) {
            legalBasisId = existing.id;
          } else {
            const createdId = await createNewResource({
              resourceType: RESOURCE_TYPES.LEGAL_BASIS,
              nameKey: legalBasisName
            });
            legalBasisId = createdId;
          }
        } catch (error) {
          console.error("Failed to handle legal basis:", error);
        }
      }
      return legalBasisId;
    },
    [legalBasisTranslatedNameToId, resources, translateById]
  );

  // new function to update PA with generated data
  const updatePA = useCallback(
    async ({
      paId,
      purposeId,
      purposeDescription,
      lbVerificationValue,
      legalBasisId,
      potentialAmount,
      dataOfMinors,
      personGroupName,
      dataTypeWithDataCategoryInSquareBracketAsArrayStrings
    }: {
      paId: string;
      purposeId: string;
      description: string;
      purposeDescription: string;
      lbVerificationValue: string;
      legalBasisId: string | undefined;
      potentialAmount: string;
      dataOfMinors: UpdatePersonGroupDTODataOfMinorsEnum;
      personGroupName: string;
      dataTypeWithDataCategoryInSquareBracketAsArrayStrings: string | string[];
    }) => {
      await processClient.updatePurpose(paId, purposeId, {
        legalBasisId: legalBasisId!,
        purposeDescription: purposeDescription,
        lbVerificationValue: lbVerificationValue || ""
      } as UpdatePurposeDTO);

      const personGroups = dataTypeTreeManager.data?.map(pg => ({
        id: pg.id,
        dataCategories: pg.dataCategories,
        name: pg.personGroupKey
      }));

      // const personGroupId = personGroups?.find(pg => pg.name === personGroupName)?.id;
      const personGroupId = personGroups?.find(pg => pg.name.toLowerCase().includes(personGroupName.toLowerCase()))?.id;

      // Calculate filtered category type tuples first
      const { filteredCategoryTypeTuples } = await handlePersonGroupAndDataTypes(
        personGroupName,
        Array.isArray(dataTypeWithDataCategoryInSquareBracketAsArrayStrings)
          ? dataTypeWithDataCategoryInSquareBracketAsArrayStrings
          : dataTypeWithDataCategoryInSquareBracketAsArrayStrings.split(","),
        dataTypeTreeManager,
        resources,
        translateById
      );

      if (!personGroupId) {
        // create new person group since none was found
        console.log("no person group found, creating new one", { personGroupName });
        try {
          const pgResponse = await dataAssetClient.createDataAsset(
            {
              assetType: DataAssetType.PersonGroup,
              name: personGroupName
            },
            "true", // enable multilingual
            "true" // conflict detection
          );
          const newPersonGroupId = pgResponse.headers["x-resource-id"];
          if (!newPersonGroupId) {
            throw new Error(t("error.no-person-group-created"));
          }
          const personGroupResponse = await processClient.createPersonGroup(paId, { id: newPersonGroupId });
          const personGroupId = personGroupResponse.headers["x-resource-id"];
          const existingPGResponse = await dataAssetClient.getPersonGroups(paId);
          const existingPGArray = existingPGResponse.data.items || [];
          const existingPG = existingPGArray.find((pg: PersonGroup) => pg.id === personGroupId);
          if (!existingPG) {
            throw new Error("Person group not found in backend data");
          }
          const updatedPGPayload = {
            personGroup: newPersonGroupId,
            categoryTypeTuples: filteredCategoryTypeTuples.map(tuple => ({
              id: tuple.id ? tuple.id : v4(),
              category: tuple.category,
              type: tuple.type,
              personGroup: newPersonGroupId,
              dataClassificationId: tuple.dataClassificationId
            })),
            dataOfMinors: dataOfMinors.toLowerCase() as UpdatePersonGroupDTODataOfMinorsEnum,
            potentialAmountOfDataSubjects: `${potentialAmount}`
          };
          await processClient.updatePersonGroup(paId, personGroupId, updatedPGPayload);
        } catch (error) {
          if (isAxiosError(error) && error.response?.status === 409) {
            // person group already exists, get existing id
            const match = error.response?.data.message.match(/Resource with '([a-f0-9-]+)'/);
            const existingPersonGroupId = match?.[1];
            if (!existingPersonGroupId) {
              throw new Error(t("error.person-group-conflict"));
            }
            // use existing person group id
            const personGroupResponse = await processClient.createPersonGroup(paId, { id: existingPersonGroupId });
            const personGroupId = personGroupResponse.headers["x-resource-id"];
            const existingPGResponse = await dataAssetClient.getPersonGroups(paId);
            const existingPGArray = existingPGResponse.data.items || [];
            const existingPG = existingPGArray.find((pg: PersonGroup) => pg.id === personGroupId);
            if (!existingPG) {
              throw new Error("Person group not found in backend data");
            }
            const updatedPGPayload = {
              personGroup: existingPersonGroupId,
              categoryTypeTuples: filteredCategoryTypeTuples.map(tuple => ({
                id: tuple.id ? tuple.id : v4(),
                category: tuple.category,
                type: tuple.type,
                personGroup: existingPersonGroupId,
                dataClassificationId: tuple.dataClassificationId
              })),
              dataOfMinors: dataOfMinors.toLowerCase() as UpdatePersonGroupDTODataOfMinorsEnum,
              potentialAmountOfDataSubjects: `${potentialAmount}`
            };
            await processClient.updatePersonGroup(paId, personGroupId, updatedPGPayload);
          } else {
            throw error;
          }
        }
      } else {
        let personGroupDataAssetId: string;
        try {
          const pgResponse = await dataAssetClient.createDataAsset(
            {
              assetType: DataAssetType.PersonGroup,
              name: personGroupName
            },
            "true", // enable multilingual
            "true" // conflict detection
          );
          personGroupDataAssetId = pgResponse.headers["x-resource-id"];
        } catch (error) {
          if (isAxiosError(error) && error.status === 409) {
            const match = error.response?.data.message.match(/Resource with '([a-f0-9-]+)'/);
            personGroupDataAssetId = match?.[1];
            if (!personGroupDataAssetId) throw new Error(t("error.person-group-conflict"));
          } else {
            throw error;
          }
        }
        const personGroupResponse = await processClient.createPersonGroup(paId, { id: personGroupDataAssetId });
        const personGroupId = personGroupResponse.headers["x-resource-id"];
        const existingPGResponse = await dataAssetClient.getPersonGroups(paId);
        const existingPGArray = existingPGResponse.data.items || [];
        const existingPG = existingPGArray.find((pg: PersonGroup) => pg.id === personGroupId);
        if (!existingPG) {
          throw new Error("Person group not found in backend data");
        }
        const updatedPGPayload = {
          personGroup: personGroupDataAssetId,
          categoryTypeTuples: filteredCategoryTypeTuples.map(tuple => ({
            id: tuple.id ? tuple.id : v4(),
            category: tuple.category,
            type: tuple.type,
            personGroup: personGroupDataAssetId,
            dataClassificationId: tuple.dataClassificationId
          })),
          dataOfMinors: dataOfMinors.toLowerCase() as UpdatePersonGroupDTODataOfMinorsEnum,
          potentialAmountOfDataSubjects: `${potentialAmount}`
        };
        await processClient.updatePersonGroup(paId, personGroupId, updatedPGPayload);
      }
    },
    [dataTypeTreeManager, resources, translateById, t]
  );

  // The following is super hacky - we know a lot of the logic should better be in the backend
  // it's a poc
  // please dont build anything on top of this
  // if the user exits in the final stage after PA was created and before everything is updated it's incomplete..
  const addPA = useCallback(
    async (pa: { name: string; description: string }) => {
      if (!sessionId) throw new Error(t("error.no-session"));
      const { name, description: shortDescription } = pa;

      const startTime = Date.now();

      try {
        setSuggestedPAs(prev => prev.map(p => (p.name === name ? { ...p, status: "creating" } : p)));

        // generate all fields at once
        const [
          description,
          purposeDescription,
          legal_basis,
          legalBasisHowToProveDescription,
          personGroupName,
          dataOfMinorAsSmallLetterYesNoUnsureEnum,
          dataTypeWithDataCategoryInSquareBracketAsArrayStrings,
          potentialAmountOfDataSubjects
        ] = await generatePAFields({
          name,
          description: shortDescription
        });

        const lbVerificationValue = legalBasisHowToProveDescription;

        let oneLegalBasis = legal_basis;
        if (Array.isArray(legal_basis)) {
          oneLegalBasis = legal_basis[0];
        } else if (legal_basis.includes(",")) {
          oneLegalBasis = legal_basis.split(",")[0];
        }
        const legalBasisId = await handleLegalBasis(oneLegalBasis);

        const potentialAmount = potentialAmountOfDataSubjects;
        const dataOfMinors = dataOfMinorAsSmallLetterYesNoUnsureEnum;

        // finished generating - create PA and update fields
        const paId = await createPa({ name });

        const [, purposeId] = await Promise.all([
          processClient.updateDescription(paId, { description }),
          createPurpose({ paId })
        ]);

        await updatePA({
          paId,
          purposeId,
          description,
          purposeDescription,
          lbVerificationValue,
          legalBasisId,
          potentialAmount,
          dataOfMinors: dataOfMinors.toLowerCase() as UpdatePersonGroupDTODataOfMinorsEnum,
          personGroupName,
          dataTypeWithDataCategoryInSquareBracketAsArrayStrings
        });

        setSuggestedPAs(prev => prev.map(p => (p.name === pa.name ? { ...p, status: "created" } : p)));
      } catch (error) {
        setSuggestedPAs(prev => prev.map(p => (p.name === pa.name ? { ...p, status: null } : p)));
        console.error("PA creation failed:", error);
      } finally {
        dispatch({
          type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
          collection: COLLECTIONS.PROCESSES,
          reloadOverview: Date.now()
        });
      }
      const endTime = Date.now();

      console.log("Time taken: ", (endTime - startTime) / 1000, "seconds");
    },
    [createPa, createPurpose, dispatch, generatePAFields, handleLegalBasis, sessionId, t, updatePA]
  );

  const addAllSuggestionsCallback = useCallback(async () => {
    setAddingAll(true);
    const suggestionsCopy = [...suggestedPAs];
    try {
      for (let i = 0; i < suggestionsCopy.length; i++) {
        const pa = suggestionsCopy[i];
        if (pa.status) continue;
        setSuggestedPAs(current =>
          current.map(item => (item.name === pa.name ? { ...item, status: "creating" } : item))
        );
        await addPA(pa);
      }
    } finally {
      setAddingAll(false);
    }
  }, [addPA, suggestedPAs]);

  const onFeedbackInputSubmission = useCallback(async () => {
    if (!sessionId) {
      console.error("No session ID available");
      setFeedbackError(t("error.unknown"));
      return;
    }
    try {
      setFeedbackInputLoading(true);
      setFeedbackError(null);

      let lastParseMs = Date.now();
      const newAbortController = new AbortController();
      abortControllerRef.current.abort();
      abortControllerRef.current = newAbortController;

      // Start showing the generation state immediately to see streaming results
      setStepState("generation");

      const response = await aiClient.suggestTitles(
        COLLECTIONS.PROCESSES,
        sessionId,
        {
          prompt: feedbackInput,
          tenantContext: {
            tenantName: tenantData?.name || ""
            // personGroupsTree: dataTypeTreeManager.data || [],
            // resources: resources || {}
          }
        },
        {
          timeout: 0,
          headers: { "Accept-Language": i18n.language },
          signal: abortControllerRef.current.signal,
          onDownloadProgress: evt => {
            try {
              const currentMs = Date.now();
              const passedTimeMs = currentMs - lastParseMs;
              if (passedTimeMs < 500) return;
              lastParseMs = currentMs;

              const currentResponse = evt.event?.target?.responseText || "";
              const cleanedResponse = cleanJSONResponse(currentResponse);
              const bestEffortData = bestEffortParse(cleanedResponse);

              if (typeof bestEffortData === "object" && Object.entries(bestEffortData).length > 0) {
                // If we have items, update the suggestions list
                if (bestEffortData.items?.length) {
                  setSuggestedPAs(
                    bestEffortData.items.map((pa: { name: string; description: string }) => ({
                      name: pa.name || "",
                      description: pa.description || "",
                      status: null
                    }))
                  );
                }
              }
            } catch (parseError) {
              if (streamRetryCount < MAX_STREAM_RETRIES) {
                streamRetryCount += 1;
                setTimeout(() => {
                  onFeedbackInputSubmission();
                }, 10000 * streamRetryCount);
              }
            }
          }
        }
      );

      // Handle the final response
      if (response.data) {
        try {
          const cleaned = cleanJSONResponse(JSON.stringify(response.data));
          const verifiedData = bestEffortParse(cleaned) as TitlesAIResponse;

          // Use existing suggestions if final parse fails
          const finalItems = verifiedData.items?.length
            ? verifiedData.items
            : suggestedPAs.map(p => ({ name: p.name, description: p.description }));

          if (!finalItems.length) {
            throw new Error("No valid suggestions");
          }

          setSuggestedPAs(
            finalItems.map(pa => ({
              name: pa.name || "",
              description: pa.description || "",
              status: null
            }))
          );
        } catch (finalParseError) {
          // Only show error if we have NO partial results
          if (!suggestedPAs.length) {
            setFeedbackError(t("error.no-suggestions"));
            setStepState("prompt");
          }
        }
      }
    } catch (error) {
      console.error("Error during AI title suggestion:", error);
      if (error instanceof Error) {
        if (
          isAxiosErrorWithCode(error, 400, "UnclearPromptError") ||
          isAxiosErrorWithCode(error, 400, "EmptyPromptError")
        ) {
          setFeedbackError(t("error.too-short"));
        } else {
          setFeedbackError(error.message || t("error.unknown"));
        }
      }
      setStepState("prompt"); // Go back to prompt on error
    } finally {
      setFeedbackInputLoading(false);
    }
  }, [sessionId, t, feedbackInput, tenantData?.name, i18n.language, suggestedPAs]);

  const refineUseCaseCallback = useCallback(() => {
    setStepState("prompt");
    setSuggestedPAs([]);
  }, []);

  const coverAnotherUserCaseCallback = useCallback(() => {
    setStepState("prompt");
    setSuggestedPAs([]);
    setFeedbackInput("");
    setSessionId(v4());
  }, []);

  // Compute current steps dynamically so that changes (like feedbackInput) are reflected immediately.
  const currentSteps = useMemo<AIStepProps[]>(() => {
    if (stepState === "thinking") {
      return [
        createTitleStep(onDone || (() => {}), t),
        {
          title: (
            <Typography variant="body2" textTransform="none" textAlign="start">
              {t("thinking")}
            </Typography>
          ),
          icon: CircularProcessPropIcon
        }
      ];
    } else if (stepState === "prompt") {
      return [
        createTitleStep(onDone || (() => {}), t),
        {
          title: (
            <Typography variant="body2" textTransform="none" textAlign="start">
              {t("describe-usecase")}
            </Typography>
          ),
          icon: CircleOutlinedPropIcon,
          content: (
            <>
              <FeedbackInput
                placeholder={t("describe-usecase-placeholder")}
                value={feedbackInput}
                onChange={setFeedbackInput}
                onClick={onFeedbackInputSubmission}
                loading={feedbackInputLoading}
              />
              {feedbackError && (
                <Box mt={1}>
                  <Typography variant="body1" textTransform="none" textAlign="start" color="text.secondary">
                    {feedbackError}
                  </Typography>
                </Box>
              )}
            </>
          )
        }
      ];
    } else if (stepState === "generation") {
      return [
        createTitleStep(onDone || (() => {}), t),
        {
          title: (
            <Typography variant="body2" textTransform="none" textAlign="start">
              {t("describe-usecase")}
            </Typography>
          ),
          icon: CheckCircleOutlinedPropIcon,
          content: (
            <Typography variant="body2" textTransform="none" textAlign="start" color="text.secondary">
              {feedbackInput}
            </Typography>
          )
        },
        {
          title: (
            <Typography variant="body2" textTransform="none" textAlign="start">
              {t("suggested-titles")}
            </Typography>
          ),
          icon: feedbackInputLoading ? CircularProcessPropIcon : InfoOutlinedPropIcon,
          content: (
            <Box>
              {/* Show loading indicator while no suggestions are available */}
              {feedbackInputLoading && !suggestedPAs.length && (
                <Box display="flex" justifyContent="center" my={2}>
                  <CircularProgress size={24} />
                </Box>
              )}
              {suggestedPAs.map(pa => (
                <Box key={pa.name} mb={0.5}>
                  <AIStepAccordion
                    title={pa.name}
                    icon={
                      <>
                        {!pa.status && (
                          <IconButton
                            size="medium"
                            color="primary"
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={async e => {
                              e.stopPropagation();
                              setSuggestedPAs(lastValues =>
                                lastValues.map(lastValue =>
                                  lastValue.name === pa.name ? { ...lastValue, status: "creating" } : lastValue
                                )
                              );
                              await addPA(pa);
                            }}
                          >
                            <AddCircleOutlineIcon fontSize="small" />
                          </IconButton>
                        )}
                        {pa.status === "creating" && (
                          <IconButton size="medium" disabled>
                            <CircularProgress size={20} />
                          </IconButton>
                        )}
                        {pa.status === "created" && (
                          <IconButton disabled>
                            <CheckCircleIcon color="success" fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    }
                  >
                    <Typography variant="body1" textTransform="none" textAlign="start" color="text.secondary">
                      {pa.description}
                    </Typography>
                  </AIStepAccordion>
                </Box>
              ))}
              <Collapse in={!feedbackInputLoading && suggestedPAs.some(it => !it.status)}>
                <Button
                  onClick={addAllSuggestionsCallback}
                  variant="text"
                  color="primary"
                  startIcon={addingAll ? <CircularProgress size={24} /> : <LibraryAddOutlinedIcon fontSize="small" />}
                >
                  {t("add-all")}
                </Button>
              </Collapse>
            </Box>
          )
        },
        !feedbackInputLoading && {
          title: (
            <Typography variant="body2" textTransform="none" textAlign="start">
              {t("what-next")}
            </Typography>
          ),
          icon: CircleOutlinedPropIcon,
          content: (
            <Box>
              {(
                [
                  {
                    onClick: refineUseCaseCallback,
                    startIcon: <AutoFixNormalOutlined color="action" />,
                    label: t("refine"),
                    sx: { mb: 1 }
                  },
                  {
                    onClick: coverAnotherUserCaseCallback,
                    startIcon: <AddCircleOutlineIcon color="action" />,
                    label: t("cover-another-case"),
                    sx: { mb: 1 }
                  },
                  {
                    onClick: onDone,
                    startIcon: <WavingHandOutlined color="action" />,
                    label: t("done")
                  }
                ] as AIIntegrationNextStepProps[]
              ).map(it => (
                <Box mb={1} key={it.label}>
                  <AIStepButton onClick={it.onClick} startIcon={it.startIcon}>
                    <Typography variant="body2" textTransform="none" textAlign="start">
                      {it.label}
                    </Typography>
                  </AIStepButton>
                </Box>
              ))}
            </Box>
          )
        }
      ].flatMap(it => (it ? [it] : []));
    } else {
      return [];
    }
  }, [
    stepState,
    t,
    feedbackInput,
    feedbackInputLoading,
    onFeedbackInputSubmission,
    feedbackError,
    suggestedPAs,
    addingAll,
    addAllSuggestionsCallback,
    refineUseCaseCallback,
    coverAnotherUserCaseCallback,
    onDone,
    addPA
  ]);

  // Compute active step based on stepState.
  const activeStepComputed = useMemo(() => {
    if (stepState === "thinking" || stepState === "prompt") return 1;
    if (stepState === "generation") return 2;
    return 0;
  }, [stepState]);

  return (
    <Box>
      <Stepper
        activeStep={activeStepComputed}
        orientation="vertical"
        nonLinear
        sx={{
          "& .MuiStepConnector-root": { ml: "9px !important" },
          "& .MuiStepContent-root": { ml: "9px !important" }
        }}
      >
        {currentSteps.map((step, index) => (
          <Step key={`${step.title}-${index}`} expanded>
            <StepLabel optional={step.subtitle} StepIconComponent={step.icon} StepIconProps={step.iconProps}>
              {step.title}
            </StepLabel>
            <StepContent>{step.content}</StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const handlePersonGroupAndDataTypes = async (
  personGroupName: string,
  dataTypePairs: string[],
  dataTypeTreeManager: any,
  resources: any,
  translateById: (resourceType: RESOURCE_TYPE, id: string) => string
) => {
  // clean up data type pairs by removing leading dash and space
  const cleanedDataTypePairs = dataTypePairs.map(pair => pair.replace(/^[\s-]+/, "").trim());

  let personGroupId: string | undefined;
  try {
    // attempt to create new person group with conflict detection
    const pgResponse = await dataAssetClient.createDataAsset(
      {
        assetType: DataAssetType.PersonGroup,
        name: personGroupName
      },
      "true", // multilingual
      "true" // detect conflict
    );
    personGroupId = pgResponse.headers["x-resource-id"];
  } catch (error) {
    if (isAxiosError(error) && error.status === 409) {
      personGroupId = error.response?.data.existingResourceId;
      if (!personGroupId) throw new Error(`Conflict but no ID: ${personGroupName}`);
    }
  }

  await dataTypeTreeManager.mutate();

  const existingCategories = new Map<string, string>();
  const existingTypes = new Map<string, string>();

  const categoryTypeTuples = await Promise.all(
    cleanedDataTypePairs.map(async pair => {
      const { dataCategoryName, dataTypeName } = parseDataTypeCategoryPersonGroupLine(pair);

      // Normalize names
      const normCategory = dataCategoryName.trim().toLowerCase();
      const normType = dataTypeName.trim().toLowerCase();

      // Category handling with normalized cache
      let categoryId = existingCategories.get(normCategory);
      if (!categoryId) {
        const existingCategory = resources[RESOURCE_TYPES.DATA_ASSETS]?.find(
          (asset: DataAsset) =>
            asset.assetType === DataAssetType.DataCategory &&
            normalizeName(translateById(RESOURCE_TYPES.DATA_ASSETS, asset.id!)) === normCategory
        );

        categoryId = existingCategory?.id;

        if (!categoryId) {
          try {
            const response = await dataAssetClient.createDataAsset(
              {
                assetType: DataAssetType.DataCategory,
                name: dataCategoryName,
                parentDataAssetId: personGroupId
              },
              "true",
              "true"
            );
            categoryId = response.headers["x-resource-id"];
          } catch (error) {
            if (isAxiosError(error) && error.status === 409) {
              categoryId = error.response?.data.existingResourceId; // Use direct field access
              if (!categoryId) throw new Error(`Conflict but no ID: ${dataCategoryName}`);
              existingCategories.set(normCategory, categoryId);
            }
            throw error;
          }
          if (!categoryId) throw new Error(`Category creation failed: ${dataCategoryName}`);
          existingCategories.set(normCategory, categoryId);
        }
      }

      // Similar normalization and caching for dataType...
      let dataTypeId = existingTypes.get(normType);
      if (!dataTypeId) {
        // Verify parent category exists first
        if (!categoryId) {
          throw new Error(`Missing parent category for type: ${dataTypeName}`);
        }

        const existingType = resources[RESOURCE_TYPES.DATA_ASSETS]?.find((asset: any) => {
          if (asset.assetType !== DataAssetType.DataType) return false;
          // Use asset.name if available, then asset.nameKey, then the translated name
          const candidateName = (
            asset.name ||
            asset.nameKey ||
            translateById(RESOURCE_TYPES.DATA_ASSETS, asset.id) ||
            ""
          )
            .trim()
            .toLowerCase();
          return candidateName === normType && asset.parentDataAssetId === categoryId;
        });
        if (existingType) {
          dataTypeId = existingType.id;
          existingTypes.set(normType, dataTypeId!);
        } else {
          try {
            dataTypeId = await createDataType(dataTypeName, categoryId);
            if (!dataTypeId) {
              throw new Error(`DataType creation returned undefined for type ${dataTypeName}`);
            }
            existingTypes.set(normType, dataTypeId!);
            await dataAssetClient.getDataAsset(dataTypeId!);
          } catch (error) {
            const existingId = getExistingId(error);
            if (existingId) {
              dataTypeId = existingId;
              existingTypes.set(normType, dataTypeId!);
            } else {
              console.error("Type creation failed", { error, dataTypeId, normType, dataTypeName });
            }
          }
        }

        // Final validation before return
        if (!dataTypeId) {
          throw new Error(`Type ID resolution failed: ${dataTypeName}`);
        }

        return {
          id: dataTypeId!,
          category: categoryId,
          type: dataTypeId!,
          personGroup: personGroupId,
          dataClassificationId: null
        };
      }

      // Final validation before return
      if (!dataTypeId) {
        throw new Error(`Type ID resolution failed: ${dataTypeName}`);
      }

      return {
        id: dataTypeId!,
        category: categoryId,
        type: dataTypeId!,
        personGroup: personGroupId,
        dataClassificationId: null
      };
    })
  );

  // Filter out uncategorized entries
  const filteredCategoryTypeTuples = categoryTypeTuples.filter(
    tuple =>
      !resources[RESOURCE_TYPES.DATA_ASSETS]?.some(
        (asset: any) =>
          asset.id === tuple.type && translateById(RESOURCE_TYPES.DATA_ASSETS, asset.id) === "uncategorized"
      )
  );

  return { personGroupId, filteredCategoryTypeTuples };
};

const createDataType = async (dataTypeName: string, categoryId: string) => {
  try {
    const response = await dataAssetClient.createDataAsset(
      {
        assetType: DataAssetType.DataType,
        name: dataTypeName,
        parentDataAssetId: categoryId
      },
      "true",
      "true"
    );
    return response.headers["x-resource-id"];
  } catch (error) {
    const existingId = getExistingId(error);
    if (existingId) return existingId;
    throw error;
  }
};
