import { Box } from "@mui/material";
import { patchAnswerSetApi, postContributorExternalUserAPi } from "app/api/assessmentApi";
import { isAxiosErrorWithCode } from "app/api/axios/axiosErrorHandler";
import { ExternalUserDTO } from "app/api/user/userApi";
import { sendNotificationApi } from "app/api/userNotificationApi";
import { COLLECTIONS } from "app/collections";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { AddContributorDialog } from "app/pages/audits/assessment/dialog/AddContributorDialog";
import { AUDIT_METHODOLOGY } from "app/pages/audits/audit/AuditTypes";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { UserAvatarProps } from "components/UserAvatar/UserAvatar";
import UserAvatarList from "components/UserAvatar/UserAvatarList";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "theme/palette/colors";
import stopEvent from "tool/stopEvent";

const AssessmentOverviewBrickContributors = ({ item }: OverviewRowBrickProps) => {
  const { auditId, id, title, participantsUIDs, disableActions, assigneeUID, auditMethodology } = item;

  const { t } = useTranslation();
  const dispatch = useOverviewDispatch();
  const { reloadTenantUsers } = useUserAndTenantData();

  const [openAddContributorDialog, setOpenAddContributorDialog] = useState<boolean>(false);
  const [addExternalUserErrors, setAddExternalUserErrors] = useState<Record<string, string>>({});

  const disabled = useMemo(() => disableActions?.some(({ action }) => action === "edit"), [disableActions]);

  const reload = useCallback(async () => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.ASSESSMENT_RESPONSE, reload });
  }, [dispatch]);

  /* CONTRIBUTOR DIALOG */
  const onAddContributorOpen = useCallback(() => {
    setOpenAddContributorDialog(true);
  }, []);
  const onConfirmAddInternalContributor = useCallback(
    async (_id: string) => {
      setAddExternalUserErrors({});
      if (participantsUIDs.includes(_id) || assigneeUID === _id) {
        setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        return;
      }
      try {
        const payload = {
          participantsUIDs: [...participantsUIDs, _id]
        };
        await patchAnswerSetApi({ auditId: auditId, id, payload });
        const notificationObj = {
          title: "audit_assignment",
          receivers: [_id],
          pageId: "general",
          collection: COLLECTIONS.ASSESSMENT_RESPONSE,
          docId: `${auditId}/answerset/${id}`, // this for generic link notification email
          docName: title || "",
          message: ""
        };
        await sendNotificationApi(notificationObj);
        reload();
        setOpenAddContributorDialog(false);
      } catch (e) {
        if (isAxiosErrorWithCode(e, 409)) {
          setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        }
      }
    },
    [assigneeUID, auditId, participantsUIDs, id, reload, t, title]
  );
  const onConfirmAddExternalContributor = useCallback(
    async (externalUser: ExternalUserDTO) => {
      setAddExternalUserErrors({});
      try {
        await postContributorExternalUserAPi({
          auditId,
          answersetId: id,
          payload: externalUser
        });
        await reloadTenantUsers();
        reload();
        setOpenAddContributorDialog(false);
      } catch (e) {
        if (isAxiosErrorWithCode(e, 409)) {
          setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        }
      }
    },
    [auditId, id, reload, reloadTenantUsers, t]
  );
  const onCancelAddContributor = useCallback(() => {
    setOpenAddContributorDialog(false);
    setAddExternalUserErrors({});
  }, []);

  const onRemoveContributor = useCallback(
    async (contributorId: string) => {
      const payload = {
        participantsUIDs: participantsUIDs?.filter((_id: string) => _id !== contributorId)
      };
      await patchAnswerSetApi({ auditId: auditId, id: id || "", payload });
      reload();
    },
    [auditId, participantsUIDs, id, reload]
  );

  const avatars = participantsUIDs?.map(
    (contributorId: string) =>
      ({
        userId: contributorId,
        size: "small",
        color: colors.blue.blue400,
        allowRemove: true
      }) as UserAvatarProps
  );

  if (auditMethodology === AUDIT_METHODOLOGY.INTERVIEW) {
    return <></>;
  }
  return (
    <Box onClick={stopEvent}>
      <UserAvatarList
        avatars={avatars}
        onRemove={onRemoveContributor}
        onAdd={onAddContributorOpen}
        disabled={disabled}
      />
      <AddContributorDialog
        auditMethodology={AUDIT_METHODOLOGY.SELF_ASSESSMENT}
        open={openAddContributorDialog}
        errors={addExternalUserErrors}
        onCancel={onCancelAddContributor}
        onConfirmInternal={onConfirmAddInternalContributor}
        onConfirmExternal={onConfirmAddExternalContributor}
        auditOrgUnitIds={[item.auditMainOrgUnitId, ...item.auditFurtherOrgUnitIds]}
      />
    </Box>
  );
};

export default AssessmentOverviewBrickContributors;
