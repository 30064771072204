import { Box, Typography } from "@mui/material";
import { DataSubjectRequest } from "app/handlers/dataSubjectRequestHandler";
import { useTranslation } from "react-i18next";
import DSRPersonGroupPicker from "./components/DSRPersonGroupPicker";
import DSRDataSourcePicker from "./components/DSRDataSourcePicker";
import DSRDataStoragePicker from "./components/DSRDataStoragePicker";
import DSRInternalRecipientPicker from "./components/DSRInternalRecipientPicker";
import DSRExternalRecipientPicker from "./components/DSRExternalRecipientPicker";
import DSRProcessingActivities from "./components/DSRProcessingActivities";
import DSRAttachments from "./components/DSRAttachments";
import { useEffect, useMemo } from "react";
import { useDataSubjectRequest } from "app/contexts/dsr-context";
import { useMetaView } from "app/contexts/meta-view-context";

interface RequestDataPageProps {
  readonly request: DataSubjectRequest;
  readonly disabled?: boolean;
}

// simple data page component for data subject requests
// this is the "Data" page in the DSR flow
const RequestDataPage = ({ disabled }: RequestDataPageProps) => {
  const { t } = useTranslation("data_subject_requests_data_page");
  const { dataSubjectRequest } = useDataSubjectRequest();
  const { setInfo } = useMetaView();

  const sx = useMemo(
    () => ({
      root: {
        display: "flex",
        flexDirection: "column",
        "& .MuiChip-root": {
          opacity: 1
        }
      }
    }),
    []
  );

  const hasPersonGroups = Boolean(dataSubjectRequest?.inputData?.personGroups?.length);

  // info cards for different states
  const infoCardNoPersonGroup = useMemo(
    () => ({
      title: t("info_title"),
      text: t("info_no_person_group")
    }),
    [t]
  );

  const infoCardPersonGroup = useMemo(
    () => ({
      title: t("info_title"),
      text: t("info_person_group")
    }),
    [t]
  );

  // update info based on person groups selection
  useEffect(() => {
    if (hasPersonGroups) {
      setInfo(infoCardPersonGroup);
    } else {
      setInfo(infoCardNoPersonGroup);
    }
  }, [hasPersonGroups, setInfo, infoCardPersonGroup, infoCardNoPersonGroup]);

  const currentInfoCard = hasPersonGroups ? infoCardPersonGroup : infoCardNoPersonGroup;

  return (
    <Box sx={sx.root}>
      <Typography variant="h6">{t("data")}</Typography>
      <DSRPersonGroupPicker disabled={disabled} />
      {hasPersonGroups && (
        <>
          <Typography variant="subtitle1">{t("relevant_resources")}</Typography>
          <DSRDataSourcePicker disabled={disabled} />
          <DSRDataStoragePicker disabled={disabled} />
          <DSRInternalRecipientPicker disabled={disabled} />
          <DSRExternalRecipientPicker disabled={disabled} />
          <DSRProcessingActivities disabled={disabled} defaultInfo={currentInfoCard} />
        </>
      )}
      <DSRAttachments disabled={disabled} defaultInfo={currentInfoCard} />
    </Box>
  );
};

export default RequestDataPage;
