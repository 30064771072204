import { Box, Button, Checkbox, Chip, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import colors from "theme/palette/colors";
import { getBoldSearchText } from "tool/search";
import stopEvent from "tool/stopEvent";
import { ChevronRight, SearchOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useResources } from "app/contexts/resource-context";
import ProcessOverviewBrickStatus from "components/Overview/bricks/processes/ProcessOverviewBrickStatus";
import { createPAApi } from "app/api/paApi";
import { t } from "i18next";
import OverviewNoItemsFound from "components/Overview/controls/OverviewNoItemsFound";

const sx = {
  root: {
    height: "600px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  groupBy: {
    my: 0.5,
    display: "flex",
    gap: 0.5
  },
  search: {
    width: "100%",
    mb: 2
  },
  list: {
    flex: 1,
    overflow: "auto"
  },
  disabledRow: {
    opacity: 0.6
  },
  row: {
    display: "flex",
    borderBottom: `1px solid ${colors.divider}`,
    cursor: "pointer",
    py: 0.5,
    pr: 1,
    alignItems: "center",
    "&:hover": {
      background: colors.grey.grey100
    }
  },
  title: {
    ml: 1,
    flex: 1,
    overflow: "hidden"
  },
  children: {
    mb: 3
  },
  totalCountZero: { backgroundColor: colors.grey.grey100 },
  totalCountNonZero: { backgroundColor: colors.blue.blue100 },
  totalCount: {
    borderRadius: "16px",
    padding: 0.5,
    textAlign: "center",
    width: "32px",
    opacity: 0.8
  }
};

interface ProcessRowProps {
  readonly process: ProcessesPickerModalOptionProps;
  readonly search: string;
  readonly checked: boolean;
  readonly disabled: boolean;
  readonly pl?: number;
  readonly onChecked: (id: string) => void;
}
const ProcessRow = ({ process, search, checked, disabled, pl, onChecked }: ProcessRowProps) => {
  const { name, status, id } = process;
  const onCheckedCallback = useCallback(
    event => {
      if (disabled) return;
      stopEvent(event);
      onChecked?.(id);
    },
    [disabled, id, onChecked]
  );

  return (
    <Tooltip title={disabled ? t("common:readOnly") : ""}>
      <Box sx={{ ...sx.row, ...(disabled ? sx.disabledRow : {}) }} pl={pl || 0.5} onClick={onCheckedCallback}>
        <Checkbox checked={checked} disabled={disabled} />
        <ProcessOverviewBrickStatus item={{ id: process.id, title: process.name, status }} />
        <Box sx={sx.title}>
          <Typography noWrap sx={{ textOverflow: "ellipsis" }} variant="body2">
            {getBoldSearchText({ title: name, search })}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

interface ProcessGroupByRowProps {
  readonly search: string;
  readonly checked: boolean;
  readonly id: string;
  readonly name: string;
  readonly emptyTooltip?: string;
  readonly selectedIds: string[];
  readonly selectableIds: string[];
  readonly processes: ProcessesPickerModalOptionProps[];

  readonly onChecked?: (id: string, force?: boolean) => void;
  readonly onCheckedProcess: (id: string, force?: boolean) => void;
}

const ProcessGroupByRow = ({
  search,
  id,
  name,
  checked,
  emptyTooltip,
  selectedIds,
  selectableIds,
  processes,

  onChecked,
  onCheckedProcess
}: ProcessGroupByRowProps) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(search !== "");
  }, [search]);

  const filteredOptions = useMemo(() => {
    const searchLowerCase = search?.trim().toLowerCase() || "";
    return processes.filter(
      process =>
        process.name.toLowerCase().indexOf(searchLowerCase) > -1 || name.toLowerCase().indexOf(searchLowerCase) > -1
    );
  }, [search, processes, name]);

  const onCheckedCallback = useCallback(
    event => {
      stopEvent(event);

      // check group
      onChecked?.(id);

      processes.forEach(p => {
        onCheckedProcess(p.id, !checked);
      });
    },
    [checked, id, onChecked, onCheckedProcess, processes]
  );

  const onExpandCallback = useCallback(() => {
    if (!filteredOptions.length) {
      return;
    }
    setExpanded(current => !current);
  }, [filteredOptions.length]);

  const indeterminate = useMemo(
    () => !checked && filteredOptions.some(({ id }) => selectedIds.includes(id)),
    [checked, filteredOptions, selectedIds]
  );

  if (search && !filteredOptions.length && name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
    return <></>;
  }

  return (
    <Box>
      <Tooltip title={filteredOptions.length ? "" : emptyTooltip}>
        <Box sx={sx.row} onClick={onExpandCallback}>
          <IconButton size="small" disabled={!filteredOptions.length}>
            {expanded ? <ExpandMoreIcon /> : <ChevronRight />}
          </IconButton>
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onClick={onCheckedCallback}
            disabled={!filteredOptions.length}
          />
          <Typography noWrap sx={{ flex: 1 }}>
            {getBoldSearchText({ title: name, search })}
          </Typography>
          <Tooltip title={t("totalCount")}>
            <Typography
              variant="caption"
              sx={{ ...sx.totalCount, ...(filteredOptions.length ? sx.totalCountNonZero : sx.totalCountZero) }}
            >
              {filteredOptions.length || 0}
            </Typography>
          </Tooltip>
        </Box>
      </Tooltip>
      <Box sx={{ ...sx.children, display: expanded ? "block" : "none" }}>
        {filteredOptions.map(process => (
          <ProcessRow
            key={process.id}
            process={process}
            search={search}
            checked={selectedIds.includes(process.id)}
            disabled={!(selectableIds || []).includes(process.id)}
            pl={2}
            onChecked={onCheckedProcess}
          />
        ))}
      </Box>
    </Box>
  );
};

export interface ProcessesPickerModalOptionProps {
  readonly id: string;
  readonly name: string;
  readonly status: string;
  readonly labels: string[];
}

interface ProcessesPickerModalProps {
  readonly allowAdd: boolean;
  readonly open: boolean;
  readonly options: ProcessesPickerModalOptionProps[];
  readonly selectedIds: string[];
  readonly selectableIds: string[];
  readonly onAdd: (id: string) => void;
  readonly onCancel: () => void;
  readonly onConfirm: (ids: string[]) => void;
}
const ProcessesPickerModal = ({
  allowAdd,
  open,
  onAdd,
  options,
  selectedIds,
  selectableIds,
  onCancel,
  onConfirm
}: ProcessesPickerModalProps) => {
  const { t } = useTranslation("processPickerModal");
  const { translateById, resources } = useResources();

  const [search, setSearch] = useState<string>("");
  const [groupBy, setGroupBy] = useState<"label" | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPaIds, setSelectedPaIds] = useState<string[]>([]);
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedPaIds(selectedIds);
  }, [selectedIds]);

  const filteredOptions = useMemo(() => {
    const searchLowerCase = search.trim().toLowerCase();
    return options?.filter(({ name }) => {
      return name?.toLowerCase().indexOf(searchLowerCase) > -1;
    });
  }, [options, search]);

  /* RESET DIALOG */
  const cleanUp = useCallback(() => {
    setSearch("");
    setGroupBy(null);
    setSelectedPaIds([]);
    setSelectedLabelIds([]);
  }, []);

  /* GROUP BY EL */
  const onSetGroupByLabel = useCallback(() => {
    setGroupBy(groupBy === "label" ? null : "label");
    setSearch("");
  }, [groupBy]);
  const groupByEl = useMemo(() => {
    return (
      <Box sx={sx.groupBy}>
        <Tooltip title={groupBy === "label" ? t("unGroupByLabel") : t("groupByLabel")}>
          <Chip label={t("label")} onClick={onSetGroupByLabel} color={groupBy === "label" ? "primary" : "secondary"} />
        </Tooltip>
      </Box>
    );
  }, [groupBy, onSetGroupByLabel, t]);

  /* SEARCH EL */
  const onSearchChange = useCallback(event => {
    setSearch(event.target.value);
  }, []);
  const searchEl = useMemo(() => {
    return (
      <TextField
        sx={sx.search}
        value={search}
        onChange={onSearchChange}
        placeholder={t("common:search")}
        InputProps={{
          startAdornment: (
            <SearchOutlined
              sx={{
                color: colors.grey.grey600,
                marginRight: "8px"
              }}
            />
          )
        }}
      />
    );
  }, [onSearchChange, search, t]);

  /* ADD NEW   */
  const onAddCallback = useCallback(async () => {
    setLoading(true);
    const id = await createPAApi({ name: search });
    await onAdd?.(id);
    cleanUp();
    setLoading(false);
  }, [cleanUp, onAdd, search]);
  const addNewButtonEl = useMemo(() => {
    if (allowAdd && groupBy === null && search && filteredOptions?.length === 0) {
      return (
        <Box textAlign={"center"}>
          <Button color="primary" variant="outlined" onClick={onAddCallback}>
            {t("addNewPA")}
          </Button>
        </Box>
      );
    }

    return <></>;
  }, [allowAdd, filteredOptions?.length, groupBy, onAddCallback, search, t]);

  /* LIST ALL PAs */
  const onCheckPa = useCallback((id: string, force?: boolean) => {
    if (force !== undefined) {
      setSelectedPaIds(current => (force ? [...new Set([...current, id])] : current.filter(_id => _id !== id)));
    } else {
      setSelectedPaIds(current => (current.includes(id) ? current.filter(i => i !== id) : [...current, id]));
    }
  }, []);
  const listAllPAs = useMemo(() => {
    if (groupBy !== null) {
      return <></>;
    }
    return (
      <Box sx={sx.list}>
        {filteredOptions.map(process => (
          <ProcessRow
            key={process.id}
            process={process}
            search={search}
            checked={selectedPaIds.includes(process.id)}
            disabled={!(selectableIds || []).includes(process.id)}
            onChecked={onCheckPa}
          />
        ))}
      </Box>
    );
  }, [groupBy, filteredOptions, search, selectedPaIds, selectableIds, onCheckPa]);

  /* LIST GROUP BY LABEL */
  const labels = useMemo(() => {
    return (
      resources[RESOURCE_TYPES.LABEL]?.map(r => ({
        id: r.id,
        name: translateById(RESOURCE_TYPES.LABEL, r.id)
      })) || []
    );
  }, [resources, translateById]);
  const onCheckLabel = useCallback((id: string, force?: boolean) => {
    if (force !== undefined) {
      setSelectedLabelIds(current => (force ? [...new Set([...current, id])] : current.filter(_id => _id !== id)));
    } else {
      setSelectedLabelIds(current => (current.includes(id) ? current.filter(i => i !== id) : [...current, id]));
    }
  }, []);
  const optionLabelMap = useMemo(() => {
    return labels.reduce<Record<string, ProcessesPickerModalOptionProps[]>>(
      (acc, next) => ({ ...acc, [next.id]: options.filter(({ labels }) => labels.includes(next.id)) }),
      {}
    );
  }, [labels, options]);
  const listGroupByLabelEl = useMemo(() => {
    if (groupBy !== "label") {
      return <></>;
    } else {
      return (
        <Box sx={sx.list}>
          {labels.map(({ id, name }) => (
            <ProcessGroupByRow
              key={id}
              search={search}
              id={id}
              name={name}
              onChecked={onCheckLabel}
              onCheckedProcess={onCheckPa}
              checked={selectedLabelIds.includes(id)}
              selectedIds={selectedPaIds}
              selectableIds={selectableIds}
              emptyTooltip={t("noPaLabel")}
              processes={optionLabelMap[id] || []}
            />
          ))}
        </Box>
      );
    }
  }, [
    groupBy,
    labels,
    search,
    onCheckLabel,
    onCheckPa,
    selectedLabelIds,
    selectedPaIds,
    selectableIds,
    t,
    optionLabelMap
  ]);

  /* NO ITEMS */
  const noItemsEl = useMemo(() => {
    if (groupBy === null || search === "" || loading) {
      return <></>;
    } else {
      const anyAllPAs = options.some(o => o.name.toLowerCase().includes(search.toLowerCase()));
      if (anyAllPAs) return <></>;

      const anyLabels = labels.some(o => o.name.toLowerCase().includes(search.toLowerCase()));
      if (anyLabels) return <></>;

      return <OverviewNoItemsFound align={"center"} />;
    }
  }, [groupBy, labels, loading, options, search]);

  const bodyEl = useMemo(() => {
    return (
      <Box sx={sx.root}>
        {groupByEl}
        {searchEl}
        {noItemsEl}
        {addNewButtonEl}
        {listAllPAs}
        {listGroupByLabelEl}
      </Box>
    );
  }, [groupByEl, searchEl, noItemsEl, addNewButtonEl, listAllPAs, listGroupByLabelEl]);

  /* ACTIONS */
  const onCancelCallback = useCallback(() => {
    cleanUp();
    onCancel?.();
  }, [cleanUp, onCancel]);

  const onConfirmCallback = useCallback(async () => {
    setLoading(true);
    await onConfirm?.(selectedPaIds);
    cleanUp();
    setLoading(false);
  }, [cleanUp, onConfirm, selectedPaIds]);

  const buttons: ConfirmationModalButtonProps[] = [
    {
      confirmButton: false,
      title: t("common:cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      disabled: loading,
      onClick: onCancelCallback
    },
    {
      confirmButton: true,
      title: t("common:confirm"),
      variant: "contained",
      color: "primary",
      size: "medium",
      disabled: loading,
      onClick: onConfirmCallback
    }
  ];

  if (!open) return <></>;
  return (
    <ConfirmationModal
      modalOpen={open}
      onClose={onCancel}
      modalTitle={t("collection:processes_cap")}
      modalText={""}
      buttons={buttons}
      modalBody={bodyEl}
      variant={"info"}
    />
  );
};

export default ProcessesPickerModal;
