import React, { useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Grid } from "@mui/material";
import DocTitle from "components/DocTitle/DocTitle";

export default function DocViewWithBackButton({
  header,
  children,
  onBack
}: {
  header?: string;
  children?: React.ReactNode;
  onBack?: () => void;
}) {
  const theme = useTheme();

  const backCallback = useCallback(() => {
    onBack?.();
  }, [onBack]);

  return (
    <Grid
      container
      sx={{
        overflowY: "auto",
        height: "100%",
        bgcolor: theme.palette.background.paper // set background to paper color
      }}
    >
      <Grid
        item
        sx={{
          width: "60px",
          padding: "23px 0 0 0"
        }}
      >
        <IconButton
          sx={{
            display: "block",
            margin: "0 auto"
          }}
          onClick={backCallback}
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid item sx={{ marginBottom: "50px" }}>
        <Grid container spacing={0} direction="column" justifyContent="center" sx={{ padding: "30px 30px 30px 0" }}>
          <Grid item xs={12}>
            <DocTitle title={header || ""} />
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
