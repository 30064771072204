import { IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";
import { t } from "i18next";

const OverviewBrickSettings = ({ item, disabled, hovered, onClick, onBlur }: OverviewRowBrickProps) => {
  const onClickCallback = useCallback(
    event => {
      onBlur?.();
      stopEvent(event);
      onClick?.(item.id, item);
    },
    [item, onBlur, onClick]
  );

  if (!hovered) {
    return <></>;
  }
  return (
    <Tooltip title={t("websites_overview:scan")}>
      <IconButton disabled={disabled} onClick={onClickCallback} size="small">
        <PlayArrowIcon />
      </IconButton>
    </Tooltip>
  );
};

export default OverviewBrickSettings;
