import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useDataLocations } from "hook/useDataLocations";
import { getDataLocationWithExternalRecipient } from "app/utils/getDataLocationWithExternalRecipient";
import { Chip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete/Autocomplete";

interface DSRDataStorageFieldProps {
  readonly values: string[];
  readonly loading?: boolean;
}

// displays data storage locations in read-only mode
export const DSRDataStorageField = ({ values, loading }: DSRDataStorageFieldProps) => {
  const { t } = useTranslation("data_subject_requests_data_page");
  const { dataLocations } = useDataLocations();

  // get data location label with external recipient info
  const getDataLocationLabel = useCallback(
    (id: string) => {
      // create map of data locations for quick lookup inside callback
      const dataLocationsMap = new Map(dataLocations.map(location => [location.id, location]));
      return getDataLocationWithExternalRecipient(id, dataLocationsMap, t);
    },
    [dataLocations, t]
  );

  // render chips for selected data storage locations
  const renderTags = useCallback(
    (value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
      value.map((option: string, index: number) => (
        <Chip deleteIcon={<> </>} label={getDataLocationLabel(option)} {...getTagProps({ index })} key={index} />
      )),
    [getDataLocationLabel]
  );

  return (
    <MultiAutocomplete
      id="data_storages"
      selected={values}
      renderTags={renderTags}
      disabled={true} // always disabled since this is read-only
      options={values} // only show selected values since this is read-only
      getOptionLabel={getDataLocationLabel}
      hasMultiSelect={true}
      label={t("datalocations")}
      icon={loading ? undefined : undefined}
    />
  );
};
