import { ParsingOptions } from "xlsx";

export async function parseXlsxDataToJson(excelDataBlob: Blob) {
  return readXLSX(await excelDataBlob.arrayBuffer(), "array");
}

export async function readXLSX(binary: any, binaryType: ParsingOptions["type"]) {
  const { read, utils } = await import("xlsx");
  const readBinary = read(binary, { type: binaryType, bookVBA: true });
  return readBinary.SheetNames.reduce((acc: { [key: string]: any }, sheetName: string) => {
    acc[sheetName] = utils.sheet_to_json(readBinary.Sheets[sheetName]);
    return acc;
  }, {});
}

export const writeXLSX = async (input: {
  readonly sheets: {
    readonly sheetName: string;
    readonly data: object[];
    readonly columnOrder?: string[];
  }[];
}): Promise<Buffer> => {
  const { writeXLSX, utils } = await import("xlsx");
  const newFile = utils.book_new();
  for (const sheet of input.sheets) {
    const worksheet = utils.json_to_sheet(sheet.data, {
      header: sheet.columnOrder
    });
    utils.book_append_sheet(newFile, worksheet, sheet.sheetName);
  }
  return writeXLSX(newFile, {
    type: "buffer",
    bookType: "xlsx"
  });
};
