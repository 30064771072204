import { Add } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { AITechDocAssetLinkError, createAITechDocAndLinkToAsset } from "app/api/aiTechDocApi";
import { COLLECTIONS } from "app/collections";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { mutatePAAiTechDoc } from "app/pages/questionnaires/aitechdoc-page/processAITechDocApi";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

const AITechDocOverviewBrickAdd = ({ item, hovered, onBlur }: OverviewRowBrickProps) => {
  const { auth } = useAuthentication();
  const { id: paId } = useParams();
  const dispatch = useOverviewDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoadin] = useState<boolean>(false);

  const onCreateTechDoc = useCallback(async () => {
    onBlur?.();
    if (!auth?.tenantId || !item) {
      return;
    }
    setLoadin(true);
    let createdTechDocId: string;
    try {
      createdTechDocId = await createAITechDocAndLinkToAsset({
        payload: {
          name: t("process_aitechdoc_page:defaultTechDocName", { assetName: item.subTitle }),
          assigneeUID: auth.uid,
          mainOrgUnitId: item.responsibleOrgUnitId,
          furtherOrgUnitIds: item.furtherOrgUnitIds
        },
        assetId: item.assetId || "",
        tenantId: auth.tenantId,
        userId: auth.uid
      });
    } catch (e: unknown) {
      setLoadin(false);
      if (e instanceof AITechDocAssetLinkError) {
        enqueueSnackbar(t("common:missingPermission"), { variant: "error" });
        return;
      }
      throw e;
    }
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.PROCESSES_AITECHDOCS,
      reloadOverview: Date.now()
    });
    mutatePAAiTechDoc(paId || "");
    setLoadin(false);
    dispatch({
      type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
      collection: COLLECTIONS.PROCESSES_AITECHDOCS,
      selectedId: createdTechDocId
    });
  }, [onBlur, auth?.tenantId, auth?.uid, item, dispatch, paId, enqueueSnackbar]);

  if (loading) {
    return <CircularProgress />;
  } else if (item.techDocId || !hovered) {
    return <></>;
  } else {
    return (
      <Tooltip title={t("genericDocView:add")}>
        <Box>
          <IconButton onClick={onCreateTechDoc} size="small">
            <Add />
          </IconButton>
        </Box>
      </Tooltip>
    );
  }
};

export default AITechDocOverviewBrickAdd;
