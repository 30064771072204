import { useTranslation } from "react-i18next";
import Question from "../../../../components/Question/Question";
import MultiAutocomplete from "../../../../components/MultiAutocomplete/MultiAutocomplete";
import {
  tDataCategoryClassification,
  tDataTypeClassification,
  tDataTypeClassificationCategory,
  tDeletedEntry,
  tSimpleTranslate
} from "../../../handlers/dataTypeTranslatorHandler";
import AccordionMultiField from "../../../../components/AccordionMultiField/AccordionMultiField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { getPersonGroupCategoryTupleStrings, toLegacyCategoryTypeTuple } from "../utils/helper-functions";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { dataAssetClient, useDataTypeTreeManager } from "app/api/dataAssetApi";
import { useResources } from "../../../contexts/resource-context";
import { MetaViewAIInputProps, useMetaView } from "../../../contexts/meta-view-context";
import TextField from "@material-ui/core/TextField";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import { CategoryTypeTupleModel } from "../CategoryTypeTupleModel";
import ArrayDisplay from "components/ArrayDisplay";
import { DataCategory, PersonGroup } from "../../../api/generated/asset-service";
import { isEqual } from "lodash-es";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useParams } from "react-router-dom";
import { useProcessGeneralPage } from "../../../api/paApi";
import { AIAskAIButton } from "../../../../components/AIIntegration/AIAskAIButton";
import { parseDataTypeCategoryPersonGroupLine } from "./parseDataTypeCategoryPersonGroupLine";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";

export interface PersonGroupAccordionData {
  readonly id: string;
  readonly categoryTypeTuples: CategoryTypeTupleModel[];
  readonly dataOfMinors: "yes" | "no" | "unsure" | "";
  readonly personGroup: string;
  readonly potentialAmountOfDataSubjects: string;
}

type PersonGroupAccordionProps = {
  readonly personGroup: PersonGroupAccordionData;
  readonly onDelete: (id: string) => void;
  readonly onSave: (val: PersonGroupAccordionData) => Promise<void>;
  readonly isCreatedNew?: boolean;
  readonly disabled?: boolean;
};
const PersonGroupAccordion = ({ personGroup, onDelete, onSave, isCreatedNew, disabled }: PersonGroupAccordionProps) => {
  const { t } = useTranslation("questionnaires");
  const { resources } = useResources();
  const { setInfo } = useMetaView();
  const [dirty, setDirty] = useState<boolean>(false);
  const { auth } = useAuthentication();
  const userHasAssetWritePermission = auth?.permissions?.some(
    permission => permission === "asset_write_all" || permission === "asset_write_org"
  );

  const [currentPersonGroup, setCurrentPersonGroup] = useState<PersonGroupAccordionData>(personGroup || {});
  useEffect(() => {
    if (personGroup) {
      setCurrentPersonGroup(personGroup);
    }
  }, [personGroup]);

  useEffect(() => {
    const diff = Object.keys(currentPersonGroup).some(
      key =>
        !isEqual(
          currentPersonGroup[key as keyof PersonGroupAccordionData],
          personGroup[key as keyof PersonGroupAccordionData]
        )
    );
    setDirty(diff);
  }, [currentPersonGroup, personGroup]);

  const newPersonalData = useMemo(
    () => ({
      title: t("newPersonalDataTitle"),
      text: t("newPersonalDataText")
    }),
    [t]
  );

  const dataTypeTree = useDataTypeTreeManager(true);
  const dataTypeCategoryPersonGroupList = dataTypeTree.data;

  /*  Person Group Selector El */
  const updatePersonGroupId = useCallback(
    (personGroupId: string) => {
      const newPersonGroup = { ...currentPersonGroup, personGroup: personGroupId, categoryTypeTuples: [] };
      setCurrentPersonGroup(newPersonGroup);
    },
    [currentPersonGroup]
  );
  const pgGetOptionSelected = useCallback((optionItem: PersonGroup, currentCheckedItem: PersonGroup) => {
    if (!currentCheckedItem) {
      return false;
    }
    return optionItem.id === currentCheckedItem.id;
  }, []);
  const pgUpdateOptions = useCallback(
    async (personGroups: (PersonGroup | string)[]) => {
      const personGroupToAdd = personGroups[personGroups.length - 1];
      if (typeof personGroupToAdd !== "string") {
        return;
      }
      const createdId = await dataTypeTree.actions.addPersonGroup(personGroupToAdd, { populateLocalCache: true });
      updatePersonGroupId(createdId);
    },
    [dataTypeTree.actions, updatePersonGroupId]
  );
  const pgUpdateSelected = useCallback(
    (personGroup: PersonGroup | null) => {
      if (personGroup?.id) {
        updatePersonGroupId(personGroup.id);
      }
    },
    [updatePersonGroupId]
  );

  const pgGetOptionLabel = useCallback(
    (option: PersonGroup | string) => {
      if (typeof option === "string" && option.includes(t("personGroup:addText"))) {
        return option;
      }
      if (typeof option !== "string" && option.id === "deleted") {
        return tDeletedEntry({ t });
      }
      return tSimpleTranslate({
        t,
        typeCategoryOrPersonGroup: typeof option === "string" ? option : option.personGroupKey
      });
    },
    [t]
  );

  const selectedPersonGroup = useMemo(
    () =>
      currentPersonGroup.categoryTypeTuples.length > 0 &&
      toLegacyCategoryTypeTuple(currentPersonGroup, dataTypeTree.dataById)?.personGroupDeleted // if deleted
        ? {
            id: "deleted",
            personGroupKey: "",
            dataCategories: []
          }
        : dataTypeTree?.dataById?.personGroups[currentPersonGroup.personGroup],
    [currentPersonGroup, dataTypeTree.dataById]
  );

  const personGroupInfo = useMemo(
    () => ({
      title: t("personGroupInfoTitle"),
      text: t("personGroupInfoText")
    }),
    [t]
  );
  const pgOnFocusSelected = useCallback(() => {
    setInfo(personGroupInfo);
  }, [personGroupInfo, setInfo]);
  const pgOnBlur = useCallback(() => {
    setInfo(newPersonalData);
  }, [newPersonalData, setInfo]);

  const pgNewOptionEntryAlreadyExists = useCallback(
    newTypedOption => {
      const cleanedTypedOption = (newTypedOption || "").trim();
      return !!dataTypeCategoryPersonGroupList
        ?.map(listPersonGroup => listPersonGroup.personGroupKey || "")
        .map(personGroupKey => personGroupKey.trim())
        .find(
          cleanedPersonGroupKey =>
            cleanedPersonGroupKey === cleanedTypedOption ||
            tSimpleTranslate({
              t,
              typeCategoryOrPersonGroup: cleanedPersonGroupKey
            }) === cleanedTypedOption
        );
    },
    [dataTypeCategoryPersonGroupList, t]
  );

  const personGroupEl = (
    <MultiAutocomplete<PersonGroup, false, undefined, undefined>
      hasMultiSelect={false}
      getOptionLabel={pgGetOptionLabel}
      isOptionEqualToValue={pgGetOptionSelected}
      options={dataTypeCategoryPersonGroupList || emptyArray}
      updateOptions={pgUpdateOptions}
      selected={selectedPersonGroup}
      updateSelected={pgUpdateSelected}
      onFocus={pgOnFocusSelected}
      onBlur={pgOnBlur}
      disabled={disabled}
      placeholder={t("personGroup:search")}
      id="personengruppe"
      label={t("personGroup:questionTitle")}
      addText={userHasAssetWritePermission ? t("personGroup:addText") : undefined}
      newOptionEntryAlreadyExists={pgNewOptionEntryAlreadyExists}
      error={!currentPersonGroup.personGroup}
      helperText={!currentPersonGroup.personGroup ? t("questionnaires:mandatoryField") : ""}
    />
  );

  /*  Data of Minors Selector El */
  const minorityOptions = useMemo(() => ["yes", "no", "unsure"], []);
  const updatePersonGroupDataOfMinors = useCallback(
    (dataOfMinors: "yes" | "no" | "unsure" | "") => {
      const newPersonGroup = { ...currentPersonGroup, dataOfMinors: dataOfMinors };
      setCurrentPersonGroup(newPersonGroup);
    },
    [currentPersonGroup]
  );
  const [minorsInputText, setMinorsInputText] = useState("");
  const minorsUpdateSelected = useCallback(
    value => {
      updatePersonGroupDataOfMinors(value);
      setMinorsInputText(value);
    },
    [updatePersonGroupDataOfMinors]
  );
  const minorsOnInputChange = useCallback((event, value) => {
    setMinorsInputText(value);
  }, []);

  const minorsGetOptionLabel = useCallback(option => t(`minors:${option}`, option), [t]);
  const minorsInfo = useMemo(
    () => ({
      title: t("minorsInfoTitle"),
      text: t("minorsInfoText")
    }),
    [t]
  );
  const minorsOnFocus = useCallback(() => setInfo(minorsInfo), [minorsInfo, setInfo]);
  const minorsOnBlur = useCallback(() => setInfo(newPersonalData), [newPersonalData, setInfo]);
  const minorsEl = currentPersonGroup.personGroup ? (
    <Question questionId={"minors"}>
      <MultiAutocomplete
        hasMultiSelect={false}
        getOptionLabel={minorsGetOptionLabel}
        options={minorityOptions}
        selected={currentPersonGroup.dataOfMinors}
        updateSelected={minorsUpdateSelected}
        onFocus={minorsOnFocus}
        onBlur={minorsOnBlur}
        id={"minors"}
        disabled={disabled}
        label={t("minors:questionTitle")}
        onInputChange={minorsOnInputChange}
        inputValue={t(`minors:${minorsInputText}`, minorsInputText)}
      />
    </Question>
  ) : (
    <></>
  );

  /*  Data Type Selector El */
  const onChangeDataTypeIds = useCallback(
    (ids: string[]) => {
      const categoryTypeTuples = ids
        .map(id => {
          const dataType = dataTypeTree.dataById?.dataTypes[id];

          if (dataType) {
            const category = Object.values(dataTypeTree.dataById?.dataCategories || {}).find(dataCategory =>
              dataCategory.dataTypes.find(({ id }) => id === dataType.id)
            );

            if (category) {
              return {
                id: dataType.id,
                type: dataType.id,
                dataClassificationId: dataType.dataClassificationId || null,
                personGroup: currentPersonGroup.personGroup,
                category: category.id
              };
            }
            return null;
          }
          return null;
        })
        .filter(notNull => notNull) as unknown as CategoryTypeTupleModel[];
      const newPersonGroup = { ...currentPersonGroup, categoryTypeTuples };
      setCurrentPersonGroup(newPersonGroup);
    },
    [currentPersonGroup, dataTypeTree.dataById?.dataCategories, dataTypeTree.dataById?.dataTypes]
  );

  const updatePersonGroupCategoryTypeTuples = useCallback(
    categoryTypeTuples => {
      const newPersonGroup = { ...currentPersonGroup, categoryTypeTuples: categoryTypeTuples };
      setCurrentPersonGroup(newPersonGroup);
    },
    [currentPersonGroup]
  );

  const onAddDataType = useCallback(
    async ({ title }) => {
      const createdId = await dataAssetClient
        .createUncategorizedDataType(
          currentPersonGroup.personGroup,
          {
            name: title
          },
          "true"
        )
        .then(response => response.headers["x-resource-id"] as string);
      const dataType = await dataAssetClient.getDataAsset(createdId);
      await dataTypeTree.mutate();
      const categoryId = dataType?.data.parentDataAssetId;
      if (!categoryId) {
        return;
      }
      updatePersonGroupCategoryTypeTuples([
        ...(currentPersonGroup.categoryTypeTuples || []),
        {
          id: createdId,
          category: categoryId,
          personGroup: currentPersonGroup.personGroup,
          type: createdId,
          dataClassificationId: ""
        }
      ]);
    },
    [
      currentPersonGroup.categoryTypeTuples,
      currentPersonGroup.personGroup,
      dataTypeTree,
      updatePersonGroupCategoryTypeTuples
    ]
  );

  const dataTypeEl = currentPersonGroup.personGroup ? (
    <Question
      qType={QUESTION_TYPE.DATA_TYPE_TREE}
      value={currentPersonGroup.categoryTypeTuples.map(({ id }) => id)}
      personGroupId={currentPersonGroup.personGroup}
      error={!currentPersonGroup.categoryTypeTuples.length}
      helperText={!currentPersonGroup.categoryTypeTuples.length ? t("questionnaires:mandatoryField") : ""}
      onChange={onChangeDataTypeIds}
      onAdd={onAddDataType}
    />
  ) : (
    <></>
  );

  /*  Potential Amount data Subjects Selector El */
  const onChangeAmountDataSubjects = useCallback(
    event => {
      const newPersonGroup = { ...currentPersonGroup, potentialAmountOfDataSubjects: event.target.value };
      setCurrentPersonGroup(newPersonGroup);
    },
    [currentPersonGroup]
  );

  const isDataSubjectPotentialAmountPresent = useIsFeaturePresent(FEATURES.DATA_SUBJECT_POTENTIAL_AMOUNT);
  const amountDataSubjectsEl =
    isDataSubjectPotentialAmountPresent && currentPersonGroup.personGroup ? (
      <TextField
        multiline={false}
        id={"potentialAmountOfDataSubjects"}
        fullWidth={true}
        label={t("potentialAmountOfDataSubjectsLabel")}
        type="text"
        variant="outlined"
        value={currentPersonGroup.potentialAmountOfDataSubjects}
        onChange={onChangeAmountDataSubjects}
        disabled={disabled}
      />
    ) : (
      <></>
    );

  const titles = useMemo(
    () =>
      getPersonGroupCategoryTupleStrings(
        currentPersonGroup,
        resources[RESOURCE_TYPES.DATA_CLASSIFICATION],
        dataTypeTree.data,
        t,
        dataTypeTree.dataById
      ),
    [currentPersonGroup, dataTypeTree.data, dataTypeTree.dataById, resources, t]
  );

  const onClickDelete = useCallback(() => onDelete(personGroup.id), [onDelete, personGroup.id]);
  const [isSaving, setIsSaving] = useState(false);
  const onClickSave = useCallback(async () => {
    setIsSaving(true);
    try {
      await onSave(currentPersonGroup);
      setDirty(false);
    } catch (error: unknown) {
      setIsSaving(false);
      throw error;
    }
    setIsSaving(false);
  }, [currentPersonGroup, onSave]);

  const questionId = "personGroups " + currentPersonGroup.id;
  const { aiButtonEl } = useAIIntegrationForPersonGroupAccordion({
    currentPersonGroup,
    setCurrentPersonGroup,
    dataTypeTree,
    questionId: questionId
  });

  return (
    <Question
      key={currentPersonGroup.id}
      questionId={questionId}
      questionName={titles.join(", ")}
      // translatable={currentPersonGroup.potentialAmountOfDataSubjects}
    >
      <AccordionMultiField
        id={questionId}
        key={currentPersonGroup.id}
        field={"personGroups"}
        deleteButtonText={t("delete")}
        deleteMultiField={onClickDelete}
        hasCancelAndSave={true}
        onClickSave={onClickSave}
        onClickCancel={undefined}
        isNewMultiFiled={isCreatedNew}
        disableButton={disabled}
        disableOnlySaveButton={!currentPersonGroup.personGroup || currentPersonGroup.categoryTypeTuples.length === 0}
        additionalRightButton={aiButtonEl}
        loading={isSaving}
        title={
          currentPersonGroup.personGroup ? (
            <Box display="flex" alignItems="center">
              <ArrayDisplay elements={titles} />
              {isSaving && <CircularProgress size="1rem" />}
            </Box>
          ) : (
            <>
              <Typography color="textSecondary">
                <i>{t("personGroup:inputPlaceholder")}</i>
              </Typography>
            </>
          )
        }
        dirty={dirty}
      >
        <Box>
          <Box display="flex" alignItems="center" width="100%">
            <Box mr={5} flex={1}>
              <Question>{personGroupEl}</Question>
            </Box>
            <Box flex={1}>{minorsEl}</Box>
          </Box>
          <Box mb={3}>{dataTypeEl}</Box>
          <Box>{amountDataSubjectsEl}</Box>
        </Box>
      </AccordionMultiField>
    </Question>
  );
};

const emptyArray: PersonGroup[] = [];

const useAIIntegrationForPersonGroupAccordion = ({
  currentPersonGroup,
  setCurrentPersonGroup,
  dataTypeTree,
  questionId
}: {
  currentPersonGroup: PersonGroupAccordionData;
  setCurrentPersonGroup: (currentPersonGroup: PersonGroupAccordionData) => void;
  dataTypeTree: ReturnType<typeof useDataTypeTreeManager>;
  questionId?: string;
}) => {
  const { t } = useTranslation();
  const {
    questionId: metaviewQuestionId,
    setAIIntegrationSuggestionCallback,
    setAIIntegrationMode,
    setAIIntegrationFieldInput
  } = useMetaView();
  const params = useParams();
  const aiIntegrationEnabled = useIsFeaturePresent(FEATURES.AI_INTEGRATION);
  const isDataSubjectPotentialAmountPresent = useIsFeaturePresent(FEATURES.DATA_SUBJECT_POTENTIAL_AMOUNT);
  const generalPage = useProcessGeneralPage({ paId: aiIntegrationEnabled ? params.id : undefined });
  const personGroupAIFieldInput = useMemo(() => {
    const fields: {
      field: string;
      translated: string;
    }[] = [
      { field: "personGroupName", translated: t("personGroup:questionTitle") },
      { field: "dataOfMinorAsSmallLetterYesNoUnsureEnum", translated: t("minors:questionTitle") },
      {
        field: "dataTypeWithDataCategoryInSquareBracketAsArrayStrings",
        translated: t("questionnaires:dataCategoryDataTypeLabel")
      }
    ];
    if (isDataSubjectPotentialAmountPresent) {
      fields.push({
        field: "potentialAmountOfDataSubjects",
        translated: t("questionnaires:potentialAmountOfDataSubjectsLabel")
      });
    }

    const initialSuggestions: string[] = [];
    if (generalPage?.data?.processMeta?.title) {
      initialSuggestions.push(generalPage.data.processMeta.title);
    }
    if (currentPersonGroup?.personGroup) {
      const personGroupNameKey = dataTypeTree?.dataById?.personGroups[currentPersonGroup.personGroup]?.personGroupKey;
      if (personGroupNameKey) {
        initialSuggestions.push(tSimpleTranslate({ t, typeCategoryOrPersonGroup: personGroupNameKey }));
      }
    }
    return {
      fields: fields.map(field => field.field),
      initialSuggestions: initialSuggestions,
      parseOutput: response => {
        return (
          <Box>
            {fields.flatMap(field => {
              const hasValue = field.field in response;
              if (!hasValue) {
                return [];
              }
              const value = (response as any)[field.field];
              if (!value) {
                return [];
              }
              const title = field.translated;
              if (typeof value === "string") {
                // if it's not minors value, then it will just return original value
                const translatedValue = t(`minors:${value}`, value);
                return (
                  <Box key={field.field} mb={1}>
                    <strong>{title}:</strong> {translatedValue}
                  </Box>
                );
              }
              if (Array.isArray(value) && value.length > 0) {
                return (
                  <Box key={field.field} mb={1}>
                    <strong>{title}:</strong>
                    <ul>
                      {value.map((item: string) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </Box>
                );
              }
              return [];
            })}
          </Box>
        );
      }
    } satisfies MetaViewAIInputProps;
  }, [
    isDataSubjectPotentialAmountPresent,
    generalPage?.data?.processMeta?.title,
    currentPersonGroup?.personGroup,
    dataTypeTree?.dataById?.personGroups,
    t
  ]);

  const { addPersonGroup, addDataCategory, addDataType } = dataTypeTree?.actions || {};
  const onSuggestionCallback = useCallback(
    async (suggestions: object, originalInput: MetaViewAIInputProps) => {
      const originalFieldKeys = new Set(originalInput?.fields || []);
      const suggestionWithValidKeys = Object.entries(suggestions || {})
        .filter(([key]) => originalFieldKeys.has(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      let updatedPersonGroup: PersonGroupAccordionData = { ...currentPersonGroup };
      if (
        "personGroupName" in suggestionWithValidKeys &&
        suggestionWithValidKeys.personGroupName &&
        typeof suggestionWithValidKeys.personGroupName === "string"
      ) {
        const personGroupName = suggestionWithValidKeys.personGroupName;
        const existingPersonGroup = dataTypeTree?.data?.find(pg => {
          return (
            pg.personGroupKey === personGroupName ||
            tSimpleTranslate({
              t,
              typeCategoryOrPersonGroup: pg.personGroupKey
            }) === personGroupName
          );
        });
        if (existingPersonGroup) {
          updatedPersonGroup = {
            ...updatedPersonGroup,
            personGroup: existingPersonGroup.id || "",
            categoryTypeTuples: []
          };
        } else {
          const createdId = await addPersonGroup(personGroupName, {
            populateLocalCache: true,
            detectConflict: true
          });
          updatedPersonGroup = {
            ...updatedPersonGroup,
            personGroup: createdId,
            categoryTypeTuples: []
          };
        }
      }

      if (
        "dataOfMinorAsSmallLetterYesNoUnsureEnum" in suggestionWithValidKeys &&
        suggestionWithValidKeys.dataOfMinorAsSmallLetterYesNoUnsureEnum &&
        typeof suggestionWithValidKeys.dataOfMinorAsSmallLetterYesNoUnsureEnum === "string"
      ) {
        const value = suggestionWithValidKeys.dataOfMinorAsSmallLetterYesNoUnsureEnum;
        if (value === "yes" || value === "no" || value === "unsure") {
          updatedPersonGroup = {
            ...updatedPersonGroup,
            dataOfMinors: value
          };
        }
      }

      if (
        updatedPersonGroup.personGroup &&
        "dataTypeWithDataCategoryInSquareBracketAsArrayStrings" in suggestionWithValidKeys &&
        suggestionWithValidKeys.dataTypeWithDataCategoryInSquareBracketAsArrayStrings &&
        Array.isArray(suggestionWithValidKeys.dataTypeWithDataCategoryInSquareBracketAsArrayStrings)
      ) {
        const updatedTypeTuples: CategoryTypeTupleModel[] = [];
        const validStringValues = suggestionWithValidKeys.dataTypeWithDataCategoryInSquareBracketAsArrayStrings.filter(
          item => typeof item === "string"
        );

        const personGroupId = updatedPersonGroup.personGroup;
        const dataCategories = dataTypeTree.data?.find(pg => pg.id === personGroupId)?.dataCategories || [];
        const cachedDataCategory = new Map<string, DataCategory>();
        for (const value of validStringValues) {
          const { dataTypeName, dataCategoryName } = parseDataTypeCategoryPersonGroupLine(value);
          let existingDataCategory =
            cachedDataCategory.get(dataCategoryName) ||
            dataCategories.find(
              dc =>
                dc.dataCategoryKey === dataCategoryName ||
                tSimpleTranslate({
                  t,
                  typeCategoryOrPersonGroup: dc.dataCategoryKey
                }) === dataCategoryName
            );
          if (!existingDataCategory) {
            const dataCategoryId = await addDataCategory({
              dataCategory: dataCategoryName,
              personGroupId: updatedPersonGroup.personGroup || "",
              populateLocalCache: true,
              detectConflict: true
            });
            existingDataCategory = {
              id: dataCategoryId,
              dataCategoryKey: dataCategoryName,
              dataTypes: []
            };
          }
          cachedDataCategory.set(dataCategoryName, existingDataCategory);

          let existingDataType = existingDataCategory.dataTypes.find(
            dt =>
              dt.dataTypeKey === dataTypeName ||
              tSimpleTranslate({
                t,
                typeCategoryOrPersonGroup: dt.dataTypeKey
              }) === dataTypeName
          );
          if (!existingDataType) {
            const createdDataTypeId = await addDataType({
              personGroupId: updatedPersonGroup.personGroup || "",
              dataCategoryId: existingDataCategory.id || "",
              dataType: dataTypeName,
              populateLocalCache: true,
              detectConflict: true
            });
            existingDataType = {
              id: createdDataTypeId,
              dataTypeKey: dataTypeName
            };
            existingDataCategory.dataTypes.push(existingDataType);
          }
          updatedTypeTuples.push({
            id: existingDataType.id,
            category: existingDataCategory.id || "",
            personGroup: personGroupId,
            type: existingDataType.id,
            dataClassificationId: existingDataType.dataClassificationId || null
          });
        }
        updatedPersonGroup = {
          ...updatedPersonGroup,
          categoryTypeTuples: updatedTypeTuples
        };
      }

      if (
        "potentialAmountOfDataSubjects" in suggestionWithValidKeys &&
        suggestionWithValidKeys.potentialAmountOfDataSubjects &&
        typeof suggestionWithValidKeys.potentialAmountOfDataSubjects === "string"
      ) {
        const value = suggestionWithValidKeys.potentialAmountOfDataSubjects;
        updatedPersonGroup = {
          ...updatedPersonGroup,
          potentialAmountOfDataSubjects: value
        };
      }

      setCurrentPersonGroup(updatedPersonGroup);
    },
    [currentPersonGroup, addPersonGroup, addDataType, addDataCategory, dataTypeTree?.data, setCurrentPersonGroup, t]
  );

  const aiButtonEl = aiIntegrationEnabled ? (
    <AIAskAIButton fieldInput={personGroupAIFieldInput} onSuggestionCallback={onSuggestionCallback} />
  ) : undefined;

  useEffect(() => {
    if (metaviewQuestionId === questionId) {
      setAIIntegrationMode("suggest-field");
      setAIIntegrationFieldInput(personGroupAIFieldInput);
      setAIIntegrationSuggestionCallback(() => onSuggestionCallback);
      return () => {
        setAIIntegrationMode(null);
        setAIIntegrationFieldInput({ fields: [] });
        setAIIntegrationSuggestionCallback(undefined);
      };
    }
  }, [
    metaviewQuestionId,
    onSuggestionCallback,
    personGroupAIFieldInput,
    questionId,
    setAIIntegrationFieldInput,
    setAIIntegrationMode,
    setAIIntegrationSuggestionCallback
  ]);

  return {
    aiButtonEl
  };
};

export default PersonGroupAccordion;
