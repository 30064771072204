import React from "react";
import { Box, Typography } from "@mui/material";

const HomeHeader = ({ subtitle }: { subtitle: string }) => (
  <Box pb={4}>
    <Typography sx={{ fontSize: "16px", fontFamily: "SuisseIntl" }}>{subtitle}</Typography>
  </Box>
);

export default HomeHeader;
