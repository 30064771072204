import MetaView, { META_VIEW_TABS, META_VIEW_TABS_TYPES } from "components/MetaView/MetaView";
import DocMetaView from "components/DocMetaView/DocMetaView";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuditDetailDTO, getAuditDetail, patchAuditDetail } from "app/api/auditApi";
import DocView from "components/DocView/DocView";
import { Box, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import { AssessmentDetails } from "./pages/AssessmentDetails";
import { AssessmentResponses } from "./pages/AssessmentResponses";
import { AUDIT_METHODOLOGY, AUDIT_METHODOLOGY_TYPES, AUDIT_PAGE, AUDIT_STATUS } from "../audit/AuditTypes";
import { useMetaView } from "app/contexts/meta-view-context";
import { useTasks } from "app/contexts/tasks-context";
import CompletedIcon from "../../../../assets/images/assessment/statuses/completed.svg";
import DraftIcon from "../../../../assets/images/assessment/statuses/draft.svg";
import EditIcon from "../../../../assets/images/assessment/statuses/in-progress.svg";
import ReviewIcon from "../../../../assets/images/assessment/statuses/in-review.svg";
import AssessmentIcon from "../../../../assets/images/assessment/assessment.svg";
import SelfAssessmentIcon from "@mui/icons-material/AssignmentIndOutlined";
import InterviewIcon from "@mui/icons-material/AssignmentOutlined";
import { AssessmentQuestionnaire } from "./pages/AssessmentQuestionnaire";
import { AuditSummary } from "../audit/pages/AuditSummary";
import { useDocumentName } from "app/contexts/document-name-context";
import Stepper, { StepperItemProps } from "components/Stepper/Stepper";

const assessmentPaths = [AUDIT_PAGE.GENERAL, AUDIT_PAGE.QUESTIONNAIRE, AUDIT_PAGE.RESPONSES, AUDIT_PAGE.SUMMARY];

const Assessment = () => {
  const { t } = useTranslation("audit_details");
  const id = useParams()?.id || "";
  const { page } = useParams();
  const [audit, setAudit] = useState<Partial<AuditDetailDTO> | null>(null);
  const [auditName, setAuditName] = useState<string>("");
  const [activeStep, setActiveStep] = useState<StepperItemProps | null>(null);
  const [activePage, setActivePage] = useState<string>("");
  const { setDocumentName } = useDocumentName();
  const navigate = useNavigate();
  const [documentNotFound, setDocumentNotFound] = useState<boolean>(false);
  const { reloadDocRelatedTasks } = useTasks();
  const [metaViewContent, setMetaViewContent] = useState<React.ReactNode>(<></>);
  const { setInfo, setQuestionId, setSelectedTab } = useMetaView();
  const noWritePermission = useMemo(() => !audit?.pagesWithWrite?.includes?.("general"), [audit?.pagesWithWrite]);

  const reloadAuditCallback = useCallback(async () => {
    if (!id) {
      setAudit(null);
      setDocumentNotFound(true);
      return;
    }

    const auditDetail = await getAuditDetail({ id: id });
    if (auditDetail) {
      setAudit(auditDetail);
      setAuditName(auditDetail?.title || "");
      setDocumentName(auditDetail?.title || "");
      setDocumentNotFound(false);
      reloadDocRelatedTasks();
    } else {
      setDocumentNotFound(true);
    }
  }, [id, reloadDocRelatedTasks, setDocumentName]);

  const updateQuestionnaireMetaView = useCallback((value: React.ReactNode) => {
    setMetaViewContent(value);
  }, []);

  const pagePermission = useCallback(
    (page: string) => {
      if (page === AUDIT_PAGE.GENERAL) return !audit?.pages?.includes?.("general");
      else if (page === AUDIT_PAGE.QUESTIONNAIRE) return !audit?.pages?.includes?.("template");
      else if (page === AUDIT_PAGE.RESPONSES) return !audit?.pages?.includes?.("questionnaire");
      else if (page === AUDIT_PAGE.SUMMARY) return !audit?.pages?.includes?.("result");
      else return true;
    },
    [audit?.pages]
  );

  const steps = useMemo(() => {
    return assessmentPaths
      .filter(step => pagePermission(step) === false)
      .map((step, index) => ({
        label: t(step),
        path: step,
        number: index + 1,
        selected: step === page,
        disabled: pagePermission(step)
      }));
  }, [page, pagePermission, t]);

  useEffect(() => {
    const pageFromParam = steps.find(({ path }) => path === page);
    setActiveStep(pageFromParam || steps[0]);
    setActivePage(pageFromParam?.label || "");
  }, [page, steps]);

  useEffect(() => {
    reloadAuditCallback();
  }, [reloadAuditCallback]);

  const onSwitchPage = useCallback(
    (path: string) => {
      setQuestionId(null);
      setSelectedTab(META_VIEW_TABS.ASSISTANT);
      navigate(`/audits/instances/${id}/${path}`);
    },
    [id, navigate, setQuestionId, setSelectedTab]
  );
  const onStepClick = useCallback(
    ({ path }) => {
      setQuestionId(null);
      setSelectedTab(META_VIEW_TABS.ASSISTANT);
      onSwitchPage(path || "general");
    },
    [setQuestionId, setSelectedTab, onSwitchPage]
  );
  const onNextClick = useCallback(() => {
    if (!page) {
      return;
    }
    const currentIndex = steps
      .map(({ path }) => path)
      .indexOf(page as "general" | "questionnaire" | "responses" | "summary");
    if (currentIndex < 0) {
      return;
    }
    const nextPage = steps[currentIndex + 1];
    if (!nextPage) {
      return;
    }
    onSwitchPage(nextPage.path);
  }, [onSwitchPage, page, steps]);
  const steppersEl = <Stepper steps={steps} onClick={onStepClick} />;

  /* TITLE */
  const onDocTitleFocus = useCallback(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t(`titleInfoCardContent`)
    });
  }, [setInfo, t]);

  const onTitleChange = useCallback(
    async title => {
      await patchAuditDetail({ id: id, payload: { title } });
      await reloadAuditCallback();
    },
    [id, reloadAuditCallback]
  );

  /* STATUS */
  const onStatusChange = useCallback(
    async status => {
      await patchAuditDetail({ id: id, payload: { status } });
      const updatedAudit: Partial<AuditDetailDTO> = { ...audit, status: status };
      setAudit(updatedAudit);
    },
    [id, audit]
  );
  const statusIconMapper = useMemo(
    () => ({
      [AUDIT_STATUS.COMPLETED]: <CompletedIcon />,
      [AUDIT_STATUS.DRAFT]: <DraftIcon />,
      [AUDIT_STATUS.EDIT]: <EditIcon />,
      [AUDIT_STATUS.REVIEW]: <ReviewIcon />
    }),
    []
  );
  const statuses = useMemo(() => {
    return Object.values(AUDIT_STATUS).map(status => ({
      id: status,
      label: t(`audit_status:${status.toLowerCase()}`),
      icon: statusIconMapper[status]
    }));
  }, [statusIconMapper, t]);

  /* DOC PROPERTIES */
  const docProperties = useMemo(() => {
    return [
      { icon: <AssessmentIcon />, label: t("assessment_answerset:title") },
      audit?.methodology === AUDIT_METHODOLOGY.INTERVIEW
        ? { icon: <InterviewIcon />, label: t("audit_methodologies:interview") }
        : { icon: <SelfAssessmentIcon />, label: t("audit_methodologies:self-assessment") }
    ];
  }, [audit?.methodology, t]);

  /* METAVIEW TABS */
  const metaviewTabs = useMemo(() => {
    if (audit) {
      const allTabs: META_VIEW_TABS_TYPES[] = [META_VIEW_TABS.ASSISTANT];
      const step = activeStep?.path;

      if (step === AUDIT_PAGE.GENERAL) {
        allTabs.push(META_VIEW_TABS.TODOS);
        allTabs.push(META_VIEW_TABS.COMMENTS);
      }
      return allTabs;
    } else return [];
  }, [activeStep, audit]);

  return (
    <DocMetaView
      loading={audit === null}
      notFound={documentNotFound}
      collection={COLLECTIONS.AUDITS}
      metaViewContent={
        activePage === "questionnaire" && metaViewContent ? (
          metaViewContent
        ) : (
          <MetaView
            collection={COLLECTIONS.AUDITS}
            translationKey={"audit_details"}
            tabs={metaviewTabs}
            docId={id || ""}
          />
        )
      }
    >
      <DocView
        pagination={steppersEl}
        header={auditName}
        statusId={audit?.status}
        statuses={statuses}
        docProperties={docProperties}
        onHeaderChange={onTitleChange}
        onStatusChange={onStatusChange}
        disabled={noWritePermission}
        onHeaderFocus={onDocTitleFocus}
      >
        {activeStep?.path === AUDIT_PAGE.GENERAL && <AssessmentDetails audit={audit} onUpdate={reloadAuditCallback} />}
        {activeStep?.path === AUDIT_PAGE.QUESTIONNAIRE && (
          <AssessmentQuestionnaire
            onUpdate={reloadAuditCallback}
            auditId={id}
            updateQuestionnaireMetaView={updateQuestionnaireMetaView}
            onClickNext={onNextClick}
          />
        )}
        {activeStep?.path === AUDIT_PAGE.RESPONSES && (
          <AssessmentResponses
            auditId={id}
            auditMethodology={audit?.methodology as AUDIT_METHODOLOGY_TYPES}
            onClickNext={onNextClick}
          />
        )}
        {activeStep?.path === AUDIT_PAGE.SUMMARY && <AuditSummary auditId={id} />}
        <Box mt={5} display="flex" justifyContent="flex-end">
          {activeStep?.path !== "summary" && (
            <Button variant="contained" color="primary" onClick={onNextClick}>
              {t("common:nextContinue")}
            </Button>
          )}
        </Box>
      </DocView>
    </DocMetaView>
  );
};

export default Assessment;
