import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { Box, Button } from "@mui/material";
import { TaskInfoLabel } from "./TaskInfoLabel";
import { useTranslation } from "react-i18next";
import TextEditor from "./TaskTextEditor";
import NotesIcon from "@mui/icons-material/Notes";
import stopEvent from "tool/stopEvent";

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface TaskDescriptionProps {
  readonly docId: string;
  readonly readOnly?: boolean;
  readonly text: string | null;
  readonly onChangeDescription: (description: string) => void;
}

const TaskDescription = ({ docId, onChangeDescription, readOnly, text }: TaskDescriptionProps) => {
  const { t } = useTranslation("task_details");

  const [description, setDescription] = useState(text || "");
  const [initialDescription, setInitialDescription] = useState(text || "");
  const [isEditing, setIsEditing] = useState(false);
  const previousDocId = usePrevious(docId);

  useEffect(() => {
    setDescription(text || "");
    if (docId !== previousDocId) {
      setIsEditing(false);
      setInitialDescription(text || "");
    }
  }, [docId, previousDocId, text]);

  const focusEditor = useCallback(() => {
    const container = document.getElementById("task-description");
    const editor = container?.querySelector(".rdw-editor-wrapper");
    if (editor) {
      const contentEditableElement = editor.querySelector('[contenteditable="true"]') as HTMLElement;
      contentEditableElement?.focus();
    }
  }, []);

  const releaseEditorFocus = useCallback(() => {
    const container = document.getElementById("task-description");
    const editor = container?.querySelector(".rdw-editor-wrapper");
    if (editor) {
      const contentEditableElement = editor.querySelector('[contenteditable="true"]') as HTMLElement;
      contentEditableElement?.blur();
    }
  }, []);

  const openEditor = useCallback(() => {
    if (!readOnly) {
      setIsEditing(true);
      setInitialDescription(description);
      setTimeout(() => focusEditor(), 0);
    }
  }, [description, focusEditor, readOnly]);

  const onSave = useCallback(
    event => {
      stopEvent(event);
      setIsEditing(false);
      if (description) onChangeDescription(description);
    },
    [description, onChangeDescription]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
        stopEvent(event);
        releaseEditorFocus();
        onSave(event);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onSave, releaseEditorFocus]);

  const onChange = useCallback(
    newDescription => {
      setDescription(newDescription);
      onChangeDescription(newDescription);
    },
    [onChangeDescription]
  );

  const onDismiss = useCallback(
    event => {
      stopEvent(event);
      setIsEditing(false);
      setDescription(initialDescription);
    },
    [initialDescription]
  );

  const isDescriptionEmpty = useMemo(() => {
    if (isEditing) return false;
    if (description && description[0] === "{") {
      const parsedDescription = JSON.parse(description);
      return (
        parsedDescription?.blocks
          ?.map((block: { text: string }) => block.text)
          .join("")
          .trim() === ""
      );
    }
    return description === "";
  }, [description, isEditing]);

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        mb: 2,
        mt: 2,
        "& .rdw-editor-wrapper": { border: "none" },
        "& .rdw-editor-main": {
          caretColor: "primary.main",
          padding: 1,
          borderRadius: "6px",
          "&:hover": readOnly ? "none" : { cursor: "pointer", backgroundColor: "grey.100" }
        },
        "& .public-DraftStyleDefault-block": { margin: 0 }
      }}
    >
      <TaskInfoLabel icon={NotesIcon} text={t("task_details:description")} />
      <Box
        ml={-1}
        onClick={openEditor}
        sx={{
          width: "100%"
        }}
        id="task-description"
      >
        {(!isDescriptionEmpty || isEditing) && (
          <TextEditor
            className={isEditing ? "edit" : readOnly ? "view" : "view hoverGrayBg"}
            disabled={!isEditing}
            inputValue={description}
            testId={"description-text-editor"}
            isEditing={isEditing}
            onChange={onChange}
          />
        )}
        {!isDescriptionEmpty && isEditing && (
          <Box p={1}>
            <Button onClick={onSave} variant="outlined" color="primary">
              {t("common:save")}
            </Button>
            <Button onClick={onDismiss} variant="text" color="primary" sx={{ ml: 1 }}>
              {t("common:dismiss")}
            </Button>
          </Box>
        )}
        {isDescriptionEmpty && !isEditing && (
          <Box
            onClick={openEditor}
            p={1}
            ml={-0.5}
            pb={1}
            sx={{
              alignSelf: "center",
              borderRadius: "6px",
              color: "grey.400",
              cursor: readOnly ? "default" : "pointer",
              display: "flex",
              "&:hover": !readOnly ? { backgroundColor: "grey.100" } : undefined
            }}
          >
            {readOnly ? t("task_details:no_description_added") : `${t("task_details:add_description")}...`}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { TaskDescription };
