import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import GeneralPageStepper, { PageStep } from "../../../components/Pagination/GeneralPageStepper";
import GeneralPageButtonPagination from "../../../components/Pagination/GeneralPageButtonPagination";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../features";

function usePaginationSteps() {
  const { t } = useTranslation();
  const assetRisksFeatureEnabled = useIsFeaturePresent(FEATURES.ASSET_RISKS);
  const assetProcessesFeatureEnabled = useIsFeaturePresent(FEATURES.ASSET_PROCESSES);
  const assetDLsFeatureEnabled = useIsFeaturePresent(FEATURES.ASSET_DLS);
  const assetERsFeatureEnabled = useIsFeaturePresent(FEATURES.ASSET_ERS);
  const [steps, setSteps] = useState<PageStep[]>([]);

  useEffect(() => {
    setSteps(
      [
        {
          label: t("asset_details:tabGeneral"),
          path: "general"
        },
        assetProcessesFeatureEnabled && {
          label: t("asset_details:tabProcess"),
          path: "asset-processes"
        },
        assetRisksFeatureEnabled && {
          label: t("asset_details:tabRisks"),
          path: "risks"
        },
        assetERsFeatureEnabled && {
          label: t("asset_details:tabExternalRecipients"),
          path: "external-recipients"
        },
        assetDLsFeatureEnabled && {
          label: t("asset_details:tabDataLocations"),
          path: "data-locations"
        }
      ].flatMap(step => (step ? [step] : []))
    );
  }, [assetRisksFeatureEnabled, assetProcessesFeatureEnabled, t, assetERsFeatureEnabled, assetDLsFeatureEnabled]);

  return {
    steps
  };
}

export function AssetPageStepper() {
  const { steps } = usePaginationSteps();

  return <GeneralPageStepper steps={steps} />;
}

export function AssetPageButtons({ rightArea }: { readonly rightArea?: React.ReactNode }) {
  const { steps } = usePaginationSteps();

  return <GeneralPageButtonPagination steps={steps} rightArea={rightArea} />;
}
