import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import CustomAlert from "components/CustomAlert/CustomAlert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Accordion, AccordionSummary, Box, Button, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AccordionDetails from "@mui/material/AccordionDetails";
import EditIcon from "@mui/icons-material/Edit";

export interface AccordionMultiFieldProps {
  id: string;
  title: React.ReactNode;
  placeholder?: string;
  index?: number | string;
  field?: string;
  isNewMultiFiled?: boolean;
  deleteMultiField?: (index: number | string | undefined, field: string | undefined) => void;
  deleteButtonText?: string;
  deleteButtonHint?: string;
  cancelButtonText?: string;
  saveButtonText?: string;
  disableButton?: boolean;
  disableOnlySaveButton?: boolean;
  onClickSave?: (index: number | string | undefined) => void;
  onClickCancel?: () => void;
  children?: React.ReactNode;
  onFocus?: () => void;
  hasCancelAndSave?: boolean;
  hasDelete?: boolean;
  accordionsExpanded?: boolean;
  setAccordionsExpanded?: (expanded: boolean) => void;
  editButtonText?: string;
  onClickEdit?: () => void;
  additionalLeftButton?: React.ReactNode;
  additionalRightButton?: React.ReactNode;
  loading?: boolean;
  dirty?: boolean;
}

export default function AccordionMultiField({
  id,
  title,
  placeholder,
  index,
  field,
  isNewMultiFiled,
  deleteMultiField,
  deleteButtonText,
  deleteButtonHint,
  cancelButtonText,
  saveButtonText,
  disableButton,
  disableOnlySaveButton = false,
  onClickSave,
  onClickCancel,
  children,
  onFocus,
  hasCancelAndSave = true,
  hasDelete = true,
  accordionsExpanded,
  setAccordionsExpanded,
  editButtonText,
  onClickEdit,
  additionalLeftButton,
  additionalRightButton,
  loading,
  dirty = false
}: AccordionMultiFieldProps) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isNewMultiFiled) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [isNewMultiFiled]);

  // used for closing all accordions with a external save button
  useEffect(() => {
    if (accordionsExpanded === false) {
      setIsExpanded(false);
      setAccordionsExpanded?.(true);
    }
  }, [accordionsExpanded, setAccordionsExpanded]);

  const toggleExpanded = useCallback(() => {
    setIsExpanded(isExpanded => !isExpanded);
  }, []);

  const dirtyEl = dirty ? (
    <CustomAlert severity="error" icon={<ErrorOutlineIcon />}>
      {t("common:unsavedChanges")}
    </CustomAlert>
  ) : (
    <></>
  );

  const titleEl = (() => {
    return (
      <AccordionSummary onClick={toggleExpanded} expandIcon={<ExpandMoreIcon />}>
        <Box width="100%">
          {dirty ? (
            <Box display="flex" mb={1}>
              <Box>{dirtyEl}</Box>
              <Box flexGrow={1} />
            </Box>
          ) : (
            <></>
          )}

          <Box display={"flex"}>
            {placeholder && !title ? (
              <Box mt={0.5} color={"palette.grey[500]"} fontStyle={"italic"}>
                {placeholder}
              </Box>
            ) : (
              title
            )}
          </Box>
        </Box>
      </AccordionSummary>
    );
  })();

  const saveButtonClicked = useCallback(() => {
    setIsExpanded(false);
    onClickSave?.(index);
  }, [onClickSave, index]);

  const cancelButtonClicked = useCallback(() => {
    setIsExpanded(false);
    onClickCancel?.();
  }, [onClickCancel]);

  const onClickDelete = useCallback(() => {
    deleteMultiField?.(index, field);
    setIsExpanded(false);
  }, [deleteMultiField, index, field]);

  const deleteButton = (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<DeleteIcon />}
      onClick={onClickDelete}
      disabled={loading || disableButton}
    >
      {deleteButtonText || t("questionnaires:delete")}
    </Button>
  );

  return (
    <Box mb={3}>
      <Accordion expanded={isExpanded} id={id} onFocus={onFocus} data-testid={id}>
        {titleEl}
        <AccordionDetails>
          {children}
          <Box display="flex" mb={0.5} mt={3} alignItems="center" justifyContent="space-between">
            <Box gap={1} display="flex">
              {hasDelete && (
                <Box>
                  {deleteButtonHint && <Tooltip title={deleteButtonHint}>{deleteButton}</Tooltip>}
                  {!deleteButtonHint && deleteButton}
                </Box>
              )}
              {onClickEdit && (
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={onClickEdit}
                    disabled={loading || disableButton}
                  >
                    {editButtonText}
                  </Button>
                </Box>
              )}
              {additionalLeftButton && <Box>{additionalLeftButton}</Box>}
            </Box>

            {hasCancelAndSave && (
              <Box display="flex" gap={1}>
                {additionalRightButton && <Box>{additionalRightButton}</Box>}
                <Box>
                  <Button
                    data-testid="accordion_cancel_button"
                    variant="outlined"
                    color="primary"
                    onClick={cancelButtonClicked}
                    disabled={loading || disableButton}
                  >
                    {cancelButtonText || t("questionnaires:cancel")}
                  </Button>
                </Box>
                <Box>
                  <Button
                    data-testid="accordion_save_button"
                    variant="contained"
                    color="primary"
                    onClick={saveButtonClicked}
                    disabled={loading || disableButton || disableOnlySaveButton}
                  >
                    {saveButtonText || t("questionnaires:save")}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
