import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../../Question";
import { useTranslation } from "react-i18next";
import {
  ExternalRecipientOverviewItemDTO,
  PaginatedExternalRecipientsApiFilter,
  usePaginatedExternalRecipientsApi
} from "app/api/externalRecipientApi";
import { useResources } from "app/contexts/resource-context";
import { isEUCountryOrArea } from "app/handlers/countryHandler";
import ExternalRecipient from "app/pages/service-providers/ExternalRecipient";
import { useMetaView } from "app/contexts/meta-view-context";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useDataLocations } from "hook/useDataLocations";
import { isArray } from "lodash-es";
import DocTitle from "components/DocTitle/DocTitle";
import { useAuthentication } from "../../../../app/handlers/authentication/authentication-context";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  TextField,
  Tooltip
} from "@mui/material";
import SuggestionChips from "./../../../SuggestionChips/SuggestionChips";
import { ChipProps } from "@mui/material/Chip/Chip";
import { tDeletedEntry } from "../../../../app/handlers/dataTypeTranslatorHandler";
import ExternalRecipientsPickerModal from "app/pages/service-providers/ExternalRecipientsPickerModal";
import { Close } from "@mui/icons-material";
import stopEvent from "tool/stopEvent";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import colors from "theme/palette/colors";
import WarningIcon from "@mui/icons-material/Warning";
import PolicyIcon from "@mui/icons-material/Policy";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExternalRecipientsList from "app/pages/service-providers/ExternalRecipientsList";

const sx = {
  colorErrorIcon: {
    color: `${colors.red.red500}!important`
  },
  colorInfoIcon: {
    color: `${colors.green.green500}!important`
  },
  colorLightWarningIcon: {
    color: `${colors.orange.orange500}!important`
  },
  chipStyles: {
    maxWidth: "22em"
  },
  wrapperTags: {
    maxWidth: "-webkit-fill-available"
  }
};

const ExternalDataRecipientTag = ({
  externalRecipient,
  chipProps,
  disabled,
  onClick,
  onDelete
}: {
  readonly externalRecipient: ExternalRecipientOverviewItemDTO | null;
  readonly chipProps: ChipProps;
  readonly disabled?: boolean;
  readonly onClick: (externalRecipient: ExternalRecipientOverviewItemDTO) => void;
  readonly onDelete?: (id: string) => void;
}) => {
  const { t } = useTranslation("questionnaires");
  const { translateById } = useResources();
  const { setMeta } = useMetaView();

  const [icon, setIcon] = useState<React.ReactElement | undefined>(undefined);
  const [status, setStatus] = useState<string | null>(null);
  const tooltipTitle = useMemo(() => externalRecipient?.title || tDeletedEntry({ t }), [externalRecipient?.title, t]);

  const defineIconAndStatus = useCallback(() => {
    if (!(externalRecipient && externalRecipient.dpLocations)) {
      setIcon(<ErrorOutlineIcon sx={sx.colorLightWarningIcon} />);
      setStatus(t("incompleteInformation"));
      return;
    }

    const dataProcessingNotEu = externalRecipient.dpLocations.some(countryId => !isEUCountryOrArea(countryId));
    const externalRecipientApproved = !!externalRecipient.approvedBy;

    if (dataProcessingNotEu && !externalRecipientApproved) {
      setIcon(<WarningIcon sx={sx.colorErrorIcon} />);
      setStatus(t("nonEuNotApproved"));
    } else if (dataProcessingNotEu && externalRecipientApproved) {
      setIcon(<VerifiedUserIcon sx={sx.colorInfoIcon} />);
      setStatus(t("nonEuApproved"));
    } else if (!dataProcessingNotEu && !externalRecipientApproved) {
      setIcon(<PolicyIcon sx={sx.colorLightWarningIcon} />);
      setStatus(t("euNotApproved"));
    } else if (!dataProcessingNotEu && externalRecipientApproved) {
      setIcon(<VerifiedUserIcon sx={sx.colorInfoIcon} />);
      setStatus(t("euApproved"));
    } else {
      setIcon(<ErrorOutlineIcon sx={sx.colorLightWarningIcon} />);
      setStatus(t("incompleteInformation"));
    }
  }, [externalRecipient, t]);

  useEffect(() => {
    defineIconAndStatus();
  }, [defineIconAndStatus, externalRecipient]);

  const onMouseOverCallback = useCallback(() => {
    if (!externalRecipient) {
      return;
    }

    setMeta({
      [t("common:name")]: externalRecipient.title,
      [t("locationDataProcessing")]: externalRecipient.dpLocations
        ? externalRecipient.dpLocations.map(element => " " + t("countries:" + element))
        : t("noInformation"),
      [t("headquarter")]: externalRecipient.country ? t("countries:" + externalRecipient.country) : t("noInformation"),
      [t("contractType")]: externalRecipient.contractTypes.length
        ? externalRecipient.contractTypes.map(
            option => ` ${translateById(RESOURCE_TYPES.EXTERNAL_RECIPIENT_CONTRACT_TYPE, option)}`
          )
        : t("noInformation"),
      [t("safeguards")]: externalRecipient.safeguards.length
        ? externalRecipient.safeguards.map(option => ` ${translateById(RESOURCE_TYPES.SAFEGUARD, option)}`)
        : t("noInformation"),
      [t("status")]: status
    });
  }, [externalRecipient, setMeta, status, t, translateById]);

  const onMouseLeaveCallback = useCallback(() => {
    setMeta(null);
  }, [setMeta]);

  const onClickCallback = useCallback(
    event => {
      stopEvent(event);
      externalRecipient && onClick(externalRecipient);
    },
    [externalRecipient, onClick]
  );

  const onDeleteCallback = useCallback(() => {
    onDelete?.(externalRecipient?.id || "");
  }, [externalRecipient?.id, onDelete]);

  return (
    <Box ml={1} my={0.5} overflow={"hidden"}>
      <Tooltip title={tooltipTitle}>
        <Chip
          icon={icon || undefined}
          onMouseOver={onMouseOverCallback}
          onMouseLeave={onMouseLeaveCallback}
          label={tooltipTitle}
          {...chipProps}
          disabled={disabled}
          onDelete={onDeleteCallback}
          onClick={onClickCallback}
        />
      </Tooltip>
    </Box>
  );
};

const ExternalDataRecipientsQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange,
  orgUnitIds,
  suggestionValueIds,
  multiSelect = true,
  allowAdd = true,
  blackListedIds
}: QuestionProps) => {
  const { t } = useTranslation("questionnaires");
  const { dataLocationsReload } = useDataLocations();
  const { auth } = useAuthentication();

  const [erModalPickerOpen, setErModalPickerOpen] = useState<boolean>(false);
  const [singleSelectRrListOpen, setSingleSelectErListOpen] = useState<boolean>(false);
  const [showAllChips, setShowAllChips] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string | string[] | null>(multiSelect ? [] : "");
  const [externalDataRecipientModal, setExternalDataRecipientModal] = useState<ExternalRecipientOverviewItemDTO | null>(
    null
  );
  const [notYetSelectedChips, setNotYetSelectedChips] = useState<string[]>([]);
  const [newERId, setNewERId] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [userHasExternalRecipientWritePermission, setUserHasExternalRecipientWritePermission] =
    useState<boolean>(false);

  const currentValueAsArray = useMemo(
    () =>
      (currentValue ? (Array.isArray(currentValue) ? currentValue : [currentValue]) : []).filter(
        (it): it is string => typeof it === "string"
      ),
    [currentValue]
  );

  useEffect(() => {
    return setUserHasExternalRecipientWritePermission(
      Boolean(auth?.permissions?.some(permission => permission === "er_write_org" || permission === "er_write_all"))
    );
  }, [auth?.permissions]);

  useEffect(() => {
    if (multiSelect) {
      const valueAsArray = (isArray(value) ? value : [value]).filter((it): it is string => typeof it === "string");
      setCurrentValue(valueAsArray);
    } else {
      const valueAsString = typeof value === "string" ? value : Array.isArray(value) && value[0] ? value[0] : "";
      setCurrentValue(valueAsString);
    }
  }, [multiSelect, value]);

  /* SELECTED ERs */
  const selectedERFilter = useMemo<PaginatedExternalRecipientsApiFilter>(() => {
    return {
      id: {
        in: currentValueAsArray
      }
    };
  }, [currentValueAsArray]);
  const selectedERs = usePaginatedExternalRecipientsApi({
    filter: selectedERFilter,
    limit: currentValueAsArray.length ? 100 : 0
  });
  const selectedERsDict = useMemo(
    () =>
      selectedERs.items.reduce<{ [key: string]: ExternalRecipientOverviewItemDTO }>((acc, er) => {
        acc[er.id] = er;
        return acc;
      }, {}),
    [selectedERs.items]
  );

  /* EDIT MODAL */
  const showExternalRecipientModal = useCallback(
    (externalRecipient: ExternalRecipientOverviewItemDTO) => setExternalDataRecipientModal(externalRecipient),
    []
  );
  const closeExternalRecipientModal = useCallback(() => setExternalDataRecipientModal(null), []);
  const onExternalRecipientSave = useCallback(async () => {
    closeExternalRecipientModal();
    dataLocationsReload();
  }, [closeExternalRecipientModal, dataLocationsReload]);
  const erEditModalEl = useMemo(
    () => (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={Boolean(externalDataRecipientModal && externalDataRecipientModal.id)}
          onClose={closeExternalRecipientModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DocTitle title={externalDataRecipientModal?.title || ""} />
            <ExternalRecipient documentId={externalDataRecipientModal?.id || ""} renderOnlyDetailsView={true} />
          </DialogContent>
          <DialogActions>
            <Box mr={4} display={"flex"}>
              <Button variant="outlined" color="primary" onClick={closeExternalRecipientModal}>
                {t("questionnaires:cancel")}
              </Button>
              <Box mr={2} />
              <Button variant="contained" color="primary" onClick={onExternalRecipientSave}>
                {t("questionnaires:save")}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </>
    ),
    [closeExternalRecipientModal, externalDataRecipientModal, onExternalRecipientSave, t]
  );

  /* SINGLE SELECT INPUT */
  const openERList = useCallback(() => {
    setSingleSelectErListOpen(true);
  }, []);
  const onDeleteSingleChipCallback = useCallback(() => {
    onChange?.(multiSelect ? [] : "");
    setSingleSelectErListOpen(false);
  }, [multiSelect, onChange]);
  const onSingleSelectErSearchTextChange = useCallback(event => setSearch(event.target.value), []);
  const onSelectSingleEr = useCallback(
    (id: string) => {
      onChange?.(multiSelect ? [id] : id);
      setSearch("");
    },
    [multiSelect, onChange]
  );
  const onAddSingleEr = useCallback(
    (id: string) => {
      setSearch("");
      setSingleSelectErListOpen(false);
      onChange?.(multiSelect ? [id] : id);
      setNewERId(id);
    },
    [multiSelect, onChange]
  );
  const closeSingleSelectErList = useCallback(() => {
    setSingleSelectErListOpen(false);
  }, []);
  const erListEl = useMemo(() => {
    if (!singleSelectRrListOpen) return <></>;
    return (
      <Paper>
        <ExternalRecipientsList
          search={search}
          blackListedIds={blackListedIds}
          allowAdd={allowAdd}
          onSelect={onSelectSingleEr}
          onAdd={onAddSingleEr}
        />
      </Paper>
    );
  }, [singleSelectRrListOpen, search, blackListedIds, allowAdd, onSelectSingleEr, onAddSingleEr]);

  const singleSelectinputEl = useMemo(() => {
    if (multiSelect) return <></>;

    const er = value ? selectedERsDict[value as string] : null;
    return (
      <ClickAwayListener onClickAway={closeSingleSelectErList}>
        <TextField
          label={questionName || t("dataRecipients:external")}
          fullWidth={true}
          onClick={openERList}
          onFocus={onFocus}
          multiline={false}
          disabled={disabled}
          placeholder="Search"
          InputProps={{
            startAdornment: er ? (
              <Box flex={1} mr={1}>
                <ExternalDataRecipientTag
                  key={`${value}`}
                  externalRecipient={er}
                  chipProps={{ onDelete: onDeleteSingleChipCallback }}
                  onClick={showExternalRecipientModal}
                  onDelete={onDeleteSingleChipCallback}
                />
              </Box>
            ) : (
              <></>
            )
          }}
          onChange={onSingleSelectErSearchTextChange}
          error={error}
          helperText={helperText}
        />
      </ClickAwayListener>
    );
  }, [
    closeSingleSelectErList,
    disabled,
    error,
    helperText,
    multiSelect,
    onDeleteSingleChipCallback,
    onFocus,
    onSingleSelectErSearchTextChange,
    openERList,
    questionName,
    selectedERsDict,
    showExternalRecipientModal,
    t,
    value
  ]);

  /* MULT SELECT INPUT EL */
  const limitChips = 8;
  const onDeleteChip = useCallback(
    (id: string) => {
      setCurrentValue(current => (current ? (current as string[]).filter(i => i !== id) : current));
      onChange?.(multiSelect ? currentValueAsArray?.filter(_id => _id !== id) : "");
    },
    [currentValueAsArray, multiSelect, onChange]
  );
  const onShowAllChipsCallback = useCallback(event => {
    stopEvent(event);
    setShowAllChips(true);
  }, []);
  const onHideAllChipsCallback = useCallback(event => {
    stopEvent(event);
    setShowAllChips(false);
  }, []);
  const openERPickerModal = useCallback(() => {
    if (disabled || !userHasExternalRecipientWritePermission) {
      return;
    }
    setErModalPickerOpen(true);
  }, [disabled, userHasExternalRecipientWritePermission]);
  const showMoreButtonEl = useMemo(() => {
    if (currentValueAsArray.length - limitChips <= 0) {
      return <></>;
    }
    return (
      <Chip
        sx={{ cursor: "pointer", marginX: "8px" }}
        color="primary"
        label={showAllChips ? `show less` : `+${currentValueAsArray.length - limitChips} more`}
        onClick={showAllChips ? onHideAllChipsCallback : onShowAllChipsCallback}
      />
    );
  }, [currentValueAsArray.length, onHideAllChipsCallback, onShowAllChipsCallback, showAllChips]);
  const startAdornmentEl = useMemo(() => {
    const allValuesSelected = false;
    if (allValuesSelected) {
      return (
        <Chip
          key="all"
          label={t("common:allDepartmentsSelected", {
            count: currentValue?.length
          })}
          disabled={!!disabled}
        />
      );
    } else {
      const tags = currentValueAsArray
        .slice(0, showAllChips ? currentValueAsArray.length : limitChips)
        .map((erId, index) => {
          const er = selectedERsDict[erId];
          if (!er) {
            return (
              <Chip
                key={`${erId}-${index}-notfound`}
                label={
                  <Box mt={0.5}>
                    <CircularProgress size={16} />
                  </Box>
                }
              />
            );
          }
          return (
            <ExternalDataRecipientTag
              key={`${erId}-${index}`}
              externalRecipient={er || null}
              chipProps={{ onDelete: onDeleteChip }}
              disabled={disabled}
              onClick={showExternalRecipientModal}
              onDelete={onDeleteChip}
            />
          );
        });

      return (
        <Box sx={{ flex: 1, display: "flex", flexWrap: "wrap", minWidth: "0px", width: "100%" }} flex={1}>
          {tags}
          {showMoreButtonEl}
        </Box>
      );
    }
  }, [
    currentValue?.length,
    currentValueAsArray,
    disabled,
    onDeleteChip,
    selectedERsDict,
    showAllChips,
    showExternalRecipientModal,
    showMoreButtonEl,
    t
  ]);
  const clearAllCallback = useCallback(
    event => {
      stopEvent(event);
      setCurrentValue([]);
      onChange?.([]);
    },
    [onChange]
  );
  const endAdornmentEl = useMemo(() => {
    if (disabled) {
      return <></>;
    }
    return (
      <IconButton size="small" onClick={clearAllCallback}>
        <Close />
      </IconButton>
    );
  }, [clearAllCallback, disabled]);
  const inputProps = useMemo<Partial<StandardInputProps>>(() => {
    if (currentValue?.length === 0) {
      return {};
    }
    return {
      sx: {
        flexWrap: "wrap",
        padding: "9px 12px",
        "& .MuiInputBase-inputMultiline": {
          display: "none"
        },
        minHeight: 56
      },
      startAdornment: startAdornmentEl,
      endAdornment: endAdornmentEl
    } satisfies Partial<StandardInputProps>;
  }, [currentValue?.length, endAdornmentEl, startAdornmentEl]);
  const multiSelectinputEl = useMemo(() => {
    if (!multiSelect) return <></>;
    return (
      <TextField
        label={questionName || t("dataRecipients:external")}
        fullWidth={true}
        onClick={openERPickerModal}
        InputProps={inputProps}
        onFocus={onFocus}
        multiline={true}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
    );
  }, [disabled, error, helperText, inputProps, multiSelect, onFocus, openERPickerModal, questionName, t]);

  /* ER PICKER MODAL */
  useEffect(() => {
    if (newERId && selectedERsDict[newERId]) {
      setExternalDataRecipientModal(selectedERsDict[newERId]);
      setNewERId("");
    }
  }, [newERId, selectedERsDict]);
  const onCloseERPickerModal = useCallback(() => {
    setErModalPickerOpen(false);
  }, []);
  const onConfirmERPickerModal = useCallback(
    (ids: string[]) => {
      onChange?.(ids);
      setErModalPickerOpen(false);
    },
    [onChange]
  );
  const onAdd = useCallback(
    async (input: { readonly selectedIds: string[]; readonly newItemId: string }) => {
      const { selectedIds, newItemId } = input;
      setErModalPickerOpen(false);
      setCurrentValue(selectedIds);
      onChange?.(selectedIds);
      setNewERId(newItemId);
    },
    [onChange]
  );
  const erPickerModalEl = useMemo(() => {
    return (
      <ExternalRecipientsPickerModal
        selectedIds={currentValueAsArray}
        blackListedIds={blackListedIds}
        orgUnitIds={orgUnitIds}
        open={erModalPickerOpen}
        allowAdd={allowAdd}
        onCancel={onCloseERPickerModal}
        onConfirm={onConfirmERPickerModal}
        onAdd={onAdd}
      />
    );
  }, [
    allowAdd,
    blackListedIds,
    currentValueAsArray,
    erModalPickerOpen,
    onAdd,
    onCloseERPickerModal,
    onConfirmERPickerModal,
    orgUnitIds
  ]);

  /* SUGGESTION CHIPS */
  useEffect(() => {
    if (suggestionValueIds && suggestionValueIds.length > 0) {
      const notYetSelected = suggestionValueIds.filter(value => !currentValueAsArray.includes(value));
      setNotYetSelectedChips(notYetSelected);
    }
  }, [suggestionValueIds, currentValueAsArray]);
  const suggestionERFilter = useMemo<PaginatedExternalRecipientsApiFilter>(() => {
    return {
      id: {
        in: suggestionValueIds
      }
    };
  }, [suggestionValueIds]);
  const suggestionERs = usePaginatedExternalRecipientsApi({
    filter: suggestionERFilter,
    limit: suggestionValueIds?.length ? 100 : 0
  });
  const suggestionERsDict = useMemo(
    () =>
      suggestionERs.items.reduce<{ [key: string]: ExternalRecipientOverviewItemDTO }>((acc, er) => {
        acc[er.id] = er;
        return acc;
      }, {}),
    [suggestionERs.items]
  );
  const onAddSuggestionCallback = useCallback(
    ids => {
      const allIds = [...ids, ...currentValueAsArray];
      onChange?.(allIds);
    },
    [onChange, currentValueAsArray]
  );
  const getOptionLabelCallback = useCallback(
    (id: string | undefined) => {
      if (!id) {
        return "";
      }
      const er = selectedERsDict[id] || suggestionERsDict[id];
      return (er && er.title) || id;
    },
    [selectedERsDict, suggestionERsDict]
  );
  const suggestionChipsEl = useMemo(() => {
    return (
      <SuggestionChips
        modalTitle={t("processor_pa_page:addProcessorExternalRecipients")}
        optionIds={notYetSelectedChips}
        getOptionLabel={getOptionLabelCallback}
        allowAdd={false}
        onSuggestionsAdded={onAddSuggestionCallback}
      />
    );
  }, [getOptionLabelCallback, notYetSelectedChips, onAddSuggestionCallback, t]);

  return (
    <>
      {singleSelectinputEl}
      {multiSelectinputEl}
      {suggestionChipsEl}
      {erEditModalEl}
      {erPickerModalEl}
      {erListEl}
    </>
  );
};

export default ExternalDataRecipientsQuestion;
