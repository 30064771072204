import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Question from "components/Question/Question";
import { useTranslation } from "react-i18next";
import { ResourceField } from "../../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { useResources } from "app/contexts/resource-context";
import { useMetaView } from "app/contexts/meta-view-context";

const defaultAIRoleResourceWithMetaView = [
  "provider",
  "deployer",
  "authorizedRepresentative",
  "importer",
  "distributor"
];

type AiRoleResourcePickerProps = {
  accordionId: string;
  aiRoleId: string | undefined;
  disabled?: boolean;
  isAiAssetSelected: boolean;
  onChange?: (accordionId: string, aiRoleId: string | null) => void;
};

/**
 * Component for selecting an AI role resource.
 * @param accordionId The id of the accordion (impacted asset).
 * @param aiRoleId The id of the AI role.
 * @param aiRoleResources The AI role resources.
 * @param disabled Whether the component is disabled.
 * @param isAiAssetSelected Whether an AI asset is selected.
 * @param onChange The function to call when the value changes.
 * @returns The AI role resource picker component.
 */
const AiRoleResourcePicker = ({
  accordionId,
  aiRoleId,
  disabled,
  isAiAssetSelected,
  onChange
}: AiRoleResourcePickerProps) => {
  const { t } = useTranslation("questionnaires");
  const [selectedAiRoleId, setSelectedAiRoleId] = useState<string | undefined>(aiRoleId);
  const [infoCardId, setInfoCardId] = useState<string>("infocard.pa.page2.operator");
  const { isDefaultResource, getNameKey } = useResources();
  const { setInfoId } = useMetaView();

  const [focused, setFocused] = useState(false);
  const onFocus = useCallback(() => setFocused(true), []);
  const onBlur = useCallback(() => setFocused(false), []);

  useEffect(() => {
    if (!focused) {
      return;
    }

    if (selectedAiRoleId && isDefaultResource(RESOURCE_TYPES.AI_ROLE, selectedAiRoleId)) {
      const aiRoleText = getNameKey(RESOURCE_TYPES.AI_ROLE, selectedAiRoleId);
      if (aiRoleText) {
        setInfoCardId(`infocard.pa.page2.operator.${aiRoleText.replace(" ", "").toLowerCase()}`);
        setInfoId(`infocard.pa.page2.operator.${aiRoleText.replace(" ", "").toLowerCase()}`);
      } else {
        setInfoId(`infocard.pa.page2.operator`);
        setInfoCardId(`infocard.pa.page2.operator`);
      }
    } else {
      setInfoId(`infocard.pa.page2.operator`);
      setInfoCardId(`infocard.pa.page2.operator`);
    }
  }, [selectedAiRoleId, setInfoId, getNameKey, isDefaultResource, focused]);

  const handleChange = useCallback(
    (roleId: string | string[] | null) => {
      const value = Array.isArray(roleId) ? roleId[0] : roleId;
      if (value) {
        onChange?.(accordionId, value);
        setSelectedAiRoleId(value);
      }
    },
    [accordionId, onChange]
  );

  return (
    <Box width="100%" ml={1}>
      {isAiAssetSelected && (
        <Question infoId={infoCardId} onFocus={onFocus} onBlur={onBlur}>
          <ResourceField
            disabled={disabled}
            multiSelect={false}
            resourceType={RESOURCE_TYPES.AI_ROLE}
            id="aiRoleSelect"
            label={t("operator")}
            value={selectedAiRoleId || ""}
            onChange={handleChange}
          />
        </Question>
      )}
    </Box>
  );
};

export default AiRoleResourcePicker;
