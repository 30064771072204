import { TypographyOptions, TypographyStyleOptions } from "@material-ui/core/styles/createTypography";
import palette from "theme/palette/palette";

const body1: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "14px",
  fontWeight: 450,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  color: palette.text?.primary
};

const body1Bold: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: palette.text?.primary
};

const body2: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "14px",
  fontWeight: 450,
  lineHeight: "24px",
  letterSpacing: "0.2px",
  color: palette.text?.primary
};

const body2Bold: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "12.5px",
  fontWeight: 600,
  lineHeight: "20px",
  letterSpacing: "0.25px",
  color: palette.text?.primary
};

const subtitle1: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: palette.text?.primary
};

const subtitle2: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: palette.text?.primary
};

const overline: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "11px",
  fontWeight: 600,
  lineHeight: "20px",
  letterSpacing: "1.25px",
  textTransform: "uppercase",
  color: palette.text?.primary
};

const caption: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "12px",
  fontWeight: 450,
  lineHeight: "20px",
  letterSpacing: "0.3px",
  display: "block",
  color: palette.text?.primary
};

const h6: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "18px",
  fontWeight: 450,
  lineHeight: "34px",
  letterSpacing: "0px",
  color: palette.text?.primary
};

const h5: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "20px",
  fontWeight: 450,
  lineHeight: "36px",
  letterSpacing: "0px",
  color: palette.text?.primary
};

const h4: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "22px",
  fontWeight: 500,
  lineHeight: "38px",
  letterSpacing: "0px",
  color: palette.text?.primary
};

const h3: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "24px",
  fontWeight: 500,
  lineHeight: "40px",
  letterSpacing: "0x",
  color: palette.text?.primary
};

const h2: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "26px",
  fontWeight: 500,
  lineHeight: "42px",
  letterSpacing: "0px",
  color: palette.text?.primary
};

const h1: TypographyStyleOptions = {
  fontFamily: "SuisseIntl",
  fontSize: "28px",
  fontWeight: 500,
  lineHeight: "44px",
  letterSpacing: "0px",
  color: palette.text?.primary
};

const typography: TypographyOptions = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  body1,
  body2,
  subtitle1,
  subtitle2,
  caption,
  allVariants: { fontFamily: "SuisseIntl" },
  button: {
    textTransform: "none"
  }
};

export default typography;
