import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, makeStyles, TextField } from "@material-ui/core";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";
import ServiceProviderSectionHeader from "components/ServiceProviderSectionHeader/ServiceProviderSectionHeader";
import RadioButtonQuestion from "components/RadioButtonQuestion/RadioButtonQuestion";
import Button from "@material-ui/core/Button";
import { AutomaticUserDataDisplay } from "../../../../components/UserDataDisplay";
import DateDisplay from "../../../../components/DateDisplay";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import DataProcessingSection from "./DataProcessingSection";
import Typography from "@material-ui/core/Typography";
import ComplianceCheck from "./ComplianceCheck";
import { FEATURES } from "app/features";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
import { toJSDateObjectIfFirebaseDate } from "../../../handlers/utility/date-helper";
import Question from "components/Question/Question";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { ExpiringSCCNotice } from "../../../../components/ExpiringSCCNotice";
import { DateField } from "../../../../components/DateField";
import { ResourceField } from "../../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { useExternalRecipients } from "../../../contexts/external-recipient-context";
import { mapBooleanToYesNo, mapYesNoToBoolean } from "./helper";
import AttachmentsOverviewOBS from "../../shared/Attachments/AttachmentsOverviewOBS";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

const useStyles = makeStyles(theme => ({
  widthAutocomplete: {
    width: "100%",
    marginBottom: "20px",
    marginTop: "10px"
  },
  commentTaskBadge: {
    position: "absolute",
    right: 8,
    marginTop: -70
  },
  commentTaskBadgeContractType: {
    position: "absolute",
    right: 8,
    marginTop: -90
  },
  commentTaskBadgeDataLocation: {
    position: "absolute",
    right: 8,
    marginTop: -80
  },
  commentTaskBadgeDate: {
    position: "absolute",
    right: 8,
    marginTop: -61
  },
  commentTaskBadgeNotes: {
    position: "absolute",
    right: 8,
    marginTop: 3
  },
  questionTitle: {
    marginTop: "10px"
  },
  button: {
    marginTop: "20px"
  },
  approvalElement: {
    marginTop: "10px"
  },
  inputErrorMessage: {
    color: theme.palette.error.main
  },
  alert: {
    marginTop: "10px"
  },
  legalReviewCheckbox: {
    paddingLeft: 0
  },
  legalReviewCheckboxLabel: {
    fontWeight: "600"
  },
  legalReviewCheckboxNote: {
    color: theme.palette.grey[600]
  }
}));

export default function ComplianceTab({ setMeta, setInfo, disableComment, updateAnswers, answers, onApprove }) {
  const { t, i18n } = useTranslation("compliance_page");
  const classes = useStyles();
  // user data from the global context
  const xsTextField = 12;
  const [isNoContractRequiredSelected, setIsNoContractRequiredSelected] = useState(false);
  const [showDescriptionErrorMessage, setShowDescriptionErrorMessage] = useState(false);
  const enableCustomContractType = useIsFeaturePresent(FEATURES.RESOURCES_CUSTOM_CONTRACT_TYPE);
  const [fieldsToDisplay, setFieldsToDisplay] = useState({});

  const {
    externalRecipient,
    updateExternalRecipientHook,
    approveExternalRecipientHook,
    unApproveExternalRecipientHook,
    loadExternalRecipientHook
  } = useExternalRecipients();

  useEffect(() => {
    if (externalRecipient?.displayFields) {
      setFieldsToDisplay(externalRecipient.displayFields);
    }
  }, [externalRecipient?.displayFields]);

  const [complianceNotes, setComplianceNotes] = useState("");
  const [noContractRequiredReason, setNoContractRequiredReason] = useState("");

  useEffect(() => {
    setComplianceNotes(answers.complianceNotes);
    setNoContractRequiredReason(answers.noContractRequiredReason);
  }, [answers.complianceNotes, answers.noContractRequiredReason]);

  const updateAndReloadExternalRecipientData = useCallback(
    async payload => {
      await updateExternalRecipientHook(answers.id, payload);
      await loadExternalRecipientHook(answers.id);
    },
    [answers.id, loadExternalRecipientHook, updateExternalRecipientHook]
  );

  const contractTypeNotRequiredId = "c85722ab047be8a031b0";
  const contractTypeNotExistingId = "d79e40bdbc2a0bec4b00";
  const contractTypeIds = [
    "36ca00355b5dbdc84c05",
    "0786e3b58c9d9133cca0",
    "cb8058ad9c7c44394d7e",
    "9e5c64a58673cae92bd1",
    "f0d28a3949ebc4f67b5c"
  ];
  const defaultSafeguards = [
    "696f66849c6ae624e271",
    "52619286bd9a85fd7af5",
    "2d956f25d4e7e98aae41",
    "076cc774ed392a0d438c",
    "dbzv0poxdwjaw1loy0c8",
    "pinnx096orx7usx0tadp",
    "5ba0de6450fc10429397",
    "klri7dy0k1nqouiu1oxx",
    "p6dcyl18hr20euugmap5"
  ];

  // check if a contract is already selected
  useEffect(() => {
    if (Array.isArray(answers.contractTypes) && answers.contractTypes?.length) {
      if (answers.contractTypes.includes(contractTypeNotRequiredId)) {
        setIsNoContractRequiredSelected(true);
      }
    }
  }, []);

  const { catchAsSnackbar } = useErrorSnackbar();

  // meta view info data
  const general = {
    title: t("serviceProviderGeneralTab:generalInformation"),
    text: t("serviceProviderGeneralTab:infoGeneralInformation")
  };

  const contractType = {
    title: t("info_card_title_contract_type"),
    text: t("info_card_content_contract_type")
  };

  const noContractRequiredReasonInfoCardData = {
    title: t("info_card_title_no_contractRequiredReason"),
    text: t("info_card_content_no_contractRequiredReason")
  };

  const processingOutsideEu = {
    title: t("info_card_title_processing_outside"),
    text: t("info_card_contentprocessing_outside")
  };

  useEffect(() => {
    setInfo(general);
  }, [i18n.language]);

  const approveServiceProvider = async function (approve) {
    onApprove(approve);
    // await loadExternalRecipientHook(answers.id);
  };

  const contractTypesLogicAndSetContractTypeState = selectedContractTypes => {
    const selectedValue = [...selectedContractTypes];
    if (selectedValue.length) {
      const selectedContractTypeId = selectedValue[selectedValue.length - 1];
      // if any id from contractTypeIds is selected, then deselect contractTypeNotRequiredId and contractTypeNotExistingId
      // if contractTypeNotRequiredId or contractTypeNotExistingId is selected, then deselect all contractTypeIds
      if (
        selectedContractTypeId === contractTypeNotRequiredId ||
        selectedContractTypeId === contractTypeNotExistingId
      ) {
        // deselect contractTypeNotExistingId
        if (selectedContractTypeId === contractTypeNotRequiredId) {
          setIsNoContractRequiredSelected(true);
          if (selectedValue.includes(contractTypeNotExistingId)) {
            selectedValue.splice(selectedValue.indexOf(contractTypeNotExistingId), 1);
          }
        }

        // deselect contractTypeNotRequiredId
        if (selectedContractTypeId === contractTypeNotExistingId) {
          setIsNoContractRequiredSelected(false);
          if (selectedValue.includes(contractTypeNotRequiredId)) {
            selectedValue.splice(selectedValue.indexOf(contractTypeNotRequiredId), 1);
          }
        }

        // deselect all contractTypeIds
        for (let i = selectedValue.length - 1; i >= 0; i--) {
          if (contractTypeIds.includes(selectedValue[i])) {
            selectedValue.splice(i, 1);
          }
        }
      } else {
        setIsNoContractRequiredSelected(false);
        // deselect contractTypeNotRequiredId
        if (selectedValue.includes(contractTypeNotRequiredId)) {
          selectedValue.splice(selectedValue.indexOf(contractTypeNotRequiredId), 1);
        }
        // deselect contractTypeNotExistingId
        if (selectedValue.includes(contractTypeNotExistingId)) {
          selectedValue.splice(selectedValue.indexOf(contractTypeNotExistingId), 1);
        }
      }
    } else {
      setIsNoContractRequiredSelected(false);
    }

    return selectedValue;
  };

  //update selected
  const contractTypeChanges = async selectedValueIds => {
    const selectedAndFilteredContractTypes = contractTypesLogicAndSetContractTypeState(selectedValueIds);
    updateAnswers({ contractTypes: selectedAndFilteredContractTypes });
  };

  //check if all reuired fields are answered. Visibility of some fields are dependent on the answer of preceeding
  //questions. Hence only if visible they are required.
  const checkIfAnyRequiredFieldUnanswered = () => {
    if (isNoContractRequiredSelected && noContractRequiredReason) {
      return false;
    } else {
      return (
        !answers.contractTypes?.length ||
        (isNoContractRequiredSelected && answers.noContractRequiredReason === null) ||
        answers.tomsProvided === null ||
        answers.spUsage === null ||
        answers.spRequirementApproval === null ||
        //required fields only relevant if sub processors exist
        (answers.spUsage === true &&
          (answers.spPAgreementsConcluded === null ||
            answers.spListedInContract === null ||
            answers.spExcludedDPOutsideEU === null ||
            //required fields if data processing outside EU cant be excluded
            (answers.spExcludedDPOutsideEU === false &&
              (!answers.dpLocations || answers.spSscComplianceObligation === null))))
      );
    }
  };

  const { auth } = useAuthentication();

  const hasERApprovePermission = useMemo(() => auth?.permissions?.includes("er_approve_org"), [auth?.permissions]);

  const docOrgUnitIds = useMemo(
    () => [externalRecipient?.responsibleOrgUnitId, ...(externalRecipient?.furtherOrgUnitIds || [])].filter(it => !!it),
    [externalRecipient?.responsibleOrgUnitId, externalRecipient?.furtherOrgUnitIds]
  );

  const disabled = useMemo(() => externalRecipient?.permission !== "write", [externalRecipient?.permission]);

  if (!answers) {
    return <></>;
  }

  return (
    <>
      <ExpiringSCCNotice
        toCheckExternalRecipientIds={[answers.id]}
        expiringSCCKey={"scc"}
        warningText={
          <Box>
            <Box>
              <strong>{t("expiringSCC:scc_compliance_page_title")}</strong>
            </Box>
            <Box>{t("expiringSCC:scc_compliance_page_desc")}</Box>
          </Box>
        }
      />
      <ServiceProviderSectionHeader text={t("contract_information")} />
      <Question
        questionId={!disableComment ? "contractType" : undefined}
        questionName={t("compliance_page:contract_type")}
      >
        <ResourceField
          id="contract_type"
          value={answers.contractTypes ? answers.contractTypes : []}
          onChange={value => {
            contractTypeChanges(value).catch(catchAsSnackbar("error updating contract types"));
          }}
          onBlur={() => updateAndReloadExternalRecipientData({ contractTypes: answers.contractTypes })}
          onFocus={() => {
            setInfo(contractType);
            setShowDescriptionErrorMessage(true);
          }}
          resourceType={RESOURCE_TYPES.EXTERNAL_RECIPIENT_CONTRACT_TYPE}
          label={t("compliance_page:contract_type")}
          allowAdd={enableCustomContractType}
          docOrgUnitIds={docOrgUnitIds}
          onlyIntersectingOrgUnitIds={true}
          disabled={disabled}
        />
        {showDescriptionErrorMessage && answers && !answers.contractTypes?.length && (
          <div className={classes.inputErrorMessage}>{t("mandatoryField")}</div>
        )}
      </Question>
      <Grid container>
        {fieldsToDisplay?.contractStartDate && (
          <Grid item xs={6}>
            <Box mr={3}>
              <Question questionId={"contractStartDate"} questionName={t("contractStartDate")}>
                <DateField
                  date={toJSDateObjectIfFirebaseDate(answers.contractStartDate) || null}
                  onDateChange={value => {
                    const contractStartDate = value ? value.toISOString() : null;
                    updateAnswers({ contractStartDate });
                    updateAndReloadExternalRecipientData({
                      contractStartDate
                    }).catch(catchAsSnackbar("error updating contract start date"));
                  }}
                  label={t("contractStartDate")}
                  margin="none"
                  onFocus={() => {
                    setInfo(null);
                  }}
                  onBlur={() => {
                    setInfo(null);
                  }}
                  disabled={disabled}
                />
              </Question>
            </Box>
          </Grid>
        )}
        {fieldsToDisplay?.contractEndDate && (
          <Grid item xs={6}>
            <Box ml={3}>
              <Question
                questionName={t("contractEndDate")}
                questionId={!disableComment ? "contractEndDate" : undefined}
              >
                <DateField
                  minDateMessage={t("contractEndMinDateMessage")}
                  minDate={answers.contractStartDate}
                  date={toJSDateObjectIfFirebaseDate(answers.contractEndDate) || null}
                  onDateChange={value => {
                    const contractEndDate = value ? value.toISOString() : null;
                    updateAnswers({ contractEndDate });
                    updateAndReloadExternalRecipientData({
                      contractEndDate
                    }).catch(catchAsSnackbar("error updating contract start date"));
                  }}
                  label={t("contractEndDate")}
                  margin="none"
                  onFocus={() => {
                    setInfo(null);
                  }}
                  onBlur={() => {
                    setInfo(null);
                  }}
                  disabled={disabled}
                />
              </Question>
            </Box>
          </Grid>
        )}
      </Grid>
      {fieldsToDisplay?.noContractRequiredReason && (
        <>
          <Question
            questionId={!disableComment ? "noContractRequiredReason" : undefined}
            questionName={t("noContractRequiredReason")}
          >
            <TextField
              id="noContractRequiredReason"
              multiline
              fullWidth={true}
              label={t("noContractRequiredReason")}
              variant="outlined"
              value={noContractRequiredReason}
              onChange={event => {
                setNoContractRequiredReason(event.target.value);
              }}
              onFocus={() => {
                setInfo(noContractRequiredReasonInfoCardData);
                setShowDescriptionErrorMessage(true);
              }}
              onBlur={() => {
                updateAndReloadExternalRecipientData({
                  noContractRequiredReason: noContractRequiredReason
                }).catch(catchAsSnackbar("error updating no contract required reason"));
                setInfo(null);
              }}
              disabled={disabled}
            />
            {showDescriptionErrorMessage && !answers.noContractRequiredReason && (
              <div className={classes.inputErrorMessage}>{t("mandatoryField")}</div>
            )}
          </Question>
        </>
      )}
      <>
        <DataProcessingSection
          setInfo={setInfo}
          comment={{ disableComment }}
          answers={answers}
          updateAnswers={updateAnswers}
          fieldsToDisplay={fieldsToDisplay}
        />
        {fieldsToDisplay?.tomsProvided && (
          <Question
            questionId={!disableComment ? "tomsProvided" : undefined}
            disabled={disableComment}
            pb={0}
            questionName={t("toms_provided_title")}
          >
            <RadioButtonQuestion
              rowQuestion={false}
              index={0}
              questionId="tomsProvided"
              radioOptions={["yes", "no"]}
              question={t("toms_provided_title")}
              initialValue={mapBooleanToYesNo(answers.tomsProvided)}
              onFocus={() => {
                setInfo();
              }}
              onChange={(questionId, value, index) => {
                updateAnswers({ tomsProvided: mapYesNoToBoolean(value) });
                updateAndReloadExternalRecipientData({
                  tomsProvided: mapYesNoToBoolean(value)
                }).catch(catchAsSnackbar("error updating sub processor"));
              }}
              optionLabels={[t("yes"), t("no")]}
              disabled={disabled}
            />
          </Question>
        )}
        {fieldsToDisplay?.spUsage && (
          <>
            <ServiceProviderSectionHeader text={t("sub_processors")} />
            <Question
              questionId={!disableComment ? "subProcessors" : undefined}
              disabled={disableComment}
              pb={0}
              questionName={t("sub_processors_usage_title")}
            >
              <RadioButtonQuestion
                rowQuestion={false}
                index={0}
                questionId="subProcessors.usage"
                radioOptions={["yes", "no"]}
                question={t("sub_processors_usage_title")}
                initialValue={mapBooleanToYesNo(answers.spUsage)}
                onFocus={() => {
                  setInfo();
                }}
                onChange={(questionId, value, index) => {
                  updateAnswers({ spUsage: mapYesNoToBoolean(value) });
                  updateAndReloadExternalRecipientData({
                    spUsage: mapYesNoToBoolean(value)
                  }).catch(catchAsSnackbar("error updating sub processor"));
                }}
                optionLabels={[t("yes"), t("no")]}
                disabled={disabled}
              />
            </Question>
          </>
        )}
        {fieldsToDisplay?.spUsage && (
          <Question
            questionId={!disableComment ? "subProcessors.requirementApproval" : undefined}
            disabled={disableComment}
            pb={0}
            questionName={t("sub_processors_requirement_for_approval_title")}
          >
            <RadioButtonQuestion
              rowQuestion={false}
              index={0}
              questionId="subProcessors.requirementApproval"
              radioOptions={["yes", "no"]}
              question={t("sub_processors_requirement_for_approval_title")}
              initialValue={mapBooleanToYesNo(answers.spRequirementApproval)}
              onFocus={() => {
                setInfo();
              }}
              onChange={(questionId, value, index) => {
                updateAnswers({ spRequirementApproval: mapYesNoToBoolean(value) });
                updateAndReloadExternalRecipientData({
                  spRequirementApproval: mapYesNoToBoolean(value)
                }).catch(catchAsSnackbar("error updating sub processor"));
              }}
              optionLabels={[t("yes"), t("no")]}
              disabled={disabled}
            />
          </Question>
        )}
        <>
          {fieldsToDisplay?.spListedInContract && (
            <Question
              questionId={!disableComment ? "subProcessors.listedInContract" : undefined}
              disabled={disableComment}
              pb={0}
              questionName={t("sub_processors_listed_in_contract_title")}
            >
              <RadioButtonQuestion
                rowQuestion={false}
                index={0}
                questionId="subProcessors.listedInContract"
                radioOptions={["yes", "no"]}
                question={t("sub_processors_listed_in_contract_title")}
                initialValue={mapBooleanToYesNo(answers.spListedInContract)}
                onFocus={() => {
                  setInfo();
                }}
                onChange={(questionId, value, index) => {
                  updateAnswers({ spListedInContract: mapYesNoToBoolean(value) });
                  updateAndReloadExternalRecipientData({
                    spListedInContract: mapYesNoToBoolean(value)
                  }).catch(catchAsSnackbar("error updating sub processor"));
                }}
                optionLabels={[t("yes"), t("no")]}
                disabled={disabled}
              />
            </Question>
          )}
          {fieldsToDisplay?.spPAgreementsConcluded && (
            <Question
              questionId={!disableComment ? "subProcessors.processingAgreementsConcluded" : undefined}
              disabled={disableComment}
              pb={0}
              questionName={t("sub_processors_processing_aggreements_concluded_title")}
            >
              <RadioButtonQuestion
                rowQuestion={false}
                index={0}
                questionId="subProcessors.processingAgreementsConcluded"
                radioOptions={["yes", "no"]}
                question={t("sub_processors_processing_aggreements_concluded_title")}
                initialValue={mapBooleanToYesNo(answers.spPAgreementsConcluded)}
                onFocus={() => {
                  setInfo();
                }}
                onChange={(questionId, value, index) => {
                  updateAnswers({ spPAgreementsConcluded: mapYesNoToBoolean(value) });
                  updateAndReloadExternalRecipientData({
                    spPAgreementsConcluded: mapYesNoToBoolean(value)
                  }).catch(catchAsSnackbar("error updating sub processor"));
                }}
                optionLabels={[t("yes"), t("no")]}
                disabled={disabled}
              />
            </Question>
          )}
          {fieldsToDisplay?.spExcludedDPOutsideEU && (
            <Question
              questionId={!disableComment ? "subProcessors.excludedDataProcessingOutsideEU" : undefined}
              disabled={disableComment}
              pb={0}
              questionName={t("sub_processors_excluded_data_processing_outside_eu_title")}
            >
              <RadioButtonQuestion
                rowQuestion={false}
                index={0}
                questionId="subProcessors.excludedDataProcessingOutsideEU"
                radioOptions={["yes", "no"]}
                question={t("sub_processors_excluded_data_processing_outside_eu_title")}
                initialValue={mapBooleanToYesNo(answers.spExcludedDPOutsideEU)}
                onFocus={() => {
                  setInfo(processingOutsideEu);
                }}
                onChange={(questionId, value, index) => {
                  updateAnswers({ spExcludedDPOutsideEU: mapYesNoToBoolean(value) });
                  updateAndReloadExternalRecipientData({
                    spExcludedDPOutsideEU: mapYesNoToBoolean(value)
                  }).catch(catchAsSnackbar("error updating sub processor"));
                }}
                optionLabels={[t("yes"), t("no")]}
                disabled={disabled}
              />
            </Question>
          )}
          {fieldsToDisplay?.spSscComplianceObligation && (
            <>
              <Question
                questionId={!disableComment ? "subProcessors.sscComplianceObligation" : undefined}
                disabled={disableComment}
                pb={0}
                questionName={t("sub_processors_ssc_compliance_obligation_title")}
              >
                <RadioButtonQuestion
                  rowQuestion={false}
                  index={0}
                  questionId="subProcessors.sscComplianceObligation"
                  radioOptions={["yes", "no"]}
                  question={t("sub_processors_ssc_compliance_obligation_title")}
                  initialValue={mapBooleanToYesNo(answers.spSscComplianceObligation)}
                  onFocus={() => {
                    setInfo();
                  }}
                  onChange={(questionId, value, index) => {
                    updateAnswers({ spSscComplianceObligation: mapYesNoToBoolean(value) });
                    updateAndReloadExternalRecipientData({
                      spSscComplianceObligation: mapYesNoToBoolean(value)
                    }).catch(catchAsSnackbar("error updating sub processor"));
                  }}
                  optionLabels={[t("yes"), t("no")]}
                  disabled={disabled}
                />
              </Question>
            </>
          )}
        </>
      </>
      <ServiceProviderSectionHeader text={t("notes")} />
      <Question questionName={t("notes")} questionId={!disableComment ? "notes" : undefined}>
        <TextEditor
          id="notes"
          variant="outlined"
          inputValue={complianceNotes || ""}
          onChange={value => setComplianceNotes(value)}
          onBlur={() => {
            updateAndReloadExternalRecipientData({
              complianceNotes: complianceNotes
            }).catch(catchAsSnackbar("error updating notes"));
            setInfo(null);
          }}
          disabled={disabled}
        />
      </Question>
      <ServiceProviderSectionHeader text={t("compliance_check_sp")} />
      <Box mt={3}>
        <ComplianceCheck externalDataRecipient={answers} />
      </Box>
      {answers.safeguards?.some(safeguard => !defaultSafeguards.includes(safeguard)) && (
        <div className={classes.alert}>
          <CustomAlert severity={"warning"}>
            <Typography variant="body2" component="span">
              {parse(DOMPurify.sanitize(t("compliance_check:unknown_safeguard")))}
            </Typography>
          </CustomAlert>
        </div>
      )}

      {hasERApprovePermission && (
        <>
          <ServiceProviderSectionHeader text={t("approve_sp")} />
          {!answers.approvedAt && (
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={disabled || checkIfAnyRequiredFieldUnanswered()}
                onClick={() => {
                  approveServiceProvider(true).catch(catchAsSnackbar("error approving service provider"));
                }}
              >
                {t("approve")}
              </Button>
            </Box>
          )}
        </>
      )}
      {answers.approvedAt && (
        <>
          <Grid container>
            <Grid item xs={4}>
              <b>{t("approved_by")}</b>
            </Grid>
            <Grid item xs={4}>
              <AutomaticUserDataDisplay uid={answers.approvedBy} />
            </Grid>
          </Grid>
          <Grid container className={classes.approvalElement}>
            <Grid item xs={4}>
              <b>{t("approved_on")}</b>
            </Grid>
            <Grid item xs={4}>
              {answers.approvedAt && <DateDisplay timestamp={new Date(answers.approvedAt)} />}
            </Grid>
          </Grid>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            disabled={disabled}
            onClick={() => {
              approveServiceProvider(false).catch(catchAsSnackbar("error unapproving service provider"));
            }}
          >
            {t("unapprove")}
          </Button>
        </>
      )}
      <ServiceProviderSectionHeader text={t("attachments")} />
      <Grid container spacing={1}>
        <Grid item xs={xsTextField}>
          <Question
            questionName={t("attachments")}
            disabled={disableComment}
            questionId={!disableComment ? "attachments" : undefined}
          >
            <AttachmentsOverviewOBS
              docId={answers?.id}
              setInfo={setInfo}
              setMeta={setMeta}
              category={"externalrecipients"}
              disabled={disabled}
            />
          </Question>
        </Grid>
      </Grid>
    </>
  );
}
