import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMetaView } from "../../../../contexts/meta-view-context";
import { useTranslation } from "react-i18next";
import { AutomaticUserDataDisplay } from "../../../../../components/UserDataDisplay";
import { AssetsOverviewCollectionParams } from "app/pages/assets/overview/AssetsOverviewController";
import { OverviewProvider } from "app/contexts/overview-context";
import Overview, { OverviewParams } from "components/Overview/Overview";
import { COLLECTIONS } from "app/collections";
import { Box, CircularProgress } from "@mui/material";
import { unlink } from "app/api/assetApi";
import { useSnackbar } from "notistack";
import { OverviewBrickProps } from "components/Overview/controls/OverviewRow";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";

interface AssetsOverviewProps extends Pick<OverviewParams, "onRowOver" | "onRowLeave" | "noEntriesText" | "header"> {
  readonly filter: { [key: string]: string | string[] };

  readonly onUnlink: (id: string) => void;
}
const AssetsOverview = React.memo(
  (props: AssetsOverviewProps) => {
    const collectionParams = useMemo<AssetsOverviewCollectionParams>(
      () => ({
        openInNewTab: true,
        filter: props.filter
      }),
      [props.filter]
    );
    const onUnlinkCallback = useCallback(
      item => {
        props.onUnlink(item.id);
      },
      [props]
    );

    const bricks = useMemo<OverviewBrickProps[]>(
      () => [
        { component: OverviewBrick.AssetStatus },
        { component: OverviewBrick.Title },
        { component: OverviewBrick.AssetType },
        { component: OverviewBrick.Readonly },
        {
          component: brickProps => (
            <OverviewBrick.Unlink item={brickProps.item} hovered={brickProps.hovered} onClick={onUnlinkCallback} />
          ),
          position: "right"
        }
      ],
      [onUnlinkCallback]
    );

    return (
      // new overview provider to not share the same state as the main asset overview
      <OverviewProvider>
        <Overview
          checkable={false}
          collection={COLLECTIONS.ASSETS}
          collectionParams={collectionParams}
          deletable={false}
          header={props.header}
          noEntriesText={props.noEntriesText}
          bricks={bricks}
          showSelectAll={false}
          toolbarMode="tabs"
          onRowLeave={props.onRowLeave}
          onRowOver={props.onRowOver}
        />
      </OverviewProvider>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.filter === nextProps.filter &&
      prevProps.header === nextProps.header &&
      prevProps.noEntriesText === nextProps.noEntriesText &&
      prevProps.onUnlink === nextProps.onUnlink
    );
  }
);
AssetsOverview.displayName = "ExternalRecipientAssetsOverview";

const ExternalRecipientAssetsOverview = ({ externalRecipientId }: { readonly externalRecipientId: string }) => {
  const { t } = useTranslation("assets_overview");
  const { setInfo, setMeta } = useMetaView();
  const { enqueueSnackbar } = useSnackbar();
  const [isUnlinkingInProgress, setIsUnlinkingInProgress] = useState(false);

  const onUnlinkStart = useCallback(() => setIsUnlinkingInProgress(true), []);
  const onUnlinkDone = useCallback(() => setIsUnlinkingInProgress(false), []);

  const unlinkAsset = useCallback(
    async assetId => {
      onUnlinkStart();
      try {
        await unlink(assetId, { externalRecipientId });
        enqueueSnackbar(t("external_recipients_assets_overview:unlinkSuccess"), { variant: "success" });
      } catch (error) {
        console.error("Failed to unlink asset:", error);
        enqueueSnackbar(t("external_recipients_assets_overview:unlinkError"), { variant: "error" });
      } finally {
        onUnlinkDone();
      }
    },
    [enqueueSnackbar, externalRecipientId, onUnlinkDone, onUnlinkStart, t]
  );

  const filter = useMemo(() => ({ externalRecipientIds: [externalRecipientId] }), [externalRecipientId]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("external_recipients_assets_overview:enteringInfoCardTitle"),
        text: t("external_recipients_assets_overview:enteringInfoCardText")
      }
    }),
    [t]
  );

  useEffect(() => {
    if (setInfo) {
      setInfo(infoCard.entering);
    }
  }, [setInfo, infoCard.entering]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo]);

  const onRowOver = useCallback(
    item => {
      setMeta({
        [t("title")]: item.title,
        [t("type")]: t(item.resourceAssetTypeId),
        [t("status")]: t(item.status),
        [t("owner")]: <AutomaticUserDataDisplay uid={item.creatorUID} separator={undefined} />,
        [t("amountOfLinkedProcesses")]: item.processes?.length || 0,
        [t("amountOfLinkedRisks")]: item?.riskIds?.length || 0,
        [t("amountOfLinkedDataSourcesOrStarageLocations")]: item?.dataLocationIds?.length || 0
      });
    },
    [setMeta, t]
  );

  if (isUnlinkingInProgress) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return <AssetsOverview filter={filter} onRowOver={onRowOver} onRowLeave={showEnterInfo} onUnlink={unlinkAsset} />;
};
ExternalRecipientAssetsOverview.displayName = "ExternalRecipientAssetsOverview";

export default ExternalRecipientAssetsOverview;
