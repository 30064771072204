import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import overviewBaseController, {
  OverviewController,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import {
  getDataStorageLocationFilter,
  getLabelsFilter,
  getProcessTaskFilter,
  getRiskLevelFilter,
  getRiskPrivacyRelevantFilter,
  getRiskTreatmentFilter
} from "app/pages/shared/Filters/filters";
import { AxiosInstance } from "axios";
import { resourcesDecorator } from "components/Overview/controllers/decorator/resourcesDecorator";
import { tDeletedEntry } from "app/handlers/dataTypeTranslatorHandler";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import i18n from "../../../i18n";
import { getOrganizationFilter } from "../../shared/Filters/OrganizationFilter";
import { CollectionParams } from "../../../../hook/useOverviewData";
import { RiskDTO } from "../../../api/riskApi";

const TomRiskOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: CollectionParams
): OverviewController => {
  const { t } = useTranslation("risks_overview");
  const translateItem = useCallback(
    obj => ({ ...obj, subTitle: obj.subTitle === "deleted" ? tDeletedEntry({ t }) : obj.subTitle }),
    [t]
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.TOM_RISKS, translateItem, [
    resourcesDecorator,
    departmentsDecorator
  ]);

  const { t: tFilter } = useTranslation("filter_criteria");

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: tFilter("aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: tFilter("zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: tFilter("newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: tFilter("oldFirst")
      },
      {
        field: "rating",
        type: "desc",
        label: tFilter("riskLevelHighToLow")
      },
      {
        field: "rating",
        type: "asc",
        label: tFilter("riskLevelLowToHigh")
      }
    ],
    [tFilter]
  );

  const getFilters = useCallback(
    data => [
      getOrganizationFilter("orgUnitId", data._departments, t, undefined),
      getDataStorageLocationFilter("dataLocationIds", data.dls, t),
      getRiskLevelFilter("riskLevel", t),
      getRiskTreatmentFilter("treatment", t),
      getRiskPrivacyRelevantFilter("privacyRelevant", t),
      getLabelsFilter("labelIds", data._resources, t),
      getProcessTaskFilter("openTasks", t)
    ],
    [t]
  );

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup, `${collectionParams.id || ""}/risks/overview`, {
      headers: { "Accept-Language": i18n.language }
    });
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    if (collectionParams && collectionParams.openInNewTab) {
      window.open(`/risks/${id}/general`);
    } else baseController.goToItem(`/risks/${id}/general`);
  };

  return {
    ...baseController,
    goToItem,
    getOverview
  };
};

export default TomRiskOverviewController;
