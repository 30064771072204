import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Question from "components/Question/Question";
import { DSRDataSourceField } from "./DSRDataSourceField";
import { useDataSubjectRequest } from "app/contexts/dsr-context";
import { useDataLocations } from "hook/useDataLocations";
import { useUserProcesses } from "hook/useUserProcesses";

interface DSRDataSourcePickerProps {
  readonly disabled?: boolean;
}

// displays data sources from processes related to selected person groups
const DSRDataSourcePicker = ({ disabled }: DSRDataSourcePickerProps) => {
  const { t } = useTranslation("data_subject_requests_data_page");
  const { dataSubjectRequest } = useDataSubjectRequest();
  const { dataLocations, dataLocationsLoaded } = useDataLocations();

  // track data sources found in processes
  const [dataSourceIDs, setDataSourceIDs] = useState<string[]>([]);

  // get processes based on selected person groups
  const { processes, processesLoaded } = useUserProcesses({
    personGroupIDs: dataSubjectRequest?.inputData?.personGroups || []
  });

  // update data sources when processes change
  useEffect(() => {
    if (!processesLoaded || !dataLocationsLoaded) return;

    // get all valid data location IDs
    const allDataLocationIDs = dataLocations.map(location => location.id);

    // collect relevant data source IDs from processes
    const relevantDataSourceIDs = new Set<string>();
    for (const processData of processes) {
      processData.allDataSourceIds
        .filter(dataSourceId => allDataLocationIDs.includes(dataSourceId))
        .forEach(id => relevantDataSourceIDs.add(id));
    }

    setDataSourceIDs([...relevantDataSourceIDs]);
  }, [processes, processesLoaded, dataLocations, dataLocationsLoaded]);

  // only show field if person groups are selected
  const shouldShow = (dataSubjectRequest?.inputData?.personGroups || []).length > 0;

  if (!shouldShow) return null;

  return (
    <Question questionId="dataSources" questionName={t("datasource")} disabled={disabled}>
      <DSRDataSourceField values={dataSourceIDs} loading={!processesLoaded} />
    </Question>
  );
};

export default DSRDataSourcePicker;
