import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import HeatMap from "../../../components/HeatMap/HeatMap";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import CardWithTextButton from "../../../components/CardWithButton/CardWithTextButton";
import { CircleWithNumber } from "../../../components/CircleWithNumber/CircleWithNumber";
import Overview from "components/Overview/Overview";
import { COLLECTIONS } from "app/collections";
import { getRisksMatrixApi, RiskMatrixItemDTO } from "../../api/riskApi";
import { CollectionParams } from "../../../hook/useOverviewData";
import useSWR from "swr";
import { OverviewProvider, useOverviewState } from "../../contexts/overview-context";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";
import { OverviewBrickProps } from "components/Overview/controls/OverviewRow";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { exportRisksExcel } from "app/export/createdExcelExportData";
import { isEmpty } from "lodash-es";
import { useMetaView } from "../../contexts/meta-view-context";
import DateDisplay from "../../../components/DateDisplay";
import { AutomaticUserDataDisplay } from "../../../components/UserDataDisplay";

const bricks = [{ component: OverviewBrick.Title }, { component: OverviewBrick.RiskLevel }] as OverviewBrickProps[];

export const RiskMatrixPage = () => {
  const { t } = useTranslation("risks_overview");
  const [mapSize, setMapSize] = useState(400);
  const { auth } = useAuthentication();
  const overviewSetup = useOverviewState()[COLLECTIONS.RISK];
  const { setInfo, setMeta } = useMetaView();

  const filter = useMemo(() => {
    const baseFilter = overviewSetup?.filter || {};
    const transformedFilter = { ...baseFilter };
    if (Array.isArray(transformedFilter.riskLevel) && transformedFilter.riskLevel.length === 1) {
      transformedFilter.riskLevel = transformedFilter.riskLevel[0];
    } else if (Array.isArray(transformedFilter.riskLevel) && transformedFilter.riskLevel.length > 1) {
      delete transformedFilter.riskLevel;
    }
    if (Array.isArray(transformedFilter.orgUnitId) && transformedFilter.orgUnitId.length === 1) {
      transformedFilter.orgUnitId = transformedFilter.orgUnitId[0];
    } else if (Array.isArray(transformedFilter.orgUnitId) && transformedFilter.orgUnitId.length > 1) {
      delete transformedFilter.orgUnitId;
    }
    return transformedFilter;
  }, [overviewSetup?.filter]);

  const filterKey = useMemo(() => JSON.stringify(filter), [filter]);

  const { data: risks } = useSWR<RiskMatrixItemDTO[]>(["riskMatrix", filterKey], () =>
    getRisksMatrixApi(isEmpty(filter) ? undefined : filter).then(data => data?.items || [])
  );

  const [riskMarkersToDisplay, setRiskMarkersToDisplay] = useState<
    {
      readonly xPosition: number;
      readonly yPosition: number;
      readonly items: RiskMatrixItemDTO[];
      readonly objectToRender: React.ReactNode;
    }[]
  >([]);
  const [selectedCircle, setSelectedCircle] = useState<{
    readonly xPosition: number;
    readonly yPosition: number;
  } | null>(null);
  const [riskIds, setRiskIds] = useState<string[]>([]);
  const [updateId, setUpdateId] = useState<number>(Date.now());

  const infoCard = useMemo(
    () => ({
      matrix: {
        title: t("risks_overview:enteringInfoCardTitle"),
        text: t("risks_overview:enteringInfoCardText")
      }
    }),
    [t]
  );

  // Set initial info when component mounts
  useEffect(() => {
    setInfo(infoCard.matrix);
  }, [infoCard, setInfo]);

  const showMatrixInfo = useCallback(() => {
    setInfo(infoCard.matrix);
  }, [infoCard, setInfo]);

  const onRowOver = useCallback(
    item => {
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        riskOwner: <AutomaticUserDataDisplay uid={item.ownerUID} />
      });
    },
    [setMeta]
  );

  const onRowLeave = useCallback(() => {
    showMatrixInfo();
  }, [showMatrixInfo]);

  const exportToXLSX = useCallback(
    async ids => {
      if (auth?.tenantId) {
        return await exportRisksExcel(auth?.tenantId, t, ids);
      }
    },
    [auth?.tenantId, t]
  );

  const onCircleClick = useCallback((riskIds: string[], x: number, y: number) => {
    setRiskIds(riskIds);
    setSelectedCircle({ yPosition: y, xPosition: x });
    setUpdateId(Date.now());
  }, []);

  const getCircleSVG = useCallback(
    (risks: RiskMatrixItemDTO[]) => {
      let selected = false;
      if (
        risks[0].damageExtendValue === selectedCircle?.xPosition &&
        risks[0].occurrenceValue === selectedCircle?.yPosition
      ) {
        selected = true;
      }

      return (
        <CircleWithNumber risks={risks} onClick={onCircleClick} sizeSVG={50} radiusCircle={20} selected={selected} />
      );
    },
    [onCircleClick, selectedCircle?.xPosition, selectedCircle?.yPosition]
  );

  useEffect(() => {
    if (!risks) {
      return;
    }
    const coordinateObjects: {
      readonly xPosition: number;
      readonly yPosition: number;
      readonly items: RiskMatrixItemDTO[];
      readonly objectToRender: JSX.Element;
    }[] = [];
    for (let y = 0; y <= 3; y++) {
      for (let x = 0; x <= 3; x++) {
        const itemsOnCoordinate = risks
          .map(item => {
            if (item.occurrenceValue === y && item.damageExtendValue === x) {
              return item;
            }
            return null;
          })
          .filter((item): item is RiskMatrixItemDTO => !!item);
        if (itemsOnCoordinate?.length > 0) {
          coordinateObjects.push({
            xPosition: x,
            yPosition: y,
            items: itemsOnCoordinate,
            objectToRender: getCircleSVG(itemsOnCoordinate)
          });
        }
      }
    }
    setRiskMarkersToDisplay(coordinateObjects);
  }, [getCircleSVG, risks]);

  const parentRef = useRef<HTMLInputElement>(null);
  const estimateMapSize = useCallback(() => {
    if (parentRef.current) {
      if (parentRef.current.clientWidth >= 1200) {
        setMapSize(650);
        return;
      }
      if (parentRef.current.clientWidth >= 1000) {
        setMapSize(600);
        return;
      }
      if (parentRef.current.clientWidth >= 800) {
        setMapSize(550);
        return;
      }
      if (parentRef.current.clientWidth >= 600) {
        setMapSize(500);
        return;
      }
      if (parentRef.current.clientWidth >= 500) {
        setMapSize(400);
        return;
      }
      if (parentRef.current.clientWidth < 400) {
        setMapSize(250);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", estimateMapSize);
  }, [estimateMapSize]);

  const collectionParams = useMemo(() => {
    return {
      filter: { riskIds: riskIds || ["empty-id-to-return-nothing"] },
      openInNewTab: true,
      exportToXLSX
    } satisfies CollectionParams;
  }, [riskIds, exportToXLSX]);

  const toolbarActions = useMemo(() => {
    return riskIds.length > 0 ? [{ action: "export-xlsx" }] : [];
  }, [riskIds]);

  const selectionActions = useMemo(() => [{ action: "export-xlsx" }], []);

  return (
    <>
      <div ref={parentRef}>
        <HeatMap
          mapSize={mapSize}
          numberSquaresPerRow={4}
          labelYAxis={t("risk_assessment:occurrence")}
          labelXAxis={t("risk_assessment:damageExtend")}
          markersToDisplay={riskMarkersToDisplay}
        />
      </div>
      <Box mt={4}>
        {riskIds.length > 0 && (
          <OverviewProvider key={updateId}>
            <Overview
              collection={COLLECTIONS.RISK}
              collectionParams={collectionParams}
              showSelectAll={false}
              bricks={bricks}
              toolbarActions={toolbarActions}
              selectionActions={selectionActions}
              checkable={true}
              onRowOver={onRowOver}
              onRowLeave={onRowLeave}
            />
          </OverviewProvider>
        )}
        {riskIds.length === 0 && (
          <CardWithTextButton
            text={t("matrixInfo")}
            buttonText={undefined}
            onClick={undefined}
            colorTheme={undefined}
          />
        )}
      </Box>
    </>
  );
};
