import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import { patchAuditTemplate } from "app/api/auditApi";
import { COLLECTIONS } from "app/collections";
import { useOverviewDispatch, OVERVIEW_ACTIONS } from "app/contexts/overview-context";
import { AUDIT_TEMPLATE_STATUS } from "app/pages/audits/audit/AuditTypes";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import stopEvent from "tool/stopEvent";

const AssessmentTemplateOverviewBrickStatus = ({ item }: OverviewRowBrickProps) => {
  const { id, status, disabled } = item;
  const { t } = useTranslation();
  const dispatch = useOverviewDispatch();

  const [ref, setRef] = useState<Element | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const openMenuCallback = useCallback(event => {
    stopEvent(event);
    setRef(event?.currentTarget);
    setOpen(true);
  }, []);

  const closeMenuCallback = useCallback(() => {
    setRef(null);
    setOpen(false);
  }, []);

  const reload = useCallback(() => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.AUDITS, reload });
  }, [dispatch]);

  const statuses = useMemo(
    () => [
      {
        id: AUDIT_TEMPLATE_STATUS.ACTIVE,
        icon: <LazySvgIcon name="Completed" />,
        label: t(`audit_status:${AUDIT_TEMPLATE_STATUS.ACTIVE}`),
        status: AUDIT_TEMPLATE_STATUS.ACTIVE,
        selected: status === AUDIT_TEMPLATE_STATUS.ACTIVE
      },
      {
        id: AUDIT_TEMPLATE_STATUS.INACTIVE,
        icon: <LazySvgIcon name="On_Halt" />,
        label: t(`audit_status:${AUDIT_TEMPLATE_STATUS.INACTIVE}`),
        status: AUDIT_TEMPLATE_STATUS.INACTIVE,
        selected: status === AUDIT_TEMPLATE_STATUS.INACTIVE
      }
    ],
    [status, t]
  );

  const currentStatus = useMemo(() => {
    return statuses.find(it => it.status === status);
  }, [statuses, status]);

  const onChangeCallback = useCallback(
    async (status: string) => {
      closeMenuCallback();
      await patchAuditTemplate({ id, payload: { status } });
      reload();
    },
    [closeMenuCallback, id, reload]
  );

  const statusEl = useMemo(
    () => (
      <Tooltip title={currentStatus?.label}>
        <Box sx={{ height: "24px" }} onClick={openMenuCallback}>
          {currentStatus?.icon}
        </Box>
      </Tooltip>
    ),
    [currentStatus?.icon, currentStatus?.label, openMenuCallback]
  );

  if (disabled) {
    return statusEl;
  }

  return (
    <ClickAwayListener onClickAway={closeMenuCallback}>
      <>
        {statusEl}
        <DropDownMenu
          open={open}
          anchorEl={ref}
          items={statuses}
          onChange={onChangeCallback}
          onClose={closeMenuCallback}
        />
      </>
    </ClickAwayListener>
  );
};

export default AssessmentTemplateOverviewBrickStatus;
