import React from "react";

export interface HighlightProps {
  readonly text: string;
  readonly searchTerm: string;
}

const OverviewHighlight = ({ text, searchTerm }: HighlightProps) => {
  // escape string to use in RegExp() to prevent crash for strings like "?" or "("
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const parts = text.split(new RegExp(`(${escapedSearchTerm})`, "gi"));
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <mark style={{ background: "#FFE1A9" }} key={index}>
            {part}
          </mark>
        ) : (
          part
        )
      )}
    </>
  );
};

export default OverviewHighlight;
