import { Box, Paper, Typography, CircularProgress, IconButton, useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMetaViewWithDefault } from "hook/useMetaViewWithDefault";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import DateDisplay from "components/DateDisplay";

interface AttachmentFile {
  name: string;
  uploadedBy?: string;
  timeCreated?: string;
  size?: number;
  status: "loading" | "complete";
}

interface AttachmentsListProps {
  files: AttachmentFile[];
  disabled?: boolean;
  onDownload?: (file: AttachmentFile) => void;
  onDelete?: (file: AttachmentFile) => void;
  defaultInfo?: {
    title: string;
    text: string;
  };
}

// modern list component for attachments
const AttachmentsList = ({ files, disabled, onDownload, onDelete, defaultInfo }: AttachmentsListProps) => {
  const theme = useTheme();
  const { t } = useTranslation("file_upload");
  const { setMeta, handleMouseLeave } = useMetaViewWithDefault(defaultInfo || { text: "", title: "" });

  // handle mouse enter to show metadata
  const handleMouseEnter = useCallback(
    (file: AttachmentFile) => {
      if (file.uploadedBy && file.timeCreated) {
        setMeta({
          name: file.name,
          uploadedBy: <AutomaticUserDataDisplay uid={file.uploadedBy} />,
          timeCreated: <DateDisplay timestamp={new Date(file.timeCreated)} />
        });
      }
    },
    [setMeta]
  );

  // memoize handlers for each file
  const fileHandlers = useMemo(() => {
    return files.map(file => ({
      handleClick: () => {
        if (!disabled && onDownload && file.status === "complete") {
          onDownload(file);
        }
      },
      handleDelete: (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!disabled && onDelete) {
          onDelete(file);
        }
      },
      handleMouseEnter: () => handleMouseEnter(file),
      handleMouseLeave
    }));
  }, [files, disabled, onDownload, onDelete, handleMouseEnter, handleMouseLeave]);

  // format file size
  const formatFileSize = (bytes?: number) => {
    if (!bytes) return "unknown";
    if (bytes < 1024) return `${bytes}B`;
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(0)}kb`;
    return `${(bytes / (1024 * 1024)).toFixed(0)}MB`;
  };

  if (files.length === 0) return null;

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1 }}>
      {files.map((file, index) => (
        <Paper
          key={`${file.name}-${index}`}
          elevation={1}
          sx={{
            cursor: disabled || file.status !== "complete" ? "default" : "pointer",
            "&:hover": {
              backgroundColor: file.status === "complete" ? theme.palette.action.hover : "inherit"
            }
          }}
          onClick={fileHandlers[index].handleClick}
          onMouseEnter={fileHandlers[index].handleMouseEnter}
          onMouseLeave={fileHandlers[index].handleMouseLeave}
        >
          <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
            <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
              {file.status === "loading" ? (
                <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />
              ) : (
                <UploadFileIcon sx={{ color: theme.palette.primary.main, height: 24, width: 24 }} />
              )}
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body1">{file.name}</Typography>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                {formatFileSize(file.size)}
                {/* unicode-non-breaking-spaces to preserve extra spaces */}
                {"\u00A0\u00A0\u00A0•\u00A0\u00A0\u00A0"}
                {file.status === "loading" ? t("loading") : t("complete")}
              </Typography>
            </Box>
            {!disabled && onDelete && file.status === "complete" && (
              <IconButton onClick={fileHandlers[index].handleDelete} size="small">
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Paper>
      ))}
    </Box>
  );
};

export default AttachmentsList;
