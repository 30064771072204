import { Box } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import colors from "theme/palette/colors";

const OverviewBrickUnseen = ({ item }: OverviewRowBrickProps) => {
  return item.seen === false ? (
    <Box bgcolor={colors.blue.blue500} borderRadius={"50%"} flexShrink={0} height={"8px"} width={"8px"} />
  ) : (
    <></>
  );
};
export default OverviewBrickUnseen;
