import { Box, Slide } from "@mui/material";
import { sx } from "../HomeStyles";
import TaskDetails from "../../tasks/details/TaskDetails";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { useCallback } from "react";
import { useOverviewState } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import {
  handleCloseTaskDetails,
  handleGroupTaskDelete,
  handleRecurringTaskDelete,
  handleTaskDelete,
  handleTaskEdit
} from "../handlers/taskHandlers";
import { useNavigate } from "react-router-dom";

export default function HomeTaskDetailsOverlay({
  isSlidedIn,
  reload,
  selectedRow,
  setIsSlidedIn,
  setSelectedRow
}: {
  isSlidedIn: boolean;
  reload: (input?: { id?: string | null; shadowLoading?: boolean }) => void;
  selectedRow: OverviewItem | null;
  setIsSlidedIn: (value: boolean) => void;
  setSelectedRow: (value: OverviewItem | null) => void;
}) {
  const { reloadMetaview } = useOverviewState()[COLLECTIONS.HOME];
  const navigate = useNavigate();

  const onCloseTaskDetails = useCallback(() => handleCloseTaskDetails({ reload }), [reload]);

  const onTaskDeleteInMeta = useCallback(async (taskId: string) => handleTaskDelete({ taskId, reload }), [reload]);

  const onGroupTaskDeleteInMeta = useCallback(
    async (taskId: string) => handleGroupTaskDelete({ taskId, reload }),
    [reload]
  );

  const onRecurringTaskDeleteInMeta = useCallback(
    async (taskId: string) => handleRecurringTaskDelete({ taskId, reload }),
    [reload]
  );

  const onTaskEditInMeta = useCallback((taskId, field) => handleTaskEdit({ taskId, reload, field }), [reload]);

  const onPageChange = useCallback(
    input => {
      // this is used to open recurring tasks from children (when clicking "edit schedule")
      const { id, withReload } = input;
      setSelectedRow(null);
      setIsSlidedIn(false);
      navigate(`/home/${id}`);
      if (withReload) {
        reload({
          id,
          shadowLoading: true
        });
      }
      setSelectedRow({ id: input.id, detailType: input.detailType, title: "", subTitle: "" });
    },
    [navigate, reload, setIsSlidedIn, setSelectedRow]
  );

  if (!selectedRow) {
    return null;
  }

  return (
    <>
      <Slide direction="left" in={isSlidedIn} mountOnEnter unmountOnExit>
        <Box sx={sx.taskDetailsOverlay}>
          <TaskDetails
            detailType={selectedRow.detailType}
            reload={reloadMetaview || undefined}
            setIsSlidedIn={setIsSlidedIn}
            taskId={selectedRow.id}
            onCloseTaskDetails={onCloseTaskDetails}
            onGroupTaskDelete={onGroupTaskDeleteInMeta}
            onPageChange={onPageChange}
            onRecurringTaskDelete={onRecurringTaskDeleteInMeta}
            onTaskDelete={onTaskDeleteInMeta}
            onTaskEdit={onTaskEditInMeta}
          />
        </Box>
      </Slide>
    </>
  );
}
