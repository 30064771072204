import React, { useCallback } from "react";
import { Box } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useTheme } from "@mui/material/styles";
import { MetaViewTabProps } from "components/MetaView/MetaView";

interface MetaViewTabsProps {
  readonly initialTabId?: string;
  readonly tabs: Array<
    Omit<MetaViewTabProps, "tabId"> & {
      tabId: string;
    }
  >;
}

export default function GenericMetaViewTabs({ tabs, initialTabId }: MetaViewTabsProps) {
  const [state, setState] = React.useState({
    tabId: initialTabId || tabs[0].tabId
  });
  const activeTab = React.useMemo(() => {
    return tabs.find(tab => tab.tabId === state.tabId);
  }, [tabs, state.tabId]);
  const theme = useTheme();

  const onTabChangeCallback = useCallback((event, value) => {
    setState({ tabId: value.tabId });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          background: theme.palette.blue?.[50] || "#e3f2fd",
          height: "80px",
          width: "100%",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            height: "6px"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0.2)",
            borderRadius: "3px"
          }
        }}
      >
        <BottomNavigation
          value={activeTab}
          onChange={onTabChangeCallback}
          sx={{
            width: "100%",
            minWidth: "fit-content",
            background: "transparent",
            marginTop: "12px",
            "& .MuiBottomNavigationAction-root": {
              minWidth: "60px",
              padding: "6px 4px"
            },
            "& .MuiBottomNavigationAction-label": {
              marginTop: "6px",
              fontSize: "12px"
            }
          }}
        >
          {tabs.map((tab, index) => (
            <BottomNavigationAction key={index} label={tab.title} value={tab} icon={tab.icon} />
          ))}
        </BottomNavigation>
      </Box>
      <Box
        sx={{
          flex: 1,
          overflow: "auto"
        }}
      >
        {activeTab?.content}
      </Box>
    </Box>
  );
}
