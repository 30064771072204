import { Box, IconButton, SvgIcon, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import { t } from "i18next";
import stopEvent from "tool/stopEvent";
import { useDocumentPermissions } from "hook/useDocumentPermissions";
import DriveFileMoveIcon from "assets/images/icons/driveFileMove.svg";

const sx = {
  "& svg": {
    opacity: 0.5
  }
};

const DocumentOverviewBrickMove = ({ item, hovered, setOpenMoveModal, setItemToMove }: OverviewRowBrickProps) => {
  const onClickCallback = useCallback(
    event => {
      stopEvent(event);
      stopEvent(event);
      setItemToMove?.(item);
      setOpenMoveModal?.(true);
    },
    [item, setOpenMoveModal, setItemToMove]
  );

  const { canMoveFileOrFolder } = useDocumentPermissions(item);

  if (!hovered || item.disableActions?.some(({ action }) => action === "move")) {
    return <></>;
  }

  return (
    <Tooltip title={canMoveFileOrFolder ? t("documentCenter:move") : t("common:missingPermission")}>
      <Box>
        <IconButton onClick={onClickCallback} disabled={!canMoveFileOrFolder} size="small">
          <SvgIcon sx={{ ...(!canMoveFileOrFolder ? sx : {}) }}>
            <DriveFileMoveIcon />
          </SvgIcon>
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default DocumentOverviewBrickMove;
