import { COLLECTIONS } from "app/collections";
import { useUserDepartments } from "app/contexts/department-context";
import { addOrganisationalUnit, editNameOfOrganisationalUnit, exportOrgUnits } from "app/handlers/departmentHandler";
import { AxiosInstance } from "axios";
import overviewBaseController, {
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getOrganizationFilter } from "../shared/Filters/OrganizationFilter";
import { departmentsDecorator } from "components/Overview/controllers/decorator/departmentsDecorator";

const OrganizationOverviewController = (axiosInstance: AxiosInstance) => {
  const { t } = useTranslation();
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.ORGANIZATIONS, undefined, [
    departmentsDecorator
  ]);
  const { reloadDepartments } = useUserDepartments();

  const getFilters = useCallback(
    (overviewResult: OverviewResult) => {
      return [getOrganizationFilter("id", overviewResult._departments, t, undefined)];
    },
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: t("filter_criteria:aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: t("filter_criteria:zToA")
      }
    ],
    [t]
  );

  const getOverview = async (setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup, `/overview`);
    if (!data) {
      return null;
    }

    return {
      ...data,
      sortings: getSortings(),
      filters: getFilters(data)
    };
  };

  const patchItem = async (docId: string, data: { readonly title: string }) => {
    await editNameOfOrganisationalUnit({
      name: data.title,
      docId
    });
    await reloadDepartments();
  };

  const addItem = async (data: OverviewNewItem) => {
    if (data.title?.trim()) {
      await addOrganisationalUnit({ data: { name: data.title?.trim(), parentId: data.parentId || null } });
      await reloadDepartments();
    }
  };

  const goToItem = () => {
    // do nothing
  };

  const exportAllItems = async () => {
    return exportOrgUnits();
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    patchItem,
    addItem,
    exportAllItems
  };
};

export default OrganizationOverviewController;
