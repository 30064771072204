import React, { useCallback, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { useMetaView } from "../../../contexts/meta-view-context";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useNavigate } from "react-router-dom";
import { userWritePermissions } from "../../../handlers/permissionHandler";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";
import { OverviewBrickProps } from "components/Overview/controls/OverviewRow";
import { UserDeletionDialog } from "./UserDeletionDialog";

const bricks = [
  { component: OverviewBrick.Title },
  { component: OverviewBrick.UserRole },
  { component: OverviewBrick.UserResetPassword, position: "right" },
  { component: OverviewBrick.UserDelete, position: "right" }
] as OverviewBrickProps[];

const UsersOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useAuthentication();
  const { selectedId: selectedUserIdToBeDeleted } = useOverviewState()[COLLECTIONS.USER];
  const dispatch = useOverviewDispatch();
  const { setInfo, setMeta } = useMetaView();
  const [currentPage, setCurrentPage] = useState<string>("users");

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("users_overview:defaultInfoTitle"),
        text: t("users_overview:defaultInfoText")
      }
    }),
    [t]
  );

  const showGroups = useIsFeaturePresent(FEATURES.USERGROUPS_FEATURES) && auth?.permissions.includes("group_read_all");
  const showRoles = useIsFeaturePresent(FEATURES.USERROLES_FEATURES) && auth?.permissions.includes("role_read_all");

  const pages = useMemo(() => {
    const pages = [{ title: t("users_overview:users"), route: "users", current: currentPage === "users" }];
    if (showGroups) {
      pages.push({ title: t("users_overview:groups"), route: "groups", current: currentPage === "groups" });
    }
    if (showRoles && auth?.permissions.includes("super_admin")) {
      pages.push({ title: t("users_overview:roles"), route: "roles", current: currentPage === "roles" });
    }
    return pages;
  }, [t, currentPage, showGroups, showRoles, auth?.permissions]);

  const onPageChange = useCallback(
    ({ route }) => {
      navigate("/" + route);
      dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.USER, selectedId: null });
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW, collection: COLLECTIONS.USER, reloadOverview: Date.now() });
    },
    [dispatch, navigate]
  );

  const showEnterInfo = useCallback(() => {
    setMeta(null);
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo, setMeta]);

  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }], []);
  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }], []);

  const selectionActions = useMemo(() => [{ action: "remove" }], []);
  const showAddActions = auth?.permissions.find(permission => userWritePermissions.includes(permission));

  const userDeletionCancelled = useCallback(() => {
    dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.USER, selectedId: null });
  }, [dispatch]);
  const userDeletionConfirmed = useCallback(() => {
    dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.USER, selectedId: null });
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.USER,
      reloadOverview: Date.now(),
      shadowLoading: true
    });
  }, [dispatch]);

  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"users_overview"} />}>
      <Overview
        pages={pages}
        onPageChange={onPageChange}
        collection={COLLECTIONS.USER}
        onAddClose={showEnterInfo}
        selectionActions={selectionActions}
        onRowLeave={showEnterInfo}
        header={t("users_overview:header")}
        bricks={bricks}
        addActions={showAddActions ? addActions : undefined}
        toolbarActions={toolbarActions}
        checkable={true}
        showSelectAll={true}
      />
      {selectedUserIdToBeDeleted ? (
        <UserDeletionDialog
          open={!!selectedUserIdToBeDeleted}
          onClose={userDeletionCancelled}
          toBeDeletedUserId={selectedUserIdToBeDeleted || ""}
          onUserDeleted={userDeletionConfirmed}
        />
      ) : (
        <></>
      )}
    </DocMetaView>
  );
};

export default UsersOverview;
