import { Box, Chip } from "@mui/material";
import colors from "theme/palette/colors";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const sx = {
  root: {
    display: "flex",
    gap: "8px"
  },
  common: {
    padding: "6px 10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    height: "24px",
    "& .MuiChip-label": {
      fontSize: "11px",
      lineHeight: "12px",
      textTransform: "uppercase",
      fontWeight: 600,
      color: colors.grey.grey500,
      padding: 0
    }
  },
  participant: {
    textTransform: "uppercase",
    backgroundColor: colors.orange.orange500,
    "& .MuiChip-label": {
      fontSize: "11px",
      lineHeight: "12px",
      textTransform: "uppercase",
      fontWeight: 600,
      color: colors.white,
      letterSpacing: "0.5px",
      padding: 0
    }
  },
  contributor: {
    textTransform: "uppercase",
    backgroundColor: colors.blue.blue300,
    "& .MuiChip-label": {
      fontSize: "11px",
      lineHeight: "12px",
      textTransform: "uppercase",
      fontWeight: 600,
      color: colors.white,
      letterSpacing: "0.5px",
      padding: 0
    }
  }
};

const AnswersetOverviewBrickRoles = ({ item }: OverviewRowBrickProps) => {
  const { auth } = useAuthentication();
  const { t } = useTranslation();

  const allChips = useMemo(
    () => ({
      participant: <Chip sx={{ ...sx.common, ...sx.participant }} label={t("add_participant:participant")} />,
      contributor: <Chip sx={{ ...sx.common, ...sx.contributor }} label={t("add_participant:contributor")} />
    }),
    [t]
  );

  const { assigneeUID, participantsUIDs } = item;
  return (
    <Box sx={sx.root}>
      {auth?.uid === assigneeUID && allChips.participant}
      {participantsUIDs.includes(auth?.uid || "") && allChips.contributor}
    </Box>
  );
};

export default AnswersetOverviewBrickRoles;
