import React from "react";
import { Box, Typography, Button } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import Overview from "components/Overview/Overview";
import { COLLECTIONS } from "app/collections";
import { sx } from "../HomeStyles";
import { useTranslation } from "react-i18next";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { OverviewPageProps } from "components/Overview/controls/OverviewPagesMenu";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";
import { OverviewBrickProps } from "components/Overview/controls/OverviewRow";

const bricks: OverviewBrickProps[] = [
  { component: OverviewBrick.TaskStatus },
  { component: OverviewBrick.Title },
  { component: OverviewBrick.TaskPriority },
  { component: OverviewBrick.TaskGroup },
  { component: OverviewBrick.TaskRecurring },
  { component: OverviewBrick.TaskDueDate },
  { component: OverviewBrick.TaskAssignUser, position: "right" },
  { component: OverviewBrick.TaskAssignGroup, position: "right" },
  { component: OverviewBrick.Remove, position: "right" }
];

const TaskOverviewSection = ({
  noEntriesEl,
  onNewTask,
  onRowClick,
  onSeeAllTasks,
  pages,
  title
}: {
  noEntriesEl: React.ReactNode;
  onNewTask: () => void;
  onRowClick: (row: OverviewItem) => void;
  onSeeAllTasks: () => void;
  pages: OverviewPageProps[] | undefined;
  title: string;
}) => {
  const { t } = useTranslation("homePage");

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography sx={sx.taskOverviewTitle}>{title}</Typography>
        <Button variant="text" color="primary" onClick={onSeeAllTasks} sx={sx.seeAllTasksButton}>
          {t("see_all_tasks")}
        </Button>
        <Button variant="contained" color="primary" onClick={onNewTask} sx={{ ...sx.newTaskButton }}>
          <AddIcon />
          {t("new_task")}
        </Button>
      </Box>
      <Box sx={sx.taskOverview}>
        <Overview
          collection={COLLECTIONS.HOME}
          noEntriesCustomEl={noEntriesEl}
          onRowClick={onRowClick}
          pages={pages}
          paginated={true}
          bricks={bricks}
          selectable={true}
          toolbarMode="none"
        />
      </Box>
    </>
  );
};

export default TaskOverviewSection;
