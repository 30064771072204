import React, { useCallback, useEffect, useMemo, useState } from "react";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Grid from "@material-ui/core/Grid";
import DocView from "components/DocView/DocView";
import { DataBreachesPageButtons, DataBreachesPageStepper } from "./DataBreachesPagination";
import { useTranslation } from "react-i18next";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import { getDataBreachById, updateDataBreach } from "../../handlers/dataBreachHandler";
import { Box, Chip, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import { AttachmentsOverviewOBS } from "../shared/Attachments/AttachmentsOverviewOBS";
import { useMetaView } from "app/contexts/meta-view-context";
import Question from "components/Question/Question";
import Checkbox from "@material-ui/core/Checkbox";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "../../features";
import { DataBreachesMetaView } from "./DataBreachesMetaView";
import { useAuthentication } from "../../handlers/authentication/authentication-context";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import { DataBreachDTO } from "app/api/dataBreachApi";
import { COLLECTIONS } from "app/collections";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useUserDepartments } from "../../contexts/department-context";
import { toLegacyCategoryTypeTuple } from "../questionnaires/utils/helper-functions";
import { tDataCategoryClassification, tDataTypeClassification } from "app/handlers/dataTypeTranslatorHandler";
import { useResources } from "app/contexts/resource-context";

const DataBreachesEvaluationPage = ({ documentId }: { readonly documentId: string }) => {
  const { t, i18n } = useTranslation("data_breaches_evaluation_page");
  const { isPartOfUserDepartments } = useUserDepartments();
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [dataBreach, setDataBreach] = useState<DataBreachDTO | null>(null);
  const [answersLoaded, setAnswersLoaded] = useState(false);
  const { setInfo, setMeta } = useMetaView();
  const dataTypeTree = useDataTypeTree();
  const { resources } = useResources();
  const dataTypeCategoryPersonGroupList = React.useMemo(() => dataTypeTree?.data || [], [dataTypeTree?.data]);
  const { auth } = useAuthentication();

  const patch = useCallback(
    async (data: Partial<DataBreachDTO>) => {
      setDataBreach(current => (current ? { ...current, ...data } : null));
      if (!auth) {
        return;
      }
      const db = await updateDataBreach(documentId, data, auth);
      setDataBreach(db);
    },
    [auth, documentId]
  );

  const noWritePermission = useMemo(() => {
    if (!auth) return true;

    if (!dataBreach?.orgUnitId) return false;
    if (
      dataBreach?.assigneeUID === auth.uid ||
      dataBreach?.dpoUID === auth.uid ||
      auth.permissions.includes("db_write_all")
    )
      return false;
    if (isPartOfUserDepartments(...[dataBreach.orgUnitId])) return false;
    return true;
  }, [dataBreach, isPartOfUserDepartments, auth]);

  // get all data types of person group as options, get selected data types by ids of person group
  const getPersonGroupDataTypesByIds = useCallback(
    (dataTypeIds: string[], personGroup: string) => {
      const categoriesForPersonGroup = dataTypeCategoryPersonGroupList.filter(element => element.id === personGroup);
      const selectedDataTypeArray: DataTypeModel[] = [];
      const optionsDataTypeArray: DataTypeModel[] = [];
      categoriesForPersonGroup[0]?.dataCategories?.forEach(category => {
        category.dataTypes
          .filter(type => !type.mergedIntoId)
          .forEach(type => {
            const dataTypeObject = {
              category: category.id || "",
              type: type.id,
              personGroup: personGroup,
              id: type.id
            };
            if (dataTypeIds?.includes(type.id)) {
              selectedDataTypeArray.push(dataTypeObject);
            }
            optionsDataTypeArray.push(dataTypeObject);
          });
      });
      return { selectedDataTypeArray: selectedDataTypeArray, optionsDataTypeArray: optionsDataTypeArray };
    },
    [dataTypeCategoryPersonGroupList]
  );
  // update lists of dataType options and selected data types -> executed when entering page and selected person groups are updated
  const updateDataTypes = useCallback(
    (dataTypeIds: string[], personGroupIds: string[], enableSave: boolean) => {
      if (personGroupIds) {
        let optionsDataTypes: DataTypeModel[] = [];
        let selectedDataTypes: DataTypeModel[] = [];
        personGroupIds?.forEach(personGroup => {
          const {
            selectedDataTypeArray: selectedDataTypesOfPersonGroup,
            optionsDataTypeArray: optionsDataTypesOfPersonGroup
          } = getPersonGroupDataTypesByIds(dataTypeIds, personGroup);
          optionsDataTypes = optionsDataTypes.concat(optionsDataTypesOfPersonGroup);
          selectedDataTypes = selectedDataTypes.concat(selectedDataTypesOfPersonGroup);
        });
        if (enableSave) {
          const dataTypeIds: string[] = selectedDataTypes.map(element => element.id || "").filter(notNull => notNull);
          patch({ dataTypeIds });
        }
        setDataTypesOptions(optionsDataTypes);
        setSelectedDataTypes(selectedDataTypes);
      }
    },
    [getPersonGroupDataTypesByIds, patch]
  );

  useEffect(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("enteringInfoCardText")
    });
  }, [i18n.language, setInfo, t]);

  const fetch = useCallback(async () => {
    const data = await getDataBreachById(documentId);
    if (!data) {
      setDocumentNotFound(true);
      return;
    }
    setDataBreach(data);

    updateDataTypes(data.dataTypeIds, data.personGroupIds, false);

    setAnswersLoaded(true);
  }, [documentId, updateDataTypes]);

  useEffect(() => {
    if (dataBreach === null) {
      fetch();
    }
  }, [dataBreach, dataTypeTree.isValidating, fetch]);

  /* PERSON GROUPS AND DATA TYPES */
  interface DataTypeModel {
    readonly category: string;
    readonly type: string;
    readonly personGroup: string;
    readonly id: string;
  }

  const [dataTypesOptions, setDataTypesOptions] = useState<DataTypeModel[]>([]);
  const [selectedDataTypes, setSelectedDataTypes] = useState<DataTypeModel[]>([]);
  const personGroupInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("person_groups_info")
    }),
    [t]
  );

  const onPersonGroupChange = useCallback(
    (personGroupIds: string[]) => {
      patch({ personGroupIds });
      updateDataTypes(dataBreach?.dataTypeIds || [], personGroupIds, true);
    },
    [dataBreach?.dataTypeIds, patch, updateDataTypes]
  );

  const personGroupEl = (
    <Question
      qType={QUESTION_TYPE.PERSON_GROUP}
      questionId={"dataTypeIdsPersonGroups"}
      questionName={t("person_groups")}
      multiSelect={true}
      value={dataBreach?.personGroupIds || []}
      onChange={onPersonGroupChange}
      info={personGroupInfo}
      disabled={noWritePermission}
    />
  );

  /* DATA TYPES */
  const dataTypesInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("data_types_info")
    }),
    [t]
  );
  const getDataTypesOptionLabel = useCallback(
    (dataType: DataTypeModel, isCategory = false) => {
      const categoryTypeTuple = toLegacyCategoryTypeTuple(dataType, dataTypeTree.dataById);
      if (!categoryTypeTuple) {
        return "";
      }

      // disable category display during adding of new option
      if (isCategory && categoryTypeTuple.toString().includes(t("dataType:addText"))) {
        return;
      }

      if (isCategory) {
        return tDataCategoryClassification({
          t,
          dataType: categoryTypeTuple,
          classifications: resources[RESOURCE_TYPES.DATA_CLASSIFICATION],
          dataTypeTree: dataTypeTree.data
        });
      }
      return tDataTypeClassification({
        t,
        dataType: categoryTypeTuple,
        classifications: resources[RESOURCE_TYPES.DATA_CLASSIFICATION]
      });
    },
    [dataTypeTree.data, dataTypeTree.dataById, resources, t]
  );
  const dataTypeGroupBy = useCallback(
    (dataType: DataTypeModel) => getDataTypesOptionLabel(dataType, true),
    [getDataTypesOptionLabel]
  );
  const getSelectedDataTypeLabel = useCallback(
    (dataType: DataTypeModel) => {
      const selectedValue = toLegacyCategoryTypeTuple(dataType, dataTypeTree.dataById);
      let typeString;
      let categoryString;
      // if data type is deleted, then deleted entry text is used
      if (selectedValue.typeDeleted) {
        typeString = t("resources_lists_data_types_categories_person_groups:deletedEntry");
      } else if (i18n.exists("lists_data_types_categories_person_groups:" + selectedValue.type)) {
        typeString = t("lists_data_types_categories_person_groups:" + selectedValue.type);
      } else {
        typeString = selectedValue.type;
      }
      // if data category is deleted, then deleted entry text is used
      if (selectedValue.categoryDeleted) {
        categoryString = t("resources_lists_data_types_categories_person_groups:deletedEntry");
      } else if (i18n.exists("lists_data_types_categories_person_groups:" + selectedValue.category)) {
        categoryString = t("lists_data_types_categories_person_groups:" + selectedValue.category);
      } else {
        categoryString = selectedValue.category;
      }
      return typeString + " [" + categoryString + "]";
    },
    [dataTypeTree.dataById, i18n, t]
  );
  const renderTags = useCallback(
    (dataTypes: DataTypeModel[], getTagProps) => {
      return dataTypes.map((option, index) => (
        <Chip key={"chip" + index} {...getTagProps({ index })} label={getSelectedDataTypeLabel(option)} />
      ));
    },
    [getSelectedDataTypeLabel]
  );
  const getOptionSelected = useCallback((optionItem: DataTypeModel, currentCheckedItem: DataTypeModel) => {
    return optionItem.id === currentCheckedItem.id;
  }, []);
  const onDataTypesChange = useCallback(
    (dataTypes: DataTypeModel[]) => {
      setSelectedDataTypes(dataTypes);
      const dataTypeIds = dataTypes.map(element => element.id);
      patch({ dataTypeIds: dataTypeIds });
    },
    [patch]
  );
  const dataTypeEl = (
    <Question
      questionId={"dataTypeIds"}
      questionName={t("data_types")}
      info={dataTypesInfo}
      hidden={!dataBreach?.personGroupIds?.length}
      disabled={noWritePermission}
    >
      <MultiAutocomplete
        disableClearable={false}
        selected={selectedDataTypes || []}
        groupBy={dataTypeGroupBy}
        label={t("data_types")}
        hasMultiSelect={true}
        options={dataTypesOptions || []}
        getOptionLabel={getDataTypesOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        renderTags={renderTags}
        updateSelected={onDataTypesChange}
        disabled={noWritePermission}
      />
    </Question>
  );

  /* NUMBER DATA SUBJECTS */
  const numberDataSubjectInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("data_subjects_info")
    }),
    [t]
  );
  const onNumberDataSubjectChnage = useCallback((numberDataSubjects: number) => patch({ numberDataSubjects }), [patch]);

  const numberDataSubjectEl = (
    <Question
      qType={QUESTION_TYPE.NUMBER}
      questionId={"numberDataSubjects"}
      questionName={t("data_subjects")}
      value={dataBreach?.numberDataSubjects || ""}
      onChange={onNumberDataSubjectChnage}
      info={numberDataSubjectInfo}
      disabled={noWritePermission}
    />
  );

  /* NUMBER DATA SETS */
  const numberDataSetsInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("data_sets_info")
    }),
    [t]
  );
  const onNumberDataSetsChange = useCallback((numberDataSets: number) => patch({ numberDataSets }), [patch]);

  const numberDataSetsEl = (
    <Question
      qType={QUESTION_TYPE.NUMBER}
      questionId={"numberDataSets"}
      questionName={t("data_sets")}
      value={dataBreach?.numberDataSets || ""}
      onChange={onNumberDataSetsChange}
      info={numberDataSetsInfo}
      disabled={noWritePermission}
    />
  );

  /* INCIDENT IMPACT IDS */
  const incidentImpactIdsInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("impact_info")
    }),
    [t]
  );
  const onIncidentImpactIdsChange = useCallback((incidentImpactIds: string[]) => patch({ incidentImpactIds }), [patch]);
  const incidentImpactIdsEl = (
    <Question
      qType={QUESTION_TYPE.RESOURCE}
      resourceType={RESOURCE_TYPES.DATABREACH_IMPACT}
      questionId={"incidentImpactIds"}
      questionName={t("incidentImpactIds")}
      value={dataBreach?.incidentImpactIds || []}
      onChange={onIncidentImpactIdsChange}
      allowAdd={true}
      multiSelect={true}
      info={incidentImpactIdsInfo}
      hidden={!useIsFeaturePresent(FEATURES.DATABREACH_IMPACT)}
      disabled={noWritePermission}
      orgUnitIds={dataBreach ? [dataBreach.orgUnitId || "", ...(dataBreach.furtherOrgUnitIds || [])] : []}
    />
  );
  /* IMPACT */
  const impactInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("impact_info")
    }),
    [t]
  );
  const onImpactChange = useCallback((impact: string) => patch({ impact }), [patch]);
  const impactEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_EDITOR}
      questionId={"impact"}
      questionName={t("possible_consequences")}
      translatable={dataBreach?.impact || ""}
      translationId={"impact"}
      value={dataBreach?.impact || ""}
      onChange={onImpactChange}
      info={impactInfo}
      disabled={noWritePermission}
    />
  );

  /* MEASURE TAKEN IDS */
  const measureTakenIdsInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("measures_to_take_info")
    }),
    [t]
  );
  const onMeasureTakenIdsChange = useCallback((measureTakenIds: string[]) => patch({ measureTakenIds }), [patch]);
  const measureTakenIdsEl = (
    <Question
      qType={QUESTION_TYPE.RESOURCE}
      resourceType={RESOURCE_TYPES.DATABREACH_MEASURE}
      questionId={"measureTakenIds"}
      questionName={t("measureTakenIds")}
      value={dataBreach?.measureTakenIds || []}
      onChange={onMeasureTakenIdsChange}
      allowAdd={true}
      multiSelect={true}
      info={measureTakenIdsInfo}
      hidden={!useIsFeaturePresent(FEATURES.DATABREACH_MEASURE)}
      disabled={noWritePermission}
      orgUnitIds={dataBreach ? [dataBreach.orgUnitId || "", ...(dataBreach.furtherOrgUnitIds || [])] : []}
    />
  );

  /* MEASURES TAKEN */
  const measuresTakenInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("measures_taken_info")
    }),
    [t]
  );
  const onMeasuresTakenChange = useCallback((measuresTaken: string) => patch({ measuresTaken }), [patch]);
  const measuresTakenEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_EDITOR}
      questionId={"measuresTaken"}
      translationId={"measuresTaken"}
      questionName={t("measures_taken")}
      translatable={dataBreach?.measuresTaken || ""}
      value={dataBreach?.measuresTaken || ""}
      onChange={onMeasuresTakenChange}
      info={measuresTakenInfo}
      disabled={noWritePermission}
    />
  );

  /* MEASURE TO TAKE */
  const measuresToTakeInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("measures_to_take_info")
    }),
    [t]
  );
  const onmeasuresToTakeChange = useCallback((measuresToTake: string) => patch({ measuresToTake }), [patch]);
  const measuresToTakeEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_EDITOR}
      questionId={"measuresToTake"}
      translationId={"measuresToTake"}
      questionName={t("measures_to_take")}
      translatable={dataBreach?.measuresToTake || ""}
      value={dataBreach?.measuresToTake || ""}
      onChange={onmeasuresToTakeChange}
      info={measuresToTakeInfo}
      disabled={noWritePermission}
    />
  );

  /* DATA SUBJECT INFORMED */
  const dataBreachesDataSubjectInformedFeature = useIsFeaturePresent(FEATURES.DATA_BREACHES_DATA_SUBJECT_INFORMED);
  const onDataSubjectInformedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, dataSubjectInformed: boolean) => patch({ dataSubjectInformed }),
    [patch]
  );
  const dataSubjectInformedEl = (
    <Question
      questionId={"dataSubjectInformed"}
      questionName={t("dataSubjectInformed")}
      hidden={!dataBreachesDataSubjectInformedFeature}
      disabled={noWritePermission}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={dataBreach?.dataSubjectInformed || false}
            onChange={onDataSubjectInformedChange}
            name="dataSubjectInformed"
            color="primary"
          />
        }
        disabled={noWritePermission}
        label={t("dataSubjectInformed")}
      />
    </Question>
  );

  /* REPORT INCIDENT */
  const currentReportIncident = useMemo(() => {
    if (dataBreach?.reportIncident === false) {
      return "no";
    } else if (dataBreach?.reportIncident === true) {
      return "yes";
    }
    return "";
  }, [dataBreach]);
  const onReportIncidentYesChange = useCallback(() => patch({ reportIncident: true }), [patch]);
  const onReportIncidentNoChange = useCallback(() => patch({ reportIncident: false }), [patch]);

  const reportIncidentEl = (
    <Question questionId={"reportIncident"} questionName={t("incident_reported")} disabled={noWritePermission}>
      <RadioGroup row name="isDataSubjectVerified" value={currentReportIncident}>
        <Grid item xs={12}>
          <FormControlLabel
            id={"incident_reported"}
            label={t("incident_reported")}
            value={"yes"}
            disabled={noWritePermission}
            control={<Radio onClick={onReportIncidentYesChange} color={"primary"} />}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            id={"incident_not_reported"}
            value={"no"}
            label={t("incident_not_reported")}
            disabled={noWritePermission}
            control={<Radio onClick={onReportIncidentNoChange} color={"primary"} />}
          />
        </Grid>
      </RadioGroup>
    </Question>
  );
  const reportIncidentAlertEl = useMemo(
    () =>
      dataBreach?.reportIncident && (
        <Box mt={2}>
          <CustomAlert id="alert" severity={"error"}>
            {t("alert_text")}
          </CustomAlert>
        </Box>
      ),
    [dataBreach?.reportIncident, t]
  );

  const noReportReasonInfo = useMemo(
    () => ({
      title: t("info_title"),
      text: t("no_report_reason_info")
    }),
    [t]
  );
  const onNoReportReasonChanged = useCallback((noReportReason: string) => patch({ noReportReason }), [patch]);

  const noReportReasonEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_EDITOR}
      questionId={"noReportReason"}
      translationId={"npReportReason"}
      questionName={t("noReportReason")}
      translatable={dataBreach?.noReportReason || ""}
      value={dataBreach?.noReportReason || ""}
      onChange={onNoReportReasonChanged}
      info={noReportReasonInfo}
      disabled={noWritePermission}
    />
  );

  const docViewContent = answersLoaded && (
    <DocView header={dataBreach?.title || ""} pagination={<DataBreachesPageStepper />}>
      <QuestionnaireSubHeader text={t("title_data")} />
      {personGroupEl}
      {dataTypeEl}
      <Box display="flex">
        <Box flex="1" mr={3}>
          {numberDataSubjectEl}
        </Box>
        <Box flex="1" ml={3}>
          {numberDataSetsEl}
        </Box>
      </Box>
      <Box mt={3} />
      <QuestionnaireSubHeader text={t("impact")} />
      <Box mt={-2} />
      {incidentImpactIdsEl}
      {impactEl}
      <Box mt={3} />
      <QuestionnaireSubHeader text={t("measures")} />
      <Box mt={-2} />
      {measureTakenIdsEl}
      {measuresTakenEl}
      {measuresToTakeEl}
      <Box mt={4} />
      {dataBreachesDataSubjectInformedFeature && <QuestionnaireSubHeader text={t("dataSubjectRights")} />}
      {dataSubjectInformedEl}
      <QuestionnaireSubHeader text={t("evaluation")} />
      {reportIncidentEl}
      {reportIncidentAlertEl}
      <Box marginTop={-2}>{dataBreach?.reportIncident === false && noReportReasonEl}</Box>
      <Box mt={4} />
      <QuestionnaireSubHeader text={t("attachments")} />
      <AttachmentsOverviewOBS
        docId={documentId}
        category={"dataBreach"}
        setMeta={setMeta}
        disabled={noWritePermission}
      />
      <DataBreachesPageButtons />
    </DocView>
  );

  return (
    <DocMetaView
      collection={COLLECTIONS.DATA_BREACHES}
      notFound={documentNotFound}
      docViewContent={docViewContent}
      metaViewContent={<DataBreachesMetaView docName={dataBreach?.title || ""} />}
    />
  );
};

export default DataBreachesEvaluationPage;
