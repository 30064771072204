import overviewBaseController, {
  OverviewController,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import { getAuditsStatusesTypeFilter } from "app/pages/shared/Filters/filters";
import { AxiosInstance } from "axios";
import i18n from "app/i18n";
import { departmentsDecorator } from "components/Overview/controllers/decorator/departmentsDecorator";
import { getOrganizationFilter } from "../../../shared/Filters/OrganizationFilter";
import { CollectionParams } from "hook/useOverviewData";

const AssessmentResponseOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: CollectionParams
): OverviewController => {
  // the controller is a pure TS file, if it uses state, the value will not be updated
  const t = i18n.t;

  const options = collectionParams;

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.ASSESSMENT_RESPONSE, undefined, [
    departmentsDecorator
  ]);

  const getFilters = (overviewResult: OverviewResult) => {
    return [
      getOrganizationFilter("orgUnitId", overviewResult._departments, t, undefined),
      getAuditsStatusesTypeFilter("status", overviewResult.filters?.statuses || [], t)
    ].filter(nonNull => nonNull?.filterTree?.length);
  };

  const getSortings = () => [
    {
      field: "title",
      type: "asc",
      label: t("filter_criteria:aToZ")
    },
    {
      field: "title",
      type: "desc",
      label: t("filter_criteria:zToA")
    },
    {
      field: "createdAt",
      type: "desc",
      label: t("filter_criteria:newFirst")
    },
    {
      field: "createdAt",
      type: "asc",
      label: t("filter_criteria:oldFirst")
    }
  ];

  const getCurrentPage = () => window.location.pathname.split("/").pop();

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup, `/overviews/audit/${options.auditId}/answers`);
    if (!data) {
      options.setAllAssessmentResponseIds([]);
      return null;
    }

    options.setAllAssessmentResponseIds((data?.items || []).map(({ id }) => id));
    return {
      ...data,
      items: data?.items || [],
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = () => {
    // do nothing
  };

  const deleteItem = async (id: string) => {
    const item = baseController.getById(id);
    return await baseController.deleteItem(id, `/assessments/${item?.auditId}/answerset/${id}`);
  };

  const addItemsFromTemplates = async (data: { templateIds: string[] }) => {
    return await baseController.addItemsFromTemplates(data, `overviews/templates`);
  };

  const copyItems = async ({ ids }: { ids: string[] }) => {
    await axiosInstance.post(`/audits/${getCurrentPage() === "templates" ? "templates" : "audits"}/copies`, {
      ids
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportItems = async (_format: string, ids: string[], _setup: OverviewSetup) => {
    await collectionParams?.exportToXLSX?.(ids);
  };

  const exportAllItems = async (format: string, setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup);
    if (data) {
      const ids = data.allItems.map(({ id }) => id);
      await exportItems(format, ids, setup);
    }
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    deleteItem,
    addItemsFromTemplates,
    copyItems,
    exportItems,
    exportAllItems
  };
};

export default AssessmentResponseOverviewController;
