import { Box, Tooltip } from "@mui/material";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useTranslation } from "react-i18next";

type statuses = "edit" | "review" | "approved";
const statusIconMap: Record<statuses, React.ReactNode> = {
  edit: <LazySvgIcon name="In_Progress" />,
  review: <LazySvgIcon name="In_Review" />,
  approved: <LazySvgIcon name="Completed" />
};

const ProcessOverviewBrickStatus = ({ item }: OverviewRowBrickProps) => {
  const { t } = useTranslation("processes_overview");

  const { status } = item;
  if (!status) {
    return <></>;
  }

  return (
    <Tooltip title={t(`status_${status}`)}>
      <Box sx={{ height: "24px" }}>{statusIconMap[status as statuses]}</Box>
    </Tooltip>
  );
};

export default ProcessOverviewBrickStatus;
