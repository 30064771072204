import React, { useCallback, useMemo } from "react";
import { changeLastUrlPath } from "./url-handler";
import { useNavigate } from "react-router-dom";
import { usePathName } from "../../app/router/router-custom-hooks";
import Stepper, { StepperGroupProps } from "components/Stepper/Stepper";
import { t } from "i18next";

export interface PageStep {
  readonly path: string;
  readonly label: string;
  readonly icon?: React.ReactNode;
}

export default function GeneralPageStepper({
  currentPath,
  steps,
  onStepChange,
  skipNavigate
}: {
  readonly currentPath?: string;
  readonly steps: PageStep[];
  readonly skipNavigate?: boolean;
  readonly onStepChange?: (step: PageStep) => void;
}) {
  const navigate = useNavigate();
  const path = usePathName();

  const stepClicked = useCallback(
    step => {
      if (!skipNavigate) {
        navigate(changeLastUrlPath(path, step.path));
      }
      if (onStepChange) {
        onStepChange(step);
      }
    },
    [skipNavigate, onStepChange, navigate, path]
  );

  const currentPathValue = currentPath || path.split("/").pop();
  const _steps = useMemo(() => {
    return steps.map((s, index) => ({
      ...s,
      id: (index + 1).toString(),
      number: index + 1,
      selected: s.path === currentPathValue
    }));
  }, [currentPathValue, steps]);
  const _groups = useMemo<StepperGroupProps[]>(() => {
    return [
      {
        id: "dpia",
        name: t("questionnaires:dpia_delete")
      },
      {
        id: "pa",
        name: t("questionnaires:pa_delete")
      },
      {
        id: "aiact",
        name: t("questionnaires:ai_delete")
      }
    ] as StepperGroupProps[];
  }, []);
  return <Stepper groups={_groups} steps={_steps} onClick={stepClicked} />;
}
