import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthentication } from "../../handlers/authentication/authentication-context";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { createTask, TYPES } from "app/handlers/tasksHandler";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import noTasksGraphic from "assets/images/home/no_tasks.svg";
import { Box } from "@material-ui/core";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import { useSnackbar } from "notistack";

export const useHomeLogic = () => {
  const { t } = useTranslation("homePage");
  const { auth } = useAuthentication();
  const { getUserFirstNameHook, addToSeenItemsOfUserHook } = useUserAndTenantData();
  const dispatch = useOverviewDispatch();
  const { id } = useParams();
  const { selectedId } = useOverviewState()[COLLECTIONS.HOME];
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isSlidedIn, setIsSlidedIn] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<OverviewItem | null>(null);

  const name = useMemo(() => {
    if (!auth?.uid) return "";
    return getUserFirstNameHook(auth?.uid);
  }, [auth?.uid, getUserFirstNameHook]);

  const pages = useMemo(() => {
    return [{ title: t("myTask"), route: "my", current: true, unseenCount: 0 }];
  }, [t]);

  const handleSeeAllTasksButtonClick = useCallback(() => {
    navigate("/tasks/my");
  }, [navigate]);

  const reload = useCallback(
    (input?: { readonly id?: string | null; readonly shadowLoading?: boolean }) => {
      const reload = {
        shadowLoading: input?.shadowLoading || false,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now(),
        selectedId: input?.id !== undefined ? input?.id : undefined
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.HOME, reload });
    },
    [dispatch]
  );

  const handleNewTaskButtonClick = useCallback(async () => {
    setIsSlidedIn(false);
    const createTaskObject = () => ({
      type: TYPES.individual,
      dueAt: null,
      assigneeUID: auth?.uid || "",
      collection: "tasks",
      title: createOverviewItemDefaultName("tasks"),
      description: null,
      labels: [],
      priority: null,
      status: null
    });

    const taskId = await createTask({
      tenantId: auth?.tenantId || "",
      task: createTaskObject()
    });
    if (taskId) {
      navigate(`/home/${taskId}`);
      reload({ id: taskId, shadowLoading: true });
      setSelectedRow({ id: taskId, title: "" });
      dispatch({
        type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
        collection: COLLECTIONS.HOME,
        selectedId: taskId
      });
      enqueueSnackbar(t("new_task_success"), { variant: "success" });
      setTimeout(() => {
        // COLLECTIONS.TASKS, not .HOME, because we share the same "seen" state for tasks and home
        addToSeenItemsOfUserHook(COLLECTIONS.TASKS, taskId);
        reload({ id: taskId, shadowLoading: true });
      }, 500);
    }
  }, [addToSeenItemsOfUserHook, auth?.tenantId, auth?.uid, dispatch, enqueueSnackbar, navigate, reload, t]);

  const handleRowClick = useCallback(
    async task => {
      if (task) {
        window.history.pushState("", "", `/home/${task.id}`);
        if (selectedId !== null && selectedId !== task.id) {
          setIsSlidedIn(!isSlidedIn);
        } else {
          setIsSlidedIn(true);
        }
        setSelectedRow(task);
        if (task.seen === false) {
          // COLLECTIONS.TASKS, not .HOME, because we share the same "seen" state for tasks and home
          addToSeenItemsOfUserHook(COLLECTIONS.TASKS, task.id);
          reload({ id: task.id, shadowLoading: true });
        }
      }
    },
    [addToSeenItemsOfUserHook, isSlidedIn, reload, selectedId]
  );

  const noEntriesEl = useMemo(
    () => (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
        <Box>{noTasksGraphic}</Box>
      </Box>
    ),
    []
  );

  useEffect(() => {
    if (!selectedId) {
      setSelectedRow(null);
      setIsSlidedIn(false);
    } else {
      window.history.pushState("", "", `/home/${selectedId}`);
    }
  }, [selectedId]);

  // set selected id from url
  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch({
      type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
      collection: COLLECTIONS.HOME,
      selectedId: id
    });
    setSelectedRow({ id, title: "" });
    setIsSlidedIn(true);
  }, [dispatch, id]);

  return {
    handleNewTaskButtonClick,
    handleRowClick,
    handleSeeAllTasksButtonClick,
    isSlidedIn,
    name,
    noEntriesEl,
    pages,
    reload,
    selectedRow,
    setIsSlidedIn,
    setSelectedRow,
    t
  };
};
