import { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { FEATURES } from "app/features";
import { changeTaskAssignee, changeTaskGroups, changeTaskParticipants } from "app/handlers/tasksHandler";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useQueryParamByKey } from "app/router/router-custom-hooks";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { useTaskData } from "hook/useTaskData";
import getTextTitle from "app/utils/taskTextTitle";
import { CubeJSEvent, postCubeJsEvent } from "app/api/cubeJsHelperApi";
import { RecurringTask } from "app/api/recurringTaskApi";
import { TaskDetailsDTO } from "app/api/taskApi";
import { UserDTO } from "app/api/user/userApi";
import { debounce } from "lodash-es";
import { useTranslation } from "react-i18next";
import { GroupTask } from "app/api/groupTaskApi";

type TaskTypes = TaskDetailsDTO & RecurringTask & GroupTask;

export interface TaskDetailsProps {
  readonly detailType: "RECURRING" | "GROUP" | "DEFAULT";
  readonly taskId: string;
  onTaskEdit: (taskId: string, prop: keyof TaskTypes, value: TaskTypes, withNavigation?: boolean) => void;
  readonly onTaskAssign?: (taskId: string, userId: string, newParticipantIds: string[]) => void;
  readonly onTaskParticipantsChange?: (taskId: string, userId: string, userIds: string[]) => void;
  readonly onCloseTaskDetails: () => void;
  readonly onTaskDelete?: (taskid: string) => void;
  readonly onGroupTaskDelete?: (taskid: string) => void;
  readonly onRecurringTaskDelete?: (taskid: string) => void;
  readonly onTaskLoad?: (task: TaskTypes | null) => void;
  readonly onPageChange?: (page: {
    route: string;
    id: string;
    detailType: "DEFAULT" | "RECURRING" | "GROUP";
    withReload: boolean;
  }) => void;
  readonly setIsSlidedIn?: (isSlidedIn: boolean) => void;
  readonly reload?: number;
}
export function useTaskDetailsLogic({
  detailType,
  onCloseTaskDetails,
  onTaskDelete,
  onGroupTaskDelete,
  onRecurringTaskDelete,
  onTaskEdit,
  onTaskLoad,
  onTaskParticipantsChange,
  taskId,
  setIsSlidedIn,
  reload
}: TaskDetailsProps) {
  const { t } = useTranslation();
  const { auth } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const passedTitle = useQueryParamByKey("title");
  const isShowRecentActivities = useIsFeaturePresent(FEATURES.DASHBOARD_RECENT_ACTIVITIES);

  const { tenantId, uid } = auth || { tenantId: "", uid: "" };

  const [pageAccessPublished, setPageAccessPublished] = useState(false);
  const [saveOperationInProgress, setSaveOperationInProgress] = useState(false);
  const [newlyAddedCommentId, setNewlyAddedCommentId] = useState("");

  const { task, setTask, updateTask, documentNotFound } = useTaskData({
    taskId,
    detailType,
    onCloseTaskDetails,
    onTaskLoad,
    onTaskEdit,
    enqueueSnackbar,
    setIsSlidedIn,
    reload
  });

  const publishActivity = useCallback(
    async activityType => {
      if (!task || !isShowRecentActivities) {
        return;
      }
      const payload = {
        url: "/task-details/" + taskId,
        docType: "task",
        docTitle: task.title || "",
        docId: taskId,
        activityType: activityType,
        tableId: "activities" as CubeJSEvent["tableId"]
      };
      await postCubeJsEvent(payload);
    },
    [task, isShowRecentActivities, taskId]
  );

  useEffect(() => {
    if (task && pageAccessPublished === false) {
      publishActivity("pageAccess");
      setPageAccessPublished(true);
    }
  }, [pageAccessPublished, publishActivity, task]);

  const isGroupSubTask = useMemo(() => {
    if (detailType === "GROUP" && task && task.groupId) {
      return true;
    }
    return false;
  }, [detailType, task]);

  const taskTitle = useMemo(() => {
    return getTextTitle({
      name: passedTitle,
      collection: task?.collection,
      questionId: task?.questionId,
      type: task?.type,
      title: task?.title
    });
  }, [passedTitle, task?.collection, task?.questionId, task?.type, task?.title]);

  const onChangeTitle = useCallback(
    async (title: string) => {
      if (task) {
        updateTask(task.id, detailType, { title }, task);
      }
    },
    [task, detailType, updateTask]
  );

  const onDelete = useCallback(
    id => {
      if (detailType === "GROUP") {
        onGroupTaskDelete?.(id);
      } else if (detailType === "RECURRING") {
        onRecurringTaskDelete?.(id);
      } else {
        onTaskDelete?.(id);
      }
      setIsSlidedIn?.(false);
    },
    [detailType, onGroupTaskDelete, onRecurringTaskDelete, onTaskDelete, setIsSlidedIn]
  );

  const onChangeParticipants = useCallback(
    async (selectedUserIds: string[]) => {
      if (!task) return;
      try {
        updateTask(task.id, detailType, { participants: selectedUserIds, updated: new Date() }, task);
        onTaskParticipantsChange?.(taskId, task.assigneeUID || "", selectedUserIds);
      } catch (error) {
        enqueueSnackbar("Failed to update task participants", { variant: "error" });
        console.error("Failed to update task participants", error);
      }
    },
    [enqueueSnackbar, onTaskParticipantsChange, task, detailType, taskId, updateTask]
  );

  const debouncedDescriptionUpdate = debounce((description: string) => {
    if (task) {
      updateTask(task.id, detailType, { description }, task);
      setTask({ ...task, description });
    }
  }, 300);

  const onChangeDescription = useCallback(debouncedDescriptionUpdate, [
    task,
    detailType,
    updateTask,
    debouncedDescriptionUpdate
  ]);

  const onChangeFiles = useCallback(() => {
    if (task) {
      onTaskEdit?.(taskId, "id", task);
    }
  }, [onTaskEdit, task, taskId]);

  const onChangeLabels = useCallback(
    async (labels: string[]) => {
      if (task) {
        await updateTask(task.id, detailType, { labels }, task);
      }
    },
    [task, detailType, updateTask]
  );

  const onChangeAssignType = useCallback(
    async (assignTo: string) => {
      try {
        if (task && task.assignedToType !== assignTo) {
          setSaveOperationInProgress(true);
          await changeTaskGroups(taskId, [], tenantId);
          await changeTaskAssignee(tenantId, uid, taskId, task.creatorUID);
          await changeTaskParticipants(tenantId, uid, taskId, []);
          onTaskParticipantsChange?.(taskId, task.assigneeUID || "", []);
          const updatedTask = {
            ...task,
            assignedToType: assignTo.toUpperCase()
          };
          await updateTask(task.id, detailType, updatedTask, task);
        }
      } catch (error) {
        enqueueSnackbar(t("error_messages:generic"), { variant: "error" });
      } finally {
        setSaveOperationInProgress(false);
      }
    },
    [task, taskId, tenantId, uid, onTaskParticipantsChange, updateTask, detailType, enqueueSnackbar, t]
  );

  const onAddingMentionsToComment = useCallback(
    async (mentionedUsers: UserDTO[]) => {
      if (task && mentionedUsers.length > 0) {
        const currentAssignedUserIds = [task?.assigneeUID, ...(task?.participants ? task.participants : [])];
        const newMentionedUserIds = mentionedUsers.map(user => user.id) as string[];
        const userIdsToAdd = newMentionedUserIds.filter(mentionedId => !currentAssignedUserIds.includes(mentionedId));
        const newParticipants = [...(task?.participants ? task.participants : []), ...userIdsToAdd];
        const updatedTask = {
          ...task,
          participants: newParticipants
        };
        setTask(updatedTask);
        await changeTaskParticipants(tenantId, uid, taskId, newParticipants).catch(error => {
          enqueueSnackbar(error.message, { variant: "error" });
        });
      }
    },
    [enqueueSnackbar, setTask, task, taskId, tenantId, uid]
  );

  return {
    documentNotFound,
    isGroupSubTask,
    newlyAddedCommentId,
    onAddingMentionsToComment,
    onChangeAssignType,
    onChangeDescription,
    onChangeFiles,
    onChangeLabels,
    onChangeParticipants,
    onChangeTitle,
    onDelete,
    saveOperationInProgress,
    setNewlyAddedCommentId,
    task,
    taskTitle,
    updateTask
  };
}
