import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS, META_VIEW_TABS_TYPES } from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useMetaView } from "app/contexts/meta-view-context";
import GeneralInformationExport from "./GeneralInformationExport";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useUserDepartments } from "app/contexts/department-context";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { ProcessesOverviewCollectionParams } from "./ProcessesOverviewController";
import { exportProcessingActivitiesExcel } from "app/export/createdExcelExportData";
import { FEATURES } from "../../../features";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { exportProcessesPDF } from "app/export/createdPdfExportData";
import { AllProfileData, PROFILE_TYPE } from "app/handlers/tenantProfileInformationHandler";
import { paWritePermissions } from "../../../handlers/permissionHandler";
import { streamFilesToZip } from "../../../handlers/fileHandler";
import { usePAPages } from "./processPages";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { OverviewBrickProps } from "components/Overview/controls/OverviewRow";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";
import { useDocumentName } from "app/contexts/document-name-context";

const bricks: OverviewBrickProps[] = [
  { component: OverviewBrick.ProcessStatus },
  { component: OverviewBrick.Title },
  { component: OverviewBrick.Readonly },
  { component: OverviewBrick.Remove, position: "right" }
];

export type ProcessOverviewType = "pa" | "dpia" | "aiact" | "business";

const ProcessesOverview = ({ type }: { readonly type?: ProcessOverviewType }) => {
  const translationContext = type === "dpia" || type === "aiact" ? `${type}_overview` : "processes_overview";
  const { t } = useTranslation(translationContext);
  const { setInfoId, setMeta, setSelectedTab, setAIIntegrationMode } = useMetaView();
  const { auth } = useAuthentication();
  const { departmentsLoaded } = useUserDepartments();
  const { clearDocumentName } = useDocumentName();
  const { data, isLoading } = useDataTypeTree(true);
  const aiIntegrationActivated = useIsFeaturePresent(FEATURES.AI_INTEGRATION);
  useEnteringInfoCard({ pathName: `/processes`, infoId: `infocard.${type}.overview.entering` });

  const [generalExportOpen, setGeneralExportOpen] = useState<boolean>(false);
  const [processIdsToExport, setProcessIdsToExport] = useState<string[] | null>(null);

  const showEnterInfo = useCallback(() => {
    setInfoId(`infocard.${type}.overview.entering`);
  }, [setInfoId, type]);
  const showCreateInfo = useCallback(() => {
    setInfoId(`infocard.${type}.overview.creating`);
  }, [type, setInfoId]);

  const onRowOver = useCallback(
    item => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const meta: any = {
        // eslint-disable-next-line camelcase
        dueDate: item.dueAt ? <DateDisplay timestamp={new Date(item.dueAt)} displaySeconds={undefined} /> : "-",
        department: item.subTitle,
        version: item.version ? item.version : "-",
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} separator={undefined} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("status_" + item.status),
        approved: item.approvedAt ? (
          <DateDisplay timestamp={new Date(item.approvedAt)} displaySeconds={undefined} />
        ) : (
          "-"
        ),
        approvedBy: <AutomaticUserDataDisplay uid={item.approvedBy} separator={undefined} />
      };

      if (item.status.toLowerCase() !== "approved") {
        delete meta.approved;
        delete meta.approvedBy;
      }
      setMeta(meta);
    },
    [setMeta, t]
  );

  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }, ...[{ action: "export-all" }]], []);

  const paTemplateEnabled = useIsFeaturePresent(FEATURES.PA_TEMPLATES);
  const addActions = useMemo(
    () => [
      { action: OVERVIEW_ADD_TYPE.SINGLE },
      { action: OVERVIEW_ADD_TYPE.MULTIPLE },
      ...(paTemplateEnabled ? [{ action: OVERVIEW_ADD_TYPE.TEMPLATE }] : [])
    ],
    [paTemplateEnabled]
  );

  /* useEffects */
  useEffect(() => {
    clearDocumentName();
    return showEnterInfo();
  }, [showEnterInfo, clearDocumentName]);

  const exportToPdfDialog = useCallback((ids: string[]) => {
    setProcessIdsToExport(ids);
    setGeneralExportOpen(true);
  }, []);

  const exportToPDF = useCallback(
    async (exportInfo: AllProfileData) => {
      await exportProcessesPDF({
        paIds: processIdsToExport || [],
        exportInfo,
        isDPIA: type === "dpia"
      });
      setGeneralExportOpen(false);
    },
    [type, processIdsToExport]
  );

  const exportToXLSX = useCallback(
    async ids => {
      if (!auth?.tenantId) {
        return;
      }

      return await exportProcessingActivitiesExcel(auth?.tenantId, auth?.uid, t, ids);
    },
    [auth?.tenantId, auth?.uid, t]
  );
  const exportToAttachments = useCallback(
    async (ids, idToName) => {
      if (!auth?.tenantId) {
        return;
      }

      await streamFilesToZip({
        exportDefinitions: [
          {
            docIds: ids,
            category: `${COLLECTIONS.PROCESSES}-description`,
            categoryName: "General",
            withPrefixQuery: false,
            docIdToFolderName: id => idToName.get(id) || id
          },
          {
            docIds: ids,
            category: `${COLLECTIONS.PROCESSES}`,
            categoryName: "Data Flows",
            withPrefixQuery: false,
            docIdToFolderName: id => idToName.get(id) || id
          }
        ]
      });
    },
    [auth?.tenantId]
  );
  const selectionActions = useMemo(
    () => [{ action: "duplicate-copy-to" }, { action: "export-all" }, { action: "remove" }],
    []
  );
  const collectionParams = useMemo<ProcessesOverviewCollectionParams>(
    () => ({
      personGroups: data,
      filter: { dpiaOnly: Boolean(type === "dpia"), aiActOnly: Boolean(type === "aiact") },
      exportToPdfDialog: exportToPdfDialog,
      exportToXLSX,
      exportToAttachments,
      overviewType: type
    }),
    [data, type, exportToPdfDialog, exportToXLSX, exportToAttachments]
  );
  const showAddActions = auth?.permissions.find(permission => paWritePermissions.includes(permission));
  const { pages, onPageChange, shouldShowPages } = usePAPages();

  const metaviewTabIds = useMemo<META_VIEW_TABS_TYPES[]>(
    () =>
      aiIntegrationActivated ? [META_VIEW_TABS.AI_INTEGRATION, META_VIEW_TABS.ASSISTANT] : [META_VIEW_TABS.ASSISTANT],
    [aiIntegrationActivated]
  );

  // set default tab selection
  useEffect(() => {
    setSelectedTab(aiIntegrationActivated ? META_VIEW_TABS.AI_INTEGRATION : META_VIEW_TABS.ASSISTANT);
    if (aiIntegrationActivated) {
      setAIIntegrationMode("suggest-process");
    }
  }, [aiIntegrationActivated, setAIIntegrationMode, setSelectedTab]);

  const headerText = useMemo(() => {
    switch (type) {
      case "dpia":
        return t("list_dpias");
      case "aiact":
        return t("sidebar:aiact");
      case "business":
        return t("sidebar:process_overview_business");
      default:
        return t("list_processes");
    }
  }, [t, type]);

  const showProcessorPAPage = useMemo(() => shouldShowPages && (!type || type === "pa"), [shouldShowPages, type]);

  return (
    <DocMetaView
      loading={isLoading}
      metaViewContent={<MetaView editable={true} translationKey={translationContext} tabs={metaviewTabIds} />}
    >
      {departmentsLoaded && (
        <>
          <Overview
            collection={COLLECTIONS.PROCESSES}
            collectionParams={collectionParams}
            pages={showProcessorPAPage ? pages : undefined}
            onPageChange={onPageChange}
            onAddOpen={showCreateInfo}
            onAddClose={showEnterInfo}
            onRowOver={onRowOver}
            onRowLeave={showEnterInfo}
            toolbarActions={toolbarActions}
            addActions={showAddActions ? addActions : undefined}
            selectionActions={selectionActions}
            bricks={bricks}
            checkable={true}
            header={headerText}
            toolbarMode={"tabs"}
            showSelectAll={true}
          />
          <GeneralInformationExport
            tenantId={auth?.tenantId || ""}
            open={generalExportOpen}
            setOpen={setGeneralExportOpen}
            exportProcesses={exportToPDF}
            profiles={profilesForExport}
          />
        </>
      )}
    </DocMetaView>
  );
};

const profilesForExport: PROFILE_TYPE[] = [
  "dataController",
  "jointController",
  "controllerRepresentative",
  "dataProtectionOfficer"
];

export default ProcessesOverview;
