import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AiAssetSelect from "./AiAssetSelect";
import { useCallback, useEffect, useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DefaultApi, GetAiAssets200Response, ImpactedAssetDTO } from "app/api/generated/process-service";
import Question from "components/Question/Question";
import { useTranslation } from "react-i18next";
import { Button, LinearProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { createAsset, getAssetsWithAdditionalIDs } from "../handler/assetHandler";
import { AssetShortDTO } from "../types/AssetTypes";
import AiRoleResourcePicker from "./AiRoleResourcePicker";
import { apiEndpoints } from "../../../api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../../api/axios/loggedInAxiosProvider";
import useSWR from "swr";
import { useResources } from "../../../contexts/resource-context";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { SxProps } from "@mui/system/styleFunctionSx";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import { isEqual } from "lodash-es";
import { useMetaView } from "../../../contexts/meta-view-context";
import CustomAlert from "components/CustomAlert/CustomAlert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type AssetMultiselectProps = {
  readonly paId: string;
  readonly expandedAccordion?: string | false;
  readonly disabled?: boolean;
  readonly impactedAssets: ImpactedAssetDTO[];
  readonly orgUnitIds?: string[] | undefined;
  readonly onChange?: (accordionId: string, data: ImpactedAssetDTO) => Promise<void>;
  readonly onDelete?: (accordionId: string) => Promise<void>;
  readonly onSave?: (accordionId: string, data: ImpactedAssetDTO) => Promise<void>;
};

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

/**
 * AI asset accordion component for managing impacted assets.
 * It allows the user to create, update and delete impacted assets.
 * One "impacted asset" consists of an asset and a resource of type AI role.
 * @param paId The id of the process assessment.
 * @param expandedAccordion The id of the expanded accordion (impacted asset).
 * @param disabled Whether the component is disabled.
 * @param impactedAssets The impacted assets.
 * @param orgUnitIds The organization unit ids.
 * @param onChange The function to call when the value changes.
 * @param onDelete The function to call when an impacted asset is deleted.
 * @returns The AI asset accordion component.
 */
const AiAssetAccordion = ({
  paId,
  disabled,
  expandedAccordion,
  impactedAssets,
  orgUnitIds,
  onChange,
  onDelete,
  onSave
}: AssetMultiselectProps) => {
  const { t } = useTranslation("questionnaires");

  const [localExpandedAccordion, setLocalExpandedAccordion] = useState<string | false>(expandedAccordion || false);
  const { loadSeenItemsOfUserHook } = useUserAndTenantData();
  const { data: aiAssetResponse, isLoading: aiAssetIdsLoading } = useSWR<GetAiAssets200Response>(
    `ai-asset-ids-${paId}`,
    async () => {
      const response = await processClient.getAiAssets(paId);
      return response.data || {};
    }
  );
  const aiAssetIds = useMemo<Set<string>>(() => {
    return new Set<string>(aiAssetResponse?.aiSystemAssetIds || []);
  }, [aiAssetResponse]);

  const existingAssetIds = useMemo(
    () => impactedAssets.map(impactedAsset => impactedAsset.assetId).filter((it): it is string => !!it),
    [impactedAssets]
  );

  const {
    data: availableAssetsResponse,
    isLoading: availableAssetsLoading,
    mutate
  } = useSWR<{ items: AssetShortDTO[] }>(
    orgUnitIds ? `available-assets-${paId}-${orgUnitIds}-${existingAssetIds}` : null,
    () => getAssetsWithAdditionalIDs({ anyOrgUnitIds: orgUnitIds }, existingAssetIds)
  );
  const availableAssets = useMemo(() => availableAssetsResponse?.items || [], [availableAssetsResponse]);
  const availableAssetsById = useMemo<Map<string, AssetShortDTO>>(() => {
    return availableAssets.reduce<Map<string, AssetShortDTO>>((acc, asset) => {
      acc.set(asset.id, asset);
      return acc;
    }, new Map<string, AssetShortDTO>());
  }, [availableAssets]);

  // set local expanded accordion state when expandedAccordion changes
  useEffect(() => {
    if (expandedAccordion) {
      setLocalExpandedAccordion(expandedAccordion);
    }
  }, [expandedAccordion]);

  const handleNewAssetAdded = useCallback(
    async (accordionId: string, newAssetName: string) => {
      const createdAssetId = await createAsset({ name: newAssetName });
      await mutate();
      loadSeenItemsOfUserHook();
      await onChange?.(accordionId, { assetId: createdAssetId });
    },
    [loadSeenItemsOfUserHook, mutate, onChange]
  );

  const handleAccordionClicked = useCallback((accordionId: string) => {
    setLocalExpandedAccordion(prev => (prev === accordionId ? false : accordionId));
  }, []);

  if (aiAssetIdsLoading || availableAssetsLoading) {
    return (
      <Box>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box sx={accordionDetailsSx}>
      {impactedAssets.map((impactedAsset, index) => (
        <Question
          disabled={disabled}
          key={`impacted-asset-${impactedAsset.id}`}
          pb={0}
          pl={0}
          pr={0}
          pt={0}
          questionId={`impactedAsset-${impactedAsset.id}`}
          questionName={t("asset_details:affected_assets")}
        >
          <ImpactedAssetAccordion
            key={impactedAsset.id}
            index={index}
            impactedAsset={impactedAsset}
            aiAssetIds={aiAssetIds}
            availableAssetsById={availableAssetsById}
            disabled={disabled}
            localExpandedAccordion={localExpandedAccordion}
            expanded={localExpandedAccordion === impactedAsset.id}
            onClick={handleAccordionClicked}
            onNewAssetAdded={handleNewAssetAdded}
            onDelete={onDelete}
            onSave={onSave}
          />
        </Question>
      ))}
    </Box>
  );
};

const accordionDetailsSx: SxProps = {
  "& .MuiAccordionDetails-root": {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0
  }
};

/**
 * Component for selecting one asset.
 * @param accordionId The id of the accordion (impacted asset).
 * @param disabled Whether the component is disabled.
 * @param selectedAssetId The id of the selected asset.
 * @returns The asset picker component.
 */
const AssetPicker = ({
  accordionId,
  disabled,
  selectedAssetId,
  availableAssetsById,
  onChange,
  onAddNewOption
}: {
  accordionId: string;
  disabled?: boolean;
  selectedAssetId?: string;
  onChange?: (accordionId: string, assetId: string) => void;
  availableAssetsById: Map<string, AssetShortDTO>;
  onAddNewOption?: (accordionId: string, newOption: string) => void;
}) => {
  const handleChange = useCallback((assetId: string) => onChange?.(accordionId, assetId), [accordionId, onChange]);
  const handleNewAsset = useCallback(
    (newOption: string) => onAddNewOption?.(accordionId, newOption),
    [accordionId, onAddNewOption]
  );

  const { setInfoId } = useMetaView();

  const onFocus = useCallback(() => {
    setInfoId?.("infocard.pa.page2.asset");
  }, [setInfoId]);

  return (
    <Box width="100%" mr={1}>
      <Question onFocus={onFocus}>
        <AiAssetSelect
          availableAssetsById={availableAssetsById}
          disabled={disabled}
          selectedAssetId={selectedAssetId}
          onChange={handleChange}
          onAddNewOption={handleNewAsset}
        />
      </Question>
    </Box>
  );
};

interface ImpactedAssetAccordionProps {
  index: number;
  impactedAsset: ImpactedAssetDTO;
  availableAssetsById: Map<string, AssetShortDTO>;
  aiAssetIds: Set<string>;
  disabled?: boolean;
  expanded?: boolean;
  localExpandedAccordion?: string | false;
  onClick?: (accordionId: string) => void;
  onNewAssetAdded?: (accordionId: string, newAssetName: string) => void;
  onDelete?: (accordionId: string) => void;
  onSave?: (accordionId: string, data: ImpactedAssetDTO) => void;
}

/**
 * Component for managing an impacted asset.
 * @param index The index of the impacted asset.
 * @param impactedAsset The impacted asset.
 * @param availableAssetsById The available assets by id.
 * @param aiAssetIds The asset ids which uses ai system
 * @param disabled Whether the component is disabled.
 * @param expanded Whether the component is expanded.
 * @param onClick The function to call when the accordion is clicked.
 * @param onAssetChange The function to call when the asset changes.
 * @param onNewAssetAdded The function to call when a new asset is added.
 * @param onAiRoleChange The function to call when the AI role changes.
 * @param onDelete The function to call when the component is deleted.
 * @returns The impacted asset component.
 */
const ImpactedAssetAccordion = ({
  index,
  impactedAsset,
  availableAssetsById,
  aiAssetIds,
  disabled,
  expanded,
  onClick,
  onNewAssetAdded,
  onDelete,
  onSave,
  localExpandedAccordion
}: ImpactedAssetAccordionProps) => {
  const { t } = useTranslation("questionnaires");
  const [currentImpactedAsset, setCurrentImpactedAsset] = useState<ImpactedAssetDTO>(() => ({
    ...impactedAsset,
    aiRoleId: impactedAsset.aiRoleId ?? ""
  }));
  const [isExpanded, setIsExpanded] = useState(false);
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    const normalizedImpactedAsset = {
      ...impactedAsset,
      aiRoleId: impactedAsset.aiRoleId ?? ""
    };

    const diff = Object.keys(currentImpactedAsset).some(
      key =>
        !isEqual(
          currentImpactedAsset[key as keyof ImpactedAssetDTO],
          normalizedImpactedAsset[key as keyof ImpactedAssetDTO]
        )
    );

    setDirty(diff);
  }, [currentImpactedAsset, impactedAsset]);

  const isAiAssetSelected = useMemo(() => {
    if (!currentImpactedAsset?.assetId) {
      return false;
    }

    return aiAssetIds.has(currentImpactedAsset.assetId);
  }, [currentImpactedAsset?.assetId, aiAssetIds]);

  useEffect(() => {
    if (localExpandedAccordion === impactedAsset.id) setIsExpanded(true);
  }, [localExpandedAccordion, impactedAsset.id]);

  const cancelButtonClicked = useCallback(() => {
    setIsExpanded(false);
  }, []);
  const saveButtonClicked = useCallback(() => {
    onSave?.(currentImpactedAsset.id || "", {
      assetId: currentImpactedAsset.assetId,
      aiRoleId: currentImpactedAsset.aiRoleId || ""
    });
  }, [currentImpactedAsset, onSave]);

  const selectedAsset = useMemo(
    () => availableAssetsById.get(currentImpactedAsset.assetId || "") || null,
    [availableAssetsById, currentImpactedAsset.assetId]
  );

  const { translateById } = useResources();
  const aiRoleTranslation = useMemo(() => {
    if (!currentImpactedAsset.aiRoleId) return "";
    return translateById(RESOURCE_TYPES.AI_ROLE, currentImpactedAsset.aiRoleId);
  }, [translateById, currentImpactedAsset.aiRoleId]);

  const accordionSx = useMemo<SxProps>(
    () => ({
      borderRadius: "6px",
      "&:before": {
        display: "none"
      }
    }),
    []
  );

  const onChangeCallback = useCallback(() => {
    onClick?.(currentImpactedAsset.id as string);
  }, [currentImpactedAsset.id, onClick]);
  const onDeleteCallback = useCallback(() => {
    onDelete?.(currentImpactedAsset.id as string);

    setCurrentImpactedAsset({} as ImpactedAssetDTO);
  }, [currentImpactedAsset.id, onDelete]);
  const updateCurrentImpactedAsset = useCallback(
    (updatedFields: Partial<ImpactedAssetDTO>) => {
      console.log(currentImpactedAsset);
      setCurrentImpactedAsset(currentImpactedAsset => ({ ...currentImpactedAsset, ...updatedFields }));
    },
    [currentImpactedAsset]
  );

  const onChangeAsset = useCallback(
    (accordionId, assetId) => {
      updateCurrentImpactedAsset({ assetId, aiRoleId: "" });
    },
    [updateCurrentImpactedAsset]
  );

  const onChangeAIRole = useCallback(
    (accordionId, aiRoleId) => {
      updateCurrentImpactedAsset({ aiRoleId });
    },
    [updateCurrentImpactedAsset]
  );

  const toggleExpanded = useCallback(() => {
    setIsExpanded(prevState => !prevState);
  }, []);

  const dirtyEl = dirty ? (
    <CustomAlert severity="error" icon={<ErrorOutlineIcon />}>
      {t("common:unsavedChanges")}
    </CustomAlert>
  ) : (
    <></>
  );

  const titleEl = (() => {
    return (
      <AccordionSummary onClick={toggleExpanded} expandIcon={<ExpandMoreIcon />}>
        <Box width="100%">
          {dirty ? (
            <Box display="flex" mb={1}>
              <Box>{dirtyEl}</Box>
              <Box flexGrow={1} />
            </Box>
          ) : (
            <></>
          )}

          <Box display={"flex"}>
            {selectedAsset
              ? `${selectedAsset?.name}${aiRoleTranslation ? ` - ${aiRoleTranslation}` : ""}`
              : t("questionnaires:selectAssetAndAiRole")}{" "}
          </Box>
        </Box>
      </AccordionSummary>
    );
  })();

  return (
    <Box key={index} mt={1}>
      <Accordion expanded={isExpanded} onChange={onChangeCallback} sx={accordionSx}>
        {titleEl}
        <AccordionDetails>
          <AssetPicker
            accordionId={currentImpactedAsset.id as string}
            disabled={disabled}
            selectedAssetId={currentImpactedAsset.assetId}
            availableAssetsById={availableAssetsById}
            onChange={onChangeAsset}
            onAddNewOption={onNewAssetAdded}
          />
          <AiRoleResourcePicker
            accordionId={currentImpactedAsset.id as string}
            aiRoleId={currentImpactedAsset.aiRoleId as string}
            disabled={disabled}
            isAiAssetSelected={isAiAssetSelected}
            onChange={onChangeAIRole}
          />
        </AccordionDetails>
        <Box display="flex" mb={0.5} mt={3} alignItems="center" justifyContent="space-between">
          {!disabled && (
            <Box ml={2} mb={2}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<DeleteIcon />}
                onClick={onDeleteCallback}
                disabled={disabled}
              >
                {t("questionnaires:delete")}
              </Button>
            </Box>
          )}

          <Box display="flex" gap={1} mr={2}>
            <Box>
              <Button
                data-testid="aiasset_cancel_button"
                variant="outlined"
                color="primary"
                onClick={cancelButtonClicked}
                disabled={disabled}
              >
                {t("questionnaires:cancel")}
              </Button>
            </Box>
            <Box>
              <Button
                data-testid="aiasset_save_button"
                variant="contained"
                color="primary"
                onClick={saveButtonClicked}
                disabled={disabled || !dirty}
              >
                {t("questionnaires:save")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Accordion>
    </Box>
  );
};

export default AiAssetAccordion;
