import { Box, Tooltip } from "@mui/material";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { t } from "i18next";

type statuses = "OPEN" | "DONE" | "DELETED";
const statusIconMap: Record<statuses, React.ReactNode> = {
  OPEN: <LazySvgIcon name="In_Progress" />,
  DONE: <LazySvgIcon name="Completed" />,
  DELETED: <LazySvgIcon name="Completed" />
};

const RequestOverviewBrickStatus = ({ item }: OverviewRowBrickProps) => {
  if (!item.status) {
    return <></>;
  }

  return (
    <Tooltip title={t("filter_criteria:" + item.status.toLowerCase())}>
      <Box sx={{ height: "24px" }}>{statusIconMap[item.status as statuses]}</Box>
    </Tooltip>
  );
};

export default RequestOverviewBrickStatus;
