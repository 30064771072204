import { apiEndpoints } from "app/api/apiEndpoint";
import { testGetApiFrom } from "app/api/connectionTestApi";

const nonCriticalApis: (keyof typeof apiEndpoints)[] = [
  // very little tenant uses these features
  "cookieBoxUrl",
  "owlitUrl",
  "deeplUrl",
  "mlUrl",
  "aiTechDocUrl",
  "infoCardUrl",
  "cubeJsHelperUrl",
  "aiUrl"
];

const customHealthCheck: Partial<Record<keyof typeof apiEndpoints, string>> = {
  hasuraUrl: "/healthz"
};

export const fetchDataFromApi = (): Record<string, Promise<void>> =>
  Object.entries(apiEndpoints)
    .filter(([apiName]) => !nonCriticalApis.includes(apiName as keyof typeof apiEndpoints))
    .reduce((acc, [apiName, baseUrl]) => {
      return {
        ...acc,
        [apiName]: testGetApiFrom(baseUrl, customHealthCheck[apiName as keyof typeof customHealthCheck])
      };
    }, {});
