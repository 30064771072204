import { deleteRecurringTask, deleteTask } from "app/handlers/tasksHandler";
import { deleteGroupTaskApi } from "app/api/groupTaskApi";

interface TaskHandlerInput {
  reload: (input?: { id?: string | null; shadowLoading?: boolean }) => void;
  taskId?: string;
  field?: string;
}

export const handleCloseTaskDetails = ({ reload }: TaskHandlerInput) => {
  reload({ id: null, shadowLoading: true });
  window.history.replaceState({}, "", "/home");
};

export const handleTaskDelete = async ({ taskId, reload }: TaskHandlerInput) => {
  if (!taskId) return;
  await deleteTask(taskId);
  reload({ id: null, shadowLoading: true });
};

export const handleGroupTaskDelete = async ({ taskId, reload }: TaskHandlerInput) => {
  if (!taskId) return;
  await deleteGroupTaskApi(taskId);
  reload({ id: null, shadowLoading: true });
};

export const handleRecurringTaskDelete = async ({ taskId, reload }: TaskHandlerInput) => {
  if (!taskId) return;
  await deleteRecurringTask(taskId);
  reload({ id: null, shadowLoading: true });
};

export const handleTaskEdit = ({ taskId, reload, field }: TaskHandlerInput) => {
  if (field) {
    // no reload to prevent glitches for auto-saving fields
    if (["description", "labels"].includes(field)) {
      return;
    }
  }
  reload({
    id: taskId,
    shadowLoading: true
  });
};
