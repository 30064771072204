import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import MetaView from "../../../components/MetaView/MetaView";
import { useMarkNotificationsAsRead, useUserNotifications } from "../../../hook/useUserNotifications";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import OverviewList from "components/LegacyOverview/OverviewList";
import Row from "components/Row/Row";
import Divider from "@material-ui/core/Divider";
import NotificationsTitle from "./NotificationTitle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Button from "@material-ui/core/Button";
import { Box, CircularProgress } from "@material-ui/core";
import OverviewSearchTextField from "components/LegacyOverview/OverviewSearchTextField";
import OverviewListItemPagination from "components/LegacyOverview/OverviewListItemPagination";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useNavigateWithQueryParams } from "../../router/router-custom-hooks";
import { COLLECTIONS } from "../../collections";
import { useSnackbar } from "notistack";
import { useMetaView } from "app/contexts/meta-view-context";
import omitBy from "lodash-es/omitBy";
import isNull from "lodash-es/isNull";
import isUndefined from "lodash-es/isUndefined";
import { useUserAndTenantData } from "../../handlers/userAndTenant/user-tenant-context";
import { getTaskDetailTypeByTaskId, isMyTask } from "app/handlers/tasksHandler";
import { getSingleAnswerSetApi } from "app/api/assessmentApi";

const useStyles = makeStyles(theme => ({
  rowIconError: {
    color: theme.palette.yellow[300],
    padding: "4px"
  },
  rowIconWarning: {
    color: theme.palette.warning.main,
    padding: "4px"
  },
  rowIconSuccess: {
    color: theme.palette.success.main,
    padding: "4px"
  }
}));

export default function NotificationsOverview() {
  const navigate = useNavigateWithQueryParams();

  const { t, i18n } = useTranslation("notifications_overview");
  const classes = useStyles();
  const { getUserNameHook } = useUserAndTenantData();
  const { notifications } = useUserNotifications();

  const { markAsRead, isMutating } = useMarkNotificationsAsRead();
  const { setInfo } = useMetaView();
  const { auth } = useAuthentication();
  const [notificationsFiltered, setNotificationsFiltered] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const { enqueueSnackbar } = useSnackbar();
  const { t: t_error_messages } = useTranslation("error_messages");
  // Pagination logic needed at page level
  const [displayableNotifications, setDisplayableNotifications] = useState([]);
  const [indexOffset, setIndexOffset] = useState(0);
  const getTrueIndex = function (index) {
    return indexOffset + index;
  };

  const notificationEntries = useMemo(() => {
    if (!notifications) {
      return [];
    }

    const generateNotificationText = notification => {
      const senderName = getUserNameHook(notification.senderUID)
        ? getUserNameHook(notification.senderUID)
        : t("system");

      switch (notification.title) {
        case "task_assignment":
          switch (notification.collection) {
            case "dataSubjectRequests":
              return t("assigned_task_dsr", { sender: senderName });
            case "dataBreaches":
              return t("assigned_task_data_breach", { sender: senderName });
            case "serviceProviders":
              return t("assigned_task_service_provider", { sender: senderName });
            case "tom":
              return t("assigned_task_tom", { sender: senderName });
            case "processes":
              return t("assigned_task_processes", { sender: senderName });
            default:
              return t("assigned_task", { sender: senderName });
          }
        case "processor_pa_approval":
          return t("process_approval", { sender: senderName });
        case "assigned_processor_pa_submit":
        case "process_assignment":
          return t("assign_process", { sender: senderName });
        case "dbreach_assigned":
          return t("data_breach_assigned", { sender: senderName });
        case "audit_assignment":
          return t("assign_audit", { sender: senderName });
        case "assessmentResponse":
          return t("assign_audit", { sender: senderName });
        default:
          return t(notification.title, { sender: senderName });
      }
    };

    return notifications
      .filter(it => it.senderUID !== auth.uid)
      .map(entry => ({
        ...entry,
        text: generateNotificationText(entry),
        textForSearch: ["task_overdue", "process_overdue", "dsr_overdue"].includes(entry.title)
          ? `${t("overdue")} ${entry.textForSearch}`
          : `${entry.text || ""} ${entry.docName}`
      }));
  }, [auth?.uid, getUserNameHook, notifications, t]);

  /* useEffect */
  useEffect(() => {
    if (notificationEntries) {
      notificationEntries.sort((a, b) => b.createdAt - a.createdAt);
      const filteredBySearch = notificationEntries.filter(notification => {
        return notification.textForSearch.toLowerCase().includes(searchKeyword.toLowerCase());
      });
      setNotificationsFiltered(filteredBySearch);
    }
  }, [notificationEntries, searchKeyword, i18n.language]);

  useEffect(() => {
    setInfo({
      title: t("infoCardTitle"),
      text: t("infoCardText")
    });
  }, [setInfo, t]);

  const loadAnswerSet = useCallback(async (auditId, id) => {
    const data = await getSingleAnswerSetApi({ auditId, id });
    if (data) {
      return [data.assigneeUID || "", ...data.participantsUIDs];
    } else {
      return [];
    }
  }, []);

  const handleClick = notification => {
    // mark specific notification as clicked
    jumpToPage(notification);
    if (!notification.read) {
      const arrayOfClicked = [];
      arrayOfClicked.push(notification);
      return markAsRead({
        notificationIds: arrayOfClicked.map(it => it.notificationId)
      });
    }
  };
  const jumpToPage = async notification => {
    let navigateTo;
    switch (notification.collection) {
      case COLLECTIONS.DPIAS:
      case COLLECTIONS.AIACTS:
      case COLLECTIONS.PROCESSES: {
        navigateTo = "processes";
        break;
      }
      case COLLECTIONS.PROCESSOR_PAS: {
        navigateTo = "processor-pas";
        break;
      }
      case COLLECTIONS.EXTERNAL_RECIPIENTS: {
        if (notification.pageId) {
          navigateTo = "external-recipients/" + notification.pageId;
        } else {
          navigateTo = "external-recipients";
        }
        break;
      }
      case COLLECTIONS.TOM: {
        navigateTo = "toms";
        break;
      }
      case COLLECTIONS.USER:
      case COLLECTIONS.TASK_DETAIL:
      case COLLECTIONS.TASKS: {
        const taskDetail = await getTaskDetailTypeByTaskId(notification.docId);
        if (taskDetail) {
          const { task } = taskDetail;
          const isMy = isMyTask(auth.uid, task);
          navigateTo = isMy ? "tasks/my" : "tasks/other";
        } else {
          enqueueSnackbar(t("task_details:notFound"), { variant: "error" });
          return false;
        }
        break;
      }
      case COLLECTIONS.DATA_SUBJECT_REQUESTS: {
        navigateTo = "data-subject-requests";
        break;
      }
      case COLLECTIONS.DATA_BREACHES: {
        navigateTo = "data-breaches";
        break;
      }
      case COLLECTIONS.AUDITS: {
        navigateTo = "audits/instances";
        break;
      }
      case COLLECTIONS.ASSESSMENT_RESPONSE: {
        navigateTo = "audits";
        break;
      }
      case COLLECTIONS.RISK: {
        navigateTo = "risks";
        break;
      }
      default: {
        enqueueSnackbar(t_error_messages("generic"), { variant: "error" });
        throw new Error(`Can't identify path to navigate to for collection ${notification.collection}`);
      }
    }
    const id = notification.docId;

    let navigateUrl = `/${navigateTo}/${id}`;
    if (
      notification.collection === COLLECTIONS.USER ||
      notification.collection === COLLECTIONS.TASKS ||
      notification.collection === COLLECTIONS.TASK_DETAIL
    ) {
      navigate(navigateUrl);
      return;
    }

    if (notification.collection !== COLLECTIONS.EXTERNAL_RECIPIENTS) {
      if (notification.pageId && notification.collection !== COLLECTIONS.EXTERNAL_RECIPIENTS) {
        if (notification.collection === COLLECTIONS.ASSESSMENT_RESPONSE) {
          // check if this user has permission to read answer set, otherwise navigate to assessment instead of answer set.
          const docId = id.split("/answerset/");
          const affectedUser = await loadAnswerSet(docId[0], docId[1]);
          if (affectedUser.length > 0 && affectedUser.includes(auth.uid)) {
            navigateUrl += `/${notification.pageId}`;
          } else {
            navigateUrl = `/${navigateTo}/instances/${docId[0]}/responses`;
            notification.origin = { answersetId: docId[1] };
          }
        } else if (notification.collection === COLLECTIONS.AUDITS && id.includes("/answerset/")) {
          // notification assessment response has been submitted by participant
          const docId = id.split("/answerset/");
          navigateUrl = `/${navigateTo}/${docId[0]}/${notification.pageId}`;
          notification.origin = { answersetId: docId[1] };
        } else {
          navigateUrl += `/${notification.pageId}`;
        }
      } else {
        const page = notification.collection === COLLECTIONS.TOM ? "description" : "general";
        navigateUrl += `/${page}`;
      }
    }

    const notificationOrigin = omitBy(notification.origin, origin => isNull(origin) || isUndefined(origin));
    navigate(navigateUrl, {
      ...notificationOrigin
    });
  };

  const getNotificationAtIndex = function (index) {
    return notificationsFiltered[index];
  };

  const onClick = index => {
    const notification = getNotificationAtIndex(index);
    handleClick(notification);
  };

  const determineStatusIcon = index => {
    const indexInt = parseInt(index);
    const notification = getNotificationAtIndex(indexInt);
    if (notification && !notification.readAt) {
      return <FiberManualRecordIcon className={classes.rowIconError} />;
    }
  };

  const onClickButtonLeft = () => {
    return markAsRead({
      notificationIds: notificationsFiltered.map(it => it.notificationId)
    });
  };

  // content for doc view
  const docViewContent = (
    <DocView header={t("notifications")}>
      <OverviewSearchTextField
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        size={"small"}
        placeholder={t("common:search")}
        variant={"outlined"}
      />
      <OverviewList>
        {(!displayableNotifications || displayableNotifications.length === 0) && <div>{t("no_notifications")}</div>}
        {displayableNotifications.map((notification, index) => {
          if (notification.senderUID === auth.uid) {
            return <></>;
          }

          return (
            <React.Fragment key={`${notification.id}-${index}`}>
              <Row
                index={getTrueIndex(index)}
                rowDataItemText={<NotificationsTitle notification={notification} />}
                key={index}
                determineStatusIcon={() => determineStatusIcon(getTrueIndex(index))}
                onClick={() => onClick(getTrueIndex(index))}
              />
              <Divider />
            </React.Fragment>
          );
        })}
        <OverviewListItemPagination
          toolTipNext={t("next")}
          toolTipPrevious={t("previous")}
          list={notificationsFiltered}
          setDisplayableChunk={setDisplayableNotifications}
          setIndexOffset={setIndexOffset}
          numberDescriptionText={t("outOf")}
          numberOfPagesDescriptionText={t("notificationsPerPage")}
          onPageChange={setPageIndex}
          onItemsPerPageChange={setItemsPerPage}
          page={pageIndex}
          itemsPerPage={itemsPerPage}
        />
      </OverviewList>
    </DocView>
  );

  // content for meta view
  const metaViewContent = (
    <MetaView translationKey={"notifications_overview"}>
      <Box mt={3} display="flex" justifyContent="center" alignItems="center">
        <Button variant="outlined" onClick={onClickButtonLeft} disabled={isMutating}>
          {t("mark_read")}
          {isMutating && (
            <Box ml={1}>
              <CircularProgress color="inherit" size={14} />
            </Box>
          )}
        </Button>
      </Box>
    </MetaView>
  );

  return <DocMetaView docViewContent={docViewContent} metaViewContent={metaViewContent} />;
}
