export const getFieldKeysFromHardcodedLegalBasisId = (legalBasisId: string) => {
  let fields: string[] = [];
  switch (legalBasisId) {
    case "44414090f506cb232d45":
    case "4112f143-2d7b-46ba-bfcc-6c7a8aeb96a1":
    case "c249a22d-11aa-4e88-acb0-e716c0fb2104":
    case "ea7977f3-bfe4-4b61-ad30-f26b7ec70a20":
      fields = ["lbObtainingConsentValue", "lbConsentContentValue", "lbVerificationValue"];
      break;
    case "3c66b8ec02d2584cdfe2":
      fields = ["lbOtherLegalBasisValue"];
      break;
    case "e3e0121e85f97ba0ea62":
    case "346c4294-f759-4089-9738-a7f5c5bd70ec":
    case "44683922-9abb-46dd-b4d3-373b21183754":
      fields = ["lbContractValue"];
      break;
    case "d9a5f4773153a11ac85f":
    case "9f80dae3-e159-4736-b026-804ebfcbb9a5":
      fields = ["lbLegalObligationValue"];
      break;
    case "37ac81056b3c03a00197":
    case "4fe67b70-cd3f-4a2c-9e5c-a24f8abc7cc0":
    case "1fbf3e3f-7911-4d4f-880f-404cffea16ef":
    case "045cc5ee-0708-48ab-a2e7-58fe5ea578fd":
      fields = ["lbVitalInterestsValue"];
      break;
    case "6dd236adea54334de3a4":
    case "1376e36a-d3d1-486a-b51b-14ddc475f92c":
      fields = ["lbControllerInterestValue", "lbInterestOfTheDataSubjectValue", "lbInterestBalancingValue"];
      break;
    case "e30f6ab21dbfd277da99":
    case "32318996-e72a-48d7-9474-a3c3f9b7e5d4":
    case "7e8e834c-16ec-40f7-96d6-c584b363d5bd":
    case "d874b2de-0849-42a7-8b35-5833973194d3":
      fields = ["lbPublicInterestValue"];
      break;
    default:
      fields = ["lbDefaultValue"];
      break;
  }
  return fields;
};
