import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useParams } from "react-router-dom";
import { useProcessPage } from "../../../contexts/process-page-context";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import { v4 } from "uuid";
import Question from "../../../../components/Question/Question";
import TextEditor from "../utils/TextEditor";
import { checkIfTextEditorContainsText } from "../utils/textEditorConverter";
import { MetaViewAIInputProps, useMetaView } from "../../../contexts/meta-view-context";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import AssetsMultiSelect from "../../assets/components/AssetMultiSelect";
import AiAssetAccordion from "../../assets/components/AiAssetAccordion";
import PurposeAccordion, { Purpose } from "./PurposeAccordion";
import PersonGroupAccordion, { PersonGroupAccordionData } from "./PersonGroupAccordion";
import { COLLECTIONS } from "../../../collections";
import { AttachmentsOverviewOBS } from "../../shared/Attachments/AttachmentsOverviewOBS";
import { useProcessing } from "../../../api/process/processingPageApi";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";
import { ImpactedAssetDTO } from "app/api/generated/process-service";
import { usePAPermission } from "../usePAPermission";
import { AIAskAIButton } from "../../../../components/AIIntegration/AIAskAIButton";
import { sidebarZIndex } from "../../shared/Sidebar/sidebarZIndex";

const ProcessProcessingPage = (props: { readonly readonly?: boolean }) => {
  const { t } = useTranslation("questionnaires");
  const { auth } = useAuthentication();
  const { id } = useParams();
  const {
    setInfo,
    setInfoId,
    questionId,
    setAIIntegrationSuggestionCallback,
    setAIIntegrationMode,
    setAIIntegrationFieldInput
  } = useMetaView();
  const { onBeforeProcessUpdate, setProcessMeta, processMeta } = useProcessPage();
  useEnteringInfoCard({ pathName: `/processes/${id}/description`, infoId: "infocard.pa.page2" });

  const [description, setDescription] = useState<string>("");
  const [assetIds, setAssetIds] = useState<string[]>([]);
  const [purposes, setPurposes] = useState<Purpose[]>([]);
  const [personGroups, setPersonGroups] = useState<PersonGroupAccordionData[]>([]);
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);

  const isAssetFeatureEnabled = useIsFeaturePresent(FEATURES.ASSETS);
  const isAiRiskLevelFeatureEnabled = useIsFeaturePresent(FEATURES.AI_RISK_LEVEL);
  const isAiIntegrationEnabled = useIsFeaturePresent(FEATURES.AI_INTEGRATION);

  const {
    actions,
    createdNewPersonGroupIds,
    createdNewPurposeIds,
    data,
    isImpactedAssetsCreating,
    isLoading,
    isPersonGroupCreating,
    isPurposeCreating
  } = useProcessing({ documentId: id || "" });

  useEffect(() => {
    if (data) {
      setProcessMeta(data.processMeta);
    }
  }, [auth, data, id, setProcessMeta]);

  const loadingAnimationEl = useMemo(
    () => (
      <Box textAlign={"center"} mt={8}>
        <CircularProgress />
      </Box>
    ),
    []
  );

  /*  PAGE ELEMENTS - Description */
  useEffect(() => {
    if (data?.processPage.description) {
      setDescription(data?.processPage.description);
    }
  }, [data?.processPage.description]);

  const [descriptionKey, setDescriptionKey] = useState<number>(0);
  const updateDescription = useCallback(
    async (description: string) => {
      await onBeforeProcessUpdate(async () => {
        await actions.updateDescription(id || "", { description: description });
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );

  const [showDescriptionErrorMessage, setShowDescriptionErrorMessage] = useState(false);
  const onDescriptionBlur = useCallback(async () => {
    await updateDescription(description);
  }, [description, updateDescription]);

  // ref to make sure cleanup function has still has latest description
  const descriptionRef = useRef(description);
  useEffect(() => {
    descriptionRef.current = description;
  }, [description]);
  // cleanup function to make sure description is saved before unmount
  useEffect(() => {
    if (data?.processPage.description && !isLoading) {
      const initialDescription = data?.processPage.description;
      return () => {
        if (descriptionRef.current !== initialDescription) {
          updateDescription(descriptionRef.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDescriptionFocus = useCallback(async () => {
    setShowDescriptionErrorMessage(true);
    setInfoId("infocard.pa.page2.description");
  }, [setInfoId]);

  const descriptionAIFieldInput = useMemo(() => {
    return {
      fields: ["description"],
      initialSuggestions: data?.processMeta?.title ? [data.processMeta.title] : []
    } satisfies MetaViewAIInputProps;
  }, [data?.processMeta?.title]);

  /*  PAGE ELEMENTS - ASSETS */
  useEffect(() => {
    if (data?.processPage.assetIds) {
      setAssetIds(data?.processPage.assetIds);
    }
  }, [data?.processPage.assetIds]);

  const updateAssetIds = useCallback(
    async (assetIds: string[]) => {
      await onBeforeProcessUpdate(async () => {
        await actions.updateAssetIds(id || "", { assetIds: assetIds });
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );

  const createImpactedAsset = useCallback(async () => {
    await onBeforeProcessUpdate(async () => {
      const impactedAssetId = await actions.createImpactedAsset();
      setExpandedAccordion(impactedAssetId);
    });
  }, [actions, onBeforeProcessUpdate]);

  const { mutate: mutatePermission } = usePAPermission(id || "");
  const updateImpactedAssetId = useCallback(
    async (accordionId: string, data: ImpactedAssetDTO) => {
      await onBeforeProcessUpdate(async () => {
        await actions.updateImpactedAsset(id || "", accordionId, data);
        mutatePermission();
      });
    },
    [actions, id, mutatePermission, onBeforeProcessUpdate]
  );

  const saveImpactedAsset = useCallback(
    async (accordionId: string, data: ImpactedAssetDTO) => {
      await onBeforeProcessUpdate(async () => {
        await actions.updateImpactedAsset(id || "", accordionId, data);
        mutatePermission();
      });
    },
    [actions, id, onBeforeProcessUpdate, mutatePermission]
  );

  const deleteImpactedAssetId = useCallback(
    async (accordionId: string) => {
      await onBeforeProcessUpdate(async () => {
        await actions.deleteImpactedAsset(id || "", accordionId);
        mutatePermission();
      });
      setAssetIds(assetIds.filter(id => id !== accordionId));
      setExpandedAccordion(false);
    },
    [actions, assetIds, id, mutatePermission, onBeforeProcessUpdate]
  );

  const aiAssetAccordionEl = (
    <AiAssetAccordion
      paId={id || ""}
      disabled={props.readonly}
      expandedAccordion={expandedAccordion}
      impactedAssets={data?.processPage?.impactedAssets || []}
      orgUnitIds={processMeta?.relativeOrgUnitIds}
      onDelete={deleteImpactedAssetId}
      onChange={updateImpactedAssetId}
      onSave={saveImpactedAsset}
    />
  );

  const assetEl = isAssetFeatureEnabled && (
    <>
      <Box mt={4}>
        <QuestionnaireSubHeader text={t("asset_details:affected_assets")} />
        {isAiRiskLevelFeatureEnabled ? (
          <>
            {aiAssetAccordionEl}
            <Box mt={2}>
              <Button
                color="primary"
                disabled={props.readonly || isImpactedAssetsCreating}
                onClick={createImpactedAsset}
                variant="contained"
              >
                <Box display="flex" alignItems="center">
                  {t("pa_data_processing:add_new")}
                  {isImpactedAssetsCreating && (
                    <Box ml={1} display="flex" alignItems="center">
                      <CircularProgress size="1rem" />
                    </Box>
                  )}
                </Box>
              </Button>
            </Box>
          </>
        ) : (
          <Question
            disabled={props.readonly}
            pb={0}
            pl={0}
            pr={0}
            pt={0}
            questionId={"assetIds"}
            questionName={t("asset_details:affected_assets")}
          >
            <AssetsMultiSelect
              disabled={props.readonly}
              onChange={updateAssetIds}
              orgUnitIds={processMeta?.relativeOrgUnitIds}
              selectedAssetIds={assetIds}
            />
          </Question>
        )}
      </Box>
    </>
  );
  /*  PAGE ELEMENTS - ATTACHMENTS */
  const isFileUploadOnProcessingActivitiesAllowed = useIsFeaturePresent(FEATURES.FILE_UPLOAD_ON_PROCESSING_ACTIVITIES);
  const attachmentEl = isFileUploadOnProcessingActivitiesAllowed ? (
    <Box mt={4}>
      <QuestionnaireSubHeader text={t("attachments")} />
      <AttachmentsOverviewOBS docId={id} category={COLLECTIONS.PROCESSES + "-description"} disabled={props.readonly} />
    </Box>
  ) : (
    <></>
  );

  /*  PAGE ELEMENTS - Purposes */
  useEffect(() => {
    if (data?.processPage.purposes) {
      setPurposes(data?.processPage.purposes);
    }
  }, [data?.processPage.purposes]);

  const createPurpose = useCallback(async () => {
    await onBeforeProcessUpdate(async () => {
      const newId = v4();
      await actions.createPurpose(id || "", newId);
    });
  }, [actions, id, onBeforeProcessUpdate]);

  const savePurpose = useCallback(
    async updatedPurpose => {
      await onBeforeProcessUpdate(async () => {
        await actions.updatePurpose(id || "", updatedPurpose.id, { ...updatedPurpose, id: undefined });
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );

  const deletePurpose = useCallback(
    async purposeId => {
      await onBeforeProcessUpdate(async () => {
        await actions.deletePurpose(id || "", purposeId);
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );

  const purposesEl = (
    <>
      <Box mt={4}>
        <QuestionnaireSubHeader text={t("pa_data_processing:purpose_of_processing_title")} />
      </Box>
      {purposes.map(purpose => {
        const questionId = `purposes ${purpose.id}`;
        return (
          <Question
            disabled={props.readonly}
            key={purpose.id}
            questionId={questionId}
            questionName={purpose.purposeDescription}
            translatable={purpose.purposeDescription}
          >
            <PurposeAccordion
              questionId={questionId}
              disabled={props.readonly}
              purpose={purpose}
              paOrgUnitIds={processMeta?.relativeOrgUnitIds || []}
              onDelete={deletePurpose}
              onSave={savePurpose}
              isCreatedNew={createdNewPurposeIds.includes(purpose.id)}
            />
          </Question>
        );
      })}
      <Box mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={createPurpose}
          disabled={props.readonly || isPurposeCreating}
        >
          <Box display="flex" alignItems="center">
            {t("pa_data_processing:add_new")}
            {isPurposeCreating && (
              <Box ml={1} display="flex" alignItems="center">
                <CircularProgress size="1rem" />
              </Box>
            )}
          </Box>
        </Button>
      </Box>
    </>
  );

  /*  PAGE ELEMENTS - Person Groups */
  useEffect(() => {
    const newPersonGroups = data?.processPage.personGroups;
    if (newPersonGroups) {
      setPersonGroups(newPersonGroups);
    }
  }, [data?.processPage.personGroups]);
  const newPersonalData = useMemo(
    () => ({
      title: t("newPersonalDataTitle"),
      text: t("newPersonalDataText")
    }),
    [t]
  );
  const createPersonGroup = useCallback(async () => {
    await onBeforeProcessUpdate(async () => {
      setInfo(newPersonalData);
      const newId = v4();
      await actions.createPersonGroup(id || "", newId);
      mutatePermission();
    });
  }, [actions, id, mutatePermission, newPersonalData, onBeforeProcessUpdate, setInfo]);
  const savePersonGroup = useCallback(
    async updatedPersonGroup => {
      await onBeforeProcessUpdate(async () => {
        await actions.updatePersonGroup(id || "", updatedPersonGroup.id, { ...updatedPersonGroup, id: undefined });
        mutatePermission();
      });
    },
    [actions, id, mutatePermission, onBeforeProcessUpdate]
  );
  const deletePersonGroup = useCallback(
    async personGroupId => {
      await onBeforeProcessUpdate(async () => {
        await actions.deletePersonGroup(id || "", personGroupId);
        mutatePermission();
      });
    },
    [actions, id, mutatePermission, onBeforeProcessUpdate]
  );
  const personGroupsEl = (
    <>
      <Box mt={6}>
        <QuestionnaireSubHeader text={t("1_table:descriptionSidebar")} />
      </Box>
      {personGroups.map(personGroup => (
        <PersonGroupAccordion
          disabled={props.readonly}
          isCreatedNew={createdNewPersonGroupIds.includes(personGroup.id)}
          key={personGroup.id}
          onDelete={deletePersonGroup}
          onSave={savePersonGroup}
          personGroup={personGroup}
        />
      ))}
      <Box mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={createPersonGroup}
          disabled={props.readonly || isPersonGroupCreating}
        >
          <Box display="flex" alignItems="center">
            {t("pa_data_processing:add_new")}
            {isPersonGroupCreating && (
              <Box ml={1} display="flex" alignItems="center">
                <CircularProgress size="1rem" />
              </Box>
            )}
          </Box>
        </Button>
      </Box>
    </>
  );

  const updatedDescriptionFromAISuggestion = useCallback(
    (suggestion: object) => {
      const updatedDescription = "description" in suggestion && suggestion["description"];
      if (updatedDescription && typeof updatedDescription === "string") {
        setDescription(updatedDescription);
        updateDescription(updatedDescription);
        setDescriptionKey(value => value + 1);
      }
    },
    [updateDescription]
  );

  useEffect(() => {
    if (questionId === "description") {
      setAIIntegrationMode("suggest-field");
      setAIIntegrationFieldInput(descriptionAIFieldInput);
      setAIIntegrationSuggestionCallback(() => updatedDescriptionFromAISuggestion);
      return () => {
        setAIIntegrationMode(null);
        setAIIntegrationFieldInput({ fields: [] });
        setAIIntegrationSuggestionCallback(undefined);
      };
    }
  }, [
    descriptionAIFieldInput,
    questionId,
    setAIIntegrationFieldInput,
    setAIIntegrationMode,
    setAIIntegrationSuggestionCallback,
    updatedDescriptionFromAISuggestion
  ]);
  useEffect(() => {
    return () => setAIIntegrationMode(null);
  }, [setAIIntegrationMode]);

  const descriptionEl = (
    <Box>
      <QuestionnaireSubHeader text={t("pa_data_processing:page_title")} />
      <Question
        disabled={props.readonly}
        questionId={"description"}
        questionName={t("0_0_summary:questionTitle")}
        title={t("0_0_summary:questionTitle")}
        translatable={description}
      >
        <Box position="relative">
          <TextEditor
            key={`description${descriptionKey}`}
            disabled={props.readonly}
            inputValue={description}
            onBlur={onDescriptionBlur}
            onChange={setDescription}
            onFocus={onDescriptionFocus}
          />
          {isAiIntegrationEnabled ? (
            <Box position="absolute" right={16} bottom={16} zIndex={sidebarZIndex + 1}>
              <AIAskAIButton
                fieldInput={descriptionAIFieldInput}
                onSuggestionCallback={updatedDescriptionFromAISuggestion}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
        {showDescriptionErrorMessage && !checkIfTextEditorContainsText(description) && (
          <Typography color="error">{t("questionnaires:mandatoryField")}</Typography>
        )}
      </Question>
    </Box>
  );

  if (isLoading) {
    return loadingAnimationEl;
  } else {
    return (
      <>
        {descriptionEl}
        {attachmentEl}
        {assetEl}
        {purposesEl}
        {personGroupsEl}
      </>
    );
  }
};

export default ProcessProcessingPage;
