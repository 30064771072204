import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";

const OverviewBrickRename = ({ hovered, item, onRename }: OverviewRowBrickProps) => {
  const disableActions = item.disableActions;
  const onClick = useCallback(
    event => {
      stopEvent(event);
      onRename?.();
    },
    [onRename]
  );

  if (!hovered || disableActions?.some(({ action }) => action === "edit")) {
    return <></>;
  }
  return (
    <IconButton onClick={onClick} size="small">
      <Edit />
    </IconButton>
  );
};

export default OverviewBrickRename;
