import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { DeleteForever, Loupe } from "@material-ui/icons";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { v4 } from "uuid";
import { useParams } from "react-router-dom";
import UserAvatar from "../../../components/UserAvatar/UserAvatar";
import BasicPagination from "components/BasicPagination/BasicPagination";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { META_VIEW_TABS } from "components/MetaView/MetaView";
import { useMetaView } from "app/contexts/meta-view-context";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import {
  createTask,
  deleteTask,
  genericTasksFilter,
  markTaskAsDone,
  sortTaskByPrioprity,
  TYPES
} from "app/handlers/tasksHandler";
import { useTasks } from "app/contexts/tasks-context";
import { COLLECTIONS } from "app/collections";
import TaskStatus from "app/pages/tasks/controls/TaskStatus";
import GoTo from "app/pages/shared/GoTo/GoTo";
import { getTextFromTextEditorJsonString } from "app/pages/questionnaires/utils/textEditorConverter";
import TaskDeleteConfirmation from "app/pages/tasks/TaskDeleteConfirmation";
import { useQueryParamByKey } from "app/router/router-custom-hooks";
import TaskDetails from "app/pages/tasks/details/TaskDetails";

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.blue[50],
    borderRadius: 6,
    position: "relative",
    padding: "32px 0"
  },
  root: {
    "&:hover": {
      background: theme.palette.grey[50]
    }
  },
  rootTitle: {
    maxWidth: "75%",
    margin: "auto"
  },
  task: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  main: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flex: 1
  },
  mainTitle: {
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  mainDescription: {
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.grey[600]
  },
  footer: {
    display: "flex",
    justifyContent: "space-around"
  },
  modal: {
    textAlign: "center"
  },
  modalAction: {
    display: "flex",
    justifyContent: "space-around"
  },
  noTasks: {
    background: theme.palette.blue[50],
    textAlign: "center"
  },
  button: {
    padding: "0px 0px 0px 0px",
    maxWidth: "140px",
    width: "10vw",
    maxHeight: "25px",
    height: "10vh",
    color: theme.palette.primary.main
  },
  buttonText: {
    fontSize: "11px"
  },
  buttonDelete: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light
    }
  },
  buttonShowMore: {
    color: theme.palette.primary.main
  },
  backButton: {
    position: "absolute",
    left: "16px"
  },
  taskDetails: {
    position: "absolute",
    top: "0px",
    left: "0px",
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: "0",
    background: theme.palette.common.white,
    zIndex: 1300
  }
}));

interface TaskMetaViewProps {
  readonly id: string;
  readonly title: string;
  readonly description: string;
  readonly type: string;
  readonly collection: string;
  readonly documentId: string;
  readonly pageId: string;
  readonly questionId: string;
  readonly assigneeUID: string;
}

const TaskShort = ({
  task,
  expanded,
  onExpand,
  onOpenTaskDetails,
  onDelete,
  reloadDocRelatedTasks
}: {
  readonly task: TaskMetaViewProps;
  readonly expanded: boolean;
  readonly reloadDocRelatedTasks: () => void;
  readonly onExpand: (id: string) => void;
  readonly onDelete: (id: string) => void;
  readonly onOpenTaskDetails: (id: string) => void;
}) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { auth } = useAuthentication();
  const { id, collection, documentId, pageId, questionId } = task;

  const [showInlineTaskDeletion, setShowInlineDeletion] = useState(false);

  const onExpandCallback = useCallback(() => onExpand(id), [onExpand, id]);
  const onOpenTaskDetailsCallback = useCallback(() => onOpenTaskDetails(id), [onOpenTaskDetails, id]);
  const onDeleteTaskCallback = useCallback(() => {
    setShowInlineDeletion(false);
    onDelete(id);
  }, [id, onDelete]);

  const openDeleteConfiramtion = useCallback(() => setShowInlineDeletion(true), []);
  const closeDeleteConfiramtion = useCallback(() => setShowInlineDeletion(false), []);

  return (
    <React.Fragment key={task.id}>
      <Box p={2} className={`${cls.root}`}>
        <Box onClick={onExpandCallback} className={cls.task} position="relative">
          <UserAvatar userId={task.assigneeUID} size="large" />
          <Box mx={2} className={cls.main}>
            <Box className={cls.mainTitle}>{task.title}</Box>
          </Box>
          <TaskStatus task={task} auth={auth} onChangeStatus={reloadDocRelatedTasks} />
        </Box>
        {expanded && (
          <>
            <Box p={1} mt={1} pb={0}>
              {getTextFromTextEditorJsonString(task.description) || t("no_description")}
              <Box px={3}>
                {!showInlineTaskDeletion && (
                  <Box display="flex" justifyContent="space-between" alignItems="center" pt={2}>
                    <Tooltip title={t("tasks_page:more_details")}>
                      <IconButton className={cls.buttonShowMore} onClick={onOpenTaskDetailsCallback}>
                        <Loupe />
                      </IconButton>
                    </Tooltip>
                    {task.type !== TYPES.individual && (
                      <GoTo
                        collection={collection}
                        documentId={documentId}
                        pageId={pageId}
                        questionId={questionId}
                        tooltipText={t("go_to_task_in_doc")}
                        showIconText={false}
                        taskId={undefined}
                        openInNewTab={undefined}
                      />
                    )}
                    <Tooltip title={t("delete_task")}>
                      <IconButton className={cls.buttonDelete} onClick={openDeleteConfiramtion}>
                        <DeleteForever />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Box>
            {showInlineTaskDeletion && (
              <Box pt={2}>
                <TaskDeleteConfirmation onConfirm={onDeleteTaskCallback} onCancel={closeDeleteConfiramtion} />
              </Box>
            )}
          </>
        )}
      </Box>
      <Divider />
    </React.Fragment>
  );
};

export default function MetaViewTasksTab({
  collection,
  pageId,
  docId
}: {
  readonly collection: string;
  readonly pageId?: string;
  readonly docId?: string;
}) {
  const { t } = useTranslation("task_details");
  const cls = useStyles();
  const elementsPerPage = 5;
  const paramTaskId = useQueryParamByKey("taskId");

  const {
    selectedTab,
    questionId,
    questionName,
    setQuestionId,
    newTaskForm,
    showTaskDetails,
    setSelectedTab,
    setShowTaskDetails,
    setNewTaskForm,
    setLastCreatedTaskId
  } = useMetaView();

  /* STATE */
  const params = useParams();
  const pageParam = params?.page;
  const documentId = docId || params?.id;
  const { auth } = useAuthentication();

  const { addToSeenItemsOfUserHook } = useUserAndTenantData();

  /* useState */
  const [modal, setModal] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [taskDetailsType, setTaskDetailsType] = useState<"DEFAULT" | "RECURRING" | "GROUP">("DEFAULT");
  const [taskDetailsId, setTaskDetailsId] = useState<string | null>(null);

  const closeTaskDetails = useCallback(() => {
    setTaskDetailsId(null);
    setShowTaskDetails(false);
    setTaskDetailsType("DEFAULT");
  }, [setShowTaskDetails]);

  const onPageChange = useCallback(({ route, id, detailType, withReload }) => {
    setTaskDetailsType(detailType);
    setTaskDetailsId(id);
  }, []);

  const [page, setPage] = useState(1);

  const openModal = useCallback(() => setModal(true), []);
  const closeModal = useCallback(() => setModal(false), []);

  const onExpandedClick = useCallback(
    taskId => {
      setExpanded(expanded === taskId ? null : taskId);
    },
    [expanded]
  );

  const onOpenTaskDetailsCallback = useCallback(
    taskId => {
      setNewTaskForm(false);
      setShowTaskDetails(true);
      setTaskDetailsId(taskId);
    },
    [setNewTaskForm, setShowTaskDetails]
  );
  const resetQuestionId = useCallback(() => setQuestionId(null), [setQuestionId]);

  const { docTasks, reloadDocRelatedTasks, loading: tasksLoading } = useTasks();
  const createNewTask = useCallback(
    async title => {
      const taskId = v4();
      await createTask({
        tenantId: auth?.tenantId || "",
        task: {
          id: taskId,
          assigneeUID: auth?.uid,
          collection: collection,
          title: title,
          description: null,
          labels: [],
          documentId: documentId || "",
          questionId: questionId,
          pageId: pageId || pageParam,
          status: null
        }
      });
      await addToSeenItemsOfUserHook(COLLECTIONS.TASKS, taskId);
      reloadDocRelatedTasks();
      onOpenTaskDetailsCallback(taskId);
    },
    [
      auth?.tenantId,
      auth?.uid,
      collection,
      documentId,
      questionId,
      pageParam,
      addToSeenItemsOfUserHook,
      reloadDocRelatedTasks,
      onOpenTaskDetailsCallback,
      pageId
    ]
  );

  /* useEffect */
  useEffect(() => {
    if (paramTaskId) {
      onOpenTaskDetailsCallback(paramTaskId);
    }
  }, [onOpenTaskDetailsCallback, paramTaskId]);

  useEffect(() => {
    const tasks: any[] = genericTasksFilter(docTasks, { documentId, questionId });
    const sortedTasks: any[] = sortTaskByPrioprity(tasks);
    setTasks(sortedTasks);
  }, [docTasks, documentId, questionId, selectedTab, setLastCreatedTaskId]);

  useEffect(() => {
    setPage(1);
  }, [questionId]);

  useEffect(() => {
    if (selectedTab === META_VIEW_TABS.TODOS) {
      reloadDocRelatedTasks();
    }
  }, [selectedTab, reloadDocRelatedTasks]);

  useEffect(() => {
    if (newTaskForm) {
      createNewTask(createOverviewItemDefaultName("tasks"));
    }
  }, [createNewTask, newTaskForm]);

  const markAllTasksAsDone = useCallback(async () => {
    if (auth && auth.tenantId) {
      await Promise.all(tasks.map((task: TaskMetaViewProps) => markTaskAsDone(auth.tenantId, auth.uid, task.id)));
      closeModal();
      reloadDocRelatedTasks();
    }
  }, [auth, tasks, closeModal, reloadDocRelatedTasks]);

  const modalDialog = useMemo(
    () => (
      <Modal open={modal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <Box width={"30%"} mt={10} mx={"auto"}>
          <Paper>
            <Box p={4}>
              <Box className={cls.modal} mb={4}>
                {t("tasks_page:set_all_done_confirm")}
              </Box>
              <Box className={cls.modalAction}>
                <Button color="primary" onClick={markAllTasksAsDone}>
                  {t("common:yes")}
                </Button>
                <Button color="primary" variant="contained" onClick={closeModal}>
                  {t("common:no")}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>
    ),
    [closeModal, cls.modal, cls.modalAction, markAllTasksAsDone, modal, t]
  );

  const getTasksChunk = useCallback(() => {
    return tasks.slice((page - 1) * elementsPerPage || 0, (page - 1) * elementsPerPage + elementsPerPage);
  }, [page, elementsPerPage, tasks]);

  const onTaskDelete = useCallback(
    async taskId => {
      await deleteTask(taskId);
      await reloadDocRelatedTasks();
      setTaskDetailsType("DEFAULT");
      closeTaskDetails();
      setSelectedTab(META_VIEW_TABS.TODOS);
    },
    [closeTaskDetails, reloadDocRelatedTasks, setSelectedTab]
  );

  const taskListEl = (getTasksChunk() || []).map((task: TaskMetaViewProps) => (
    <TaskShort
      key={task.id}
      onExpand={onExpandedClick}
      onOpenTaskDetails={onOpenTaskDetailsCallback}
      expanded={expanded === task.id}
      task={task}
      reloadDocRelatedTasks={reloadDocRelatedTasks}
      onDelete={onTaskDelete}
    />
  ));

  const backButtonEl = useMemo(
    () => (
      <Tooltip title={t("tasks_page:show_all_tasks")}>
        <IconButton size="small" aria-label="close" className={cls.backButton} onClick={resetQuestionId}>
          <ChevronLeftIcon />
        </IconButton>
      </Tooltip>
    ),
    [cls.backButton, resetQuestionId, t]
  );

  const noTasksEl = useMemo(
    () => (
      <Box className={cls.noTasks} px={2}>
        {questionId && backButtonEl}
        <Typography variant="h3" align="center" component="div" noWrap>
          {t("tasks_page:good_job")}
        </Typography>
        <p>{t(`tasks_page:no_todos`)}</p>
      </Box>
    ),
    [backButtonEl, cls.noTasks, questionId, t]
  );

  const titleEl = useMemo(
    () =>
      tasks.length > 0 && (
        <>
          <Box px={2}>
            {questionId && backButtonEl}
            <Typography variant="h3" align="center" className={cls.rootTitle} component="div" noWrap>
              {questionId ? t("tasks_page:field_specific_tasks") : t("tasks_page:all_tasks")}
            </Typography>
          </Box>
          <Box mb={3}>
            {questionId && questionName && (
              <Box mt={2} px={2}>
                <Typography variant="h4" align="center" component="div" noWrap={true}>
                  {questionName}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      ),
    [backButtonEl, cls.rootTitle, questionId, questionName, t, tasks.length]
  );

  const onFooterClicked = useCallback(() => {
    createNewTask(createOverviewItemDefaultName("tasks"));
  }, [createNewTask]);

  const footerEl = useMemo(
    () => (
      <Box className={cls.footer} mt={4} ml={2} mr={2}>
        <Button size="small" color="primary" onClick={onFooterClicked}>
          {t("tasks_page:create_task")}
        </Button>
        {tasks.length > 0 && (
          <Button size="small" onClick={openModal}>
            {t("tasks_page:mark_all_done")}
          </Button>
        )}
      </Box>
    ),
    [cls.footer, onFooterClicked, openModal, t, tasks.length]
  );

  const tasksLoaderEl = (
    <Box mt={10} textAlign="center">
      <CircularProgress />
    </Box>
  );

  const paginationEl = useMemo(
    () =>
      tasks.length > 5 && (
        <BasicPagination
          elementsPerPage={elementsPerPage}
          numberElements={tasks.length}
          page={page}
          setPage={setPage}
        />
      ),
    [page, tasks.length]
  );

  const tasksListEl = useMemo(
    () => (
      <Box className={cls.container}>
        {titleEl}
        {tasks.length > 0 ? taskListEl : noTasksEl}
        {paginationEl}
        {footerEl}
        {tasks.length > 0 && modalDialog}
      </Box>
    ),
    [cls.container, footerEl, modalDialog, noTasksEl, paginationEl, taskListEl, tasks.length, titleEl]
  );

  const taskDetailsEl = useMemo(
    () =>
      showTaskDetails && taskDetailsId ? (
        <Box className={cls.taskDetails}>
          <Suspense fallback={<div>Loading...</div>}>
            <TaskDetails
              detailType={taskDetailsType}
              taskId={taskDetailsId}
              onTaskDelete={onTaskDelete}
              onTaskEdit={reloadDocRelatedTasks}
              onCloseTaskDetails={closeTaskDetails}
              onPageChange={onPageChange}
            />
          </Suspense>
        </Box>
      ) : (
        <></>
      ),
    [
      closeTaskDetails,
      cls.taskDetails,
      onPageChange,
      onTaskDelete,
      reloadDocRelatedTasks,
      showTaskDetails,
      taskDetailsId,
      taskDetailsType
    ]
  );

  if (showTaskDetails) {
    return taskDetailsEl;
  } else if (tasksLoading) {
    return tasksLoaderEl;
  } else return tasksListEl;
}
