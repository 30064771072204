/* tslint:disable */
/* eslint-disable */
/**
 * AI Service API
 * AI Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface FieldsResponseDTO
 */
export interface FieldsResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof FieldsResponseDTO
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface OkResponse
 */
export interface OkResponse {
    /**
     * 
     * @type {string}
     * @memberof OkResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface SuggestFieldsRequest
 */
export interface SuggestFieldsRequest {
    /**
     * 
     * @type {object}
     * @memberof SuggestFieldsRequest
     */
    'tenantContext'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof SuggestFieldsRequest
     */
    'generateFields'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SuggestFieldsRequest
     */
    'prompt'?: string;
}
/**
 * 
 * @export
 * @interface SuggestPADetailsRequest
 */
export interface SuggestPADetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof SuggestPADetailsRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestPADetailsRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestPADetailsRequest
     */
    'prompt': string;
    /**
     * 
     * @type {SuggestPADetailsRequestTenantContext}
     * @memberof SuggestPADetailsRequest
     */
    'tenantContext': SuggestPADetailsRequestTenantContext;
}
/**
 * 
 * @export
 * @interface SuggestPADetailsRequestTenantContext
 */
export interface SuggestPADetailsRequestTenantContext {
    /**
     * 
     * @type {string}
     * @memberof SuggestPADetailsRequestTenantContext
     */
    'tenantName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SuggestPADetailsRequestTenantContext
     */
    'resources': Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SuggestPADetailsRequestTenantContext
     */
    'personGroupsTree': Array<object>;
}
/**
 * 
 * @export
 * @interface SuggestTitlesRequest
 */
export interface SuggestTitlesRequest {
    /**
     * 
     * @type {object}
     * @memberof SuggestTitlesRequest
     */
    'tenantContext'?: object;
    /**
     * 
     * @type {string}
     * @memberof SuggestTitlesRequest
     */
    'prompt'?: string;
}
/**
 * 
 * @export
 * @interface TitleResponseDTO
 */
export interface TitleResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof TitleResponseDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleResponseDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface TitlesResponseDTO
 */
export interface TitlesResponseDTO {
    /**
     * 
     * @type {Array<TitleResponseDTO>}
     * @memberof TitlesResponseDTO
     */
    'items'?: Array<TitleResponseDTO>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get fields recommendation
         * @summary Get fields recommendation
         * @param {string} collection Collection
         * @param {string} sessionId Session id
         * @param {SuggestFieldsRequest} suggestFieldsRequest Field generation request
         * @param {boolean} [streaming] Enable streaming response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestFields: async (collection: string, sessionId: string, suggestFieldsRequest: SuggestFieldsRequest, streaming?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collection' is not null or undefined
            assertParamExists('suggestFields', 'collection', collection)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('suggestFields', 'sessionId', sessionId)
            // verify required parameter 'suggestFieldsRequest' is not null or undefined
            assertParamExists('suggestFields', 'suggestFieldsRequest', suggestFieldsRequest)
            const localVarPath = `/api/fields/{collection}/{sessionId}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (streaming !== undefined) {
                localVarQueryParameter['streaming'] = streaming;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suggestFieldsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get PA details recommendation
         * @summary Get PA details recommendation
         * @param {string} sessionId Session id
         * @param {SuggestPADetailsRequest} suggestPADetailsRequest PA details generation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestPADetails: async (sessionId: string, suggestPADetailsRequest: SuggestPADetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('suggestPADetails', 'sessionId', sessionId)
            // verify required parameter 'suggestPADetailsRequest' is not null or undefined
            assertParamExists('suggestPADetails', 'suggestPADetailsRequest', suggestPADetailsRequest)
            const localVarPath = `/api/pa-details/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suggestPADetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Titles recommendation
         * @summary Get Titles recommendation
         * @param {string} collection Collection
         * @param {string} sessionId Session id
         * @param {SuggestTitlesRequest} suggestTitlesRequest Get Title model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestTitles: async (collection: string, sessionId: string, suggestTitlesRequest: SuggestTitlesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collection' is not null or undefined
            assertParamExists('suggestTitles', 'collection', collection)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('suggestTitles', 'sessionId', sessionId)
            // verify required parameter 'suggestTitlesRequest' is not null or undefined
            assertParamExists('suggestTitles', 'suggestTitlesRequest', suggestTitlesRequest)
            const localVarPath = `/api/titles/{collection}/{sessionId}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suggestTitlesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Thumbs down
         * @summary Thumbs down
         * @param {string} sessionId Session id
         * @param {string} requestId Request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thumbsDown: async (sessionId: string, requestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('thumbsDown', 'sessionId', sessionId)
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('thumbsDown', 'requestId', requestId)
            const localVarPath = `/api/sessions/{sessionId}/{requestId}/thumbsdown`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Thumbs up
         * @summary Thumbs up
         * @param {string} sessionId Session id
         * @param {string} requestId Request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thumbsUp: async (sessionId: string, requestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('thumbsUp', 'sessionId', sessionId)
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('thumbsUp', 'requestId', requestId)
            const localVarPath = `/api/sessions/{sessionId}/{requestId}/thumbsup`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get fields recommendation
         * @summary Get fields recommendation
         * @param {string} collection Collection
         * @param {string} sessionId Session id
         * @param {SuggestFieldsRequest} suggestFieldsRequest Field generation request
         * @param {boolean} [streaming] Enable streaming response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestFields(collection: string, sessionId: string, suggestFieldsRequest: SuggestFieldsRequest, streaming?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestFields(collection, sessionId, suggestFieldsRequest, streaming, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get PA details recommendation
         * @summary Get PA details recommendation
         * @param {string} sessionId Session id
         * @param {SuggestPADetailsRequest} suggestPADetailsRequest PA details generation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestPADetails(sessionId: string, suggestPADetailsRequest: SuggestPADetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestPADetails(sessionId, suggestPADetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Titles recommendation
         * @summary Get Titles recommendation
         * @param {string} collection Collection
         * @param {string} sessionId Session id
         * @param {SuggestTitlesRequest} suggestTitlesRequest Get Title model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestTitles(collection: string, sessionId: string, suggestTitlesRequest: SuggestTitlesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitlesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestTitles(collection, sessionId, suggestTitlesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Thumbs down
         * @summary Thumbs down
         * @param {string} sessionId Session id
         * @param {string} requestId Request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async thumbsDown(sessionId: string, requestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.thumbsDown(sessionId, requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Thumbs up
         * @summary Thumbs up
         * @param {string} sessionId Session id
         * @param {string} requestId Request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async thumbsUp(sessionId: string, requestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.thumbsUp(sessionId, requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<OkResponse> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
        /**
         * Get fields recommendation
         * @summary Get fields recommendation
         * @param {string} collection Collection
         * @param {string} sessionId Session id
         * @param {SuggestFieldsRequest} suggestFieldsRequest Field generation request
         * @param {boolean} [streaming] Enable streaming response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestFields(collection: string, sessionId: string, suggestFieldsRequest: SuggestFieldsRequest, streaming?: boolean, options?: any): AxiosPromise<FieldsResponseDTO> {
            return localVarFp.suggestFields(collection, sessionId, suggestFieldsRequest, streaming, options).then((request) => request(axios, basePath));
        },
        /**
         * Get PA details recommendation
         * @summary Get PA details recommendation
         * @param {string} sessionId Session id
         * @param {SuggestPADetailsRequest} suggestPADetailsRequest PA details generation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestPADetails(sessionId: string, suggestPADetailsRequest: SuggestPADetailsRequest, options?: any): AxiosPromise<FieldsResponseDTO> {
            return localVarFp.suggestPADetails(sessionId, suggestPADetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Titles recommendation
         * @summary Get Titles recommendation
         * @param {string} collection Collection
         * @param {string} sessionId Session id
         * @param {SuggestTitlesRequest} suggestTitlesRequest Get Title model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestTitles(collection: string, sessionId: string, suggestTitlesRequest: SuggestTitlesRequest, options?: any): AxiosPromise<TitlesResponseDTO> {
            return localVarFp.suggestTitles(collection, sessionId, suggestTitlesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Thumbs down
         * @summary Thumbs down
         * @param {string} sessionId Session id
         * @param {string} requestId Request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thumbsDown(sessionId: string, requestId: string, options?: any): AxiosPromise<void> {
            return localVarFp.thumbsDown(sessionId, requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Thumbs up
         * @summary Thumbs up
         * @param {string} sessionId Session id
         * @param {string} requestId Request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thumbsUp(sessionId: string, requestId: string, options?: any): AxiosPromise<void> {
            return localVarFp.thumbsUp(sessionId, requestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Health check
     * @summary Health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public health(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get fields recommendation
     * @summary Get fields recommendation
     * @param {string} collection Collection
     * @param {string} sessionId Session id
     * @param {SuggestFieldsRequest} suggestFieldsRequest Field generation request
     * @param {boolean} [streaming] Enable streaming response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public suggestFields(collection: string, sessionId: string, suggestFieldsRequest: SuggestFieldsRequest, streaming?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).suggestFields(collection, sessionId, suggestFieldsRequest, streaming, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get PA details recommendation
     * @summary Get PA details recommendation
     * @param {string} sessionId Session id
     * @param {SuggestPADetailsRequest} suggestPADetailsRequest PA details generation request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public suggestPADetails(sessionId: string, suggestPADetailsRequest: SuggestPADetailsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).suggestPADetails(sessionId, suggestPADetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Titles recommendation
     * @summary Get Titles recommendation
     * @param {string} collection Collection
     * @param {string} sessionId Session id
     * @param {SuggestTitlesRequest} suggestTitlesRequest Get Title model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public suggestTitles(collection: string, sessionId: string, suggestTitlesRequest: SuggestTitlesRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).suggestTitles(collection, sessionId, suggestTitlesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Thumbs down
     * @summary Thumbs down
     * @param {string} sessionId Session id
     * @param {string} requestId Request id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public thumbsDown(sessionId: string, requestId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).thumbsDown(sessionId, requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Thumbs up
     * @summary Thumbs up
     * @param {string} sessionId Session id
     * @param {string} requestId Request id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public thumbsUp(sessionId: string, requestId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).thumbsUp(sessionId, requestId, options).then((request) => request(this.axios, this.basePath));
    }
}


