import { MailOutline } from "@material-ui/icons";
import { Chip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

export default function NewDataRoomMessageBrick(
  props: OverviewRowBrickProps<{
    unreadMessageCount: number;
  }>
) {
  if (props.item.unreadMessageCount === 0) {
    return null;
  }
  return (
    <Chip
      size="small"
      sx={{
        backgroundColor: "warning.dark",
        color: "white",
        ["& .MuiChip-label"]: {
          marginTop: "2px"
        }
      }}
      icon={<MailOutline />}
      label={props.item.unreadMessageCount}
    />
  );
}
