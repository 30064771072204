import React, { useState, useEffect, useCallback, useMemo } from "react";
import { AppBar, Badge, Box, IconButton, Toolbar, useTheme } from "@mui/material";
import LanguageSelector from "./Language";
import { useSidebarUnseen } from "./../Sidebar/useSidebarUnseen";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useAvailableLanguages } from "hook/useAvailableLanguages";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import UserAvatarTopbar from "./UserAvatarTopbar";
import { useNavigate } from "react-router-dom";
import { usePathName } from "app/router/router-custom-hooks";
import { useDocumentName } from "app/contexts/document-name-context";
import Help from "./Help";
import { TopbarBreadcrumbs } from "./Breadcrumbs";

export default function Topbar() {
  const [lng, setLng] = useState("");
  const { clearDocumentName } = useDocumentName();
  const theme = useTheme();
  const { sidebarNewItems } = useSidebarUnseen();
  const { user } = useAuthentication();
  const { tenantData } = useUserAndTenantData();
  const path = usePathName();
  const navigate = useNavigate();
  const { i18n } = useTranslation("sidebar");
  const { availableLanguages, isLoadedFromTenantData: availableLanguagesLoadedFromTenant } = useAvailableLanguages();
  // set language to previously selected or default value
  useEffect(() => {
    if (!tenantData?.features) {
      return;
    }
    if (!availableLanguagesLoadedFromTenant) {
      return;
    }

    const currentLanguage = i18n.language;
    if (!availableLanguages.includes(currentLanguage)) {
      const fallbackLanguage = tenantData.companyLanguage || "en";
      setLng(fallbackLanguage);
      i18n.changeLanguage(fallbackLanguage);
      return;
    }

    setLng(currentLanguage);
  }, [availableLanguagesLoadedFromTenant, tenantData?.features, availableLanguages, i18n, tenantData?.companyLanguage]);

  const isOnPage = useMemo(() => {
    const getFirstPath = (input: string) => input.split("/").filter(it => it)[0];
    return getFirstPath(path) === getFirstPath("/notifications");
  }, [path]);

  const onNotificationClick = useCallback(() => {
    clearDocumentName();
    navigate("/notifications");
  }, [navigate, clearDocumentName]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{
          backgroundColor: theme.palette.common.white,
          borderBottom: "1px solid rgba(57, 58, 50, 0.12)",
          justifySelf: "center",
          alignSelf: "center",
          paddingTop: "12",
          paddingBottom: "12"
        }}
        elevation={0}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, maxWidth: "87.3%", marginRight: "24px" }}>
            <TopbarBreadcrumbs />
          </Box>
          <Box sx={{ display: "flex" }}>
            <IconButton
              aria-label="notification-selector"
              onClick={onNotificationClick}
              sx={{ color: isOnPage ? theme.palette.primary.main : theme.palette.grey[500], justifyContent: "center" }}
            >
              <Badge
                badgeContent={sidebarNewItems[COLLECTIONS.NOTIFICATIONS]}
                color="error"
                overlap="rectangular"
                max={9}
              >
                <NotificationsIcon sx={{ height: "20px", width: "20px" }} />
              </Badge>
            </IconButton>
            <LanguageSelector language={lng} />

            <Help language={lng} />
            <UserAvatarTopbar userData={user} />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
