import {
  approveExternalRecipientApi,
  createExternalRecipientApi,
  deleteExternalRecipientApi,
  ExternalRecipientDocumentDTO,
  ExternalRecipientDTO,
  ExternalRecipientPayload,
  getExternalRecipientApi,
  getExternalRecipientsApi,
  unApproveExternalRecipientApi,
  updateExternalRecipientApi
} from "../api/externalRecipientApi";
import { AxiosResponse } from "axios";

export const getExternalRecipients = async (): Promise<ExternalRecipientDTO[]> => {
  const externalRecipientsDTO = await getExternalRecipientsApi();
  return externalRecipientsDTO.externalRecipients;
};

export const deleteExternalRecipient = async (externalRecipientId: string): Promise<void> => {
  return await deleteExternalRecipientApi(externalRecipientId);
};

export const getExternalRecipient = async (externalRecipientId: string): Promise<ExternalRecipientDocumentDTO> => {
  return await getExternalRecipientApi(externalRecipientId);
};

export const createExternalRecipient = async (
  createPayload: ExternalRecipientPayload
): Promise<{ id: string; response: AxiosResponse }> => {
  return await createExternalRecipientApi(createPayload);
};

export const updateExternalRecipient = async (
  externalRecipientId: string,
  updatePayload: ExternalRecipientPayload
): Promise<void> => {
  return await updateExternalRecipientApi(externalRecipientId, updatePayload);
};

export const approveExternalRecipient = async (externalRecipientId: string): Promise<void> => {
  return await approveExternalRecipientApi(externalRecipientId);
};

export const unApproveExternalRecipient = async (externalRecipientId: string): Promise<void> => {
  return await unApproveExternalRecipientApi(externalRecipientId);
};
