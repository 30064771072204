import React, { useMemo } from "react";
import { Box, CircularProgress, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import DocumentNotFoundPage from "app/pages/shared/DocumentNotFound/DocumentNotFound";

export interface DocMetaViewProps {
  readonly docViewContent?: React.ReactNode;
  readonly metaViewContent?: React.ReactNode;
  readonly notFound?: boolean | undefined;
  readonly loading?: boolean | undefined;
  readonly collection?: COLLECTION_TYPES;
  readonly children?: React.ReactNode;
}

const DocMetaView = ({
  docViewContent,
  metaViewContent,
  notFound,
  loading,
  collection,
  children
}: DocMetaViewProps) => {
  const theme = useTheme();

  // document not found message
  const notFoundEl = useMemo(
    () => notFound && <DocumentNotFoundPage collection={collection || COLLECTIONS.PROCESSES} />,
    [collection, notFound]
  );

  // loading spinner
  const loadingEl = useMemo(
    () =>
      loading && (
        <Box textAlign="center" mt={8}>
          <CircularProgress />
        </Box>
      ),
    [loading]
  );

  // check if metaview content is empty
  const isMetaviewContentEmpty = useMemo(() => {
    return React.isValidElement(metaViewContent) && metaViewContent.type === React.Fragment;
  }, [metaViewContent]);

  // main content layout
  const contentEl = !notFound && !loading && (
    <Box
      display="flex"
      width="100%"
      height="92%" // controls lower gap between content and screen-bottom
      sx={{
        pl: 12,
        pt: 0,
        overflow: "auto"
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          gap: 0
        }}
      >
        {/* document content section */}
        <Box
          sx={{
            flex: metaViewContent ? 8 : 12,
            height: "100%",
            bgcolor: theme.palette.background.paper,
            overflow: "auto",
            mr: 3
          }}
        >
          {children || docViewContent}
        </Box>

        {/* metaview section - only rendered if metaViewContent exists */}
        {metaViewContent && (
          <>
            {/* vertical separator between document and metaview */}
            <Divider
              orientation="vertical"
              sx={{
                height: "100%",
                borderColor: theme.palette.divider
              }}
            />

            {/* metaview container */}
            <Box
              sx={{
                flex: 4,
                overflow: "auto",
                bgcolor: theme.palette.background.paper,
                display: isMetaviewContentEmpty ? "none" : "block"
              }}
              data-qa="doc_meta_view"
            >
              {metaViewContent}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      {notFoundEl}
      {loadingEl}
      {contentEl}
    </>
  );
};

export default DocMetaView;
