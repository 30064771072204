import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, InputAdornment, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OverviewSort, SortingItemProps } from "./OverviewSort";
import { OverviewFilter } from "./OverviewFilter";
import Search from "@mui/icons-material/Search";
import { OverviewSelectAction } from "./OverviewSelectActions";
import { ExportButton, OverviewExport, OverviewPdfXlsExport } from "./OverviewExport";
import OverviewMarkAllAsReadButton from "./OverviewMarkAllAsReadButton";
import OverviewAddButton, { OverviewAddButtonActionProps } from "./OverviewAddButton";
import CancelIcon from "@mui/icons-material/Cancel";
import OverviewIconButton from "./OverviewIconButton";
import Tabs from "components/TabsLayout/Tabs";
import { OverviewSelectAllCheckbox } from "./OverviewSelectAllCheckbox";
import { OverviewSelectAll } from "./OverviewSelectAll";
import { COLLECTION_TYPES } from "app/collections";
import { OverviewContextFilter, OverviewContextSort } from "app/contexts/overview-context";
import { FilterItemProps, OverviewItemsCount } from "../controllers/overviewBaseController";

export interface OverviewToolbarActionProps {
  readonly action: string;
  readonly title?: string;
  readonly tooltip?: string;
  readonly icon?: React.ReactNode;
  readonly dropdownActions?: {
    readonly title: string;
    readonly onHandle: (val?: any) => void;
  }[];
  readonly disabled?: boolean;
  readonly onHandle?: (val: any) => void;
}

export interface OverviewPageProps {
  readonly title: string;
  readonly route: string;
  readonly current: boolean;
  readonly unseenCount?: number;
}

export interface OverviewToolbarProps {
  readonly collection: COLLECTION_TYPES;
  readonly searchTerm: string;
  readonly sort: OverviewContextSort;
  readonly sortings: SortingItemProps[];
  readonly filter: OverviewContextFilter;
  readonly filters: FilterItemProps[];
  readonly actions?: OverviewToolbarActionProps[];
  readonly addActions?: OverviewAddButtonActionProps[];
  readonly selectionActions?: OverviewToolbarActionProps[];
  readonly pages?: OverviewPageProps[];
  readonly itemsCount?: OverviewItemsCount | null;
  readonly checkedItems: any[];
  readonly hideSearch?: boolean;
  readonly hideCount?: boolean;
  readonly toolbarHeader?: string;
  readonly toolbarAddControl?: React.ReactNode;
  readonly showSelectAll?: boolean;
  readonly numberSelectableItems?: number;
  readonly numberTotalItems?: number;
  readonly numberTotalSelectableItems: number;
  readonly isPaginatedApi?: boolean;

  readonly onAdd: (val: any) => void;
  readonly onSearch: (val: string) => void;
  readonly onSort: (val: SortingItemProps) => void;
  readonly onFilter: (val: object) => void;
  readonly onReset: (val: string) => void;
  readonly onPageChange?: (val: OverviewPageProps) => void;
  readonly onResetChecked: () => void;
  readonly onDuplicate: (val: string[] | undefined) => void;
  readonly onDelete: () => void;
  readonly onExport: (format: string) => void;
  readonly onExportAll: (format: string) => void;
  readonly onMarkAllAsRead: () => Promise<void>;
  readonly onInlineButtonClick?: () => void;
  readonly onSelectAllCheckbox: (checked: boolean) => void;
  readonly onSelectAllText: () => void;
  readonly onToolbarSelectAllHover: (checked: boolean) => void;
}

export const OverviewToolbar = ({
  actions,
  addActions,
  checkedItems,
  collection,
  filter,
  filters,
  hideCount,
  hideSearch,
  itemsCount,
  numberSelectableItems,
  numberTotalItems,
  numberTotalSelectableItems,
  pages,
  searchTerm,
  selectionActions,
  showSelectAll,
  sort,
  sortings,
  toolbarAddControl,
  toolbarHeader,

  onAdd,
  onDelete,
  onDuplicate,
  onExport,
  onExportAll,
  onFilter,
  onMarkAllAsRead,
  onPageChange,
  onReset,
  onResetChecked,
  onSearch,
  onSelectAllCheckbox,
  onSelectAllText,
  onSort,
  onToolbarSelectAllHover
}: OverviewToolbarProps) => {
  const { t } = useTranslation("overview");
  const { t: tTaskDetails } = useTranslation("task_details");
  const theme = useTheme();

  const [searchText, setSearchText] = useState(searchTerm || "");
  const onChangeSearchText = useCallback(event => {
    setSearchText(event.target.value);
  }, []);
  useEffect(
    function triggerSearch() {
      const timeoutID = setTimeout(() => {
        onSearch(searchText);
      }, 300);
      return () => clearTimeout(timeoutID);
    },
    [searchText, onSearch]
  );
  const clearSearch = useCallback(() => {
    setSearchText("");
    onSearch("");
  }, [onSearch]);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const searchButtonClicked = useCallback(() => {
    setSearchEnabled(lastValue => !lastValue);
  }, []);
  useEffect(() => {
    if (searchText) {
      setSearchEnabled(true);
    }
  }, [searchText]);
  const onSearchBlur = useCallback(() => {
    if (!searchText) {
      setSearchEnabled(false);
    }
  }, [searchText]);

  const actionsEl = actions
    ?.filter(a => a.action !== "add")
    .map(a => {
      if (a.action === "sort") {
        return (
          <React.Fragment key={a.action}>
            <OverviewSort sortings={sortings} sort={sort} onSort={onSort} onReset={onReset} />
          </React.Fragment>
        );
      } else if (a.action === "filter") {
        return (
          <React.Fragment key={a.action}>
            <OverviewFilter filters={filters} filter={filter} onFilter={onFilter} onReset={onReset} />
          </React.Fragment>
        );
      } else if (a.action === "export-all") {
        return (
          <React.Fragment key={a.action}>
            <OverviewExport onExport={onExportAll} />
          </React.Fragment>
        );
      } else if (a.action === "export-pdf-xlsx") {
        return (
          <React.Fragment key={a.action}>
            <OverviewPdfXlsExport onExport={onExportAll} />
          </React.Fragment>
        );
      } else if (a.action === "export-xlsx") {
        return (
          <React.Fragment key={a.action}>
            <ExportButton handleClick={onExportAll} />
          </React.Fragment>
        );
      } else if (a.action === "mark-all-as-read") {
        return (
          <React.Fragment key={a.action}>
            <OverviewMarkAllAsReadButton collection={collection} onMarkAllAsRead={onMarkAllAsRead} />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={a.action}>
            <OverviewIconButton onClick={a.onHandle} disabled={a.disabled} tooltip={a.tooltip}>
              {a.icon}
            </OverviewIconButton>
          </React.Fragment>
        );
      }
    });

  const addButtonEl = useMemo(() => {
    if (checkedItems.length) {
      return <></>;
    }
    if (addActions?.length) {
      return (
        <Box ml={2}>
          <OverviewAddButton onAdd={onAdd} actions={addActions} />
        </Box>
      );
    }
    if (toolbarAddControl) {
      return toolbarAddControl;
    }
  }, [addActions, checkedItems.length, onAdd, toolbarAddControl]);

  const selectedButtonEl = useMemo(
    () =>
      checkedItems.length > 0 ? (
        <Box ml={1} mt={-1}>
          <OverviewSelectAction
            collection={collection}
            onCancel={onResetChecked}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            onExport={onExport}
            checkedItems={checkedItems}
            actions={selectionActions}
          />
        </Box>
      ) : (
        <></>
      ),
    [checkedItems, collection, onDelete, onDuplicate, onExport, onResetChecked, selectionActions]
  );
  const allCountText = itemsCount && typeof itemsCount.allCount === "number" && itemsCount.allCount.toString();
  const currentCountText =
    itemsCount && typeof itemsCount?.currentCount === "number"
      ? t("items_number_partial", { number: itemsCount.currentCount })
      : (itemsCount &&
          typeof itemsCount?.allCount === "number" &&
          t("items_number_partial", { number: itemsCount.allCount })) ||
        "";
  const countText = `${currentCountText || ""} ${allCountText || ""}`;
  const itemsNumberEl = useMemo(
    () =>
      hideCount ? (
        <></>
      ) : (
        <Box px={0.5}>
          <Tooltip title={countText}>
            <Typography
              variant="caption"
              noWrap
              sx={{
                color: theme.palette.grey[500],
                fontSize: "12px"
              }}
            >
              {countText}
            </Typography>
          </Tooltip>
        </Box>
      ),
    [countText, hideCount, theme.palette.grey]
  );

  const checkAllEl = (
    <Box pl={0.4}>
      {showSelectAll ? (
        <OverviewSelectAllCheckbox
          collection={collection}
          onChange={onSelectAllCheckbox}
          checkedItemsNumber={checkedItems.length}
          onHover={onToolbarSelectAllHover}
          numberTotalSelectableItems={numberTotalSelectableItems}
        />
      ) : (
        <></>
      )}
    </Box>
  );

  const onTabChange = useCallback(
    (index: number) => {
      const clickedPage = pages?.[index];
      if (clickedPage) {
        onPageChange?.(clickedPage);
      }
    },
    [onPageChange, pages]
  );

  const tabs = pages?.map((p, index) => {
    return {
      label: p.title,
      id: index.toString(),
      selected: p.current,
      unseenCount: p.unseenCount
    };
  });
  const tabsEl = useMemo(() => <Tabs tabs={tabs || []} onTabChange={onTabChange} />, [onTabChange, tabs]);

  const searchEl = useMemo(
    () =>
      hideSearch ? (
        <></>
      ) : (
        <>
          <Box sx={{ padding: "2.5px 0" }}>
            <OverviewIconButton onClick={searchButtonClicked} tooltip={t("search")}>
              <Search />
            </OverviewIconButton>
          </Box>
          {searchEnabled && (
            <TextField
              fullWidth
              id="overview-search-input"
              variant="outlined"
              value={searchText}
              onBlur={onSearchBlur}
              onChange={onChangeSearchText}
              autoFocus={true}
              placeholder={t("search_placeholder")}
              sx={{
                width: "120px",
                margin: "0 8px 0 4px",
                background: theme.palette.grey[100],
                borderRadius: "4px",
                "& input": {
                  background: theme.palette.grey[100],
                  border: 0,
                  outline: 0,
                  padding: "12px 10px",
                  borderRadius: "4px"
                },
                "& fieldset": { border: "none" },
                "& .MuiInputAdornment-root": {
                  margin: 0,
                  padding: 0
                },
                "& .MuiOutlinedInput-adornedEnd": {
                  padding: 0
                },
                "& .MuiIconButton-root": {
                  "&:hover": { backgroundColor: "transparent" }
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={tTaskDetails("clear")}>
                      <IconButton
                        onClick={clearSearch}
                        sx={{
                          padding: "0 8px 0 0",
                          "& svg": {
                            width: "16px",
                            height: "16px"
                          }
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          )}
        </>
      ),
    [
      clearSearch,
      hideSearch,
      onChangeSearchText,
      onSearchBlur,
      searchButtonClicked,
      searchEnabled,
      searchText,
      t,
      tTaskDetails,
      theme
    ]
  );

  const inlineHeaderEl = useMemo(
    () =>
      toolbarHeader ? (
        <Typography sx={{ marginTop: "6px" }} variant="subtitle1">
          {toolbarHeader}
        </Typography>
      ) : (
        <></>
      ),
    [toolbarHeader]
  );

  const actionsContainerSx = {
    display: "flex",
    alignItems: "center",
    "& .MuiIconButton-root": {
      color: theme.palette.grey[600],
      width: "32px",
      height: "32px",
      margin: "0 2px"
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {checkAllEl}
        {inlineHeaderEl}
        {tabsEl}
        {itemsNumberEl}
        {searchEl}
        <Box sx={actionsContainerSx}>{actionsEl}</Box>
        {addButtonEl}
        {selectedButtonEl}
      </Box>
      <Box pb={pages?.length ? 0 : 0.75} />
      {showSelectAll && (
        <OverviewSelectAll
          key={"select-all"}
          numberCheckedItems={checkedItems.length}
          numberSelectableItems={numberSelectableItems || 0}
          numberTotalItems={numberTotalItems || 0}
          onClearAll={onResetChecked}
          numberTotalSelectableItems={numberTotalSelectableItems || 0}
          onSelectAll={onSelectAllText}
        />
      )}
    </Box>
  );
};
