import { useCallback } from "react";
import { useMetaView } from "app/contexts/meta-view-context";

// hook to manage metaview state with default fallback
export const useMetaViewWithDefault = (defaultInfo: { title: string; text: string }) => {
  const { setMeta, setInfo } = useMetaView();

  // set meta with default fallback on mouse leave
  const handleMouseLeave = useCallback(() => {
    setMeta(null);
    setInfo(defaultInfo);
  }, [setMeta, setInfo, defaultInfo]);

  return {
    setMeta,
    setInfo,
    handleMouseLeave
  };
};
