import { GetApp } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { exportAITechDocsExcel } from "app/export/createdExcelExportData";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import stopEvent from "tool/stopEvent";

const AITechDocOverviewBrickDownload = ({ item, hovered }: OverviewRowBrickProps) => {
  const { t } = useTranslation();
  const { auth } = useAuthentication();

  const onExportHandler = useCallback(
    event => {
      stopEvent(event);
      if (!auth?.tenantId) {
        return;
      }
      return exportAITechDocsExcel(auth.tenantId, t, [item.id]);
    },
    [auth?.tenantId, item.id, t]
  );

  if (!hovered) {
    return <></>;
  }

  return (
    <Tooltip title={t("overview:export_xls")}>
      <IconButton onClick={onExportHandler} size="small">
        <GetApp />
      </IconButton>
    </Tooltip>
  );
};

export default AITechDocOverviewBrickDownload;
