import { Chip } from "@mui/material";
import { useResources } from "app/contexts/resource-context";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import colors from "theme/palette/colors";

const sx = {
  background: colors.grey.grey200,
  color: colors.grey.grey500,
  textTransform: "uppercase",
  fontWeight: 500,
  fontSize: "10px",
  height: "24px"
};

const AssetOverviewBrickType = ({ item }: OverviewRowBrickProps) => {
  const resourceAssetTypeId = item.resourceAssetTypeId;
  const { translateById } = useResources();

  if (!resourceAssetTypeId) {
    return <></>;
  }

  return <Chip label={translateById(RESOURCE_TYPES.ASSET_TYPE, resourceAssetTypeId)} sx={sx} />;
};

export default AssetOverviewBrickType;
