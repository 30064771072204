import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TextEditor from "../utils/TextEditor";
import Question from "components/Question/Question";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { v4 } from "uuid";
import { useMetaView } from "app/contexts/meta-view-context";
import { PAFollowUpTask } from "../PAFollowUpTask";
import { useFinalAssessmentPageApi } from "app/api/process/finalAssessmentPageApi";
import { useParams } from "react-router-dom";
import AssessmentMeasureAccordion from "./AssessmentMeasureAccordion";
import PropTypes from "prop-types";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";

const useStyles = makeStyles(theme => ({
  accordionMultiFieldContent: {
    position: "relative",
    width: "92.7%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "-5px",
    marginTop: -10
  },
  measureContainerLeft: {
    width: "92%",
    marginTop: 16,
    marginRight: 20
  },
  measureContainerRight: {
    width: "92%"
  },
  consulation: {
    marginTop: 45
  },
  radioChecked: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  resubmissionDate: {
    width: "30%",
    marginBottom: "50px"
  },
  datePicker: {
    marginTop: 0
  },
  checked: {
    color: theme.palette.primary.main
  }
}));

DocViewForFinalAssessmentPage.propTypes = {
  readonly: PropTypes.bool
};

export default function DocViewForFinalAssessmentPage({ readonly }) {
  const classes = useStyles();
  const { t } = useTranslation("questionnaire_four_five_page");
  const { setInfo } = useMetaView();
  const { id } = useParams();
  const finalAssessment = useFinalAssessmentPageApi({ documentId: id });
  const [assessment, setAssessment] = useState("");

  useEnteringInfoCard({
    pathName: `/processes/${id}/dpia-assessment`,
    infoId: "infocard.dpia.page11"
  });

  useEffect(() => {
    setAssessment(finalAssessment.data?.processPage?.assessment || "");
  }, [finalAssessment.data?.processPage?.assessment]);

  const handleAssessmentUpdate = useCallback(async () => {
    await finalAssessment.actions.updateFinalAssessment(assessment);
  }, [finalAssessment.actions, assessment]);

  const consultationDPA = useMemo(() => {
    return {
      consultationDPARequired: finalAssessment.data?.processPage?.consultationDPARequired,
      consultationDPADescription: finalAssessment.data?.processPage?.consultationDPADescription
    };
  }, [finalAssessment.data?.processPage]);

  const [consultationDescription, setConsultationDescription] = useState("");
  useEffect(() => {
    setConsultationDescription(consultationDPA.consultationDPADescription || "");
  }, [consultationDPA.consultationDPADescription]);

  const handleConsultationDescriptionUpdate = useCallback(async () => {
    await finalAssessment.actions.updateConsultation({
      consultationDPADescription: consultationDescription
    });
  }, [finalAssessment.actions, consultationDescription]);

  if (finalAssessment.isLoading) {
    return <></>;
  }

  return (
    <>
      <QuestionnaireSubHeader text={t("title")} />
      <Question
        title={t("assessment")}
        questionId={"assessment"}
        translatable={assessment}
        questionName={t("title")}
        infoId={"infocard.dpia.page11.assessmentInfo"}
      >
        <TextEditor
          id={"assessment"}
          disabled={readonly}
          inputValue={assessment}
          onChange={setAssessment}
          onBlur={handleAssessmentUpdate}
        />
      </Question>
      <Question title={t("measure")} />
      {finalAssessment.data?.processPage?.assessmentMeasures?.map((assessmentMeasure, index) => {
        return (
          <Question
            key={assessmentMeasure.id}
            questionId={"assessmentMeasures " + assessmentMeasure.id}
            questionName={t("measure")}
            disabled={readonly}
            translatable={assessmentMeasure.measure}
          >
            <AssessmentMeasureAccordion assessmentMeasure={assessmentMeasure} readonly={readonly} />
          </Question>
        );
      })}
      <Button
        variant="contained"
        color="primary"
        disabled={readonly}
        onClick={() =>
          finalAssessment.actions.addAssesmentMeasure({
            measure: "",
            priority: "",
            id: v4()
          })
        }
      >
        {t("pa_data_processing:add_new")}
      </Button>
      <div className={classes.consulation} />
      <Question
        title={t("consulation")}
        questionId={"consulationDPARequired"}
        questionName={t("consulation")}
        translatable={consultationDPA.consultationDPADescription}
        disabled={readonly}
      >
        <RadioGroup
          row
          name="consulationDataProtection"
          defaultValue={consultationDPA.consultationDPARequired}
          key={consultationDPA.consultationDPARequired}
        >
          <FormControlLabel
            label={t("yes")}
            value={"yes"}
            disabled={readonly}
            control={
              <Radio
                onClick={async () => {
                  await finalAssessment.actions.updateConsultation({
                    consultationDPARequired: "yes"
                  });
                }}
                classes={{ root: classes.radioChecked, checked: classes.checked }}
                disabled={readonly}
              />
            }
          />
          <FormControlLabel
            label={t("no")}
            value={"no"}
            disabled={readonly}
            control={
              <Radio
                onClick={async () => {
                  await finalAssessment.actions.updateConsultation({
                    consultationDPARequired: "no"
                  });
                }}
                classes={{ root: classes.radioChecked, checked: classes.checked }}
                disabled={readonly}
              />
            }
          />
        </RadioGroup>
        {consultationDPA.consultationDPARequired === "yes" && (
          <Question title={t("process")}>
            <TextEditor
              inputValue={consultationDPA.consultationDPADescription}
              key={finalAssessment.isValidating}
              disabled={readonly}
              onChange={setConsultationDescription}
              onBlur={handleConsultationDescriptionUpdate}
            />
          </Question>
        )}
      </Question>
      <PAFollowUpTask disabled={readonly} />
    </>
  );
}
