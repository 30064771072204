import { AtRule } from "csstype";
import SuisseIntlBold from "./SuisseIntl/SuisseIntl-Bold.ttf";
import SuisseIntlBoldItalic from "./SuisseIntl/SuisseIntl-BoldItalic.ttf";
import SuisseIntlBook from "./SuisseIntl/SuisseIntl-Book.ttf";
import SuisseIntlBookItalic from "./SuisseIntl/SuisseIntl-BookItalic.ttf";
import SuisseIntlLight from "./SuisseIntl/SuisseIntl-Light.ttf";
import SuisseIntlLightItalic from "./SuisseIntl/SuisseIntl-LightItalic.ttf";
import SuisseIntlMedium from "./SuisseIntl/SuisseIntl-Medium.ttf";
import SuisseIntlMediumItalic from "./SuisseIntl/SuisseIntl-MediumItalic.ttf";
import SuisseIntlRegular from "./SuisseIntl/SuisseIntl-Regular.ttf";
import SuisseIntlRegularItalic from "./SuisseIntl/SuisseIntl-RegularItalic.ttf";
import SuisseIntlSemiBold from "./SuisseIntl/SuisseIntl-SemiBold.ttf";
import SuisseIntlSemiBoldItalic from "./SuisseIntl/SuisseIntl-SemiBoldItalic.ttf";
import SuisseIntlThin from "./SuisseIntl/SuisseIntl-Thin.ttf";
import SuisseIntlThinItalic from "./SuisseIntl/SuisseIntl-ThinItalic.ttf";
import SuisseIntlUltralight from "./SuisseIntl/SuisseIntl-Ultralight.ttf";
import SuisseIntlUltralightItalic from "./SuisseIntl/SuisseIntl-UltralightItalic.ttf";

import NotoSansKRBlack from "./NotoSansKR/NotoSansKR-Black.ttf";
import NotoSansKRBold from "./NotoSansKR/NotoSansKR-Bold.ttf";
import NotoSansKRExtraBold from "./NotoSansKR/NotoSansKR-ExtraBold.ttf";
import NotoSansKRExtraLight from "./NotoSansKR/NotoSansKR-ExtraLight.ttf";
import NotoSansKRLight from "./NotoSansKR/NotoSansKR-Light.ttf";
import NotoSansKRMedium from "./NotoSansKR/NotoSansKR-Medium.ttf";
import NotoSansKRRegular from "./NotoSansKR/NotoSansKR-Regular.ttf";
import NotoSansKRSemiBold from "./NotoSansKR/NotoSansKR-SemiBold.ttf";
import NotoSansKRThin from "./NotoSansKR/NotoSansKR-Thin.ttf";

// Defaults fonts properties
const unicodeRange =
  "U+0000-00FF, U+0100-017F, U+0180-024F, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF";

const fontDisplay: AtRule.FontFace["fontDisplay"] = "swap";

const suisseIntlFonts: AtRule.FontFace[] = [
  {
    fontFamily: "SuisseIntl",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 275,
    src: `local('SuisseIntl-Thin'), url(${SuisseIntlThin}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "italic",
    fontDisplay,
    fontWeight: 275,
    src: `local('SuisseIntl-ThinItalic'), url(${SuisseIntlThinItalic}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 300,
    src: `local('SuisseIntl-Light'), url(${SuisseIntlLight}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "italic",
    fontDisplay,
    fontWeight: 300,
    src: `local('SuisseIntl-LightItalic'), url(${SuisseIntlLightItalic}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 450,
    src: `local('SuisseIntl-Book'), url(${SuisseIntlBook}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "italic",
    fontDisplay,
    fontWeight: 450,
    src: `local('SuisseIntl-BookItalic'), url(${SuisseIntlBookItalic}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 400,
    src: `local('SuisseIntl-Regular'), url(${SuisseIntlRegular}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "italic",
    fontDisplay,
    fontWeight: 400,
    src: `local('SuisseIntl-RegularItalic'), url(${SuisseIntlRegularItalic}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 500,
    src: `local('SuisseIntl-Medium'), url(${SuisseIntlMedium}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "italic",
    fontDisplay,
    fontWeight: 500,
    src: `local('SuisseIntl-MediumItalic'), url(${SuisseIntlMediumItalic}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 600,
    src: `local('SuisseIntl-SemiBold'), url(${SuisseIntlSemiBold}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "italic",
    fontDisplay,
    fontWeight: 600,
    src: `local('SuisseIntl-SemiBoldItalic'), url(${SuisseIntlSemiBoldItalic}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 700,
    src: `local('SuisseIntl-Bold'), url(${SuisseIntlBold}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "italic",
    fontDisplay,
    fontWeight: 700,
    src: `local('SuisseIntl-BoldItalic'), url(${SuisseIntlBoldItalic}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 250,
    src: `local('SuisseIntl-Ultralight'), url(${SuisseIntlUltralight}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "SuisseIntl",
    fontStyle: "italic",
    fontDisplay,
    fontWeight: 250,
    src: `local('SuisseIntl-UltralightItalic'), url(${SuisseIntlUltralightItalic}) format('truetype')`,
    unicodeRange
  }
];

const notoSansKRFonts: AtRule.FontFace[] = [
  {
    fontFamily: "NotoSansKR",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 100,
    src: `local('NotoSansKR-Thin'), url(${NotoSansKRThin}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "NotoSansKR",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 200,
    src: `local('NotoSansKR-ExtraLight'), url(${NotoSansKRExtraLight}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "NotoSansKR",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 300,
    src: `local('NotoSansKR-Light'), url(${NotoSansKRLight}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "NotoSansKR",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 400,
    src: `local('NotoSansKR-Regular'), url(${NotoSansKRRegular}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "NotoSansKR",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 500,
    src: `local('NotoSansKR-Medium'), url(${NotoSansKRMedium}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "NotoSansKR",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 600,
    src: `local('NotoSansKR-SemiBold'), url(${NotoSansKRSemiBold}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "NotoSansKR",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 700,
    src: `local('NotoSansKR-Bold'), url(${NotoSansKRBold}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "NotoSansKR",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 800,
    src: `local('NotoSansKR-ExtraBold'), url(${NotoSansKRExtraBold}) format('truetype')`,
    unicodeRange
  },
  {
    fontFamily: "NotoSansKR",
    fontStyle: "normal",
    fontDisplay,
    fontWeight: 900,
    src: `local('NotoSansKR-Black'), url(${NotoSansKRBlack}) format('truetype')`,
    unicodeRange
  }
];

export { suisseIntlFonts, notoSansKRFonts };
