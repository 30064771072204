import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isAxiosErrorWithCode } from "../../api/axios/axiosErrorHandler";
import DocumentNotFoundPage from "../shared/DocumentNotFound/DocumentNotFound";
import { COLLECTIONS } from "../../collections";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import Box from "@material-ui/core/Box";
import { useUpdateQueues } from "../../../hook/useUpdateQueues";
import DocView from "../../../components/DocView/DocView";
import { useTranslation } from "react-i18next";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { TomStatus, TomStatusValues, UpdateTomModelDTO, useGetTOMApi, useUpdateTOMApi } from "../../api/tomApi";
import TomRisks from "./pages/TomRisksPage";
import TOMProcessesPage from "./pages/TOMProcessesPage";
import { TOMPageButtons, TOMPageStepper } from "./TOMPagination";
import { TOMDetails } from "./pages/TOMDetails";
import { useDocumentName } from "app/contexts/document-name-context";

export const TOMDocPage = () => {
  const { id, page } = useParams();
  const { isLoading, data, error } = useGetTOMApi({ documentId: id || "" });
  const docName = data?.name || "-";
  const [documentNotFound, setDocumentNotFound] = useState(false);

  useEffect(() => {
    if (isAxiosErrorWithCode(error, 404) || isAxiosErrorWithCode(error, 403)) {
      setDocumentNotFound(true);
    } else {
      setDocumentNotFound(false);
    }
  }, [error]);

  if (documentNotFound) {
    return <DocumentNotFoundPage collection={COLLECTIONS.TOM} />;
  }

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          collection={COLLECTIONS.TOM}
          docId={id}
          docName={docName}
          pageId={page || "description"}
          tabs={metaViewTabIds}
        />
      }
      loading={isLoading}
    >
      <TomDocView key={page} readonly={data?.permission !== "write"}>
        <Box>
          <Box mt={4}>
            {page === "description" && <TOMDetails readonly={data?.permission !== "write"} id={id || ""} />}
            {page === "risks" && <TomRisks readonly={data?.permission !== "write"} id={id || ""} />}
            {page === "processes" && <TOMProcessesPage readonly={data?.permission !== "write"} id={id || ""} />}
          </Box>
          <Box>
            <TOMPageButtons />
          </Box>
        </Box>
      </TomDocView>
    </DocMetaView>
  );
};

export const TomDocView = ({
  children,
  readonly
}: {
  readonly children: React.ReactNode;
  readonly readonly: boolean;
}) => {
  const { t } = useTranslation("tom");
  const { setDocumentName } = useDocumentName();
  const { id } = useParams();
  const { data } = useGetTOMApi({ documentId: id || "" });
  const docName = data?.name || "-";
  const { updateTOMApi } = useUpdateTOMApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<UpdateTomModelDTO, UpdateTomModelDTO>({
    triggerUpdate: updateTOMApi
  });

  const tomDocsStatusIconMap: Record<TomStatus, React.ReactNode> = useMemo(
    () => ({
      PLANNED: <LazySvgIcon name="In_Progress" />,
      IMPLEMENTED: <LazySvgIcon name="Completed" />
    }),
    []
  );

  useEffect(() => {
    setDocumentName(docName);
  }, [docName, setDocumentName]);

  const statuses = useMemo(() => {
    return TomStatusValues.map(status => ({
      id: status,
      label: t(`tom_description_tab:status_${status}`),
      icon: tomDocsStatusIconMap[status]
    }));
  }, [tomDocsStatusIconMap, t]);

  const onHeaderChange = useCallback(
    async (name: string) => {
      await queueUpdates({ name });
    },
    [queueUpdates]
  );
  const onStatusChange = useCallback(
    async (status: string) => {
      await queueUpdates({ status: status as TomStatus });
    },
    [queueUpdates]
  );

  return (
    <DocView
      header={docName}
      onHeaderChange={onHeaderChange}
      onStatusChange={onStatusChange}
      pagination={<TOMPageStepper />}
      statusId={data?.status || "PLANNED"}
      statuses={statuses}
      disabled={readonly}
    >
      {children}
    </DocView>
  );
};

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];
