import { Box, Grid, IconButton, Typography, Divider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";

interface ListPaginationProps {
  list: any[];
  page: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
  setDisplayableChunk: (chunk: any[]) => void;
  setIndexOffset?: (offset: number) => void;
  toolTipNext?: string;
  toolTipPrevious?: string;
  numberDescriptionText?: string;
}

// modern pagination component for lists
const ListPagination = ({
  itemsPerPage,
  list,
  page,
  setDisplayableChunk,
  setIndexOffset,
  toolTipNext,
  toolTipPrevious,
  onPageChange
}: ListPaginationProps) => {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(page);
  const [pageSize, setPageSize] = useState(itemsPerPage);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [chunks, setChunks] = useState<any[][]>([[]]);

  // calculate pagination numbers
  const totalItems = list.length;
  const totalPages = Math.ceil(totalItems / pageSize);
  const hasNextPage = currentPage < chunks.length - 1;
  const hasPrevPage = currentPage > 0;

  // create chunks when list or page size changes
  useEffect(() => {
    if (list.length === 0) {
      setDisplayableChunk([]);
      setChunks([[]]);
      setCurrentPage(0);
      return;
    }

    const newChunks = [];
    for (let i = 0; i < totalItems; i += pageSize) {
      newChunks.push(list.slice(i, i + pageSize));
    }
    setChunks(newChunks);

    if (currentPage > newChunks.length - 1) {
      setCurrentPage(newChunks.length - 1);
    }
  }, [currentPage, list, pageSize, setDisplayableChunk, totalItems]);

  // update displayable chunk when page changes
  useEffect(() => {
    if (chunks[currentPage]) {
      setDisplayableChunk(chunks[currentPage]);
    } else {
      setDisplayableChunk([]);
    }
  }, [currentPage, chunks, setDisplayableChunk]);

  // update index offset when page changes
  useEffect(() => {
    setIndexOffset?.(currentPage * pageSize);
  }, [currentPage, pageSize, setIndexOffset]);

  // sync with external page state
  useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [currentPage, page]);

  // sync with external items per page state
  useEffect(() => {
    if (itemsPerPage !== pageSize) {
      setPageSize(itemsPerPage);
    }
  }, [currentPage, itemsPerPage, pageSize]);

  // handlers
  const handleNextPage = useCallback(() => {
    if (hasNextPage) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      onPageChange(newPage);
    }
  }, [currentPage, hasNextPage, onPageChange]);

  const handlePrevPage = useCallback(() => {
    if (hasPrevPage) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      onPageChange(newPage);
    }
  }, [currentPage, hasPrevPage, onPageChange]);

  const handleFirstPage = useCallback(() => {
    if (hasPrevPage) {
      setCurrentPage(0);
      onPageChange(0);
    }
  }, [hasPrevPage, onPageChange]);

  const handleLastPage = useCallback(() => {
    if (hasNextPage) {
      const lastPage = totalPages - 1;
      setCurrentPage(lastPage);
      onPageChange(lastPage);
    }
  }, [hasNextPage, onPageChange, totalPages]);

  if (list.length === 0) return null;

  return (
    <Box>
      <Box
        my={2}
        sx={{
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: 0.38
        }}
      >
        <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ maxWidth: 300 }}>
          <Grid item>
            <IconButton
              onClick={handleFirstPage}
              disabled={!hasPrevPage}
              title="First page"
              size="small"
              sx={{
                color: theme.palette.text.primary,
                height: 32,
                width: 32
              }}
            >
              <FirstPageIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handlePrevPage}
              disabled={!hasPrevPage}
              title={toolTipPrevious}
              size="small"
              sx={{
                color: theme.palette.text.primary,
                height: 32,
                width: 32
              }}
            >
              <KeyboardArrowLeftIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                mx: 2,
                minWidth: 20,
                textAlign: "center",
                color: theme.palette.text.primary
              }}
            >
              {currentPage + 1}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleNextPage}
              disabled={!hasNextPage}
              title={toolTipNext}
              size="small"
              sx={{
                color: theme.palette.text.primary,
                height: 32,
                width: 32
              }}
            >
              <KeyboardArrowRightIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleLastPage}
              disabled={!hasNextPage}
              title="Last page"
              size="small"
              sx={{
                color: theme.palette.text.primary,
                height: 32,
                width: 32
              }}
            >
              <LastPageIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ListPagination;
