import React from "react";
import { SxProps } from "@mui/system/styleFunctionSx";
import { Box, Button } from "@mui/material";

export interface AIStepButtonProps {
  readonly onClick?: () => void;
  readonly startIcon?: React.ReactNode;
  readonly sx?: SxProps;
  readonly children?: React.ReactNode;
  readonly disabled?: boolean;
}

export const AIStepButton = ({ onClick, startIcon, sx, children, disabled }: AIStepButtonProps) => {
  return (
    <Button
      onClick={onClick}
      fullWidth={true}
      sx={sx}
      startIcon={startIcon}
      variant="contained"
      color="brick"
      disabled={disabled}
    >
      <Box display="flex" width="100%" justifyContent="start" alignItems="center">
        {children}
      </Box>
    </Button>
  );
};
