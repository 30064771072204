import TextBody2 from "../../../components/TextBody2/TextBody2";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { getRoleData } from "../../handlers/newRoleHandler";
import { Collapse } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";

export const AdminFurtherOrgUnitsWarning = (props: {
  readonly roleId: string;
  readonly existingOrgUnitIds: string[];
  readonly currentOrgUnitIds: string[];
  readonly sx?: SxProps;
}) => {
  const { t } = useTranslation("manage-user-page");

  const { data: role } = useSWR(props.roleId ? [props.roleId] : null, args => {
    const [roleId] = args;
    return getRoleData(roleId);
  });

  const hasUserWritePermissions = useMemo(() => {
    return role?.permissions?.some(it => it.startsWith("user_write"));
  }, [role]);

  const hasAdditionalOrgUnitIds = useMemo(() => {
    const existingOrgUnitIds = new Set(props.existingOrgUnitIds || []);
    return !!props.currentOrgUnitIds?.some(it => !existingOrgUnitIds.has(it));
  }, [props.currentOrgUnitIds, props.existingOrgUnitIds]);

  const shouldShowWarning = useMemo(() => {
    return hasUserWritePermissions && hasAdditionalOrgUnitIds;
  }, [hasUserWritePermissions, hasAdditionalOrgUnitIds]);

  return (
    <Collapse in={shouldShowWarning} sx={shouldShowWarning ? props.sx : undefined}>
      <CustomAlert fullWidth={true} severity={"warning"}>
        <TextBody2 text={t("admin_user_additional_org_unit_warning")} parseHTML={true} sx={resetBulletPointMarginSx} />
      </CustomAlert>
    </Collapse>
  );
};

const resetBulletPointMarginSx: SxProps = {
  "& * ul": {
    margin: 0
  }
};
