import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";

import { expandOrgUnitsApi, mergeOrgUnitsApi } from "../../api/orgUnitApi";
import ResponsibleOrgUnitQuestion from "../../../components/Question/types/orgUnits/ResponsibleOrgUnitQuestion";
import { OverviewItem } from "../../../components/Overview/controllers/overviewBaseController";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "../../contexts/overview-context";
import { COLLECTIONS } from "../../collections";
import { useUserDepartments } from "../../contexts/department-context";
import { useSnackbar } from "notistack";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import TextBody2 from "../../../components/TextBody2/TextBody2";

export interface OrgUnitMergeModalProps {
  readonly selectedOrgUnit: OverviewItem;
  readonly onClose: () => void;
  readonly onCancel: () => void;
  readonly mode: "merge" | "delete";
}
export const OrganizationMergeModal = ({ selectedOrgUnit, onClose, onCancel, mode }: OrgUnitMergeModalProps) => {
  const { t } = useTranslation("organisationManagement");
  const { enqueueSnackbar } = useSnackbar();

  const { reloadDepartments } = useUserDepartments();
  const [departmentsLoaded, setDepartmentsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!departmentsLoaded) {
      reloadDepartments();
      setDepartmentsLoaded(true);
    }
  }, [departmentsLoaded, reloadDepartments]);

  const [mergeIntoOrgUnitId, setMergeIntoOrgUnitId] = useState<string | null>(null);
  const [notAllowedToMergeIds, setNotAllowedToMergeIds] = useState<string[]>([]);

  const getExpandedOrgUnitIds = useCallback(async () => {
    if (selectedOrgUnit.id) {
      const expandedOrgUnitIds = (await expandOrgUnitsApi([selectedOrgUnit.id])).concat(selectedOrgUnit.id);
      setNotAllowedToMergeIds(expandedOrgUnitIds);
    }
  }, [selectedOrgUnit.id]);

  useEffect(() => {
    if (selectedOrgUnit.id) {
      getExpandedOrgUnitIds();
    }
  }, [getExpandedOrgUnitIds, selectedOrgUnit.id]);

  const dispatch = useOverviewDispatch();

  const reload = useCallback(() => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now(),
      checkedIds: []
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.ORGANIZATIONS, reload });
  }, [dispatch]);

  const mergeOrgUnits = useCallback(async () => {
    if (mergeIntoOrgUnitId && selectedOrgUnit.id) {
      await mergeOrgUnitsApi(
        {
          toMergeId: selectedOrgUnit.id,
          mergeIntoId: mergeIntoOrgUnitId
        },
        {
          wait: true
        }
      );
    }
    reload();
    onClose();
    enqueueSnackbar(`${t("snackbarMessage:actionCompleted")}`, { variant: "success" });
  }, [enqueueSnackbar, mergeIntoOrgUnitId, onClose, reload, selectedOrgUnit.id, t]);

  const [buttons, setButtons] = useState<ConfirmationModalButtonProps[]>([]);
  useEffect(
    () =>
      setButtons([
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCancel
        },
        {
          disabled: !mergeIntoOrgUnitId,
          confirmButton: true,
          title: mode === "merge" ? t("common:merge") : t("deleteAndReplace"),
          variant: "contained",
          color: mode === "merge" ? "primary" : "error",
          size: "medium",
          onClick: mergeOrgUnits
        }
      ]),
    [mergeIntoOrgUnitId, mergeOrgUnits, mode, onCancel, onClose, t]
  );

  const modalBody = (
    <Box mt={3} mb={4}>
      <Box mt={3}>
        <ResponsibleOrgUnitQuestion
          value={mergeIntoOrgUnitId}
          multiSelect={false}
          allowAdd={false}
          onChange={setMergeIntoOrgUnitId}
          blackListedIds={notAllowedToMergeIds}
          questionName={mode === "merge" ? t("mergeWith") : t("selectReplacement")}
          noDefaultValue={true}
        />
      </Box>
      <Box mt={2}>
        <CustomAlert title={t("beforeContinue")} severity={"warning"}>
          {mode === "merge" ? t("mergeWarningNew") : t("deleteWarningNew") + "\n" + t("deleteWarningNew2")}
        </CustomAlert>
      </Box>
    </Box>
  );

  return (
    <ConfirmationModal
      modalOpen={!!selectedOrgUnit.id}
      onClose={onCancel}
      modalTitle={mode === "merge" ? t("mergeTitle") : t("deleteTitleNew")}
      modalText={
        <Box>
          {mode === "merge" ? (
            <Box mb={2}>
              <TextBody2 text={t("mergeItemNew")} />
            </Box>
          ) : (
            <></>
          )}
          <Box gap={1} display="flex" alignItems="center" flexWrap="wrap">
            <Box flexBasis={150}>
              <Typography variant="body2" noWrap={true}>
                {mode === "merge" ? t("mergingOrgUnit") : t("deletingOrgUnit")}
              </Typography>
            </Box>
            <Box flex={1}>
              <Chip label={selectedOrgUnit.path} size="small" />
            </Box>
          </Box>
        </Box>
      }
      buttons={buttons}
      modalBody={modalBody}
    />
  );
};
