import { Box, Typography, Button, Divider, CircularProgress, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, Fragment, useState } from "react";
import { useUserProcesses } from "hook/useUserProcesses";
import { useDataSubjectRequest } from "app/contexts/dsr-context";
import ListPagination from "components/Pagination/ListPagination";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { useMetaViewWithDefault } from "hook/useMetaViewWithDefault";
import { useUserDepartments } from "app/contexts/department-context";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import DateDisplay from "components/DateDisplay";

interface DSRProcessingActivitiesProps {
  disabled?: boolean;
  defaultInfo: {
    title: string;
    text: string;
  };
}

// processing activities component for data subject requests
const DSRProcessingActivities = ({ disabled, defaultInfo }: DSRProcessingActivitiesProps) => {
  const { t } = useTranslation(["data_subject_requests_data_page", "processes_overview"]);
  const { dataSubjectRequest } = useDataSubjectRequest();
  const { setMeta, handleMouseLeave } = useMetaViewWithDefault(defaultInfo);
  const { getDepartmentName } = useUserDepartments();
  const [pageIndex, setPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [displayableProcesses, setDisplayableProcesses] = useState<
    Array<{
      id: string;
      name: string;
      status: string;
      version: number;
      createdAt: string;
      createdBy: string;
      orgUnitIds: string[];
      dueAt: string | null;
    }>
  >([]);

  // fetch processes based on person groups
  const {
    processes,
    processesLoaded,
    isValidating: paReloading
  } = useUserProcesses({
    personGroupIDs: dataSubjectRequest?.inputData?.personGroups || []
  });

  const sx = useMemo(
    () => ({
      processList: {
        mt: 2
      },
      processItem: {
        display: "flex",
        alignItems: "center",
        height: 40,
        mx: 2,
        width: "100%"
      },
      processIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        pr: 3
      },
      noProcesses: {
        mt: 4,
        marginLeft: "5%"
      }
    }),
    []
  );

  // prepare all processes for pagination with metadata
  const allProcesses = useMemo(() => {
    if (!processes) return [];
    return processes.map(process => ({
      id: process.id,
      name: process.title,
      status: process.status,
      version: process.version,
      createdAt: process.createdAt,
      createdBy: process.createdBy,
      orgUnitIds: process.orgUnitIds,
      dueAt: process.dueAt
    }));
  }, [processes]);

  // handle process click to open in new tab
  const handleProcessClick = useCallback((processId: string) => {
    window.open(`/processes/${processId}/recipients`);
  }, []);

  // handle mouse enter to show metadata
  const handleMouseEnter = useCallback(
    (process: (typeof allProcesses)[0]) => {
      const meta = {
        [t("processes_overview:version")]: process.version,
        [t("processes_overview:created")]: process.createdAt ? (
          <DateDisplay timestamp={new Date(process.createdAt)} />
        ) : null,
        [t("processes_overview:createdBy")]: process.createdBy ? (
          <AutomaticUserDataDisplay uid={process.createdBy} />
        ) : null,
        [t("processes_overview:department")]: process.orgUnitIds?.length
          ? getDepartmentName(process.orgUnitIds[0])
          : null,
        [t("processes_overview:dueDate")]: process.dueAt ? <DateDisplay timestamp={new Date(process.dueAt)} /> : "-",
        [t("processes_overview:status")]: t(`processes_overview:status_${process.status}`, process.status)
      };
      setMeta(meta);
    },
    [t, getDepartmentName, setMeta]
  );

  // render status icon based on process status
  const getStatusIcon = useCallback(
    (status: string) => {
      switch (status) {
        case "edit":
          return (
            <Tooltip title={t("processes_tab:status_edit")}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <LazySvgIcon name="In_Progress" />
              </Box>
            </Tooltip>
          );
        case "review":
          return (
            <Tooltip title={t("processes_tab:status_review")}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <LazySvgIcon name="In_Review" />
              </Box>
            </Tooltip>
          );
        case "approved":
          return (
            <Tooltip title={t("processes_tab:status_approved")}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <LazySvgIcon name="Completed" />
              </Box>
            </Tooltip>
          );
        default:
          return (
            <Tooltip title={t("processes_tab:status_edit")}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <LazySvgIcon name="In_Progress" />
              </Box>
            </Tooltip>
          );
      }
    },
    [t]
  );

  // memoize process click handler for each process
  const getProcessClickHandler = useCallback(
    (processId: string) => () => handleProcessClick(processId),
    [handleProcessClick]
  );

  // memoize process mouse enter handler for each process
  const getProcessMouseEnterHandler = useCallback(
    (process: (typeof allProcesses)[0]) => () => handleMouseEnter(process),
    [handleMouseEnter]
  );

  return (
    <Box>
      <Box pt={2} display="flex" alignItems="center">
        <Typography variant="subtitle1">{t("processing_activities")}</Typography>
        {paReloading && (
          <Box mb={1} ml={2}>
            <CircularProgress size={16} />
          </Box>
        )}
      </Box>
      <Box sx={sx.processList}>
        {displayableProcesses.map((process, index) => (
          <Fragment key={process.id}>
            {index > 0 && <Divider sx={{ opacity: 0.3 }} />}
            <Button
              variant="text"
              onClick={getProcessClickHandler(process.id)}
              onMouseEnter={getProcessMouseEnterHandler(process)}
              onMouseLeave={handleMouseLeave}
              fullWidth
              disabled={disabled}
            >
              <Box sx={sx.processItem}>
                <Box sx={sx.processIcon}>{getStatusIcon(process.status)}</Box>
                <Box sx={{ display: "flex", justifyItems: "center" }}>
                  <Typography variant="body1" mt={"2px"}>
                    {process.name}
                  </Typography>
                </Box>
              </Box>
            </Button>
          </Fragment>
        ))}
        {displayableProcesses.length === 0 && processesLoaded && <Box sx={sx.noProcesses}>{t("no_processes")}</Box>}

        <Divider sx={{ opacity: 0.3 }} />
        <ListPagination
          list={allProcesses}
          page={pageIndex}
          itemsPerPage={itemsPerPage}
          onPageChange={setPageIndex}
          onItemsPerPageChange={setItemsPerPage}
          setDisplayableChunk={setDisplayableProcesses}
          toolTipNext={t("processes_tab:next")}
          toolTipPrevious={t("processes_tab:previous")}
          numberDescriptionText={t("processes_tab:outOf")}
        />
      </Box>
    </Box>
  );
};

export default DSRProcessingActivities;
