import { IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import SettingsIcon from "@material-ui/icons/Settings";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";
import { t } from "i18next";

const OverviewBrickSettings = ({ item, disabled, hovered, onClick }: OverviewRowBrickProps) => {
  const onClickCallback = useCallback(
    event => {
      stopEvent(event);
      onClick?.(item.id, item);
    },
    [item, onClick]
  );

  if (!hovered) {
    return <></>;
  }
  return (
    <Tooltip title={t("common:settings")}>
      <IconButton disabled={disabled} onClick={onClickCallback} size="small">
        <SettingsIcon />
      </IconButton>
    </Tooltip>
  );
};

export default OverviewBrickSettings;
