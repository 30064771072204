import React, { useCallback, useMemo, useState } from "react";
import TextBody2 from "../TextBody2/TextBody2";
import EditInfoCardModal from "./EditInfoCardModal";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";
import { useMetaView } from "app/contexts/meta-view-context";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { Box, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import SubjectIcon from "@mui/icons-material/Subject";
import EditIcon from "@mui/icons-material/Edit";
import colors from "theme/palette/colors";

const sx = {
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    minHeight: "0px",
    "& p": {
      marginTop: 0
    }
  },
  header: {
    color: colors.blue.blue600,
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  headerText: {
    color: `${colors.blue.blue600} !important`,
    paddingY: 1,
    flex: 1
  },
  headerEditIcon: {
    color: colors.blue.blue600,
    paddingY: 1
  },
  contentWrapper: {
    flex: 1,
    overflow: "auto",
    height: "100%"
  },
  editor: {
    marginTop: "-24px",
    "& .rdw-editor-main": {
      border: "none !important",
      padding: 0,
      margin: 0,
      color: colors.black,
      maxHeight: "100% !important"
    },
    "& .rdw-editor-wrapper": {
      border: "none !important"
    },
    "& .rdw-link-decorator-icon": {
      display: "none"
    },
    "& a": {
      cursor: "pointer"
    }
  }
};

export enum InfoCardType {
  INFO = "INFO",
  TIPP = "TIPP",
  DETAILS = "DETAILS"
}

const infoCardTypeIconsMap = {
  [InfoCardType.INFO]: <InfoOutlinedIcon />,
  [InfoCardType.TIPP]: <LightbulbOutlinedIcon />,
  [InfoCardType.DETAILS]: <SubjectIcon />
};

interface InfoCardProps {
  readonly editable?: boolean;
  readonly infoCardType?: InfoCardType;
  readonly infoCardHeader?: React.ReactNode;
  readonly infoCardContent?: React.ReactNode | string;
}

const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const InfoCard = ({ editable, infoCardType, infoCardHeader, infoCardContent }: InfoCardProps) => {
  const { infoId, setInfoId } = useMetaView();
  const { auth } = useAuthentication();

  const customInfoCardEnable =
    useIsFeaturePresent(FEATURES.CUSTOM_INFO_CARD) && auth?.permissions.includes("infovault_write_all");

  const [infoCardModalOpen, setInfoCardModelopen] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  const onHoverOn = useCallback(() => {
    setHovered(true);
  }, []);
  const onHoverOff = useCallback(() => {
    setHovered(false);
  }, []);

  const onOpenInfoCardModal = useCallback(() => {
    setInfoCardModelopen(true);
  }, []);
  const onCancelInfoCardModal = useCallback(() => {
    setInfoCardModelopen(false);
  }, []);
  const onSaveInfoCard = useCallback(() => {
    if (infoId) {
      setInfoId(null);
      setInfoId(infoId);
    }
    setInfoCardModelopen(false);
  }, [infoId, setInfoId]);

  const onRestoreInfocCard = useCallback(() => {
    if (infoId) {
      setInfoId(null);
      setInfoId(infoId);
    }
  }, [infoId, setInfoId]);

  const headerEl = useMemo(() => {
    return (
      <Box sx={sx.header}>
        {infoCardTypeIconsMap[infoCardType || InfoCardType.INFO]}
        <Typography sx={sx.headerText} variant="subtitle2">
          {infoCardHeader}
        </Typography>
        {customInfoCardEnable && editable && infoId && hovered && (
          <IconButton sx={sx.headerEditIcon} size="small" onClick={onOpenInfoCardModal}>
            <EditIcon />
          </IconButton>
        )}
      </Box>
    );
  }, [customInfoCardEnable, editable, hovered, infoCardHeader, infoCardType, infoId, onOpenInfoCardModal]);

  const contentEl = useMemo(() => {
    if (typeof infoCardContent === "string") {
      if (isJsonString(infoCardContent)) {
        return (
          <Box sx={sx.editor}>
            <TextEditor
              testId={"info-card-description"}
              inputValue={infoCardContent}
              titleComponent={undefined}
              focused={undefined}
              disabled={true}
            />
          </Box>
        );
      } else {
        return <TextBody2 text={infoCardContent} parseHTML={true} />;
      }
    } else {
      return infoCardContent;
    }
  }, [infoCardContent]);

  return (
    <>
      <Box
        sx={{
          ...sx.root,
          ...(infoCardType === InfoCardType.INFO ? { flex: 1 } : {}),
          ...(infoCardType === InfoCardType.TIPP ? { minHeight: "100px", maxHeight: "50%" } : {})
        }}
        onMouseLeave={onHoverOff}
        onMouseOver={onHoverOn}
      >
        {headerEl}
        <Box sx={sx.contentWrapper}>{contentEl}</Box>
      </Box>
      {infoId && editable && (
        <EditInfoCardModal
          open={infoCardModalOpen}
          infoCardId={infoId}
          onCancel={onCancelInfoCardModal}
          onSave={onSaveInfoCard}
          onRestore={onRestoreInfocCard}
        />
      )}
    </>
  );
};

export default InfoCard;
