import { Box, Typography, useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { ALLOWED_FILE_EXTENSIONS } from "app/pages/shared/Attachments/fileAttachmentUtils";

interface AttachmentsOverviewProps {
  disabled?: boolean;
  onFileSelect?: (files: File[]) => void;
}

// modern attachments overview component with drag and drop
const AttachmentsOverview = ({ disabled, onFileSelect }: AttachmentsOverviewProps) => {
  const theme = useTheme();
  const { t } = useTranslation("file_upload");
  const [isDragging, setIsDragging] = useState(false);

  // handle drag events
  const handleDragEnter = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!disabled) setIsDragging(true);
    },
    [disabled]
  );

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // handle file selection
  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      if (disabled) return;

      const files = Array.from(e.dataTransfer.files);
      onFileSelect?.(files);
    },
    [disabled, onFileSelect]
  );

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled || !e.target.files) return;

      const files = Array.from(e.target.files);
      onFileSelect?.(files);

      // reset input
      e.target.value = "";
    },
    [disabled, onFileSelect]
  );

  return (
    <Box
      sx={{
        width: "100%",
        border: `2px dashed ${isDragging ? theme.palette.primary.main : theme.palette.grey[200]}`,
        borderRadius: 1,
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: disabled ? "default" : "pointer",
        transition: "border-color 0.2s ease",
        "&:hover": {
          borderColor: !disabled ? theme.palette.primary.main : theme.palette.grey[300]
        }
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      component="label"
    >
      <input
        type="file"
        hidden
        multiple
        onChange={handleFileSelect}
        accept={Object.values(ALLOWED_FILE_EXTENSIONS).join(",")}
        disabled={disabled}
      />
      <UploadFileIcon
        sx={{
          color: theme.palette.primary.main,
          fontSize: 40,
          mb: theme.spacing(2),
          height: 24,
          width: 24
        }}
      />
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
          <Box
            component="span"
            sx={{
              color: theme.palette.primary.main,
              textDecoration: "underline",
              cursor: disabled ? "default" : "pointer"
            }}
          >
            {t("click_to_upload")}
          </Box>
          <Box
            component="span"
            sx={{
              fontWeight: 400
            }}
          >{` ${t("or_drag_and_drop")}`}</Box>
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary
          }}
        >
          {t("allowed_file_info")}
        </Typography>
      </Box>
    </Box>
  );
};

export default AttachmentsOverview;
