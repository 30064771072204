import React from "react";
import PDFIcon from "./../../../../assets/images/icons/pdf.svg";
import DOCXIcon from "./../../../../assets/images/icons/docx.svg";
import RTFIcon from "./../../../../assets/images/icons/rtf.svg";
import JPGIcon from "./../../../../assets/images/icons/jpg.svg";
import PNGIcon from "./../../../../assets/images/icons/png.svg";
import DOCIcon from "./../../../../assets/images/icons/doc.svg";
import ODTIcon from "./../../../../assets/images/icons/odt.svg";
import XLSIcon from "./../../../../assets/images/icons/xls.svg";
import PPTIcon from "./../../../../assets/images/icons/ppt.svg";
import ZIPIcon from "./../../../../assets/images/icons/zip.svg";
import { Box } from "@mui/material";

export function normaliseFileNameExtension(fileName: string) {
  const fileNameArray = fileName.split(".");
  let fileNameWithoutExtension = "";
  let extension = "";
  // necessary because file can have "." in the name as well
  fileNameArray.forEach((nameString, index) => {
    if (index === 0) {
      fileNameWithoutExtension = nameString;
    } else if (index < fileNameArray.length - 1) {
      fileNameWithoutExtension = fileNameWithoutExtension + "." + nameString;
    } else {
      extension = nameString;
    }
  });
  const extensionLowerCase = extension.toLowerCase();
  return fileNameWithoutExtension + "." + extensionLowerCase;
}

export const ALLOWED_FILE_EXTENSIONS = {
  PDF: ".pdf",
  DOC: ".doc",
  DOCX: ".docx",
  TXT: ".txt",
  XLS: ".xls",
  XLSX: ".xlsx",
  CSV: ".csv",
  JPG: ".jpg",
  JPEG: ".jpeg",
  PNG: ".png",
  TIF: ".tif",
  PPT: ".ppt",
  PPTX: ".pptx",
  ODT: ".odt",
  RTF: ".rtf",
  KEY: ".key",
  ODP: ".odp",
  TIFF: ".tiff",
  ZIP: ".zip",
  RAR: ".rar",
  MSG: ".msg",
  HTML: ".html",
  SEVEN_Z: ".7z",
  DOCM: ".docm",
  DOTX: ".dotx",
  GIF: ".gif",
  HTM: ".htm",
  ICS: ".ics",
  VSD: ".vsd",
  XLSM: ".xlsm",
  XLTX: ".xltx",
  XLTM: ".xltm",
  POTX: ".potx",
  WAV: ".wav"
};

const sx = {
  width: "24px",
  height: "24px",
  svg: {
    width: "24px",
    height: "24px"
  }
};

const extensionMap: Record<string, React.ReactNode> = {
  [ALLOWED_FILE_EXTENSIONS.PDF]: <PDFIcon />,
  [ALLOWED_FILE_EXTENSIONS.DOCX]: <DOCXIcon />,
  [ALLOWED_FILE_EXTENSIONS.RTF]: <RTFIcon />,
  [ALLOWED_FILE_EXTENSIONS.JPG]: <JPGIcon />,
  [ALLOWED_FILE_EXTENSIONS.PNG]: <PNGIcon />,
  [ALLOWED_FILE_EXTENSIONS.ODT]: <ODTIcon />,
  [ALLOWED_FILE_EXTENSIONS.XLS]: <XLSIcon />,
  [ALLOWED_FILE_EXTENSIONS.XLSX]: <XLSIcon />,
  [ALLOWED_FILE_EXTENSIONS.PPT]: <PPTIcon />,
  [ALLOWED_FILE_EXTENSIONS.PPTX]: <PPTIcon />,
  [ALLOWED_FILE_EXTENSIONS.ZIP]: <ZIPIcon />
};

export const getFileIcon = (filename: string) => {
  const extension = `.${filename.split(".").pop()}`;
  return <Box sx={sx}>{extensionMap[extension] || <DOCIcon />}</Box>;
};
