import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import {
  createDocFollowUpTask,
  deleteDocFollowUpTasks,
  getDocFollowUpTasks,
  updateRecurringTask
} from "app/handlers/tasksHandler";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecurringTask } from "../../api/recurringTaskApi";
import ChipsStack, { ChipVariant, type ChipProps } from "../../../components/ChipsStack/ChipsStack";
import { TaskRepetitionModal } from "./details/components/recurring/TaskRepetitionModal";
import { useUserAndTenantData } from "../../handlers/userAndTenant/user-tenant-context";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";

export interface FollowUpTaskProps {
  docId: string;
  creatorUID: string;
  assigneeUID: string;
  taskName: string;
  collection: string;
  taskDescription: string;
  disableCheckbox?: boolean;
  tooltipWhenDisabled?: string;
}

export const FollowUpTask = ({
  docId,
  creatorUID,
  assigneeUID,
  taskName,
  taskDescription,
  collection,
  disableCheckbox,
  tooltipWhenDisabled
}: FollowUpTaskProps) => {
  const [repetitionModalOpen, setRepetitionModalOpen] = useState(false);
  const [followUpSelected, setFollowUpSelected] = useState(false);
  const [followUpTask, setFollowUpTask] = useState<RecurringTask | null>(null);
  const { t } = useTranslation("followUpTask");

  const getFollowUpTask = useCallback(async () => {
    const followUpTasks = await getDocFollowUpTasks(docId);
    if (followUpTasks.length) {
      setFollowUpTask(followUpTasks[0]);
      setFollowUpSelected(true);
    }
  }, [docId]);

  useEffect(() => {
    getFollowUpTask();
  }, [getFollowUpTask]);

  const onChangeRepetition = useCallback(
    async updateData => {
      await updateRecurringTask(followUpTask?.id || "", updateData);
      await getFollowUpTask();
    },
    [followUpTask?.id, getFollowUpTask]
  );

  const onFollowUpChange = useCallback(
    async (event, checked) => {
      if (checked) {
        const newRecurringTaskData = {
          title: taskName,
          description: taskDescription,
          assigneeUID: assigneeUID,
          ownerUID: creatorUID,
          documentId: docId,
          collection: collection
        };
        await createDocFollowUpTask(docId, newRecurringTaskData);
        await getFollowUpTask();
      } else {
        await deleteDocFollowUpTasks(docId);
        setFollowUpSelected(false);
      }
    },
    [taskName, taskDescription, assigneeUID, creatorUID, docId, collection, getFollowUpTask]
  );

  const chipsEl: ChipProps[] = useMemo(
    () => [
      {
        disabled: disableCheckbox,
        label: t("task_details:" + followUpTask?.recurrenceType.toLowerCase()),
        variant: ChipVariant.ROUNDED_FILLED,
        onClick: () => setRepetitionModalOpen(true),
        styles: {}
      }
    ],
    [disableCheckbox, followUpTask?.recurrenceType, t]
  );

  const { tenantData } = useUserAndTenantData();
  const recurringTaskFeatureIsActive = tenantData?.features?.includes("recurringTasks");

  return (
    <>
      {recurringTaskFeatureIsActive && (
        <Box mt={3}>
          <QuestionnaireSubHeader text={t("headline")} />
          <Box mt={2} display={"flex"} alignItems="center" height={40}>
            <Box>
              <Tooltip title={disableCheckbox ? tooltipWhenDisabled || "" : ""}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={followUpSelected}
                          value={followUpSelected}
                          onChange={onFollowUpChange}
                          color="primary"
                          name={""}
                          disabled={disableCheckbox}
                        />
                      }
                      label={t("checkboxLabel")}
                    />
                  </FormGroup>
                </FormControl>
              </Tooltip>
            </Box>
            <Box hidden={!followUpSelected}>
              <ChipsStack chips={chipsEl} />
            </Box>
          </Box>
          {followUpTask && (
            <Box>
              <TaskRepetitionModal
                onChangeRepetition={onChangeRepetition}
                active={repetitionModalOpen}
                setActive={setRepetitionModalOpen}
                endTime={followUpTask?.endTime}
                startTime={followUpTask?.startTime}
                dayOfWeek={followUpTask?.dayOfWeek}
                type={followUpTask?.recurrenceType}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
