import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Editor, EditorProps, EditorState } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import QuestionTitle from "components/QuestionTitle/QuestionTitle";
import PropTypes from "prop-types";
import {
  convertContentStateFromRawObject,
  parseContentState
} from "app/pages/questionnaires/utils/textEditorConverter";
import { convertToRaw, EditorState as DraftJSEditorState } from "draft-js";
import { handleDraftEditorPastedText, onDraftEditorCopy, onDraftEditorCut } from "draftjs-conductor";
import Typography from "@mui/material/Typography";
import { languageToggles } from "app/handlers/languageHandler";

const toolbarStyles = {
  borderStyle: "none",
  "& .rdwLinkWrapper": {
    position: "relative"
  },
  "& .rdwLinkModal": {
    marginLeft: "60%",
    top: "45px",
    zIndex: 1000
  },
  "& .rdwDropdownWrapper .rdwDropdownSelectedtext": {
    display: "none !important"
  }
};

const toolbarStickyStyles = {
  backgroundColor: "background.paper",
  position: "sticky",
  top: 0,
  zIndex: 900
};

const editorStyles = {
  padding: "0px 14px 18.5px",
  cursor: "auto",
  overflow: "auto",
  maxHeight: "300px"
};

const wrapperStyles = {
  borderRadius: "5px",
  borderWidth: "1.5px",
  overflow: "visible"
};

export interface TextEditorProps {
  readonly additionalClasses: Record<string, string>;
  readonly className: string;
  readonly disabled: boolean;
  readonly error?: boolean;
  readonly focused: boolean;
  readonly helperText?: string;
  readonly inputValue: string | null;
  readonly isEditing: boolean;
  readonly placeholder?: string;
  readonly testId: string;
  readonly title: string | null;
  readonly titleComponent?: React.ReactNode;
  readonly toolbarHidden: EditorProps["toolbarHidden"];
  readonly toolbarOptions?: string[];
  readonly onChange: (text: string) => void;
  readonly onBlur: EditorProps["onBlur"];
  readonly onFocus: EditorProps["onFocus"];
}

const TextEditor = ({
  disabled,
  error,
  helperText,
  inputValue,
  isEditing,
  placeholder,
  testId,
  title,
  titleComponent,
  toolbarHidden,
  toolbarOptions,
  onFocus,
  onBlur,
  onChange
}: TextEditorProps) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<{ readonly locale?: string }>({});
  const [editorState, setEditorState] = useState<EditorState>();
  const [lastChangedInputValue, setLastChangedInputValue] = useState<string | null>(null);
  const theme = useTheme();

  const updateEditorState = useCallback(value => {
    setEditorState(value);
    const editorRawContent = JSON.stringify(convertToRaw(value.getCurrentContent()));
    setLastChangedInputValue(editorRawContent);
    return editorRawContent;
  }, []);

  const onEditorStateChange = useCallback(
    value => {
      const updatedValue = updateEditorState(value);
      onChange(updatedValue);
    },
    [onChange, updateEditorState]
  );

  useEffect(() => {
    const updateEditorWithInputValue = () => {
      if (inputValue !== lastChangedInputValue) {
        const newRawContent = parseContentState(inputValue || "");
        const newEditorState = DraftJSEditorState.createWithContent(convertContentStateFromRawObject(newRawContent));
        setEditorState(DraftJSEditorState.moveSelectionToEnd(newEditorState));
      }
    };
    updateEditorWithInputValue();
  }, [inputValue, lastChangedInputValue]);

  useEffect(() => {
    const supportedLanguages = Object.keys(languageToggles);
    const language = i18n.language.split("-")[0];
    setLanguage({ locale: supportedLanguages.includes(language) ? language : "en" });
  }, [i18n.language]);

  const toolbar = useMemo(
    () => ({
      options: toolbarOptions || ["inline", "blockType", "fontSize", "colorPicker", "emoji", "list", "link"],
      inline: {
        options: ["bold", "underline", "italic", "strikethrough"],
        bold: { className: "icon" },
        italic: { className: "icon" },
        underline: { className: "icon" },
        strikethrough: { className: "icon" }
      },
      blockType: {
        inDropdown: true,
        options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
        className: "blockType"
      },
      colorPicker: { className: "icon" },
      emoji: { className: "icon" },
      list: {
        unordered: { className: "icon" },
        ordered: { className: "icon" },
        indent: { className: "icon" },
        outdent: { className: "icon" }
      },
      link: {
        link: { className: "icon" },
        unlink: { className: "icon" }
      }
    }),
    [toolbarOptions]
  );

  const handlePastedText = useCallback(
    (text, html, editorState) => {
      const newEditorState = handleDraftEditorPastedText(html, editorState);
      if (newEditorState) {
        const updatedValue = updateEditorState(newEditorState);
        onChange(updatedValue);
        return true;
      }
      return false;
    },
    [onChange, updateEditorState]
  );

  const sx = {
    mb: "1px",
    mt: "1px",
    width: "100%",
    "& .rdw-editor-toolbar": isEditing ? { margin: 0, padding: 0 } : { display: "none" },
    "& .rdw-block-wrapper, .rdw-fontsize-wrapper, .rdw-colorpicker-wrapper, .rdw-emoji-wrapper, .rdw-option-disabled": {
      display: isEditing ? "block" : "none"
    },
    "& .rdw-option-wrapper": {
      border: "none",
      padding: 0,
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
        borderRadius: "6px",
        boxShadow: "none"
      }
    },
    "& .rdw-block-wrapper": { display: "none" },
    "& .rdw-fontsize-wrapper": { display: "none" },
    "& .rdw-colorpicker-wrapper": { display: "none" },
    "& .rdw-emoji-wrapper": { display: "none" },
    "& .rdw-list-wrapper": { display: "none" },
    "& .rdw-link-wrapper > div:nth-of-type(2)": { display: "none" }
  };

  return (
    <>
      {titleComponent}
      {!titleComponent && title && <QuestionTitle disabled={disabled}>{title}</QuestionTitle>}
      <Box mt={1} data-testid={testId} sx={{ position: "relative", ...sx }}>
        <Editor
          editorState={editorState}
          handlePastedText={handlePastedText}
          localization={language}
          placeholder={placeholder}
          readOnly={disabled}
          toolbar={toolbar}
          toolbarHidden={toolbarHidden || disabled}
          wrapperClassName={error ? "error" : ""}
          onBlur={onBlur}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onCopy={onDraftEditorCopy}
          onCut={onDraftEditorCut}
          onEditorStateChange={onEditorStateChange}
          onFocus={onFocus}
          editorStyle={editorStyles}
          toolbarStyle={{ ...toolbarStyles, ...(toolbarHidden ? {} : toolbarStickyStyles) }}
          wrapperStyle={{
            ...wrapperStyles,
            borderColor: error ? "#E7211F" : disabled ? "grey.400" : "primary.main",
            color: disabled ? "text.disabled" : "inherit"
          }}
        />
      </Box>
      {helperText && (
        <Box sx={{ margin: "3px 14px 0" }}>
          <Typography variant="caption" color={error ? "error" : "primary"}>
            {helperText}
          </Typography>
        </Box>
      )}
    </>
  );
};

TextEditor.propTypes = {
  additionalClasses: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  inputValue: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  testId: PropTypes.string,
  title: PropTypes.string,
  titleComponent: PropTypes.element,
  toolbarHidden: PropTypes.bool
};

TextEditor.defaultProps = {
  additionalClasses: {},
  className: "",
  disabled: false,
  focused: false,
  inputValue: null,
  testId: "textEditor",
  title: null,
  toolbarHidden: false,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {}
};

export default TextEditor;
