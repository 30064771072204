import { getHasuraClientSDK } from "app/api/hasuraApi";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useEffect, useState, useMemo } from "react";

type TenantSetting = {
  flag_key: string;
  flag_value: string;
};

export const useTenantSettings = (tenantId: string) => {
  const hasuraClient = useMemo(() => getHasuraClientSDK(), []);
  const [tenantSettings, setTenantSettings] = useState<TenantSetting[]>([]);
  const [currentTenantId, setCurrentTenantId] = useState<string | null>(null);

  useEffect(() => {
    if (!tenantId || tenantId === currentTenantId) return;

    const fetchTenantSettings = async () => {
      try {
        const client = await hasuraClient;
        const settings = await client.tenantSettingsGetAll({
          tenant_id: tenantId
        });
        setTenantSettings(
          settings?.frontend_access_tenant_settings?.map(setting => ({
            flag_key: setting.flag_key,
            flag_value: setting.flag_value || ""
          })) || []
        );
        setCurrentTenantId(tenantId);
      } catch (error) {
        console.error("Error fetching tenant settings:", error);
        setTenantSettings([]);
      }
    };

    fetchTenantSettings();
  }, [tenantId, hasuraClient, currentTenantId]);

  return {
    tenantSettings
  };
};

export const useCurrentTenantSettings = () => {
  const { auth } = useAuthentication();
  const tenantId = useMemo(() => auth?.tenantId || "", [auth]);

  const { tenantSettings } = useTenantSettings(tenantId);
  return tenantSettings;
};

export const useLawPilotTrainingUrl = (tenantId: string) => {
  const [lawPilotUrl, setLawPilotUrl] = useState("");
  const { tenantSettings } = useTenantSettings(tenantId);

  useEffect(() => {
    if (!tenantId || !tenantSettings) {
      setLawPilotUrl("");
      return;
    }
    const tenantSpecificLawpilotUrl = tenantSettings?.find(
      (setting: { flag_key: string; flag_value: string }) => setting.flag_key === "lawpilots_training_url"
    )?.flag_value;
    setLawPilotUrl(tenantSpecificLawpilotUrl || "");
  }, [tenantId, tenantSettings]);
  return lawPilotUrl;
};
