/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useParams } from "react-router-dom";
import { AUDIT_QUESTION } from "./AuditQuestionType";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { AuditQuestionPayloadDTO, AuditQuestionWithAnswerAndRemarkDTO } from "app/api/auditApi";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { useTranslation } from "react-i18next";
import { DateField } from "components/DateField";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";
import AddIcon from "@material-ui/icons/Add";
import AuditQuestionSelector from "./AuditQuestionSelector";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  AUDIT_REPORT_PERMISSION,
  AUDIT_REPORT_PERMISSION_TYPES,
  AUDIT_STATUS,
  AUDIT_TEMPLATE_STATUS
} from "../audit/AuditTypes";
import Question, {
  QUESTION_ACTION,
  QUESTION_ACTION_TYPES,
  QUESTION_CHIP,
  QUESTION_CHIP_TYPES
} from "components/Question/Question";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { useUserDepartments } from "app/contexts/department-context";
import {
  closestCenter,
  DndContext,
  DraggableAttributes,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import colors from "theme/palette/colors";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { EXTERNAL_USER_ROLE_NAME } from "app/handlers/userAndTenant/user-tenant-context";
import { getTextFromTextEditorJsonString } from "app/pages/questionnaires/utils/textEditorConverter";

const styles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    "&.showCondition": {
      "& .audit-block": {
        opacity: 0.4,
        "&.selected": {
          background: theme.palette.blue[50],
          "&:hover": {
            background: theme.palette.blue[50],
            "& .block-actions": {
              visibility: "visible"
            }
          }
        },
        "&:hover": {
          background: theme.palette.grey[50],
          "& .block-actions": {
            visibility: "hidden"
          }
        },
        "&.haveCondition": {
          opacity: 1
        }
      }
    },
    "&.pickerMode": {
      "& .audit-block": {
        "&.allowPick": {
          cursor: "pointer"
        },
        "&.disablePick": {
          opacity: 0.4,
          cursor: "not-allowed"
        }
      }
    },
    "& .pre-wrap": {
      whiteSpace: "pre-wrap"
    }
  },
  auditBlockRoot: {
    borderRadius: "8px",
    position: "relative",
    background: theme.palette.grey[50],
    border: "2px solid #fff",
    "&:hover": {
      background: theme.palette.grey[100],
      "& > .block-actions": {
        visibility: "visible",
        background: theme.palette.common.white
      },
      "& > .MuiInputBase-root": {
        background: theme.palette.common.white
      },
      "& > .rdw-editor-wrapper": {
        background: theme.palette.common.white
      }
    },
    "& .block-type": {
      visibility: "hidden",
      fontSize: "11px",
      color: theme.palette.grey[500]
    },
    "& .block-actions": {
      visibility: "hidden",
      position: "absolute",
      right: "16px",
      top: "16px",
      background: theme.palette.common.white,
      border: "8px",
      boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
      borderRadius: "8px",
      padding: "4px"
    },
    "&.selected": {
      background: theme.palette.blue[50],
      "& > .block-type": {
        visibility: "visible"
      },
      "& > .block-actions": {
        visibility: "visible"
      },
      "&:hover": {
        "& > .block-actions": {
          background: theme.palette.common.white
        }
      },
      "& > .MuiInputBase-root": {
        background: theme.palette.common.white
      },
      "& > .rdw-editor-wrapper": {
        background: theme.palette.common.white
      }
    },
    "& .reorderIcon": {
      color: "#6c6f75",
      width: "46px",
      height: "46px",
      borderRadius: "50%",
      textAlign: "center",
      cursor: "move",
      "& svg": {
        marginTop: "10px"
      },
      "&:hover": {
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "rgba(0, 0, 0, 0.04)"
      }
    },
    "&.dragging": {
      background: theme.palette.blue[50],
      boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
      "& .block-type": {
        visibility: "visible"
      },
      "& .block-actions": {
        visibility: "visible"
      }
    },
    "&.disabled": {
      background: "none",
      "&:hover": {
        background: "none"
      }
    },
    "&.audit-block-group": {
      paddingTop: "0px",
      background: theme.palette.common.white,
      border: "2px solid " + theme.palette.grey[50],
      margin: "16px -32px",
      padding: "0 32px 0 32px",
      "& .allowDrop": {},
      "& .disableDrop": {},
      "&:hover": {
        border: "2px solid " + theme.palette.grey[100],
        background: theme.palette.common.white,
        "& .group-title": {
          backgroundColor: theme.palette.grey[100]
        }
      },
      "&.selected": {
        background: theme.palette.common.white,
        border: "2px solid " + theme.palette.blue[50],
        "& .group-title": {
          backgroundColor: theme.palette.blue[50]
        },
        "&:hover": {
          "& .group-title": {
            backgroundColor: theme.palette.blue[50]
          }
        }
      },
      "& .group-title": {
        margin: "0 -32px",
        padding: "8px",
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[600],
        cursor: "pointer"
      },
      "&.dragging": {},
      "&.disabled": {
        border: "none",
        background: "none",
        "&:hover": {
          background: "none"
        }
      },
      "&.collapse": {
        "& .group-title": {
          "& .reorderIcon": {
            visibility: "visible"
          }
        }
      },
      "&.expand": {
        "& .group-title": {
          "& .reorderIcon": {
            visibility: "hidden"
          }
        }
      },
      "&.haveCondition": {
        border: "2px solid #1171e3"
      }
    },
    "&.haveCondition": {
      border: "2px solid #1171e3",
      "& .questionConditionLine": {
        display: "block"
      }
    },
    "& .questionConditionLine": {
      display: "none",
      position: "absolute",
      background: "#1171e3",
      height: "2px",
      width: "24px",
      top: "12px",
      right: "-26px"
    }
  },
  conditionLine: {
    position: "absolute",
    height: "100%",
    width: "5px",
    right: "-28px",
    top: "14px",
    borderLeft: "2px solid #1171e3"
  },
  questionConditionLine: {},
  textEditor: {
    position: "relative",
    "& .MuiTypography-root": {
      position: "absolute",
      top: "-30px"
    },
    "& .rdw-editor-wrapper": {
      background: colors.white
    }
  }
}));

interface QuestionsRenderProps {
  readonly conditionPickerQuestion?: AuditQuestionWithAnswerAndRemarkDTO | null;
  readonly parentGroupId?: string;
  readonly selectedId: string | null;
  readonly questions: AuditQuestionWithAnswerAndRemarkDTO[];
  readonly allowAnswer?: boolean;
  readonly templateStatus?: string;
  readonly auditStatus?: string;
  readonly reportPermission?: AUDIT_REPORT_PERMISSION_TYPES | null;
  readonly disabled?: boolean;
  readonly onSelectQuestion?: (questionId: string | null) => void;
  readonly onRemoveQuestion?: (question: AuditQuestionWithAnswerAndRemarkDTO) => void;
  readonly onCopyQuestion?: (question: AuditQuestionWithAnswerAndRemarkDTO) => void;
  readonly onReorderQuestion?: (questionId: string, payload: Partial<AuditQuestionPayloadDTO>) => void;
  readonly onAddQuestionToGroup?: (questionType: string, groupId: string) => void;
  readonly onDropQuestionToGroup?: (questionId: string, payload: Partial<AuditQuestionPayloadDTO>) => void;
  readonly onDropQuestionToRoot?: (questionId: string, payload: Partial<AuditQuestionPayloadDTO>) => void;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
  readonly documentId?: string;
}

interface QuestionsGroupRenderProps extends QuestionsRenderProps {
  readonly conditionQuestion?: AuditQuestionWithAnswerAndRemarkDTO | null;
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly index: number;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
  readonly onShowConditionQuestion?: (question: AuditQuestionWithAnswerAndRemarkDTO | null) => void;
}

const QUESTION_WITH_ANSWER: string[] = [
  AUDIT_QUESTION.TEXT_INPUT_SINGLE,
  AUDIT_QUESTION.TEXT_INPUT_MULTIPLE,
  AUDIT_QUESTION.SELECT_BOX_SINGLE,
  AUDIT_QUESTION.SELECT_BOX_MULTIPLE,
  AUDIT_QUESTION.CHECK_BOX,
  AUDIT_QUESTION.RADIO_BOX,
  AUDIT_QUESTION.DATE,
  AUDIT_QUESTION.ORG_PROCESSING_CONSENT
];

/* WRAPPER AUDIT QUESTION */
const AuditBlockWrapper = ({
  allowToPick,
  allowAnswer,
  templateStatus,
  auditStatus,
  disabled,
  question,
  conditionQuestion,
  reportPermission,
  onSelectQuestion,
  onRemoveQuestion,
  onCopyQuestion,
  onAddQuestion,
  onShowConditionQuestion,
  selected,
  children,
  documentId
}: {
  readonly allowToPick?: boolean;
  readonly allowAnswer?: boolean;
  readonly templateStatus?: string;
  readonly auditStatus?: string;
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly conditionQuestion?: AuditQuestionWithAnswerAndRemarkDTO | null;
  readonly selected: boolean | null;
  readonly reportPermission?: AUDIT_REPORT_PERMISSION_TYPES | null;
  readonly index: number;
  readonly disabled?: boolean;
  readonly onSelectQuestion?: (questionId: string | null) => void;
  readonly onRemoveQuestion?: (question: AuditQuestionWithAnswerAndRemarkDTO) => void;
  readonly onCopyQuestion?: (question: AuditQuestionWithAnswerAndRemarkDTO) => void;
  readonly onAddQuestion?: () => void;
  readonly onShowConditionQuestion?: (question: AuditQuestionWithAnswerAndRemarkDTO | null) => void;
  readonly children: React.ReactNode;
  readonly documentId?: string;
}) => {
  const cls = styles();
  const isPublicAssessment = useIsFeaturePresent(FEATURES.PUBLIC_ASSESSMENT);
  const { auth } = useAuthentication();
  const { t } = useTranslation("audit_details");
  const params = useParams();
  const pageName = params?.page;
  const showCondition = useMemo(
    () => conditionQuestion && (conditionQuestion.conditions?.[question.id] || conditionQuestion === question),
    [conditionQuestion, question]
  );
  const conditionButtonColor = useMemo(
    () => (conditionQuestion && conditionQuestion === question ? "primary" : "default"),
    [conditionQuestion, question]
  );
  const onSelectCallback = useCallback(
    event => {
      if (allowToPick === true || allowToPick === undefined) {
        onSelectQuestion?.(question.id);
      }
      event.stopPropagation();
    },
    [allowToPick, onSelectQuestion, question.id]
  );
  const onRemoveCallback = useCallback(() => onRemoveQuestion?.(question), [question, onRemoveQuestion]);
  const onCopyCallback = useCallback(() => onCopyQuestion?.(question), [question, onCopyQuestion]);
  const onAddCallback = useCallback(() => onAddQuestion?.(), [onAddQuestion]);
  const onShowConditionQuestionCallback = useCallback(
    () => onShowConditionQuestion?.(question === conditionQuestion ? null : question),
    [conditionQuestion, onShowConditionQuestion, question]
  );

  const getActionEls = useCallback(
    (listeners: SyntheticListenerMap | undefined, attributes: DraggableAttributes) => (
      <Box className={"block-actions"} ml={2} alignItems={"start"} display="flex">
        <Tooltip title={t("question_actions_reorder")}>
          <div {...listeners} {...attributes}>
            <Box className="reorderIcon">
              <ImportExportIcon />
            </Box>
          </div>
        </Tooltip>
        {question.conditions && Object.keys(question.conditions).length > 0 && (
          <Tooltip title={t("showCondition")}>
            <IconButton color={conditionButtonColor} aria-label="add" onClick={onShowConditionQuestionCallback}>
              <CallSplitIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {question.type === AUDIT_QUESTION.GROUP && (
          <Tooltip title={t("question_actions_add")}>
            <IconButton aria-label="add" onClick={onAddCallback}>
              <AddIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("question_actions_duplicate")}>
          <IconButton aria-label="delete" onClick={onCopyCallback}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("question_actions_delete")}>
          <IconButton aria-label="delete" onClick={onRemoveCallback}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    [
      conditionButtonColor,
      onAddCallback,
      onCopyCallback,
      onRemoveCallback,
      onShowConditionQuestionCallback,
      question.conditions,
      question.type,
      t
    ]
  );

  const conditionLine = (
    <Box className={"questionConditionLine"}>
      <Box className={"left"} />
      <Box className={"right"} />
    </Box>
  );

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: question.id,
    data: { question }
  });

  const style = useMemo(
    () => ({
      transform: CSS.Translate.toString(transform),
      transition
    }),
    [transform, transition]
  );

  const inactiveWrapperEl = (
    <div ref={setNodeRef} style={style}>
      <Box
        my={2}
        p={4}
        onClick={onSelectCallback}
        className={`
              ${cls.auditBlockRoot} 
              audit-block
              audit-block-${question.type.toLowerCase()} 
              ${allowToPick ? "allowPick" : "disablePick"}
              ${showCondition ? "haveCondition" : ""}
              ${selected ? "selected" : ""}
            `}
      >
        <Box flex={1}>{children}</Box>
        {!disabled && getActionEls(listeners, attributes)}
        {!disabled && conditionLine}
      </Box>
    </div>
  );

  const activeWrapperEl = useMemo(() => {
    if (isPublicAssessment && QUESTION_WITH_ANSWER.includes(question.type)) {
      const actions: QUESTION_ACTION_TYPES[] =
        auth?.role === EXTERNAL_USER_ROLE_NAME
          ? [QUESTION_ACTION.COMMENTS]
          : [QUESTION_ACTION.TASKS, QUESTION_ACTION.COMMENTS];

      const chips: QUESTION_CHIP_TYPES[] =
        auth?.role === EXTERNAL_USER_ROLE_NAME
          ? [QUESTION_CHIP.COMMENTS, QUESTION_CHIP.AUDIT_ACTION_TASKS]
          : [QUESTION_CHIP.TASKS, QUESTION_CHIP.COMMENTS, QUESTION_CHIP.AUDIT_ACTION_TASKS];

      if (auth?.permissions.some(permission => permission.startsWith("audit_write")) && pageName === "responses") {
        actions.push(QUESTION_ACTION.REMARKS);
        chips.push(QUESTION_CHIP.REMARKS);
      }

      return (
        <Question
          questionId={question.id}
          questionName={question.title || question.placeholder || ""}
          actions={actions}
          chips={chips}
          documentId={documentId}
        >
          <Box className={`${cls.auditBlockRoot} disabled audit-block-${question.type.toLowerCase()}`} flex={1}>
            {children}
          </Box>
        </Question>
      );
    } else if (QUESTION_WITH_ANSWER.includes(question.type) && allowAnswer) {
      const chips: QUESTION_CHIP_TYPES[] = [
        QUESTION_CHIP.TASKS,
        QUESTION_CHIP.REMARKS,
        auditStatus === AUDIT_STATUS.EDIT && QUESTION_CHIP.AUDIT_ACTION_TASKS
      ].filter(notNull => notNull) as QUESTION_CHIP_TYPES[];

      const actions: QUESTION_ACTION_TYPES[] =
        reportPermission === AUDIT_REPORT_PERMISSION.WRITE ? [QUESTION_ACTION.TASKS, QUESTION_ACTION.REMARKS] : [];

      return (
        <Question
          questionId={question.id}
          questionName={question.title || question.placeholder || ""}
          chips={chips}
          actions={actions}
        >
          <Box className={`${cls.auditBlockRoot} disabled audit-block-${question.type.toLowerCase()}`} flex={1}>
            {children}
          </Box>
        </Question>
      );
    } else {
      return (
        <Question disabled={true}>
          <Box className={`${cls.auditBlockRoot} disabled audit-block-${question.type.toLowerCase()}`}>
            <Box flex={1}>{children}</Box>
          </Box>
        </Question>
      );
    }
  }, [
    documentId,
    allowAnswer,
    pageName,
    auth?.role,
    auth?.permissions,
    auditStatus,
    children,
    cls.auditBlockRoot,
    question.id,
    question.placeholder,
    question.title,
    question.type,
    reportPermission,
    isPublicAssessment
  ]);

  return !disabled && templateStatus === AUDIT_TEMPLATE_STATUS.ACTIVE ? activeWrapperEl : inactiveWrapperEl;
};

/* HEADING AUDIT QUESTION */
const HeadingBlockRender = ({ question }: { readonly question: AuditQuestionWithAnswerAndRemarkDTO }) => {
  return (
    <Typography className="pre-wrap" variant="h2">
      {question.title}
    </Typography>
  );
};

/* SUB HEADING AUDIT QUESTION */
const SubHeadingBlockRender = ({ question }: { readonly question: AuditQuestionWithAnswerAndRemarkDTO }) => {
  return (
    <Typography className="pre-wrap" variant="h3">
      {question.title}
    </Typography>
  );
};

/* PARAGRAPH AUDIT QUESTION */
const ParagraphBlockRender = ({ question }: { readonly question: AuditQuestionWithAnswerAndRemarkDTO }) => {
  return <Typography className="pre-wrap">{question.title}</Typography>;
};

/* DIVIDER */
const DividerRender = () => {
  return (
    <>
      <Box borderBottom="2px solid #555" />
    </>
  );
};

/* GROUP */
const GroupRender = ({
  allowAnswer,
  reportPermission,
  conditionQuestion,
  question,
  templateStatus,
  auditStatus,
  selectedId,
  questions,
  disabled,
  onSelectQuestion,
  onCopyQuestion,
  onRemoveQuestion,
  onReorderQuestion,
  onAddQuestionToGroup,
  onShowConditionQuestion,
  onAnswer,
  documentId
}: QuestionsGroupRenderProps) => {
  const cls = styles();
  const { t } = useTranslation("audit_details");
  const [expand, setExpand] = useState<boolean | null>(null);
  const stopEvent = useCallback(event => event.stopPropagation(), []);

  const conditionButtonColor = useMemo(
    () => (conditionQuestion && conditionQuestion === question ? "primary" : "default"),
    [conditionQuestion, question]
  );

  const showCondition = useMemo(
    () => conditionQuestion && (conditionQuestion.conditions?.[question.id] || conditionQuestion === question),
    [conditionQuestion, question]
  );

  const [questionAddOpen, setQuestionAddOpen] = useState<boolean>(false);
  const hideQuestionAdd = useCallback(() => setQuestionAddOpen(false), []);
  const showQuestionAdd = useCallback(() => {
    setQuestionAddOpen(true);
    setExpand(true);
  }, []);

  const onRemoveCallback = useCallback(() => onRemoveQuestion?.(question), [question, onRemoveQuestion]);
  const onCopyCallback = useCallback(() => onCopyQuestion?.(question), [question, onCopyQuestion]);

  const onShowConditionQuestionCallback = useCallback(
    () => onShowConditionQuestion?.(question === conditionQuestion ? null : question),
    [conditionQuestion, onShowConditionQuestion, question]
  );

  const onSelectCallback = useCallback(
    event => {
      onSelectQuestion?.(question.id);
      event.stopPropagation();
    },
    [question, onSelectQuestion]
  );

  const onAddQuestion = useCallback(
    questionType => {
      hideQuestionAdd();
      onAddQuestionToGroup?.(questionType, question.id);
    },
    [hideQuestionAdd, onAddQuestionToGroup, question.id]
  );

  const onExpandToggle = useCallback(
    event => {
      onSelectCallback(event);
      stopEvent(event);
      setExpand(expand => !expand);
    },
    [onSelectCallback, stopEvent]
  );

  const questionSelectorEl = useMemo(
    () =>
      questionAddOpen && (
        <ClickAwayListener onClickAway={hideQuestionAdd}>
          <AuditQuestionSelector onSelect={onAddQuestion} onClose={hideQuestionAdd} />
        </ClickAwayListener>
      ),
    [questionAddOpen, hideQuestionAdd, onAddQuestion]
  );

  const questionsEl = (
    <>
      {questions && (
        <AuditQuestionsRender
          allowAnswer={allowAnswer}
          onAnswer={onAnswer}
          reportPermission={reportPermission}
          templateStatus={templateStatus}
          auditStatus={auditStatus}
          parentGroupId={question.id}
          questions={questions}
          disabled={disabled}
          onSelectQuestion={onSelectQuestion}
          onCopyQuestion={onCopyQuestion}
          onRemoveQuestion={onRemoveQuestion}
          selectedId={selectedId}
          onReorderQuestion={onReorderQuestion}
          documentId={documentId}
        />
      )}
    </>
  );

  const emptyGroupEl = (
    <>
      {(!questions || questions?.length === 0) && (
        <Box mt={3}>
          <Typography align="center">{t("empty_group_message")}</Typography>
        </Box>
      )}
    </>
  );

  const getGroupActionEls = useCallback(
    (listeners: SyntheticListenerMap | undefined, attributes: DraggableAttributes) => (
      <Box className={"group-actions"} ml={2} alignItems={"start"} display="flex">
        <Box className="reorderIcon">
          <div {...listeners} {...attributes}>
            <Tooltip title={t("question_actions_reorder")}>
              <Box>
                <ImportExportIcon />
              </Box>
            </Tooltip>
          </div>
        </Box>
        <Tooltip title={t("question_actions_add")}>
          <IconButton aria-label="add" onClick={showQuestionAdd}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        {question.conditions && Object.keys(question.conditions).length > 0 && (
          <Tooltip title={t("showCondition")}>
            <IconButton color={conditionButtonColor} aria-label="add" onClick={onShowConditionQuestionCallback}>
              <CallSplitIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("question_actions_duplicate")}>
          <IconButton aria-label="copy" onClick={onCopyCallback}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("question_actions_delete")}>
          <IconButton aria-label="delete" onClick={onRemoveCallback}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    [
      conditionButtonColor,
      onCopyCallback,
      onRemoveCallback,
      onShowConditionQuestionCallback,
      question.conditions,
      showQuestionAdd,
      t
    ]
  );

  const getGroupTitleEl = useCallback(
    (listeners: SyntheticListenerMap | undefined, attributes: DraggableAttributes) => (
      <Box display={"flex"} alignItems={"center"} className={"group-title"}>
        <Box mr={2}>
          <Tooltip title={expand ? t("collapse") : t("expand")}>
            <IconButton aria-label="add" onClick={onExpandToggle}>
              {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box flex={1}>{question.title}</Box>
        <Box>{!disabled && getGroupActionEls(listeners, attributes)}</Box>
      </Box>
    ),
    [disabled, expand, getGroupActionEls, onExpandToggle, question.title, t]
  );

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: question.id,
    data: {
      question
    }
  });

  const style = useMemo(
    () => ({
      transform: CSS.Translate.toString(transform),
      transition
    }),
    [transform, transition]
  );

  const inactiveWrapperEl = (
    <div ref={setNodeRef} style={style}>
      <Box>
        <Box
          my={2}
          onClick={onSelectCallback}
          className={`
              ${cls.auditBlockRoot} 
              audit-block-${question.type.toLowerCase()} 
              ${selectedId === question.id ? "selected" : ""}
              ${showCondition ? "haveCondition" : ""}
              ${expand ? "expand" : "collapse"}`}
        >
          {getGroupTitleEl(listeners, attributes)}
          {expand && (
            <Box>
              {emptyGroupEl}
              {questionsEl}
              {questionSelectorEl}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );

  const activeWrapperEl = (
    <Box my={2} p={4} className={`${cls.auditBlockRoot} disabled audit-block-${question.type.toLowerCase()}`}>
      {questionsEl}
    </Box>
  );

  return !disabled && templateStatus === AUDIT_TEMPLATE_STATUS.ACTIVE ? activeWrapperEl : inactiveWrapperEl;
};

/* TEXT INPUT SINGLE LINE AUDIT QUESTION */
const TextInputSingleLineBlockRender = ({
  question,
  allowAnswer,
  onAnswer
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly allowAnswer?: boolean;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const onAnswerCallBack = useCallback(
    event => {
      if (isDirty) {
        onAnswer?.(question.id, event.target.value);
      }
    },
    [onAnswer, question.id, isDirty]
  );
  const onChange = useCallback(event => {
    setValue(event.target.value);
    setIsDirty(true);
  }, []);

  useEffect(() => setValue(question.answer?.value || ""), [question.answer]);
  return (
    <TextField
      margin={"none"}
      label={question.title || question.placeholder || ""}
      id={question.id}
      value={value}
      onChange={onChange}
      fullWidth
      placeholder={question.placeholder || ""}
      onBlur={onAnswerCallBack}
      variant="outlined"
      disabled={!allowAnswer}
      style={allowAnswer ? undefined : { pointerEvents: "none" }}
      InputLabelProps={{
        shrink: true
      }}
    />
  );
};

/* TEXT INPUT MULTI LINE AUDIT QUESTION */
const TextInputMultiLineBlockRender = ({
  question,
  allowAnswer,
  onAnswer
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly allowAnswer?: boolean;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
}) => {
  const cls = styles();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const onAnswerCallBack = useCallback(() => {
    if (isDirty) {
      onAnswer?.(question.id, value);
    }
  }, [onAnswer, isDirty, question.id, value]);
  const onChange = useCallback(
    _value => {
      setValue(_value);
      setIsDirty(
        getTextFromTextEditorJsonString(question.answer?.value || "") !== getTextFromTextEditorJsonString(_value)
      );
    },
    [question.answer]
  );

  useEffect(() => setValue(question.answer?.value || ""), [question.answer]);
  return (
    <Box className={cls.textEditor}>
      <TextEditor
        title={question.title || question.placeholder || ""}
        onBlur={onAnswerCallBack}
        titleComponent={undefined}
        inputValue={value}
        onChange={onChange}
        disabled={!allowAnswer}
        placeholder={question.placeholder || ""}
      />
    </Box>
  );
};

/* SELECT BOX SINGLE AUDIT QUESTION */
const SelectBlockSingleRender = ({
  question,
  allowAnswer,
  onAnswer
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly allowAnswer?: boolean;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
}) => {
  const getOptionLabel = useCallback(
    optionId => question.options.find(({ id }) => optionId === id)?.title || "",
    [question.options]
  );
  const optionIds: string[] = useMemo(() => (question.options || []).map(({ id }) => id), [question.options]);
  const onAnswerCallBack = useCallback(value => onAnswer?.(question.id, value), [onAnswer, question.id]);

  return (
    <MultiAutocomplete
      label={question.title || question.placeholder || ""}
      placeholder={question.placeholder || ""}
      hasMultiSelect={false}
      freeSolo={false}
      disableClearable={false}
      onBlur={undefined}
      onFocus={undefined}
      options={optionIds}
      getOptionLabel={getOptionLabel}
      updateSelected={onAnswerCallBack}
      selected={question.answer?.value}
      disabled={!allowAnswer}
    />
  );
};

/* SELECT BOX MULTIPLE AUDIT QUESTION */
const SelectBlockMultipleRender = ({
  question,
  allowAnswer,
  onAnswer
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly allowAnswer?: boolean;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
}) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  useEffect(() => {
    setCheckedItems(question.answer?.value ? question.answer.value.split(";") : []);
  }, [question.answer?.value]);

  const updateSelectedCallback = useCallback(
    ids => {
      setCheckedItems(ids);
      onAnswer?.(question.id, ids.join(";"));
    },
    [onAnswer, question.id]
  );

  const optionIds: string[] = useMemo(() => (question.options || []).flatMap(({ id }) => id), [question.options]);
  const getOptionLabel = useCallback(
    optionId => question.options.find(({ id }) => optionId === id)?.title || "",
    [question.options]
  );

  return (
    <MultiAutocomplete<string, true, true, true>
      label={question.title || question.placeholder || ""}
      placeholder={question.placeholder || ""}
      hasMultiSelect={true}
      onBlur={undefined}
      onFocus={undefined}
      options={optionIds}
      getOptionLabel={getOptionLabel}
      updateSelected={updateSelectedCallback}
      selected={checkedItems}
      disabled={!allowAnswer}
    />
  );
};

/* CHECK BOX AUDIT QUESTION */
const CheckBoxBlockRender = ({
  question,
  allowAnswer,
  onAnswer
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly allowAnswer?: boolean;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
}) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  useEffect(() => {
    setCheckedItems(question.answer?.value ? question.answer.value.split(";") : []);
  }, [question.answer?.value]);

  const labelEl = (
    <Box className="pre-wrap" mb={1}>
      {question.title || ""}
    </Box>
  );

  const onAnswerCallBack = useCallback(
    event => {
      const { checked, value } = event.target;
      const data = checked ? [...checkedItems, value] : checkedItems.filter(id => id !== value);
      setCheckedItems(data);
      onAnswer?.(question.id, data.join(";"));
    },
    [checkedItems, onAnswer, question.id]
  );

  const optionsEl = (
    <FormControl component="fieldset" disabled={!allowAnswer}>
      <FormGroup>
        {(question.options || []).map(({ id, title }) => (
          <FormControlLabel
            key={id}
            control={
              <Checkbox
                checked={checkedItems.includes(id)}
                value={id}
                onChange={onAnswerCallBack}
                color="primary"
                name={title}
                disabled={!allowAnswer}
              />
            }
            label={title}
          />
        ))}
      </FormGroup>
    </FormControl>
  );

  return (
    <>
      {labelEl}
      {optionsEl}
    </>
  );
};

/* RADIO BOX AUDIT QUESTION */
const RadioBoxBlockRender = ({
  question,
  allowAnswer,
  onAnswer
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly allowAnswer?: boolean;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
}) => {
  const [value, setValue] = useState<string>("");
  useEffect(() => setValue(question.answer?.value || ""), [question.answer?.value]);

  const labelEl = (
    <Box className="pre-wrap" mb={1}>
      {question.title || ""}
    </Box>
  );

  const onChangeCallback = useCallback(
    event => {
      const v = event.target.value;
      setValue(v);
      onAnswer?.(question.id, v);
    },
    [onAnswer, question.id]
  );
  const optionsEl = (
    <FormControl component="fieldset" disabled={!allowAnswer}>
      <RadioGroup aria-label={question.id} name={question.id}>
        {question.options.map(({ id, title }) => (
          <FormControlLabel
            key={id}
            control={
              <Radio
                checked={value === id}
                onChange={onChangeCallback}
                value={id}
                color="primary"
                name={title}
                disabled={!allowAnswer}
              />
            }
            label={title}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  return (
    <>
      {labelEl}
      {optionsEl}
    </>
  );
};

/* DATE AUDIT QUESTION */
const DateBlockRender = ({
  question,
  allowAnswer,
  onAnswer
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly allowAnswer?: boolean;
  readonly templateStatus?: string;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [value, setValue] = useState<MaterialUiPickersDate | null>(null);
  const onDateChange = useCallback(
    (value: MaterialUiPickersDate | null) => {
      setValue(value);
      onAnswer?.(question.id, value?.toISOString?.() || "");
    },
    [onAnswer, question.id]
  );
  const onBlur = useCallback(() => {
    if (isDirty) {
      onAnswer?.(question.id, value?.toISOString?.() || "");
    }
  }, [question.id, value, onAnswer, isDirty]);

  useEffect(() => setValue(question.answer?.value ? new Date(question.answer.value) : null), [question.answer?.value]);
  return (
    <DateField
      label={question.title || question.placeholder || ""}
      margin="none"
      placeholder={question.placeholder}
      disablePast={false}
      onDateChange={onDateChange}
      date={value}
      onBlur={onBlur}
      disabled={!allowAnswer}
    />
  );
};

/* ORG PROCESSING CONSENT AUDIT QUESTION */
const OrgProcessingConsentBlockRender = ({
  question,
  auditStatus,
  allowAnswer,
  onAnswer
}: {
  readonly question: AuditQuestionWithAnswerAndRemarkDTO;
  readonly allowAnswer?: boolean;
  readonly auditStatus?: string;
  readonly onAnswer?: (questionId: string, questionAnswer: string) => void;
}) => {
  const { t } = useTranslation("audit_details");
  const [value, setValue] = useState<{
    departments: Record<string, boolean>;
    documented: boolean | null;
  }>({
    departments: {},
    documented: null
  });
  const departements = useUserDepartments();

  const initialValueSet = useRef(false);
  useEffect(() => {
    if (initialValueSet.current || !question.answer?.value) return;
    initialValueSet.current = true;
    setValue(value => {
      try {
        const parsed = JSON.parse(question.answer?.value || "{}");
        return {
          departments: parsed.departments || {},
          documented: !!parsed.documented
        } as typeof value;
      } catch (error) {
        return value;
      }
    });
  }, [question.answer?.value]);

  const labelEl = <Box mb={1}>{question.title || ""}</Box>;
  const onChangeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value;
    setValue(value => {
      return {
        ...value,
        departments: {
          ...value.departments,
          [v]: !value.departments[v]
        }
      };
    });
  }, []);

  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current || auditStatus === "draft") {
      didMount.current = true;
      return;
    }
    onAnswer?.(question.id, JSON.stringify(value));
  }, [question.id, value, onAnswer, auditStatus]);

  const handleSelectAll = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setValue(value => ({
      ...value,
      departments: Object.keys(value.departments).reduce((depts, deptId) => ({ ...depts, [deptId]: checked }), {})
    }));
  }, []);
  const handleChangeDocumented = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const documented = event.target.value === "true";
    setValue(value => ({
      ...value,
      documented
    }));
  }, []);

  const checkedDepartments = useMemo(
    () => Object.keys(value.departments).filter(deptId => value.departments[deptId]),
    [value.departments]
  );

  const departmentsChecklist = useMemo(
    () =>
      departements.departments
        .filter(dept => typeof value.departments[dept.id] !== "undefined")
        .map(dept => ({
          id: dept.id,
          name: dept.name
        })),
    [departements, value.departments]
  );

  return (
    <>
      {labelEl}
      {auditStatus === "draft" ? (
        <CustomAlert severity="info">
          <Typography style={{ fontWeight: "bold" }}>{t("orgProcessingConsent_info_message")}</Typography>
        </CustomAlert>
      ) : (
        <>
          {departmentsChecklist.length === 0 ? (
            <CustomAlert severity="success">
              <Typography style={{ fontWeight: "bold" }}>{t("orgProcessingConsent_success_message")}</Typography>
            </CustomAlert>
          ) : (
            <CustomAlert severity="warning">
              <Typography style={{ fontWeight: "bold" }}>{t("orgProcessingConsent_warning_message")}</Typography>
              <span style={{ color: "#000000" }}>
                {parse(DOMPurify.sanitize(t("orgProcessingConsent_warning_message_description")))}
              </span>
            </CustomAlert>
          )}
          <FormControl component="fieldset" disabled={!allowAnswer || typeof onAnswer === "undefined"}>
            {departmentsChecklist.length ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedDepartments.length === departmentsChecklist.length}
                      onChange={handleSelectAll}
                      color="primary"
                      disabled={!allowAnswer || typeof onAnswer === "undefined"}
                    />
                  }
                  label={
                    <p>
                      <i>{t("orgProcessingConsent_select_all")}</i>
                    </p>
                  }
                />
                {departmentsChecklist.map(dep => (
                  <FormControlLabel
                    key={dep.id}
                    control={
                      <Checkbox
                        checked={value.departments[dep.id]}
                        value={dep.id}
                        onChange={onChangeCallback}
                        color="primary"
                        name={dep.name}
                        disabled={!allowAnswer || typeof onAnswer === "undefined"}
                      />
                    }
                    label={dep.name}
                  />
                ))}
              </FormGroup>
            ) : null}
            <FormGroup>
              <p>{t("orgProcessingConsent_confirmation_label")}</p>
            </FormGroup>

            <RadioGroup row>
              {[true, false].map(bool => (
                <FormControlLabel
                  key={`${bool}`}
                  control={
                    <Radio
                      checked={value.documented === bool}
                      onChange={handleChangeDocumented}
                      color="primary"
                      value={`${bool}`}
                      disabled={!allowAnswer || typeof onAnswer === "undefined"}
                    />
                  }
                  label={t(`orgProcessingConsent_confirmation_${bool ? "yes" : "no"}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </>
      )}
    </>
  );
};

export const AuditQuestionsRender = ({
  conditionPickerQuestion,
  parentGroupId,
  selectedId,
  questions,
  allowAnswer,
  reportPermission,
  templateStatus,
  auditStatus,
  disabled,
  onSelectQuestion,
  onCopyQuestion,
  onRemoveQuestion,
  onReorderQuestion,
  onAddQuestionToGroup,
  onAnswer,
  documentId
}: QuestionsRenderProps) => {
  const cls = styles();

  const [conditionQuestion, setConditionQuestion] = useState<AuditQuestionWithAnswerAndRemarkDTO | null>(null);

  useEffect(() => setConditionQuestion(null), [selectedId]);
  useEffect(() => {
    if (conditionPickerQuestion) {
      setConditionQuestion(null);
    }
  }, [conditionPickerQuestion]);

  const getCondition = useCallback(
    (conditions: Record<string, string>) => {
      // if condition empty return true
      if (Object.keys(conditions).length === 0) {
        return true;
      }
      // if condition not empty lets check if condition are okay to show element
      else {
        return !Object.keys(conditions).some(key => {
          const conditionQuestion: AuditQuestionWithAnswerAndRemarkDTO | undefined = questions.find(
            ({ id }) => key === id
          );
          const questionAnswer: string = (conditionQuestion && conditionQuestion?.answer?.value) || "";
          const questionAnswerValues: string[] = questionAnswer.split(";");

          return !questionAnswerValues.includes(conditions[key]);
        });
      }
    },
    [questions]
  );
  const filteredQuestions = useMemo(() => {
    return questions.filter(({ conditions, groupId }) => {
      return (
        ((!groupId && !parentGroupId) || parentGroupId === groupId) && (!allowAnswer || getCondition(conditions || {}))
      );
    });
  }, [allowAnswer, getCondition, parentGroupId, questions]);
  const onDragEndCallback = useCallback(
    event => {
      const filteredQuestionIds = filteredQuestions.map(({ id }) => id);
      const { active, over } = event;
      const draggableQuestion = active.data.current.question;
      const droppableQuestion = over.data.current.question;

      // sorting inside ROOT OR sorting inside GROUP
      if (
        (!draggableQuestion.groupId && !droppableQuestion.groupId) ||
        (draggableQuestion.groupId &&
          droppableQuestion.groupId &&
          draggableQuestion.groupId === droppableQuestion.groupId)
      ) {
        const afterQuestionId =
          draggableQuestion.ordering < droppableQuestion.ordering
            ? droppableQuestion.id
            : filteredQuestionIds[filteredQuestionIds.indexOf(droppableQuestion.id) - 1] || null;
        onReorderQuestion?.(draggableQuestion.id, { order: { afterQuestionId } });
      }
    },
    [filteredQuestions, onReorderQuestion]
  );

  const getReactElement = useCallback((type: string) => {
    switch (type) {
      case AUDIT_QUESTION.HEADING: {
        return HeadingBlockRender;
      }
      case AUDIT_QUESTION.SUB_HEADING: {
        return SubHeadingBlockRender;
      }
      case AUDIT_QUESTION.PARAGRAPH: {
        return ParagraphBlockRender;
      }
      case AUDIT_QUESTION.TEXT_INPUT_SINGLE: {
        return TextInputSingleLineBlockRender;
      }
      case AUDIT_QUESTION.TEXT_INPUT_MULTIPLE: {
        return TextInputMultiLineBlockRender;
      }
      case AUDIT_QUESTION.SELECT_BOX_SINGLE: {
        return SelectBlockSingleRender;
      }
      case AUDIT_QUESTION.SELECT_BOX_MULTIPLE: {
        return SelectBlockMultipleRender;
      }
      case AUDIT_QUESTION.CHECK_BOX: {
        return CheckBoxBlockRender;
      }
      case AUDIT_QUESTION.RADIO_BOX: {
        return RadioBoxBlockRender;
      }
      case AUDIT_QUESTION.DATE: {
        return DateBlockRender;
      }
      case AUDIT_QUESTION.DIVIDER: {
        return DividerRender;
      }
      case AUDIT_QUESTION.ORG_PROCESSING_CONSENT: {
        return OrgProcessingConsentBlockRender;
      }
      default:
        throw new Error(`Unknown audit question: ${type}`);
    }
  }, []);

  const getQuestionEl = useCallback(
    (question: AuditQuestionWithAnswerAndRemarkDTO, index: number) => {
      const reactEl = getReactElement(question.type);
      const el = React.createElement(reactEl, {
        question,
        onAnswer: allowAnswer ? onAnswer : undefined,
        allowAnswer,
        auditStatus,
        templateStatus
      });
      const allowToPick = conditionPickerQuestion
        ? Boolean(
            conditionPickerQuestion &&
              conditionPickerQuestion.conditions?.[question.id] === undefined &&
              question.options.length > 0
          )
        : undefined;
      return (
        <AuditBlockWrapper
          key={question.id}
          allowToPick={allowToPick}
          allowAnswer={allowAnswer}
          templateStatus={templateStatus}
          auditStatus={auditStatus}
          disabled={disabled}
          selected={question.id === selectedId}
          question={question}
          conditionQuestion={conditionQuestion}
          reportPermission={reportPermission}
          index={index}
          onSelectQuestion={onSelectQuestion}
          onRemoveQuestion={onRemoveQuestion}
          onCopyQuestion={onCopyQuestion}
          onShowConditionQuestion={setConditionQuestion}
          documentId={documentId}
        >
          {el}
        </AuditBlockWrapper>
      );
    },
    [
      getReactElement,
      allowAnswer,
      onAnswer,
      auditStatus,
      templateStatus,
      conditionPickerQuestion,
      disabled,
      selectedId,
      conditionQuestion,
      reportPermission,
      onSelectQuestion,
      onRemoveQuestion,
      onCopyQuestion,
      documentId
    ]
  );

  const getQuestionGroupEl = useCallback(
    (question: AuditQuestionWithAnswerAndRemarkDTO, index: number) => {
      const childQuestions = questions.filter(({ groupId }) => groupId === question.id);
      return (
        <GroupRender
          key={question.id}
          allowAnswer={allowAnswer}
          question={question}
          selectedId={selectedId}
          index={index}
          templateStatus={templateStatus}
          auditStatus={auditStatus}
          questions={childQuestions}
          conditionQuestion={conditionQuestion}
          reportPermission={reportPermission}
          conditionPickerQuestion={null}
          disabled={disabled}
          onSelectQuestion={onSelectQuestion}
          onCopyQuestion={onCopyQuestion}
          onRemoveQuestion={onRemoveQuestion}
          onReorderQuestion={onReorderQuestion}
          onAddQuestionToGroup={onAddQuestionToGroup}
          onShowConditionQuestion={setConditionQuestion}
          onAnswer={onAnswer}
          documentId={documentId}
        />
      );
    },
    [
      questions,
      allowAnswer,
      selectedId,
      templateStatus,
      auditStatus,
      conditionQuestion,
      reportPermission,
      disabled,
      onSelectQuestion,
      onCopyQuestion,
      onRemoveQuestion,
      onReorderQuestion,
      onAddQuestionToGroup,
      onAnswer,
      documentId
    ]
  );

  const questionsEl = filteredQuestions.map((question: AuditQuestionWithAnswerAndRemarkDTO, index) =>
    question.type === AUDIT_QUESTION.GROUP ? getQuestionGroupEl(question, index) : getQuestionEl(question, index)
  );

  const conditionLine = templateStatus === AUDIT_TEMPLATE_STATUS.INACTIVE && !parentGroupId && conditionQuestion && (
    <Box className={cls.conditionLine} />
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  return (
    <Box
      className={`
        ${cls.root}
        ${conditionQuestion ? "showCondition" : ""} 
        ${conditionPickerQuestion ? "pickerMode" : ""} 
      `}
      pb={parentGroupId ? 2 : 6}
    >
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEndCallback}>
        <SortableContext items={filteredQuestions} strategy={verticalListSortingStrategy}>
          {questionsEl}
          {conditionLine}
        </SortableContext>
      </DndContext>
    </Box>
  );
};
