import { useParams } from "react-router-dom";
import { useGetAssetApi, useUpdateAssetApi } from "../../api/assetApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isAxiosErrorWithCode } from "../../api/axios/axiosErrorHandler";
import DocumentNotFoundPage from "../shared/DocumentNotFound/DocumentNotFound";
import { COLLECTIONS } from "../../collections";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { useUpdateQueues } from "../../../hook/useUpdateQueues";
import DocView from "../../../components/DocView/DocView";
import { AssetStatusEnum, UpdateAssetDTO } from "../../api/generated/asset-service";
import AssetsProcessesOverview from "./components/AssetProcessesOverview";
import { AssetPageButtons, AssetPageStepper } from "./AssetPagination";
import AssetsDetails from "./components/AssetDetails";
import { DocProperty } from "../../../components/DocTitle/DocTitle";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { Box, useTheme } from "@mui/material";
import AssetRisks from "./components/AssetRisks";
import { HasFeatureToggleOn } from "app/router/router-filters";
import { FEATURES } from "app/features";
import { useTranslation } from "react-i18next";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { AssetDataLocationsOverview } from "./components/AssetDataLocationsOverview";
import { AssetExternalRecipientsOverview } from "./components/AssetExternalRecipientsOverview";
import { useDocumentName } from "app/contexts/document-name-context";

export const AssetPage = () => {
  const { id, page } = useParams();
  const { isLoading, data, error } = useGetAssetApi({ documentId: id || "" });
  const docName = data?.name || "-";
  const [documentNotFound, setDocumentNotFound] = useState(false);

  useEffect(() => {
    if (isAxiosErrorWithCode(error, 404) || isAxiosErrorWithCode(error, 403)) {
      setDocumentNotFound(true);
    } else {
      setDocumentNotFound(false);
    }
  }, [error]);

  if (documentNotFound) {
    return <DocumentNotFoundPage collection={COLLECTIONS.ASSETS} />;
  }

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          collection={COLLECTIONS.ASSETS}
          docId={id}
          docName={docName}
          pageId={page || "general"}
          tabs={metaViewTabIds}
          translationKey={"asset_details"}
        />
      }
      loading={isLoading}
    >
      <AssetDocView key={page} readonly={data?.permission !== "write"}>
        <Box>
          <Box mt={4}>
            {page === "general" && <AssetsDetails readonly={data?.permission !== "write"} id={id || ""} />}
            {page === "asset-processes" && (
              <HasFeatureToggleOn feature={FEATURES.ASSET_PROCESSES}>
                <Box mx={-6}>
                  <AssetsProcessesOverview assetId={id || ""} readonly={data?.permission !== "write"} />
                </Box>
              </HasFeatureToggleOn>
            )}
            {page === "risks" && (
              <HasFeatureToggleOn feature={FEATURES.ASSET_RISKS}>
                <Box mx={-6}>
                  <AssetRisks readonly={data?.permission !== "write"} />
                </Box>
              </HasFeatureToggleOn>
            )}
            {page === "external-recipients" && (
              <HasFeatureToggleOn feature={FEATURES.ASSET_ERS}>
                <Box mx={-4}>
                  <AssetExternalRecipientsOverview readonly={data?.permission !== "write"} assetId={id || ""} />
                </Box>
              </HasFeatureToggleOn>
            )}
            {page === "data-locations" && (
              <HasFeatureToggleOn feature={FEATURES.ASSET_DLS}>
                <Box mx={-4}>
                  <AssetDataLocationsOverview readonly={data?.permission !== "write"} assetId={id || ""} />
                </Box>
              </HasFeatureToggleOn>
            )}
          </Box>
          <Box>
            <AssetPageButtons />
          </Box>
        </Box>
      </AssetDocView>
    </DocMetaView>
  );
};

export const AssetDocView = ({
  children,
  readonly
}: {
  readonly children: React.ReactNode;
  readonly readonly: boolean;
}) => {
  const { t } = useTranslation("asset_details");
  const { id } = useParams();
  const { setDocumentName } = useDocumentName();
  const { data } = useGetAssetApi({ documentId: id || "" });
  const docName = data?.name || "-";
  const { updateAsset } = useUpdateAssetApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<UpdateAssetDTO, UpdateAssetDTO>({
    triggerUpdate: updateAsset
  });

  const theme = useTheme();

  useEffect(() => {
    setDocumentName(docName);
  }, [docName, setDocumentName]);

  const docProperties = useMemo<DocProperty[]>(
    () => [
      {
        label: (data?.assetId || "-").split("-").join(" - "),
        icon: (
          <SellOutlinedIcon
            sx={{
              color: theme.palette.grey[600],
              fontSize: 20,
              margin: 0.5
            }}
          />
        )
      }
    ],
    [data?.assetId, theme.palette.grey]
  );

  const assetsStatusIconMap: Record<AssetStatusEnum, React.ReactNode> = useMemo(
    () => ({
      [AssetStatusEnum.Planned]: <LazySvgIcon name="Planned" />,
      [AssetStatusEnum.Testing]: <LazySvgIcon name="Testing" />,
      [AssetStatusEnum.Active]: <LazySvgIcon name="Completed" />,
      [AssetStatusEnum.Inactive]: <LazySvgIcon name="On_Halt" />
    }),
    []
  );

  const statuses = useMemo(() => {
    return Object.values(AssetStatusEnum).map(status => ({
      id: status,
      label: t(`assets_overview:${status.toLowerCase()}`),
      icon: assetsStatusIconMap[status]
    }));
  }, [assetsStatusIconMap, t]);

  const onHeaderChange = useCallback(
    async (name: string) => {
      await queueUpdates({ name });
    },
    [queueUpdates]
  );
  const onStatusChange = useCallback(
    async (status: string) => {
      await queueUpdates({ status: status as AssetStatusEnum });
    },
    [queueUpdates]
  );

  return (
    <DocView
      docProperties={docProperties}
      header={docName}
      onHeaderChange={onHeaderChange}
      onStatusChange={onStatusChange}
      pagination={<AssetPageStepper />}
      statusId={data?.status}
      statuses={statuses}
      disabled={readonly}
    >
      {children}
    </DocView>
  );
};

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.COMMENTS, META_VIEW_TABS.TODOS];
