import { Box, Chip, CircularProgress } from "@mui/material";
import { defaultRolesWithoutGlobal, useUserRoles } from "app/contexts/role-context";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useTranslation } from "react-i18next";
import colors from "theme/palette/colors";

const sx = {
  background: colors.grey.grey200,
  color: colors.grey.grey500,
  textTransform: "uppercase",
  fontWeight: 500,
  fontSize: "10px",
  height: "24px"
};

const UserOverviewBrickRole = ({ item }: OverviewRowBrickProps) => {
  const { t } = useTranslation("manage-user-page");
  const { roles, rolesLoaded } = useUserRoles();

  if (item?.userRole === "externalUser") {
    return <Chip label={t(`add_participant:participant_type_external`).toLocaleUpperCase()} sx={sx} />;
  } else if (defaultRolesWithoutGlobal.includes(item?.userRole)) {
    return <Chip label={t(item?.userRole)} sx={sx} />;
  } else {
    if (rolesLoaded) {
      return <Chip label={roles.find(({ id }) => id === item?.userRole)?.name || item?.userRole} sx={sx} />;
    } else {
      return <CircularProgress sx={{ width: "24px", marginLeft: "16px", color: colors.grey.grey600 }} size={"small"} />;
    }
  }
};

export default UserOverviewBrickRole;
