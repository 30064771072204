import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import stopEvent from "../../../../tool/stopEvent";
import React, { useCallback, useState } from "react";
import { OrganizationMoveModal } from "../../../../app/pages/organization/OrganizationMoveModal";
import DriveFileMoveIcon from "../../../../assets/images/icons/driveFileMove.svg";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../../app/features";

const OrganizationOverviewBrickMerge = ({ item, hovered, checkedItems, onBlur }: OverviewRowBrickProps) => {
  const { t } = useTranslation("organisationManagement");

  const [selectedOrgUnitId, setSelectedOrgUnitId] = useState<string | null>(null);

  const onMergeAction = useCallback(
    event => {
      setSelectedOrgUnitId(item.id);
      stopEvent(event);
    },
    [item.id]
  );
  const onCancel = useCallback(() => {
    if (onBlur) {
      setSelectedOrgUnitId(null);
      onBlur();
    }
  }, [onBlur]);
  const onClose = useCallback(() => {
    if (onBlur) {
      setSelectedOrgUnitId(null);
      onBlur();
    }
  }, [onBlur]);

  const moveFeatureEnabled = !useIsFeaturePresent(FEATURES.ORG_ACTION_MOVE_DISABLED);
  const itemHasNoMoveAction = item.disableActions?.find(action => action.action === "move");
  const moveDisabled = checkedItems && checkedItems?.length > 0;

  return (
    <>
      {hovered && !itemHasNoMoveAction && moveFeatureEnabled && (
        <>
          <Tooltip title={moveDisabled ? t("disableMoveTooltip") : t("documentCenter:move")}>
            <IconButton onClick={onMergeAction} style={{ opacity: moveDisabled ? 0.5 : 1 }} size="small">
              <DriveFileMoveIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {selectedOrgUnitId && <OrganizationMoveModal selectedItems={[item]} onCancel={onCancel} onClose={onClose} />}
    </>
  );
};

export default OrganizationOverviewBrickMerge;
