import { Box, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";

import { expandOrgUnitsApi, moveOrgUnitsApi } from "../../api/orgUnitApi";
import ResponsibleOrgUnitQuestion from "../../../components/Question/types/orgUnits/ResponsibleOrgUnitQuestion";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "../../contexts/overview-context";
import { COLLECTIONS } from "../../collections";
import { OverviewItem } from "../../../components/Overview/controllers/overviewBaseController";
import TextBody2 from "../../../components/TextBody2/TextBody2";
import { useUserDepartments } from "../../contexts/department-context";
import { useSnackbar } from "notistack";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";

export interface OrgUnitMergeModalProps {
  readonly selectedItems: OverviewItem[];
  readonly onClose: () => void;
  readonly onCancel: () => void;
}
export const OrganizationMoveModal = ({ selectedItems, onClose, onCancel }: OrgUnitMergeModalProps) => {
  const { t } = useTranslation("organisationManagement");
  const { enqueueSnackbar } = useSnackbar();

  const { reloadDepartments, expandChildrenIds } = useUserDepartments();
  const [departmentsLoaded, setDepartmentsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!departmentsLoaded) {
      reloadDepartments();
      setDepartmentsLoaded(true);
    }
  }, [departmentsLoaded, reloadDepartments]);

  const [moveToOrgUnitId, setMoveToOrgUnitId] = useState<string | null>(null);
  const [notAllowedToMoveIntoIds, setNotAllowedToMoveIntoIds] = useState<string[]>([]);

  // we do technically not move selected children if parent is selected to maintain current structure
  const [orgUnitsToBeMoved, setOrgUnitsToBeMoved] = useState<OverviewItem[]>([]);

  const getExpandedOrgUnitIds = useCallback(async () => {
    const selectedItemIds = selectedItems.map(item => item.id);
    const expandedOrgUnitIds = await expandOrgUnitsApi(selectedItemIds);
    const expandedChildrenIds = expandChildrenIds(selectedItemIds);
    setNotAllowedToMoveIntoIds(expandedOrgUnitIds.concat(selectedItemIds));
    setOrgUnitsToBeMoved(selectedItems.filter(item => !expandedChildrenIds.has(item.id)));
  }, [expandChildrenIds, selectedItems]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      getExpandedOrgUnitIds();
    }
  }, [getExpandedOrgUnitIds, selectedItems.length]);

  const dispatch = useOverviewDispatch();

  const reload = useCallback(() => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now(),
      checkedIds: []
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.ORGANIZATIONS, reload });
  }, [dispatch]);

  const moveOrgUnits = useCallback(async () => {
    if (moveToOrgUnitId && selectedItems.length > 0) {
      await moveOrgUnitsApi(
        {
          toMoveOrgUnitIds: orgUnitsToBeMoved.map(item => item.id),
          targetOrgUnitId: moveToOrgUnitId
        },
        {
          wait: true
        }
      );
    }
    reload();
    onClose();
    enqueueSnackbar(`${t("snackbarMessage:actionCompleted")}`, { variant: "success" });
  }, [enqueueSnackbar, moveToOrgUnitId, onClose, orgUnitsToBeMoved, reload, selectedItems.length, t]);

  const [buttons, setButtons] = useState<ConfirmationModalButtonProps[]>([]);

  // we display all items that are selected but technically we dont move children if parent is selected
  const itemsNumberEl = (
    <>
      <Box display={"flex"}>
        <TextBody2 text={t("moveNumberNew")} />
        <Box ml={0.5} />
        <Tooltip
          title={selectedItems.map(item => (
            <span key={item.path}>
              {item.path} <br />
            </span>
          ))}
        >
          <Typography variant={"body2"} color={"primary"}>
            {"" + selectedItems.length}
          </Typography>
        </Tooltip>
      </Box>
    </>
  );
  useEffect(
    () =>
      setButtons([
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCancel
        },
        {
          disabled: !moveToOrgUnitId,
          confirmButton: true,
          title: t("documentCenter:move"),
          variant: "contained",
          color: "primary",
          size: "medium",
          onClick: moveOrgUnits
        }
      ]),
    [moveOrgUnits, moveToOrgUnitId, onCancel, onClose, t]
  );

  const modalBody = (
    <Box mt={3} mb={4}>
      <Box mt={3}>
        <ResponsibleOrgUnitQuestion
          value={moveToOrgUnitId}
          multiSelect={false}
          allowAdd={false}
          onChange={setMoveToOrgUnitId}
          blackListedIds={notAllowedToMoveIntoIds}
          questionName={t("moveTo")}
          noDefaultValue={true}
        />
      </Box>
      <Box mt={2}>
        <CustomAlert title={t("beforeContinue")} severity={"warning"}>
          {t("moveWarningNew")}
        </CustomAlert>
      </Box>
    </Box>
  );

  return (
    <ConfirmationModal
      modalOpen={selectedItems.length > 0}
      onClose={onCancel}
      modalTitle={t("moveTitle")}
      modalText={itemsNumberEl}
      buttons={buttons}
      modalBody={modalBody}
    />
  );
};
