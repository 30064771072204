import { Box, Button, Checkbox, Chip, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { useResources } from "app/contexts/resource-context";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import colors from "theme/palette/colors";
import { ChevronRight, SearchOutlined } from "@mui/icons-material";
import stopEvent from "tool/stopEvent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getBoldSearchText } from "tool/search";
import OverviewNoItemsFound from "components/Overview/controls/OverviewNoItemsFound";

const sx = {
  root: {
    height: "600px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  groupBy: {
    my: 0.5,
    display: "flex",
    gap: 0.5
  },
  search: {
    width: "100%",
    mb: 2
  },
  list: {
    flex: 1,
    overflow: "auto"
  },
  row: {
    display: "flex",
    borderBottom: `1px solid ${colors.divider}`,
    cursor: "pointer",
    py: 0.5,
    pl: 0.5,
    pr: 1,
    alignItems: "center",
    "&:hover": {
      background: colors.grey.grey100
    }
  },
  title: {
    ml: 0.5
  },
  children: {
    mb: 3
  },
  totalCountZero: { backgroundColor: colors.grey.grey100 },
  totalCountNonZero: { backgroundColor: colors.blue.blue100 },
  totalCount: {
    borderRadius: "16px",
    padding: 0.5,
    textAlign: "center",
    width: "32px",
    opacity: 0.8
  }
};
interface MeasureRowProps {
  readonly item: { readonly id: string; readonly name: string };
  readonly checked: boolean;
  readonly search?: string;
  readonly pl?: number;
  readonly onChecked?: (id: string) => void;
}
const MeasureRow = ({ item, checked, search, pl, onChecked }: MeasureRowProps) => {
  const { name, id } = item;
  const onCheckedCallback = useCallback(
    event => {
      stopEvent(event);
      onChecked?.(id);
    },
    [id, onChecked]
  );

  return (
    <Box sx={{ ...sx.row, pl: pl || 0.5 }} onClick={onCheckedCallback}>
      <Checkbox checked={checked} />
      <Box sx={sx.title}>
        <Typography variant="body2">{getBoldSearchText({ title: name, search })}</Typography>
      </Box>
    </Box>
  );
};
interface MeasureGroupByRowProps {
  readonly search?: string;
  readonly checked?: boolean;
  readonly id: string;
  readonly name: string;
  readonly emptyTooltip?: string;
  readonly selectedTomIds: string[];
  readonly options: OptionProps[];
  readonly pl?: number;

  readonly onChecked?: (id: string, force?: boolean) => void;
  readonly onCheckedTom: (id: string, force?: boolean) => void;
}

const MeasureGroupByRow = ({
  search,
  id,
  name,
  checked,
  emptyTooltip,
  selectedTomIds,
  options,

  onChecked,
  onCheckedTom
}: MeasureGroupByRowProps) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(search !== "");
  }, [search]);

  const filteredOptions = useMemo(() => {
    const searchLowerCase = search?.trim().toLowerCase() || "";
    return options.filter(
      option =>
        option.name.toLowerCase().indexOf(searchLowerCase) > -1 || name.toLowerCase().indexOf(searchLowerCase) > -1
    );
  }, [name, options, search]);

  const onCheckedCallback = useCallback(
    event => {
      stopEvent(event);

      // check group
      onChecked?.(id);

      // check all children
      options.forEach(({ id }) => {
        onCheckedTom(id, !checked);
      });
    },
    [checked, id, onChecked, onCheckedTom, options]
  );

  const onExpandCallback = useCallback(() => {
    if (!filteredOptions.length) {
      return;
    }
    setExpanded(current => !current);
  }, [filteredOptions.length]);

  const indeterminate = useMemo(
    () => !checked && filteredOptions.some(({ id }) => selectedTomIds.includes(id)),
    [checked, filteredOptions, selectedTomIds]
  );

  if (search && !filteredOptions.length && name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
    return <></>;
  }

  return (
    <Box>
      <Tooltip title={filteredOptions.length ? "" : emptyTooltip}>
        <Box sx={sx.row} onClick={onExpandCallback}>
          <IconButton size="small" disabled={!filteredOptions.length}>
            {expanded ? <ExpandMoreIcon /> : <ChevronRight />}
          </IconButton>
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onClick={onCheckedCallback}
            disabled={!filteredOptions.length}
          />
          <Typography sx={{ flex: 1 }}>{getBoldSearchText({ title: name, search })}</Typography>
          <Tooltip title={t("totalCount")}>
            <Typography
              variant="caption"
              sx={{ ...sx.totalCount, ...(filteredOptions.length ? sx.totalCountNonZero : sx.totalCountZero) }}
            >
              {filteredOptions.length || 0}
            </Typography>
          </Tooltip>
        </Box>
      </Tooltip>
      <Box sx={{ ...sx.children, display: expanded ? "block" : "none" }}>
        <MeasureProcessSpecificGroupRow
          id={"general_measure"}
          name={t("dpia_four_four_page:general_measure")}
          pl={2}
          search={search}
          options={filteredOptions.filter(({ processSpecific }) => !processSpecific)}
          selectedTomIds={selectedTomIds}
          checked={false}
          emptyTooltip={t("measuresPickerModal:noGeneralTomLabel")}
          onChecked={onCheckedCallback}
          onCheckedTom={onCheckedTom}
        />
        <MeasureProcessSpecificGroupRow
          id={"process_specific_measure"}
          name={t("dpia_four_four_page:process_specific_measure")}
          pl={2}
          search={search}
          options={filteredOptions.filter(({ processSpecific }) => processSpecific)}
          selectedTomIds={selectedTomIds}
          checked={false}
          emptyTooltip={t("measuresPickerModal:noProcessingSpecificTom")}
          onChecked={onCheckedCallback}
          onCheckedTom={onCheckedTom}
        />
      </Box>
    </Box>
  );
};

const MeasureProcessSpecificGroupRow = ({
  name,
  options,
  search,
  selectedTomIds,
  onCheckedTom,
  emptyTooltip,
  pl
}: MeasureGroupByRowProps) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(Boolean(options.length && options.every(({ id }) => selectedTomIds.includes(id))));
  }, [options, selectedTomIds]);

  const indeterminate = useMemo(
    () => !checked && options.some(({ id }) => selectedTomIds.includes(id)),
    [checked, options, selectedTomIds]
  );

  const toggle = useCallback(() => options.length && setExpanded(current => !current), [options.length]);
  const onCheckedCallback = useCallback(
    event => {
      stopEvent(event);
      options.forEach(({ id }) => {
        onCheckedTom(id, !checked);
      });
    },
    [checked, onCheckedTom, options]
  );

  return (
    <Box>
      <Tooltip title={options.length ? "" : emptyTooltip}>
        <Box sx={sx.row} onClick={toggle}>
          <Box pl={pl} />
          <IconButton size="small" disabled={!options.length}>
            {expanded ? <ExpandMoreIcon /> : <ChevronRight />}
          </IconButton>
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            disabled={!options.length}
            onClick={onCheckedCallback}
          />
          <Typography sx={{ flex: 1 }}>{name}</Typography>
          <Tooltip title={t("totalCount")}>
            <Typography
              variant="caption"
              sx={{ ...sx.totalCount, ...(options.length ? sx.totalCountNonZero : sx.totalCountZero) }}
            >
              {options.length || 0}
            </Typography>
          </Tooltip>
        </Box>
      </Tooltip>

      <Box sx={{ ...sx.children, display: expanded ? "block" : "none" }}>
        {options.map(tom => (
          <MeasureRow
            pl={!pl ? 2 : 8}
            key={tom.id}
            item={tom}
            checked={selectedTomIds.includes(tom.id)}
            search={search}
            onChecked={onCheckedTom}
          />
        ))}
      </Box>
    </Box>
  );
};

interface OptionProps {
  readonly name: string;
  readonly id: string;
  readonly labelIds: string[];
  readonly protectionObjectiveIds: string[];
  readonly processSpecific: boolean;
}
export interface MeasuresPickerModalProps {
  readonly open: boolean;
  readonly selectedIds: string[];
  readonly orgUnitIds?: string[];
  readonly blackListedIds?: string[];
  readonly allowAdd?: boolean;
  readonly options: OptionProps[];

  readonly onConfirm: (ids: string[]) => void;
  readonly onCancel: () => void;
  readonly onAdd?: (name: string) => void;
}
const MeasuresPickerModal = ({
  open,
  selectedIds,
  allowAdd,
  options,

  onAdd,
  onConfirm,
  onCancel
}: MeasuresPickerModalProps) => {
  const { t } = useTranslation("measuresPickerModal");
  const { translateById, resources } = useResources();

  const [search, setSearch] = useState<string>("");
  const [groupBy, setGroupBy] = useState<"label" | "protectionObjective" | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTomIds, setSelectedTomIds] = useState<string[]>([]);
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);
  const [selectedProtectionObjectiveIds, setSelectedProtectionObjectiveIds] = useState<string[]>([]);

  const filteredOptions = useMemo<OptionProps[]>(() => {
    const searchLowerCase = search.trim().toLowerCase();
    return options?.filter(({ name }) => name.toLocaleLowerCase().indexOf(searchLowerCase) > -1);
  }, [options, search]);

  const getSelectedIds = useCallback(async () => {
    const allIds: string[] = [...selectedTomIds];
    return allIds;
  }, [selectedTomIds]);

  useEffect(() => {
    setSelectedTomIds(selectedIds);
  }, [selectedIds]);

  /* SEARCH EL */
  const onSearchChange = useCallback(event => {
    setSearch(event.target.value);
  }, []);
  const searchEl = useMemo(() => {
    return (
      <TextField
        sx={sx.search}
        value={search}
        onChange={onSearchChange}
        placeholder={t("common:search")}
        InputProps={{
          startAdornment: (
            <SearchOutlined
              sx={{
                color: colors.grey.grey600,
                marginRight: "8px"
              }}
            />
          )
        }}
      />
    );
  }, [onSearchChange, search, t]);

  /* GROUP BY EL */
  const onSetGroupByLabel = useCallback(() => {
    setGroupBy(groupBy === "label" ? null : "label");
    setSearch("");
  }, [groupBy]);
  const onSetGroupByProtectionObjective = useCallback(() => {
    setGroupBy(groupBy === "protectionObjective" ? null : "protectionObjective");
    setSearch("");
  }, [groupBy]);
  const groupByEl = useMemo(() => {
    return (
      <Box sx={sx.groupBy}>
        <Tooltip title={groupBy === "label" ? t("unGroupByLabel") : t("groupByLabel")}>
          <Chip label={t("label")} onClick={onSetGroupByLabel} color={groupBy === "label" ? "primary" : "secondary"} />
        </Tooltip>
        <Tooltip
          title={
            groupBy === "protectionObjective" ? t("unGroupByProtectionObjective") : t("groupByProtectionObjective")
          }
        >
          <Chip
            label={t("protectionObjective")}
            onClick={onSetGroupByProtectionObjective}
            color={groupBy === "protectionObjective" ? "primary" : "secondary"}
          />
        </Tooltip>
      </Box>
    );
  }, [groupBy, onSetGroupByLabel, onSetGroupByProtectionObjective, t]);

  /* LIST ALL EL */
  const onCheckTom = useCallback((id: string, force?: boolean) => {
    if (force !== undefined) {
      setSelectedTomIds(current => (force ? [...new Set([...current, id])] : current.filter(_id => _id !== id)));
    } else {
      setSelectedTomIds(current => (current.includes(id) ? current.filter(i => i !== id) : [...current, id]));
    }
  }, []);

  const listAllToms = useMemo(() => {
    if (groupBy !== null) {
      return <></>;
    }
    return (
      <Box sx={sx.list}>
        <MeasureProcessSpecificGroupRow
          id={"general_measure"}
          name={t("dpia_four_four_page:general_measure")}
          options={filteredOptions.filter(({ processSpecific }) => !processSpecific)}
          search={search}
          selectedTomIds={selectedTomIds}
          emptyTooltip={t("measuresPickerModal:noGeneralTomLabel")}
          onCheckedTom={onCheckTom}
        />
        <MeasureProcessSpecificGroupRow
          id={"process_specific_measure"}
          name={t("dpia_four_four_page:process_specific_measure")}
          options={filteredOptions.filter(({ processSpecific }) => processSpecific)}
          search={search}
          selectedTomIds={selectedTomIds}
          emptyTooltip={t("measuresPickerModal:noProcessingSpecificTom")}
          onCheckedTom={onCheckTom}
        />
      </Box>
    );
  }, [groupBy, t, filteredOptions, search, selectedTomIds, onCheckTom]);

  /* LIST GROUP BY LABEL */
  const labels = useMemo(() => {
    return (
      resources[RESOURCE_TYPES.LABEL]?.map(r => ({
        id: r.id,
        name: translateById(RESOURCE_TYPES.LABEL, r.id)
      })) || []
    );
  }, [resources, translateById]);
  const onCheckLabel = useCallback((id: string, force?: boolean) => {
    if (force !== undefined) {
      setSelectedLabelIds(current => (force ? [...new Set([...current, id])] : current.filter(_id => _id !== id)));
    } else {
      setSelectedLabelIds(current => (current.includes(id) ? current.filter(i => i !== id) : [...current, id]));
    }
  }, []);
  const listGroupByLabelEl = useMemo(() => {
    if (groupBy !== "label") {
      return <></>;
    } else {
      return (
        <Box sx={sx.list}>
          {labels.map(label => (
            <MeasureGroupByRow
              key={label.id}
              search={search}
              id={label.id}
              name={label.name}
              onChecked={onCheckLabel}
              onCheckedTom={onCheckTom}
              checked={selectedLabelIds.includes(label.id)}
              selectedTomIds={selectedTomIds}
              emptyTooltip={t("noTomLabel")}
              options={options.filter(({ labelIds }) => labelIds.includes(label.id))}
            />
          ))}
        </Box>
      );
    }
  }, [groupBy, labels, search, onCheckLabel, onCheckTom, selectedLabelIds, selectedTomIds, t, options]);

  /* LIST GROUP BY PROTECTION OBJECTIVE */
  const protectionObjectives = useMemo(() => {
    return (
      resources[RESOURCE_TYPES.PROTECTION_OBJECTIVE]?.map(po => ({
        id: po.id,
        name: translateById(RESOURCE_TYPES.PROTECTION_OBJECTIVE, po.id)
      })) || []
    );
  }, [resources, translateById]);
  const onCheckProtectionObjective = useCallback((id: string, force?: boolean) => {
    if (force !== undefined) {
      setSelectedProtectionObjectiveIds(current =>
        force ? [...new Set([...current, id])] : current.filter(_id => _id !== id)
      );
    } else {
      setSelectedProtectionObjectiveIds(current =>
        current.includes(id) ? current.filter(i => i !== id) : [...current, id]
      );
    }
  }, []);
  const protectionObjectiveGroupByLabelEl = useMemo(() => {
    if (groupBy !== "protectionObjective") {
      return <></>;
    } else {
      return (
        <Box sx={sx.list}>
          {protectionObjectives.map(po => (
            <MeasureGroupByRow
              key={po.id}
              search={search}
              id={po.id}
              name={po.name}
              onChecked={onCheckProtectionObjective}
              onCheckedTom={onCheckTom}
              checked={selectedProtectionObjectiveIds.includes(po.id)}
              selectedTomIds={selectedTomIds}
              emptyTooltip={t("noTomProtectionObjective")}
              options={options.filter(({ protectionObjectiveIds }) => protectionObjectiveIds.includes(po.id))}
            />
          ))}
        </Box>
      );
    }
  }, [
    groupBy,
    protectionObjectives,
    search,
    onCheckProtectionObjective,
    onCheckTom,
    selectedProtectionObjectiveIds,
    selectedTomIds,
    t,
    options
  ]);

  /* ADD NEW   */
  const onAddCallback = useCallback(async () => {
    onAdd?.(search);
  }, [onAdd, search]);

  const addNewButtonEl = useMemo(() => {
    if (allowAdd && groupBy === null && search && filteredOptions?.length === 0) {
      return (
        <Box textAlign={"center"}>
          <Button color="primary" variant="outlined" onClick={onAddCallback}>
            {t("multipicker:add_new")}
          </Button>
        </Box>
      );
    }

    return <></>;
  }, [allowAdd, filteredOptions?.length, groupBy, onAddCallback, search, t]);

  /* NO ITEMS */
  const noItemsEl = useMemo(() => {
    if (groupBy === null || search === "" || loading) {
      return <></>;
    } else {
      const anyAllToms = options.some(o => o.name.toLowerCase().includes(search.toLowerCase()));
      if (anyAllToms) return <></>;

      const anyLabels = labels.some(o => o.name.toLowerCase().includes(search.toLowerCase()));
      if (anyLabels) return <></>;

      const anyPOs = protectionObjectives.some(o => o.name.toLowerCase().includes(search.toLowerCase()));
      if (anyPOs) return <></>;

      return <OverviewNoItemsFound align={"center"} />;
    }
  }, [groupBy, labels, loading, options, protectionObjectives, search]);

  const bodyEl = useMemo(() => {
    return (
      <Box sx={sx.root}>
        {groupByEl}
        {searchEl}
        {noItemsEl}
        {addNewButtonEl}
        {listAllToms}
        {listGroupByLabelEl}
        {protectionObjectiveGroupByLabelEl}
      </Box>
    );
  }, [
    groupByEl,
    searchEl,
    noItemsEl,
    addNewButtonEl,
    listAllToms,
    listGroupByLabelEl,
    protectionObjectiveGroupByLabelEl
  ]);

  /* ACTIONS */
  const cleanUp = useCallback(() => {
    setSearch("");
    setGroupBy(null);
    setSelectedTomIds([]);
    setSelectedLabelIds([]);
    setSelectedProtectionObjectiveIds([]);
  }, []);

  const onCancelCallback = useCallback(() => {
    cleanUp();
    onCancel?.();
  }, [cleanUp, onCancel]);

  const onConfirmCallback = useCallback(async () => {
    setLoading(true);
    const allIds = await getSelectedIds();

    onConfirm?.(allIds);
    cleanUp();
  }, [cleanUp, getSelectedIds, onConfirm]);

  const buttons: ConfirmationModalButtonProps[] = [
    {
      confirmButton: false,
      title: t("common:cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      onClick: onCancelCallback
    },
    {
      confirmButton: true,
      title: t("common:confirm"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: onConfirmCallback
    }
  ];
  return (
    <ConfirmationModal
      modalOpen={open}
      onClose={onCancel}
      modalTitle={t("collection:tom_cap")}
      modalText={""}
      buttons={buttons}
      modalBody={bodyEl}
      variant={"info"}
    />
  );
};

export default MeasuresPickerModal;
