import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthenticationPageTemplate, { AuthenticationAlert } from "./AuthenticationPageTemplate";
import { useNavigate } from "react-router-dom";
import { useHashFragments } from "app/router/router-custom-hooks";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { softCheckJWTTokenExpired } from "../../handlers/authentication/jwtTokenSoftParse";
import { WrongCredentials } from "../../handlers/authentication/authenticationError";
import { Box, Button } from "@mui/material";
import { clearRedirectFullPathSession, getRedirectFullPath } from "../../handlers/authentication/redirectStorage";

export default function SSOLoginCallback() {
  const navigate = useNavigate();
  const { ssoCallbackHook, changeTenantHook } = useAuthentication();
  const { ssoLoginToken, ssoErrorCode, tenantId: tenantIdFragment } = useHashFragments();
  const { t } = useTranslation("authentication");

  const [ssoSuccessful, setSSOSuccessful] = useState(false);
  const [alertNotification, setAlertNotification] = useState<AuthenticationAlert | null>(null);

  const { catchAsSnackbar } = useErrorSnackbar();
  useEffect(() => {
    if (!ssoSuccessful) {
      return;
    }
    if (tenantIdFragment) {
      changeTenantHook(tenantIdFragment)
        .catch(catchAsSnackbar("Failed to select passed tenant"))
        .then(() => {
          navigate("/processes");
        });
      return;
    }
    getRedirectFullPath().then(redirectPath => {
      clearRedirectFullPathSession();
      navigate(redirectPath || "/processes");
    });
  }, [catchAsSnackbar, tenantIdFragment, ssoSuccessful, changeTenantHook, navigate]);

  useEffect(() => {
    if (ssoErrorCode === "no-email") {
      setAlertNotification({
        title: t("sso_no_email"),
        severity: "error"
      });
      return;
    }
    if (ssoErrorCode === "no-user") {
      setAlertNotification({
        title: t("sso_no_user"),
        severity: "info"
      });
      return;
    }
    if (ssoErrorCode === "mapping-but-no-groups") {
      setAlertNotification({
        title: t("sso_mapping_no_groups"),
        severity: "info"
      });
      return;
    }
    if (ssoErrorCode === "mapping-but-no-orgunits") {
      setAlertNotification({
        title: t("sso_mapping_no_orgunits"),
        severity: "info"
      });
      return;
    }

    const tokenExpired = softCheckJWTTokenExpired(ssoLoginToken);
    if (tokenExpired) {
      setAlertNotification({
        title: t("error_link_expired"),
        severity: "error"
      });
      return;
    }

    setAlertNotification(null);
  }, [ssoLoginToken, ssoErrorCode, navigate, t]);

  useEffect(() => {
    if (!ssoLoginToken) {
      return;
    }

    ssoCallbackHook(ssoLoginToken)
      .then(() => {
        setAlertNotification({
          title: t("login_successful"),
          severity: "success"
        });
        setSSOSuccessful(true);
      })
      .catch(error => {
        if (error instanceof WrongCredentials) {
          setAlertNotification({
            title: t("error_link_expired"),
            severity: "error"
          });
          return;
        }
        throw error;
      });
  }, [ssoCallbackHook, ssoLoginToken, t, navigate]);

  const toLoginPage = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  return (
    <AuthenticationPageTemplate alertNotification={alertNotification}>
      {alertNotification && !ssoSuccessful && (
        <Box mt={2} mb={1} display="flex" justifyContent="center">
          <Button color="primary" onClick={toLoginPage}>
            {t("back_to_login")}
          </Button>
        </Box>
      )}
    </AuthenticationPageTemplate>
  );
}
