import { Backdrop, Box, CircularProgress, Grid, Tooltip } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { getRedirectToDocument } from "app/api/owlitApi";
import BookPlaceholderSrc from "../assets/images/owlit/book_placeholder.png";
import { useAwayNavigation } from "./NavigateAway";
import { useTranslation } from "react-i18next";
import { sidebarZIndex } from "../app/pages/shared/Sidebar/sidebarZIndex";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system/styleFunctionSx";

export const OwlitMetaViewEntry = ({
  imageData,
  title,
  snippets,
  documentId,
  authors,
  breadcrumbs,
  decidedAt,
  publishedAt
}: {
  readonly imageData?: string | null;
  readonly title?: string;
  readonly snippets?: string[];
  readonly documentId: string;
  readonly authors?: string[];
  readonly breadcrumbs?: string[];
  readonly decidedAt?: string | null;
  readonly publishedAt?: string | null;
}) => {
  const { t } = useTranslation();
  const defaultImageOnError = useCallback(error => {
    if (error.currentTarget) {
      error.currentTarget.onerror = null; // prevents looping
      error.currentTarget.src = BookPlaceholderSrc;
    }
  }, []);

  const [isOpeningLink, setIsOpeningLink] = useState(false);
  const { setLink } = useAwayNavigation();

  const viewDocument = useCallback(() => {
    const viewInNewTab = async () => {
      setIsOpeningLink(true);
      try {
        const redirectLink = await getRedirectToDocument(documentId);
        setLink(redirectLink);
      } catch (error) {
        setIsOpeningLink(false);
        throw error;
      }

      setIsOpeningLink(false);
    };

    viewInNewTab();
  }, [documentId, setLink]);

  const style = useMemo(() => ({ cursor: "pointer" }), []);

  return (
    <Box py={1} my={1} onClick={viewDocument} style={style}>
      <Grid container justifyContent="space-between" alignItems="flex-start" spacing={1}>
        <Grid item>
          <Box sx={{ height: 120, width: 100 }}>
            {imageData && <img src={imageData} alt={title} onError={defaultImageOnError} />}
          </Box>
        </Grid>
        <Grid item xs={true} zeroMinWidth>
          <Grid container spacing={1}>
            <Grid item xs={12} zeroMinWidth style={{ wordBreak: "break-word" }}>
              <Tooltip title={(title || "").trim()}>
                <Typography sx={titleSx} variant="h5">
                  {(title || "").trim()}
                </Typography>
              </Tooltip>
            </Grid>
            {(authors || []).length > 0 && (
              <Grid item xs={12} zeroMinWidth style={{ wordBreak: "break-word" }}>
                {`${t("owlit:authors")}: ${(authors || []).join("; ")}`}
              </Grid>
            )}
            {publishedAt && (
              <Grid item xs={12} zeroMinWidth style={{ wordBreak: "break-word" }}>
                {`${t("owlit:publishedAt")}: ${new Date(publishedAt).toLocaleDateString()}`}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} zeroMinWidth>
          <Grid container spacing={1}>
            {(snippets || []).length > 0 &&
              (snippets || []).map(snippet => (
                <Grid item xs={12} zeroMinWidth style={{ wordBreak: "break-word" }} key={snippet}>
                  <Box sx={{ fontSize: 12 }}>
                    <SafeSnippet snippet={snippet} />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Backdrop sx={{ zIndex: sidebarZIndex + 1 }} open={isOpeningLink}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

const SafeSnippet = ({ snippet }: { snippet: string }) => {
  const parts = snippet.split(/<\/?span.*?>/g);
  const description = parts.map((part, index) => {
    // noinspection UnnecessaryLocalVariableJS
    const everySecondPart = index % 2 === 1;
    // noinspection UnnecessaryLocalVariableJS
    const highlightPart = everySecondPart;

    const reactFragmentKey = `${part}-${index}`;
    if (!highlightPart) {
      return <span key={reactFragmentKey}>{part}</span>;
    }

    return (
      <span key={reactFragmentKey} style={{ fontWeight: "bold" }}>
        {part}
      </span>
    );
  });
  return (
    <Tooltip title={description}>
      <Typography>{description}</Typography>
    </Tooltip>
  );
};

export const titleSx: SxProps = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  "-webkit-line-clamp": 3,
  "-webkit-box-orient": "vertical"
};

OwlitMetaViewEntry.defaultProps = {
  title: "",
  description: "",
  authors: [],
  breadcrumbs: []
};
