import { IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";
import UploadFileIcon from "assets/images/icons/uploadFile.svg";
import { t } from "i18next";

const OverviewBrickUploadTemplate = ({ item, disabled, hovered, onClick, onBlur }: OverviewRowBrickProps) => {
  const onClickCallback = useCallback(
    event => {
      onBlur?.();
      stopEvent(event);
      onClick?.(item.id, item);
    },
    [item, onBlur, onClick]
  );

  if (!hovered) {
    return <></>;
  }
  return (
    <Tooltip title={t("resources_dsr-request-type_overview:uploadToolTipText")}>
      <IconButton disabled={disabled} onClick={onClickCallback} size="small">
        <UploadFileIcon />
      </IconButton>
    </Tooltip>
  );
};

export default OverviewBrickUploadTemplate;
