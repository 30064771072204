// for each tenant different features can be defined that act as feature toggles
// all possible feature toggles are defined in the following
export const FEATURES = {
  DATAFLOW_UPLOAD: "dataFlowUpload",
  PA_TEMPLATES: "paTemplates",
  PA_EXCEL_EXPORT: "paExcelExport",
  PA_ATTACHMENT_EXPORT: "paAttachmentExport",
  EDT_TEMPLATES: "edtTemplates",
  ER_EXPORT: "erExport",
  MEASURES_FEATURE: "measuresFeature",
  TOM_TEMPLATES: "tomTemplates",
  TOM_SDM_TEMPLATES: "tomSDMTemplates",
  DATA_SUBJECT_POTENTIAL_AMOUNT: "dataSubjectPotentialAmount",
  DPA_LEGAL_REVIEW: "DPALegalReview",
  DPA_CHECK_RESULT: "DPAcheckResult",
  DATA_CLASSIFICATION: "dataClassification",
  DEEP_L: "deepLApi",
  DATA_BREACHES: "dataBreaches",
  DSR_BASIC_SUBMISSION: "dsrBasicSubmission",
  DSR_EXPORT: "dsrExport",
  RISKS: "risks",
  RISKS_TEMPLATES: "risksTemplates",
  LANGUAGE_FR: "languageFR",
  LANGUAGE_CZ: "languageCZ",
  LANGUAGE_PL: "languagePL",
  LANGUAGE_EN: "languageEN",
  LANGUAGE_DE: "languageDE",
  LANGUAGE_DECH: "languageDECH",
  LANGUAGE_NL: "languageNL",
  LANGUAGE_FI: "languageFI",
  LANGUAGE_HU: "languageHU",
  LANGUAGE_IT: "languageIT",
  LANGUAGE_KO: "languageKO",
  LANGUAGE_NO: "languageNO",
  LANGUAGE_DA: "languageDA",
  LANGUAGE_PT: "languagePT",
  LANGUAGE_RO: "languageRO",
  LANGUAGE_SK: "languageSK",
  LANGUAGE_SL: "languageSL",
  LANGUAGE_ES: "languageES",
  LANGUAGE_SV: "languageSV",
  LANGUAGE_AR: "languageAR",
  LANGUAGE_BG: "languageBG",
  LANGUAGE_ZH: "languageZH",
  LANGUAGE_ET: "languageET",
  LANGUAGE_EL: "languageEL",
  LANGUAGE_ID: "languageID",
  LANGUAGE_JA: "languageJA",
  LANGUAGE_LV: "languageLV",
  LANGUAGE_LT: "languageLT",
  LANGUAGE_NB: "languageNB",
  LANGUAGE_TR: "languageTR",
  LANGUAGE_UK: "languageUK",
  LANGUAGE_HR: "languageHR",
  CONTACT_INFO_PROFILES: "contactInfoProfiles",
  TASK_SEEN_BY_USER: "taskSeenByUser",
  RISKS_ON_PA_SUBMISSION: "risksOnProcessSubmission",
  OWLIT: "owlit",
  USER_FEATURES: "userFeatures",
  USERGROUPS_FEATURES: "userGroupsFeatures", // user group
  RECURRING_TASK: "recurringTasks",
  LINK_LEGAL_RETENTION_PERIODS_TO_ORG_UNITS: "linkLegalRetentionPeriodsToOrgUnits",
  DELETION_CONCEPT: "deletionConcept",
  ASSETS: "assets",
  WEBSITES: "websites",
  RESOURCES_CUSTOM_CONTRACT_TYPE: "resourceCustomContractType",
  RESOURCES_CUSTOM_REQUEST_TYPE: "resourceCustomRequestType",
  DATA_BREACHES_DATA_SUBJECT_INFORMED: "dataBreachesDataSubjectInformed",
  DATABREACH_CAUSE: "databreach-cause-type",
  DATABREACH_MEASURE: "databreach-measure-type",
  DATABREACH_IMPACT: "databreach-impact-type",
  FILE_UPLOAD_ON_PROCESSING_ACTIVITIES: "fileUploadOnProcessingActivities",
  EXTERNAL_RECIPIENTS_INSIGHT_DATA: "externalRecipientsInsightData",
  WHERE_I_AM: "whereIAm",
  AUDIT: "audits",
  AUDIT_BUILDING_BLOCK_PA_ORG_UNIT_CHECK: "AuditBuildingBlock-PAOrgUnitCheck",
  RESOURCES_SET_LEGAL_BASIS_ART9: "resourceSetLegalBasisArt9",
  RESOURCES_SET_LEGAL_BASIS_KDG: "resourceSetLegalBasisKDG",
  DOCUMENT_CENTER_FOLDERS: "documentCenterFolders",
  IMPLEMENTATION_DATE: "implementationDate",
  END_DATE: "endDate",
  AUDIT_QUESTIONNAIRE_TEMPLATE_DSVGO: "auditQuestionnaireTemplateDSVGO", // all audit questionnaire template features should start with 'auditQuestionnaireTemplate...
  AUDIT_QUESTIONNAIRE_TEMPLATE_MARKETING: "auditQuestionnaireTemplateMarketing",
  AUDIT_QUESTIONNAIRE_TEMPLATE_PERSONAL: "auditQuestionnaireTemplatePersonal",
  DASHBOARD_RECENT_ACTIVITIES: "dashboardRecentActivities",
  TOKEN: "token",
  LAWPILOT_TRAINING: "lawpilotTraining",
  NOTIFICATION_DISABLE_EMAILS: "notificationDisableEmails",
  USERROLES_FEATURES: "userRolesFeatures",
  PUBLIC_REQUESTS: "publicRequests",
  PUBLIC_REQUESTS_MULTI_LINKS: "publicRequestsMultiLinks",
  PUBLIC_REQUESTS_CODE_SNIPPET: "publicRequestsCodeSnippet",
  DATA_SUBJECT_REQUEST: "dataSubjectRequest",
  DATA_PROTECTION_OFFICER_FIELD: "dataProtectionOfficerField",
  TURNOFF_SMARTLOOK: "turnOffSmartLook",
  PROCESSOR_PA_FEATURE: "processorPA",
  RESOURCES_CUSTOM_PROCESSING_CATEGORIES: "resourceCustomProcessingCategories",
  EXPERIMENTAL: "experimental",
  ALWAYS_SELECTABLE_BY_LOWER_ORG_UNITS: "alwaysSelectableByLowerOrgUnit",
  CUSTOM_INFO_CARD: "customInfoCard",
  PUBLIC_ASSESSMENT: "publicAssessment",
  MULTILINGUAL: "multilingual",
  MULTILINGUAL_AUTO_TRANSLATE: "multilingualAutoTranslate",
  ASSET_RISKS: "assetRisks",
  AI_INTEGRATION: "aiIntegration",
  AI_RISK_LEVEL: "paAiRiskLevel",
  AI_ROLE_MANAGEMENT: "aiRoleManagement",
  ASSET_PROCESSES: "assetProcesses",
  ASSET_ERS: "assetERs",
  ASSET_DLS: "assetDLs",
  AI_TECH_DOCS: "aiTechDocs",
  RESOURCE_AI_TRAINING_METHOD: "resourceAITrainingMethod",
  EXTERNAL_USER_ASSESSMENT: "externalUserAssessment",
  PUBLIC_SELF_ASSESSMENT_METHOD: "publicSelfAssessmentMethod",
  ASSESSMENT_REPORT: "assessmentReport",
  OVERVIEW_BRICKS: "overviewBricks",
  USER_HOME_PAGE_AS_DEFAULT: "userHomePageAsDefault",
  USER_HOME_PAGE: "userHomePage",
  USER_REPLACE_DISABLED: "userReplaceDisabled",
  ORG_ACTION_DELETE_DISABLED: "orgActionDeleteDisabled",
  ORG_ACTION_MOVE_DISABLED: "orgActionMoveDisabled",
  ORG_ACTION_MERGE_DISABLED: "orgActionMergeDisabled",
  BUSINESS_PROCESS: "businessProcess",
  BUSINESS_PROCESS_LEGACY: "businessProcessLegacy",
  SECURE_DATA_ROOM: "secureDataRoom"
} as const;

export interface UserFeature {
  readonly id: string;
  readonly dependencies: string[];
}

export const USER_FEATURE_IDS = {
  OWLIT_USER: "owlit-user",
  WEBSITES: "websites-user"
} as const;

export const ALL_USER_FEATURES: UserFeature[] = [
  {
    id: USER_FEATURE_IDS.OWLIT_USER,
    dependencies: [FEATURES.OWLIT]
  },
  {
    id: USER_FEATURE_IDS.WEBSITES,
    dependencies: [FEATURES.WEBSITES]
  }
];

export const enabledUserFeaturesBasedOnTenantFeatures = (tenantFeatureIds: string[]): UserFeature[] => {
  return ALL_USER_FEATURES.filter(userFeature =>
    userFeature.dependencies.every(dependency => tenantFeatureIds.includes(dependency))
  );
};
