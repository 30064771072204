import { AIActPreCheck } from "./AIActPreCheck";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAiPrecheck } from "../../../api/process/processingAiPrecheckApi";
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Checkbox from "@mui/material/Checkbox";
import { SxProps } from "@mui/system/styleFunctionSx";
import Question from "../../../../components/Question/Question";
import { useMetaView } from "../../../contexts/meta-view-context";
import { useProcessPage } from "../../../contexts/process-page-context";

export const AIPreAssessmentPage = ({ readonly }: { readonly readonly?: boolean }) => {
  const { setInfoId } = useMetaView();

  useEffect(() => {
    setInfoId("infocard.pa.pageai.default");
  }, [setInfoId]);

  return (
    <Box>
      <Box mb={4}>
        <AIPreAssessmentQuestions />
      </Box>
      <Box>
        <AIActPreCheck />
      </Box>
    </Box>
  );
};

export const AIPreAssessmentQuestions = () => {
  const { id: paId } = useParams();
  const { t } = useTranslation("ai_pre_assessment");

  const aiPreCheck = useAiPrecheck({ documentId: paId || "" });
  const { resetSignal } = useProcessPage();

  return (
    <Box key={`ai-pre-assessment-${resetSignal}`}>
      <QuestionnaireSubHeader text={t("header")} />
      {aiPreCheck.shownQuestions.map((questionId, index) => (
        <AIPreCheckQuestion key={`${questionId}-${index}`} questionId={questionId} aiPreCheck={aiPreCheck} />
      ))}
    </Box>
  );
};

const AIPreCheckQuestion = ({
  questionId,
  aiPreCheck
}: {
  readonly questionId: string;
  readonly aiPreCheck: ReturnType<typeof useAiPrecheck>;
}) => {
  const { t } = useTranslation("ai_pre_assessment");

  const checkboxQuestion = shouldDisplayAsCheckbox(questionId);
  const checkboxQuestionPreTitle = specialTitleSet[questionId];
  const value = (aiPreCheck as any)[questionId];
  const questionName = t(questionId);

  return (
    <Box width="100%">
      {checkboxQuestion ? (
        <>
          {checkboxQuestionPreTitle ? (
            <Box mt={2} pb={2}>
              <FormLabel>{t(checkboxQuestionPreTitle)}</FormLabel>
            </Box>
          ) : (
            <></>
          )}
          <Question
            questionId={questionId}
            questionName={questionName}
            pt={0}
            pb={1}
            infoId={`infocard.pa.pageai.${questionId}`}
          >
            <FormControlLabel
              control={<Checkbox />}
              label={questionName}
              checked={value === "yes" /* eslint-disable-next-line react/jsx-no-bind */}
              onChange={(event, value) => {
                const stringValue = value ? "yes" : "no";
                aiPreCheck.actions.queueAIPreCheckUpdate({ [questionId]: stringValue });
              }}
            />
          </Question>
        </>
      ) : (
        <Question questionId={questionId} questionName={questionName} infoId={`infocard.pa.pageai.${questionId}`}>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <FormLabel>{t(questionId)}</FormLabel>
            <Box flex={1} />
            <Box flexBasis={318} flexShrink={0} flexGrow={0} display="flex" alignItems="center">
              <RadioGroupQuestion /* eslint-disable-next-line react/jsx-no-bind */
                onChange={(event, value) => {
                  aiPreCheck.actions.queueAIPreCheckUpdate({ [questionId]: value });
                }}
                value={(aiPreCheck as any)[questionId]}
              />
            </Box>
          </Box>
        </Question>
      )}
    </Box>
  );
};

const specialTitleSet: Record<string, string | undefined> = {
  isUsedInBiometricIdentification: "isUsedInTitle",
  isMeasureTransparencyMade: "isMeasuresMadeTitle"
};
const checkboxQuestionIds = new Set([
  "isUsedInBiometricIdentification",
  "isUsedInCriticalInfrastructure",
  "isUsedInEducation",
  "isUsedInRecruitment",
  "isUsedInPublicService",
  "isUsedInLawEnforcement",
  "isUsedInMigration",
  "isUsedInJusticeProcess",
  "isMeasureTransparencyMade",
  "isMeasureHumanControlMade",
  "isMeasureBiasTestingMade",
  "isMeasureDataProtectionMade",
  "isMeasureAuditControlMade",
  "isMeasureEthicsGuideMade"
]);
const shouldDisplayAsCheckbox = (questionId: string) => {
  return checkboxQuestionIds.has(questionId);
};

const RadioGroupQuestion = ({
  onChange,
  value
}: {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  value: string;
}) => {
  const { t } = useTranslation("");
  return (
    <RadioGroup onChange={onChange} value={value} row={true}>
      <FormControlLabel labelPlacement="top" value="yes" control={<Radio />} label={t("fields:yes")} sx={radioSx} />
      <FormControlLabel labelPlacement="top" value="no" control={<Radio />} label={t("fields:no")} sx={radioSx} />
      <FormControlLabel
        labelPlacement="top"
        value="unsure"
        control={<Radio />}
        label={t("fields:unsure")}
        sx={radioSx}
      />
    </RadioGroup>
  );
};
const radioSx: SxProps = {
  width: 74
};

export default AIPreAssessmentPage;
