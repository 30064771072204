import { Typography } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import colors from "theme/palette/colors";

const sx = {
  background: colors.red.red500,
  marginLeft: "8px",
  borderRadius: "16px",
  minWidth: "20px",
  padding: "4px 6px",
  color: colors.white,
  fontSize: "11px",
  lineHeight: "11px",
  textAlign: "center"
};

const OverviewBrickUnseeCount = ({ item }: OverviewRowBrickProps) => {
  return item?.unseenCount ? <Typography sx={sx}>{item.unseenCount}</Typography> : <></>;
};

export default OverviewBrickUnseeCount;
