import { Box } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import colors from "theme/palette/colors";

const sx = {
  root: {
    width: "36px",
    height: "36px",
    textAlign: "center",
    lineHeight: "36px",
    marginTop: "8px",
    marginBottom: "8px",
    borderRadius: "50%",
    color: colors.grey.grey700,
    backgroundColor: colors.grey.grey200
  },
  blue: {
    color: colors.blue.blue500,
    backgroundColor: colors.blue.blue50
  },
  blueHovered: {
    color: colors.blue.blue600,
    backgroundColor: colors.blue.blue200
  }
};

const DeletionConceptOverviewBrickProcessesNumber = ({ item, hovered }: OverviewRowBrickProps) => {
  if (item.processesNumber === undefined) {
    return <></>;
  }
  return (
    <Box sx={{ ...sx.root, ...(item.processesNumber ? (hovered ? sx.blueHovered : sx.blue) : {}) }}>
      {item.processesNumber}
    </Box>
  );
};

export default DeletionConceptOverviewBrickProcessesNumber;
