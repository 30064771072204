import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../../Question";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { UserDTO } from "app/api/user/userApi";
import DpoUserIcon from "assets/images/icons/dpoUser.svg";
import { useUserFilter } from "../../../../app/pages/questionnaires/utils/AssignUsersMultiAutocomplete/useUserFilter";
import { useTranslation } from "react-i18next";

const DpoUserQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onChange,
  onFocus,
  orgUnitIds,
  onlyIntersectingOrgUnitIds
}: QuestionProps) => {
  const { t } = useTranslation();
  const { getUserNameEmailHook, getUsersByAssignableFieldHook } = useUserAndTenantData();
  const { filterUsersByOrgUnitIds } = useUserFilter();
  const [assignableUsers, setAssignableUsers] = useState<UserDTO[]>([]);

  useEffect(() => {
    const init = async () => {
      const users = await getUsersByAssignableFieldHook("privacyExpert");
      if (orgUnitIds?.length) {
        const filteredUsers = filterUsersByOrgUnitIds({
          users,
          orgUnitIds,
          onlyIntersectingOrgUnitIds: !!onlyIntersectingOrgUnitIds
        });
        setAssignableUsers(filteredUsers);
      } else {
        setAssignableUsers(users);
      }
    };
    init();
  }, [filterUsersByOrgUnitIds, getUsersByAssignableFieldHook, onlyIntersectingOrgUnitIds, orgUnitIds]);

  const _value: string = useMemo(() => (Array.isArray(value) ? value[0] : (value as string)), [value]);

  const optionIds: string[] = useMemo(
    () =>
      assignableUsers.reduce<string[]>((acc, next) => {
        if (next.id) {
          acc.push(next.id);
        }
        return acc;
      }, []),
    [assignableUsers]
  );

  const updateSelected = useCallback(
    userId => {
      onChange?.([userId]);
    },
    [onChange]
  );

  return (
    <MultiAutocomplete
      id={"DpoUserQuestion"}
      selected={_value}
      options={optionIds}
      icon={<DpoUserIcon />}
      disableClearable={true}
      freeSolo={false}
      updateSelected={updateSelected}
      getOptionLabel={getUserNameEmailHook}
      disabled={disabled}
      hasMultiSelect={false}
      label={questionName || t("pa_general:dpo")}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
    />
  );
};

export default DpoUserQuestion;
