import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useTranslation } from "react-i18next";
import GroupIcon from "@material-ui/icons/Group";
import StorageIcon from "@material-ui/icons/Storage";
import DescriptionIcon from "@material-ui/icons/Description";
import { DataAssetType } from "app/api/generated/asset-service";
import { Box, Tooltip } from "@mui/material";
import colors from "theme/palette/colors";

const iconMapper: Record<DataAssetType, React.ReactNode> = {
  [DataAssetType.PersonGroup]: <GroupIcon fontSize="small" />,
  [DataAssetType.DataCategory]: <StorageIcon fontSize="small" />,
  [DataAssetType.DataType]: <DescriptionIcon fontSize="small" />
};

const tooltipMapper: Record<DataAssetType, string> = {
  [DataAssetType.PersonGroup]: "personGroup:inputLabel",
  [DataAssetType.DataCategory]: "dataCategory:inputLabel",
  [DataAssetType.DataType]: "dataType:inputLabel"
};

const sx = {
  root: {
    minWidth: "36px",
    width: "36px",
    height: "36px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center"
  },
  green: {
    color: colors.green.green500,
    backgroundColor: colors.green.green50
  },
  blue: {
    color: colors.blue.blue500,
    backgroundColor: colors.blue.blue50
  }
};

const PersonGroupOverviewBrickIcon = ({ item }: OverviewRowBrickProps) => {
  const { t } = useTranslation("");
  const assetType = item.assetType as DataAssetType;

  return (
    <Tooltip title={t(tooltipMapper[assetType])}>
      <Box sx={{ ...sx.root, ...(assetType === DataAssetType.PersonGroup ? sx.green : sx.blue) }}>
        {iconMapper[assetType]}
      </Box>
    </Tooltip>
  );
};

export default PersonGroupOverviewBrickIcon;
