import { useUserDepartments } from "../../contexts/department-context";
import { useCallback, useMemo } from "react";
import { readXLSX, writeXLSX } from "../../handlers/xlsxToJsonhandler";
import { createAndDownloadFile } from "../../export/createAndDownloadFile";
import { Department } from "../../handlers/departmentHandler";

export const useMappingFiles = () => {
  const { departments } = useUserDepartments();

  const departmentsMap = useMemo(() => {
    return new Map(departments.map(department => [department.id, department]));
  }, [departments]);
  const departmentsFullPathMap = useMemo(() => {
    return new Map(departments.map(department => [department.fullName, department]));
  }, [departments]);

  const downloadMappingFile = useCallback(
    async (
      input: {
        readonly externalId: string;
        readonly orgUnitIds: string[];
      }[]
    ) => {
      const mappingAsSheet = input.flatMap(mapping => {
        return mapping.orgUnitIds.flatMap(orgUnitId => {
          const orgUnit = departmentsMap.get(orgUnitId);
          return {
            "External ID": mapping.externalId,
            "Org Unit ID": orgUnitId,
            "Org Unit Full Path": orgUnit?.fullName || "Deleted",
            "Org Unit Name": orgUnit?.name || "Deleted"
          } satisfies Partial<Record<MappingFilesColumn, string>>;
        });
      }, []);

      const departmentsAsSheet = departments.map(department => {
        return {
          "Org Unit ID": department.id,
          "Org Unit Full Path": department.fullName,
          "Org Unit Name": department.name
        } satisfies Partial<Record<MappingFilesColumn, string>>;
      });

      const buffer = await writeXLSX({
        sheets: [
          {
            sheetName: "Group Mapping",
            data: mappingAsSheet,
            columnOrder: [
              "External ID",
              "Org Unit Full Path",
              "Org Unit Name",
              "Org Unit ID"
            ] satisfies MappingFilesColumn[]
          },
          {
            sheetName: "Departments List",
            data: departmentsAsSheet,
            columnOrder: ["Org Unit Full Path", "Org Unit Name", "Org Unit ID"] satisfies MappingFilesColumn[]
          }
        ]
      });

      await createAndDownloadFile(
        new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
        "iam-mapping.xlsx"
      );
    },
    [departments, departmentsMap]
  );

  const parseMappingFile = useCallback(
    async (base64Excel: string) => {
      const parsedFile = await readXLSX(base64Excel, "base64");

      const result: Map<string, string[]> = new Map<string, string[]>();
      // check only group mapping sheet if exists
      if (parsedFile["Group Mapping"]) {
        for (const row of parsedFile["Group Mapping"]) {
          addMapping(row, result, departmentsFullPathMap);
        }
      } else {
        // otherwise just check all sheets
        for (const row of Object.values(parsedFile).flat()) {
          addMapping(row, result, departmentsFullPathMap);
        }
      }

      return [
        ...result.entries().map(([externalId, orgUnitIds]) => ({
          externalId,
          orgUnitIds
        }))
      ];
    },
    [departmentsFullPathMap]
  );

  return {
    downloadMappingFile,
    parseMappingFile
  };
};

const getCleanString = (input: unknown, key: string): string => {
  const valueRaw = typeof input === "object" && input && key in input && (input as any)[key];
  if (typeof valueRaw === "string") {
    return valueRaw.trim();
  }
  return "";
};

const addMapping = (row: unknown, result: Map<string, string[]>, departmentsFullPathMap: Map<string, Department>) => {
  const externalId = getCleanString(row, "External ID" satisfies MappingFilesColumn);
  if (!externalId) {
    return;
  }

  const currentMapping = result.get(externalId) || [];
  result.set(externalId, currentMapping);

  const orgUnitId = getCleanString(row, "Org Unit ID" satisfies MappingFilesColumn);
  if (orgUnitId) {
    currentMapping.push(orgUnitId);
    return;
  }

  const orgUnitFullPath = getCleanString(row, "Org Unit Full Path" satisfies MappingFilesColumn);
  if (orgUnitFullPath) {
    const department = departmentsFullPathMap.get(orgUnitFullPath);
    if (department) {
      currentMapping.push(department.id);
      return;
    }
  }
};

export type MappingFilesColumn = "External ID" | "Org Unit ID" | "Org Unit Name" | "Org Unit Full Path";
