import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import Question from "components/Question/Question";
import { useTranslation } from "react-i18next";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useMetaView } from "app/contexts/meta-view-context";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { BusinessProcessUpdateDTO, BusinessProcessWithPermission } from "app/api/businessProcessApi";
import { updateBusinessProcess } from "app/handlers/businessprocessHandler";
import { Typography } from "@mui/material";
import AttachmentsContainer from "components/AttachmentsOverview/AttachmentsContainer";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";

export const BusinessProcessProcessDescription = ({
  businessProcess,
  onUpdate,
  noWritePermission
}: {
  readonly businessProcess: Partial<BusinessProcessWithPermission> | null;
  readonly onUpdate: () => void;
  readonly noWritePermission: boolean;
}) => {
  const { t } = useTranslation("businessprocess_processdescription");
  const [summaryDescription, setSummaryDescription] = useState<string>("");
  const [detailDescription, setDetailDescription] = useState<string>("");
  const { setInfo, setMeta } = useMetaView();
  const { catchAsSnackbar } = useErrorSnackbar();
  const [businessprocessData, setBusinessprocessData] = useState<Partial<BusinessProcessWithPermission> | null>(null);

  useEffect(() => {
    setBusinessprocessData(businessProcess);
  }, [businessProcess]);

  const infoCard: Record<string, { readonly title: string; readonly text: string }> = useMemo(
    () => ({
      entering: {
        title: t("business_process_overview:enteringInfoCardTitle"),
        text: t("business_process_overview:enteringInfoCardText")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  /* UPDATE BUSINESS PROCESS*/
  const updateData = useCallback(
    async (data: BusinessProcessUpdateDTO) => {
      if (businessprocessData && businessprocessData.id) {
        await updateBusinessProcess(businessprocessData.id, data).catch(catchAsSnackbar());
        onUpdate();
      }
    },
    [businessprocessData, onUpdate, catchAsSnackbar]
  );

  /* businessprocess summary description */
  const onSummaryDescriptionBlur = useCallback(async () => {
    await updateData({ summaryDescription });
  }, [summaryDescription, updateData]);

  /* businessprocess detail description */
  const onDetailDescriptionBlur = useCallback(async () => {
    await updateData({ detailDescription });
  }, [detailDescription, updateData]);

  if (businessProcess) {
    return (
      <>
        <QuestionnaireSubHeader text={t("processDescription")} />
        <Question
          questionId={"summaryDescription"}
          questionName={t("summaryDescription")}
          translatable={businessProcess.summaryDescription || ""}
          translationId={"summaryDescription"}
          title={t("summaryDescription")}
          disabled={noWritePermission}
        >
          <TextEditor
            onBlur={onSummaryDescriptionBlur}
            disabled={noWritePermission}
            onChange={setSummaryDescription}
            inputValue={businessProcess.summaryDescription}
          />
        </Question>

        <Question
          questionId={"detailDescription"}
          questionName={t("detailDescription")}
          translatable={businessProcess.detailDescription || ""}
          translationId={"detailDescription"}
          title={t("detailDescription")}
          disabled={noWritePermission}
        >
          <TextEditor
            onBlur={onDetailDescriptionBlur}
            disabled={noWritePermission}
            onChange={setDetailDescription}
            inputValue={businessProcess.detailDescription}
          />
        </Question>

        <Box>
          <Typography variant="subtitle1">{t("attachments")}</Typography>
          <Box mt={3}>
            <AttachmentsContainer
              disabled={noWritePermission}
              docId={businessProcess.id || ""}
              category="businessprocess"
            />
          </Box>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const cachedEmptyArrayToNotRerender: string[] = [];
