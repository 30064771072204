import { WarningAmber } from "@mui/icons-material";
import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import { useUpdateAITechDocApi } from "app/api/aiTechDocApi";
import { UpdateAITechDocDTOStatusEnum } from "app/api/generated/aitechdoc-service";
import { COLLECTIONS } from "app/collections";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useAITechDocStatuses } from "app/pages/ai-tech-docs/overview/useAITechDocStatuses";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { t } from "i18next";
import { useCallback, useState } from "react";
import stopEvent from "tool/stopEvent";

type ItemStatus = {
  icon: React.ReactNode;
  status: string;
  label?: string;
};

const AITechDocOverviewBrickStatus = ({ item }: OverviewRowBrickProps) => {
  const status = item.status;

  const dispatch = useOverviewDispatch();
  const aiTechDocStatuses: ItemStatus[] = useAITechDocStatuses();
  const statuses = aiTechDocStatuses.map(it => ({
    ...it,
    id: it.status,
    label: it.label || "",
    selected: it.status === item.status
  }));
  const { updateAITechDoc } = useUpdateAITechDocApi({ documentId: item.id || "" });

  const [ref, setRef] = useState<Element | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const reload = useCallback(() => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.AI_TECH_DOCS, reload });
  }, [dispatch]);

  const closeMenuCallback = useCallback(() => {
    setRef(null);
    setOpen(false);
  }, []);

  const openMenuCallback = useCallback(event => {
    stopEvent(event);
    setRef(event?.currentTarget);
    setOpen(true);
  }, []);

  const onChangeCallback = useCallback(
    async (status: string) => {
      closeMenuCallback();
      await updateAITechDoc({ status: status as UpdateAITechDocDTOStatusEnum });
      reload();
    },
    [closeMenuCallback, reload, updateAITechDoc]
  );

  const currentStatus = aiTechDocStatuses.find(it => it.status === status);

  if (!status) {
    return <></>;
  }

  if (status === "documentation-missing") {
    return (
      <Tooltip title={t(`process_aitechdoc_page:no-documentation`)}>
        <Box sx={{ height: "24px" }}>
          <WarningAmber sx={{ color: "warning.main" }} />
        </Box>
      </Tooltip>
    );
  }

  return (
    <ClickAwayListener onClickAway={closeMenuCallback}>
      <>
        <Tooltip title={currentStatus?.label}>
          <Box sx={{ height: "24px" }} onClick={openMenuCallback}>
            {currentStatus?.icon}
          </Box>
        </Tooltip>
        <DropDownMenu
          open={open}
          anchorEl={ref}
          items={statuses}
          onChange={onChangeCallback}
          onClose={closeMenuCallback}
        />
      </>
    </ClickAwayListener>
  );
};

export default AITechDocOverviewBrickStatus;
