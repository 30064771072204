import { useCallback } from "react";
import overviewBaseController, {
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import { getLabelsFilter } from "app/pages/shared/Filters/filters";
import { tDeletedEntry } from "../../../handlers/dataTypeTranslatorHandler";
import { resourcesDecorator } from "../../../../components/Overview/controllers/decorator/resourcesDecorator";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { AxiosInstance, AxiosResponse } from "axios";
import { usersDecorator } from "../../../../components/Overview/controllers/decorator/usersDecorator";
import { getOrganizationFilter } from "../../shared/Filters/OrganizationFilter";

const BusinessProcessOverviewController = (axiosInstance: AxiosInstance) => {
  const { auth } = useAuthentication();
  const { t } = useTranslation();
  const { t: t_filter } = useTranslation("filter_criteria");
  const { t: t_business_process_general_page } = useTranslation("business_process_general_page");

  const translateItem = useCallback(
    obj => ({ ...obj, subTitle: obj.subTitle === "deleted" ? tDeletedEntry({ t }) : obj.subTitle }),
    [t]
  );

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.BUSINESS_PROCESS, translateItem, [
    resourcesDecorator,
    departmentsDecorator,
    usersDecorator
  ]);

  const getFilters = useCallback(
    data => [
      getOrganizationFilter("allOrgUnitIds", data._departments, t, "department"),
      getOrganizationFilter("orgUnitId", data._departments, t, "responsibleDepartment"),
      getOrganizationFilter("furtherOrgUnitIds", data._departments, t, "furtherDepartments"),
      getLabelsFilter("labelIds", data._resources, t)
    ],
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: t_filter("aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: t_filter("zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: t_filter("newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: t_filter("oldFirst")
      }
    ],
    [t_filter]
  );

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup);
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/business-process/${id}/general`);
  };

  const addItem = async (data: OverviewNewItem): Promise<AxiosResponse> => {
    return await baseController.addItem({
      title: data?.title || createOverviewItemDefaultName("business_process"),
      assigneeUID: auth?.uid
    });
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, data);
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await baseController.addItem({
      title: data?.title || createOverviewItemDefaultName("business_process"),
      assigneeUID: auth?.uid
    });
    goToItem(response.headers["x-resource-id"] || "");
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    patchItem,
    addItemAndGo
  };
};

export default BusinessProcessOverviewController;
