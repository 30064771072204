import MultiAutocomplete from "../../../../components/MultiAutocomplete/MultiAutocomplete";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tSimpleTranslate } from "../../../handlers/dataTypeTranslatorHandler";
import { useDataTypeTree } from "app/api/dataAssetApi";

interface DSRPersonGroupFieldProps {
  disabled?: boolean;
  values: string[];
  onBlur?: () => void;
  onChanges: (values: string | string[] | null) => void;
}

export const DSRPersonGroupField: React.FC<DSRPersonGroupFieldProps> = ({
  disabled,
  values = [],
  onChanges,
  onBlur
}) => {
  const { t } = useTranslation();
  const dataTypeTree = useDataTypeTree();

  const getOptionLabel = useCallback(
    (option: string) => {
      const pg = dataTypeTree.dataById?.personGroups?.[option];
      if (!pg) return "";
      return tSimpleTranslate({ t, typeCategoryOrPersonGroup: pg.personGroupKey });
    },
    [t, dataTypeTree.dataById?.personGroups]
  );

  const personGroupIds = useMemo(
    () => Object.keys(dataTypeTree.dataById?.personGroups || {}),
    [dataTypeTree.dataById?.personGroups]
  );

  return (
    <MultiAutocomplete
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      hasMultiSelect={true}
      id="person_groups"
      label={t("personGroup:questionTitle")}
      options={personGroupIds}
      selected={values}
      updateSelected={onChanges}
      onBlur={onBlur}
    />
  );
};
