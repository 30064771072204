import { FEATURES } from "../features";

export const allAvailableLanguages = [
  "en",
  "de",
  "fr",
  "cz",
  "pl",
  "de-CH",
  "da",
  "nl",
  "fi",
  "hu",
  "it",
  "ko",
  "no",
  "pt",
  "ro",
  "sk",
  "sl",
  "es",
  "sv",
  "ar",
  "bg",
  "zh",
  "et",
  "el",
  "id",
  "ja",
  "lv",
  "lt",
  "nb",
  "tr",
  "uk",
  "hr"
] as const;

export const defaultLng = (features: string[]): (typeof allAvailableLanguages)[number] => {
  if (features.includes(FEATURES.LANGUAGE_DECH)) return "de-CH";
  if (features.includes(FEATURES.LANGUAGE_PL)) return "pl";
  if (features.includes(FEATURES.LANGUAGE_FR)) return "fr";
  if (features.includes(FEATURES.LANGUAGE_CZ)) return "cz";
  if (features.includes(FEATURES.LANGUAGE_DE)) return "de";
  if (features.includes(FEATURES.LANGUAGE_DA)) return "da";
  if (features.includes(FEATURES.LANGUAGE_NL)) return "nl";
  if (features.includes(FEATURES.LANGUAGE_FI)) return "fi";
  if (features.includes(FEATURES.LANGUAGE_HU)) return "hu";
  if (features.includes(FEATURES.LANGUAGE_IT)) return "it";
  if (features.includes(FEATURES.LANGUAGE_KO)) return "ko";
  if (features.includes(FEATURES.LANGUAGE_NO)) return "no";
  if (features.includes(FEATURES.LANGUAGE_PT)) return "pt";
  if (features.includes(FEATURES.LANGUAGE_RO)) return "ro";
  if (features.includes(FEATURES.LANGUAGE_SK)) return "sk";
  if (features.includes(FEATURES.LANGUAGE_SL)) return "sl";
  if (features.includes(FEATURES.LANGUAGE_ES)) return "es";
  if (features.includes(FEATURES.LANGUAGE_SV)) return "sv";
  if (features.includes(FEATURES.LANGUAGE_AR)) return "ar";
  if (features.includes(FEATURES.LANGUAGE_BG)) return "bg";
  if (features.includes(FEATURES.LANGUAGE_ZH)) return "zh";
  if (features.includes(FEATURES.LANGUAGE_ET)) return "et";
  if (features.includes(FEATURES.LANGUAGE_EL)) return "el";
  if (features.includes(FEATURES.LANGUAGE_ID)) return "id";
  if (features.includes(FEATURES.LANGUAGE_JA)) return "ja";
  if (features.includes(FEATURES.LANGUAGE_LV)) return "lv";
  if (features.includes(FEATURES.LANGUAGE_LT)) return "lt";
  if (features.includes(FEATURES.LANGUAGE_NB)) return "nb";
  if (features.includes(FEATURES.LANGUAGE_TR)) return "tr";
  if (features.includes(FEATURES.LANGUAGE_UK)) return "uk";
  if (features.includes(FEATURES.LANGUAGE_HR)) return "hr";
  else return "en";
};

export const languageToggles: Record<(typeof allAvailableLanguages)[number], string> = {
  fr: FEATURES.LANGUAGE_FR,
  cz: FEATURES.LANGUAGE_CZ,
  pl: FEATURES.LANGUAGE_PL,
  en: FEATURES.LANGUAGE_EN,
  de: FEATURES.LANGUAGE_DE,
  nl: FEATURES.LANGUAGE_NL,
  fi: FEATURES.LANGUAGE_FI,
  hu: FEATURES.LANGUAGE_HU,
  it: FEATURES.LANGUAGE_IT,
  da: FEATURES.LANGUAGE_DA,
  ko: FEATURES.LANGUAGE_KO,
  no: FEATURES.LANGUAGE_NO,
  pt: FEATURES.LANGUAGE_PT,
  ro: FEATURES.LANGUAGE_RO,
  sk: FEATURES.LANGUAGE_SK,
  sl: FEATURES.LANGUAGE_SL,
  es: FEATURES.LANGUAGE_ES,
  sv: FEATURES.LANGUAGE_SV,
  "de-CH": FEATURES.LANGUAGE_DECH,
  ar: FEATURES.LANGUAGE_AR,
  bg: FEATURES.LANGUAGE_BG,
  zh: FEATURES.LANGUAGE_ZH,
  et: FEATURES.LANGUAGE_ET,
  el: FEATURES.LANGUAGE_EL,
  id: FEATURES.LANGUAGE_ID,
  ja: FEATURES.LANGUAGE_JA,
  lv: FEATURES.LANGUAGE_LV,
  lt: FEATURES.LANGUAGE_LT,
  nb: FEATURES.LANGUAGE_NB,
  tr: FEATURES.LANGUAGE_TR,
  uk: FEATURES.LANGUAGE_UK,
  hr: FEATURES.LANGUAGE_HR
};
