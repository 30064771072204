import { apiEndpoints } from "app/api/apiEndpoint";
import { AssetCreateDTO, AssetUpdateDTO } from "../pages/assets/types/AssetTypes";
import { AssetFilterProps } from "../pages/assets/handler/assetHandler";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { AssetDTO, DefaultApi, UpdateAssetDTO } from "./generated/asset-service";
import useSWR from "swr";
import { useCallback } from "react";
import { RiskDTO } from "./riskApi";
import { MiniProcessingActivityDTO } from "./paApi";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.assetUrl}/api/v1/assets`
});

export async function createApi(payload: AssetCreateDTO) {
  const response = await axiosInstance.post("/", payload);
  return response.headers["x-resource-id"] || "";
}

export async function getSingleApi(id: string) {
  const response = await axiosInstance.get<AssetDTO>(`/${id}`);
  return response ? response.data : null;
}

export async function getManyApi(filter: AssetFilterProps) {
  const response = await axiosInstance.post("/asset-queries", filter);
  return response ? response.data : null;
}

export async function updateApi(id: string, payload: AssetUpdateDTO) {
  const response = await axiosInstance.patch("/" + id, payload);
  return response ? response.data : null;
}

export async function deleteApi(id: string) {
  return await axiosInstance.delete("/" + id);
}

export async function getProcesses(id: string) {
  return await axiosInstance.get(`/${id}/processes`);
}

export async function getNumberUnseenAssetsApi(
  httpOptions: {
    abortController?: AbortController;
  } = {}
) {
  const response = await axiosInstance.get(`/unseen`, { signal: httpOptions.abortController?.signal });
  return response?.data?.unseenCount || 0;
}

const assetClient = new DefaultApi(undefined, apiEndpoints.assetUrl, defaultOTCAuthenticatedAxios());
export const useGetAssetApi = (args: { documentId: string }) => {
  return useSWR(args.documentId ? ["asset", args.documentId] : null, () => {
    return assetClient.getAsset(args.documentId).then(resp => resp.data);
  });
};

export const useUpdateAssetApi = (args: { documentId: string }) => {
  const { mutate, isValidating } = useGetAssetApi({ documentId: args.documentId });

  return {
    updateAsset: useCallback(
      async (payload: UpdateAssetDTO) => {
        await mutate(
          async data => {
            await assetClient.updateAsset(args.documentId, payload);
            return data;
          },
          {
            populateCache: false,
            revalidate: true
          }
        );
      },
      [args.documentId, mutate]
    ),
    isValidating
  };
};

export const getAssetOverview = async () => {
  const response = await assetClient.getAssetOverview();
  return response.data;
};

export const useAutomaticallyToBeAddedErs = (args: { documentId: string; dlIds: string[] }) => {
  return useSWR(args.documentId ? ["asset", args.documentId, "automatic-ers", args.dlIds] : null, args => {
    const [, documentId, , dlIds] = args;
    return assetClient
      .getAutomaticExternalRecipients(documentId, {
        dataLocationIds: dlIds
      })
      .then(resp => resp.data);
  });
};

export async function linkExternalRecipient(assetId: string, externalRecipientId: string) {
  const response = await axiosInstance.post(`/${assetId}/link`, {
    externalRecipientId
  });
  return response ? response.data : null;
}

export async function unlink(assetId: string, data: { externalRecipientId?: string; riskId?: string }) {
  const response = await axiosInstance.post(`/${assetId}/unlink`, data);
  return response ? response.data : null;
}

export async function getRisksOptions(assetId: string) {
  const response = await axiosInstance.get<{
    risks: RiskDTO[];
  }>(`/${assetId}/options/risks`);
  return response ? response.data : null;
}

export async function getPaOptions(assetId: string) {
  const response = await axiosInstance.get<{
    readonly pas: Pick<MiniProcessingActivityDTO, "id" | "name" | "status" | "labels" | "permission">[];
  }>(`/${assetId}/options/pas`);
  return response ? response.data : null;
}

export async function getAITechDocsOptions(assetId: string) {
  const response = await axiosInstance.get<{
    aiTechDocs: {
      id: string;
      name: string;
    }[];
  }>(`/${assetId}/options/aitechdocs`);
  return response ? response.data : null;
}

export async function linkToPAs(input: { readonly assetId: string; readonly paIds: string[] }) {
  const { assetId, paIds } = input;
  return await axiosInstance.post(`/${assetId}/link/pas`, { paIds });
}

export async function unlinkToPAs(input: { readonly assetId: string; readonly paIds: string[] }) {
  const { assetId, paIds } = input;
  return await axiosInstance.post(`/${assetId}/unlink/pas`, { paIds });
}

/* SWR */
export const useAssetRisksOptions = (args: { documentId: string }) => {
  return useSWR(args.documentId ? ["asset", args.documentId, "risks-options"] : null, args => {
    const [, documentId] = args;
    return getRisksOptions(documentId);
  });
};

export const useAssetPasOptions = (args: { documentId: string }) => {
  return useSWR(args.documentId ? ["asset", args.documentId, "pas-options"] : null, args => {
    const [, documentId] = args;
    return getPaOptions(documentId);
  });
};

export const useAssetAITechDocsOptions = (args: { documentId: string }) => {
  return useSWR(args.documentId ? ["asset", args.documentId, "aitechdocs-options"] : null, args => {
    const [, documentId] = args;
    return getAITechDocsOptions(documentId);
  });
};
