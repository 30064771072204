import { COLLECTIONS } from "app/collections";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import MetaView, { META_VIEW_TABS } from "components/MetaView/MetaView";
import { RiskPageStepper } from "./RiskPagination";
import { useRisk } from "app/contexts/risk-context";
import { useTranslation } from "react-i18next";
import { ProcessesOverviewReadOnly } from "../processes/overview/ProcessesOverviewReadOnly";
import { useCallback, useMemo, useState } from "react";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { useMetaView } from "app/contexts/meta-view-context";
import { Box, Button } from "@mui/material";
import { useUserProcesses } from "hook/useUserProcesses";
import useSWR from "swr";
import { getPaOptions, linkToPAs, unlinkToPAs } from "app/api/riskApi";
import ProcessesPickerModal from "components/Processes/ProcessesPickerModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { useSnackbar } from "notistack";

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

const RiskProcessesPage = () => {
  const { t } = useTranslation("risk_general_page");
  const { risk, initialized } = useRisk();
  const { setMeta } = useMetaView();
  const { enqueueSnackbar } = useSnackbar();

  const [hoveredPaId, setHoveredPaId] = useState<string | null>(null);
  const [openPaPickerModal, setOpenPaPickerModal] = useState<boolean>(false);
  const [renderId, setRenderId] = useState<string>("");
  const [unlinkProcessId, setUnlinkProcessId] = useState<string | null>(null);

  const riskId = useMemo<string>(() => risk?.id || "", [risk?.id]);
  const filter = useMemo(() => ({ riskIDs: [riskId] }), [riskId]);

  // getting linked PAs to current asset
  const {
    processes,
    processesLoaded,
    isValidating,
    mutate: mutateLinkedProcesses
  } = useUserProcesses({ riskIDs: [riskId] });

  // getting available and selectable PAs
  const {
    data: optionPAs,
    isLoading: isLoadingOptionPAs,
    mutate: mutateOptionPAs
  } = useSWR(risk.id && risk.permission === "write" ? ["risk", riskId, "pas-options"] : null, args => {
    const [, riskId] = args;
    return getPaOptions(riskId);
  });

  // only selectable PAs for user (status: review or edit, permission: write)
  const selectableIds = useMemo(() => {
    if (optionPAs && !isLoadingOptionPAs) {
      return optionPAs.pas
        .filter(({ status, permission }) => permission === "write" && (status === "edit" || status === "review"))
        .map(({ id }) => id);
    }
    return [];
  }, [optionPAs, isLoadingOptionPAs]);

  const selectedPaIds = useMemo<string[]>(() => {
    if (!processesLoaded || isValidating) return [];
    else return (processes || []).map(({ id }) => id);
  }, [isValidating, processes, processesLoaded]);

  const updateAll = useCallback(async () => {
    setRenderId(Date.now().toString());
    await mutateOptionPAs();
    await mutateLinkedProcesses();
  }, [mutateOptionPAs, mutateLinkedProcesses]);

  const handleRowOverCallback = useCallback(
    item => {
      if (hoveredPaId === item.id) {
        return;
      }
      setHoveredPaId(item.id);
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} separator={undefined} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("processes_overview:status_" + item.status)
      });
    },
    [hoveredPaId, setMeta, t]
  );

  const onOpenUnlinkConfirmation = useCallback((id: string) => {
    setUnlinkProcessId(id);
  }, []);
  const onCloseUnlinkConfirmation = useCallback(() => {
    setUnlinkProcessId(null);
  }, []);
  const onOpenPaPickerModal = useCallback(() => {
    setOpenPaPickerModal(true);
  }, []);
  const onClosePaPickerModal = useCallback(() => {
    setOpenPaPickerModal(false);
  }, []);
  const onConfirmPaPickerModal = useCallback(
    async (paIds: string[]) => {
      const idsToUnlink = selectedPaIds.filter(id => !paIds.includes(id));
      const idsToLink = paIds.filter(id => !selectedPaIds.includes(id));

      try {
        if (idsToLink.length) {
          await linkToPAs({ riskId, paIds: idsToLink });
        }
        if (idsToUnlink.length) {
          await unlinkToPAs({ riskId, paIds: idsToUnlink });
        }
      } catch (e) {
        enqueueSnackbar("Error link/unlink Processing Activities", { variant: "error" });
      } finally {
        await updateAll();
        setOpenPaPickerModal(false);
      }
    },
    [enqueueSnackbar, riskId, selectedPaIds, updateAll]
  );
  const onAddPaPickerModal = useCallback(
    async (id: string) => {
      await linkToPAs({ riskId, paIds: [id] });
      await updateAll();
      setOpenPaPickerModal(false);
    },
    [riskId, updateAll]
  );
  const onUnlinkCallback = useCallback(
    (id: string) => {
      onOpenUnlinkConfirmation(id);
    },
    [onOpenUnlinkConfirmation]
  );
  const onUnlinkSingleProcess = useCallback(async () => {
    if (unlinkProcessId) {
      setUnlinkProcessId(null);
      await unlinkToPAs({ riskId, paIds: [unlinkProcessId] });
      await updateAll();
    }
  }, [riskId, unlinkProcessId, updateAll]);

  const unlinkConfirmationDialogEl = useMemo(
    () => (
      <ConfirmationModal
        variant="info"
        modalOpen={Boolean(unlinkProcessId)}
        onClose={onCloseUnlinkConfirmation}
        modalTitle={t("common:information")}
        modalText={t("processPickerModal:unlinkProcessConfirmation")}
        buttons={[
          {
            title: t("common:cancel"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: onCloseUnlinkConfirmation
          },
          {
            confirmButton: true,
            title: t("common:confirm"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: onUnlinkSingleProcess
          }
        ]}
      />
    ),
    [onCloseUnlinkConfirmation, onUnlinkSingleProcess, t, unlinkProcessId]
  );

  const linkPAsButtonEl = useMemo(() => {
    if (risk.permission === "write") {
      return (
        <Button variant="contained" onClick={onOpenPaPickerModal} disabled={(optionPAs?.pas || []).length === 0}>
          {t("processPickerModal:linkToProcess")}
        </Button>
      );
    }
    return <></>;
  }, [onOpenPaPickerModal, optionPAs?.pas, risk.permission, t]);

  return (
    <DocMetaView
      loading={!initialized || !risk}
      metaViewContent={
        <MetaView
          translationKey={"risk_general_page"}
          docName={risk?.title || ""}
          docId={risk?.id || ""}
          collection={COLLECTIONS.RISK}
          tabs={metaViewTabIds}
        />
      }
    >
      <DocView header={risk?.title || ""} pagination={<RiskPageStepper />}>
        <Box mt={4}>
          <Box mx={-6}>
            {linkPAsButtonEl}
            <ProcessesOverviewReadOnly
              filter={filter}
              onRowOver={handleRowOverCallback}
              renderId={renderId}
              onUnlink={risk.permission === "write" ? onUnlinkCallback : undefined}
            />
          </Box>
          {openPaPickerModal && (
            <ProcessesPickerModal
              open={openPaPickerModal}
              selectedIds={selectedPaIds}
              selectableIds={selectableIds}
              options={optionPAs?.pas || []}
              allowAdd={true}
              onCancel={onClosePaPickerModal}
              onConfirm={onConfirmPaPickerModal}
              onAdd={onAddPaPickerModal}
            />
          )}
          {unlinkConfirmationDialogEl}
        </Box>
      </DocView>
    </DocMetaView>
  );
};

export default RiskProcessesPage;
