import { Chip, Tooltip } from "@mui/material";
import colors from "theme/palette/colors";

const sx = {
  backHand: colors.grey.grey200,
  color: colors.grey.grey500,
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: "11px",
  lineHeight: "12px",
  height: "24px"
};

interface OverviewBrickDateProps {
  readonly date?: string;
  readonly tooltip?: string;
}

const OverviewBrickDate = ({ date, tooltip }: OverviewBrickDateProps) => {
  if (!date) {
    return <></>;
  }

  return (
    <Tooltip title={tooltip}>
      <Chip sx={sx} label={new Date(date).toLocaleDateString()} />
    </Tooltip>
  );
};

export default OverviewBrickDate;
