import FileCopyIcon from "@material-ui/icons/FileCopy";
import { IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { t } from "i18next";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";

const OverviewBrickCopy = ({ hovered, item, disabled, onClick, onBlur }: OverviewRowBrickProps) => {
  const disableActions = item.disableActions;
  const onClickCallback = useCallback(
    event => {
      onBlur?.();
      stopEvent(event);
      onClick?.(item.id, item);
    },
    [item, onBlur, onClick]
  );

  if (!hovered || disableActions?.some(({ action }) => action === "copy")) {
    return <></>;
  }
  return (
    <Tooltip title={t("common:duplicate")}>
      <IconButton onClick={onClickCallback} disabled={disabled} size="small">
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default OverviewBrickCopy;
