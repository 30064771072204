import { Box, Divider } from "@material-ui/core";
import TextBody2 from "components/TextBody2/TextBody2";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { linkDataLocationApi } from "../../api/externalRecipientApi";
import { DataLocationsPicker } from "./DataLocationsPicker";
import { DataLocationAffectedProcesses } from "./DataLocationAffectedProcesses";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";

export interface DataLocationLinkItem {
  readonly id: string;
}
export interface DataLocationLinkModalProps {
  readonly selectedDataLocations: DataLocationLinkItem[];
  readonly onClose: () => void;
  readonly onCancel: () => void;
}
export const DataLocationLinkModal = ({ selectedDataLocations, onClose, onCancel }: DataLocationLinkModalProps) => {
  const { t } = useTranslation("service_providers_overview");

  const [selectedDataLocationIds, setSelectedDataLocationIds] = useState<string[]>([]);
  const [externalRecipientId, setExternalRecipientId] = useState<string | null>(null);
  useEffect(() => {
    setSelectedDataLocationIds(selectedDataLocations.map(dl => dl.id));
    setExternalRecipientId(null);
  }, [selectedDataLocations]);

  const linkDataLocations = useCallback(async () => {
    await linkDataLocationApi(
      {
        toLinkIDs: selectedDataLocationIds,
        externalRecipientId: externalRecipientId || null
      },
      {
        wait: true
      }
    );
    onClose();
  }, [onClose, selectedDataLocationIds, externalRecipientId]);

  const [buttons, setButtons] = useState<ConfirmationModalButtonProps[]>([]);
  useEffect(
    () =>
      setButtons([
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCancel
        },
        {
          confirmButton: true,
          title: t("common:save"),
          variant: "contained",
          color: "primary",
          size: "medium",
          onClick: linkDataLocations,
          disabled: !externalRecipientId
        }
      ]),
    [onCancel, onClose, t, linkDataLocations, externalRecipientId]
  );

  const modalBody = (
    <Box mt={3} mb={4}>
      <Box>
        <TextBody2 text={t("datalocations_link_confirmation")} />
        <Box mt={2}>
          <DataLocationsPicker selectedIDs={selectedDataLocationIds} onChange={setSelectedDataLocationIds} />
        </Box>
      </Box>
      <Box mt={3}>
        <TextBody2 text={t("link_data_source_modal_text")} />
        <Question
          qType={QUESTION_TYPE.EXTERNAL_DATA_RECIPIENTS}
          value={externalRecipientId}
          onChange={setExternalRecipientId}
          multiSelect={false}
          questionName={t("service_providers_overview:externalRecipient")}
        />
      </Box>
      {!!selectedDataLocationIds.length && (
        <Box>
          <Box mb={1}>
            <TextBody2 text={t("affected_pas")} />
          </Box>
          <Divider />
          <Box>
            <DataLocationAffectedProcesses selectedDataLocationIds={selectedDataLocationIds} />
          </Box>
          <Divider />
        </Box>
      )}
    </Box>
  );

  return (
    <ConfirmationModal
      modalOpen={!!selectedDataLocations.length}
      onClose={onCancel}
      modalTitle={t("link_data_source_modal_title")}
      modalText={t("link_data_source_warning_text")}
      buttons={buttons}
      modalBody={modalBody}
    />
  );
};
