import { Box, Tooltip, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import i18n from "app/i18n";
import { getLocale } from "components/DateField";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { STATUSES } from "app/handlers/tasksHandler";
import { createDueDateLabel, createRegularDateLabel } from "app/utils/create-due-date-label";

interface TaskOverviewRowDueDateProps {
  readonly dueDate: Date | null;
  readonly onChange: (dueAt: Date | null) => void;
  readonly readOnly?: boolean;
  readonly showAddDueDate?: boolean;
  readonly status: string;
}
const TaskOverviewRowDueDate = ({
  dueDate,
  status,
  onChange,
  showAddDueDate,
  readOnly
}: TaskOverviewRowDueDateProps) => {
  const theme = useTheme();
  const { t } = useTranslation("task_details");

  const [open, setOpen] = useState<boolean>(false);

  const { dateLabel, dateVariant } =
    status === STATUSES.done ? createRegularDateLabel(dueDate, t) : createDueDateLabel(dueDate, t);

  const openMenuCallback = useCallback(() => {
    if (readOnly) {
      return;
    }
    setOpen(true);
  }, [readOnly]);

  const closeMenuCallback = useCallback(() => {
    setOpen(false);
  }, []);

  const onChangeCallback = useCallback(
    (date: MaterialUiPickersDate) => {
      onChange(date || null);
    },
    [onChange]
  );

  const rootStyles = useMemo(
    () => ({
      cursor: !readOnly ? "pointer" : "default",
      textTransform: "uppercase",
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      padding: "6px 10px",
      fontWeight: 600,
      letterSpacing: "1px",
      fontSize: "10px",
      whiteSpace: "nowrap",
      height: "24px",
      "&.overdue": {
        color: theme.palette.common.white,
        // TODO move colors to to theme !
        backgroundColor: "#F17A79"
      },
      "&.today": {
        color: "#C81228",
        backgroundColor: "#FDE9E9"
      },
      "&.tomorrow": {
        color: "#C81228",
        backgroundColor: "#FDE9E9"
      },
      "&.five_days": {
        color: "#BF871F",
        backgroundColor: "#FFF0D4"
      },
      "&.regular": {
        color: "#6C6C6C",
        backgroundColor: "#eeeeee"
      }
    }),
    [readOnly, theme.palette.common.white]
  );

  const textFieldComponentCallback = useCallback(
    () =>
      dueDate ? (
        <Tooltip title={t("changeDueDate")} disableHoverListener={readOnly}>
          <Box className={dateVariant} onClick={openMenuCallback} mr={2} sx={rootStyles}>
            {dateLabel}
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={t("changeDueDate")} disableHoverListener={readOnly}>
          <Box
            onClick={openMenuCallback}
            mr={2}
            sx={{
              ...rootStyles,
              borderRadius: "22px",
              letterSpacing: "0.5px",
              border: "1px dashed",
              borderColor: "#9E9E9E",
              color: "#9E9E9E",
              "&:hover": {
                background: theme.palette.grey[200]
              }
            }}
          >
            {`+ ${t("add_due_date")}`}
          </Box>
        </Tooltip>
      ),
    [dateLabel, dateVariant, dueDate, openMenuCallback, readOnly, rootStyles, t, theme.palette.grey]
  );

  if (!dueDate && !showAddDueDate) {
    return <></>;
  }

  return (
    <MuiPickersUtilsProvider locale={getLocale(i18n?.language || "")} utils={DateFnsUtils}>
      <KeyboardDatePicker
        InputProps={{ readOnly: true }}
        KeyboardButtonProps={{ "aria-label": "change date" }}
        TextFieldComponent={textFieldComponentCallback}
        cancelLabel={t("common:cancel")}
        clearLabel={t("common:clear")}
        clearable={true}
        disablePast={true}
        disableToolbar
        format="dd.MM.yyyy"
        fullWidth
        id="deadline"
        inputVariant="outlined"
        label={t("due_date", { optional: "(optional)" })}
        margin="normal"
        minDateMessage={t("general_page:pastDate")}
        okLabel={t("common:ok")}
        onChange={onChangeCallback}
        onClick={openMenuCallback}
        onClose={closeMenuCallback}
        open={open}
        value={dueDate}
        variant="dialog"
      />
    </MuiPickersUtilsProvider>
  );
};

export default TaskOverviewRowDueDate;
