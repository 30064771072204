import { useUserDepartments } from "../../../../contexts/department-context";
import { useCallback } from "react";
import { UserDTO } from "../../../../api/user/userApi";

export const useUserFilter = () => {
  const { expandDepartmentIds } = useUserDepartments();

  const isUserSelectableInTheOrgUnits = useCallback(
    (input: {
      readonly user: UserDTO;
      readonly expandedOrgUnitIds: string[];
      readonly expandedOrgUnitIdsAsSet: Set<string>;
    }): boolean => {
      const { user, expandedOrgUnitIds } = input;
      if (input.user.orgUnitId && input.expandedOrgUnitIdsAsSet.has(input.user.orgUnitId)) {
        return true; // quick exit by checking plain main org unit
      }
      if (input.user.furtherOrgUnitIds?.some(it => input.expandedOrgUnitIdsAsSet.has(it))) {
        return true; // quick exit by checking plain further org unit
      }
      if (input.user.exposedOrgUnitIds?.some(it => input.expandedOrgUnitIdsAsSet.has(it))) {
        return true; // quick exit by checking exposed org unit id
      }

      if (input.user.exposedOrgUnitIds?.includes("*")) {
        const expandedUserOrgUnitIds = [
          ...expandDepartmentIds(
            [user.orgUnitId, ...(user?.furtherOrgUnitIds || [])].filter((it): it is string => !!it)
          )
        ];
        if (expandedUserOrgUnitIds.some(it => expandedOrgUnitIds.includes(it))) {
          return true;
        }
      }

      return false;
    },
    [expandDepartmentIds]
  );

  const filterUsersByOrgUnitIds = useCallback(
    (input: {
      readonly users: UserDTO[];
      readonly orgUnitIds: string[];
      readonly onlyIntersectingOrgUnitIds: boolean;
    }) => {
      const relevantOrgUnitIdsAsSet = input.onlyIntersectingOrgUnitIds
        ? new Set(input.orgUnitIds)
        : expandDepartmentIds(input.orgUnitIds);
      const relevantOrgUnitIdsAsArray = [...relevantOrgUnitIdsAsSet];

      const filteredUsers = input.users.filter(user => {
        return isUserSelectableInTheOrgUnits({
          user,
          expandedOrgUnitIds: relevantOrgUnitIdsAsArray,
          expandedOrgUnitIdsAsSet: relevantOrgUnitIdsAsSet
        });
      });

      return filteredUsers;
    },
    [expandDepartmentIds, isUserSelectableInTheOrgUnits]
  );

  return {
    filterUsersByOrgUnitIds
  };
};
