import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import { DataBreachStatus } from "app/api/dataBreachApi";
import { COLLECTIONS } from "app/collections";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { updateDataBreach } from "app/handlers/dataBreachHandler";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import stopEvent from "tool/stopEvent";

const DataBreachOverviewBrickStatus = ({ item, onBlur }: OverviewRowBrickProps) => {
  const { id, status } = item;
  const { t } = useTranslation("data_breaches_general_page");
  const { auth } = useAuthentication();
  const dispatch = useOverviewDispatch();

  const [ref, setRef] = useState<Element | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const reload = useCallback(() => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.DATA_BREACHES, reload });
  }, [dispatch]);

  const openMenuCallback = useCallback(
    event => {
      stopEvent(event);
      onBlur?.();
      setRef(event?.currentTarget);
      setOpen(true);
    },
    [onBlur]
  );

  const closeMenuCallback = useCallback(() => {
    setRef(null);
    setOpen(false);
  }, []);

  const onChangeStatusCallback = useCallback(
    async (newStatus: string) => {
      closeMenuCallback();
      if (auth && status !== newStatus) {
        await updateDataBreach(id, { status: newStatus as DataBreachStatus }, auth);
        reload();
      }
    },
    [auth, closeMenuCallback, id, reload, status]
  );

  const statuses = useMemo(
    () => [
      {
        id: "OPEN",
        icon: <LazySvgIcon name="Draft" />,
        label: t("status_OPEN"),
        status: "OPEN",
        selected: status === "OPEN"
      },
      {
        id: "IN_PROGRESS",
        icon: <LazySvgIcon name="In_Progress" />,
        label: t(`status_IN_PROGRESS`),
        status: "IN_PROGRESS",
        selected: status === "IN_PROGRESS"
      },
      {
        id: "PENDING_FEEDBACK",
        icon: <LazySvgIcon name="In_Review" />,
        label: t(`status_PENDING_FEEDBACK`),
        status: "PENDING_FEEDBACK",
        selected: status === "PENDING_FEEDBACK"
      },
      {
        id: "CLOSED",
        icon: <LazySvgIcon name="Completed" />,
        label: t(`status_CLOSED`),
        status: "CLOSED",
        selected: status === "CLOSED"
      }
    ],
    [status, t]
  );

  const currentStatus = statuses.find(({ id }) => id === status);

  return (
    <ClickAwayListener onClickAway={closeMenuCallback}>
      <>
        <Tooltip title={currentStatus?.label}>
          <Box sx={{ height: "24px" }} onClick={openMenuCallback}>
            {currentStatus?.icon}
          </Box>
        </Tooltip>
        <DropDownMenu
          open={open}
          anchorEl={ref}
          items={statuses}
          onChange={onChangeStatusCallback}
          onClose={closeMenuCallback}
        />
      </>
    </ClickAwayListener>
  );
};

export default DataBreachOverviewBrickStatus;
