import ProcessGeneralPage from "./general-page/ProcessGeneralPage";
import ProcessProcessingPage from "./processing-page/ProcessProcessingPage";
import ProcessRecipientsPage from "./recipients-page/ProcessRecipientsPage";
import ProcessPeriodsPage from "./periods-page/ProcessPeriodsPage";
import ProcessMeasuresPage from "./measures-page/ProcessMeasuresPage";
import DocViewForAssessmentPage from "./assessment-page/DocViewForAssessmentPage";
import ProcessProportionalityPage from "./proportionality-page/ProcessProportionalityPage";
import ProcessRisksPage from "./risks-page/ProcessRisksPage";
import ProcessRightsPage from "./rights-page/ProcessRightsPage";
import ProcessChangePage from "./change-page/ProcessChangePage";
import DocViewForFinalAssessmentPage from "./final-assessment/DocViewForFinalAssessmentPage";
import React from "react";
import ProcessAITechDocPage from "./aitechdoc-page/ProcessAITechDocPage";
import AIPreAssessmentPage from "./assessment-page/AIPreAssessmentPage";

export const pages: Record<
  string,
  {
    element: (input: { readonly readonly?: boolean }) => React.JSX.Element;
    name: string;
    number: string;
  }
> = {
  general: {
    element: ProcessGeneralPage,
    name: "general",
    number: "1"
  },
  processing: {
    element: ProcessProcessingPage,
    name: "description",
    number: "2"
  },
  recipients: {
    element: ProcessRecipientsPage,
    name: "recipients",
    number: "3"
  },
  periods: {
    element: ProcessPeriodsPage,
    name: "periods",
    number: "4"
  },
  measures: {
    element: ProcessMeasuresPage,
    name: "measures",
    number: "5"
  },
  "pa-assessment": {
    element: DocViewForAssessmentPage,
    name: "pa-assessment",
    number: "6"
  },
  "ai-pre-assessment": {
    element: AIPreAssessmentPage,
    name: "ai-pre-assessment",
    number: "6.5"
  },
  proportionality: {
    element: ProcessProportionalityPage,
    name: "proportionality",
    number: "7"
  },
  risks: {
    element: ProcessRisksPage,
    name: "risks",
    number: "8"
  },
  rights: {
    element: ProcessRightsPage,
    name: "rights",
    number: "9"
  },
  change: {
    element: ProcessChangePage,
    name: "change",
    number: "10"
  },
  "dpia-assessment": {
    element: DocViewForFinalAssessmentPage as any,
    name: "dpia-assessment",
    number: "11"
  },
  aitechdoc: {
    element: ProcessAITechDocPage,
    name: "aitechdoc",
    number: "11"
  }
};
