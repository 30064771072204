import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetProcessorApi,
  usePAProcessorApproveMissingFieldsApi,
  useUpdateProcessorApi,
  useUpdateStatusProcessorApi
} from "../../api/processorPAApi";
import { useDocumentName } from "./../../../app/contexts/document-name-context";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { COLLECTIONS } from "../../collections";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import { ProcessorPAPageButtons, ProcessorPAPageStepper } from "./ProcessorPAPagination";
import { ProcessorProcessorController } from "./pages/ProcessorProcessorController";
import { ProcessorCategoriesOfProcessing } from "./pages/ProcessorCategoriesOfProcessing";
import { ProcessorThirdCountryTransfer } from "./pages/ProcessorThirdCountryTransfer";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { isAxiosErrorWithCode } from "../../api/axios/axiosErrorHandler";
import DocumentNotFoundPage from "../shared/DocumentNotFound/DocumentNotFound";
import { ProcessorPAEditConfirmationProvider, useProcessorPAEditConfirmation } from "./ProcessorPAEditConfirmation";
import StaticDocViewHeader from "../../../components/StaticDocViewHeader/StaticDocViewHeader";
import StaticDocViewTextContent from "../../../components/StaticDocViewTextContent/StaticDocViewTextContent";
import StaticDocViewSubmitButton from "../../../components/StaticDocViewSubmitButton/StaticDocViewSubmitButton";
import StaticDocView from "../../../components/StaticDocView/StaticDocView";
import { ProcessorPAMissingFields } from "./ProcessorPAMissingFields";
import { ProcessorMeasure } from "./pages/ProcessorMeasure";
import { useUpdateQueues } from "../../../hook/useUpdateQueues";
import { PatchProcessorPADTO } from "../../api/generated/process-service";
import { useAuthentication } from "../../handlers/authentication/authentication-context";
import { Box, Button, CircularProgress, Tooltip } from "@mui/material";

export const ProcessorPAPage = () => {
  const { id, page } = useParams();
  const { isLoading, data, error } = useGetProcessorApi({ documentId: id || "" });
  const docName = data?.processorPA?.name || "-";

  const isNotTheLastPage = page !== "measure";
  const readonly = data?.processorPA?.permission === "read";
  const { auth } = useAuthentication();
  const userHasProcessorApprovePermission = auth?.permissions.includes("processor_pa_approve_org");
  const { t } = useTranslation();

  const [documentNotFound, setDocumentNotFound] = useState(false);
  useEffect(() => {
    if (isAxiosErrorWithCode(error, 404)) {
      setDocumentNotFound(true);
    } else if (isAxiosErrorWithCode(error, 403)) {
      setDocumentNotFound(true);
    } else {
      setDocumentNotFound(false);
    }
  }, [error]);

  const [approvalTriggered, setApprovalTriggered] = useState(false);
  const onApprovedCallback = useCallback(() => {
    setApprovalTriggered(true);
  }, []);

  if (documentNotFound) {
    return <DocumentNotFoundPage collection={COLLECTIONS.PROCESSOR_PAS} />;
  }

  if (approvalTriggered) {
    return <DocMetaView metaViewContent={<MetaView />}>{<ProcessorPAApprovedPage />}</DocMetaView>;
  }

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          tabs={metaViewTabIds}
          docName={docName}
          pageId={page || "general"}
          docId={id}
          collection={COLLECTIONS.PROCESSOR_PAS}
          translationKey={"processes_overview"}
        />
      }
      loading={isLoading}
    >
      <ProcessorPAEditConfirmationProvider>
        <ProcessorPADocView>
          <Box>
            <Box mt={4}>
              {page === "general" && <ProcessorProcessorController readonly={readonly} />}
              {page === "processing" && <ProcessorCategoriesOfProcessing readonly={readonly} />}
              {page === "third-country-transfer" && <ProcessorThirdCountryTransfer readonly={readonly} />}
              {page === "measure" && <ProcessorMeasure readonly={readonly} />}
            </Box>
            {isNotTheLastPage ? undefined : <ProcessorPAMissingFields />}
            <Box>
              <ProcessorPAPageButtons
                rightArea={
                  isNotTheLastPage ? undefined : (
                    <Tooltip title={!userHasProcessorApprovePermission ? t("common:noPermission") : ""}>
                      <Box display="flex" alignItems="center" gap={8} justifyContent="space-between">
                        {data?.processorPA?.status === "approved" && <ProcessorPAEditButton readonly={readonly} />}
                        <ProcessorPAApproveButton
                          readonly={readonly || data?.processorPA?.status === "approved"}
                          onApproved={onApprovedCallback}
                        />
                      </Box>
                    </Tooltip>
                  )
                }
              />
            </Box>
          </Box>
        </ProcessorPADocView>
      </ProcessorPAEditConfirmationProvider>
    </DocMetaView>
  );
};

export const ProcessorPADocView = ({ children }: { children: React.ReactNode }) => {
  const { id } = useParams();
  const { data } = useGetProcessorApi({ documentId: id || "" });
  const docName = data?.processorPA?.name || "-";
  const { updateProcessor } = useUpdateProcessorApi({ documentId: id || "" });
  const { setDocumentName } = useDocumentName();
  const { openModalIfApproved, beforeProcessUpdate } = useProcessorPAEditConfirmation();
  const { queueUpdates } = useUpdateQueues<PatchProcessorPADTO, PatchProcessorPADTO>({
    triggerUpdate: updateProcessor
  });

  const onHeaderChange = useCallback(
    (val: string) => {
      setDocumentName(val);
      return beforeProcessUpdate(() => queueUpdates({ name: val }));
    },
    [queueUpdates, beforeProcessUpdate, setDocumentName]
  );

  return (
    <DocView
      header={docName}
      onHeaderChange={onHeaderChange}
      pagination={<ProcessorPAPageStepper />}
      onHeaderFocus={openModalIfApproved}
    >
      {children}
    </DocView>
  );
};

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

export const ProcessorPAApproveButton = ({
  readonly,
  onApproved
}: {
  readonly readonly?: boolean;
  readonly onApproved?: () => void;
}) => {
  const { auth } = useAuthentication();
  const userHasProcessorApprovePermission = auth?.permissions.includes("processor_pa_approve_org");
  const { t } = useTranslation();
  const { id } = useParams();
  const { approveProcessor } = useUpdateStatusProcessorApi({ documentId: id || "" });
  const { data, error } = usePAProcessorApproveMissingFieldsApi({ documentId: id || "" });
  const missingFields = (data?.length || 0) > 0;
  const noApprovePermission = !userHasProcessorApprovePermission || isAxiosErrorWithCode(error, 403);

  const [settingToApprove, setSettingToApprove] = useState(false);
  const approveCallback = useCallback(async () => {
    setSettingToApprove(true);
    try {
      await approveProcessor({ status: "approved" });
      setSettingToApprove(false);
      onApproved?.();
    } catch (e) {
      setSettingToApprove(false);
      throw e;
    }
  }, [approveProcessor, onApproved]);

  return (
    <Tooltip title={noApprovePermission ? t("processor_pa_page:noApprovePermission") : ""}>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={approveCallback}
          disabled={readonly || settingToApprove || missingFields || noApprovePermission}
          endIcon={settingToApprove ? <CircularProgress size={14} /> : undefined}
        >
          {t("pagination:finish")}
        </Button>
      </Box>
    </Tooltip>
  );
};

export const ProcessorPAEditButton = ({ readonly }: { readonly readonly?: boolean }) => {
  const { auth } = useAuthentication();
  const userHasProcessorApprovePermission = auth?.permissions.includes("processor_pa_approve_org");
  const { t } = useTranslation();
  const { id } = useParams();
  const { approveProcessor } = useUpdateStatusProcessorApi({ documentId: id || "" });
  const { enqueueSnackbar } = useSnackbar();

  const [settingToEdit, setSettingToEdit] = useState(false);
  const editCallback = useCallback(async () => {
    setSettingToEdit(true);
    try {
      await approveProcessor({ status: "edit" });
      setSettingToEdit(false);
    } catch (e) {
      setSettingToEdit(false);
      throw e;
    }
    enqueueSnackbar(t("processor_pa_page:edit_snackbar"));
  }, [approveProcessor, enqueueSnackbar, t]);

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={editCallback}
      disabled={readonly || settingToEdit || !userHasProcessorApprovePermission}
      endIcon={settingToEdit ? <CircularProgress size={14} /> : undefined}
    >
      {t("pagination:setBackToEdit")}
    </Button>
  );
};

export const ProcessorPAApprovedPage = () => {
  const { t } = useTranslation("processor_pa_page");
  const navigate = useNavigate();

  const toProcessOverview = useCallback(() => {
    navigate("/processor-pas");
  }, [navigate]);

  return (
    <StaticDocView icon={"CheckCircleOutline"} type={"success"}>
      <StaticDocViewHeader text={t("approvedInfoHeader")} />
      <StaticDocViewTextContent text={t("approvedInfoText")} />
      <StaticDocViewSubmitButton text={t("questionnaires:approvedAcknowledgeButtonText")} onClick={toProcessOverview} />
    </StaticDocView>
  );
};
