import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useResources } from "app/contexts/resource-context";
import { TaskInfoLabel } from "./TaskInfoLabel";
import { isEqual } from "lodash-es";
import { LabelField } from "../../../../../components/LabelField";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import ChipsStack, { ChipVariant } from "components/ChipsStack/ChipsStack";
import { RESOURCE_TYPES } from "../../../../handlers/resourceHandler";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import LabelIcon from "@mui/icons-material/Label";

interface TaskLabelsProps {
  readonly labels: string[];
  readonly saveOperationInProgress: boolean;
  readonly taskId: string;
  readonly onChangeLabels: (labels: string[]) => void;
}
export const TaskLabels = ({ taskId, labels, saveOperationInProgress, onChangeLabels }: TaskLabelsProps) => {
  const { t } = useTranslation("task_details");
  const theme = useTheme();

  const [addLabelsActive, setAddLabelsActive] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const { translateById } = useResources();

  useEffect(() => {
    setSelectedLabels(labels);
  }, [labels, taskId]);

  const onLabelSelectionChanged = useCallback(
    value => {
      return setSelectedLabels(value);
    },
    [setSelectedLabels]
  );
  const addLabelsModalBody = <LabelField selectedIDs={selectedLabels} onSelectionChanged={onLabelSelectionChanged} />;
  const labelsAreUnchanged = isEqual(new Set(selectedLabels || []), new Set(labels));

  const resetTaskLabels = useCallback(() => {
    const taskLabels = labels || [];
    setSelectedLabels(taskLabels);
  }, [labels]);

  const addLabelsModalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        color: "primary",
        confirmButton: false,
        size: "medium",
        title: t("cancel"),
        variant: "outlined",
        onClick: () => {
          resetTaskLabels();
          setAddLabelsActive(false);
        }
      },
      {
        color: "primary",
        confirmButton: true,
        disabled: labelsAreUnchanged || saveOperationInProgress,
        size: "medium",
        title: t("save"),
        variant: "contained",
        onClick: () => {
          onChangeLabels(selectedLabels);
          setAddLabelsActive(false);
        }
      }
    ],
    [t, labelsAreUnchanged, saveOperationInProgress, resetTaskLabels, selectedLabels, onChangeLabels]
  );

  const getLabelName = useCallback(
    labelId => {
      return translateById(RESOURCE_TYPES.LABEL, labelId);
    },
    [translateById]
  );

  const closeModal = useCallback(() => {
    resetTaskLabels();
    setAddLabelsActive(false);
  }, [resetTaskLabels]);

  return (
    <Box mt={1.5} mb={2}>
      <TaskInfoLabel icon={LabelIcon} text={t("Labels")} />
      <ConfirmationModal
        buttons={addLabelsModalButtons}
        modalBody={addLabelsModalBody}
        modalOpen={addLabelsActive}
        modalText={t("add_labels_modal_text")}
        modalTitle={t("add_labels_modal_title")}
        variant="info"
        onClose={closeModal}
      />
      <Box mt={1}>
        <ChipsStack
          chips={[
            ...(labels && labels.length
              ? labels.map(labelId => ({
                  label: getLabelName(labelId),
                  rightIcon: CancelIcon,
                  type: "label",
                  styles: {
                    borderRadius: theme.spacing(0.5),
                    fontWeight: 500,
                    "& svg": {
                      width: "16px",
                      height: "16px",
                      marginRight: "-1px",
                      marginLeft: theme.spacing(1)
                    }
                  },
                  variant: ChipVariant.CONTAINED,
                  onRightIconClick: async () => {
                    const temp = selectedLabels.filter(selectedLabel => selectedLabel !== labelId);
                    setSelectedLabels(temp);
                    onChangeLabels(temp);
                  }
                }))
              : []),
            {
              label: t("add_label"),
              leftIcon: AddIcon,
              styles: {
                borderRadius: theme.spacing(0.5),
                color: theme.palette.grey[500],
                fontWeight: 500
              },
              variant: ChipVariant.DASHED,
              onClick: () => setAddLabelsActive(true)
            }
          ]}
        />
      </Box>
    </Box>
  );
};
