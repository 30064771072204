import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import OrgunitsPathsAutocomplete from "components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import TextField from "@mui/material/TextField";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { shouldShowEmailInputError } from "app/utils/emailFormatter";
import MetaView from "components/MetaView/MetaView";
import { useMetaView } from "app/contexts/meta-view-context";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES, USER_FEATURE_IDS } from "app/features";
import MultiAutocomplete from "../../../components/MultiAutocomplete/MultiAutocomplete";
import { usePathParamByKey } from "app/router/router-custom-hooks";
import { useNavigate } from "react-router-dom";
import { triggerResetPasswordApi } from "app/api/user/userPasswordResetApi";
import { useUserDepartments } from "app/contexts/department-context";
import { useSSOConfigs } from "../../../hook/useSSOConfigs";
import AssignGroupsMultiAutocomplete from "../questionnaires/utils/AssignGroupsMultiAutocomplete";
import { defaultRolesWithoutGlobal, useUserRoles } from "app/contexts/role-context";
import { RoleDTO } from "app/api/roleApi";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import SelfAssessmentIcon from "../../../assets/images/assessment/self-assessment.svg";
import { UserDeletionDialog } from "./overview/UserDeletionDialog";
import { UserDTO } from "../../api/user/userApi";
import { AdminFurtherOrgUnitsWarning } from "./AdminFurtherOrgUnitsWarning";
import { SxProps } from "@mui/system/styleFunctionSx";

export default function CreateAndEditUser() {
  const existingUserId = usePathParamByKey("userId");
  const { t } = useTranslation("manage-user-page");
  const { auth } = useAuthentication();
  const { roles, rolesLoaded } = useUserRoles();

  const { createUserHook, updateUserHook, getLatestUserHook } = useUserAndTenantData();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [orgUnitId, setOrgUnitId] = useState("");
  const [furtherOrgUnitIds, setFurtherOrgUnitIds] = useState<string[]>([]);
  const [userRole, setUserRole] = useState("");
  const [userFeatureIds, setUserFeatureIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [exposedToLowerLevelOrgUnits, setExposedToLowerLevelOrgUnits] = useState(false);
  const [modalText, setModalText] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmationFunction, setConfirmationFunction] = useState<() => void>(() => {
    /* empty */
  });
  const [loadingInfo, setLoadingInfo] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setInfo } = useMetaView();
  const { getDepartmentWithParentName, departmentsLoaded, authUserSelectableDepartmentIds } = useUserDepartments();
  const { ssoConfig, loading: ssoLoading } = useSSOConfigs({ typedEmail: email });

  const userFeaturesEnabled = useIsFeaturePresent(FEATURES.USER_FEATURES);
  const groupFeaturesEnabled = useIsFeaturePresent(FEATURES.USERGROUPS_FEATURES);
  const roleFeaturesEnabled = useIsFeaturePresent(FEATURES.USERROLES_FEATURES);
  const publicAssessmentEnabled = useIsFeaturePresent(FEATURES.PUBLIC_ASSESSMENT);
  const alwaysSelectableByLowerOrgUnitEnabled = useIsFeaturePresent(FEATURES.ALWAYS_SELECTABLE_BY_LOWER_ORG_UNITS);

  const navigateToOverview = useCallback(() => navigate("/users"), [navigate]);
  const [roleOptions] = useState(defaultRolesWithoutGlobal);

  const sendResetEmail = useCallback(async () => {
    // if user data is accessible and email was input
    if (!existingUserId) {
      return;
    }

    const existingUserEmail = (await getLatestUserHook(existingUserId))?.email;
    if (!existingUserEmail) {
      throw new Error("Trying to reset the email of a user which doesn't exist");
    }
    await triggerResetPasswordApi({ email: existingUserEmail });
    enqueueSnackbar(t("user_resetted"), { variant: "success" });
    navigateToOverview();
  }, [navigateToOverview, enqueueSnackbar, getLatestUserHook, existingUserId, t]);

  const [existingUser, setExistingUser] = useState<UserDTO | null>(null);
  const loadUser = useCallback(async () => {
    if (!existingUserId) {
      setFirstName("");
      setLastName("");
      setOrgUnitId("");
      setUserRole("basic");
      setEmail("");
      setUserFeatureIds([]);
      setFurtherOrgUnitIds([]);
      setExposedToLowerLevelOrgUnits(false);
      setGroupIds([]);
      setExistingUser(null);
      return;
    }

    if (existingUserId) {
      const user = await getLatestUserHook(existingUserId);
      setExistingUser(user);
      if (!user) {
        return navigateToOverview();
      }
      setFirstName(user.firstName || "");
      setLastName(user.lastName || "");
      setOrgUnitId(user.orgUnitId || "");
      setUserRole(user.userRole || "");
      setEmail(user.email || "");
      setUserFeatureIds(user.featureIds || []);
      setFurtherOrgUnitIds(user.furtherOrgUnitIds || []);
      setExposedToLowerLevelOrgUnits(!!user.exposedOrgUnitIds?.includes("*"));
      setGroupIds(user.groupIds || []);
      return;
    }
  }, [existingUserId, getLatestUserHook, navigateToOverview]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const rolesWithDefault = useMemo(() => {
    if (rolesLoaded) {
      const completeRoles: RoleDTO[] = roleFeaturesEnabled ? [...new Set(roles)] : [];
      const defaultRoles: string[] = roleOptions;
      if (existingUserId && (userRole === "admin" || userRole === "expert" || userRole === "dpo")) {
        defaultRoles.push(userRole);
      }
      for (let i = 0; i < defaultRoles.length; i++) {
        completeRoles.push({
          tenantId: roles[0]?.tenantId ?? "",
          id: defaultRoles[i],
          name: t(defaultRoles[i]),
          permissions: []
        });
      }
      if (existingUserId && userRole === "externalUser") {
        completeRoles.push({
          tenantId: roles[0]?.tenantId ?? "",
          id: userRole,
          name: t(`${userRole}`),
          permissions: []
        });
      }
      return completeRoles;
    }
    return [];
  }, [rolesLoaded, roleFeaturesEnabled, roles, roleOptions, t, userRole, existingUserId]);

  const defaultInfo = useMemo(
    () =>
      existingUserId
        ? {
            title: t("manage-user-page:userDetailsInfoTitle"),
            text: t("manage-user-page:userDetailsInfoText")
          }
        : {
            title: t("manage-user-page:addNewUserInfoTitle"),
            text: t("manage-user-page:addNewUserInfoText")
          },
    [existingUserId, t]
  );

  const departmentInfo = useMemo(
    () => ({
      title: t("manage-user-page:departmentInfoTitle"),
      text: t("manage-user-page:departmentInfoText")
    }),
    [t]
  );

  useEffect(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);

  // register user in user database of relevant tenant
  const registerUser = useCallback(async () => {
    setLoadingInfo(true);

    const trimmedEmail = email?.trim();
    const trimmedFirstName = firstName?.trim();
    const trimmedLastName = lastName?.trim();
    const userGroupIds = groupIds ?? [];
    const userExposedOrgUnitIds = exposedToLowerLevelOrgUnits ? ["*"] : [];
    try {
      await createUserHook({
        email: trimmedEmail,
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
        userRole,
        orgUnitId,
        featureIds: userFeatureIds,
        furtherOrgUnitIds,
        // only add group ids if feature toggle is enabled
        groupIds: groupFeaturesEnabled ? userGroupIds : undefined,
        // never add exposedOrgUnitIds, if always selectable feature toggle is on
        exposedOrgUnitIds: alwaysSelectableByLowerOrgUnitEnabled ? undefined : userExposedOrgUnitIds
      });

      setLoadingInfo(false);
      enqueueSnackbar(t("created_message"), { variant: "success" });
      navigateToOverview();
    } catch (error: any) {
      enqueueSnackbar(t(error?.message), { variant: "error" });
      console.error(error);
      setLoadingInfo(false);
    }
  }, [
    email,
    firstName,
    lastName,
    createUserHook,
    userRole,
    orgUnitId,
    userFeatureIds,
    furtherOrgUnitIds,
    groupIds,
    enqueueSnackbar,
    t,
    navigateToOverview,
    groupFeaturesEnabled,
    exposedToLowerLevelOrgUnits,
    alwaysSelectableByLowerOrgUnitEnabled
  ]);

  // edit user in user database of relevant tenant
  const editUser = useCallback(async () => {
    if (!existingUserId) {
      throw new Error("User is non existent!");
    }

    setLoadingInfo(true);
    const userGroupIds = groupIds ?? [];
    const userExposedOrgUnitIds = exposedToLowerLevelOrgUnits ? ["*"] : [];
    try {
      await updateUserHook(existingUserId, {
        firstName,
        lastName,
        orgUnitId,
        userRole,
        featureIds: userFeatureIds,
        furtherOrgUnitIds,
        // only update group ids if feature toggle is enabled
        groupIds: groupFeaturesEnabled ? userGroupIds : undefined,
        // never updates exposedOrgUnitIds, if always selectable feature toggle is on
        exposedOrgUnitIds: alwaysSelectableByLowerOrgUnitEnabled ? undefined : userExposedOrgUnitIds
      });
      enqueueSnackbar(t("changes_saved"), { variant: "success" });
      setLoadingInfo(false);
      navigateToOverview();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
      setLoadingInfo(false);
    }
  }, [
    existingUserId,
    updateUserHook,
    firstName,
    lastName,
    orgUnitId,
    userRole,
    userFeatureIds,
    furtherOrgUnitIds,
    groupIds,
    enqueueSnackbar,
    t,
    navigateToOverview,
    groupFeaturesEnabled,
    exposedToLowerLevelOrgUnits,
    alwaysSelectableByLowerOrgUnitEnabled
  ]);

  const necessaryFieldsSubmitted = () =>
    !loadingInfo && firstName && lastName && email && !shouldShowEmailInputError(email) && userRole;

  const modifyingSelf = existingUserId === auth?.uid;
  const showReset = existingUserId && !ssoConfig?.ssoOnly && !ssoLoading;
  const showDelete = existingUserId && !modifyingSelf;
  const allowModifyRole = !modifyingSelf;
  const allowModifyOrgUnit = !modifyingSelf;

  const onInfoTextReset = useCallback(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);
  const onFirstNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  }, []);
  const onFirstNameFocused = useCallback(() => {
    setInfo({
      title: t("manage-user-page:firstNameInfoTitle"),
      text: t("manage-user-page:firstNameInfoText")
    });
  }, [setInfo, t]);
  const onLastNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  }, []);
  const onLastNameFocused = useCallback(() => {
    setInfo({
      title: t("manage-user-page:lastNameInfoTitle"),
      text: t("manage-user-page:lastNameInfoText")
    });
  }, [setInfo, t]);
  const onEmailChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);
  const onEmailFocused = useCallback(() => {
    setInfo({
      title: t("manage-user-page:emailInfoTitle"),
      text: t("manage-user-page:emailInfoText")
    });
  }, [setInfo, t]);

  const onRoleChanged = useCallback((role: string) => {
    setUserRole(role);
  }, []);
  const onRoleFocused = useCallback(() => {
    setInfo({
      title: t("manage-user-page:basicPlusInfoTitle"),
      text: t("manage-user-page:basicPlusInfoText")
    });
  }, [setInfo, t]);

  const onDepartmentFocused = useCallback(() => {
    setInfo(departmentInfo);
  }, [setInfo, departmentInfo]);

  const onGroupFocused = useCallback(() => {
    setInfo({
      title: t("manage-user-page:groupInfoTitle"),
      text: t("manage-user-page:groupInfoText")
    });
  }, [setInfo, t]);

  const onExposedToLowerLevelOrgUnitChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setExposedToLowerLevelOrgUnits(event.target.checked);
  }, []);

  const onExposedToLowerLevelOrgUnitFocused = useCallback(() => {
    setInfo({
      title: t("manage-user-page:exposedToLowerLevelOrgUnitsTitle"),
      text: t("manage-user-page:exposedToLowerLevelOrgUnitsText")
    });
  }, [setInfo, t]);

  const featureIdTranslation = useCallback(featureId => t(`userFeatures:${featureId}`, featureId), [t]);

  const roleIdTranslation = useCallback(
    roleId => {
      if (rolesWithDefault.length > 0) {
        return rolesWithDefault?.find(role => role.id === roleId)?.name || "";
      } else {
        return "";
      }
    },
    [rolesWithDefault]
  );

  const resetEmailCallback = useCallback(() => {
    setConfirmationFunction(() => sendResetEmail);
    setModalText(t("reset_modal_text"));
    setEditModalOpen(true);
  }, [t, sendResetEmail]);

  const modalClose = useCallback(() => {
    setEditModalOpen(false);
    setModalText("");
  }, []);

  const [modalButtons, setModalButtons] = useState<any[]>([]);
  useEffect(() => {
    setModalButtons([
      {
        confirmButton: false,
        title: t("common:no"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: () => {
          setEditModalOpen(false);
          setModalText("");
        }
      },
      {
        confirmButton: true,
        title: t("common:yes"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: async () => {
          confirmationFunction();
          setEditModalOpen(false);
          setModalText("");
        }
      }
    ]);
  }, [confirmationFunction, t]);

  const [toBeDeletedUserId, setToBeDeletedUserId] = useState("");
  const onDeleteUserClicked = useCallback(() => {
    setToBeDeletedUserId(existingUserId || "");
  }, [existingUserId]);
  const onDeleteUserClosed = useCallback(() => {
    setToBeDeletedUserId("");
  }, []);
  const onDeleteUserConfirmed = useCallback(() => {
    enqueueSnackbar(t("user_deleted"), { variant: "success" });
    navigateToOverview();
  }, [enqueueSnackbar, navigateToOverview, t]);

  const docProperties = useMemo(() => {
    return [
      userRole === "externalUser"
        ? { icon: <SelfAssessmentIcon />, label: t(`add_participant:participant_type_external`) }
        : { icon: <SelfAssessmentIcon />, label: t("add_participant:participant_type_internal") }
    ];
  }, [t, userRole]);

  return (
    <DocMetaView metaViewContent={<MetaView translationKey="organisation" />}>
      <DocView
        docProperties={publicAssessmentEnabled ? docProperties : undefined}
        header={existingUserId ? t("edit_user_information") : t("add_user")}
      >
        <Box display="flex" justifyContent="space-between" gap={6} mb={3} mt={6}>
          <Box flex={1}>
            <TextField
              required
              fullWidth={true}
              label={t("fields:first_name")}
              value={firstName}
              onChange={onFirstNameChanged}
              name="first_name_optional"
              id="firstName"
              type="text"
              variant="outlined"
              onFocus={onFirstNameFocused}
              onBlur={onInfoTextReset}
            />
          </Box>
          <Box flex={1}>
            <TextField
              required
              fullWidth={true}
              autoComplete="off"
              value={lastName}
              onChange={onLastNameChanged}
              name="last_name_optional"
              id="lastName"
              label={t("fields:last_name")}
              type="text"
              variant="outlined"
              onFocus={onLastNameFocused}
              onBlur={onInfoTextReset}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" gap={6} mb={3}>
          <Box flex={1}>
            <Tooltip title={userRole === "externalUser" ? t("manage-user-page:disabledTooltip") : ""}>
              <TextField
                required
                fullWidth={true}
                autoComplete="off"
                disabled={!!existingUserId}
                value={email}
                onChange={onEmailChanged}
                name="email"
                id="email"
                error={shouldShowEmailInputError(email)}
                helperText={shouldShowEmailInputError(email) && t("emailBadFormat")}
                label={t("fields:email")}
                type="email"
                variant="outlined"
                onFocus={onEmailFocused}
                onBlur={onInfoTextReset}
              />
            </Tooltip>
          </Box>
          <Box flex={1}>
            {rolesWithDefault && (
              <MultiAutocomplete<string, false, true, false>
                label={t("fields:userRole")}
                disabled={!allowModifyRole || userRole === "externalUser"}
                options={rolesWithDefault.map(element => element["id"] ?? "")}
                id={"userRole"}
                onFocus={onRoleFocused}
                onBlur={onInfoTextReset}
                selected={userRole}
                updateSelected={onRoleChanged}
                getOptionLabel={roleIdTranslation}
                hasMultiSelect={false}
                disableClearable={true}
                tooltipText={userRole === "externalUser" ? t("manage-user-page:disabledTooltip") : ""}
              />
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" gap={6} mb={3}>
          <Box flex={1}>
            <OrgunitsPathsAutocomplete
              disabled={!allowModifyOrgUnit || userRole === "externalUser"}
              label={t("fields:department")}
              onFocus={onDepartmentFocused}
              onBlur={onInfoTextReset}
              value={orgUnitId}
              onChange={setOrgUnitId}
              tooltipText={userRole === "externalUser" ? t("manage-user-page:disabledTooltip") : ""}
            />
          </Box>
        </Box>

        <Box justifyContent="space-between" gap={6} mb={3}>
          {departmentsLoaded && (
            <MultiAutocomplete
              id={"assignedOrgUnits"}
              label={t("audit_details:associatedOrgUnitIds")}
              options={authUserSelectableDepartmentIds}
              selected={furtherOrgUnitIds}
              updateSelected={setFurtherOrgUnitIds}
              getOptionLabel={getDepartmentWithParentName}
              disabled={userRole === "externalUser"}
              hasMultiSelect
              fullWidth={true}
              tooltipText={userRole === "externalUser" ? t("manage-user-page:disabledTooltip") : ""}
            />
          )}
          <AdminFurtherOrgUnitsWarning
            sx={warningSx}
            roleId={userRole}
            existingOrgUnitIds={existingUser?.furtherOrgUnitIds || emptyArray}
            currentOrgUnitIds={furtherOrgUnitIds}
          />
        </Box>

        {userFeaturesEnabled && (
          <Box display="flex" justifyContent="space-between" gap={6} mb={3}>
            <Box flex={1}>
              <MultiAutocomplete<string, true, false, false>
                label={t("fields:userFeatures")}
                options={Object.values(USER_FEATURE_IDS)}
                selected={userFeatureIds}
                updateSelected={setUserFeatureIds}
                getOptionLabel={featureIdTranslation}
                disabled={userRole === "externalUser"}
                hasMultiSelect={true}
                tooltipText={userRole === "externalUser" ? t("manage-user-page:disabledTooltip") : ""}
              />
            </Box>
          </Box>
        )}

        {groupFeaturesEnabled && auth?.permissions.includes("group_read_all") && userRole !== "externalUser" && (
          <Box display="flex" justifyContent="space-between" gap={6} mb={3}>
            <Box flex={1}>
              <AssignGroupsMultiAutocomplete
                docAssignedGroupIds={groupIds}
                onDocAssignedGroupIdsChanged={setGroupIds}
                freeSolo={false}
                disableClearable={false}
                label={t("groups_overview:title")}
                hasMultiSelect={true}
                onFocus={onGroupFocused}
                tooltipText={userRole === "externalUser" ? t("manage-user-page:disabledTooltip") : ""}
              />
            </Box>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" gap={6} mb={3}>
          <Box flex={1}>
            <Tooltip
              title={alwaysSelectableByLowerOrgUnitEnabled ? t("alwaysExposedToLowerLevelOrgUnitText") : ""}
              placement="top"
            >
              <FormControl component="fieldset" onFocus={onExposedToLowerLevelOrgUnitFocused}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={alwaysSelectableByLowerOrgUnitEnabled ? true : exposedToLowerLevelOrgUnits}
                        value={alwaysSelectableByLowerOrgUnitEnabled ? true : exposedToLowerLevelOrgUnits}
                        onChange={onExposedToLowerLevelOrgUnitChanged}
                        color="primary"
                        name={""}
                        disabled={!!alwaysSelectableByLowerOrgUnitEnabled || userRole === "externalUser"}
                      />
                    }
                    label={t("exposedToLowerLevelOrgUnits")}
                  />
                </FormGroup>
              </FormControl>
            </Tooltip>
          </Box>
        </Box>

        <Box mt={3} display="flex" justifyContent="space-between">
          <Box display="flex" justifyContent="flex-start">
            {showReset && (
              <Box mr={1}>
                <Button startIcon={<VpnKeyIcon />} variant="contained" color="primary" onClick={resetEmailCallback}>
                  {t("reset")}
                </Button>
              </Box>
            )}
            {showDelete && (
              <Box mr={1}>
                <Button
                  sx={{ color: "common.white", bgcolor: "red.400" }}
                  startIcon={<DeleteIcon />}
                  variant="contained"
                  color="primary"
                  onClick={onDeleteUserClicked}
                >
                  {t("delete")}
                </Button>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box mr={1}>
              <Button variant="outlined" color="primary" onClick={navigateToOverview}>
                {t("back")}
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={!necessaryFieldsSubmitted()}
                onClick={existingUserId ? editUser : registerUser}
              >
                {loadingInfo && <CircularProgress color="inherit" size={14} sx={{ mr: 1 }} />}
                {existingUserId ? t("save_user") : t("add")}
              </Button>
            </Box>
          </Box>
          <ConfirmationModal
            modalBody={undefined}
            modalOpen={editModalOpen}
            modalTitle={t("questionnaires:edit_modal_title")}
            modalText={modalText}
            onClose={modalClose}
            buttons={modalButtons}
          />
          <UserDeletionDialog
            open={!!toBeDeletedUserId}
            onClose={onDeleteUserClosed}
            toBeDeletedUserId={toBeDeletedUserId}
            onUserDeleted={onDeleteUserConfirmed}
          />
        </Box>
      </DocView>
    </DocMetaView>
  );
}

const emptyArray: string[] = [];
const warningSx: SxProps = {
  mt: 3,
  mb: 3
};
