import { useTranslation } from "react-i18next";
import ConfirmationModal, {
  ConfirmationModalButtonProps
} from "../../../../../components/ConfirmationModal/ConfirmationModal";
import { useDataAssetUsageInPAs, useDataTypeTreeManager } from "../../../../api/dataAssetApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import TextBody2 from "../../../../../components/TextBody2/TextBody2";
import {
  expandSelectedPGItems,
  removeChildrenIfParentsAlreadySelected
} from "../../overview/person-groups/expandSelectedPGItems";
import { DataAssetType } from "app/api/generated/asset-service";
import { isAxiosErrorWithCode } from "../../../../api/axios/axiosErrorHandler";
import { useSnackbar } from "notistack";

export interface ToDeleteOverviewItem {
  readonly id: string;
  readonly assetType: "PERSON_GROUP" | "DATA_CATEGORY" | "DATA_TYPE";
  readonly children?: ToDeleteOverviewItem[];
  readonly parentId?: string | null;
}

export const PGDCDTDeleteModal = ({
  open,
  onCancel,
  onConfirm,
  items
}: {
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly items: ToDeleteOverviewItem[];
}) => {
  const { t } = useTranslation("resources_lists_data_types_categories_person_groups");

  const deletionData = useMemo(
    () =>
      expandSelectedPGItems({
        selectedItems: items
      }),
    [items]
  );

  const { data, isLoading, error } = useDataAssetUsageInPAs({
    pgIds: deletionData.pgIds,
    dtIds: deletionData.dtIds
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      const snackbarKey = enqueueSnackbar("Error in fetching usages", {
        variant: "error"
      });
      return () => {
        closeSnackbar(snackbarKey);
      };
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const rawCount = useMemo(() => {
    const globalCount = new Set<string>();
    const paIdsFromPG = Object.values(data?.byPersonGroupIds || {}).flatMap(it => it);
    const paIdsFromDT = Object.values(data?.byDataTypeIds || {}).flatMap(it => it);
    paIdsFromPG.forEach(paId => globalCount.add(paId));
    paIdsFromDT.forEach(paId => globalCount.add(paId));
    return globalCount.size;
  }, [data]);

  const { actions } = useDataTypeTreeManager();
  const deleteAnItem = useCallback(
    async (item: ToDeleteOverviewItem) => {
      if (item.assetType === DataAssetType.PersonGroup) {
        await actions.deletePersonGroup({ personGroupId: item.id });
      } else if (item.assetType === DataAssetType.DataCategory) {
        await actions.deleteDataCategory({ dataCategoryId: item.id });
      } else if (item.assetType === DataAssetType.DataType) {
        await actions.deleteDataType({
          dataTypeId: item.id
        });
      }
    },
    [actions]
  );

  const [isDeleting, setIsDeleting] = useState(false);
  const onConfirmDeletion = useCallback(async () => {
    setIsDeleting(true);

    const childrenWithoutParents = removeChildrenIfParentsAlreadySelected({
      selectedItems: items
    });

    for (const item of childrenWithoutParents) {
      try {
        await deleteAnItem(item);
      } catch (err: unknown) {
        if (isAxiosErrorWithCode(err, 404)) {
          continue;
        }
        setIsDeleting(false);
        throw err;
      }
    }

    setIsDeleting(false);
    onConfirm();
  }, [deleteAnItem, items, onConfirm]);

  const buttons = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCancel,
          disabled: isDeleting
        },
        {
          confirmButton: true,
          title: t("common:confirm"),
          variant: "contained",
          color: "primary",
          size: "medium",
          onClick: onConfirmDeletion,
          disabled: isDeleting
        }
      ] as ConfirmationModalButtonProps[],
    [isDeleting, onCancel, onConfirmDeletion, t]
  );

  return (
    <ConfirmationModal
      modalOpen={open}
      onClose={onCancel}
      modalWarning={t("deleteConfirmationTitle")}
      buttons={buttons}
      loading={isLoading}
      modalBody={
        <Box pb={2}>
          <Box>
            <TextBody2 text={t("deleteConfirmationExplanation")} />
          </Box>
          <Box mt={1} mb={1}>
            <TextBody2 text={t("deleteConfirmationCount", { count: error ? -1 : rawCount })} />
          </Box>
          <Box>
            <TextBody2 text={t("deleteConfirmationConfirmation")} />
          </Box>
        </Box>
      }
      modalText=""
    />
  );
};
