import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Icon, Typography } from "@mui/material";
import { TaskDetailsDTO } from "app/api/taskApi";
import { TaskInfoLabel } from "./TaskInfoLabel";
import { useNavigation } from "app/pages/shared/GoTo/GoTo";
import LinkIcon from "@mui/icons-material/Link";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getProcessPageData } from "app/api/paApi";
import { getProcessorPAData } from "app/api/processorPAApi";
import { getTom } from "app/handlers/tomHandler";
import { getRisk } from "app/handlers/risksHandler";
import { getAuditDetail } from "app/api/auditApi";
import { getDataBreachById } from "app/handlers/dataBreachHandler";
import { getDataSubjectRequest } from "app/handlers/dataSubjectRequestHandler";
import { getExternalRecipient } from "app/handlers/externalRecipientHandler";
import { getProcessorApi } from "../../../../api/processorPAApi";
import { SxProps } from "@mui/system/styleFunctionSx";

type FetcherFunction<TArg, TResult> = (arg: TArg) => Promise<TResult>;

interface LinkedToProps {
  readonly item: TaskDetailsDTO;
}
export const TaskLinkedTo = ({ item }: LinkedToProps) => {
  const { t } = useTranslation("task_details");
  const navigateTo = useNavigation();
  const { collection, documentId, pageId, questionId, id } = item;
  const [linkDisplayText, setLinkDisplayText] = useState("");

  const goTo = useCallback(() => {
    navigateTo({ collection, documentId, pageId, questionId, taskId: id });
  }, [collection, documentId, id, navigateTo, pageId, questionId]);

  useEffect(() => {
    if (documentId) {
      const collectionName = t(`collection:${collection}_cap`);
      const setDocumentTitle = async <TArg, TResult>(
        fetcher: FetcherFunction<TArg, TResult>,
        property: string,
        dataArg: TArg
      ) => {
        try {
          const data = await fetcher(dataArg);
          const result = Array.isArray(data) ? data[0] : data;
          const getNestedProperty = (obj: any, path: string) => {
            return path.split(".").reduce((o, p) => (o ? o[p] : null), obj);
          };
          const documentTitle =
            result && getNestedProperty(result, property)
              ? `${collectionName} / ${getNestedProperty(result, property)}`
              : collectionName;
          setLinkDisplayText(documentTitle);
        } catch (error) {
          console.error(error);
          setLinkDisplayText(collectionName);
        }
      };
      const auditId = documentId.split("/answerset/");
      switch (collection) {
        case "processes":
          setDocumentTitle(getProcessPageData, "processPage.title", { processId: documentId, page: "general" });
          break;
        case "processorPAs":
          setDocumentTitle(getProcessorPAData, "processorPA.name", { processId: documentId });
          break;
        case "tom":
          setDocumentTitle(getTom, "name", documentId);
          break;
        case "risk":
          setDocumentTitle(getRisk, "title", documentId);
          break;
        case "audits":
          setDocumentTitle(getAuditDetail, "title", { id: documentId });
          break;
        case "dataBreaches":
          setDocumentTitle(getDataBreachById, "title", documentId);
          break;
        case "dataSubjectRequests":
          setDocumentTitle(getDataSubjectRequest, "inputData.title", documentId);
          break;
        case "serviceProviders":
          setDocumentTitle(getExternalRecipient, "name", documentId);
          break;
        case "assessmentResponse":
          setDocumentTitle(getAuditDetail, "title", { id: auditId[0] });
          break;
        default:
          console.error("Document collection not found:", collection);
          setLinkDisplayText(collectionName);
      }
    }
  }, [collection, documentId, t]);

  if (!documentId) {
    return null;
  }

  return (
    <>
      <Box mt={2} mb={2} sx={linkedToContainer} onClick={goTo}>
        <TaskInfoLabel icon={LinkIcon} text={t("task_details:linked_to")} />
        <Box mt={1} sx={linkSx}>
          <Typography variant="body1" sx={textSx}>
            {linkDisplayText}
          </Typography>
          <Icon component={ArrowForwardIcon} />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

const linkedToContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  flexDirection: "column"
};

const linkSx: SxProps = {
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  "& svg": {
    color: "primary.main",
    width: "16px",
    marginLeft: 1
  },
  whiteSpace: "nowrap",
  "& p": {
    display: "inline-block",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
};
const textSx: SxProps = {
  display: "flex",
  alignSelf: "flex-start",
  alignItems: "flex-start",
  color: "primary.main",
  fontWeight: 400
};
