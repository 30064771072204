import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGetAssetApi, useUpdateAssetApi } from "app/api/assetApi";
import { ExternalRecipientOverview } from "../../service-providers/overview/ExternalRecipientOverview";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { UpdateAssetDTO } from "../../../api/generated/asset-service";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import ExternalRecipientsPickerModal from "../../service-providers/ExternalRecipientsPickerModal";

export const AssetExternalRecipientsOverview = ({
  assetId,
  readonly
}: {
  readonly assetId: string;
  readonly readonly: boolean;
}) => {
  const { data: asset, mutate } = useGetAssetApi({ documentId: assetId || "" });
  const { updateAsset } = useUpdateAssetApi({ documentId: assetId || "" });
  const { queueUpdates } = useUpdateQueues<UpdateAssetDTO, UpdateAssetDTO>({
    triggerUpdate: updateAsset
  });
  const { t } = useTranslation("assets_overview");

  useEffect(() => {
    if (!asset?.dataLocationIds) {
      return;
    }

    const timeoutId = setTimeout(() => {
      mutate();
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [asset?.dataLocationIds, mutate]);

  const [externalRecipientsPickerOpen, setExternalRecipientsPickerOpen] = useState(false);
  const openExternalRecipientsPicker = useCallback(() => setExternalRecipientsPickerOpen(true), []);
  const closeExternalRecipientsPicker = useCallback(() => setExternalRecipientsPickerOpen(false), []);

  const [externalRecipientIds, setExternalRecipientIds] = useState<string[]>(asset?.externalRecipientIds || []);
  useEffect(() => {
    setExternalRecipientIds(asset?.externalRecipientIds || []);
  }, [asset?.externalRecipientIds]);

  const onExternalRecipientsChange = useCallback(
    (externalRecipientIds: string[]) => {
      setExternalRecipientIds(externalRecipientIds);
      queueUpdates({ externalRecipientIds });
      setExternalRecipientsPickerOpen(false);
    },
    [queueUpdates]
  );

  const onExternalRecipientAdd = useCallback(
    async (input: { readonly selectedIds: string[] }) => {
      const { selectedIds } = input;
      setExternalRecipientIds(selectedIds);
      queueUpdates({ externalRecipientIds: selectedIds });
      setExternalRecipientsPickerOpen(false);
    },
    [queueUpdates]
  );

  const assetOrgUnitIds = useMemo<string[]>(
    () => [asset?.mainOrgUnitId, ...(asset?.orgUnitIds || [])].filter((it): it is string => !!it),
    [asset?.mainOrgUnitId, asset?.orgUnitIds]
  );

  return (
    <>
      <Box display="flex" px={8}>
        <Button variant="contained" color="primary" onClick={openExternalRecipientsPicker} disabled={readonly}>
          {t("asset_details:changeExternalRecipients")}
        </Button>
      </Box>
      <ExternalRecipientOverview
        key={`ers-${externalRecipientIds.join("-")}`}
        externalRecipientIds={externalRecipientIds}
        hideTitle={true}
        hideActions={true}
      />
      <ExternalRecipientsPickerModal
        selectedIds={externalRecipientIds}
        onConfirm={onExternalRecipientsChange}
        onCancel={closeExternalRecipientsPicker}
        orgUnitIds={assetOrgUnitIds}
        open={externalRecipientsPickerOpen}
        allowAdd={true}
        onAdd={onExternalRecipientAdd}
      />
    </>
  );
};
