import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDataTypeTree, DataTypeTreeData } from "app/api/dataAssetApi";
import Question from "components/Question/Question";
import { DSRPersonGroupField } from "./DSRPersonGroupField";
import { useDataSubjectRequest } from "app/contexts/dsr-context";
import { useErrorSnackbar } from "hook/errorSnackbar";

// maps person group keys to their data type ids
const getDataTypesIdsOfPersonGroup = (personGroupKey: string, dataTypeTree: DataTypeTreeData) => {
  if (!dataTypeTree?.length) return [];

  const dataTypeIds: string[] = [];
  for (const personGroup of dataTypeTree) {
    if (personGroup.personGroupKey === personGroupKey) {
      for (const category of personGroup.dataCategories) {
        for (const type of category.dataTypes) {
          // skip merged types
          if (!type.mergedIntoId) {
            dataTypeIds.push(type.id);
          }
        }
      }
    }
  }
  return dataTypeIds;
};

interface PersonGroupPickerProps {
  readonly disabled?: boolean;
}

// person group picker component for data subject requests
const DSRPersonGroupPicker = ({ disabled }: PersonGroupPickerProps) => {
  const { t } = useTranslation("data_subject_requests_overview_general_page");
  const { catchAsSnackbar } = useErrorSnackbar();
  const { updateBasicDataHook, dataSubjectRequest } = useDataSubjectRequest();

  // get data type tree for mapping person groups to data types
  const dataTypeTree = useDataTypeTree();
  const dataTypeCategoryPersonGroupList = useMemo(() => dataTypeTree.data || [], [dataTypeTree.data]);

  // handle person group selection changes
  const handlePersonGroupChange = useCallback(
    (values: string | string[] | null) => {
      // ensure we have a string array to work with
      const selectedPersonGroups = Array.isArray(values) ? values : [];

      // map selected person groups to their data type ids
      const dataTypeIds = selectedPersonGroups
        .flatMap(personGroup => getDataTypesIdsOfPersonGroup(personGroup, dataTypeCategoryPersonGroupList))
        .filter(id => id); // filter out nulls/undefined

      // update request with new person groups and data type ids
      updateBasicDataHook({
        personGroups: selectedPersonGroups,
        dataTypeIds: dataTypeIds
      }).catch(catchAsSnackbar("failed to update person groups"));
    },
    [catchAsSnackbar, dataTypeCategoryPersonGroupList, updateBasicDataHook]
  );

  return (
    <Question questionId="personGroups" questionName={t("person_groups")} disabled={disabled}>
      <DSRPersonGroupField
        disabled={disabled}
        values={dataSubjectRequest?.inputData?.personGroups || []}
        onChanges={handlePersonGroupChange}
      />
    </Question>
  );
};

export default DSRPersonGroupPicker;
