import {
  BusinessProcessUpdateDTO,
  getBusinessProcessByIdFromApi,
  updateBusinessProcessApi
} from "../api/businessProcessApi";

export async function getBusinessProcessById(id: string) {
  return await getBusinessProcessByIdFromApi(id);
}

export async function updateBusinessProcess(id: string, businessProcessData: BusinessProcessUpdateDTO) {
  return updateBusinessProcessApi(id, businessProcessData);
}
