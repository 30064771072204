import { Folder } from "@mui/icons-material";
import { getFileIcon } from "app/pages/shared/Attachments/fileAttachmentUtils";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import colors from "theme/palette/colors";

const DocumentOverviewBrickIcon = ({ item }: OverviewRowBrickProps) => {
  const isFolderItem = !item.fileType;

  return isFolderItem ? <Folder sx={{ fill: colors.grey.grey500 }} /> : getFileIcon(item.title);
};

export default DocumentOverviewBrickIcon;
