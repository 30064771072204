import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import { TomStatus, useUpdateTOMApi } from "app/api/tomApi";
import { COLLECTIONS } from "app/collections";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import stopEvent from "tool/stopEvent";

const TomOverviewBrickStatus = ({ item, onBlur }: OverviewRowBrickProps) => {
  const { id, status } = item;
  const { t } = useTranslation("tom_description_tab");
  const [ref, setRef] = useState<Element | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useOverviewDispatch();
  const { updateTOMApi } = useUpdateTOMApi({ documentId: id || "" });

  const reload = useCallback(() => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.TOM, reload });
  }, [dispatch]);

  const openMenuCallback = useCallback(
    event => {
      stopEvent(event);
      onBlur?.();
      setRef(event?.currentTarget);
      setOpen(true);
    },
    [onBlur]
  );

  const closeMenuCallback = useCallback(() => {
    setRef(null);
    setOpen(false);
  }, []);

  const statuses = useMemo(
    () => [
      {
        id: "PLANNED",
        icon: <LazySvgIcon name="Draft" />,
        label: t(`status_PLANNED`),
        status: "PLANNED",
        selected: status === "PLANNED"
      },
      {
        id: "IMPLEMENTED",
        icon: <LazySvgIcon name="Completed" />,
        label: t(`status_IMPLEMENTED`),
        status: "IMPLEMENTED",
        selected: status === "IMPLEMENTED"
      }
    ],
    [status, t]
  );

  const onChangeStatusCallback = useCallback(
    async (status: string) => {
      closeMenuCallback();
      await updateTOMApi({ status: status as TomStatus });
      reload();
    },
    [closeMenuCallback, reload, updateTOMApi]
  );

  if (!status) {
    return <></>;
  }

  return (
    <ClickAwayListener onClickAway={closeMenuCallback}>
      <>
        <Tooltip title={t(`status_${status}`)}>
          <Box sx={{ height: "24px" }} onClick={openMenuCallback}>
            {statuses.find(it => it.status === status)?.icon}
          </Box>
        </Tooltip>
        <DropDownMenu
          open={open}
          anchorEl={ref}
          items={statuses}
          onChange={onChangeStatusCallback}
          onClose={closeMenuCallback}
        />
      </>
    </ClickAwayListener>
  );
};

export default TomOverviewBrickStatus;
