import { Box, Tooltip } from "@mui/material";
import { ANSWERSET_STATUS, ANSWERSET_STATUS_TYPES, AUDIT_STATUS } from "app/pages/audits/audit/AuditTypes";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const AnswersetOverviewBrickStatus = ({ item }: OverviewRowBrickProps) => {
  const { t } = useTranslation();

  const status = item.status;

  const answerSetStatuses = useMemo(
    () => ({
      [ANSWERSET_STATUS.PENDING]: {
        icon: <LazySvgIcon name="Draft" />,
        label: t(`audit_status:pending`),
        status: ANSWERSET_STATUS.PENDING
      },
      [ANSWERSET_STATUS.INPROGRESS]: {
        icon: <LazySvgIcon name="In_Progress" />,
        label: t(`audit_status:${ANSWERSET_STATUS.INPROGRESS}`),
        status: ANSWERSET_STATUS.INPROGRESS
      },
      [ANSWERSET_STATUS.COMPLETED]: {
        icon: <LazySvgIcon name="Completed" />,
        label: t(`audit_status:${AUDIT_STATUS.COMPLETED}`),
        status: ANSWERSET_STATUS.COMPLETED
      }
    }),
    [t]
  );

  return (
    <Tooltip title={answerSetStatuses[status as ANSWERSET_STATUS_TYPES]?.label}>
      <Box>{answerSetStatuses[status as ANSWERSET_STATUS_TYPES]?.icon}</Box>
    </Tooltip>
  );
};

export default AnswersetOverviewBrickStatus;
