import React from "react";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import colors from "theme/palette/colors";
import { Box, Button, Icon, Tooltip, Typography } from "@mui/material";

export enum ChipVariant {
  CIRCLE_AVATAR = "circleAvatar",
  CIRCLE_DASHED = "circleDashed",
  CONTAINED = "contained",
  DASHED = "dashed",
  ROUNDED_FILLED = "roundedFilled",
  OUTLINED = "outlined"
}

interface ChipStyles {
  backgroundColor?: string;
  borderRadius?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  height?: string;
  leftIconMarginLeft?: string;
  leftIconMarginRight?: string;
  noTextTransform?: boolean;
  rightIconColor?: string;
  rightIconMarginLeft?: string;
  rightIconMarginRight?: string;
  rightIconSize?: string;
}

export interface ChipProps {
  disabled?: boolean;
  icon?: React.ElementType;
  label?: string;
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
  rightIconToolTip?: string;
  styles?: ChipStyles;
  variant: ChipVariant;
  onClick?: () => void;
  onRightIconClick?: (event: React.MouseEvent) => void;
}

interface ChipsStackProps {
  chips: ChipProps[];
  header?: string;
  readOnly?: boolean;
}

export default function ChipsStack({ header, chips, readOnly = false }: ChipsStackProps) {
  const theme = useTheme();

  // Base styles shared by all chip variants
  const baseChipStyles = (styles: ChipStyles = {}): SxProps<Theme> => ({
    color: theme.palette.primary.main,
    fontSize: "10px",
    fontWeight: 600,
    height: theme.spacing(3.5),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.75, 1),
    textTransform: styles.noTextTransform ? "none" : "uppercase",
    "&:hover": { cursor: "pointer" },
    ...styles
  });

  // Style configurations for each chip variant
  const variantStyles: Record<ChipVariant, (styles: ChipStyles) => SxProps<Theme>> = {
    [ChipVariant.CONTAINED]: styles => ({
      ...baseChipStyles(styles),
      backgroundColor: styles.backgroundColor || "rgba(235, 241, 255, 1)",
      borderRadius: styles.borderRadius || theme.spacing(1),
      "&:hover": { backgroundColor: theme.palette.grey[300] }
    }),

    [ChipVariant.DASHED]: styles => ({
      ...baseChipStyles(styles),
      backgroundColor: "transparent",
      border: `1px dashed ${theme.palette.grey[400]}`,
      "& svg": {
        width: "16px",
        height: "16px",
        marginLeft: "-4px",
        marginTop: "-2px"
      },
      "&:hover": { backgroundColor: theme.palette.grey[100] }
    }),

    [ChipVariant.CIRCLE_DASHED]: styles => ({
      ...baseChipStyles(styles),
      backgroundColor: "transparent",
      border: "1px dashed rgba(164, 167, 168, 1)",
      borderRadius: "50%",
      color: theme.palette.grey[600],
      height: theme.spacing(3),
      width: theme.spacing(3),
      minWidth: "unset",
      padding: 0,
      "&:hover": { backgroundColor: theme.palette.grey[100] }
    }),

    [ChipVariant.CIRCLE_AVATAR]: styles => ({
      ...baseChipStyles(styles),
      borderRadius: "50%",
      height: theme.spacing(3),
      width: theme.spacing(3),
      minWidth: "unset",
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": { backgroundColor: theme.palette.primary.dark }
    }),

    [ChipVariant.ROUNDED_FILLED]: styles => ({
      ...baseChipStyles(styles),
      backgroundColor: "rgba(235, 241, 255, 1)",
      borderRadius: "22px",
      padding: theme.spacing(0.5, 1),
      "&:hover": { backgroundColor: theme.palette.grey[200] }
    }),

    [ChipVariant.OUTLINED]: styles => ({ ...baseChipStyles(styles), color: colors.white })
  };

  // Helper functions for rendering chip components
  const getChipStyles = (variant: ChipVariant, styles: ChipStyles = {}): SxProps<Theme> =>
    variantStyles[variant](styles);

  const IconWithTooltip = ({ icon: IconComponent, tooltip, onClick, styles }: any) => (
    <Tooltip title={tooltip || ""} disableHoverListener={!tooltip}>
      <Icon onClick={onClick} sx={styles} component={IconComponent} />
    </Tooltip>
  );

  const renderChipContent = (chip: ChipProps) => (
    <>
      {chip.leftIcon && (
        <Icon
          sx={{
            marginLeft: chip.styles?.leftIconMarginLeft || "4px",
            marginRight: chip.styles?.leftIconMarginRight || "4px"
          }}
          component={chip.leftIcon}
        />
      )}
      {chip.label}
      {chip.rightIcon && (
        <IconWithTooltip
          icon={chip.rightIcon}
          tooltip={chip.rightIconToolTip}
          onClick={readOnly ? undefined : chip.onRightIconClick}
          styles={{
            color: chip.styles?.rightIconColor || theme.palette.primary.main,
            cursor: readOnly ? "default" : "pointer",
            marginLeft: chip.styles?.rightIconMarginLeft || "4px",
            marginRight: chip.styles?.rightIconMarginRight || "4px"
          }}
        />
      )}
    </>
  );

  const renderCircleContent = (chip: ChipProps) =>
    chip.icon && <Icon component={chip.icon as React.ElementType} sx={{ padding: theme.spacing(0.5), opacity: 0.5 }} />;

  return (
    <Box>
      {header && (
        <Typography variant="h5" component="div">
          {header}
        </Typography>
      )}
      <Box>
        {chips.map((chip, index) => {
          const isCircleVariant =
            chip.variant === ChipVariant.CIRCLE_DASHED || chip.variant === ChipVariant.CIRCLE_AVATAR;

          return (
            <Button
              disableElevation
              disableRipple
              disabled={chip.disabled}
              key={`chip-${index}`}
              onClick={readOnly ? undefined : chip.onClick}
              sx={getChipStyles(chip.variant, chip.styles)}
              variant={chip.variant === ChipVariant.DASHED ? "text" : "contained"}
            >
              {isCircleVariant ? renderCircleContent(chip) : renderChipContent(chip)}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
}
