import React, { useCallback, useEffect, useState } from "react";
import { Box, Breadcrumbs, IconButton, Link, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { useDocumentName } from "app/contexts/document-name-context";
import { usePathName } from "app/router/router-custom-hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { pathMapping } from "../Sidebar/Sidebar";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import stopEvent from "tool/stopEvent";
import colors from "theme/palette/colors";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { styled } from "@mui/material/styles";
import theme from "theme/theme";
import { useProcessVariations } from "../Sidebar/useProcessVariations";

const StyledBreadcrumbs = styled(Breadcrumbs)`
  width: 100%;
  .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
    display: flex;
    overflow: hidden;
  }
  .MuiBreadcrumbs-separator {
    flex-shrink: 0;
    margin-left: 4px;
    margin-right: 4px;
  }
  .MuiBreadcrumbs-li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .MuiBreadcrumbs-li:not(:first-of-type) {
    flex: 1;
  }
  .MuiBreadcrumbs-li:not(:first-of-type) .MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const sx = {
  link: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    "&:first-of-type": {
      textTransform: "capitalize"
    }
  },
  linkLabel: {
    display: "flex",
    alignItems: "center"
  },
  text: {
    display: "flex",
    alignItems: "center",
    cursor: "not-allowed",
    color: colors.grey.grey400
  },
  textIcon: { display: "inline-flex", alignItems: "center", paddingRight: 1 },
  textLabel: {
    color: colors.grey.grey400,
    textTransform: "capitalize"
  },
  chevron: { height: "20px", width: "20px" },
  documentName: {
    cursor: "not-allowed",
    color: "grey.400"
  }
};

interface BreadcrumbLinkProps {
  readonly path: string;
  readonly icon: React.ReactNode;
  readonly label: string;
  readonly onClick: (path: string) => void;
}
const BreadcrumbLink = ({ path, icon, label, onClick }: BreadcrumbLinkProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const open = Boolean(anchorEl);

  const onCLickCalbback = useCallback(
    event => {
      stopEvent(event);
      onClick(path);
    },
    [onClick, path]
  );

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const menuClick = useCallback(() => {
    setAnchorEl(null);
    onClick(path);
  }, [onClick, path]);

  const smallBreadCrumb = (
    <Box sx={sx.linkLabel}>
      {icon}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} aria-labelledby="menu-for-breadcrumbs">
        <MenuItem onClick={menuClick}>{label}</MenuItem>
      </Menu>
      <IconButton style={{ marginLeft: "8px", padding: "0px 5px" }} onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
    </Box>
  );

  const bigBreadCrumb = (
    <Link underline="hover" color="inherit" onClick={onCLickCalbback} sx={sx.link}>
      <Box sx={{ ...sx.linkLabel, paddingRight: 1 }}>{icon}</Box>
      {label}
    </Link>
  );

  return <>{!smallScreen ? bigBreadCrumb : smallBreadCrumb}</>;
};

interface BreadcrumbTextProps {
  readonly icon: React.ReactNode;
  readonly label: string;
}
const BreadcrumbText = ({ icon, label }: BreadcrumbTextProps) => {
  return (
    <Box sx={sx.text}>
      <Box sx={sx.textIcon}>{icon}</Box>
      <Typography variant="body2" sx={sx.textLabel}>
        {label}
      </Typography>
    </Box>
  );
};

export const TopbarBreadcrumbs = () => {
  const { documentName, clearDocumentName } = useDocumentName(); // Retrieve the document name
  const path = usePathName();
  const { t } = useTranslation("sidebar");
  const [rootPath, setRootPath] = useState<string>("");
  const [rootTargetPath, setRootTargetPath] = useState<string>("");
  const [rootIcon, setRootIcon] = useState<React.ReactNode>();
  const [firstChildrenPath, setFirstChildrenPath] = useState<string>("");
  const [firstChildrenLabel, setFirstChildrenLabel] = useState<string>("");
  const [rootLabel, setRootLabel] = useState<string>("");

  const navigate = useNavigate();

  const { getProcessDetailSourceOverview } = useProcessVariations();

  useEffect(() => {
    const init = async () => {
      // Exit early if path is not available
      if (!path) {
        return;
      }

      // path is something like /processes/123
      const pathSegments = path.split("/"); // Split the path into segments
      let rootKey = pathSegments[1]; // Get the first level of the path

      // Check if the root path exists in pathMapping
      if (!pathMapping[rootKey]) {
        return;
      }

      // this part is a hack to switch path mapping breadcrumb if it's related to one of pa overview / assessment variations..
      if (rootKey === "processes") {
        const processesOverviewSource = (await getProcessDetailSourceOverview(path)) || "";
        switch (processesOverviewSource) {
          case "aiact":
            rootKey = "aiacts";
            break;
          case "business":
            rootKey = "bp";
            break;
          case "dpia":
            rootKey = "dpias";
            break;
        }
      } else if (rootKey === "audits") {
        switch (pathSegments[2]) {
          case "instances":
            rootKey = "audits";
            break;
          case "templates":
            rootKey = "templates";
            break;
          default:
            rootKey = "answersets";
            break;
        }
      }

      const root = pathMapping[rootKey]; // Get root mapping
      // Set root-level properties
      setRootPath(rootKey);
      setRootTargetPath(root.targetPath ?? `/${rootKey}`);
      setRootIcon(root.icon);
      setRootLabel(t(root.label));

      const childKey = pathSegments[2];
      const childPathMapping =
        childKey && root.children !== undefined ? root.children[childKey] || root.children["default"] : undefined;
      if (childPathMapping) {
        clearDocumentName();
        setFirstChildrenPath(childKey);
        setFirstChildrenLabel(t(childPathMapping?.label || "")); // Translate label or set empty
        return;
      }

      // Reset child path and label if not under "resources"
      setFirstChildrenPath("");
      setFirstChildrenLabel("");
    };

    init();
  }, [path, t, clearDocumentName, getProcessDetailSourceOverview]);

  const onLinkClick = useCallback(
    (path: string | undefined) => {
      if (path) {
        clearDocumentName();
        navigate(path);
      }
    },
    [navigate, clearDocumentName]
  );

  return (
    <Box sx={{ paddingLeft: 8.8 }}>
      <StyledBreadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon sx={sx.chevron} />}>
        {documentName || firstChildrenLabel ? (
          <BreadcrumbLink path={rootTargetPath} icon={rootIcon} label={rootLabel} onClick={onLinkClick} />
        ) : (
          <BreadcrumbText label={rootLabel} icon={rootIcon} />
        )}
        {firstChildrenPath && <Typography sx={sx.documentName}>{firstChildrenLabel}</Typography>}
        {documentName && <Typography sx={sx.documentName}>{documentName}</Typography>}
      </StyledBreadcrumbs>
    </Box>
  );
};
