import { useCallback, useMemo } from "react";
import UserAvatar, { UserAvatarProps } from "./UserAvatar";
import { Box, IconButton, useTheme } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";

interface UserAvatarListProps {
  readonly avatars: UserAvatarProps[];

  readonly onRemove?: (val: string) => void;
  readonly onAdd?: () => void;
  readonly disabled?: boolean;
}

const UserAvatarList = ({ avatars, onRemove, onAdd, disabled }: UserAvatarListProps) => {
  const theme = useTheme();
  const sx = {
    root: {
      gap: "4px",
      display: "flex"
    },
    addButton: {
      width: "24px",
      height: "24px",
      border: `1px dashed ${theme.palette.grey[500]}`
    }
  };

  const onAddCallback = useCallback(
    e => {
      e.stopPropagation();
      onAdd?.();
    },
    [onAdd]
  );

  const addEl = useMemo(() => {
    if (onAdd) {
      return (
        <IconButton sx={sx.addButton} onClick={onAddCallback} disabled={disabled}>
          <AddIcon fontSize="small" style={{ color: theme.palette.grey[500], fontSize: "12px" }} />
        </IconButton>
      );
    } else return <></>;
  }, [onAdd, onAddCallback, sx.addButton, theme.palette.grey, disabled]);

  const avatarsEl = useMemo(() => {
    return (
      <>
        {avatars?.map(({ userId, label, color, size }, index) => (
          <UserAvatar
            key={`${userId}-${label}-${index}`}
            userId={userId}
            label={label}
            color={color}
            size={size}
            onRemove={disabled ? undefined : onRemove}
          />
        ))}
        {avatars?.length > 0 && <Box mr={1} />}
        {addEl}
      </>
    );
  }, [addEl, avatars, onRemove, disabled]);
  return <Box sx={sx.root}>{avatarsEl}</Box>;
};

export default UserAvatarList;
