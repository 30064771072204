import { Box, Divider } from "@mui/material";
import { PRIORITIES } from "app/handlers/tasksHandler";
import ChipsStack, { ChipVariant } from "components/ChipsStack/ChipsStack";
import { useTranslation } from "react-i18next";

const sx = {
  chip: {
    active: {},
    notActive: {}
  }
};

interface TaskPriorityProps {
  readonly priority: string;
  readonly disabled?: boolean;
  readonly onChangePriority?: (priority: string) => void;
}
const TaskPriority = ({ priority, onChangePriority, disabled }: TaskPriorityProps) => {
  const { t } = useTranslation("task_details");

  return (
    <>
      <ChipsStack
        chips={Object.values(PRIORITIES).map(value => ({
          label: t("priority_" + value),
          value: value,
          variant: priority === value ? ChipVariant.OUTLINED : ChipVariant.CONTAINED,
          onClick: () => {
            onChangePriority?.(value);
          },
          size: "medium"
        }))}
        header={t("priority")}
        readOnly={disabled}
      />
      <Box mt={1} />
      <Divider />
    </>
  );
};

export default TaskPriority;
