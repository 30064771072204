import { getDataLocationsOverviewApi } from "app/api/externalRecipientApi";
import { createAndDownloadFile } from "app/export/createAndDownloadFile";
import { getTenantInformation } from "app/handlers/tenantHandler";
import { writeXLSX } from "app/handlers/xlsxToJsonhandler";
import { getDocumentTitle } from "app/utils/get-title-for-exported-document";
import { TFunction } from "i18next";

export async function exportDataLocationsExcel(input: { readonly tenantId: string; readonly t: TFunction }) {
  const dataLocations = await getDataLocationsOverviewApi();
  const tenantData = await getTenantInformation(input.tenantId);
  if (!tenantData) {
    throw new Error(`Current tenant does not exist?! ${input.tenantId}`);
  }
  const excelData = dataLocations.items.map(({ id, title }) => ({ id, title }));
  const sheetName = input.t("service_providers_overview:data_tab_title");
  const buffer = await writeXLSX({
    sheets: [
      {
        sheetName: sheetName.replace("/", ""),
        data: excelData,
        columnOrder: ["id", "title"] satisfies MappingFilesColumn[]
      }
    ]
  });
  const fileName = `${getDocumentTitle(tenantData.name, input.t("service_providers_overview:data_tab_title"))}.xlsx`;
  await createAndDownloadFile(
    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
    fileName
  );
}

type MappingFilesColumn = "id" | "title";
