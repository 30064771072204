import React, { useCallback, useEffect, useMemo, useState } from "react";
import AccordionMultiField from "components/AccordionMultiField/AccordionMultiField";
import { useTranslation } from "react-i18next";
import { Box, Chip, Tooltip } from "@mui/material";
import { MetaViewAIInputProps, useMetaView } from "app/contexts/meta-view-context";
import { useLegalBasisSuggestion } from "app/handlers/legalBasisHandler";
import StarIcon from "@mui/icons-material/Star";
import { debounce, isEqual } from "lodash-es";
import { ResourceField } from "../../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { useResources } from "../../../contexts/resource-context";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import Question from "components/Question/Question";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import { AIAskAIButton } from "../../../../components/AIIntegration/AIAskAIButton";
import { useProcessGeneralPage } from "../../../api/paApi";
import { useParams } from "react-router-dom";
import { getFieldKeysFromHardcodedLegalBasisId } from "./legalBasisIdToFieldMagic";
import { SxProps } from "@mui/system/styleFunctionSx";

export interface Purpose {
  readonly id: string;
  readonly purposeDescription: string;
  readonly legalBasisId: string;
  readonly lbOtherLegalBasisValue: string;
  readonly lbContractValue: string;
  readonly lbLegalObligationValue: string;
  readonly lbVerificationValue: string;
  readonly lbVitalInterestsValue: string;
  readonly lbControllerInterestValue: string;
  readonly lbInterestOfTheDataSubjectValue: string;
  readonly lbInterestBalancingValue: string;
  readonly lbConsentContentValue: string;
  readonly lbObtainingConsentValue: string;
  readonly lbPublicInterestValue: string;
  readonly lbDefaultValue: string;
}

/* PURPOSE CHIP */

const PurposeLegalBasisChip = ({ legalBasis }: { legalBasis: string }) => {
  const { t } = useTranslation("questionnaires");
  const { translateById } = useResources();

  return (
    <Chip
      sx={legalBasis ? legalBasisSx : legalBasisWarningSx}
      label={legalBasis ? translateById(RESOURCE_TYPES.LEGAL_BASIS, legalBasis) : t("noLegalBasis")}
    />
  );
};

const legalBasisSx: SxProps = {
  maxWidth: 300
};
const legalBasisWarningSx: SxProps = {
  bgcolor: "alert.warningBackground",
  color: "alert.warning"
};

/* PURPOSE CONDITIONAL TEXT */
interface PurposeConditionalTextFieldProps {
  readonly propertyName: string;
  readonly value?: string | undefined;
  readonly disabled?: boolean;
  readonly legalBasisId?: string;
  readonly onChange: (val: { [key: string]: string }) => void;
  readonly questionId?: string;
}
const PurposeConditionalTextField = ({
  propertyName,
  value,
  legalBasisId,
  onChange,
  disabled,
  questionId
}: PurposeConditionalTextFieldProps) => {
  const { t } = useTranslation("questionnaires");
  const { resources, resourcesLoaded } = useResources();
  const isDefaultLegalBasis = useMemo(() => {
    if (legalBasisId && resourcesLoaded) {
      const allLegalBasis = resources[RESOURCE_TYPES.LEGAL_BASIS];
      return Boolean(allLegalBasis.find(({ id }) => id === legalBasisId)?.defaultResource);
    } else return true;
  }, [legalBasisId, resources, resourcesLoaded]);

  const label = t(`${propertyName}:questionTitle`);

  const onChangeCallback = useCallback(
    value => {
      onChange({ [propertyName]: value });
    },
    [onChange, propertyName]
  );
  return (
    <Question
      qType={QUESTION_TYPE.TEXT_AREA}
      questionName={label}
      translatable={value}
      translationId={`${questionId} ${propertyName}`}
      value={value || ""}
      onChange={onChangeCallback}
      disabled={disabled}
      infoId={`infocard.pa.page2.purposeAccordion.${isDefaultLegalBasis ? propertyName : "isDefaultLegalBasis"}`}
      pt={1}
      pb={1}
    />
  );
};

/* PURPOSE ACCORDION */
type PurposeAccordionProps = {
  readonly purpose: Purpose;
  readonly paOrgUnitIds: string[];
  readonly onDelete: (id: string) => void;
  readonly onSave: (val: Purpose) => void;
  readonly isCreatedNew?: boolean;
  readonly disabled?: boolean;
  readonly questionId?: string;
};
const PurposeAccordion = ({
  purpose,
  onDelete,
  onSave,
  isCreatedNew,
  disabled,
  paOrgUnitIds,
  questionId
}: PurposeAccordionProps) => {
  const { t } = useTranslation("questionnaires");
  const { suggestLegalBasis } = useLegalBasisSuggestion();
  const { setInfoId } = useMetaView();
  const { auth } = useAuthentication();
  const canReadLegalBasisField = auth?.permissions.includes("pa_read_legalbasis_org");
  const canWriteLegalBasisField = auth?.permissions.includes("pa_write_legalbasis_org");
  const [currentPurpose, setCurrentPurpose] = useState<Purpose>(purpose || {});
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    const diff = Object.keys(currentPurpose).some(
      key => !isEqual(currentPurpose[key as keyof Purpose], purpose[key as keyof Purpose])
    );
    setDirty(diff);
  }, [currentPurpose, purpose]);

  useEffect(() => {
    if (purpose) {
      setCurrentPurpose(purpose);
    }
  }, [purpose]);
  const [suggestedId, setSuggestedId] = useState<string | null>(null);

  const refreshSuggestedLegalBasis = useMemo(
    () =>
      debounce(async name => {
        const data = await suggestLegalBasis(name);
        setSuggestedId(data?.id ? data.id : null);
      }, 1000),
    [suggestLegalBasis]
  );

  useEffect(() => {
    if (currentPurpose.purposeDescription) {
      refreshSuggestedLegalBasis(currentPurpose.purposeDescription);
    }
  }, [currentPurpose.purposeDescription, refreshSuggestedLegalBasis]);

  const updateCurrentPurpose = useCallback((updatedFields: Partial<Purpose>) => {
    setCurrentPurpose(currentPurpose => ({ ...currentPurpose, ...updatedFields }));
  }, []);

  /* TITLE */
  const legalBasisChipEl = canReadLegalBasisField && <PurposeLegalBasisChip legalBasis={currentPurpose.legalBasisId} />;
  const titleEl = (
    <Box display="flex" alignItems="center" width="100%">
      <Box flexGrow={1} sx={!currentPurpose.purposeDescription ? { color: "grey.500", fontStyle: "italic" } : {}}>
        {currentPurpose.purposeDescription || t("0_2_purposes:placeholder")}
      </Box>
      <Box>{legalBasisChipEl}</Box>
    </Box>
  );

  /* CONTENT */
  const onChangeName = useCallback(
    purposeDescription => {
      updateCurrentPurpose({ purposeDescription });
    },
    [updateCurrentPurpose]
  );

  const purposeNameEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_AREA}
      questionName={t("0_2_purposes:questionTitle")}
      value={currentPurpose.purposeDescription || ""}
      translatable={currentPurpose.purposeDescription || ""}
      translationId={`${questionId} ${purpose.id} purposeDescription`}
      onChange={onChangeName}
      error={!currentPurpose.purposeDescription}
      helperText={!currentPurpose.purposeDescription ? t("questionnaires:mandatoryField") : ""}
      disabled={disabled}
      infoId={"infocard.pa.page2.purposeAccordion.purpose"}
      pt={0}
      pb={0}
    />
  );
  const contentBasicEl = <Box>{purposeNameEl}</Box>;

  const onChangeLegalBasis = useCallback(
    legalBasisId => {
      updateCurrentPurpose({ legalBasisId: legalBasisId });
    },
    [updateCurrentPurpose]
  );

  const onFocusLegalBasis = useCallback(() => setInfoId("infocard.pa.page2.purposeAccordion.legalBasis"), [setInfoId]);

  const renderOptionEl = useCallback(
    ({ key, ...restProps }, resourceId, translatedResource) => (
      <Box my={1} display="flex" justifyContent="center" width="100%" key={key} {...restProps}>
        <Box flexGrow={1}>{translatedResource}</Box>
        {resourceId === suggestedId && (
          <Box>
            <Tooltip title={t("recommendedLegalBasis")}>
              <StarIcon fontSize="small" style={{ color: "rgb(76, 175, 80)" }} />
            </Tooltip>
          </Box>
        )}
      </Box>
    ),
    [suggestedId, t]
  );
  const contentExpertEl = (
    <Box mt={1} mb={3} display="flex" justifyContent="space-between" alignItems="baseline">
      <Box flex={1} mr={6}>
        {purposeNameEl}
      </Box>
      <Box flex={1} ml={2}>
        <ResourceField
          label={t("legalBasis:questionTitle")}
          onChange={onChangeLegalBasis}
          value={currentPurpose.legalBasisId}
          resourceType={RESOURCE_TYPES.LEGAL_BASIS}
          multiSelect={false}
          onFocus={onFocusLegalBasis}
          renderOption={renderOptionEl}
          docOrgUnitIds={paOrgUnitIds}
          onlyIntersectingOrgUnitIds={true}
          disabled={disabled || !canWriteLegalBasisField}
        />
      </Box>
    </Box>
  );

  const onChangeConditionalFields = useCallback(
    data => {
      updateCurrentPurpose(data);
    },
    [updateCurrentPurpose]
  );

  const conditionalFields = useMemo(
    () => getFieldKeysFromHardcodedLegalBasisId(currentPurpose?.legalBasisId || ""),
    [currentPurpose?.legalBasisId]
  );

  const renderConditionalFieldsEl = (
    <>
      {conditionalFields.map(field => (
        <PurposeConditionalTextField
          key={field}
          legalBasisId={currentPurpose.legalBasisId}
          propertyName={field}
          value={currentPurpose[field as keyof Purpose]}
          onChange={onChangeConditionalFields}
          disabled={disabled || !canWriteLegalBasisField}
          questionId={questionId}
        />
      ))}
    </>
  );

  const onClickDelete = useCallback(() => onDelete(purpose.id), [onDelete, purpose.id]);
  const onClickSave = useCallback(() => {
    onSave(currentPurpose);
    setDirty(false);
  }, [currentPurpose, onSave]);

  const { aiButtonEl } = useAIIntegrationForPurposeAccordion({
    conditionalFields,
    canWriteLegalBasisField: !!canWriteLegalBasisField,
    currentPurpose,
    updateCurrentPurpose,
    questionId
  });

  return (
    <AccordionMultiField
      id={"purposes " + currentPurpose.id}
      key={currentPurpose.id}
      field={"purposes"}
      title={titleEl}
      hasCancelAndSave={true}
      onClickSave={onClickSave}
      onClickCancel={undefined}
      disableButton={disabled}
      disableOnlySaveButton={!currentPurpose.purposeDescription}
      isNewMultiFiled={isCreatedNew}
      deleteMultiField={onClickDelete}
      dirty={dirty}
      additionalRightButton={aiButtonEl}
    >
      {canReadLegalBasisField ? contentExpertEl : contentBasicEl}
      {canReadLegalBasisField && renderConditionalFieldsEl}
    </AccordionMultiField>
  );
};

const useAIIntegrationForPurposeAccordion = ({
  conditionalFields,
  canWriteLegalBasisField,
  currentPurpose,
  updateCurrentPurpose,
  questionId
}: {
  conditionalFields: string[];
  canWriteLegalBasisField: boolean;
  currentPurpose?: {
    purposeDescription: string;
    legalBasisId: string;
  };
  updateCurrentPurpose: (updatedFields: Partial<Purpose>) => void;
  questionId?: string;
}) => {
  const { t } = useTranslation();
  const { translateById, resources, create } = useResources();
  const {
    questionId: metaviewQuestionId,
    setAIIntegrationSuggestionCallback,
    setAIIntegrationMode,
    setAIIntegrationFieldInput
  } = useMetaView();
  const params = useParams();
  const aiIntegrationEnabled = useIsFeaturePresent(FEATURES.AI_INTEGRATION);
  const generalPage = useProcessGeneralPage({ paId: aiIntegrationEnabled ? params.id : undefined });
  const legalBasisTranslatedNameToId = useMemo<Map<string, string>>(() => {
    if (!aiIntegrationEnabled) {
      return new Map();
    }

    const legalBasis = resources[RESOURCE_TYPES.LEGAL_BASIS] || [];
    return new Map(
      legalBasis.map(({ nameKey, id, defaultResource }) => [
        defaultResource ? translateById(RESOURCE_TYPES.LEGAL_BASIS, id) : nameKey,
        id
      ])
    );
  }, [aiIntegrationEnabled, resources, translateById]);
  const purposeAIFieldInput = useMemo(() => {
    const fields: string[] = ["purposeDescription"];
    if (canWriteLegalBasisField) {
      fields.push("legalBasisName");
    }
    fields.push(...conditionalFields);

    const initialSuggestions: string[] = [];
    if (generalPage?.data?.processMeta?.title) {
      initialSuggestions.push(generalPage.data.processMeta.title);
    }
    if (currentPurpose?.purposeDescription) {
      initialSuggestions.push(currentPurpose.purposeDescription);
    }
    if (currentPurpose?.legalBasisId) {
      initialSuggestions.push(translateById(RESOURCE_TYPES.LEGAL_BASIS, currentPurpose.legalBasisId));
    }
    // can be dropped once we started to fine tune our models
    const aiFriendlyFields = fields.map(toAIFriendlyField);
    return {
      fields: aiFriendlyFields,
      tenantContext: {
        // the prop name have to be self-explanatory so that GPT can understand it better
        // this is not required later when we have more specific query per field or fine tuned AI
        alreadyExistingLegalBasisNames: [...legalBasisTranslatedNameToId.keys()]
      },
      initialSuggestions: initialSuggestions,
      parseOutput: response => {
        return (
          <Box>
            {aiFriendlyFields.flatMap(field => {
              const hasValue = field in response;
              if (!hasValue) {
                return [];
              }
              const value = (response as any)[field];
              if (!value) {
                return [];
              }
              if (typeof value !== "string") {
                return [];
              }

              const originalField = toOriginalField(field);
              const title =
                originalField === "purposeDescription"
                  ? t("0_2_purposes:questionTitle")
                  : originalField === "legalBasisName"
                    ? t("legalBasis:questionTitle")
                    : t(`${originalField}:questionTitle`, originalField);

              return (
                <Box key={originalField} mb={1}>
                  <strong>{title}:</strong> {value}
                </Box>
              );
            })}
          </Box>
        );
      }
    } satisfies MetaViewAIInputProps;
  }, [
    canWriteLegalBasisField,
    conditionalFields,
    generalPage?.data?.processMeta?.title,
    currentPurpose?.purposeDescription,
    currentPurpose?.legalBasisId,
    translateById,
    legalBasisTranslatedNameToId,
    t
  ]);

  const onSuggestionCallback = useCallback(
    async (suggestions: object, originalInput: MetaViewAIInputProps) => {
      const originalFieldKeys = new Set(originalInput?.fields || []);
      let suggestionWithValidKeys = Object.entries(suggestions || {})
        .filter(([key]) => originalFieldKeys.has(key))
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [toOriginalField(key)]: value
          }),
          {}
        );

      if (
        "legalBasisName" in suggestionWithValidKeys &&
        typeof suggestionWithValidKeys["legalBasisName"] === "string"
      ) {
        const { legalBasisName, ...restSuggestions } = suggestionWithValidKeys;
        const existingLegalBasisId = legalBasisTranslatedNameToId.get(legalBasisName);
        const existingLegalBasis = resources[RESOURCE_TYPES.LEGAL_BASIS].find(
          legalBasis => legalBasis.id === existingLegalBasisId
        );
        if (existingLegalBasis) {
          suggestionWithValidKeys = {
            ...restSuggestions,
            legalBasisId: existingLegalBasis.id || ""
          };
        } else {
          const createdLegalBasisId = await create(RESOURCE_TYPES.LEGAL_BASIS, legalBasisName);
          suggestionWithValidKeys = {
            ...restSuggestions,
            legalBasisId: createdLegalBasisId
          };
        }
      }

      if (
        "lbDefaultValue" in suggestionWithValidKeys &&
        suggestionWithValidKeys.lbDefaultValue &&
        "legalBasisId" in suggestionWithValidKeys &&
        suggestionWithValidKeys.legalBasisId
      ) {
        const hardcodedConditionalFields = getFieldKeysFromHardcodedLegalBasisId(
          suggestionWithValidKeys.legalBasisId as string
        );
        if (!hardcodedConditionalFields.includes("lbDefaultValue")) {
          const lbDefaultValue = suggestionWithValidKeys.lbDefaultValue;
          suggestionWithValidKeys = {
            ...suggestionWithValidKeys,
            [hardcodedConditionalFields[hardcodedConditionalFields.length - 1]]: lbDefaultValue,
            lbDefaultValue: ""
          };
        }
      }

      updateCurrentPurpose(suggestionWithValidKeys);
    },
    [create, legalBasisTranslatedNameToId, resources, updateCurrentPurpose]
  );

  const aiButtonEl = aiIntegrationEnabled ? (
    <AIAskAIButton fieldInput={purposeAIFieldInput} onSuggestionCallback={onSuggestionCallback} />
  ) : undefined;

  useEffect(() => {
    if (metaviewQuestionId === questionId) {
      setAIIntegrationMode("suggest-field");
      setAIIntegrationFieldInput(purposeAIFieldInput);
      setAIIntegrationSuggestionCallback(() => onSuggestionCallback);
      return () => {
        setAIIntegrationMode(null);
        setAIIntegrationFieldInput({ fields: [] });
        setAIIntegrationSuggestionCallback(undefined);
      };
    }
  }, [
    metaviewQuestionId,
    onSuggestionCallback,
    purposeAIFieldInput,
    questionId,
    setAIIntegrationFieldInput,
    setAIIntegrationMode,
    setAIIntegrationSuggestionCallback
  ]);

  return {
    aiButtonEl
  };
};

const toAIFriendlyField = (field: string) => {
  return field === "lbDefaultValue" ? "legalBasisHowToProveDescription" : field;
};

const toOriginalField = (field: string) => {
  return field === "legalBasisHowToProveDescription" ? "lbDefaultValue" : field;
};

export default PurposeAccordion;
