import { useMetaView } from "app/contexts/meta-view-context";
import React, { useCallback } from "react";
import AdvancedQuestion from "./AdvancedQuestion";
import { QUESTION_TYPE, QUESTION_TYPE_TYPES } from "./QuestionTypes";
import SimpleQuestion from "./SimpleQuestion";
import { TranslationQuestion } from "./TranslationQuestion";
import TextAreaQuestion from "./types/basic/TextAreaQuestion";
import ResponsibleOrgUnitQuestion from "./types/orgUnits/ResponsibleOrgUnitQuestion";
import FurtherOrgUnitQuestion from "./types/orgUnits/FurtherOrgUnitQuestion";
import AssignToUserQuestion from "./types/users/AssignToUserQuestion";
import DateQuestion from "./types/basic/DateQuestion";
import LabelsQuestion from "./types/resources/LabelsQuestion";
import PrivacyExpertUserQuestion from "./types/users/PrivacyExpertUserQuestion";
import LegalRetentionPeriodsQuestion from "./types/resources/LegalRetentionPeriodsQuestion";
import NumberQuestion from "./types/basic/NumberQuestion";
import UnitOfTimeQuestion from "./types/basic/UnitOfTimeQuestion";
import DeletionTriggerQuestion from "./types/resources/DeletionTriggerQuestion";
import TextEditorQuestion from "./types/basic/TextEditorQuestion";
import DataDeletionTypesQuestion from "./types/resources/DataDeletionTypesQuestion";
import { COLLECTION_TYPES } from "app/collections";
import DocumentTypesQuestion from "./types/resources/DocumentTypesQuestion";
import DataSourceDataStorageQuestion from "./types/resources/DataSourceDataStorageQuestion";
import InternalDataRecipientsQuestion from "./types/resources/InternalDataRecipientsQuestion";
import ExternalDataRecipientsQuestion from "./types/resources/ExternalDataRecipientsQuestion";
import AffectedOrgUnitQuestion from "./types/orgUnits/AffectedOrgUnitQuestion";
import { RESOURCE_TYPE } from "app/handlers/resourceHandler";
import ResourceQuestion from "./types/resources/ResourceQuestion";
import DateTimeQuestion from "./types/basic/DateTimeQuestion";
import PersonGroupsQuestion from "./types/resources/PersonGroupsQuestion";
import DataCategoryQuestion from "./types/resources/DataCategoryQuestion";
import DpoUserQuestion from "./types/users/DpoUserQuestion";
import ProtectionObjectiveQuestion from "./types/resources/ProtectionObjectiveQuestion";
import PAsQuestions from "./types/pas/PAsQuestions";
import LanguageQuestion from "./types/basic/LanguageQuestion";
import MeasuresQuestion from "./types/resources/MeasuresQuestion";
import { ASSIGNABLE_USER_FIELD } from "../../app/api/user/userApi";
import ParticipantTypeQuestion from "./types/assessment/ParticipantTypeQuestion";
import EmailQuestion from "./types/basic/EmailQuestion";
import OwnerUserQuestion from "./types/users/OwnerUserQuestion";
import OrgUnitsPickerQuestion from "./types/orgUnits/OrgUnitsPickerQuestion";
import { EditableText } from "./types/basic/EditableText";
import { YesNoQuestion } from "./types/basic/YesNoQuestion";
import { AITechDocsQuestion } from "./types/resources/AITechDocsQuestion";
import SelectUserQuestion from "./types/users/SelectUserQuestion";
import { SxProps } from "@mui/system/styleFunctionSx";
import SelectRoleQuestion from "./types/users/SelectRoleQuestion";
import DataTypeTreeQuestion from "./types/resources/DataTypeTreeQuestion";

export type QUESTION_ACTION_TYPES = "tasks" | "comments" | "remarks" | "translate";
export const QUESTION_ACTION = {
  TASKS: "tasks",
  COMMENTS: "comments",
  REMARKS: "remarks",
  TRANSLATE: "translate"
} as const satisfies Record<string, QUESTION_ACTION_TYPES>;

export type QUESTION_CHIP_TYPES = "userAvatar" | "tasks" | "comments" | "remarks" | "auditActionTasks";
export const QUESTION_CHIP = {
  USER_AVATAR: "userAvatar",
  TASKS: "tasks",
  COMMENTS: "comments",
  REMARKS: "remarks",
  AUDIT_ACTION_TASKS: "auditActionTasks"
} as const satisfies Record<string, QUESTION_CHIP_TYPES>;

export interface QuestionProps {
  readonly actions?: QUESTION_ACTION_TYPES[];
  readonly allowAdd?: boolean;
  readonly nonStandardAdditionalData?: object;
  readonly assignType?: ASSIGNABLE_USER_FIELD;
  readonly chips?: QUESTION_CHIP_TYPES[];
  readonly collection?: COLLECTION_TYPES;
  readonly disablePast?: boolean;
  readonly disabled?: boolean;
  readonly documentId?: string;
  readonly error?: boolean;
  readonly helperText?: string;
  readonly hidden?: boolean | null;
  readonly info?: { readonly title: string; readonly text: string };
  readonly infoId?: string;
  readonly multiSelect?: boolean;
  readonly multiSelectHiddenIds?: string[];
  readonly options?: any[];
  readonly blackListedIds?: string[];
  readonly orgUnitIds?: string[];
  readonly noDefaultValue?: boolean;
  /**
   * The old passed org units always expands the org units to the children.
   * With the new org units model, we only need to look for intersecting org units.
   * In the future, the default of this will be true, but not for now.
   */
  readonly onlyIntersectingOrgUnitIds?: boolean;
  readonly pb?: number;
  readonly personGroupId?: string;
  readonly pl?: number;
  readonly pr?: number;
  readonly pt?: number;
  readonly qType?: QUESTION_TYPE_TYPES;
  readonly questionId?: string;
  readonly questionName?: string;
  readonly required?: boolean;
  readonly resourceType?: RESOURCE_TYPE;
  readonly textEditorToolbarOptions?: string[];
  readonly textFieldIcon?: React.ReactNode;
  readonly title?: string;
  readonly translatable?: string;
  readonly translationId?: string;
  readonly userDepartmentBound?: boolean;
  readonly value?: string | string[] | number | boolean | null;
  readonly valueName?: string;
  readonly suggestionValueIds?: string[];
  readonly disableClearable?: boolean;
  readonly sx?: SxProps;

  readonly onBlur?: (event?: any) => void;
  readonly onChange?: (value?: any, valueName?: string) => void;
  readonly onAdd?: (value?: { readonly title: string }) => void;
  readonly onSuggestionsConfirm?: (value: string[]) => void;
  readonly onChipClick?: (val?: any) => void;
  readonly onFocus?: (event?: any) => void;
  readonly onActivate?: (event?: any) => void;
  readonly isNotDeletableChip?: (id: string) => boolean;
  readonly notDeletableChipTooltip?: (id: string) => string;

  readonly children?: React.ReactNode;
}

const QuestionBody = ({
  qType,
  actions,
  chips,
  title,
  questionId,
  questionName,
  value,
  disabled = false,
  translatable,
  translationId,
  info,
  required,
  documentId,
  collection,
  disablePast,
  orgUnitIds,
  onlyIntersectingOrgUnitIds,
  resourceType,
  multiSelect,
  allowAdd,
  nonStandardAdditionalData,
  personGroupId,
  multiSelectHiddenIds,
  options,
  blackListedIds,
  error,
  helperText,
  textEditorToolbarOptions,
  assignType,
  textFieldIcon,
  userDepartmentBound,
  suggestionValueIds,
  disableClearable,

  onFocus,
  onBlur,
  onChange,
  onAdd,
  onSuggestionsConfirm,
  onChipClick,
  onActivate,
  isNotDeletableChip,
  notDeletableChipTooltip,

  noDefaultValue
}: QuestionProps) => {
  const qProps = {
    qType,
    actions,
    chips,
    title,
    questionId,
    questionName,
    value,
    disabled,
    translatable,
    translationId,
    info,
    required,
    documentId,
    collection,
    disablePast,
    orgUnitIds,
    onlyIntersectingOrgUnitIds,
    resourceType,
    multiSelect,
    allowAdd,
    nonStandardAdditionalData,
    personGroupId,
    multiSelectHiddenIds,
    options,
    blackListedIds,
    error,
    helperText,
    textEditorToolbarOptions,
    assignType,
    textFieldIcon,
    userDepartmentBound,
    suggestionValueIds,
    disableClearable,

    onFocus,
    onBlur,
    onChange,
    onAdd,
    onSuggestionsConfirm,
    onChipClick,
    onActivate,
    isNotDeletableChip,
    notDeletableChipTooltip,

    noDefaultValue
  } satisfies QuestionProps;

  /* BASIC */
  if (qType === QUESTION_TYPE.NUMBER) {
    return React.createElement(NumberQuestion, qProps);
  } else if (qType === QUESTION_TYPE.TEXT_AREA) {
    return React.createElement(TextAreaQuestion, qProps);
  } else if (qType === QUESTION_TYPE.TEXT_EDITOR) {
    return React.createElement(TextEditorQuestion, qProps);
  } else if (qType === QUESTION_TYPE.EDITABLE_TEXT) {
    return React.createElement(EditableText, qProps);
  } else if (qType === QUESTION_TYPE.UNIT_OF_TIME) {
    return React.createElement(UnitOfTimeQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATE) {
    return React.createElement(DateQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATE_TIME) {
    return React.createElement(DateTimeQuestion, qProps);
  } else if (qType === QUESTION_TYPE.LANGUAGE) {
    return React.createElement(LanguageQuestion, qProps);
  } else if (qType === QUESTION_TYPE.EMAIL) {
    return React.createElement(EmailQuestion, qProps);
  } else if (qType === QUESTION_TYPE.RESPONSIBLE_ORG_UNIT) {
    /* ORG UNIT */
    return React.createElement(ResponsibleOrgUnitQuestion, qProps);
  } else if (qType === QUESTION_TYPE.FURTHER_ORG_UNITS) {
    return React.createElement(FurtherOrgUnitQuestion, qProps);
  } else if (qType === QUESTION_TYPE.ORG_UNITS_PICKER) {
    return React.createElement(OrgUnitsPickerQuestion, qProps);
  } else if (qType === QUESTION_TYPE.AFFECTED_ORG_UNITS) {
    return React.createElement(AffectedOrgUnitQuestion, qProps);
  } else if (qType === QUESTION_TYPE.ASSIGNED_TO_USER) {
    /* USERS */
    return React.createElement(AssignToUserQuestion, qProps);
  } else if (qType === QUESTION_TYPE.SELECT_USER) {
    /* USERS */
    return React.createElement(SelectUserQuestion, qProps);
  } else if (qType === QUESTION_TYPE.OWNER) {
    /* USERS */
    return React.createElement(OwnerUserQuestion, qProps);
  } else if (qType === QUESTION_TYPE.PRIVACY_EXPERT_USER) {
    return React.createElement(PrivacyExpertUserQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DPO_USER) {
    return React.createElement(DpoUserQuestion, qProps);
  } else if (qType === QUESTION_TYPE.RESOURCE) {
    /* RESOURCES */
    return React.createElement(ResourceQuestion, qProps);
  } else if (qType === QUESTION_TYPE.LABELS) {
    return React.createElement(LabelsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.LEGAL_RETENTION_PERIODS) {
    return React.createElement(LegalRetentionPeriodsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DELETION_TRIGGER) {
    return React.createElement(DeletionTriggerQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATA_DELETION_TYPES) {
    return React.createElement(DataDeletionTypesQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DOCUMENT_TYPES) {
    return React.createElement(DocumentTypesQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATA_SOURCE) {
    return React.createElement(DataSourceDataStorageQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATA_STORAGE) {
    return React.createElement(DataSourceDataStorageQuestion, qProps);
  } else if (qType === QUESTION_TYPE.INTERNAL_DATA_RECIPIENTS) {
    return React.createElement(InternalDataRecipientsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.MEASURE) {
    return React.createElement(MeasuresQuestion, qProps);
  } else if (qType === QUESTION_TYPE.PERSON_GROUP) {
    return React.createElement(PersonGroupsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATA_CATEGORY) {
    return React.createElement(DataCategoryQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATA_TYPE_TREE) {
    return React.createElement(DataTypeTreeQuestion, qProps);
  } else if (qType === QUESTION_TYPE.EXTERNAL_DATA_RECIPIENTS) {
    return React.createElement(ExternalDataRecipientsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.AI_TECH_DOCS) {
    return React.createElement(AITechDocsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.PROTECTION_OBJECTIVE) {
    return React.createElement(ProtectionObjectiveQuestion, qProps);
  } else if (qType === QUESTION_TYPE.PAS) {
    return React.createElement(PAsQuestions, qProps);
  } else if (qType === QUESTION_TYPE.ROLE) {
    return React.createElement(SelectRoleQuestion, qProps);
  } else if (qType === QUESTION_TYPE.PARTICIPIANT_TYPE) {
    /* ASSESSMENT */
    return React.createElement(ParticipantTypeQuestion, qProps);
  } else if (qType === QUESTION_TYPE.YES_NO) {
    /* ASSESSMENT */
    return React.createElement(YesNoQuestion, qProps);
  }

  return <>{qType}</>;
};

const Question = ({
  actions,
  allowAdd,
  nonStandardAdditionalData,
  assignType,
  chips,
  collection,
  disablePast,
  disabled = false,
  documentId,
  error,
  helperText,
  hidden,
  info,
  infoId,
  multiSelect,
  multiSelectHiddenIds,
  options,
  orgUnitIds,
  onlyIntersectingOrgUnitIds,
  pb,
  personGroupId,
  pl,
  pr,
  pt,
  qType,
  questionId,
  questionName,
  required,
  resourceType,
  textEditorToolbarOptions,
  textFieldIcon,
  title,
  translatable,
  translationId,
  userDepartmentBound,
  value,
  valueName,
  suggestionValueIds,
  isNotDeletableChip,
  notDeletableChipTooltip,
  disableClearable,
  sx,

  onBlur,
  onChange,
  onAdd,
  onSuggestionsConfirm,
  onChipClick,
  onFocus,
  onActivate,

  noDefaultValue,

  children
}: QuestionProps) => {
  const { setInfo, setInfoId } = useMetaView();

  /* EVENTS */
  const onFocusCallback = useCallback(async () => {
    if (info) {
      setInfo(info);
    }
    if (infoId) {
      setInfoId(infoId);
    }
    if (onFocus) {
      onFocus(questionId);
    }
  }, [info, infoId, onFocus, setInfo, setInfoId, questionId]);

  const onChangeCallback = useCallback(
    (value?: string | string[] | number) => {
      onChange?.(value, valueName);
    },
    [onChange, valueName]
  );

  /* QUESTION BODY */
  const questionBody = (
    <QuestionBody
      qType={qType}
      questionName={questionName}
      value={value}
      onChange={onChangeCallback}
      onAdd={onAdd}
      disabled={disabled}
      required={required}
      documentId={documentId}
      collection={collection}
      disablePast={disablePast}
      orgUnitIds={orgUnitIds}
      onlyIntersectingOrgUnitIds={onlyIntersectingOrgUnitIds}
      resourceType={resourceType}
      multiSelect={multiSelect}
      allowAdd={allowAdd}
      nonStandardAdditionalData={nonStandardAdditionalData}
      personGroupId={personGroupId}
      multiSelectHiddenIds={multiSelectHiddenIds}
      isNotDeletableChip={isNotDeletableChip}
      notDeletableChipTooltip={notDeletableChipTooltip}
      error={error}
      helperText={helperText}
      textEditorToolbarOptions={textEditorToolbarOptions}
      options={options}
      disableClearable={disableClearable}
      onFocus={onFocus}
      onBlur={onBlur}
      onChipClick={onChipClick}
      onActivate={onActivate}
      assignType={assignType}
      textFieldIcon={textFieldIcon}
      userDepartmentBound={userDepartmentBound}
      suggestionValueIds={suggestionValueIds}
      onSuggestionsConfirm={onSuggestionsConfirm}
      noDefaultValue={noDefaultValue}
    />
  );
  if (hidden) {
    return <></>;
  } else if (questionId) {
    return (
      <AdvancedQuestion
        actions={actions}
        chips={chips}
        disabled={disabled}
        onFocus={onFocusCallback}
        pb={pb}
        pl={pl}
        pr={pr}
        pt={pt}
        sx={sx}
        questionId={questionId}
        questionName={questionName}
        title={title}
        translatable={translatable}
        documentId={documentId}
      >
        {questionBody}
        {children}
      </AdvancedQuestion>
    );
  } else if (typeof value === "string" || translatable !== undefined) {
    return (
      <TranslationQuestion
        title={title}
        translatable={typeof value === "string" ? value : translatable}
        translationId={translationId}
        onFocus={onFocusCallback}
        sx={sx}
      >
        {questionBody}
        {children}
      </TranslationQuestion>
    );
  } else
    return (
      <SimpleQuestion title={title} onFocus={onFocusCallback} pt={pt} pb={pb} sx={sx}>
        {questionBody}
        {children}
      </SimpleQuestion>
    );
};

export default Question;
