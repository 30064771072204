import React from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "@material-ui/core";
import { TaskRepetitionModal } from "./TaskRepetitionModal";
import ChipsStack, { ChipVariant } from "components/ChipsStack/ChipsStack";
import { useRecurringTaskModal } from "hook/useRecurringTaskModal";

interface TaskRecurringChipProps {
  readonly startTime: Date | null;
  readonly endTime: Date | null;
  readonly dayOfWeek: string;
  readonly type: string;
  readonly onChangeRepetition: (data: {
    recurrenceType: string;
    dayOfWeek: string | null;
    startTime: Date | null;
    endTime: Date | null;
  }) => void;
}
const TaskRecurringChip = ({ startTime, endTime, dayOfWeek, type, onChangeRepetition }: TaskRecurringChipProps) => {
  const { t } = useTranslation("task_details");
  const { toggleModal: toggleRepetitionModal, isModalOpen } = useRecurringTaskModal();

  const chip = {
    label: t(type?.toLowerCase()),
    variant: ChipVariant.ROUNDED_FILLED,
    onClick: toggleRepetitionModal
  };

  if (toggleRepetitionModal === undefined || isModalOpen === undefined) {
    return null;
  }

  return (
    <>
      <TaskRepetitionModal
        startTime={startTime}
        endTime={endTime}
        dayOfWeek={dayOfWeek}
        type={type}
        onChangeRepetition={onChangeRepetition}
        active={isModalOpen}
        setActive={toggleRepetitionModal}
      />
      <ChipsStack data-testid={"add-repetition-section"} chips={[chip]} />
      <Divider />
    </>
  );
};

export { TaskRecurringChip };
