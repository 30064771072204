import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import DocView from "../../../components/DocView/DocView";
import React from "react";
import { useTranslation } from "react-i18next";
import { TenantIAMConfigWithSave } from "./TenantIAMConfig";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import TextBody2 from "../../../components/TextBody2/TextBody2";
import { Box, Divider } from "@mui/material";
import { TenantIAMMappingWithSave } from "./TenantIAMMapping";

export const IAMConfigPage = () => {
  const { t } = useTranslation("iamConfig");
  return (
    <DocMetaView metaViewContent={<MetaView translationKey="iamConfig" />}>
      <DocView header={t("title")}>
        <Box my={2}>
          <CustomAlert fullWidth severity="info">
            <TextBody2 text={t("info")} />
          </CustomAlert>
        </Box>
        <Box mb={6}>
          <TenantIAMConfigWithSave />
        </Box>
        <Divider />
        <Box mt={4}>
          <TenantIAMMappingWithSave />
        </Box>
      </DocView>
    </DocMetaView>
  );
};
