import React, { useCallback, useEffect, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import DocView from "components/DocView/DocView";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography
} from "@material-ui/core";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import Question from "components/Question/Question";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { TokenDTO, getTokenApi, createTokenApi, updateTokenApi, deleteTokenApi } from "app/api/tokenApi";

/* ALL PERMISSION TYPES  */
const PERMISSIONS = [
  "asset_read_all",
  "asset_read_org",
  "asset_write_all",
  "asset_write_org",
  "audit_read_all",
  "audit_write_all",
  "audit_read_org",
  "audit_write_org",
  "businessprocess_read_org",
  "businessprocess_write_org",
  "cookiebox_write_all",
  "db_read_all",
  "db_write_all",
  "db_read_org",
  "db_write_org",
  "dc_read_all",
  "dc_write_all",
  "dc_read_org",
  "dc_write_org",
  "dsr_read_all",
  "dsr_write_all",
  "dsr_read_org",
  "dsr_write_org",
  "er_read_org",
  "er_read_all",
  "er_write_org",
  "er_write_all",
  "filestorage_folders_write_all",
  "filestorage_write_all",
  "group_read_all",
  "group_write_all",
  "orgunit_write_all",
  "orgunit_read_all",
  "pa_read_all",
  "pa_write_all",
  "pa_read_org",
  "pa_write_org",
  "pa_read_basic_org",
  "pa_write_basic_org",
  "pa_read_min_org",
  "pa_write_min_org",
  "pa_delete_org",
  "pa_delete_all",
  "resource_read_all",
  "resource_write_all",
  "resource_read_org",
  "resource_write_org",
  "risk_read_all",
  "risk_write_all",
  "risk_read_org",
  "risk_write_org",
  "role_read_all",
  "role_write_all",
  "super_admin",
  "task_read_all",
  "task_read_org",
  "task_write_all",
  "task_write_org",
  "tom_read_all",
  "tom_write_all",
  "tom_read_org",
  "tom_write_org",
  "user_write_all",
  "user_read_all",
  "user_write_org",
  "user_read_org",
  "token_read_all",
  "pa_read_risk_org",
  "pa_write_risk_org",
  "pa_read_legalbasis_org",
  "pa_write_legalbasis_org"
];

/* Token Expires In */
const EXPIRES_IN = [{ id: "30 days" }, { id: "60 days" }, { id: "180 days" }];
interface TokenExpiresInProps {
  readonly expiresIn: string;
  readonly disabled: boolean;
  readonly onChange: (expiresIn: string, valueName: string) => void;
}
const TokenExpiresIn = ({ expiresIn, disabled, onChange }: TokenExpiresInProps) => {
  const options = EXPIRES_IN.map(({ id }) => id);
  const getOptionLabel = useCallback(opt => opt, []);
  const onChangeCallback = useCallback(
    (_expiresIn: string | null) => {
      if (_expiresIn) {
        onChange(_expiresIn, "expiresIn");
      }
    },
    [onChange]
  );
  return (
    <MultiAutocomplete
      label={"Expires In"}
      selected={expiresIn}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={onChangeCallback}
      hasMultiSelect={false}
      disabled={disabled}
    />
  );
};

interface PermissionsProps {
  readonly selectedPermissions: string[];
  readonly onChange: (val: string[], valueName: string) => void;
}
const Permissions = ({ selectedPermissions, onChange }: PermissionsProps) => {
  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const v = event.currentTarget.value;
      if (checked) {
        onChange([...selectedPermissions, v], "permissions");
      } else {
        onChange(
          selectedPermissions.filter(p => p !== v),
          "permissions"
        );
      }
    },
    [onChange, selectedPermissions]
  );

  const getControl = useCallback(
    permission => (
      <Box my={1} key={permission}>
        <FormControlLabel
          value="end"
          control={
            <Switch
              color="primary"
              onChange={onChangeCallback}
              value={permission}
              checked={selectedPermissions.includes(permission)}
            />
          }
          label={permission}
          labelPlacement="end"
        />
      </Box>
    ),
    [onChangeCallback, selectedPermissions]
  );
  return (
    <>
      <Box flex={1} mt={4} mb={2}>
        <Typography variant="h1" component="div" data-qa="paTitle">
          {"Permission"}
        </Typography>
        <Box mb={2} />
        <Box display={"flex"} flexWrap={"wrap"}>
          {PERMISSIONS.map(getControl)}
        </Box>
      </Box>
    </>
  );
};

interface CopyTokenModalProps {
  readonly refreshToken?: string;
  readonly tokenId?: string;
  readonly open?: boolean;
  readonly onCLose: () => void;
}
const CopyTokenModal = ({ refreshToken, tokenId, open, onCLose }: CopyTokenModalProps) => {
  const navigate = useNavigate();
  const onContinueCallback = useCallback(() => {
    onCLose();
    navigate("/tokens/" + tokenId);
  }, [navigate, onCLose, tokenId]);
  return (
    <Dialog open={Boolean(open)}>
      <DialogTitle>{"You can copy public-api-key only once"}</DialogTitle>
      <DialogContent>
        <Typography
          style={{
            wordBreak: "break-word",
            backgroundColor: "#eee",
            color: "#888",
            padding: "12px",
            borderRadius: "6px"
          }}
        >
          {refreshToken}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinueCallback} color="primary" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/* EXPORT */
const TokenDetail = () => {
  const { t } = useTranslation();
  const { tokenId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = useAuthentication();
  const navigate = useNavigate();

  /* STATE */
  const [token, setToken] = useState<Omit<TokenDTO, "id"> | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [modal, setModal] = useState<{
    readonly open: boolean;
    readonly tokenId: string;
    readonly refreshToken: string;
  } | null>(null);

  const fetch = useCallback(
    async (_id?: string) => {
      setLoading(true);
      if (_id) {
        try {
          const data = await getTokenApi(_id);
          setToken(data);
        } catch {
          setDocumentNotFound(true);
        } finally {
          setLoading(false);
        }
      } else {
        setToken({
          title: "",
          expiresIn: EXPIRES_IN[0].id,
          orgUnitId: auth?.orgUnitId || "",
          furtherOrgUnitIds: [],
          permissions: []
        });
        setLoading(false);
      }
    },
    [auth?.orgUnitId]
  );

  useEffect(() => {
    if (!token) {
      fetch(tokenId || "");
    }
  }, [fetch, token, tokenId]);

  useEffect(() => {
    setValid(
      Boolean(token?.title && (token?.expiresIn || token?.expiresAt) && token?.orgUnitId && token?.permissions?.length)
    );
  }, [token]);

  const onChangeCallback = useCallback((value: any, valueName?: string) => {
    if (valueName) {
      setToken(current => (current ? { ...current, [valueName]: value } : current));
    }
  }, []);

  const onSaveCallback = useCallback(async () => {
    setSaving(true);
    if (!tokenId && valid && token) {
      try {
        const response = await createTokenApi(token);
        setSaving(false);
        if (response.tokenId && response.refreshToken) {
          setModal({ tokenId: response.tokenId, refreshToken: response.refreshToken, open: true });
        } else {
          enqueueSnackbar("Cant get token", { variant: "error" });
        }
      } catch (e: any) {
        const message = e.response?.data?.message || "Cant get token";
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setSaving(false);
      }
    } else {
      try {
        if (tokenId && token) {
          const { title, orgUnitId, furtherOrgUnitIds, permissions } = token;
          await updateTokenApi(tokenId, { title, orgUnitId, furtherOrgUnitIds, permissions });
        }
      } catch (e: any) {
        const message = e.response?.data?.message || "Cant get token";
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setSaving(false);
      }
    }
  }, [enqueueSnackbar, token, tokenId, valid]);

  const onCancelCallback = useCallback(() => {
    navigate("/tokens");
  }, [navigate]);

  const onRevokeCallback = useCallback(async () => {
    if (tokenId) {
      setDeleting(true);
      try {
        if (tokenId && token) {
          await deleteTokenApi(tokenId);
          navigate("/tokens");
        }
      } catch {
        enqueueSnackbar("Cant revoke token", { variant: "error" });
      } finally {
        setDeleting(false);
      }
    }
  }, [enqueueSnackbar, navigate, token, tokenId]);

  const onCloseModal = useCallback(() => {
    setModal(null);
  }, []);

  const headerEl = (
    <Box mt={4} display={"flex"} mb={2}>
      <Box flex={1}>
        <Typography variant="h1" component="div" data-qa="paTitle">
          {tokenId ? token?.title : "New token"}
        </Typography>
      </Box>
      {tokenId && (
        <Box display={"flex"}>
          <Box mx={1} />
          <Button
            color="secondary"
            variant="contained"
            onClick={onRevokeCallback}
            startIcon={deleting && <CircularProgress size={14} />}
            disabled={deleting}
          >
            REVOKE
          </Button>
        </Box>
      )}
    </Box>
  );

  const expiresEl = tokenId ? (
    <Question qType={QUESTION_TYPE.DATE} value={token?.expiresAt?.toString() || ""} disabled={true} />
  ) : (
    <TokenExpiresIn onChange={onChangeCallback} expiresIn={token?.expiresIn || ""} disabled={Boolean(tokenId)} />
  );
  const tokenEl = (
    <>
      <Question
        qType={QUESTION_TYPE.TEXT_AREA}
        value={token?.title || ""}
        valueName={"title"}
        questionName={"Token title"}
        onChange={onChangeCallback}
      />
      {expiresEl}
    </>
  );

  const orgUnitsEl = (
    <>
      <Box flex={1} mt={4} mb={2}>
        <Typography variant="h1" component="div" data-qa="paTitle">
          {"Org units"}
        </Typography>
      </Box>
      <Question
        qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
        value={token?.orgUnitId || ""}
        valueName={"orgUnitId"}
        questionName={t("pa_general:responsible_department")}
        onChange={onChangeCallback}
      />
      <Question
        qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
        value={token?.furtherOrgUnitIds || []}
        valueName={"furtherOrgUnitIds"}
        questionName={t("pa_general:furtherDepartments")}
        onChange={onChangeCallback}
      />
    </>
  );

  const permissionEl = <Permissions selectedPermissions={token?.permissions || []} onChange={onChangeCallback} />;

  const modalEl = (
    <CopyTokenModal
      open={modal?.open}
      refreshToken={modal?.refreshToken}
      tokenId={modal?.tokenId}
      onCLose={onCloseModal}
    />
  );

  const docViewContent = (
    <DocView onSave={onSaveCallback} onCancel={onCancelCallback} saving={saving} disableSave={saving || !valid}>
      {headerEl}
      {tokenEl}
      {orgUnitsEl}
      {permissionEl}
      {modalEl}
    </DocView>
  );
  return (
    <DocMetaView
      collection={COLLECTIONS.TOKEN}
      docViewContent={docViewContent}
      metaViewContent={<MetaView translationKey="organisation" />}
      loading={loading}
      notFound={documentNotFound}
    />
  );
};

export default TokenDetail;
