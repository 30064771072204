import React, { useCallback, useEffect, useState, useRef } from "react";
import { QuestionProps } from "../../Question";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";

const TextEditorQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  textEditorToolbarOptions,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const [_value, _setValue] = useState<string | undefined>("");

  // Store initial value to compare on unmount
  const initialValueRef = useRef<string | undefined>("");

  useEffect(() => {
    const newValue = (Array.isArray(value) ? value[0] : value) as string;
    _setValue(newValue);
    // Store initial value on first render
    if (initialValueRef.current === "") {
      initialValueRef.current = newValue;
    }
  }, [value]);

  // Store latest value to access in cleanup function
  const valueRef = useRef<string | undefined>("");
  useEffect(() => {
    valueRef.current = _value;
  }, [_value]);

  // Save on unmount if value has changed
  useEffect(() => {
    return () => {
      if (valueRef.current !== initialValueRef.current) {
        onChange?.(valueRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCallback = useCallback((val: string) => _setValue(val), []);
  const onFocusCallback = useCallback(
    event => {
      onFocus?.(event);
    },
    [onFocus]
  );
  const onBlurCallback = useCallback(
    event => {
      onChange?.(_value);
      onBlur?.(event);
    },
    [_value, onBlur, onChange]
  );
  return (
    <TextEditor
      disabled={disabled}
      title={questionName}
      inputValue={_value}
      error={error}
      helperText={helperText}
      onChange={onChangeCallback}
      onFocus={onFocusCallback}
      onBlur={onBlurCallback}
      toolbarOptions={textEditorToolbarOptions}
    />
  );
};

export default React.memo(TextEditorQuestion);
