import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  debounce,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import {
  ExternalRecipientOverviewItemDTO,
  getERIds,
  PaginatedExternalRecipientsApiFilter,
  usePaginatedExternalRecipientsApi
} from "app/api/externalRecipientApi";
import colors from "theme/palette/colors";
import stopEvent from "tool/stopEvent";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useResources } from "app/contexts/resource-context";
import { ChevronRight, SearchOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SERVICE_TYPES_KEYS from "./utils/serviceTypes";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useDataLocations } from "hook/useDataLocations";
import { useSnackbar } from "notistack";
import { useExternalRecipients } from "app/contexts/external-recipient-context";
import { getBoldSearchText } from "tool/search";
import { COLLECTIONS } from "app/collections";
import OverviewNoItemsFound from "components/Overview/controls/OverviewNoItemsFound";

const sort = [
  {
    title: "asc"
  }
];

const limit = 20;

const sx = {
  root: {
    height: "600px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  groupBy: {
    my: 0.5,
    display: "flex",
    gap: 0.5
  },
  search: {
    width: "100%",
    mb: 2
  },
  list: {
    flex: 1,
    overflow: "auto"
  },
  row: {
    display: "flex",
    borderBottom: `1px solid ${colors.divider}`,
    cursor: "pointer",
    py: 0.5,
    pl: 0.5,
    pr: 1,
    alignItems: "center",
    "&:hover": {
      background: colors.grey.grey100
    }
  },
  title: {
    ml: 0.5,
    flex: 1,
    overflow: "hidden"
  },
  subTitle: {
    minWidth: 0,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "block",
    color: colors.grey.grey500,
    fontSize: "12px"
  },
  children: {
    mb: 3
  },
  groupTitle: { flex: 1, textOverflow: "ellipsis", textWrap: "nowrap", overflow: "hidden" },
  totalCountZero: { backgroundColor: colors.grey.grey100 },
  totalCountNonZero: { backgroundColor: colors.blue.blue100 },
  totalCount: {
    borderRadius: "16px",
    padding: 0.5,
    textAlign: "center",
    width: "32px",
    opacity: 0.8
  }
};

/* ER ROW */
interface ExternalRecipientRowProps {
  readonly item: ExternalRecipientOverviewItemDTO;
  readonly checked: boolean;
  readonly search?: string;
  readonly pl?: number;
  readonly onChecked?: (id: string) => void;
}
const ExternalRecipientRow = ({ item, checked, search, pl, onChecked }: ExternalRecipientRowProps) => {
  const { title, id } = item;
  const onCheckedCallback = useCallback(
    event => {
      stopEvent(event);
      onChecked?.(id);
    },
    [id, onChecked]
  );

  return (
    <Box sx={{ ...sx.row, pl: pl || 0.5 }} onClick={onCheckedCallback}>
      <Checkbox checked={checked} />
      <Box sx={sx.title}>
        <Typography variant="body2">{getBoldSearchText({ title, search })}</Typography>
        <Typography noWrap sx={sx.subTitle} variant="caption">
          {item.subTitle}
        </Typography>
      </Box>
    </Box>
  );
};

/* ER GROUP BY ROW */
interface ExternalRecipientGroupByRowProps {
  readonly filter: PaginatedExternalRecipientsApiFilter;
  readonly search?: string;
  readonly checked: boolean;
  readonly id: string;
  readonly name: string;
  readonly emptyTooltip?: string;
  readonly selectedERIds: string[];

  readonly onChecked: (id: string, force?: boolean) => void;
  readonly onCheckedEr: (id: string, force?: boolean) => void;
  readonly onSearchDone?: (count: number) => void;
}

const ExternalRecipientGroupByRow = ({
  filter,
  search,
  id,
  name,
  checked,
  emptyTooltip,
  selectedERIds,

  onChecked,
  onCheckedEr,
  onSearchDone
}: ExternalRecipientGroupByRowProps) => {
  const { t } = useTranslation("externalRecipientsPickerModal");

  const groupByERs = usePaginatedExternalRecipientsApi({
    limit,
    filter,
    sort
  });

  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(search !== "");
  }, [search]);

  useEffect(() => {
    if (!groupByERs.isValidating) {
      onSearchDone?.(groupByERs.totalCount || 0);
    }
  }, [groupByERs.isValidating, groupByERs.totalCount, onSearchDone]);

  const onCheckedCallback = useCallback(
    event => {
      stopEvent(event);

      // check group
      onChecked(id);

      // check all children
      groupByERs.items.forEach(({ id }) => {
        onCheckedEr(id, !checked);
      });
    },
    [checked, groupByERs.items, id, onChecked, onCheckedEr]
  );

  const onCheckedErCallback = useCallback(
    (erId: string) => {
      onCheckedEr(erId);

      // parent
      if (selectedERIds.includes(erId)) {
        onChecked(id, false);
      }
    },
    [id, onChecked, onCheckedEr, selectedERIds]
  );

  const onExpandCallback = useCallback(() => {
    if (groupByERs?.totalCount) {
      setExpanded(current => !current);
    }
  }, [groupByERs?.totalCount]);

  const loadMoreEl = useMemo(() => {
    if (!groupByERs.hasMore) {
      return <></>;
    }
    if (groupByERs.isValidating) {
      <Box sx={{ mt: 3, textAlign: "center" }}>
        <CircularProgress />
      </Box>;
    }
    return (
      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Button color="primary" variant="text" onClick={groupByERs.loadMore}>
          {t("loadMore")}
        </Button>
      </Box>
    );
  }, [groupByERs.hasMore, groupByERs.isValidating, groupByERs.loadMore, t]);

  const indeterminate = useMemo(
    () => !checked && groupByERs.items.some(({ id }) => selectedERIds.includes(id)),
    [checked, groupByERs.items, selectedERIds]
  );

  if (search && !groupByERs?.totalCount && !name.toLowerCase().includes(search)) {
    return <></>;
  }
  return (
    <Box>
      <Tooltip title={groupByERs?.totalCount ? "" : emptyTooltip}>
        <Box sx={sx.row} onClick={onExpandCallback}>
          <IconButton size="small" disabled={!groupByERs?.totalCount}>
            {expanded ? <ExpandMoreIcon /> : <ChevronRight />}
          </IconButton>
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onClick={onCheckedCallback}
            disabled={!groupByERs?.totalCount}
          />
          <Typography sx={sx.groupTitle}>{getBoldSearchText({ title: name, search })}</Typography>
          <Tooltip title={t("totalCount")}>
            <Typography
              variant="caption"
              sx={{ ...sx.totalCount, ...(groupByERs?.totalCount ? sx.totalCountNonZero : sx.totalCountZero) }}
            >
              {groupByERs.totalCount}
            </Typography>
          </Tooltip>
        </Box>
      </Tooltip>
      <Box sx={{ ...sx.children, display: expanded ? "block" : "none" }}>
        {groupByERs.items.map(er => (
          <ExternalRecipientRow
            key={er.id}
            pl={6}
            item={er}
            checked={selectedERIds.includes(er.id)}
            onChecked={onCheckedErCallback}
            search={search}
          />
        ))}
        {loadMoreEl}
      </Box>
    </Box>
  );
};

interface ExternalRecipientAllProps {
  readonly search: string;
  readonly blackListedIds?: string[];
  readonly selectedERIds: string[];
  readonly orgUnitIds?: string[];
  readonly allowAdd?: boolean;
  readonly groupBy: "label" | "type" | null;

  readonly onAdd: () => void;
  readonly onCheckER: (id: string, force?: boolean) => void;
  readonly onSearchDone?: (count: number) => void;
}
export const ExternalRecipientAll = ({
  search,
  blackListedIds,
  selectedERIds,
  orgUnitIds,
  allowAdd,
  groupBy,

  onAdd,
  onCheckER,
  onSearchDone
}: ExternalRecipientAllProps) => {
  const { t } = useTranslation("externalRecipientsPickerModal");
  const allERs = usePaginatedExternalRecipientsApi({
    limit,
    sort,
    filter: {
      title: {
        contains: search,
        operator: "AND"
      },
      ...(blackListedIds && blackListedIds.length ? { id: { notIn: blackListedIds }, operator: "AND" } : {}),
      ...(orgUnitIds
        ? {
            allOrgUnitIds: {
              in: orgUnitIds,
              operator: "AND"
            }
          }
        : {})
    }
  });
  const onScroll = useCallback(
    event => {
      const target = event.target;
      if (target.scrollHeight - target.scrollTop - target.clientHeight < 10) {
        allERs.loadMore();
      }
    },
    [allERs]
  );
  const debouncedOnScroll = useMemo(() => debounce(onScroll, 100), [onScroll]);
  const onDebouncedOnScrollCallback = useCallback(
    event => {
      event.persist();
      debouncedOnScroll(event);
    },
    [debouncedOnScroll]
  );

  useEffect(() => {
    if (!allERs.isValidating) {
      onSearchDone?.(allERs.totalCount || 0);
    }
  }, [allERs.isValidating, allERs.totalCount, onSearchDone]);

  /* ADD NEW */
  const addNewButtonEl = useMemo(() => {
    if (allowAdd && groupBy === null && search && allERs.items.length === 0) {
      return (
        <Box textAlign={"center"} mt={1}>
          <Button color="primary" variant="outlined" onClick={onAdd}>
            {t("addNewExternalRecipient")}
          </Button>
        </Box>
      );
    }

    return <></>;
  }, [allERs.items.length, allowAdd, groupBy, onAdd, search, t]);

  const listAllEl = useMemo(() => {
    if (!allERs.items.length) {
      return <></>;
    }
    return (
      <Box sx={sx.list} onScroll={onDebouncedOnScrollCallback}>
        {allERs.items.map(er => (
          <ExternalRecipientRow
            key={er.id}
            item={er}
            checked={selectedERIds.includes(er.id)}
            search={search}
            onChecked={onCheckER}
          />
        ))}
        {allERs.isValidating && (
          <Box mt={4} textAlign={"center"}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    );
  }, [allERs.isValidating, allERs.items, onDebouncedOnScrollCallback, onCheckER, search, selectedERIds]);

  return (
    <>
      {listAllEl}
      {addNewButtonEl}
    </>
  );
};
export interface ExternalRecipientsPickerModalProps {
  readonly open: boolean;
  readonly selectedIds: string[];
  readonly orgUnitIds?: string[];
  readonly blackListedIds?: string[];
  readonly allowAdd?: boolean;

  readonly onConfirm: (ids: string[]) => void;
  readonly onCancel: () => void;
  readonly onAdd?: (input: { readonly selectedIds: string[]; readonly newItemId: string }) => void;
}
const ExternalRecipientsPickerModal = ({
  open,
  selectedIds,
  blackListedIds,
  orgUnitIds,
  allowAdd,

  onAdd,
  onConfirm,
  onCancel
}: ExternalRecipientsPickerModalProps) => {
  const { t } = useTranslation("externalRecipientsPickerModal");
  const { translateById, resources } = useResources();
  const { loadSeenItemsOfUserHook } = useUserAndTenantData();
  const { dataLocationsReload } = useDataLocations();
  const { t: tErrorMessages } = useTranslation("error_messages");
  const { enqueueSnackbar } = useSnackbar();
  const { createExternalRecipientHook } = useExternalRecipients();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();

  const [search, setSearch] = useState<string>("");
  const [_search, _setSearch] = useState<string>("");
  const [groupBy, setGroupBy] = useState<"label" | "type" | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedERIds, setSelectedERIds] = useState<string[]>([]);
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);
  const [selectedTypeIds, setSelectedTypeIds] = useState<string[]>([]);
  const [searchByCount, setSearchByCount] = useState<number>(0);

  const getSelectedIds = useCallback(async () => {
    let allIds: string[] = [...selectedERIds];

    if (selectedLabelIds.length) {
      const filter = { labels: { in: selectedLabelIds } };
      const ids = (await getERIds({ filter })).ids;
      allIds = [...allIds, ...ids];
    }

    if (selectedTypeIds.length) {
      const filter = { serviceType: { in: selectedTypeIds } };
      const ids = (await getERIds({ filter })).ids;
      allIds = [...allIds, ...ids];
    }

    return [...new Set([...allIds])];
  }, [selectedERIds, selectedLabelIds, selectedTypeIds]);

  useEffect(() => {
    setSelectedERIds(selectedIds);
  }, [selectedIds, open]);

  /* SET INITIAL STATE */
  const cleanUp = useCallback(() => {
    setSearch("");
    _setSearch("");
    setGroupBy(null);
    setSelectedERIds([]);
    setSelectedLabelIds([]);
    setSelectedTypeIds([]);
    setLoading(false);
    setSearchByCount(0);
    searchByTypeCountRef.current = 0;
    searchByLabelCountRef.current = 0;
  }, []);

  /* ADD NEW */
  const onAddCallback = useCallback(async () => {
    try {
      setLoading(true);
      const newItemId = await createExternalRecipientHook(
        {
          name: search,
          serviceType: "SERVICEPROVIDER"
        },
        false
      );

      if (newItemId) {
        await addToSeenItemsOfUserHook(COLLECTIONS.EXTERNAL_RECIPIENTS, newItemId);
        await loadSeenItemsOfUserHook();
        await dataLocationsReload();
        const selectedIds = await getSelectedIds();
        onAdd?.({ selectedIds: [...selectedIds, newItemId], newItemId });
      }
    } catch (error: any) {
      enqueueSnackbar(tErrorMessages(error?.message), { variant: "error" });
    } finally {
      cleanUp();
    }
  }, [
    addToSeenItemsOfUserHook,
    cleanUp,
    createExternalRecipientHook,
    dataLocationsReload,
    enqueueSnackbar,
    getSelectedIds,
    loadSeenItemsOfUserHook,
    onAdd,
    search,
    tErrorMessages
  ]);

  /* SEARCH EL */
  const debouncedSearch = useMemo(
    () =>
      debounce(val => {
        _setSearch(val.toLowerCase());
        setSearchByCount(0);
      }, 1500),
    []
  );
  const onSearchChange = useCallback(
    event => {
      searchByLabelCountRef.current = 0;
      setSearch(event.target.value);
      debouncedSearch(event.target.value);
      setLoading(true);
    },
    [debouncedSearch]
  );

  const searchEl = useMemo(() => {
    return (
      <TextField
        sx={sx.search}
        value={search}
        onChange={onSearchChange}
        placeholder={t("common:search")}
        InputProps={{
          startAdornment: (
            <SearchOutlined
              sx={{
                color: colors.grey.grey600,
                marginRight: "8px"
              }}
            />
          )
        }}
      />
    );
  }, [onSearchChange, search, t]);

  /* GROUP BY EL */
  const labels = useMemo(() => {
    return (
      resources[RESOURCE_TYPES.LABEL]?.map(r => ({
        id: r.id,
        name: translateById(RESOURCE_TYPES.LABEL, r.id)
      })) || []
    );
  }, [resources, translateById]);
  const types = useMemo(() => {
    return (
      SERVICE_TYPES_KEYS?.map(r => ({
        id: r,
        name: t("filter_criteria:service_type_" + r)
      })) || []
    );
  }, [t]);
  const onSetGroupByLabel = useCallback(() => {
    setGroupBy(groupBy === "label" ? null : "label");
    setSearch("");
    _setSearch("");
  }, [groupBy]);
  const onSetGroupByType = useCallback(() => {
    setGroupBy(groupBy === "type" ? null : "type");
    setSearch("");
    _setSearch("");
  }, [groupBy]);
  const groupByEl = useMemo(() => {
    return (
      <Box sx={sx.groupBy}>
        <Tooltip title={groupBy === "label" ? t("unGroupByLabel") : t("groupByLabel")}>
          <Chip label={t("label")} onClick={onSetGroupByLabel} color={groupBy === "label" ? "primary" : "secondary"} />
        </Tooltip>
        <Tooltip title={groupBy === "type" ? t("unGroupByType") : t("groupByType")}>
          <Chip label={t("type")} onClick={onSetGroupByType} color={groupBy === "type" ? "primary" : "secondary"} />
        </Tooltip>
      </Box>
    );
  }, [groupBy, onSetGroupByLabel, onSetGroupByType, t]);

  /* LIST ALL EL */
  const onCheckER = useCallback((id: string, force?: boolean) => {
    if (force !== undefined) {
      setSelectedERIds(current => (force ? [...new Set([...current, id])] : current.filter(_id => _id !== id)));
    } else {
      setSelectedERIds(current => (current.includes(id) ? current.filter(i => i !== id) : [...current, id]));
    }
  }, []);
  const onSearchAllDone = useCallback(() => {
    setLoading(false);
  }, []);
  const listAllEl = useMemo(() => {
    if (groupBy !== null || !open) {
      return <></>;
    }
    return (
      <ExternalRecipientAll
        search={_search}
        blackListedIds={blackListedIds}
        selectedERIds={selectedERIds}
        orgUnitIds={orgUnitIds}
        groupBy={groupBy}
        allowAdd={allowAdd}
        onCheckER={onCheckER}
        onAdd={onAddCallback}
        onSearchDone={onSearchAllDone}
      />
    );
  }, [
    groupBy,
    open,
    _search,
    blackListedIds,
    selectedERIds,
    orgUnitIds,
    allowAdd,
    onCheckER,
    onAddCallback,
    onSearchAllDone
  ]);

  /* LIST GROUP BY LABEL */
  const searchByLabelCountRef = useRef<number>(0);
  const filtersByLabel = useMemo(() => {
    return labels.reduce<Record<string, PaginatedExternalRecipientsApiFilter>>((acc, next) => {
      return {
        ...acc,
        [next.id]: {
          orAndConnector: "AND",
          title: {
            contains: _search,
            operator: "OR"
          },
          labels: { in: [next.id], operator: "AND" },
          labelTitle: {
            contains: _search,
            operator: "OR"
          },
          ...(blackListedIds && blackListedIds.length ? { id: { notIn: blackListedIds, operator: "AND" } } : {}),
          ...(orgUnitIds
            ? {
                allOrgUnitIds: {
                  in: orgUnitIds,
                  operator: "AND"
                }
              }
            : {})
        }
      };
    }, {});
  }, [labels, _search, blackListedIds, orgUnitIds]);
  const onCheckLabel = useCallback((id: string, force?: boolean) => {
    if (force !== undefined) {
      setSelectedLabelIds(current => (force ? [...new Set([...current, id])] : current.filter(_id => _id !== id)));
    } else {
      setSelectedLabelIds(current => (current.includes(id) ? current.filter(i => i !== id) : [...current, id]));
    }
  }, []);
  const onSearchByLabelDone = useCallback(
    (count: number) => {
      searchByLabelCountRef.current = searchByLabelCountRef.current + 1;
      if (searchByLabelCountRef.current >= labels.length) {
        setLoading(false);
      }

      setSearchByCount(current => current + count);
    },
    [labels.length]
  );
  const listGroupByLabelEl = useMemo(() => {
    if (groupBy !== "label" || !open) {
      return <></>;
    } else {
      return (
        <Box sx={sx.list}>
          {labels.map(label => (
            <ExternalRecipientGroupByRow
              key={label.id}
              search={_search}
              filter={filtersByLabel[label.id]}
              id={label.id}
              name={label.name}
              onChecked={onCheckLabel}
              onCheckedEr={onCheckER}
              checked={selectedLabelIds.includes(label.id)}
              onSearchDone={onSearchByLabelDone}
              selectedERIds={selectedERIds}
              emptyTooltip={t("noERLabel")}
            />
          ))}
        </Box>
      );
    }
  }, [
    groupBy,
    open,
    labels,
    _search,
    filtersByLabel,
    onCheckLabel,
    onCheckER,
    selectedLabelIds,
    onSearchByLabelDone,
    selectedERIds,
    t
  ]);

  /* LIST GROUP BY TYPE */
  const searchByTypeCountRef = useRef<number>(0);
  const filtersByType = useMemo(() => {
    return types.reduce<Record<string, PaginatedExternalRecipientsApiFilter>>((acc, next) => {
      return {
        ...acc,
        [next.id]: {
          orAndConnector: "AND",
          title: {
            contains: _search,
            operator: "OR"
          },
          serviceType: { in: [next.id], operator: "AND" },
          serviceTypeLabel: {
            contains: _search,
            operator: "OR"
          },
          ...(blackListedIds && blackListedIds.length ? { id: { notIn: blackListedIds, operator: "AND" } } : {}),
          ...(orgUnitIds
            ? {
                allOrgUnitIds: {
                  in: orgUnitIds,
                  operator: "AND"
                }
              }
            : {})
        }
      };
    }, {});
  }, [types, _search, blackListedIds, orgUnitIds]);
  const onCheckType = useCallback((id: string) => {
    setSelectedTypeIds(current => (current.includes(id) ? current.filter(i => i !== id) : [...current, id]));
  }, []);
  const onSearchByTypeDone = useCallback(
    (count: number) => {
      searchByTypeCountRef.current = searchByTypeCountRef.current + 1;
      if (searchByTypeCountRef.current >= types.length) {
        setLoading(false);
      }

      setSearchByCount(current => current + count);
    },
    [types.length]
  );
  const listGroupByTypeEl = useMemo(() => {
    if (groupBy !== "type" || !open) {
      return <></>;
    } else {
      return (
        <Box sx={sx.list}>
          {types.map(type => (
            <ExternalRecipientGroupByRow
              key={type.id}
              filter={filtersByType[type.id]}
              search={_search}
              id={type.id}
              name={type.name}
              onChecked={onCheckType}
              onCheckedEr={onCheckER}
              onSearchDone={onSearchByTypeDone}
              checked={selectedTypeIds.includes(type.id)}
              selectedERIds={selectedERIds}
              emptyTooltip={t("noERType")}
            />
          ))}
        </Box>
      );
    }
  }, [
    groupBy,
    open,
    types,
    filtersByType,
    _search,
    onCheckType,
    onCheckER,
    onSearchByTypeDone,
    selectedTypeIds,
    selectedERIds,
    t
  ]);

  /* LOADING */
  const loadingEl = useMemo(() => {
    if (!loading) return <></>;
    return <LinearProgress />;
  }, [loading]);

  /* NO ITEMS */
  const noItemsEl = useMemo(() => {
    if (groupBy === null || search === "" || loading || searchByCount > 0) {
      return <></>;
    }
    return <OverviewNoItemsFound align={"center"} />;
  }, [groupBy, loading, search, searchByCount]);

  const bodyEl = useMemo(() => {
    return (
      <Box sx={sx.root}>
        {groupByEl}
        {searchEl}
        {loadingEl}
        {listAllEl}
        {noItemsEl}
        {listGroupByLabelEl}
        {listGroupByTypeEl}
      </Box>
    );
  }, [groupByEl, searchEl, loadingEl, listAllEl, noItemsEl, listGroupByLabelEl, listGroupByTypeEl]);

  /* ACTIONS */
  const onCancelCallback = useCallback(() => {
    cleanUp();
    onCancel?.();
  }, [cleanUp, onCancel]);

  const onConfirmCallback = useCallback(async () => {
    setLoading(true);
    const allIds = await getSelectedIds();

    onConfirm?.(allIds);
    cleanUp();
  }, [cleanUp, getSelectedIds, onConfirm]);

  const buttons: ConfirmationModalButtonProps[] = [
    {
      confirmButton: false,
      title: t("common:cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      disabled: loading,
      onClick: onCancelCallback
    },
    {
      confirmButton: true,
      title: t("common:confirm"),
      variant: "contained",
      color: "primary",
      size: "medium",
      disabled: loading,
      onClick: onConfirmCallback
    }
  ];
  return (
    <ConfirmationModal
      modalOpen={open}
      onClose={onCancelCallback}
      modalTitle={t("dataRecipients:external")}
      modalText={""}
      buttons={buttons}
      modalBody={bodyEl}
      variant={"info"}
    />
  );
};

export default ExternalRecipientsPickerModal;
