import React, { useCallback, useEffect, useMemo, useState } from "react";
import ConfirmationModal, {
  ConfirmationModalButtonProps
} from "../../../../components/ConfirmationModal/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import Question from "../../../../components/Question/Question";
import TextBody2 from "../../../../components/TextBody2/TextBody2";
import { Box, Chip } from "@mui/material";
import useSWR from "swr";
import { getSingleRoleApi } from "../../../api/roleApi";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import { COLLECTIONS } from "../../../collections";

export interface UserDeletionDialogProps {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onUserDeleted?: (deletedUserId: string, replacementUserId?: string) => Promise<void> | void;
  readonly toBeDeletedUserId: string;
}

export const UserDeletionDialog = ({ open, onClose, onUserDeleted, toBeDeletedUserId }: UserDeletionDialogProps) => {
  const { t } = useTranslation("manage-user-page");
  const userReplaceDisabled = useIsFeaturePresent(FEATURES.USER_REPLACE_DISABLED);
  const { deleteUserHook, getUserNameHook, isExternalUser } = useUserAndTenantData();
  const toBeDeletedUserIsExternalUser = useMemo(
    () => isExternalUser(toBeDeletedUserId),
    [isExternalUser, toBeDeletedUserId]
  );

  const [replacementUserId, setReplacementUserId] = useState<string>("");

  const replacementUserMissingPermission = useIsReplacementUserMissingPermission(toBeDeletedUserId, replacementUserId);

  const deleteUserCallback = useCallback(async () => {
    await deleteUserHook(toBeDeletedUserId, replacementUserId);
    onClose();
  }, [deleteUserHook, onClose, replacementUserId, toBeDeletedUserId]);

  const [modalButtons, setModalButtons] = useState<ConfirmationModalButtonProps[]>([]);
  useEffect(() => {
    setModalButtons([
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: async () => {
          onClose();
        }
      },
      {
        confirmButton: true,
        title: replacementUserId ? t("delete_user_confirm") : t("common:delete"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: async () => {
          await deleteUserCallback();
          await onUserDeleted?.(toBeDeletedUserId, replacementUserId);
          onClose();
        }
      }
    ]);
  }, [t, onClose, deleteUserCallback, onUserDeleted, toBeDeletedUserId, replacementUserId]);

  // if it's null, it means it's still loading, have to check for explicit false before showing this to avoid flickering
  const shouldShowUserReplace = userReplaceDisabled === false && !toBeDeletedUserIsExternalUser;

  const modelBody: React.ReactNode = shouldShowUserReplace ? (
    <Box mt={2}>
      <Box mb={-1}>
        <TextBody2 text={t("replacement_user_description")} />
      </Box>
      <Question
        qType={QUESTION_TYPE.SELECT_USER}
        questionName={t("replacement_user")}
        value={replacementUserId}
        onChange={setReplacementUserId}
      />
      {replacementUserMissingPermission ? (
        <Box mt={-1}>
          <CustomAlert severity="warning">
            <TextBody2 text={t("replacement_permission_warning")} />
          </CustomAlert>
        </Box>
      ) : (
        <Box />
      )}
    </Box>
  ) : (
    <></>
  );

  return (
    <ConfirmationModal
      modalBody={modelBody}
      modalOpen={open}
      modalTitle={shouldShowUserReplace ? t("delete_user_title") : t("overview:confirm_delete")}
      modalText={
        shouldShowUserReplace ? (
          <Box gap={1} display="flex" alignItems="center">
            {t("delete_user_description")}
            <Chip label={getUserNameHook(toBeDeletedUserId)} size="small" />
          </Box>
        ) : (
          t("overview:confirm_delete_collection", { collection: t(`collection:${COLLECTIONS.USER}`) })
        )
      }
      onClose={onClose}
      buttons={modalButtons}
    />
  );
};

const useIsReplacementUserMissingPermission = (toBeDeletedUserId: string, replacementUserId: string) => {
  const { getUserHook } = useUserAndTenantData();
  const toBeDeletedUser = useMemo(() => getUserHook(toBeDeletedUserId), [getUserHook, toBeDeletedUserId]);
  const replacementUser = useMemo(
    () => (replacementUserId ? getUserHook(replacementUserId) : null),
    [getUserHook, replacementUserId]
  );
  const { data: toBeDeletedUserPermission } = useSWR(replacementUserId ? ["roles", toBeDeletedUserId] : null, () =>
    getSingleRoleApi(toBeDeletedUser?.userRole || "").then(it => it?.permissions)
  );
  const { data: replacementUserPermission } = useSWR(replacementUserId ? ["roles", replacementUserId] : null, () =>
    getSingleRoleApi(replacementUser?.userRole || "").then(it => it?.permissions)
  );

  const missingPermission = useMemo(() => {
    if (!replacementUserPermission) {
      return false;
    }
    const toBeDeletedUserPermissions = new Set((toBeDeletedUserPermission || []).map(extractBasePermission));
    const replacementUserPermissions = new Set((replacementUserPermission || []).map(extractBasePermission));
    for (const permission of permissionToCompare) {
      const toBeDeletedUserHasPermission = toBeDeletedUserPermissions.has(permission);
      const replacementUserHasPermission = replacementUserPermissions.has(permission);
      if (toBeDeletedUserHasPermission && !replacementUserHasPermission) {
        return true;
      }
    }
    return false;
  }, [replacementUserPermission, toBeDeletedUserPermission]);

  return missingPermission;
};

const permissionToCompare = [
  "asset",
  "audit",
  "db",
  "dsr",
  "er",
  "pa",
  "processor_pa",
  "risk",
  "task",
  "tom",
  "aitechdoc"
];

const extractBasePermission = (permission: string) => {
  const permissionArray = permission.split(/(_read|_write|_approve|_delete)/g);
  return permissionArray[0] || "";
};
