import overviewBaseController, {
  OverviewController,
  OverviewItem,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import { AxiosInstance } from "axios";
import { CollectionParams } from "../../../../hook/useOverviewData";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

const PATechDocsOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: CollectionParams
): OverviewController => {
  const { t } = useTranslation();
  const normalizeItem = useCallback(
    (item: OverviewItem): OverviewItem => {
      return {
        ...item,
        status: item.status || "edit",
        title: item.title === "no-documentation" ? t("process_aitechdoc_page:no-documentation") : item.title
      };
    },
    [t]
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.PROCESSES_AITECHDOCS, normalizeItem, []);

  const getOverview = async (setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup, `${collectionParams.id || ""}/pages/precheck/ai/techdocs`, {
      headers: { "Accept-Language": i18n.language }
    });
    if (!data) {
      return null;
    }

    return {
      ...data
    };
  };

  const goToItem = () => {
    // do nothing
  };

  return {
    ...baseController,
    goToItem,
    getOverview
  };
};

export default PATechDocsOverviewController;
