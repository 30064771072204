import { Box, Button, ClickAwayListener, TextField, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DropDownIcon from "assets/images/icons/arrow_drop_down_24px.svg";
import DropDownMenu, { DropDownMenuItemProps, DropDownMenuProps } from "components/DropDownMenu/DropDownMenu";

export interface DocStatus {
  readonly id: string;
  readonly label: string;
  readonly icon?: React.ReactNode;
}

export interface DocProperty {
  readonly label: string;
  readonly icon?: React.ReactNode;
}

export interface DocTitleProps {
  readonly title: string;
  readonly statusId?: string;
  readonly statuses?: DocStatus[];
  readonly disabled?: boolean;
  readonly properties?: DocProperty[];
  readonly onTitleChange?: (title: string) => void;
  readonly onStatusChange?: (status: string) => void;
  readonly onFocus?: (event: any) => void;
}

const DocTitle = ({
  title,
  statusId,
  statuses,
  disabled,
  properties,
  onTitleChange,
  onStatusChange,
  onFocus
}: DocTitleProps) => {
  const theme = useTheme();
  const sx = useMemo(
    () => ({
      root: {},
      title: {
        flex: 1,
        minHeight: "48px",
        paddingTop: "4px",
        "& .MuiInputBase-input": {
          fontSize: "24px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          marginLeft: "-8px",
          paddingLeft: "8px",
          borderRadius: "4px",
          fontFamily: "SuisseIntl",
          "&:disabled": {
            textFillColor: "rgba(0, 0, 0, 0.87)",
            color: "rgba(0, 0, 0, 0.87)"
          },
          "&:focus": {
            background: "transparent !important"
          },
          "&:hover": {
            background: !disabled && onTitleChange ? "#f0f0f0" : "inherited"
          }
        },
        "& .MuiInput-underline": {
          "&::after": {
            borderColor: theme.palette.primary.main
          }
        }
      },
      status: {
        ml: 1,
        display: "flex",
        alignItems: "center",
        cursor: disabled ? "default" : "pointer",
        color: theme.palette.text.primary
      },
      statusIcon: {
        lineHeight: "1em"
      },
      statusText: {
        mx: 1
      },
      statusDropDown: {
        lineHeight: "1em"
      },
      properties: {
        marginTop: "8px",
        marginBottom: "8px",
        display: "flex",
        color: theme.palette.grey[500]
      },
      property: {
        display: "flex",
        alignItems: "center",
        marginRight: "24px"
      },
      propertyLabel: {
        marginLeft: "4px",
        color: theme.palette.grey[600]
      }
    }),
    [disabled, onTitleChange, theme.palette]
  );

  const [textFieldKey, setTextFieldKey] = useState<number>(Date.now());
  const [model, setModel] = useState<{
    readonly title: string;
    readonly statusId?: string;
    readonly focused: boolean;
    readonly dropDownOpen: boolean;
    readonly dropDownRef: Element | null;
  }>({
    title: "",
    statusId: "",
    focused: false,
    dropDownOpen: false,
    dropDownRef: null
  });

  useEffect(() => {
    setModel(current => ({ ...current, title, statusId }));
  }, [title, statusId]);

  /* TITLE */
  const onTitleBlurCallback = useCallback(() => {
    onTitleChange?.(model.title);
    setModel(current => ({ ...current, focused: false }));
  }, [model.title, onTitleChange]);

  const onTitleChangeCallback = useCallback(event => {
    setModel(current => ({ ...current, title: event.target.value }));
  }, []);

  const onTitleFocusCallback = useCallback(
    e => {
      setModel(current => ({ ...current, focused: true }));
      onFocus?.(e);
    },
    [onFocus]
  );

  const onKeyUpCallback = useCallback(
    event => {
      if (event?.keyCode === 13) {
        onTitleBlurCallback();
        setTextFieldKey(Date.now());
      }
    },
    [onTitleBlurCallback]
  );

  /* STATUS */
  const showStatusDropDownMenu = useCallback(event => {
    setModel(current => ({ ...current, dropDownOpen: true, dropDownRef: event.currentTarget }));
  }, []);
  const closeStatusDropDownMenu = useCallback(() => {
    setModel(current => ({ ...current, dropDownOpen: false, dropDownRef: null }));
  }, []);
  const onStatusChangeCallback = useCallback(
    (statusId: string) => {
      closeStatusDropDownMenu();
      onStatusChange?.(statusId);
    },
    [closeStatusDropDownMenu, onStatusChange]
  );
  const currentStatusIconEl = useMemo(() => {
    return statuses?.find(({ id }) => model.statusId === id)?.icon || "no";
  }, [model.statusId, statuses]);
  const currentStatusLabelEl = useMemo(() => {
    return statuses?.find(({ id }) => model.statusId === id)?.label || "";
  }, [model.statusId, statuses]);

  /* PROPERTIES */
  const propertiesEl = (() => {
    if (!properties?.length) {
      return <></>;
    }

    return (
      <Box sx={sx.properties}>
        {properties?.map((property, index) => {
          return (
            <Box sx={sx.property} key={`${property.label}-${index}`}>
              {property.icon}
              <Typography sx={sx.propertyLabel}>{property.label}</Typography>
            </Box>
          );
        }, [])}
      </Box>
    );
  })();

  const textFieldInputProps = useMemo(() => {
    return {
      disableUnderline: !model.focused
    };
  }, [model.focused]);

  return (
    <Box sx={sx.root}>
      <Box display="flex">
        <TextField
          autoComplete="off"
          key={textFieldKey}
          sx={sx.title}
          fullWidth
          variant="standard"
          value={model.title}
          onBlur={onTitleBlurCallback}
          onChange={onTitleChangeCallback}
          onFocus={onTitleFocusCallback}
          onKeyUp={onKeyUpCallback}
          InputProps={textFieldInputProps}
          disabled={disabled || !onTitleChange}
        />
        {statusId && statuses && statuses.length > 0 && (
          <ClickAwayListener onClickAway={closeStatusDropDownMenu}>
            <>
              <Button
                sx={sx.status}
                variant="text"
                onClick={showStatusDropDownMenu}
                disabled={disabled || onStatusChange === undefined}
              >
                <Box sx={sx.statusIcon}>{currentStatusIconEl}</Box>
                <Box sx={sx.statusText}>{currentStatusLabelEl}</Box>
                {!disabled && onStatusChange && (
                  <Box sx={sx.statusDropDown}>
                    <DropDownIcon />
                  </Box>
                )}
              </Button>
              <DropDownMenu
                open={Boolean(!disabled && model.dropDownOpen && model.dropDownRef)}
                anchorEl={model.dropDownRef}
                items={statuses as DropDownMenuItemProps[]}
                selectedId={model.statusId}
                anchorOrigin={dropDownAnchorOrigin}
                onChange={onStatusChangeCallback}
                onClose={closeStatusDropDownMenu}
              />
            </>
          </ClickAwayListener>
        )}
      </Box>
      {propertiesEl}
    </Box>
  );
};

const dropDownAnchorOrigin: DropDownMenuProps["anchorOrigin"] = {
  vertical: 50,
  horizontal: -12
};

export default DocTitle;
