import { getDueDateType, OverviewDueDateProps } from "components/Overview/utils/overviewDueDate";
import OverviewBrickDueDate from "../common/OverviewBrickDueDate";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

interface RequestOverviewBrickDueDateProps {
  readonly status?: "OPEN" | "DONE" | "DELETED";
  readonly date?: string;
}

const getType = ({ status, date }: RequestOverviewBrickDueDateProps): OverviewDueDateProps => {
  if (status === "DONE") {
    return { kind: "done" };
  } else return getDueDateType(date);
};

const RequestOverviewBrickDueDate = ({ item, hovered }: OverviewRowBrickProps) => {
  const dueDateType = getType({ status: item.status, date: item.dueAt });
  return <OverviewBrickDueDate date={dueDateType} hovered={hovered} />;
};

export default RequestOverviewBrickDueDate;
