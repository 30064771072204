import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import MetaView from "components/MetaView/MetaView";
import { useMetaView } from "app/contexts/meta-view-context";
import { usePathParamByKey } from "app/router/router-custom-hooks";
import { useNavigate } from "react-router-dom";
import { useUserGroups } from "app/contexts/group-context";
import { Box, Button, CircularProgress, TextField } from "@mui/material";

export default function CreateAndEditGroup() {
  const existingGroupId = usePathParamByKey("id");
  const { t } = useTranslation("manage-user-page");
  const { createGroupHook, updateGroupHook, getGroupHook } = useUserGroups();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [modalText, setModalText] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmationFunction, setConfirmationFunction] = useState<() => void>(() => {
    /* empty */
  });
  const [loadingInfo, setLoadingInfo] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setInfo } = useMetaView();
  const navigateToOverview = useCallback(() => navigate("/groups"), [navigate]);

  const loadGroup = useCallback(async () => {
    if (!existingGroupId) {
      setName("");
      return;
    }

    if (existingGroupId) {
      const group = await getGroupHook(existingGroupId);
      if (!group) {
        return navigateToOverview();
      }
      setName(group.name || "");
      return;
    }
  }, [existingGroupId, getGroupHook, navigateToOverview]);

  useEffect(() => {
    loadGroup();
  }, [loadGroup]);

  const defaultInfo = useMemo(
    () =>
      existingGroupId
        ? {
            title: t("groups_overview:groupDetailsInfoTitle"),
            text: t("groups_overview:groupDetailsInfoText")
          }
        : {
            title: t("groups_overview:addNewGroupInfoTitle"),
            text: t("groups_overview:addNewGroupInfoText")
          },
    [existingGroupId, t]
  );

  useEffect(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);

  // register Group in Database
  const registerGroup = useCallback(async () => {
    setLoadingInfo(true);
    const trimmedName = name?.trim();

    try {
      await createGroupHook({
        name: trimmedName
      });

      setLoadingInfo(false);
      enqueueSnackbar(t("group_created_message"), { variant: "success" });
      navigateToOverview();
    } catch (error: any) {
      if (error.response.data.error === "ConflictError") {
        enqueueSnackbar(t("groups_overview:group_name_exist", { name: trimmedName }), { variant: "error" });
      } else {
        enqueueSnackbar(t(error?.message), { variant: "error" });
      }
      console.error(error);
      setLoadingInfo(false);
    }
  }, [name, enqueueSnackbar, t, navigateToOverview, createGroupHook]);

  // edit Group in Database
  const editGroup = useCallback(async () => {
    if (!existingGroupId) {
      throw new Error("Group is non existent!");
    }
    const trimmedName = name?.trim();
    setLoadingInfo(true);
    try {
      await updateGroupHook(existingGroupId, {
        name: trimmedName
      });
      enqueueSnackbar(t("changes_saved"), { variant: "success" });
      setLoadingInfo(false);
      navigateToOverview();
    } catch (error: any) {
      console.error(error);
      if (error.response.data.error === "ConflictError") {
        enqueueSnackbar(t("groups_overview:group_name_exist", { name: trimmedName }), { variant: "error" });
      } else {
        enqueueSnackbar(t(error?.message), { variant: "error" });
      }
      setLoadingInfo(false);
    }
  }, [existingGroupId, updateGroupHook, name, enqueueSnackbar, t, navigateToOverview]);

  const necessaryFieldsSubmitted = () => !loadingInfo && name;

  const onInfoTextReset = useCallback(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);
  const onNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const modalClose = useCallback(() => {
    setEditModalOpen(false);
    setModalText("");
  }, []);

  const [modalButtons, setModalButtons] = useState<ConfirmationModalButtonProps[]>([]);
  useEffect(() => {
    setModalButtons([
      {
        confirmButton: false,
        title: t("common:no"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: () => {
          setEditModalOpen(false);
          setModalText("");
        }
      },
      {
        confirmButton: true,
        title: t("common:yes"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: async () => {
          confirmationFunction();
          setEditModalOpen(false);
          setModalText("");
        }
      }
    ]);
  }, [confirmationFunction, t]);

  return (
    <DocMetaView metaViewContent={<MetaView translationKey="organisation" />}>
      <DocView header={existingGroupId ? t("edit_group_information") : t("add_group")}>
        <Box mt={2}>
          <Box>
            <TextField
              required
              fullWidth={true}
              label={t("fields:group_name")}
              value={name}
              onChange={onNameChanged}
              name="name_optional"
              id="name"
              type="text"
              variant="outlined"
              onBlur={onInfoTextReset}
            />
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
            <Button variant="outlined" color="primary" onClick={navigateToOverview}>
              {t("back")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!necessaryFieldsSubmitted()}
              onClick={existingGroupId ? editGroup : registerGroup}
            >
              {existingGroupId ? t("save_user") : t("add")}
              {loadingInfo && (
                <Box ml={1} display="flex" alignItems="center">
                  <CircularProgress color="inherit" size={14} />
                </Box>
              )}
            </Button>
          </Box>
        </Box>
        <ConfirmationModal
          modalBody={undefined}
          modalOpen={editModalOpen}
          modalTitle={t("questionnaires:edit_modal_title")}
          modalText={modalText}
          onClose={modalClose}
          buttons={modalButtons}
        />
      </DocView>
    </DocMetaView>
  );
}
