import { getInfoCardByIdApi, InfoCardProps } from "app/api/info-card/infoCardApi";
import { META_VIEW_TABS, META_VIEW_TABS_TYPES } from "components/MetaView/MetaView";
import { debounce } from "lodash-es";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface MetaViewInfoProps {
  readonly title: string;
  readonly text: string;
}

export interface MetaViewMetaProps {
  readonly [key: string]: any;
}
export interface MetaViewInsightProps {
  readonly title: string;
  readonly text: string;
}

export interface MetaViewAIInputProps {
  readonly fields: string[];
  readonly tenantContext?: object;
  readonly initialSuggestions?: string[];
  readonly explicitSessionId?: string;
  readonly parseOutput?: (response: object) => React.ReactNode;
}

export interface MetaViewContextValue {
  readonly info: MetaViewInfoProps | null;
  readonly setInfo: (info: MetaViewInfoProps | null) => void;
  readonly infoId: string | null;
  readonly setInfoId: (id: string | null) => void;
  readonly translatedInfo: MetaViewInfoProps | null;
  readonly selectedTab: META_VIEW_TABS_TYPES | null;
  readonly setSelectedTab: (tab: META_VIEW_TABS_TYPES) => void;
  readonly meta: MetaViewMetaProps | null;
  readonly setMeta: (meta: MetaViewMetaProps | null) => void;
  readonly questionId: string | null;
  readonly setQuestionId: (questionId: string | null) => void;
  readonly questionName: string | null;
  readonly setQuestionName: (questionName: string | null) => void;
  readonly newTaskForm: boolean;
  readonly setNewTaskForm: (taskForm: boolean) => void;
  readonly newCommentForm: boolean;
  readonly setNewCommentForm: (commentForm: boolean) => void;
  readonly newRemarkForm: boolean;
  readonly setNewRemarkForm: (commentForm: boolean) => void;
  readonly lastCreatedTaskId: string | null;
  readonly setLastCreatedTaskId: (lastCreatedTaskId: string | null) => void;
  readonly showTaskDetails: boolean;
  readonly setShowTaskDetails: (showTaskDetails: boolean) => void;
  readonly insight: MetaViewInfoProps | null;
  readonly translatedInsight: MetaViewInfoProps | null;
  readonly setInsight: (insight: MetaViewInsightProps | null) => void;
  readonly aiIntegrationMode: string | null;
  readonly setAIIntegrationMode: (aiIntegrationMode: string | null) => void;
  readonly aiIntegrationFieldInput: MetaViewAIInputProps;
  readonly setAIIntegrationFieldInput: (aiIntegrationFieldInput: MetaViewAIInputProps) => void;
  readonly aiIntegrationSuggestionCallback?: (suggestions: object, originalInput: MetaViewAIInputProps) => void;
  readonly setAIIntegrationSuggestionCallback: (
    callback: ((suggestions: object, originalInput: MetaViewAIInputProps) => void) | undefined
  ) => void;
}

const MetaViewContext = createContext<MetaViewContextValue>({
  info: null,
  setInfo: (info: MetaViewInfoProps | null) => {
    // do
  },
  infoId: null,
  setInfoId: (id: string | null) => {
    // do
  },
  translatedInfo: null,
  selectedTab: null,
  setSelectedTab: (tab: META_VIEW_TABS_TYPES) => {
    // do
  },
  meta: null,
  setMeta: (meta: MetaViewMetaProps | null) => {
    // do
  },
  questionId: null,
  setQuestionId: (questionId: string | null) => {
    // do
  },
  questionName: null,
  setQuestionName: (questionName: string | null) => {
    // do
  },
  newTaskForm: false,
  setNewTaskForm: (taskForm: boolean) => {
    // do
  },
  newCommentForm: false,
  setNewCommentForm: (commentForm: boolean) => {
    // do
  },
  newRemarkForm: false,
  setNewRemarkForm: (commentForm: boolean) => {
    // do
  },
  lastCreatedTaskId: null,
  setLastCreatedTaskId: (lastCreatedTaskId: string | null) => {
    // do
  },
  showTaskDetails: false,
  setShowTaskDetails: (showTaskDetails: boolean) => {
    // do
  },
  insight: null,
  translatedInsight: null,
  setInsight: (insight: MetaViewInsightProps | null) => {
    // do
  },
  aiIntegrationMode: null,
  setAIIntegrationMode: (aiIntegrationMode: string | null) => {
    // do
  },
  aiIntegrationFieldInput: {
    fields: []
  },
  setAIIntegrationFieldInput: (aiIntegrationFieldInput: object) => {
    // do
  },
  aiIntegrationSuggestionCallback: undefined,
  setAIIntegrationSuggestionCallback: (
    callback: ((suggestions: object, originalInput: MetaViewAIInputProps) => void) | undefined
  ) => {
    // do
  }
});

export const EntityMetaViewProvider = ({ children }: { readonly children?: React.ReactNode }) => {
  const { t } = useTranslation("");
  const [infoId, setInfoId] = useState<string | null>(null);
  const [info, setInfo] = useState<MetaViewInfoProps | null>(null);
  const [insight, setInsight] = useState<MetaViewInsightProps | null>(null);
  const [selectedTab, setSelectedTab] = useState<META_VIEW_TABS_TYPES>(META_VIEW_TABS.ASSISTANT);
  const [meta, setMeta] = useState<MetaViewMetaProps | null>(null);
  const [questionId, setQuestionId] = useState<string | null>(null);
  const [questionName, setQuestionName] = useState<string | null>(null);
  const [newTaskForm, setNewTaskForm] = useState<boolean>(false);
  const [newCommentForm, setNewCommentForm] = useState<boolean>(false);
  const [newRemarkForm, setNewRemarkForm] = useState<boolean>(false);
  const [lastCreatedTaskId, setLastCreatedTaskId] = useState<string | null>(null);
  const [showTaskDetails, setShowTaskDetails] = useState<boolean>(false);
  const [aiIntegrationMode, setAIIntegrationMode] = useState<string | null>(null);
  const [aiIntegrationFieldInput, setAIIntegrationFieldInput] = useState<MetaViewAIInputProps>({
    fields: []
  });
  const [aiIntegrationSuggestionCallback, setAIIntegrationSuggestionCallback] = useState<
    ((suggestions: object, originalInput: MetaViewAIInputProps) => void) | undefined
  >();

  useEffect(() => {
    if (newTaskForm) {
      setNewCommentForm(false);
      setNewRemarkForm(false);
      setSelectedTab(META_VIEW_TABS.TODOS);
    }
  }, [newTaskForm]);

  useEffect(() => {
    if (newCommentForm) {
      setNewTaskForm(false);
      setShowTaskDetails(false);
      setSelectedTab(META_VIEW_TABS.COMMENTS);
    }
  }, [newCommentForm]);

  useEffect(() => {
    if (newRemarkForm) {
      setNewTaskForm(false);
      setShowTaskDetails(false);
      setSelectedTab(META_VIEW_TABS.REMARKS);
    }
  }, [newRemarkForm]);

  useEffect(() => {
    if (newTaskForm || newCommentForm || newRemarkForm) {
      setShowTaskDetails(false);
    }
  }, [newTaskForm, newCommentForm, selectedTab, newRemarkForm]);

  useEffect(() => {
    if (!questionId) {
      setQuestionName(null);
    }
  }, [questionId]);

  useEffect(() => {
    if (info !== null) {
      setMeta(null);
    }
  }, [info]);

  useEffect(() => {
    if (meta !== null) {
      setInfo(null);
      setInfoId(null);
    }
  }, [meta]);

  const fetchInfoCard = useCallback(async () => {
    if (infoId) {
      const infoCardData: InfoCardProps | null = await getInfoCardByIdApi({ id: infoId });
      if (infoCardData) {
        setInfo({ title: infoCardData.title, text: infoCardData.description });
      }
    }
  }, [infoId]);
  const debouncedFetchInfoCard = useMemo(() => debounce(fetchInfoCard, 50), [fetchInfoCard]);

  useEffect(() => {
    if (infoId) {
      debouncedFetchInfoCard();
      return () => debouncedFetchInfoCard.cancel();
    }
  }, [debouncedFetchInfoCard, infoId, t]);

  const translatedInfo = useMemo(() => {
    if (!info?.title || !info?.text) {
      return null;
    }
    return {
      title: t(info.title),
      text: t(info.text)
    };
  }, [info?.title, info?.text, t]);

  const translatedInsight = useMemo(() => {
    if (!insight?.title || !insight?.text) {
      return null;
    }
    return {
      title: t(insight.title),
      text: t(insight.text)
    };
  }, [insight?.title, insight?.text, t]);

  return (
    <MetaViewContext.Provider
      value={{
        info,
        setInfo,
        infoId,
        setInfoId,
        translatedInfo,
        insight,
        setInsight,
        translatedInsight,
        selectedTab,
        setSelectedTab,
        meta,
        setMeta,
        questionId,
        setQuestionId,
        questionName,
        setQuestionName,
        newTaskForm,
        setNewTaskForm,
        newCommentForm,
        setNewCommentForm,
        newRemarkForm,
        setNewRemarkForm,
        showTaskDetails,
        setShowTaskDetails,
        lastCreatedTaskId,
        setLastCreatedTaskId,
        aiIntegrationMode,
        setAIIntegrationMode,
        aiIntegrationFieldInput,
        setAIIntegrationFieldInput,
        aiIntegrationSuggestionCallback,
        setAIIntegrationSuggestionCallback
      }}
    >
      {children}
    </MetaViewContext.Provider>
  );
};

export const useMetaView = () => useContext(MetaViewContext);
