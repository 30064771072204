import { useNavigate } from "react-router-dom";
import { COLLECTION_TYPES } from "app/collections";
import { OVERVIEW_ACTIONS, SingleOverviewContext, useOverviewDispatch } from "app/contexts/overview-context";
import { useAuthentication } from "../../../app/handlers/authentication/authentication-context";
import { chunk, intersectionWith, isArray, isEmpty, isEqual } from "lodash-es";
import {
  addItemToSection as addItemToSectionHandler,
  createSection,
  deleteSection as deletionSectionHandler,
  removeItemFromSection as removeItemFromSectionHandler,
  renameSection
} from "../../../app/handlers/sectionHandler";
import { searchOverviewItems } from "../utils/overviewBaseController.search";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { orderOverviewItems } from "../utils/overviewBaseController.order";

export type OverviewItem<TExtras extends Record<string, any> = Record<string, any>> = {
  readonly id: string;
  readonly title: string;
  readonly subTitle?: string;
  readonly children?: OverviewItem[];
  readonly expanded?: boolean;
  readonly selected?: boolean | undefined;
  readonly disableActions?: { readonly action: string }[];
  readonly displaySelectCheckbox?: boolean;

  // for case like document center where only a portion of the title is editable (e.g. exclude extension)
  readonly editableTitle?: string;

  readonly [key: string]: any;
} & TExtras;

export interface OverviewNewItem {
  readonly title?: string;
  readonly parentId?: string;

  readonly [key: string]: any;
}

export interface OverviewItemsCount {
  readonly allCount: number | null;
  readonly currentCount: number | null;
}

export type OverviewSetup = SingleOverviewContext;

export interface OverviewResult {
  readonly items: OverviewItem[];
  readonly allItems: OverviewItem[];
  readonly responseItems: OverviewItem[];
  readonly selectedItem: OverviewItem | null;
  readonly count?: OverviewItemsCount;
  readonly moreItemsExist?: boolean;
  readonly templates?: { id: string; name: string; category: string }[];

  readonly [key: string]: any;
}

export interface TemplateItem {
  readonly id: string;
  readonly name: string;
  readonly category: string;
}

interface Cursor {
  current: string | null;
  next: string | null;
}

const OverviewBaseController = (
  axiosInstance: AxiosInstance,
  collection: COLLECTION_TYPES,
  normalizeItem?: (input: OverviewItem) => OverviewItem,
  decorators?: OverviewResultDecorator<object>[]
): OverviewController => {
  const navigate = useNavigate();
  const { auth } = useAuthentication();
  const dispatch = useOverviewDispatch();

  let lastOverviewSetup: OverviewSetup | null = null;
  let items: OverviewItem[] | null = null;
  let itemsFastAccess: Map<string, OverviewItem> = new Map();
  let responseData: { items: OverviewItem[] } & any = { items: [] };
  let itemsLeft = 0;
  let cursors: Cursor = {
    current: null,
    next: null
  };

  const _loading = (loading: boolean) => {
    dispatch({
      type: OVERVIEW_ACTIONS.SET_LOADING,
      collection,
      loading
    });
  };

  const _initItemsFastAccess = (items: OverviewItem[] | null) => {
    const newItemsFastAccess = new Map<string, OverviewItem>();

    const addItemsFastAccess = (items: OverviewItem[] | null) => {
      items?.forEach(item => {
        newItemsFastAccess.set(item.id, item);
        if (item.children) {
          addItemsFastAccess(item.children);
        }
      });
    };

    if (items) {
      addItemsFastAccess(items);
    }

    itemsFastAccess = newItemsFastAccess;
  };

  const _loadingMore = (loadingMore: boolean) => {
    dispatch({
      type: OVERVIEW_ACTIONS.SET_LOADING_MORE,
      collection,
      loadingMore
    });
  };

  const _findById = (items: OverviewItem[], id: string): OverviewItem | undefined => {
    return itemsFastAccess.get(id);
  };

  const _normalize = (items: OverviewItem[]): OverviewItem[] => {
    if (!normalizeItem) {
      return items;
    }

    return items.map(i => {
      const normalizedItem = normalizeItem(i);
      if (normalizedItem.children) {
        return { ...normalizedItem, children: _normalize(normalizedItem.children) };
      }
      return normalizedItem;
    });
  };

  const _filter = (items: OverviewItem[], key: string, filter: string | string[]): OverviewItem[] => {
    return items.flatMap(i => {
      let children = i?.children && (_filter(i.children, key, filter) || []);
      children = children && children.filter(i => i);
      if (
        isArray(i?.[key]) &&
        (intersectionWith(filter, i[key], isEqual)?.length > 0 || i[key].includes("*")) // if wildcard item in array, then it always matches
      ) {
        return { ...i, children };
      } else if (i?.[key] !== undefined && filter.includes(i[key])) {
        return { ...i, children };
      } else if (children?.length) {
        return { ...i, children };
      }
      return [];
    });
  };

  const _sort = (items: OverviewItem[], key: string, sortType?: "asc" | "desc"): OverviewItem[] => {
    return orderOverviewItems(items, key, sortType);
  };

  const _selected = (items: OverviewItem[], selectedId: string): OverviewItem[] => {
    return items.map(item => ({
      ...item,
      expanded: item.expanded || item.children?.some(i => i.id === selectedId),
      selected: item.id === selectedId,
      children: item.children ? _selected(item.children, selectedId) : undefined
    }));
  };

  const _flatMapChildren = (items: OverviewItem[]): OverviewItem[] =>
    items.flatMap(item => (item.children ? _flatMapChildren(item.children) : item));
  const _getSelected = (items: OverviewItem[], selectedId: string): OverviewItem | undefined => {
    const flatItems: OverviewItem[] = _flatMapChildren(items);
    return flatItems.find(({ id }) => id === selectedId);
  };

  const _get = (setup: OverviewSetup): { itemsForPage: OverviewItem[]; itemsForAllPages: OverviewItem[] } => {
    let _items = [...(items || [])];

    // search
    if (setup.search) {
      const search = setup.search.toLowerCase();
      _items = search.length ? searchOverviewItems(_items, search) : _items;
    }

    // filter
    if (!isEmpty(setup.filter)) {
      Object.entries(setup.filter).forEach(([key, value]) => {
        if (value.length) {
          _items = _filter(_items, key, value);
        }
      });
      _items = _items.filter(i => i);
    }

    // sort
    if (!isEmpty(setup.sort)) {
      Object.entries(setup.sort).forEach(([key, sortType]) => {
        _items = _sort(_items, key, sortType);
      });
    }

    // selectedId
    if (setup.selectedId) {
      _items = _selected(_items, setup.selectedId);
    }

    itemsLeft = _items.length - (setup.loaded || 0);
    return {
      itemsForPage: _items.slice(0, setup.loaded),
      itemsForAllPages: _items
    };
  };

  const getItemsWithChildrenCount = (items: OverviewItem[] | null): number | null => {
    if (!items) return null;
    let count = 0;

    for (const item of items) {
      count++; // Count the current item
      if (item.children) {
        count += getItemsWithChildrenCount(item.children) || 0;
      }
    }

    return count;
  };

  const _getQuery = (setup: OverviewSetup): Record<string, any> => {
    const query: Record<string, any> = {};
    query["limit"] = 50;
    if (cursors.next) {
      query["cursor"] = cursors.next;
    }

    // text search
    if (setup.search !== "") {
      query["filter"] = {
        title: {
          contains: setup.search
        }
      };
    }

    // filter
    if (setup.filter && !isEqual(setup.filter, {})) {
      query["filter"] = {
        ...query["filter"],
        ...Object.keys(setup.filter).reduce<Record<string, any>>(
          (acc, next) => ({ ...acc, [next]: { in: setup.filter[next] } }),
          []
        )
      };
    }

    // sort
    // default sort is by newest first
    query["sort"] = setup.sort && !isEqual(setup.sort, {}) ? [setup.sort] : [{ createdAt: "desc" }];

    return query;
  };

  const _updateOverview = (setup: OverviewSetup): OverviewResult | null => {
    if (items === null) {
      return null;
    }
    const { itemsForPage, itemsForAllPages } = _get(setup);
    const { allCount, currentCount } = {
      allCount: getItemsWithChildrenCount(itemsForAllPages),
      currentCount: getItemsWithChildrenCount(itemsForPage)
    };
    const count = { allCount: allCount, currentCount: allCount && allCount !== currentCount ? currentCount : null };
    const selectedItem = setup.selectedId ? _getSelected(items, setup.selectedId) : null;
    return {
      ...responseData,
      items: itemsForPage,
      allItems: itemsForAllPages,
      responseItems: items,
      selectedItem,
      count,
      moreItemsExist: itemsLeft > 0
    };
  };

  const goToItem = (url: string): void => {
    navigate(url);
  };

  const withDecorators = async <T extends OverviewResult>(
    fetchOverview: () => Promise<AxiosResponse<T>>
  ): Promise<T> => {
    const prefetchDecorators = (decorators || []).filter(d => d.type === "prefetch");
    const emptyOverviewResult: OverviewResult = {
      items: [],
      allItems: [],
      responseItems: [],
      selectedItem: null
    };
    const [overviewResponse, ...prefetchDecoratedResponse] = await Promise.all([
      fetchOverview(),
      ...prefetchDecorators.map(d => d.decorate(emptyOverviewResult, auth?.tenantId || ""))
    ]);

    const overviewWithPrefetchDecorators: OverviewResult = {
      ...prefetchDecoratedResponse.reduce<OverviewResult>(
        (acc, next) => ({
          ...acc,
          ...next
        }),
        emptyOverviewResult
      ),
      ...overviewResponse.data
    };

    const normalDecorators = (decorators || []).filter(d => d.type === "normal");
    if (normalDecorators.length === 0) {
      return overviewWithPrefetchDecorators as T;
    }

    let resultOverview: any = overviewWithPrefetchDecorators;
    for (const decorator of normalDecorators) {
      resultOverview = await decorator.decorate(resultOverview, auth?.tenantId || "");
    }
    return resultOverview;
  };

  const getOverview = async (
    setup: OverviewSetup,
    url?: string,
    config?: AxiosRequestConfig
  ): Promise<OverviewResult | null> => {
    if (isEqual(lastOverviewSetup?.reloadOverview, setup.reloadOverview)) {
      return _updateOverview(setup);
    }

    lastOverviewSetup = setup || lastOverviewSetup;

    !setup.shadowLoading && _loading(true);
    const data = await withDecorators(() => axiosInstance.get(url || "/overview", config));
    if (data) {
      responseData = data;
      items = data.items;
      if (normalizeItem && items) {
        items = _normalize(items);
      }
      _initItemsFastAccess(items);
      const { itemsForPage, itemsForAllPages } = _get(setup);
      const { allCount, currentCount } = {
        allCount: getItemsWithChildrenCount(items || []),
        currentCount: getItemsWithChildrenCount(itemsForPage)
      };
      const selectedItem = setup.selectedId ? _getSelected(items || [], setup.selectedId) : null;
      const count = {
        allCount: allCount,
        currentCount: allCount && allCount !== currentCount ? currentCount : null
      };

      _loading(false);

      return {
        ...data,
        items: itemsForPage,
        allItems: itemsForAllPages,
        responseItems: items,
        selectedItem,
        count,
        moreItemsExist: itemsLeft > 0
      };
    }

    _loading(false);
    return null;
  };

  const getPaginatedOverview = async (
    setup: OverviewSetup,
    url?: string,
    config?: AxiosRequestConfig
  ): Promise<OverviewResult | null> => {
    if (
      // only check for relevant props, we don't want e.g. for checked item to trigger a reload to BE
      isEqual(lastOverviewSetup?.reloadOverview, setup.reloadOverview) &&
      isEqual(lastOverviewSetup?.reload, setup.reload) &&
      isEqual(lastOverviewSetup?.search, setup.search) &&
      isEqual(lastOverviewSetup?.filter, setup.filter) &&
      isEqual(lastOverviewSetup?.sort, setup.sort)
    ) {
      return null;
    }

    lastOverviewSetup = setup || lastOverviewSetup;
    cursors.current = null;
    cursors.next = null;
    items = [];
    itemsFastAccess = new Map<string, OverviewItem>();
    const query = _getQuery(setup);

    !setup.shadowLoading && _loading(true);
    const data = await withDecorators(() => axiosInstance.post(url ? url : `/overview/paginated`, query, config));
    if (data) {
      responseData = data;
      items = data.items || [];
      cursors = data.cursors;
      const prefetchNextItem = cursors?.next
        ? await axiosInstance.post(
            url ? url : `/overview/paginated`,
            {
              ...query,
              cursor: cursors.next,
              limit: 1
            },
            config
          )
        : undefined;
      if (normalizeItem && items) {
        items = _normalize(items);
      }
      _initItemsFastAccess(items);
      const count = {
        allCount: responseData.totalCount,
        currentCount: items?.length
      };
      const selectedItem = setup.selectedId ? _getSelected(items || [], setup.selectedId) : null;
      if (items && setup.selectedId) {
        items = _selected(items, setup.selectedId);
        _initItemsFastAccess(items);
      }

      _loading(false);

      return {
        ...data,
        items: items,
        allItems: items,
        responseItems: items,
        count,
        selectedItem,
        moreItemsExist: !!prefetchNextItem?.data?.cursors?.next
      };
    }

    _loading(false);
    return null;
  };

  const loadAllPaginatedPages = async (setup: OverviewSetup, url?: string): Promise<OverviewResult | null> => {
    const query = _getQuery(setup);
    const startData = await withDecorators(() =>
      axiosInstance.post(url ? url : `/overview/paginated`, {
        ...query,
        cursor: null,
        limit: 100
      })
    );
    let loadAllCursor = startData.cursors; // We dont set "cursor" and "items" here but instead use new variables
    let allItems = startData.items || [];
    if (startData) {
      let moreItemsExist = true;
      while (moreItemsExist) {
        const nextData = loadAllCursor?.next
          ? await axiosInstance.post(url ? url : `/overview/paginated`, {
              ...query,
              limit: 100,
              cursor: loadAllCursor.next
            })
          : undefined;
        allItems = [...(allItems || []), ...(nextData?.data?.items || [])];
        loadAllCursor = nextData?.data.cursors;
        moreItemsExist = !!nextData?.data.cursors?.next;
      }
    }
    _initItemsFastAccess(allItems);

    return {
      ...startData,
      allItems: allItems
    };
  };

  const updatePaginatedOverview = async (
    setup: OverviewSetup,
    url?: string,
    config?: AxiosRequestConfig
  ): Promise<OverviewResult | null> => {
    if (!cursors.next) {
      return null;
    }
    const query = _getQuery(setup);
    !setup.shadowLoading && _loadingMore(true);
    const data = await withDecorators(() => axiosInstance.post(url ? url : `/overview/paginated`, query, config));

    if (data) {
      responseData = data;
      items = [...(items || []), ...(data.items || [])];
      _initItemsFastAccess(items);
      cursors = data.cursors;
      const prefetchNextItem = cursors.next
        ? await axiosInstance.post(
            url ? url : `/overview/paginated`,
            {
              ...query,
              cursor: cursors.next,
              limit: 1
            },
            config
          )
        : undefined;
      if (normalizeItem) {
        items = _normalize(items);
        _initItemsFastAccess(items);
      }
      const count = {
        allCount: responseData.totalCount,
        currentCount: items.length
      };
      const selectedItem = setup.selectedId ? _getSelected(items || [], setup.selectedId) : null;
      if (items && setup.selectedId) {
        items = _selected(items, setup.selectedId);
        _initItemsFastAccess(items);
      }
      _loadingMore(false);
      return {
        ...data,
        items: items,
        responseItems: items,
        count,
        selectedItem,
        moreItemsExist: !!prefetchNextItem?.data?.cursors?.next
      };
    }

    _loadingMore(false);
    return null;
  };

  const addSection = async (title: string): Promise<string> => {
    return await createSection(auth?.tenantId || "", auth?.uid || "", collection, title);
  };

  const patchSection = async (sectionId: string, title: string): Promise<void> => {
    return await renameSection(auth?.tenantId || "", auth?.uid || "", collection, sectionId, title);
  };

  const deleteSection = async (sectionId: string): Promise<void> => {
    return await deletionSectionHandler(auth?.tenantId || "", auth?.uid || "", collection, sectionId);
  };

  const addItemToSection = async (sectionId: string, itemDocId: string): Promise<void> => {
    return await addItemToSectionHandler(auth?.tenantId || "", auth?.uid || "", collection, sectionId, itemDocId);
  };

  const removeItemFromSection = async (sectionId: string, itemDocId: string): Promise<void> => {
    return await removeItemFromSectionHandler(auth?.tenantId || "", auth?.uid || "", collection, sectionId, itemDocId);
  };

  const addItem = async (data: any, url?: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return await axiosInstance.post(url || `/`, data, options);
  };

  const getTemplateItems = async (url?: string, options?: AxiosRequestConfig): Promise<TemplateItem[]> => {
    const response = await axiosInstance.get<{ readonly templates?: Partial<TemplateItem>[] }>(
      url || `/templates`,
      options
    );
    return (response.data?.templates || []).map(({ id, name, category }) => ({
      id: id || "",
      name: name || "",
      category: category || ""
    }));
  };

  const addItemsFromTemplates = async (
    data: {
      templateIds: string[];
    },
    url?: string,
    options?: AxiosRequestConfig
  ) => {
    // chunk so that no requests takes too long
    for (const templateIdsChunk of chunk(data.templateIds, 20)) {
      await axiosInstance.post(
        url || `/templates`,
        {
          ...data,
          templateIds: templateIdsChunk
        },
        {
          ...options,
          // create from template can take very long, we should not restrict it
          timeout: 0
        }
      );
    }
  };

  const patchItem = async (id: string, data: any, url?: string, options?: AxiosRequestConfig) => {
    await axiosInstance.patch(url || `/${id}`, data, options);
  };

  const deleteItem = async (id: string, url?: string, options?: AxiosRequestConfig) => {
    await axiosInstance.delete(url || `/${id}`, options);
  };

  const getById = (id: string): OverviewItem | null => {
    return _findById(items || [], id) || null;
  };

  const copyItems = async (
    { ids, orgUnitIds }: { ids: string[]; orgUnitIds?: string[] },
    url?: string,
    chunkSize?: number
  ) => {
    for (const toCopyIdsChunk of chunk(ids || [], chunkSize || 20)) {
      await axiosInstance.post(
        url || `/copies`,
        { ids: toCopyIdsChunk, orgUnitIds },
        {
          timeout: 0 // copying can take very long depending on the amount of items
        }
      );
    }
  };

  const markAllAsRead = async (prefix?: string) => {
    // prefix is for resources overview
    let endpoint = prefix ? `${prefix}/overview/mark-all-as-read` : "/overview/mark-all-as-read";
    // for tasks overview
    if (axiosInstance.defaults.baseURL?.endsWith("/overview")) {
      endpoint = endpoint.replace("/overview", "");
    }
    await axiosInstance.post(endpoint);
  };

  return {
    getById,
    goToItem,
    updatePaginatedOverview,
    getOverview,
    getPaginatedOverview,
    loadAllPaginatedPages,
    addSection,
    patchSection,
    deleteSection,
    addItemToSection,
    removeItemFromSection,
    addItem,
    getTemplateItems,
    addItemsFromTemplates,
    deleteItem,
    getItemsWithChildrenCount,
    patchItem,
    copyItems,
    validateItem: undefined,
    addItemAndGo: undefined,
    markAllAsRead
  };
};

export interface OverviewController {
  readonly goToItem: (url: string, item?: OverviewItem) => void;
  readonly updatePaginatedOverview: (
    setup: OverviewSetup,
    url?: string,
    options?: AxiosRequestConfig
  ) => Promise<OverviewResult | null>;
  readonly getOverview: (
    setup: OverviewSetup,
    url?: string,
    options?: AxiosRequestConfig
  ) => Promise<OverviewResult | null>;
  readonly getPaginatedOverview: (
    setup: OverviewSetup,
    url?: string,
    options?: AxiosRequestConfig
  ) => Promise<OverviewResult | null>;
  readonly loadAllPaginatedPages: (setup: OverviewSetup, url?: string) => Promise<OverviewResult | null>;
  readonly addSection: (title: string) => Promise<string>;
  readonly patchSection: (sectionId: string, title: string) => Promise<void>;
  readonly deleteSection: (sectionId: string) => Promise<void>;
  readonly addItemToSection: (sectionId: string, itemDocId: string) => Promise<void>;
  readonly removeItemFromSection: (sectionId: string, itemDocId: string) => Promise<void>;
  readonly getTemplateItems: (url?: string, options?: AxiosRequestConfig) => Promise<TemplateItem[]>;
  readonly addItemsFromTemplates: (
    data: { templateIds: string[] },
    url?: string,
    options?: AxiosRequestConfig
  ) => Promise<void>;
  readonly addItem: (data: OverviewNewItem, url?: string, options?: AxiosRequestConfig) => Promise<AxiosResponse>;
  readonly addItemAndGo?: (data: OverviewNewItem) => Promise<void>;
  readonly patchItem: (
    id: string,
    data: object,
    url?: string,
    options?: AxiosRequestConfig,
    originalItem?: OverviewItem
  ) => Promise<void>;
  readonly deleteItem: (id: string, url?: string, options?: AxiosRequestConfig) => Promise<void>;
  readonly getById: (id: string) => OverviewItem | null;
  readonly getItemsWithChildrenCount: (items: OverviewItem[] | null) => number | null;
  readonly copyItems: (
    { ids, orgUnitIds }: { ids: string[]; orgUnitIds?: string[] | undefined },
    url?: string,
    chunkSize?: number
  ) => Promise<void>;
  readonly deleteItems?: (ids: string[], url?: string) => Promise<void>;
  readonly validateItem?: (data: object) => string | null;
  readonly exportItems?: (format: string, ids: string[], setup: OverviewSetup) => Promise<void>;
  readonly exportAllItems?: (format: string, setup: OverviewSetup) => Promise<void>;
  readonly markAllAsRead?: (prefix?: string) => Promise<void>;
  readonly onDragOver?: (draggableItem: OverviewItem, droppableItem: OverviewItem) => boolean;
  readonly onDragEnd?: (draggableItem: OverviewItem, droppableItem: OverviewItem) => void;
}

export interface OverviewResultDecorator<T extends object> {
  readonly decorate: (overviewResult: OverviewResult, tenantId: string) => Promise<T>;
  /**
   * There are two types of decorator. Prefetch or normal.
   * Normally people use decorator to decorate the result of something.
   * The normal decorator will be called after the overview api so that it can modify the results of the api.
   * But it leads to slow down since additional waiting for the decorator api call.
   *
   * In most of our case, we only need to add new property to the api call result.
   * So we introduced prefetch type, where the decorator api call will be called together with the overview api call.
   * This will save time, but the decorator will not be able to modify the result of the api call, only adds new props.
   * But this is 90% of our use cases right now.
   */
  readonly type: "prefetch" | "normal";
}

export default OverviewBaseController;

export interface FiterTreeItemProps {
  readonly id?: any;
  readonly name: any;
  readonly checkable: boolean;
  readonly children: FiterTreeItemProps[];
  readonly sortingPosition?: number;
}

export interface FilterItemProps {
  readonly label?: string;
  readonly filterMode?: string;
  readonly filterTree: FiterTreeItemProps[];
  readonly filterField: string;
  readonly singleSelect?: boolean;
  readonly uncheckParentForUncheckedChildrens?: boolean;
  readonly switchControl?: {
    readonly label: string;
    readonly defaultValue: string[];
  };
}
