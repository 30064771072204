import AITechDocOverviewBrickDownload from "./ai-tech-docs/AITechDocOverviewBrickDownload";
import AITechDocOverviewBrickStatus from "./ai-tech-docs/AITechDocOverviewBrickStatus";
import AnswersetOverviewBrickRoles from "./assessments/AnswersetOverviewBrickRoles";
import AnswersetOverviewBrickStatus from "./assessments/AnswersetOverviewBrickStatus";
import AssessmentOverviewBrickContributors from "./assessments/AssessmentOverviewBrickContributors";
import AssessmentOverviewBrickMethod from "./assessments/AssessmentOverviewBrickMethod";
import AssessmentOverviewBrickReportDueDate from "./assessments/AssessmentOverviewBrickReportDueDate";
import AssessmentOverviewBrickStatus from "./assessments/AssessmentOverviewBrickStatus";
import AssessmentTemplateOverviewBrickStatus from "./assessments/AssessmentTemplateOverviewBrickStatus";
import AssetOverviewBrickStatus from "./assets/AssetOverviewBrickStatus";
import AssetOverviewBrickType from "./assets/AssetOverviewBrickType";
import OverviewBrickAdd from "./common/OverviewBrickAdd";
import OverviewBrickDate from "./common/OverviewBrickDate";
import OverviewBrickDueDate from "./common/OverviewBrickDueDate";
import OverviewBrickLink from "./common/OverviewBrickLink";
import OverviewBrickMerge from "./common/OverviewBrickMerge";
import OverviewBrickReadonly from "./common/OverviewBrickReadonly";
import OverviewBrickRemove from "./common/OverviewBrickRemove";
import OverviewBrickRename from "./common/OverviewBrickRename";
import OverviewBrickSettings from "./common/OverviewBrickSettings";
import OverviewRowTitle from "./common/OverviewBrickTitle";
import OverviewBrickUnlink from "./common/OverviewBrickUnlink";
import OverviewBrickUnseen from "./common/OverviewBrickUnsee";
import OverviewUnseeCount from "./common/OverviewBrickUnseeCount";
import OverviewBrickUpdatedAt from "./common/OverviewBrickUpdatedAt";
import OverviewBrickUploadTemplate from "./common/OverviewBrickUploadTemplate";
import MultilingualRename from "./person-groups/MultilingualRename";
import DataBreachOverviewBrickDueDate from "./data-breaches/DataBreachOverviewBrickDueDate";
import DataBreachOverviewBrickStatus from "./data-breaches/DataBreachOverviewBrickStatus";
import DeletionConceptOverviewBrickProcessesNumber from "./deletion-concept/DeletionConceptOverviewBrickProcessesNumber";
import ExternalRecipientOverviewBrickStatus from "./external-recipients/ExternalRecipientOverviewBrickStatus";
import OrganizationOverviewBrickMerge from "./organizations/OrganizationOverviewBrickMerge";
import PersonGroupOverviewBrickAdd from "./person-groups/PersonGroupOverviewBrickAdd";
import PersonGroupOverviewBrickIcon from "./person-groups/PersonGroupOverviewBrickIcon";
import PersonGroupOverviewBrickUntranslatedCount from "./person-groups/PersonGroupOverviewBrickUntranslatedCount";
import ProcessOverviewBrickStatus from "./processes/ProcessOverviewBrickStatus";
import RequestOverviewBrickDueDate from "./requests/RequestOverviewBrickDueDate";
import RequestOverviewBrickStatus from "./requests/RequestOverviewBrickStatus";
import RiskOverviewBrickLevel from "./risks/RiskOverviewBrickLevel";
import TaskOverviewBrickAssignGroups from "./tasks/TaskOverviewBrickAssignGroups";
import TaskOverviewBrickAssignUser from "./tasks/TaskOverviewBrickAssignUser";
import TaskOverviewBrickDueDate from "./tasks/TaskOverviewBrickDueDate";
import TaskOverviewBrickGroup from "./tasks/TaskOverviewBrickGroup";
import TaskOverviewBrickPriority from "./tasks/TaskOverviewBrickPriority";
import TaskOverviewBrickRecurring from "./tasks/TaskOverviewBrickRecurring";
import TaskOverviewBrickStatus from "./tasks/TaskOverviewBrickStatus";
import TomOverviewBrickProcessesNumber from "./toms/TomOverviewBrickProcessesNumber";
import TomOverviewBrickStatus from "./toms/TomOverviewBrickStatus";
import UserOverviewBrickResetPassword from "./users/UserOverviewBrickResetPassword";
import OverviewBrickCopy from "./common/OverviewBrickCopy";
import OverviewBrickMove from "./common/OverviewBrickMove";
import OverviewBrickClassify from "./common/OverviewBrickClassify";
import OrganizationOverviewBrickDelete from "./organizations/OrganizationOverviewBrickDelete";
import OrganizationOverviewBrickMove from "./organizations/OrganizationOverviewBrickMove";
import DocumentOverviewBrickIcon from "./documents/DocumentOverviewBrickIcon";
import DocumentOverviewBrickDownload from "./documents/DocumentOverviewBrickDownload";
import DocumentOverviewBrickUpload from "./documents/DocumentOverviewBrickUpload";
import DocumentOverviewBrickAddFolder from "./documents/DocumentOverviewBrickAddFolder";
import DocumentOverviewBrickMove from "./documents/DocumentOverviewBrickMove";
import DocumentOverviewBrickLinkOrgUnit from "./documents/DocumentOverviewBrickLinkOrgUnit";
import UserOverviewBrickRole from "./users/UserOverviewBrickRole";
import OverviewBrickScan from "./common/OverviewBrickScan";
import AITechDocOverviewBrickAdd from "./ai-tech-docs/AITechDocOverviewBrickAdd";
import UserOverviewBrickDelete from "./users/UserOverviewBrickDelete";
import { DocumentOverviewBrickCopyURL } from "./documents/DocumentOverviewBrickCopyURL";

const OverviewBrick = ({ children }: { readonly children: React.ReactNode }) => {
  return <>{children}</>;
};

/* COMMON */
OverviewBrick.Title = OverviewRowTitle;
OverviewBrick.Unseen = OverviewBrickUnseen;
OverviewBrick.UnseenCount = OverviewUnseeCount;
OverviewBrick.Remove = OverviewBrickRemove;
OverviewBrick.Add = OverviewBrickAdd;
OverviewBrick.Copy = OverviewBrickCopy;
OverviewBrick.Move = OverviewBrickMove;
OverviewBrick.Classify = OverviewBrickClassify;
OverviewBrick.Readonly = OverviewBrickReadonly;
OverviewBrick.Date = OverviewBrickDate;
OverviewBrick.Rename = OverviewBrickRename;
OverviewBrick.DueDate = OverviewBrickDueDate;
OverviewBrick.UpdatedAt = OverviewBrickUpdatedAt;
OverviewBrick.Unlink = OverviewBrickUnlink;
OverviewBrick.Link = OverviewBrickLink;
OverviewBrick.Merge = OverviewBrickMerge;
OverviewBrick.UploadTemplate = OverviewBrickUploadTemplate;
OverviewBrick.Settings = OverviewBrickSettings;
OverviewBrick.Scan = OverviewBrickScan;
OverviewBrick.MultilingualRename = MultilingualRename;

/* TASKS */
OverviewBrick.TaskStatus = TaskOverviewBrickStatus;
OverviewBrick.TaskPriority = TaskOverviewBrickPriority;
OverviewBrick.TaskGroup = TaskOverviewBrickGroup;
OverviewBrick.TaskRecurring = TaskOverviewBrickRecurring;
OverviewBrick.TaskDueDate = TaskOverviewBrickDueDate;
OverviewBrick.TaskAssignUser = TaskOverviewBrickAssignUser;
OverviewBrick.TaskAssignGroup = TaskOverviewBrickAssignGroups;

/* PROCESSES */
OverviewBrick.ProcessStatus = ProcessOverviewBrickStatus;

/* TOM */
OverviewBrick.TomStatus = TomOverviewBrickStatus;
OverviewBrick.TomProcessesNumber = TomOverviewBrickProcessesNumber;

/* ASSESSMENT */
OverviewBrick.AssessmentStatus = AssessmentOverviewBrickStatus;
OverviewBrick.AnswersetStatus = AnswersetOverviewBrickStatus;
OverviewBrick.AssessmentTemplateStatus = AssessmentTemplateOverviewBrickStatus;
OverviewBrick.AssessmentMethod = AssessmentOverviewBrickMethod;
OverviewBrick.AnswersetRole = AnswersetOverviewBrickRoles;
OverviewBrick.AssessmentContributors = AssessmentOverviewBrickContributors;
OverviewBrick.AssessmentReportDueDate = AssessmentOverviewBrickReportDueDate;

/* ASSET */
OverviewBrick.AssetStatus = AssetOverviewBrickStatus;
OverviewBrick.AssetType = AssetOverviewBrickType;

/* RISK */
OverviewBrick.RiskLevel = RiskOverviewBrickLevel;

/* DATA BREACH */
OverviewBrick.DataBreachDueDate = DataBreachOverviewBrickDueDate;
OverviewBrick.DataBreachStatus = DataBreachOverviewBrickStatus;

/* REQUESTS */
OverviewBrick.RequestStatus = RequestOverviewBrickStatus;
OverviewBrick.RequestDueDate = RequestOverviewBrickDueDate;

/* EXTERNAL RECIPIENT */
OverviewBrick.ExternalRecipientStatus = ExternalRecipientOverviewBrickStatus;

/* AI TECH ODC */
OverviewBrick.AITechDocStatus = AITechDocOverviewBrickStatus;
OverviewBrick.AITechDocDownload = AITechDocOverviewBrickDownload;
OverviewBrick.AITechDocAdd = AITechDocOverviewBrickAdd;

/* ORGANIZATION */
OverviewBrick.OrganizationMerge = OrganizationOverviewBrickMerge;
OverviewBrick.OrganizationDelete = OrganizationOverviewBrickDelete;
OverviewBrick.OrganizationMove = OrganizationOverviewBrickMove;

/* DELETION CONCEPT */
OverviewBrick.DeletionConceptProcesses = DeletionConceptOverviewBrickProcessesNumber;

/* USERS */
OverviewBrick.UserResetPassword = UserOverviewBrickResetPassword;
OverviewBrick.UserRole = UserOverviewBrickRole;
OverviewBrick.UserDelete = UserOverviewBrickDelete;

/* PERSON GROUPS */
OverviewBrick.PersonGroupAdd = PersonGroupOverviewBrickAdd;
OverviewBrick.PersonGroupIcon = PersonGroupOverviewBrickIcon;
OverviewBrick.PersonGroupUntranslatedCount = PersonGroupOverviewBrickUntranslatedCount;

/* DOCUMENTS */
OverviewBrick.DocumentIcon = DocumentOverviewBrickIcon;
OverviewBrick.DocumentDownload = DocumentOverviewBrickDownload;
OverviewBrick.DocumentUpload = DocumentOverviewBrickUpload;
OverviewBrick.DocumentAddFolder = DocumentOverviewBrickAddFolder;
OverviewBrick.DocumentMove = DocumentOverviewBrickMove;
OverviewBrick.DocumentLinkOrgUnit = DocumentOverviewBrickLinkOrgUnit;
OverviewBrick.DocumentCopyURL = DocumentOverviewBrickCopyURL;

export default OverviewBrick;
