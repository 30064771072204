import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DocView from "../../../components/DocView/DocView";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { RiskPageButtons, RiskPageStepper } from "./RiskPagination";
import { Box, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { AttachmentsOverviewOBS } from "../shared/Attachments/AttachmentsOverviewOBS";
import TextBody2 from "../../../components/TextBody2/TextBody2";
import { useRisk } from "../../contexts/risk-context";
import { tenantRiskId } from "../../handlers/risksHandler";
import Question from "components/Question/Question";
import { COLLECTIONS } from "../../collections";
import { QUESTION_TYPE } from "../../../components/Question/QuestionTypes";
import { useUpdateQueues } from "../../../hook/useUpdateQueues";
import { RiskPayloadDTO } from "../../api/riskApi";
import { useDocumentName } from "app/contexts/document-name-context";

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

export const RiskGeneralPage = () => {
  const { t } = useTranslation("risk_general_page");
  const { risk, initialized } = useRisk();

  return (
    <DocMetaView
      loading={!initialized || !risk}
      metaViewContent={
        <MetaView
          translationKey={"risk_general_page"}
          docName={risk?.title || ""}
          docId={risk?.id || ""}
          collection={COLLECTIONS.RISK}
          tabs={metaViewTabIds}
        />
      }
    >
      <DocView header={risk?.title || ""} pagination={<RiskPageStepper />}>
        <Box mt={4} />
        <QuestionnaireSubHeader text={t("subTitle")} />
        <RiskGeneral />
        <Box mt={4} />
        <RiskPageButtons leftArea={undefined} />
      </DocView>
    </DocMetaView>
  );
};

export const RiskGeneral = () => {
  const { t } = useTranslation("risk_general_page");
  const { setDocumentName } = useDocumentName();
  const { risk, updateBasicInfoHook, riskId, initialized } = useRisk();
  const { setInfo } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entered: {
        title: t("risk_general_page:enteringInfoCardTitle"),
        text: t("risk_general_page:enteringInfoCardText")
      },
      title: {
        title: t("titleField"),
        text: t("riskTitleCard")
      },
      description: {
        title: t("generalDescription"),
        text: t("riskDescriptionCard")
      },
      riskOwner: {
        title: t("riskOwner"),
        text: t("riskOwnerInfoCard")
      },
      orgUnitId: {
        title: t("responsible_department"),
        text: t("riskOrgUnitInfoCard")
      },
      furtherAffectedOrgUnitIds: {
        title: t("furtherAffectedOrgUnit"),
        text: t("riskFurtherAffectedOrgUnitInfoCard")
      },
      dataLocationIds: {
        title: t("dataLocationIds"),
        text: t("affectedDataLocationsCard")
      },
      protectionObjectiveIds: {
        title: t("protectionObjectiveIds"),
        text: t("protectionObjectivesCard")
      },
      labelIds: {
        title: t("labelIds"),
        text: t("labelIdsCard")
      }
    }),
    [t]
  );

  const setEnteredInfo = useCallback(() => {
    setInfo(infoCard.entered);
  }, [infoCard.entered, setInfo]);

  useEffect(() => {
    setEnteredInfo();
  }, [setEnteredInfo]);

  const [title, setTitle] = useState<string>("");
  const [riskOwner, setRiskOwner] = useState<string>("");
  const [responsibleDepartment, setResponsibleDepartment] = useState<string>("");
  const [furtherAffectedOrgUnit, setFurtherAffectedOrgUnit] = useState<string[]>([]);
  const [generalDescription, setGeneralDescription] = useState<string>("");
  const [privacyRelevant, setPrivacyRelevant] = useState<boolean | null>(null);
  const [dataLocationIds, setDataLocationIds] = useState<string[]>([]);
  const [protectionObjectiveIds, setProtectionObjectiveIds] = useState<string[]>([]);
  const [labelIds, setLabelIds] = useState<string[]>([]);
  useEffect(() => {
    setTitle(risk?.title || "");
    setRiskOwner(risk?.ownerUID || "");
    setResponsibleDepartment(risk?.orgUnitId || "");
    setFurtherAffectedOrgUnit(risk?.furtherAffectedOrgUnitIds || []);
    setGeneralDescription(risk?.description || "");
    setPrivacyRelevant(risk?.privacyRelevant === undefined ? null : risk.privacyRelevant);
    setDataLocationIds(risk?.dataLocationIds || "");
    setProtectionObjectiveIds(risk?.protectionObjectiveIds || []);
    setLabelIds(risk?.labelIds || []);
    setDocumentName(risk?.title || "");
  }, [
    risk?.dataLocationIds,
    risk?.description,
    risk?.furtherAffectedOrgUnitIds,
    risk?.labelIds,
    risk?.orgUnitId,
    risk?.ownerUID,
    risk.privacyRelevant,
    risk?.protectionObjectiveIds,
    risk?.title,
    setDocumentName
  ]);

  const { queueUpdates } = useUpdateQueues<Partial<RiskPayloadDTO>, Partial<RiskPayloadDTO>>({
    triggerUpdate: updateBasicInfoHook
  });
  /* TITLE */
  const onChangeTitle = useCallback(
    (title: string) => {
      setTitle(title);
      return queueUpdates({ title });
    },
    [queueUpdates]
  );
  /* OWNER ID */
  const onChangeRiskOwnerId = useCallback(
    (ids: string[]) => {
      setRiskOwner(ids[0] || "");
      return queueUpdates({ ownerUID: ids[0] || "" });
    },
    [queueUpdates]
  );
  /* ORG UNIT ID */
  const onChangeOrgUnitId = useCallback(
    (orgUnitId: string) => {
      setResponsibleDepartment(orgUnitId);
      return queueUpdates({ orgUnitId });
    },
    [queueUpdates]
  );
  /* FURTHER ORG UNIT ID */
  const onChangeFurtherAffectedOrgUnitIds = useCallback(
    (furtherAffectedOrgUnitIds: string[]) => {
      setFurtherAffectedOrgUnit(furtherAffectedOrgUnitIds);
      return queueUpdates({ furtherAffectedOrgUnitIds });
    },
    [queueUpdates]
  );
  /* DESCRIPTION */
  const onChangeDescription = useCallback(
    (description: string) => {
      setGeneralDescription(description);
      return queueUpdates({ description });
    },
    [queueUpdates]
  );
  /* PRIVACY RELEVANT */
  const onChangePrivacyRelevant = useCallback(
    event => {
      const privacyRelevant = event.target.value === "yes";
      setPrivacyRelevant(privacyRelevant);
      return queueUpdates({ privacyRelevant });
    },
    [queueUpdates]
  );
  /* DATA LOCATION IDS */
  const onChangeDataLocationIds = useCallback(
    (dataLocationIds: string[]) => {
      setDataLocationIds(dataLocationIds);
      return queueUpdates({ dataLocationIds });
    },
    [queueUpdates]
  );

  const onChangeProtectionObjectiveIds = useCallback(
    (protectionObjectiveIds: string[]) => {
      setProtectionObjectiveIds(protectionObjectiveIds);
      return queueUpdates({ protectionObjectiveIds });
    },
    [queueUpdates]
  );

  /* LABEL IDS */
  const onChangeLabelIds = useCallback(
    (labelIds: string[]) => {
      setLabelIds(labelIds);
      return queueUpdates({ labelIds });
    },
    [queueUpdates]
  );

  const orgUnitIds = useMemo(() => {
    return [risk?.orgUnitId, ...(risk?.furtherAffectedOrgUnitIds || [])].filter((it): it is string => !!it);
  }, [risk?.orgUnitId, risk?.furtherAffectedOrgUnitIds]);

  const readOnly = risk.permission !== "write";

  if (!initialized) {
    return (
      <Grid container justifyContent="center" spacing={3}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Box>
      <Box display="flex">
        <Box flex={1} mr={3}>
          <Question
            translatable={risk?.title || ""}
            questionId={"title"}
            questionName={t("titleField")}
            qType={QUESTION_TYPE.TEXT_AREA}
            value={title}
            onChange={onChangeTitle}
            onBlur={setEnteredInfo}
            info={infoCard.title}
            disabled={readOnly}
          />
        </Box>
        <Box flex={1} ml={3}>
          <Question
            qType={QUESTION_TYPE.TEXT_AREA}
            disabled={true}
            questionName={t("tenantRiskId")}
            value={risk ? tenantRiskId(risk as any) : ""}
          />
        </Box>
      </Box>
      <Question
        qType={QUESTION_TYPE.ASSIGNED_TO_USER}
        orgUnitIds={orgUnitIds}
        onlyIntersectingOrgUnitIds={true}
        questionId={"riskOwner"}
        questionName={t("riskOwner")}
        value={riskOwner}
        disableClearable={false}
        onChange={onChangeRiskOwnerId}
        onBlur={setEnteredInfo}
        info={infoCard.riskOwner}
        assignType={"riskOwner"}
        disabled={readOnly}
      />
      <Question
        qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
        questionId={"responsible_department"}
        questionName={t("responsible_department")}
        value={responsibleDepartment}
        onChange={onChangeOrgUnitId}
        onBlur={setEnteredInfo}
        info={infoCard.orgUnitId}
        disabled={readOnly}
      />
      <Question
        qType={QUESTION_TYPE.ORG_UNITS_PICKER}
        questionId={"furtherAffectedOrgUnit"}
        questionName={t("furtherAffectedOrgUnit")}
        value={furtherAffectedOrgUnit}
        onChange={onChangeFurtherAffectedOrgUnitIds}
        onBlur={setEnteredInfo}
        info={infoCard.furtherAffectedOrgUnitIds}
        disabled={readOnly}
      />
      <Question
        qType={QUESTION_TYPE.TEXT_EDITOR}
        translatable={risk?.description || ""}
        questionId={"generalDescription"}
        questionName={t("generalDescription")}
        value={generalDescription}
        onChange={onChangeDescription}
        onBlur={setEnteredInfo}
        info={infoCard.description}
        disabled={readOnly}
      />
      <Question questionId={"privacyRelevant"} questionName={t("privacyRelevant")} disabled={readOnly}>
        <TextBody2 text={t("privacyRelevant")} />
        <RadioGroup
          row
          name="privacyRelevant"
          value={privacyRelevant === null ? "" : privacyRelevant ? "yes" : "no"}
          onChange={onChangePrivacyRelevant}
        >
          <FormControlLabel
            label={t("common:yes")}
            value={"yes"}
            control={<Radio color="primary" />}
            disabled={readOnly}
          />
          <FormControlLabel
            label={t("common:no")}
            value={"no"}
            control={<Radio color="primary" />}
            disabled={readOnly}
          />
        </RadioGroup>
      </Question>
      <Question
        qType={QUESTION_TYPE.DATA_SOURCE}
        questionId={"dataLocationIds"}
        questionName={t("dataLocationIds")}
        value={dataLocationIds}
        onChange={onChangeDataLocationIds}
        onBlur={setEnteredInfo}
        info={infoCard.dataLocationIds}
        disabled={readOnly}
      />
      <Question
        qType={QUESTION_TYPE.PROTECTION_OBJECTIVE}
        questionId={"protectionObjectiveIds"}
        questionName={t("protectionObjectiveIds")}
        value={protectionObjectiveIds}
        onChange={onChangeProtectionObjectiveIds}
        onBlur={setEnteredInfo}
        info={infoCard.protectionObjectiveIds}
        orgUnitIds={orgUnitIds}
        onlyIntersectingOrgUnitIds={true}
        disabled={readOnly}
      />
      <Question
        qType={QUESTION_TYPE.LABELS}
        questionId={"labels"}
        questionName={t("labels")}
        value={labelIds}
        onChange={onChangeLabelIds}
        onBlur={setEnteredInfo}
        info={infoCard.labelIds}
        orgUnitIds={orgUnitIds}
        onlyIntersectingOrgUnitIds={true}
        disabled={readOnly}
      />
      <AttachmentsOverviewOBS docId={riskId || ""} category={"risks"} disabled={readOnly} />
    </Box>
  );
};
