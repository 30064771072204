import { Box, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Question from "components/Question/Question";
import { useCallback } from "react";
import { useUserProcesses } from "hook/useUserProcesses";
import { useDataSubjectRequest } from "app/contexts/dsr-context";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useExternalRecipients } from "app/contexts/external-recipient-context";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";

interface DSRExternalRecipientPickerProps {
  disabled?: boolean;
}

// external recipient picker component for data subject requests
const DSRExternalRecipientPicker = ({ disabled }: DSRExternalRecipientPickerProps) => {
  const { t } = useTranslation("data_subject_requests_data_page");
  const { dataSubjectRequest } = useDataSubjectRequest();
  const { externalRecipientsDict } = useExternalRecipients();

  // fetch processes based on selected person groups
  const { processes, processesLoaded } = useUserProcesses({
    personGroupIDs: dataSubjectRequest?.inputData?.personGroups || []
  });

  // get all unique external recipient ids from processes
  const externalRecipientIDs =
    processes?.reduce((acc: string[], process) => {
      const newRecipients = process.allExternalRecipientIds || [];
      return [...new Set([...acc, ...newRecipients])];
    }, []) || [];

  // get name for external recipient
  const getExternalRecipientName = useCallback(
    (id: string) => externalRecipientsDict[id]?.name || id,
    [externalRecipientsDict]
  );

  // render chips for external recipients
  const renderTags = useCallback(
    (value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
      value.map((option: string, index: number) => (
        <Chip deleteIcon={<> </>} label={getExternalRecipientName(option)} {...getTagProps({ index })} key={index} />
      )),
    [getExternalRecipientName]
  );

  return (
    <Box>
      <Question questionId={"dataRecipients:external"} questionName={t("dataRecipients:external")} disabled={disabled}>
        <MultiAutocomplete
          id="external_data_recipients"
          renderTags={renderTags}
          disabled={true}
          options={Object.keys(externalRecipientsDict)}
          selected={externalRecipientIDs}
          getOptionLabel={getExternalRecipientName}
          hasMultiSelect={true}
          label={t("dataRecipients:external")}
          icon={!processesLoaded ? undefined : undefined}
        />
      </Question>
    </Box>
  );
};

export default DSRExternalRecipientPicker;
