import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import DocView from "../../../components/DocView/DocView";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useMetaView } from "../../contexts/meta-view-context";
import { DSRPersonGroupField } from "./components/DSRPersonGroupField";
import { DSRReceivedOnField } from "./components/DSRReceivedOnField";
import { DSRRequestTypeField } from "./components/DSRRequestTypeField";
import { makeStyles } from "@material-ui/core/styles";
import GeneralPageButtons from "../../../components/Pagination/GeneralPageButtons";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import AttachmentsOverviewOBS from "../shared/Attachments/AttachmentsOverviewOBS";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { COLLECTIONS } from "../../collections";
import Button from "@material-ui/core/Button";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import TextEditor from "../questionnaires/utils/TextEditor";
import Question from "components/Question/Question";
import { createExternalDataSubjectRequestApi } from "../../api/dataSubjectRequestApi";
import { shouldShowEmailInputError } from "app/utils/emailFormatter";

export default function DataSubjectRequestsSubmission() {
  const [success, setSuccess] = useState(false);

  const onSuccessCreate = useCallback(() => {
    setSuccess(true);
  }, [setSuccess]);

  const onRecreateClicked = useCallback(() => {
    setSuccess(false);
  }, [setSuccess]);

  return (
    <DocMetaView
      docViewContent={
        success ? (
          <SuccessPage onRecreateClicked={onRecreateClicked} />
        ) : (
          <DataSubjectRequestsSubmissionContent onCreated={onSuccessCreate} />
        )
      }
      metaViewContent={<MetaView translationKey={"data_subject_requests_overview_general_page"} />}
    />
  );
}

const useStyles = makeStyles(theme => ({
  container: {},
  loadingContainer: {
    margin: "0 0 0 8px",
    position: "relative",
    top: 3
  },
  attachmentSection: {
    margin: "24px 0 0 0"
  },
  buttonContainer: {
    margin: "82px 0 0 0"
  },
  successAlert: {
    maxWidth: 400
  }
}));

export function DataSubjectRequestsSubmissionContent({ onCreated }) {
  const classes = useStyles();
  const { t } = useTranslation("data_subject_requests_basic_page");
  const { setInfo, setMeta } = useMetaView();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [requestTypes, setRequestTypes] = useState([]);
  const [personGroupKeys, setPersonGroupKeys] = useState([]);
  const [receivedOn, setReceivedOn] = useState(new Date());
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isCreatingDSR, setIsCreatingDSR] = useState(false);
  const [createdDSRId, setCreatedDSRId] = useState(null);
  const [pendingFilesCount, setPendingFilesCount] = useState(0);
  const [focusedElement, setFocusedElement] = useState("");

  const maximumReceivedOn = useMemo(() => {
    return new Date();
  }, []);

  const resetInfo = useCallback(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("enteringInfoCardText")
    });
  }, [t, setInfo]);

  useEffect(() => {
    resetInfo();
  }, [resetInfo]);

  const onTitleFocus = useCallback(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("request_title_info_card_text")
    });
    setFocusedElement("");
  }, [t, setInfo]);

  const onTitleChange = useCallback(
    event => {
      setTitle(event.target.value);
    },
    [setTitle]
  );

  const onFirstNameChange = useCallback(
    event => {
      setFirstName(event.target.value);
    },
    [setFirstName]
  );
  const onLastNameChange = useCallback(
    event => {
      setLastName(event.target.value);
    },
    [setLastName]
  );
  const onEmailChange = useCallback(
    event => {
      setEmail(event.target.value);
    },
    [setEmail]
  );
  const onDescriptionFocus = useCallback(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("request_description_info_card_text")
    });
    setFocusedElement("description");
  }, [t, setInfo]);

  const onRequestTypeFocus = useCallback(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("request_type_info_card_text")
    });
    setFocusedElement("");
  }, [t, setInfo]);

  const onReceivedOnFocus = useCallback(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("request_received_on_info_card_text")
    });
    setFocusedElement("");
  }, [t, setInfo]);

  const onCreate = useCallback(async () => {
    setIsCreatingDSR(true);

    let dsrId;
    try {
      dsrId = await createExternalDataSubjectRequestApi(title, {
        description,
        receivedOn,
        type: requestTypes,
        personGroups: personGroupKeys,
        firstName,
        lastName,
        email
      });
      setCreatedDSRId(dsrId);
    } catch (error) {
      catchAsSnackbar("Failed to create data subject request")(error);
      throw error;
    } finally {
      setIsCreatingDSR(false);
    }
  }, [
    setIsCreatingDSR,
    title,
    requestTypes,
    firstName,
    lastName,
    email,
    description,
    receivedOn,
    personGroupKeys,
    catchAsSnackbar
  ]);

  const isCreatingDSRAndUploadingFiles = useMemo(() => {
    const stillUploadingPendingFiles = createdDSRId && pendingFilesCount !== 0;
    return isCreatingDSR || stillUploadingPendingFiles;
  }, [createdDSRId, pendingFilesCount, isCreatingDSR]);

  useEffect(() => {
    if (createdDSRId && !isCreatingDSRAndUploadingFiles) {
      onCreated();
    }
  }, [createdDSRId, isCreatingDSRAndUploadingFiles]);

  const primaryButton = useMemo(() => {
    return {
      onClick: onCreate,
      disabled: !title || isCreatingDSRAndUploadingFiles,
      label: (
        <span>
          {t("common:submit")}
          {isCreatingDSRAndUploadingFiles && (
            <span className={classes.loadingContainer}>
              <CircularProgress size={16} />
            </span>
          )}
        </span>
      )
    };
  }, [onCreate, t, title, isCreatingDSRAndUploadingFiles]);

  const onPendingFiles = useCallback(
    pendingFiles => {
      setPendingFilesCount(pendingFiles.length);
    },
    [setPendingFilesCount]
  );

  const defaultSpacing = 2;

  return (
    <DocView header={t("title")}>
      <div className={classes.container}>
        <Question>
          <TextField
            id={"request_title"}
            required
            fullWidth={true}
            label={t("request_title")}
            variant="outlined"
            onFocus={onTitleFocus}
            value={title}
            onChange={onTitleChange}
            onBlur={resetInfo}
          />
        </Question>
        <Box display="flex" width="100%">
          <Box mr={3} flex={1}>
            <Question questionName={t("fields:first_name")}>
              <TextField
                id={"first_name"}
                fullWidth={true}
                label={t("fields:first_name")}
                variant="outlined"
                value={firstName}
                onChange={onFirstNameChange}
                onBlur={resetInfo}
              />
            </Question>
          </Box>
          <Box ml={3} flex={1}>
            <Question questionName={t("fields:last_name")}>
              <TextField
                id={"last_name"}
                fullWidth={true}
                label={t("fields:last_name")}
                variant="outlined"
                value={lastName}
                onChange={onLastNameChange}
                onBlur={resetInfo}
              />
            </Question>
          </Box>
        </Box>
        <Question questionName={t("fields:email")}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            value={email}
            onChange={onEmailChange}
            name="email"
            id="email"
            onBlur={resetInfo}
            error={shouldShowEmailInputError(email)}
            helperText={shouldShowEmailInputError(email) && t("manage-user-page:emailBadFormat")}
            label={t("fields:email")}
            type="email"
            variant="outlined"
          />
        </Question>
        <Question translatable={description} translationId={"description"} title={t("description")}>
          <TextEditor
            inputValue={description}
            onFocus={onDescriptionFocus}
            onBlur={resetInfo}
            onChange={setDescription}
            testId={"description"}
          />
        </Question>
        <Grid container alignItems="center" justifyContent="space-between" spacing={defaultSpacing}>
          <Grid item xs={6}>
            <Question>
              <DSRRequestTypeField
                values={requestTypes}
                onChanges={setRequestTypes}
                onFocus={onRequestTypeFocus}
                onBlur={resetInfo}
              />
            </Question>
          </Grid>
          <Grid item xs={6}>
            <DSRPersonGroupField values={personGroupKeys} onChanges={setPersonGroupKeys} onBlur={resetInfo} />
          </Grid>
          <Grid item xs={6}>
            <Question>
              <DSRReceivedOnField
                onFocus={onReceivedOnFocus}
                createdDate={maximumReceivedOn}
                value={receivedOn}
                onChange={setReceivedOn}
                onBlur={resetInfo}
                disabled={false}
              />
            </Question>
          </Grid>
        </Grid>
        <Box mt={3} />
        <QuestionnaireSubHeader text={t("common:attachments")} />
        <AttachmentsOverviewOBS
          docId={createdDSRId}
          category={COLLECTIONS.DATA_SUBJECT_REQUESTS.toLowerCase()}
          setMeta={setMeta}
          onPendingFiles={onPendingFiles}
        />
        <GeneralPageButtons primaryButton={primaryButton} />
      </div>
    </DocView>
  );
}

const SuccessPage = ({ onRecreateClicked }) => {
  const classes = useStyles();
  const { t } = useTranslation("data_subject_requests_basic_page");
  const { setInfo } = useMetaView();

  useEffect(() => {
    setInfo(null);
  }, [setInfo]);

  return (
    <DocView header={`${t("common:thankyou")}.`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.successAlert}>
            <CustomAlert severity="success" icon={<CheckCircleOutline />}>
              {t("success_title")}
            </CustomAlert>
          </div>
        </Grid>
        <Grid item xs={12}>
          <p>{t("success_description")}</p>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={onRecreateClicked} variant="contained" color="primary">
            {t("success_button")}
          </Button>
        </Grid>
      </Grid>
    </DocView>
  );
};
