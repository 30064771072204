import { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar, Box, Tooltip, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { COLLECTIONS } from "app/collections";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import AssignToNoBody from "../../../../assets/images/tasks/assign-to-no-body.svg";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import AssignNewIcon from "assets/images/icons/assignedTo.svg";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useSnackbar } from "notistack";
import { changeTaskAssignee } from "app/handlers/tasksHandler";
import stopEvent from "tool/stopEvent";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "5px",
    height: "34px",
    borderRadius: "12px"
  },
  hoverGrayBg: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100]
    }
  },
  containerFullName: {
    display: "flex",
    alignItems: "center",
    height: "34px",
    paddingLeft: "8px",
    paddingRight: "8px",
    marginLeft: -theme.spacing(1)
  },
  root: {
    height: "24px",
    width: "24px",
    textTransform: "uppercase",
    fontSize: "10px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  addUserDashed: {
    border: "1px dashed",
    fontWeight: 600,
    fontSize: "10px",
    letterSpacing: "0.5px",
    borderRadius: "12px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[400]
  }
}));

const TaskOverviewBrickAssignUser = ({ item, onBlur }: OverviewRowBrickProps) => {
  const { id, readOnly, assignUserId, userNameDisplay, strictlyAvailableMentionUserIds } = item;

  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { getUserNameEmailHook, getUserNameHook } = useUserAndTenantData();
  const { auth } = useAuthentication();
  const { tenantId, uid } = auth || { tenantId: "", uid: "" };
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useOverviewDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const getCurrentPage = () => {
    if (window.location.pathname.indexOf("other") > -1) return "other";
    else return "my";
  };

  const reload = useCallback(
    (taskId?: string | null) => {
      const reload = {
        selectedId: taskId,
        shadowLoading: true,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now()
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.TASKS, reload });
    },
    [dispatch]
  );

  useEffect(() => {
    setSelectedId(assignUserId);
  }, [assignUserId]);

  const openMenuCallback = useCallback(
    event => {
      stopEvent(event);
      onBlur?.();
      if (readOnly) {
        return;
      }
      setOpen(true);
    },
    [onBlur, readOnly]
  );

  const closeMenuCallback = useCallback(() => {
    setOpen(false);
  }, []);

  const onChangeUserIdCallback = useCallback(async () => {
    closeMenuCallback();

    const endsWith = window.location.pathname.endsWith(id || "");
    await changeTaskAssignee(tenantId, uid, id || "", selectedId).catch(error => {
      enqueueSnackbar(error.message, { variant: "error" });
    });

    if (selectedId !== auth?.uid) {
      reload(endsWith ? null : undefined);
      if (endsWith) {
        window.history.pushState("", "", `/tasks/${getCurrentPage()}`);
      }
    }
  }, [auth?.uid, closeMenuCallback, enqueueSnackbar, id, reload, selectedId, tenantId, uid]);

  const onChangeCallback = useCallback((ids: string[]) => {
    setSelectedId(ids[0] || "");
  }, []);

  const onChangeUserCallback = useCallback((id: string | null) => {
    setSelectedId(id || "");
  }, []);

  const assignUserTaskModalBody = useMemo(() => {
    if (strictlyAvailableMentionUserIds && strictlyAvailableMentionUserIds.length > 0) {
      return (
        <MultiAutocomplete
          id={"assignedToUser"}
          icon={<AssignNewIcon />}
          selected={selectedId}
          updateSelected={onChangeUserCallback}
          options={strictlyAvailableMentionUserIds}
          getOptionLabel={getUserNameEmailHook}
          hasMultiSelect={false}
        />
      );
    } else {
      return <Question qType={QUESTION_TYPE.ASSIGNED_TO_USER} value={selectedId} onChange={onChangeCallback} />;
    }
  }, [getUserNameEmailHook, onChangeCallback, onChangeUserCallback, selectedId, strictlyAvailableMentionUserIds]);

  const assignTaskModalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: closeMenuCallback
      },
      {
        confirmButton: true,
        disabled: false,
        title: t("assign"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: onChangeUserIdCallback
      }
    ],
    [closeMenuCallback, onChangeUserIdCallback, t]
  );

  const iconEl = useMemo(() => {
    if (assignUserId) {
      return (
        <Tooltip title={t("changeAssignee")} disableHoverListener={readOnly}>
          <Avatar className={cls.root} onClick={openMenuCallback}>
            {getUserNameHook(assignUserId)?.substring(0, 2).toUpperCase()}
          </Avatar>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t("changeAssignee")}>
          <Box onClick={openMenuCallback}>
            <AssignToNoBody />
          </Box>
        </Tooltip>
      );
    }
  }, [cls.root, getUserNameHook, openMenuCallback, readOnly, t, assignUserId]);

  return (
    <Box onClick={stopEvent}>
      {item.assignToType == "USER" && (
        <Box
          display="flex"
          onClick={openMenuCallback}
          className={[
            cls.container,
            ...(userNameDisplay && assignUserId ? [cls.containerFullName] : []),
            ...(readOnly ? [] : [cls.hoverGrayBg])
          ].join(" ")}
          style={!readOnly ? { cursor: "pointer" } : {}}
        >
          {assignUserId && iconEl}
          {assignUserId && userNameDisplay && (
            <Box ml={1} display="flex" alignItems="center">
              {userNameDisplay}
            </Box>
          )}
          {!assignUserId && (
            <Box display="flex" alignItems="center" className={cls.addUserDashed}>
              {`+ ${t("add_user").toUpperCase()}`}
            </Box>
          )}
        </Box>
      )}
      <ConfirmationModal
        modalOpen={open}
        onClose={closeMenuCallback}
        modalTitle={t("assign_task")}
        modalBody={assignUserTaskModalBody}
        buttons={assignTaskModalButtons}
        variant="info"
        modalText={""}
      />
    </Box>
  );
};

export default TaskOverviewBrickAssignUser;
