import { Chip, Tooltip } from "@mui/material";
import { useMissingTranslations } from "app/api/multilingualApi";
import { useTLng } from "components/Multilingual/MultilingualModal";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useTranslation } from "react-i18next";
import colors from "theme/palette/colors";

const sx = {
  textTransform: "uppercase",
  backgroundColor: "#F17A79",
  color: colors.white,
  height: "24px",
  fontSize: "11px"
};

const PersonGroupOverviewBrickUntranslatedCount = ({ item }: OverviewRowBrickProps) => {
  const { id } = item;
  const { t } = useTranslation();
  const { untranslatedLngs } = useMissingTranslations(id);
  const tLng = useTLng();

  if (untranslatedLngs.length === 0) {
    return <></>;
  }

  return (
    <Tooltip
      title={t("multilingual:untranslatedLanguages", {
        languages: untranslatedLngs.map(lng => tLng(lng)).join(", ")
      })}
    >
      <Chip sx={sx} label={t("multilingual:nTranslationsMissing", { count: untranslatedLngs.length })} />
    </Tooltip>
  );
};

export default PersonGroupOverviewBrickUntranslatedCount;
