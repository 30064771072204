import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocView from "components/DocView/DocView";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import cubejs from "@cubejs-client/core";
import { ChartRenderer } from "./Chartrenderer";
import OrgunitsPathsAutocomplete from "../../../components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import { useAuthentication } from "../../handlers/authentication/authentication-context";
import { ActivitiesChartRenderer } from "./ActivitiesChartRenderer";
import MultiAutocomplete from "../../../components/MultiAutocomplete/MultiAutocomplete";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { DSRChart } from "./DSRChart";
import { STATS_TYPE } from "../../handlers/dashboardHandler";
import { DataBreachChart } from "./DataBreachChart";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import { FEATURES } from "../../features";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import { ExpiringSCCNotice } from "../../../components/ExpiringSCCNotice";
import { ResourceField } from "../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../handlers/resourceHandler";
import { useUserDepartments } from "../../contexts/department-context";
import { postCubeJsToken } from "../../api/cubeJsHelperApi";

const useStyles = makeStyles(theme => ({
  autocompleteOrg: {
    width: 240
  },
  autocompleteLabel: {
    width: 240
  },
  autocompleteTime: {
    width: 240
  },
  resetFilters: {}
}));

export default function Dashboard() {
  const { t } = useTranslation("dashboardPage");
  const [timePeriodOptions, setTimePeriodOptions] = useState([]);
  const [timePeriod, setTimePeriod] = useState(null);
  const isDataBreachAvailable = useIsFeaturePresent(FEATURES.DATA_BREACHES);
  const isDataSubjectRequestAvailable = useIsFeaturePresent(FEATURES.DATA_SUBJECT_REQUEST);
  const isShowRecentActivities = useIsFeaturePresent(FEATURES.DASHBOARD_RECENT_ACTIVITIES);
  const { rootDepartment, departmentsLoaded } = useUserDepartments();

  const defaultTimePeriod = useMemo(() => ({ id: STATS_TYPE.ALL_TIME, name: t("allTime") }), [t]);

  const [orgUnitId, setOrgUnitId] = useState("");
  const [label, setLabel] = useState(null);
  const classes = useStyles();
  const [cubeJSApi, setCubeJSApi] = useState(null);
  const { auth } = useAuthentication();

  const selectDefaultOrgUnit = useCallback(() => {
    if (!departmentsLoaded) {
      return;
    }

    const orgUnitIdOfUser = auth?.isUserDepartmentBound ? auth?.orgUnitId : rootDepartment?.id;
    if (orgUnitIdOfUser) {
      setOrgUnitId(orgUnitIdOfUser);
    }
  }, [auth?.isUserDepartmentBound, auth?.orgUnitId, departmentsLoaded, rootDepartment?.id]);

  const [isFiltersEmpty, setIsFiltersEmpty] = useState(false);
  useEffect(() => {
    setIsFiltersEmpty(orgUnitId === auth?.orgUnitId && !label && !timePeriod);
  }, [orgUnitId, label, timePeriod, auth?.orgUnitId]);

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!auth?.orgUnitId || !auth?.tenantId) {
      return;
    }

    setTimePeriodOptions([
      defaultTimePeriod,
      { id: STATS_TYPE.TODAY, name: t("today") },
      { id: STATS_TYPE.THIS_WEEK, name: t("thisWeek") },
      { id: STATS_TYPE.THIS_MONTH, name: t("thisMonth") },
      { id: STATS_TYPE.THIS_YEAR, name: t("thisYear") }
    ]);
    setTimePeriod(defaultTimePeriod);
    selectDefaultOrgUnit();
    setInitialized(true);
  }, [t, defaultTimePeriod, selectDefaultOrgUnit, auth?.orgUnitId, auth?.tenantId]);

  const { catchAsSnackbar } = useErrorSnackbar();
  useEffect(() => {
    // determine whether connection should be
    const connectToLocalCubeJs = false;

    const initCubeJsApi = async () => {
      const result = await postCubeJsToken();
      if (!result?.token) {
        return;
      }

      let apiUrl = result.url; // url for connection to dev/stage/prod instance of CubeJs
      if (window && window.location && window.location.hostname === "localhost") {
        apiUrl = result.testUrl; // url for connection to test/qa instance of CubeJs
      }
      if (connectToLocalCubeJs) {
        apiUrl = "http://localhost:4000/cubejs-api/v1"; // url for connection to local CubeJs
      }

      setCubeJSApi(
        cubejs(result.token, {
          apiUrl
        })
      );
    };

    initCubeJsApi().catch(catchAsSnackbar("Failed to init cubejs"));
  }, [catchAsSnackbar]);

  const resetAllFilters = useCallback(() => {
    selectDefaultOrgUnit();
    setTimePeriod(defaultTimePeriod);
    setLabel(null);
  }, [selectDefaultOrgUnit, defaultTimePeriod]);

  if (!initialized) {
    return <></>;
  }

  return (
    <DocMetaView>
      <DocView
        header={t("page_title")}
        icons={
          <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              {orgUnitId && (
                <div className={classes.autocompleteOrg}>
                  <OrgunitsPathsAutocomplete value={orgUnitId} onChange={setOrgUnitId} label={t("orgunit")} />
                </div>
              )}
            </Grid>
            <Grid item>
              <div className={classes.autocompleteLabel}>
                <ResourceField
                  id="labels"
                  onChange={setLabel}
                  label={t("labels")}
                  value={label}
                  resourceType={RESOURCE_TYPES.LABEL}
                  multiSelect={false}
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.autocompleteTime}>
                <MultiAutocomplete
                  label={t("timeperiod")}
                  selected={timePeriod ?? ""}
                  updateSelected={setTimePeriod}
                  id="timeperiod"
                  options={timePeriodOptions}
                  getOptionLabel={option => option.name}
                  hasMultiSelect={false}
                />
              </div>
            </Grid>
            <Grid item>
              <Button onClick={resetAllFilters} disabled={isFiltersEmpty}>
                {t("reset")}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ExpiringSCCNotice expiringSCCKey={"scc"} warningText={t("expiringSCC:scc_dashboard")} />
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer timePeriod={timePeriod} orgUnitId={orgUnitId} label={label} />
          </Grid>
          <Grid item xs={12}>
            {isDataBreachAvailable === null && (
              <Box display="flex" justifyContent="center" alignItems="center" height={470}>
                <CircularProgress />
              </Box>
            )}
            {isDataBreachAvailable !== null && (
              <>
                <Grid container spacing={2}>
                  {isDataBreachAvailable && (
                    <Grid item xs={6}>
                      <DataBreachChart timePeriod={timePeriod} orgUnitId={orgUnitId || ""} labelId={label || ""} />
                    </Grid>
                  )}
                  {isDataSubjectRequestAvailable && (
                    <Grid item xs={isDataBreachAvailable ? 6 : 12}>
                      <DSRChart timePeriod={timePeriod} orgUnitId={orgUnitId || ""} labelId={label} />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          {isShowRecentActivities && (
            <Grid item xs={12}>
              <ActivitiesChartRenderer cubejsApi={cubeJSApi} />
            </Grid>
          )}
        </Grid>
      </DocView>
    </DocMetaView>
  );
}
