import { useExternalRecipients } from "../../contexts/external-recipient-context";
import React, { useCallback, useEffect, useState } from "react";
import { DataLocationOverviewItem, getDataLocationsOverviewApi } from "../../api/externalRecipientApi";
import { CircularProgress } from "@material-ui/core";
import MultiAutocomplete from "../../../components/MultiAutocomplete/MultiAutocomplete";
import { useTranslation } from "react-i18next";
import { withAbortController } from "../../api/axios/axiosErrorHandler";

export const DataLocationsPicker = ({
  onChange,
  selectedIDs,
  onFocus,
  label,
  disabled
}: {
  readonly onChange?: (dlIDs: string[]) => void;
  readonly selectedIDs: string[];
  readonly onFocus?: () => void;
  readonly label?: string;
  readonly disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { externalRecipientsDict, initialized } = useExternalRecipients();
  const [loading, setLoading] = useState(true);
  const [dataLocationIDs, setDataLocationIDs] = useState<string[]>([]);
  const [dataLocations, setDataLocations] = useState<Record<string, DataLocationOverviewItem>>({});

  useEffect(() => {
    return withAbortController({
      executeFn: async abortController => {
        const dls = await getDataLocationsOverviewApi({ abortController });
        const dlIds = dls.items.map(dl => dl.id);
        const dlsRecord = dls.items.reduce<Record<string, DataLocationOverviewItem>>(
          (acc, dl) => ({ ...acc, [dl.id]: dl }),
          {}
        );
        setDataLocations(dlsRecord);
        setDataLocationIDs(dlIds);
        setLoading(false);
      }
    });
  }, []);

  const getOptionName = useCallback(
    (dlId: string) => {
      const dataLocation = dataLocations[dlId];
      if (!dataLocation) {
        return dlId;
      }
      const erName =
        (dataLocation.externalRecipientId && externalRecipientsDict[dataLocation.externalRecipientId]?.name) ||
        dataLocation.externalRecipientId;
      const erLabel = erName ? `[${erName}]` : "";
      const dataLocationName = t(`lists_data_locations:${dataLocation.title}`, dataLocation.title);
      return erLabel ? `${dataLocationName} ${erLabel}` : dataLocationName;
    },
    [dataLocations, externalRecipientsDict, t]
  );

  if (loading || !initialized) {
    return <CircularProgress />;
  }

  return (
    <MultiAutocomplete<string, true, false, false>
      options={dataLocationIDs}
      selected={selectedIDs}
      onChange={onChange}
      getOptionLabel={getOptionName}
      hasMultiSelect={true}
      onFocus={onFocus}
      label={label}
      disabled={disabled}
    />
  );
};
