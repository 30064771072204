import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import OverviewBrick from "../OverviewBrick";

const OverviewBrickUpdatedAt = ({ item }: OverviewRowBrickProps) => {
  if (!item.updatedAt) {
    return <></>;
  }

  return <OverviewBrick.Date date={item.updatedAt} />;
};

export default OverviewBrickUpdatedAt;
