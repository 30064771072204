import { Tooltip } from "@mui/material";
import colors from "theme/palette/colors";
import InterviewIcon from "@mui/icons-material/AssignmentOutlined";
import SelfAssessmentIcon from "@mui/icons-material/AssignmentIndOutlined";
import { useTranslation } from "react-i18next";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

const AssessmentOverviewBrickMethod = ({ item }: OverviewRowBrickProps) => {
  const { t } = useTranslation();
  if (item.methodology === "interview") {
    return (
      <Tooltip title={t("audit_methodologies:interview")}>
        <InterviewIcon style={{ color: colors.grey.grey500 }} name="Interview" />
      </Tooltip>
    );
  } else if (item.methodology === "self-assessment") {
    return (
      <Tooltip title={t("audit_methodologies:self-assessment")}>
        <SelfAssessmentIcon style={{ color: colors.grey.grey500 }} name="Self-assessment" />
      </Tooltip>
    );
  }
  return <></>;
};

export default AssessmentOverviewBrickMethod;
