import React from "react";
import { Box, useTheme } from "@mui/material";
import { DateField } from "components/DateField";
import { toJSDateObjectIfFirebaseDate } from "app/handlers/utility/date-helper";
import { useTranslation } from "react-i18next";

interface DSRReceivedOnFieldProps {
  onFocus?: () => void;
  onBlur?: () => void;
  createdDate: Date | null;
  value: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
}

export const DSRReceivedOnField: React.FC<DSRReceivedOnFieldProps> = ({
  onFocus,
  onBlur,
  createdDate,
  value,
  onChange,
  disabled
}) => {
  const { t } = useTranslation("data_subject_requests_overview_general_page");
  const theme = useTheme();

  return (
    <Box
      sx={{
        "& .MuiButtonBase-root": {
          color: theme.palette.primary.main,
          position: "relative",
          ml: theme.spacing(2)
        },
        "& .MuiFormControl-root": {
          m: 0
        }
      }}
    >
      <DateField
        maxDate={toJSDateObjectIfFirebaseDate(createdDate)}
        date={toJSDateObjectIfFirebaseDate(value)}
        onDateChange={onChange}
        label={t("received_on")}
        onFocus={onFocus}
        onBlur={onBlur}
        withTime={true}
        disabled={disabled}
      />
    </Box>
  );
};
