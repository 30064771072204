import { IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";
import ClassIcon from "@material-ui/icons/Class";
import { t } from "i18next";

const OverviewBrickClassify = ({ item, hovered, disabled, onClick, onBlur }: OverviewRowBrickProps) => {
  const disableActions = item.disableActions;
  const onClickCallback = useCallback(
    event => {
      onBlur?.();
      stopEvent(event);
      onClick?.(item.id, item);
    },
    [item, onBlur, onClick]
  );

  if (!hovered || disableActions?.some(({ action }) => action === "classify")) {
    return <></>;
  }
  return (
    <Tooltip title={t("resources_lists_data_types_categories_person_groups:classifyToolTipText")}>
      <IconButton onClick={onClickCallback} disabled={disabled} size="small">
        <ClassIcon />
      </IconButton>
    </Tooltip>
  );
};

export default OverviewBrickClassify;
