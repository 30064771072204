import { Box, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useTranslation } from "react-i18next";
import colors from "theme/palette/colors";

const sx = {
  root: {
    width: "36px",
    height: "36px",
    textAlign: "center",
    lineHeight: "36px",
    marginTop: "8px",
    marginBottom: "8px",
    borderRadius: "50%",
    color: colors.grey.grey700,
    backgroundColor: colors.grey.grey200
  },
  nonZero: {
    color: colors.blue.blue500,
    backgroundColor: colors.blue.blue50
  },
  nonZeroHovered: {
    color: colors.blue.blue600,
    backgroundColor: colors.blue.blue200
  }
};

const TomOverviewBrickProcessesNumber = ({ item, hovered }: OverviewRowBrickProps) => {
  const processesNumber = item.processesNumber || 0;
  const { t } = useTranslation("tom_description_tab");

  return (
    <Tooltip title={t("tom_measures_overview:tooltip_number_connected_PAs")}>
      <Box sx={{ ...sx.root, ...(processesNumber ? (hovered ? sx.nonZeroHovered : sx.nonZero) : {}) }}>
        {processesNumber}
      </Box>
    </Tooltip>
  );
};

export default TomOverviewBrickProcessesNumber;
