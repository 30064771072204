import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import { deleteOrgUnitDocumentCheckApi } from "app/api/file-storage/documentApi";
import { createAndDownloadFile } from "../export/createAndDownloadFile";
import { deleteOrgUnitApi, getOrgUnitApi, getOrgUnitsApi, patchOrgUnitApi, postOrgUnitApi } from "../api/orgUnitApi";
import { writeXLSX } from "./xlsxToJsonhandler";

export async function addOrganisationalUnit(input: { readonly data: Pick<Department, "name" | "parentId"> }) {
  const createdOrgUnitId = await postOrgUnitApi({
    name: input.data.name,
    parentId: input.data.parentId || ""
  });
  return getOrgUnitById(createdOrgUnitId);
}

export async function editNameOfOrganisationalUnit(input: { readonly name: string; readonly docId: string }) {
  await patchOrgUnitApi(input.docId, {
    name: input.name
  });
}

/**
 * Before run deletion process for org unit
 * we have to check dependencies to not broke other object
 * if current method will return any COLLECTION_TYPES
 * that means we stop deletion process because found dependencies for COLLECTION
 * if null is returned - that means deletion process done
 */
export const deleteOrganisationalUnits = async (input: {
  readonly docIds: string[];
}): Promise<COLLECTION_TYPES | null> => {
  const { docIds } = input;

  // check file-storage dependencies
  const fileStorageCheck: COLLECTION_TYPES | null = await deleteOrgUnitDocumentCheckApi(docIds);
  if (fileStorageCheck) {
    return COLLECTIONS.FILE_STORAGE;
  }
  // check other dependencies
  for (const docId of docIds) {
    await deleteOrgUnitApi(docId);
  }

  return null;
};

export const getOrgUnits = async (): Promise<Department[]> => {
  const orgUnits = await getOrgUnitsApi();
  return orgUnits.map(it => ({
    id: it.id,
    name: it.name,
    parentId: it.parentId,
    deleted: false,
    fullName: it.fullName,
    type: it.type
  }));
};

export const exportOrgUnits = async (filename?: string): Promise<void> => {
  const orgUnits = await getOrgUnits();
  const orgUnitsWithFullpath = orgUnits.sort((a, b) => a.fullName.localeCompare(b.fullName));
  const data = orgUnitsWithFullpath.map(it => ({
    ID: it.id,
    Name: it.name,
    "Full Name": it.fullName,
    "Parent ID": it.parentId
  }));

  const buffer = await writeXLSX({
    sheets: [
      {
        sheetName: "Org Unit",
        data: data,
        columnOrder: ["Name", "Full Name", "ID", "Parent ID"] satisfies (keyof (typeof data)[0])[]
      }
    ]
  });

  await createAndDownloadFile(
    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
    filename || "org-units.xlsx"
  );
};

export const getOrgUnitById = async (orgUnitId: string): Promise<Department | null> => {
  const orgUnit = await getOrgUnitApi(orgUnitId);
  if (!orgUnit) {
    return null;
  }
  return {
    id: orgUnit.id,
    name: orgUnit.name,
    parentId: orgUnit.parentId,
    deleted: false,
    fullName: orgUnit.fullName,
    type: orgUnit.type
  };
};

export interface Department {
  readonly id: string;
  readonly name: string;
  readonly parentId: string | null;
  readonly deleted: boolean | null;
  readonly fullName: string;
  readonly type: "internal" | "external";
}
