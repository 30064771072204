import React, { useCallback, useEffect, useMemo, useState } from "react";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useParams } from "react-router-dom";
import { useMetaView } from "app/contexts/meta-view-context";
import {
  getProcessPageData,
  getProcessRisksOptions,
  patchProcessMeasuresPageData,
  useProcessGeneralPage
} from "app/api/paApi";
import { useProcessPage } from "app/contexts/process-page-context";
import Question from "components/Question/Question";
import { Box, CircularProgress, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import AttachmentsOverviewOBS from "app/pages/shared/Attachments/AttachmentsOverviewOBS";
import { COLLECTIONS } from "app/collections";
import { RiskIdsAccordion } from "../risks-page/RiskIdsAccordion";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { isEqual } from "lodash-es";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import { DefaultApi } from "../../../api/generated/process-service";
import { apiEndpoints } from "../../../api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../../api/axios/loggedInAxiosProvider";
import useSWR from "swr";
import { TOMOptionDTO } from "../../../api/riskApi";
import { MeasuresQuestionAdditionalData } from "../../../../components/Question/types/resources/MeasuresQuestion";

interface ProcessMeasureModel {
  readonly id: string;
  readonly measureDescription: string;
  readonly measureName: string;
  readonly tomId: string;
  readonly protectionObjective: string[];
  readonly isCreatedNew?: boolean;
}

export interface ProcessMeasuresPageModel {
  readonly dataFlowRequired: boolean;
  readonly measuresRequired: boolean;
  readonly measures: ProcessMeasureModel[];
  readonly riskIds: string[];
}

export interface ProcessMeasuresPageUpdateModel {
  readonly dataFlowRequired: boolean;
  readonly measuresRequired: boolean;
  readonly measures: { readonly id: string; readonly tomId: string }[];
  readonly riskIds: string[];
}

const DataFlow = ({
  processId,
  dataFlowRequired,
  disabled,
  onChange
}: {
  readonly processId: string;
  readonly dataFlowRequired: boolean;
  readonly disabled: boolean;
  readonly onChange: (value: { readonly dataFlowRequired: boolean }) => void;
}) => {
  const isAllowed = useIsFeaturePresent(FEATURES.DATAFLOW_UPLOAD);
  const { t } = useTranslation("pa_data_flow_measures_risks");
  const onChangeDataFlowRequired = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ dataFlowRequired: e.target.value.toLowerCase() === "true" });
    },
    [onChange]
  );

  if (!isAllowed) {
    return <></>;
  }

  return (
    <>
      <QuestionnaireSubHeader text={t("dataFlowHeader")} />
      <Question
        questionId={"dataFlowUploadRequired"}
        title={t("dataFlowQuestion")}
        questionName={t("dataFlowQuestion")}
        disabled={disabled}
      >
        <RadioGroup
          aria-label="dataFlow"
          row
          name="dataFlow"
          value={dataFlowRequired}
          onChange={onChangeDataFlowRequired}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label={t("common:yes")}
            disabled={disabled}
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label={t("common:no")}
            disabled={disabled}
          />
        </RadioGroup>
        {dataFlowRequired && (
          <AttachmentsOverviewOBS docId={processId} category={COLLECTIONS.PROCESSES} disabled={disabled} />
        )}
      </Question>
    </>
  );
};

const Measures = ({
  processId,
  measuresRequired,
  measureIds,
  measures,
  disabled,
  onChange
}: {
  readonly processId: string;
  readonly measuresRequired: boolean;
  readonly measureIds: string[];
  readonly measures: TOMOptionDTO[];
  readonly disabled: boolean;
  readonly onChange: (val: { readonly measuresRequired?: boolean; readonly measureIds?: string[] }) => void;
}) => {
  const { t } = useTranslation("pa_data_flow_measures_risks");
  const { setInfo } = useMetaView();

  const onChangeMeasuresRequired = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const required = e.target.value.toLowerCase() === "true";
      onChange({ measuresRequired: required });

      if (required) {
        setInfo({
          title: t("pa_data_flow_measures_risks:information"),
          text: t("pa_data_flow_measures_risks:measureRequiredInfo")
        });
      } else {
        setInfo({
          title: t("pa_data_flow_measures_risks:information"),
          text: t("pa_data_flow_measures_risks:noMeasureRequiredInfo")
        });
      }
    },
    [onChange, setInfo, t]
  );

  const onMeasureIdsChanged = useCallback(
    (measureIds: string[]) => {
      onChange({ measureIds });
    },
    [onChange]
  );

  const { data } = useProcessGeneralPage({ paId: processId || "" });
  const nonStandardAdditionalData = useMemo(() => {
    return {
      mainOrgUnitIdOnCopy: data?.processPage?.responsibleOrgUnitId || undefined
    } satisfies MeasuresQuestionAdditionalData;
  }, [data?.processPage?.responsibleOrgUnitId]);

  return (
    <>
      <QuestionnaireSubHeader text={t("measuresHeader")} />
      <Question
        questionId={"measuresRequired"}
        title={t("measuresQuestion")}
        questionName={t("measuresQuestion")}
        disabled={disabled}
      >
        <RadioGroup
          aria-label="dataFlow"
          row
          name="dataFlow"
          value={measuresRequired}
          onChange={onChangeMeasuresRequired}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label={t("common:yes")}
            disabled={disabled}
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label={t("common:no")}
            disabled={disabled}
          />
        </RadioGroup>
      </Question>
      {measuresRequired && (
        <Question
          qType={QUESTION_TYPE.MEASURE}
          questionId={"measureIds"}
          questionName={t("measureIds")}
          value={measureIds}
          onChange={onMeasureIdsChanged}
          disabled={disabled}
          options={measures}
          allowAdd={true}
          nonStandardAdditionalData={nonStandardAdditionalData}
        />
      )}
    </>
  );
};

const Risks = ({
  riskIds,
  disabled,
  onChange
}: {
  readonly riskIds?: string[];
  readonly disabled: boolean;
  readonly onChange: (val: { readonly riskIds?: string[] }) => void;
}) => {
  const { t } = useTranslation("dpia_four_four_page");
  const { id } = useParams();
  const { auth } = useAuthentication();
  const { data: risksData, isLoading: risksDataLoading } = useSWR(id ? [id, "options", "risks"] : null, () =>
    getProcessRisksOptions({ processId: id || "" })
  );
  const riskOptions = useMemo(() => risksData || [], [risksData]);
  const featureToggleEnabled = useIsFeaturePresent(FEATURES.RISKS_ON_PA_SUBMISSION);
  const isRiskReadOnly = auth?.permissions.includes("pa_read_risk_org");
  const isRiskAllowWrite = auth?.permissions.includes("pa_write_risk_org");
  const onChangeCallback = useCallback((riskIds: string[]) => onChange({ riskIds }), [onChange]);
  const { data: paGeneral } = useProcessGeneralPage({ paId: id || "" });

  if (!riskIds || !featureToggleEnabled || !isRiskReadOnly || !id) {
    return <></>;
  }
  return (
    <Box mt={3}>
      <QuestionnaireSubHeader text={t("title")} />
      {risksDataLoading ? (
        <CircularProgress size={24} />
      ) : (
        <RiskIdsAccordion
          title={t("title")}
          riskOptions={riskOptions}
          onRiskIdsChange={onChangeCallback}
          riskIds={riskIds || []}
          documentId={id}
          disabled={disabled || !isRiskAllowWrite}
          type={"processing-activity"}
          newRiskFurtherOrgUnitIds={emptyFurtherOrgUnitIds}
          newRiskPrivacyRelevant={true}
          newRiskOrgUnitId={paGeneral?.processPage?.responsibleOrgUnitId || undefined}
        />
      )}
    </Box>
  );
};

const emptyFurtherOrgUnitIds: string[] = [];

const ProcessMeasuresPage = (props: { readonly readonly?: boolean }) => {
  const { t } = useTranslation("dpia_four_four_page");
  const { auth } = useAuthentication();
  const { id } = useParams();
  const { setInfo } = useMetaView();
  const { onBeforeProcessUpdate, setProcessMeta } = useProcessPage();
  const [processPage, setProcessPage] = useState<ProcessMeasuresPageModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const isRisksOnPaSubmissionEnabled = useIsFeaturePresent(FEATURES.RISKS_ON_PA_SUBMISSION);
  const isDataFlowUploadOnPASubmissionEnabled = useIsFeaturePresent(FEATURES.DATAFLOW_UPLOAD);
  useEnteringInfoCard({
    pathName: `/processes/${id}/measures`,
    infoId: "infocard.pa.page5"
  });

  const fetch = useCallback(async () => {
    const { processPage, processMeta } = await getProcessPageData<ProcessMeasuresPageModel>({
      processId: id || "",
      page: "measures"
    });
    setProcessMeta(processMeta);
    setProcessPage(processPage || null);
    setLoading(false);
  }, [id, setProcessMeta]);

  useEffect(() => {
    let textTranslationKey = "infoCardEnterBody";
    if (!isRisksOnPaSubmissionEnabled) {
      textTranslationKey += "NoRisks";
      if (!isDataFlowUploadOnPASubmissionEnabled) {
        textTranslationKey += "NoUpload";
      }
    }
    const text = t(textTranslationKey);
    if (id) {
      fetch();
      setInfo({
        title: t("infoCardEnterTitle"),
        text
      });
    }
  }, [auth, isRisksOnPaSubmissionEnabled, isDataFlowUploadOnPASubmissionEnabled, fetch, id, setInfo, t]);

  const patch = useCallback(
    async (data: Partial<ProcessMeasuresPageModel>) => {
      await onBeforeProcessUpdate(async () => {
        setProcessPage(model =>
          model
            ? {
                ...model,
                dataFlowRequired: data.dataFlowRequired !== undefined ? data.dataFlowRequired : model.dataFlowRequired,
                measuresRequired: data.measuresRequired !== undefined ? data.measuresRequired : model.measuresRequired,
                measures: data.measures !== undefined ? data.measures : model.measures
              }
            : null
        );

        // check equality
        const notEqual = Object.keys(data).some(
          (key: string) =>
            !isEqual(data[key as keyof ProcessMeasuresPageModel], processPage?.[key as keyof ProcessMeasuresPageModel])
        );
        if (processPage && notEqual) {
          if (data.measures !== undefined) {
            const payload = { ...data, measures: data.measures?.map(({ id, tomId }) => ({ id, tomId })) };
            await patchProcessMeasuresPageData({ processId: id || "", payload });
          } else {
            await patchProcessMeasuresPageData({ processId: id || "", payload: data });
          }

          if (data.measuresRequired) {
            fetch();
          }
        }
      });
    },
    [fetch, id, onBeforeProcessUpdate, processPage]
  );

  const { data: measureOptionsData, mutate: measureOptionsMutate } = useSWR(
    id ? [id, "options", "measures"] : null,
    () => processClient.getMeasureOptions(id || "")
  );

  const patchMeasures = useCallback(
    (payload: { readonly measuresRequired?: boolean; readonly measureIds?: string[] }) => {
      patch({
        measuresRequired: payload.measuresRequired,
        measures: payload.measureIds?.map(id => ({ id, tomId: id }) as ProcessMeasureModel) || []
      }).then(() => measureOptionsMutate());
    },
    [patch, measureOptionsMutate]
  );

  const measureOptions = useMemo<TOMOptionDTO[]>(
    () =>
      (measureOptionsData?.data?.toms || []).map(
        it =>
          ({
            id: it.id || "",
            name: it.name || "",
            description: it.description || "",
            protectionObjectiveIds: it.protectionObjectiveIds || [],
            processSpecific: !!it.processSpecific,
            labelIds: it.labelIds || []
          }) satisfies TOMOptionDTO
      ),
    [measureOptionsData]
  );

  if (loading) {
    return (
      <Box textAlign={"center"} mt={8}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <DataFlow
        processId={id || ""}
        dataFlowRequired={processPage?.dataFlowRequired || false}
        onChange={patch}
        disabled={props.readonly === true}
      />

      <Measures
        processId={id || ""}
        measureIds={processPage?.measures.map(it => it.tomId) || []}
        measuresRequired={processPage?.measuresRequired || false}
        measures={measureOptions}
        onChange={patchMeasures}
        disabled={props.readonly === true}
      />
      <Risks onChange={patch} riskIds={processPage?.riskIds} disabled={props.readonly === true} />
    </>
  );
};
const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

export default ProcessMeasuresPage;
