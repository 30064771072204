import useSWR from "swr";
import { DefaultApi } from "../../api/generated/process-service";
import { apiEndpoints } from "../../api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../api/axios/loggedInAxiosProvider";
import { isAxiosErrorWithCode } from "../../api/axios/axiosErrorHandler";
import { getLastOverviewType } from "../processes/overview/paOverviewTypeStorage";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());
export const usePAPermission = (processId: string) => {
  const { data, isLoading, isValidating, mutate } = useSWR(
    processId ? ["permissions", processId] : null,
    async ([, processId]) => {
      try {
        const shouldForcePAIfOpenedFromPAOverview = (await getLastOverviewType()) === "pa";
        const response = await processClient.getPAPermissions(processId, shouldForcePAIfOpenedFromPAOverview);
        return response.data;
      } catch (e: unknown) {
        if (isAxiosErrorWithCode(e, 404)) {
          return null;
        }
        throw e;
      }
    }
  );
  return {
    paPermission: data,
    isLoading,
    isValidating,
    mutate
  };
};

export const isPAPermissionsKey = (key: unknown) => Array.isArray(key) && key[0] === "permissions";
