import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import AssignGroupsMultiAutocomplete from "app/pages/questionnaires/utils/AssignGroupsMultiAutocomplete";
import AssignToGroupIcon from "../../../../assets/images/tasks/assign-to-group.svg";
import { changeTaskGroups } from "app/handlers/tasksHandler";
import { useSnackbar } from "notistack";
import stopEvent from "tool/stopEvent";
import { COLLECTIONS } from "app/collections";
import { useOverviewDispatch, OVERVIEW_ACTIONS } from "app/contexts/overview-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

const useStyles = makeStyles(theme => ({
  groupIconContainer: {
    height: "24px",
    width: "24px",
    textTransform: "uppercase",
    cursor: "pointer",
    fontSize: "10px",
    color: theme.palette.common.white
  },
  addGroupDashed: {
    border: "1px dashed",
    fontWeight: 600,
    fontSize: "10px",
    letterSpacing: "0.5px",
    borderRadius: "12px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[400]
  }
}));

const TaskOverviewBrickAssignGroups = ({ item, onBlur }: OverviewRowBrickProps) => {
  const { id, groupIds } = item;
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = useAuthentication();
  const { tenantId } = auth || { tenantId: "" };
  const dispatch = useOverviewDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const reload = useCallback(
    (taskId?: string | null) => {
      const reload = {
        selectedId: taskId,
        shadowLoading: true,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now()
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.TASKS, reload });
    },
    [dispatch]
  );

  useEffect(() => {
    setSelectedIds(groupIds || []);
  }, [groupIds]);

  const openMenuCallback = useCallback(() => {
    onBlur?.();
    setOpen(true);
  }, [onBlur]);

  const closeMenuCallback = useCallback(() => {
    setOpen(false);
  }, []);

  const onChangeGroupIdsCallback = useCallback(async () => {
    closeMenuCallback();
    await changeTaskGroups(id || "", selectedIds, tenantId).catch(error => {
      enqueueSnackbar(error.message, { variant: "error" });
    });
    reload();
  }, [closeMenuCallback, enqueueSnackbar, id, reload, selectedIds, tenantId]);

  const onChangeCallback = useCallback(async (ids: string[]): Promise<void> => {
    setSelectedIds(ids);
  }, []);

  const assignTaskModalBody = (
    <AssignGroupsMultiAutocomplete
      docAssignedGroupIds={selectedIds}
      onDocAssignedGroupIdsChanged={onChangeCallback}
      freeSolo={false}
      disableClearable={true}
      label={t("groups_overview:title")}
      hasMultiSelect={true}
    />
  );

  const assignTaskModalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: closeMenuCallback
      },
      {
        confirmButton: true,
        disabled: false,
        title: t("save"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: onChangeGroupIdsCallback
      }
    ],
    [closeMenuCallback, onChangeGroupIdsCallback, t]
  );

  return (
    <Box onClick={stopEvent}>
      {item.assignToType === "GROUP" && (
        <Box className={cls.groupIconContainer} onClick={openMenuCallback}>
          <AssignToGroupIcon />
        </Box>
      )}
      <ConfirmationModal
        modalOpen={open}
        onClose={closeMenuCallback}
        modalTitle={t("add_group")}
        modalBody={assignTaskModalBody}
        buttons={assignTaskModalButtons}
        variant="info"
        modalText={t("add_group_modal_text")}
      />
    </Box>
  );
};

export default TaskOverviewBrickAssignGroups;
