import { useParams } from "react-router-dom";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import { useAiPrecheck } from "../../../api/process/processingAiPrecheckApi";
import React, { useCallback, useEffect, useState } from "react";
import AiRiskAssessment, { AiRiskLevel } from "./AiRiskAssessment";
import { useTranslation } from "react-i18next";
import Question from "../../../../components/Question/Question";
import { Box, Typography } from "@mui/material";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import { useProcessPage } from "../../../contexts/process-page-context";
import ConfirmationDialog from "./ConfirmationDialog";
import { SetAiRiskLevelRequest } from "../../../api/generated/process-service";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";

export const AIActPreCheck = () => {
  const { id: paId } = useParams();
  const { t } = useTranslation("pa_assessment");
  const { onBeforeProcessUpdate, resetSignal } = useProcessPage();
  const isAiRiskLevelFeatureEnabled = useIsFeaturePresent(FEATURES.AI_RISK_LEVEL);

  const {
    aiCheckRequired,
    actions: { queueAIPreCheckUpdate },
    aiRiskLevel,
    aiRiskLevelExplanation,
    isAiRiskLevelExplanationOverriddenByUser,
    isAiRiskLevelOverriddenByUser
  } = useAiPrecheck({ documentId: paId || "" });

  const [pageAiRiskLevel, setPageAiRiskLevel] = useState<AiRiskLevel | null>(aiRiskLevel || null);
  const [pageAiRiskLevelExplanation, setPageAiRiskLevelExplanation] = useState<string>(aiRiskLevelExplanation || "");
  useEffect(() => {
    setPageAiRiskLevel(aiRiskLevel || null);
    setPageAiRiskLevelExplanation(aiRiskLevelExplanation || "");
  }, [aiRiskLevel, aiRiskLevelExplanation]);

  const [confirmBeforeOverridingData, setConfirmBeforeOverridingData] = useState<SetAiRiskLevelRequest | null>(null);
  const onOverrideDataConfirmation = useCallback(() => {
    return onBeforeProcessUpdate(
      () => {
        if (confirmBeforeOverridingData?.aiRiskLevel !== undefined) {
          setPageAiRiskLevel(confirmBeforeOverridingData.aiRiskLevel);
        }
        if (confirmBeforeOverridingData?.aiRiskLevelExplanation !== undefined) {
          setPageAiRiskLevelExplanation(confirmBeforeOverridingData.aiRiskLevelExplanation || "");
        }
        setConfirmBeforeOverridingData(null);
        return queueAIPreCheckUpdate(confirmBeforeOverridingData || {});
      },
      () => {
        setConfirmBeforeOverridingData(null);
      }
    );
  }, [onBeforeProcessUpdate, queueAIPreCheckUpdate, confirmBeforeOverridingData]);
  const [overrideDataKey, setOverrideDataKey] = useState(0);
  const onOverrideDataCancel = useCallback(() => {
    setConfirmBeforeOverridingData(null);
    setOverrideDataKey(it => it + 1);
  }, []);
  const triggerExplanationOverrideIfNeverEdited = useCallback(() => {
    if (isAiRiskLevelExplanationOverriddenByUser) {
      return;
    }

    setConfirmBeforeOverridingData({
      aiRiskLevelExplanation: pageAiRiskLevelExplanation
    });
  }, [isAiRiskLevelExplanationOverriddenByUser, pageAiRiskLevelExplanation]);

  const updateAiRiskLevel = useCallback(
    (riskLevel: AiRiskLevel) => {
      if (!isAiRiskLevelOverriddenByUser && aiRiskLevel !== null) {
        setConfirmBeforeOverridingData({ aiRiskLevel: riskLevel });
        return;
      }

      return onBeforeProcessUpdate(() => {
        setPageAiRiskLevel(riskLevel);
        return queueAIPreCheckUpdate({ aiRiskLevel: riskLevel });
      });
    },
    [aiRiskLevel, isAiRiskLevelOverriddenByUser, onBeforeProcessUpdate, queueAIPreCheckUpdate]
  );

  const updateAiRiskLevelExplanation = useCallback(
    (value: string) => {
      if (!isAiRiskLevelExplanationOverriddenByUser && aiRiskLevelExplanation !== null) {
        setConfirmBeforeOverridingData({ aiRiskLevelExplanation: value });
        return;
      }

      return onBeforeProcessUpdate(() => {
        setPageAiRiskLevelExplanation(value);
        return queueAIPreCheckUpdate({ aiRiskLevelExplanation: value });
      });
    },
    [aiRiskLevelExplanation, isAiRiskLevelExplanationOverriddenByUser, onBeforeProcessUpdate, queueAIPreCheckUpdate]
  );

  if (!isAiRiskLevelFeatureEnabled || !aiCheckRequired) {
    return <></>;
  }

  return (
    <>
      <QuestionnaireSubHeader text={t("ai_risk_assessment_header")} />
      <Box mt={2}>
        <AiRiskAssessment aiRiskLevel={pageAiRiskLevel} onChange={updateAiRiskLevel} />
        {isAiRiskLevelOverriddenByUser && (
          <Box mt={-1}>
            <Typography color="textSecondary">{t("edited")}</Typography>
          </Box>
        )}
      </Box>
      {pageAiRiskLevel || !!pageAiRiskLevelExplanation ? (
        <Box mt={3} mb={5}>
          <Typography variant="subtitle2">{t("ai_act_reason")}</Typography>
          <Box mt={-1}>
            <Question
              key={`aiRiskLevelExplanation-${resetSignal}-${overrideDataKey}`}
              translatable={pageAiRiskLevelExplanation}
              qType={QUESTION_TYPE.EDITABLE_TEXT}
              questionId={"aiRiskLevelExplanation"}
              value={pageAiRiskLevelExplanation}
              onChange={updateAiRiskLevelExplanation}
              onActivate={triggerExplanationOverrideIfNeverEdited}
            />
            {isAiRiskLevelExplanationOverriddenByUser && (
              <Box mt={-1}>
                <Typography color="textSecondary">{t("edited")}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <ConfirmationDialog
        open={Boolean(confirmBeforeOverridingData)}
        onCancel={onOverrideDataCancel}
        onConfirm={onOverrideDataConfirmation}
        title={t("pa_assessment:confirmation_required")}
        text={t("pa_assessment:confirmation_text")}
      />
    </>
  );
};
