import { useAuthentication } from "app/handlers/authentication/authentication-context";
import React from "react";
import useSWR from "swr";
import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { DataAsset, DataAssetApi, DataAssetType, DataCategory, DataType, PersonGroup } from "./generated/asset-service";
import i18n from "app/i18n";
import { useTranslation } from "react-i18next";
import { createAndDownloadFile } from "../export/createAndDownloadFile";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { readXLSX, writeXLSX } from "../handlers/xlsxToJsonhandler";
import { orderBy } from "lodash-es";
import { v4 } from "uuid";
import { insertLocalImportData } from "../pages/importer/localImporterData";
import { isAxiosErrorWithCode } from "./axios/axiosErrorHandler";

export const dataAssetClient = new DataAssetApi(undefined, apiEndpoints.assetUrl, defaultOTCAuthenticatedAxios());

export const flattenPersonGroupsData = (
  data: PersonGroup[] = []
): {
  readonly personGroups: Record<string, PersonGroup>;
  readonly dataCategories: Record<string, DataCategory>;
  readonly dataTypes: Record<string, DataType>;
} => {
  const personGroups: Record<string, PersonGroup> = {};
  const dataCategories: Record<string, DataCategory> = {};
  const dataTypes: Record<string, DataType> = {};

  // loop only once, since this can cause CPU issues on FE
  for (const personGroup of data) {
    personGroups[personGroup.id as string] = personGroup;
    for (const dataCategory of personGroup.dataCategories) {
      dataCategories[dataCategory.id as string] = dataCategory;
      for (const dataType of dataCategory.dataTypes) {
        dataTypes[dataType.id as string] = dataType;
      }
    }
  }

  return {
    personGroups,
    dataCategories,
    dataTypes
  };
};

export type DataTypeTreeData = PersonGroup[];

const toPersonGroupData = (
  personGroups: PersonGroup[],
  indexed = true
): {
  readonly personGroups: PersonGroup[];
  readonly dataTypeByIDs: {
    readonly personGroups: Record<string, PersonGroup>;
    readonly dataCategories: Record<string, DataCategory>;
    readonly dataTypes: Record<string, DataType>;
  };
} => {
  if (!indexed) {
    return {
      personGroups,
      dataTypeByIDs: {
        personGroups: {},
        dataCategories: {},
        dataTypes: {}
      }
    };
  }

  const flattenedData = flattenPersonGroupsData(personGroups);
  return {
    personGroups,
    dataTypeByIDs: {
      personGroups: flattenedData.personGroups,
      dataCategories: flattenedData.dataCategories,
      dataTypes: flattenedData.dataTypes
    }
  };
};

export const useDataTypeTree = (indexed = true) => {
  const { i18n } = useTranslation();
  const isMultilingualEnabled = !!useIsFeaturePresent(FEATURES.MULTILINGUAL);
  const unseenCount = useSWR(["person-groups-unseen-count", indexed], async () => {
    if (!indexed) {
      return 0;
    }
    const response = await dataAssetClient.getUnseenDataAssetsCount();
    return response.data.count;
  });
  const personGroups = useSWR<ReturnType<typeof toPersonGroupData>>(
    ["person-groups", indexed, i18n.language],
    async () => {
      unseenCount.mutate();
      const lng = i18n.language || "en-US";
      const response = await dataAssetClient.getPersonGroups(undefined, {
        timeout: 60000,
        headers: {
          "Accept-Language": isMultilingualEnabled ? lng : undefined
        }
      });
      const personGroups = response.data.items || [];
      // deprioritize the calculation of the flattened data
      await new Promise(resolve => setTimeout(resolve, 0));
      return toPersonGroupData(personGroups, indexed);
    }
  );

  return {
    ...personGroups,
    data: personGroups.data?.personGroups satisfies DataTypeTreeData | undefined,
    dataById: personGroups.data?.dataTypeByIDs,
    unseenCount: unseenCount.data || 0
  };
};

export const useDataTypeTreeManager = (indexed = false) => {
  const { t, i18n } = useTranslation("personGroup");
  const { auth } = useAuthentication();
  const dataTypeTreeData = useDataTypeTree(indexed);
  const isMultilingualEnabled = !!useIsFeaturePresent(FEATURES.MULTILINGUAL);
  const isAllowedMergeByRename = React.useMemo(() => {
    return auth?.permissions?.includes("super_admin") || false;
  }, [auth]);

  const { mutate, data = [], unseenCount } = dataTypeTreeData;

  // ADD
  const addPersonGroup = React.useCallback(
    async (
      name: string,
      {
        populateLocalCache,
        detectConflict
      }: {
        populateLocalCache?: boolean;
        detectConflict?: boolean;
      } = {}
    ) => {
      let createdId = "";
      await mutate(
        async data => {
          const createResponse = await dataAssetClient.createDataAsset(
            {
              name,
              assetType: DataAssetType.PersonGroup
            },
            undefined,
            detectConflict ? "true" : undefined,
            isMultilingualEnabled ? "true" : undefined
          );
          createdId = createResponse.headers["x-resource-id"];

          if (!populateLocalCache) {
            return data;
          }

          const optimisticPersonGroup: PersonGroup = {
            id: createdId,
            personGroupKey: name,
            dataCategories: []
          };

          const currentData = data as ReturnType<typeof toPersonGroupData> | undefined;
          return toPersonGroupData([optimisticPersonGroup, ...(currentData?.personGroups || [])]);
        },
        {
          populateCache: Boolean(populateLocalCache)
        }
      );
      return createdId;
    },
    [mutate, isMultilingualEnabled]
  );
  const addDataCategory = React.useCallback(
    async ({
      dataCategory,
      personGroupId,
      populateLocalCache,
      detectConflict
    }: {
      personGroupId: string;
      dataCategory: string;
      populateLocalCache?: boolean;
      detectConflict?: boolean;
    }) => {
      let createdId = "";
      await mutate(
        async data => {
          const createResponse = await dataAssetClient.createDataAsset(
            {
              assetType: DataAssetType.DataCategory,
              name: dataCategory,
              parentDataAssetId: personGroupId
            },
            undefined,
            detectConflict ? "true" : undefined,
            isMultilingualEnabled ? "true" : undefined
          );
          createdId = createResponse.headers["x-resource-id"];

          if (!populateLocalCache) {
            return data;
          }

          const optimisticDataCategory: DataCategory = {
            id: createdId,
            dataCategoryKey: dataCategory,
            dataTypes: []
          };

          const currentData = data as ReturnType<typeof toPersonGroupData> | undefined;
          return toPersonGroupData(
            (currentData?.personGroups || []).map(personGroup =>
              personGroup.id === personGroupId
                ? { ...personGroup, dataCategories: [optimisticDataCategory, ...personGroup.dataCategories] }
                : personGroup
            )
          );
        },
        {
          populateCache: Boolean(populateLocalCache)
        }
      );
      return createdId;
    },
    [mutate, isMultilingualEnabled]
  );
  const addDataType = React.useCallback(
    async ({
      personGroupId,
      dataCategoryId,
      dataType,
      unseen,
      populateLocalCache,
      detectConflict
    }: {
      personGroupId: string;
      dataCategoryId: string;
      dataType: string;
      unseen?: boolean;
      populateLocalCache?: boolean;
      detectConflict?: boolean;
    }) => {
      let createdId = "";
      await mutate(
        async data => {
          const createResponse = await dataAssetClient.createDataAsset(
            {
              assetType: DataAssetType.DataType,
              name: dataType,
              parentDataAssetId: dataCategoryId,
              unseen
            },
            undefined,
            detectConflict ? "true" : undefined,
            isMultilingualEnabled ? "true" : undefined
          );
          createdId = createResponse.headers["x-resource-id"];

          if (!populateLocalCache) {
            return data;
          }

          const optimisticDataType: DataType = {
            id: createdId,
            dataTypeKey: dataType,
            unseen
          };
          const currentData = data as ReturnType<typeof toPersonGroupData> | undefined;

          return toPersonGroupData(
            (currentData?.personGroups || []).map(personGroup =>
              personGroup.id === personGroupId
                ? {
                    ...personGroup,
                    dataCategories: personGroup.dataCategories.map(dataCategory =>
                      dataCategory.id === dataCategoryId
                        ? { ...dataCategory, dataTypes: [optimisticDataType, ...dataCategory.dataTypes] }
                        : dataCategory
                    )
                  }
                : personGroup
            )
          );
        },
        {
          populateCache: Boolean(populateLocalCache)
        }
      );
      return createdId;
    },
    [mutate, isMultilingualEnabled]
  );

  // DELETE
  const deletePersonGroup = React.useCallback(
    async ({ personGroupId }: { personGroupId: string }) => {
      await mutate(
        dataAssetClient.deleteDataAsset(personGroupId, "true").then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );
  const deleteDataCategory = React.useCallback(
    async ({ dataCategoryId }: { dataCategoryId: string }) => {
      await mutate(
        dataAssetClient.deleteDataAsset(dataCategoryId, "true").then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );
  const deleteDataType = React.useCallback(
    async ({ dataTypeId }: { dataTypeId: string }) => {
      await mutate(
        dataAssetClient.deleteDataAsset(dataTypeId, "true").then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );

  // MOVE
  const moveDataType = React.useCallback(
    async ({
      personGroupId,
      formerDataCategoryId,
      dataTypeId,
      newDataCategoryId
    }: {
      personGroupId: string;
      formerDataCategoryId: string;
      dataTypeId: string;
      newDataCategoryId: string;
    }) => {
      await mutate(
        dataAssetClient
          .updateDataAsset(dataTypeId, { parentDataAssetId: newDataCategoryId }, "true")
          .then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );
  const moveDataCategory = React.useCallback(
    async ({
      formerPersonGroupId,
      dataCategoryId,
      newPersonGroupId
    }: {
      formerPersonGroupId: string;
      dataCategoryId: string;
      newPersonGroupId: string;
    }) => {
      return await mutate(
        dataAssetClient
          .updateDataAsset(dataCategoryId, { parentDataAssetId: newPersonGroupId }, "true")
          .then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );

  // UPDATE
  const updatePersonGroup = React.useCallback(
    async ({ personGroupId, updates }: { personGroupId: string; updates: Pick<DataAsset, "name"> }) => {
      // if name is updated to the same name in this tenant, merge this person group into the other one
      // move all data categories to the other person group and remove this person group
      const existingPersonGroup = data.find(pg => pg.personGroupKey === updates.name);
      const currentPersonGroupIdx = data.findIndex(pg => pg.id === personGroupId);
      const currentPersonGroup = data[currentPersonGroupIdx];
      if (existingPersonGroup && currentPersonGroup && existingPersonGroup.id !== currentPersonGroup.id) {
        // only super admin can merge person groups this way
        if (!isAllowedMergeByRename) return;
        for (const dataCategory of currentPersonGroup.dataCategories) {
          await dataAssetClient.updateDataAsset(
            dataCategory.id as string,
            {
              parentDataAssetId: existingPersonGroup.id
            },
            "true"
          );
        }
        await dataAssetClient.deleteDataAsset(personGroupId);
        return mutate();
      }

      return mutate(
        dataAssetClient.updateDataAsset(personGroupId, updates).then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate, data, isAllowedMergeByRename]
  );
  const updateDataCategory = React.useCallback(
    async ({
      personGroupId,
      dataCategoryId,
      updates
    }: {
      personGroupId: string;
      dataCategoryId: string;
      updates: Pick<DataAsset, "name">;
    }) => {
      // if name is updated to the same name in the same group, merge this data category into the other one
      // move all data types to the other data category and remove this data category
      const pg = data.find(pg => pg.id === personGroupId);
      const existingCategory = pg?.dataCategories.find(dc => dc.dataCategoryKey === updates.name);
      const currentCategory = pg?.dataCategories.find(dc => dc.id === dataCategoryId);
      if (existingCategory && currentCategory && existingCategory.id !== currentCategory.id) {
        // only super admin can merge person groups this way
        if (!isAllowedMergeByRename) return;
        for (const dataType of currentCategory.dataTypes) {
          await dataAssetClient.updateDataAsset(dataType.id, { parentDataAssetId: existingCategory.id }, "true");
        }
        await dataAssetClient.deleteDataAsset(dataCategoryId);
        return await mutate();
      }

      const shouldIncludeParentDataAssetId = pg?.id !== personGroupId;
      await mutate(
        dataAssetClient
          .updateDataAsset(
            dataCategoryId,
            {
              ...updates,
              ...(shouldIncludeParentDataAssetId ? { parentDataAssetId: personGroupId } : {})
            },
            "true"
          )
          .then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate, data, isAllowedMergeByRename]
  );
  const updateDataType = React.useCallback(
    async ({
      personGroupId,
      dataCategoryId,
      dataTypeId,
      updates
    }: {
      personGroupId: string;
      dataCategoryId: string;
      dataTypeId: string;
      updates: Partial<DataAsset>;
    }) => {
      // if name is updated to the same name in the same group, merge this data type into the other one
      // invoke mergeDataTypes
      const pg = data.find(pg => pg.id === personGroupId);
      const dataCategory = pg?.dataCategories.find(dc => dc.id === dataCategoryId);
      const existingDataType = dataCategory?.dataTypes.find(dt => dt.dataTypeKey === updates.name);
      const currentDataType = dataCategory?.dataTypes.find(dt => dt.id === dataTypeId);
      if (existingDataType && currentDataType && existingDataType.id !== currentDataType.id) {
        // only super admin can merge person groups this way
        if (!isAllowedMergeByRename) return;
        await dataAssetClient.updateDataAsset(dataTypeId, {
          parentDataAssetId: dataCategoryId,
          mergedIntoId: existingDataType.id
        });
        return await mutate();
      }

      const shouldIncludeParentDataAssetId = dataCategoryId !== updates.parentDataAssetId;

      await mutate(
        dataAssetClient
          .updateDataAsset(
            dataTypeId,
            {
              ...updates,
              ...(shouldIncludeParentDataAssetId ? { parentDataAssetId: dataCategoryId } : {})
            },
            "true"
          )
          .then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate, data, isAllowedMergeByRename]
  );

  // MERGE
  const mergeDataTypes = React.useCallback(
    async ({
      personGroupId,
      dataCategoryId,
      dataTypes,
      newName
    }: {
      personGroupId: string;
      dataCategoryId: string;
      dataTypes: DataType[];
      newName: string;
    }) => {
      const pg = data.find(pg => pg.id === personGroupId);
      const dc = pg?.dataCategories.find(dc => dc.id === dataCategoryId);
      const existingDataTypeWithNewName = dc?.dataTypes.find(dt => dt.dataTypeKey === newName);
      const dataTypeToBeMergedInto = existingDataTypeWithNewName || dataTypes[0];
      const theRestItems = dataTypes.filter(dt => dt.id !== dataTypeToBeMergedInto.id);
      const mergeDataTypes = async () => {
        // only rename if there is no existing data type with the new name
        if (!existingDataTypeWithNewName) {
          await dataAssetClient.updateDataAsset(dataTypeToBeMergedInto.id, {
            name: newName,
            parentDataAssetId: dataCategoryId
          });
        }

        for (const item of theRestItems) {
          await dataAssetClient.updateDataAsset(
            item.id,
            {
              parentDataAssetId: dataCategoryId,
              mergedIntoId: dataTypeToBeMergedInto.id
            },
            "true"
          );
        }
      };
      await mutate(
        mergeDataTypes().then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
      return dataTypeToBeMergedInto.id;
    },
    [mutate, data]
  );
  const mergeDataCategories = React.useCallback(
    async ({
      personGroupId,
      dataCategories,
      newName
    }: {
      personGroupId: string;
      dataCategories: DataCategory[];
      newName: string;
    }) => {
      const pg = data.find(pg => pg.id === personGroupId);
      const existingDataCategoryWithNewName = pg?.dataCategories.find(dc => dc.dataCategoryKey === newName);
      const dataCategoriesToBeMergedInto = existingDataCategoryWithNewName || dataCategories[0];
      const theRestItems = dataCategories.filter(dt => dt.id !== dataCategoriesToBeMergedInto.id);
      const tDataType = (key: string) => t(`lists_data_types_categories_person_groups:${key}`, key);

      const mergeDataCategories = async () => {
        // only rename if there is no existing data category with the new name
        if (!existingDataCategoryWithNewName && dataCategoriesToBeMergedInto.id) {
          await dataAssetClient.updateDataAsset(dataCategoriesToBeMergedInto.id, {
            name: newName,
            parentDataAssetId: personGroupId
          });
        }

        const dataTypesByNameInNewParent = dataCategoriesToBeMergedInto.dataTypes.reduce(
          (acc, dt) => {
            acc[tDataType(dt.dataTypeKey)] = dt;
            return acc;
          },
          {} as Record<string, DataType>
        );

        for (const item of theRestItems) {
          // move all child of data types to new data category
          for (const dataType of item.dataTypes) {
            const sameNameDataType = dataTypesByNameInNewParent[tDataType(dataType.dataTypeKey)];
            await dataAssetClient.updateDataAsset(
              dataType.id,
              {
                parentDataAssetId: dataCategoriesToBeMergedInto.id,
                mergedIntoId: sameNameDataType ? sameNameDataType.id : undefined
              },
              "true"
            );
          }

          // after that, delete childless data category
          if (item.id) {
            await dataAssetClient.deleteDataAsset(item.id);
          }
        }
      };
      await mutate(
        mergeDataCategories().then(() => toPersonGroupData([])),
        {
          populateCache: false
        }
      );
      return dataCategoriesToBeMergedInto.id;
    },
    [data, mutate, t]
  );
  const mergePersonGroups = React.useCallback(
    async ({ personGroups, newName }: { personGroups: PersonGroup[]; newName: string }) => {
      const existingPersonGroupWithNewName = data.find(pg => pg.personGroupKey === newName);
      const personGroupsToBeMergedInto = existingPersonGroupWithNewName || personGroups[0];
      const theRestItems = personGroups.filter(dt => dt.id !== personGroupsToBeMergedInto.id);
      const tDataType = (key: string) => t(`lists_data_types_categories_person_groups:${key}`, key);

      const mergePersonGroups = async () => {
        // only rename if there is no existing data category with the new name
        if (!existingPersonGroupWithNewName && personGroupsToBeMergedInto.id) {
          await dataAssetClient.updateDataAsset(personGroupsToBeMergedInto.id, {
            name: newName
          });
        }
        const dataCategoriesByNameInNewParent = personGroupsToBeMergedInto.dataCategories.reduce(
          (acc, dc) => {
            acc[tDataType(dc.dataCategoryKey)] = dc;
            return acc;
          },
          {} as Record<string, DataCategory>
        );

        for (const item of theRestItems) {
          // move all child of data category to new person group
          for (const dataCategory of item.dataCategories) {
            const sameNameDataCategory = dataCategoriesByNameInNewParent[tDataType(dataCategory.dataCategoryKey)];
            if (sameNameDataCategory && item.id && dataCategory.id && personGroupsToBeMergedInto.id) {
              const dataTypesByNameInNewParent = sameNameDataCategory.dataTypes.reduce(
                (acc, dt) => {
                  acc[tDataType(dt.dataTypeKey)] = dt;
                  return acc;
                },
                {} as Record<string, DataType>
              );
              for (const dataType of dataCategory.dataTypes) {
                const sameNameDataType = dataTypesByNameInNewParent[tDataType(dataType.dataTypeKey)];

                await dataAssetClient.updateDataAsset(
                  dataType.id,
                  {
                    parentDataAssetId: sameNameDataCategory.id,
                    mergedIntoId: sameNameDataType ? sameNameDataType.id : undefined
                  },
                  "true"
                );
              }
              await dataAssetClient.deleteDataAsset(dataCategory.id as string);
            } else {
              await dataAssetClient.updateDataAsset(
                dataCategory.id as string,
                { parentDataAssetId: personGroupsToBeMergedInto.id },
                "true"
              );
            }
          }

          // after that, delete PG
          if (item.id) {
            await dataAssetClient.deleteDataAsset(item.id);
          }
        }
      };
      await mutate(
        mergePersonGroups().then(() => toPersonGroupData([])),
        {
          populateCache: false
        }
      );
      return personGroupsToBeMergedInto.id;
    },
    [data, mutate, t]
  );

  // DUPLICATE
  const duplicate = React.useCallback(
    async (dataAssetId: string) => {
      await mutate(
        async () => {
          const lng = i18n.language || "en-US";
          const createResponse = await dataAssetClient.duplicateDataAsset(dataAssetId, {
            timeout: 30000,
            headers: {
              "Accept-Language": lng
            }
          });
          return createResponse.headers["x-resource-id"];
        },
        {
          populateCache: false
        }
      );
    },
    [mutate, i18n.language]
  );

  // MARK ALL AS READ
  const markAllAsRead = React.useCallback(async () => {
    await dataAssetClient.markAllDataAssetsAsRead();
    await mutate();
    // wait for 3s and revalidate
    await new Promise(resolve => setTimeout(resolve, 3000));
    await mutate();
  }, [mutate]);

  return {
    ...dataTypeTreeData,
    data: dataTypeTreeData.data,
    unseenCount,
    actions: {
      // ADD
      addPersonGroup,
      addDataCategory,
      addDataType,
      // DELETE
      deletePersonGroup,
      deleteDataCategory,
      deleteDataType,
      // MOVE
      moveDataType,
      moveDataCategory,
      // UPDATE
      updatePersonGroup,
      updateDataCategory,
      updateDataType,
      // MERGE
      mergePersonGroups,
      mergeDataCategories,
      mergeDataTypes,

      // DUPLICATE
      duplicate,
      // MARK ALL AS READ
      markAllAsRead
    }
  };
};

export const exportDataTypes = async (filename?: string): Promise<void> => {
  const response = await dataAssetClient.getPersonGroups(undefined, {
    timeout: 0
  });
  const personGroups = response.data.items || [];

  const flatDataTypes = personGroups.flatMap(personGroup =>
    personGroup.dataCategories.length
      ? personGroup.dataCategories.flatMap(dataCategory =>
          dataCategory.dataTypes.length
            ? dataCategory.dataTypes.map(dataType => ({
                id: dataType.id,
                dataTypeKey: dataType.dataTypeKey || "-",
                personGroupKey: personGroup.personGroupKey || "-",
                dataCategoryKey: dataCategory.dataCategoryKey || "-",
                mergedInto: dataType.mergedIntoId || "-",
                dataClassificationId: dataType.dataClassificationId || "-"
              }))
            : [
                {
                  id: `empty-category-${dataCategory.dataCategoryKey}`,
                  dataTypeKey: "-",
                  personGroupKey: personGroup.personGroupKey || "-",
                  dataCategoryKey: dataCategory.dataCategoryKey || "-",
                  mergedInto: "-",
                  dataClassificationId: "-"
                }
              ]
        )
      : [
          {
            id: `empty-persongroup-${personGroup.personGroupKey}`,
            dataTypeKey: "-",
            personGroupKey: personGroup.personGroupKey || "-",
            dataCategoryKey: "-",
            mergedInto: "-",
            dataClassificationId: "-"
          }
        ]
  );

  const flatDataTypesWithTranslations = flatDataTypes.map(it => ({
    ...it,
    translatedPGKey: i18n.t(`lists_data_types_categories_person_groups:${it.personGroupKey}`, it.personGroupKey),
    translatedDCKey: i18n.t(`lists_data_types_categories_person_groups:${it.dataCategoryKey}`, it.dataCategoryKey),
    translatedDTKey: i18n.t(`lists_data_types_categories_person_groups:${it.dataTypeKey}`, it.dataTypeKey)
  }));

  const data: ExportedDataTypeEntry[] = orderBy(flatDataTypesWithTranslations, [
    it => it.translatedPGKey,
    it => it.translatedDCKey,
    it => it.translatedDTKey
  ]).map(it => ({
    Id: it.id,
    "Person Group": it.translatedPGKey,
    "Data Category": it.translatedDCKey,
    "Data Type": it.translatedDTKey
  }));

  const buffer = await writeXLSX({
    sheets: [
      {
        sheetName: "Data Types",
        data: data,
        columnOrder: ["Id", "Person Group", "Data Category", "Data Type"] satisfies (keyof ExportedDataTypeEntry)[]
      }
    ]
  });

  await createAndDownloadFile(
    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
    filename || "data-types.xlsx"
  );
};

interface ExportedDataTypeEntry {
  readonly Id: string;
  readonly "Person Group": string;
  readonly "Data Category": string;
  readonly "Data Type": string;
}

export const importDataTypeJobLocally = async (params: {
  readonly base64Excel: string;
}): Promise<{ readonly runId: string }> => {
  const excelData = await readXLSX(params.base64Excel, "base64");

  const dataTypes: ExportedDataTypeEntry[] = excelData["Data Types"] || [];

  const runId = v4();

  const addMessage = (message: string) => {
    insertLocalImportData(runId, {
      message
    });
  };

  const importJob = async () => {
    addMessage("downloading current data type state");
    const response = await dataAssetClient.getPersonGroups(undefined, {
      timeout: 60000
    });

    addMessage("converting current data type state to index");
    type PGMap = {
      pgId: string;
      nameToDCIdMap: Map<string, string>;
    };
    const nameToPGMap = new Map<string, PGMap>();
    const dcIdToDataTypeNameToIdMap = new Map<string, Map<string, string>>();
    const dataTypeMap = new Map<
      string,
      {
        id: string;
        names: Set<string>;
        dcId: string;
        dcNames: Set<string>;
        pgId: string;
        pgNames: Set<string>;
      }
    >();

    const getKeyWithTranslations = (key: string): Set<string> => {
      return new Set([
        key,
        i18n.t(`lists_data_types_categories_person_groups:${key}`, {
          defaultValue: key,
          lng: "en"
        }),
        i18n.t(`lists_data_types_categories_person_groups:${key}`, {
          defaultValue: key,
          lng: "de"
        })
      ]);
    };

    for (const pg of response.data.items || []) {
      const pgId = pg.id || "";
      const pgMap: PGMap = {
        pgId: pgId,
        nameToDCIdMap: new Map<string, string>()
      };
      const pgNames = getKeyWithTranslations(pg.personGroupKey);
      pgNames.forEach(pgName => nameToPGMap.set(pgName, pgMap));

      for (const dc of pg.dataCategories) {
        const dcId = dc.id || "";
        const dcNames = getKeyWithTranslations(dc.dataCategoryKey);
        dcNames.forEach(dcName => pgMap.nameToDCIdMap.set(dcName, dcId));

        const dataTypeNameToIdMap = new Map<string, string>();

        for (const dt of dc.dataTypes) {
          const dtNames = getKeyWithTranslations(dt.dataTypeKey);
          dtNames.forEach(dtName => dataTypeNameToIdMap.set(dtName, dt.id));

          dataTypeMap.set(dt.id, {
            id: dt.id,
            names: dtNames,
            dcId: dcId,
            dcNames: dcNames,
            pgId: pgId,
            pgNames: pgNames
          });
        }

        dcIdToDataTypeNameToIdMap.set(dcId, dataTypeNameToIdMap);
      }
    }

    for (const dataType of dataTypes) {
      const currentDataType = dataTypeMap.get(dataType.Id);
      if (!currentDataType) {
        addMessage(`${dataType.Id} not found in current data type state`);
        continue;
      }

      addMessage(
        `${dataType.Id} start from ${[...currentDataType.pgNames].join("/")}:::${[...currentDataType.dcNames].join("/")}:::${[...currentDataType.names].join("/")} to ${dataType["Person Group"]}:::${dataType["Data Category"]}:::${dataType["Data Type"]}`
      );

      let shouldBeName = dataType["Data Type"];
      if (currentDataType.names.has(shouldBeName)) {
        addMessage(`${dataType.Id} has the same name of "${shouldBeName}" in the current data type state, skipping`);
      } else {
        addMessage(
          `${dataType.Id} updating data type name from ${[...currentDataType.names].join("/")} to ${shouldBeName}`
        );
        try {
          await dataAssetClient.updateDataAsset(dataType.Id, { name: shouldBeName }, "true");
          currentDataType.names = getKeyWithTranslations(shouldBeName);
        } catch (e: unknown) {
          if (!isAxiosErrorWithCode(e, 409)) {
            throw e;
          } else {
            const dtIdWithSameName = dcIdToDataTypeNameToIdMap.get(currentDataType.dcId)?.get(shouldBeName);
            const dtWithSameName = dtIdWithSameName ? dataTypeMap.get(dtIdWithSameName) : null;
            if (dtWithSameName) {
              addMessage(
                `${dataType.Id} conflict - merging to ${[...currentDataType.names].join("/")} to ${[...(dtWithSameName.names || [])]?.join("/")}`
              );
              await dataAssetClient.mergeDataAssets(dtWithSameName.id, { idsToMerge: [dataType.Id] });
              dataTypeMap.delete(dataType.Id);
            } else {
              shouldBeName = `${shouldBeName} (Duplicate) #${new Date().toISOString()}`;
              addMessage(
                `${dataType.Id} conflict - updating data type name from ${[...currentDataType.names].join("/")} to ${shouldBeName}`
              );
              await dataAssetClient.updateDataAsset(dataType.Id, { name: shouldBeName }, "true");
              currentDataType.names = getKeyWithTranslations(shouldBeName);
            }
          }
        }
      }

      const shouldBePGName = dataType["Person Group"];
      let existingPG = nameToPGMap.get(shouldBePGName);
      if (!existingPG) {
        addMessage(`${dataType.Id} PG name of "${shouldBePGName}" never existing, creating..`);
        const pgId: string = (
          await dataAssetClient.createDataAsset(
            {
              name: shouldBePGName,
              assetType: DataAssetType.PersonGroup
            },
            "true"
          )
        ).headers["x-resource-id"];
        const newPGNames = getKeyWithTranslations(shouldBePGName);
        const newPGMap: PGMap = {
          pgId,
          nameToDCIdMap: new Map()
        };
        for (const pgName of newPGNames) {
          nameToPGMap.set(pgName, newPGMap);
        }
        existingPG = newPGMap;
      } else {
        addMessage(
          `${dataType.Id} PG has the same name of "${shouldBePGName}" in the current data type state, skipping`
        );
      }

      const shouldBeDCName = dataType["Data Category"];
      let existingDCId = existingPG.nameToDCIdMap.get(shouldBeDCName);
      if (!existingDCId) {
        addMessage(`${dataType.Id} DC name of "${shouldBeDCName}" never existing in ${shouldBePGName}, creating..`);
        const dcId: string = (
          await dataAssetClient.createDataAsset(
            {
              name: shouldBeDCName,
              assetType: DataAssetType.DataCategory,
              parentDataAssetId: existingPG.pgId
            },
            "true"
          )
        ).headers["x-resource-id"];
        const newDCNames = getKeyWithTranslations(shouldBeDCName);
        for (const dcName of newDCNames) {
          existingPG.nameToDCIdMap.set(dcName, dcId);
        }
        dcIdToDataTypeNameToIdMap.set(dcId, new Map());
        existingDCId = dcId;
      } else {
        addMessage(`${dataType.Id} DC has the same name of "${shouldBeDCName}" in ${shouldBePGName}, skipping`);
      }

      if (currentDataType.dcId !== existingDCId) {
        addMessage(`${dataType.Id} updating data category id from ${currentDataType.dcId} to ${existingDCId}`);
        try {
          await dataAssetClient.updateDataAsset(dataType.Id, { parentDataAssetId: existingDCId }, "true");
          getKeyWithTranslations(dataType["Data Type"]).forEach(dtName => {
            const dtId = dcIdToDataTypeNameToIdMap.get(currentDataType.dcId)?.get(dtName);
            if (dtId === dataType.Id) {
              dcIdToDataTypeNameToIdMap.get(currentDataType.dcId)?.delete(dtName);
            }
          });
          getKeyWithTranslations(shouldBeName).forEach(dtName =>
            dcIdToDataTypeNameToIdMap.get(existingDCId)?.set(dtName, dataType.Id)
          );
        } catch (e: unknown) {
          if (!isAxiosErrorWithCode(e, 409)) {
            throw e;
          }

          const dtIdWithSameName = dcIdToDataTypeNameToIdMap.get(existingDCId)?.get(shouldBeName);
          const dtWithSameName = dtIdWithSameName ? dataTypeMap.get(dtIdWithSameName) : null;
          if (dtWithSameName) {
            addMessage(
              `${dataType.Id} conflict - merging to ${[...currentDataType.names].join("/")} to ${[...(dtWithSameName.names || [])]?.join("/")}`
            );
            await dataAssetClient.mergeDataAssets(dtWithSameName.id, { idsToMerge: [dataType.Id] });
            dataTypeMap.delete(dataType.Id);
          } else {
            shouldBeName = `${shouldBeName} (Duplicate) #${new Date().toISOString()}`;
            addMessage(
              `${dataType.Id} conflict - additionally updating data type name from ${[...currentDataType.names].join("/")} to ${shouldBeName}`
            );
            await dataAssetClient.updateDataAsset(
              dataType.Id,
              { parentDataAssetId: existingDCId, name: shouldBeName },
              "true"
            );
            currentDataType.names = getKeyWithTranslations(shouldBeName);
            currentDataType.dcNames = getKeyWithTranslations(shouldBeDCName);
            currentDataType.pgNames = getKeyWithTranslations(shouldBePGName);
          }
        }
        currentDataType.dcId = existingDCId;
      } else {
        addMessage(`${dataType.Id} data category id is the same, skipping`);
      }

      addMessage(
        `${dataType.Id} success from ${[...currentDataType.pgNames].join("/")}:::${[...currentDataType.dcNames].join("/")}:::${[...currentDataType.names].join("/")} to ${dataType["Person Group"]}:::${dataType["Data Category"]}:::${dataType["Data Type"]}`
      );
    }

    insertLocalImportData(runId, {
      message: "success"
    });
  };

  importJob().catch(err => {
    insertLocalImportData(runId, {
      error_message: `Error: ${err.message}}`,
      message: "An error occurred while importing data types."
    });
    console.error(err);
  });

  return { runId };
};

export const useDataAssetUsageInPAs = (args: { pgIds?: string[]; dtIds?: string[] }) => {
  return useSWR(
    !args.pgIds && !args.dtIds ? null : ["asset-usage-in-pas", args.pgIds, args.dtIds],
    ([_, pgIds, dtIds]) => {
      return dataAssetClient
        .getDataAssetUsagesInPA({
          personGroupIds: pgIds,
          dataTypeIds: dtIds
        })
        .then(resp => resp.data);
    }
  );
};
