import React, { createContext, useContext, useReducer } from "react";
import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";

export type OVERVIEW_ACTION_TYPES =
  | "SET_SORT"
  | "SET_FILTER"
  | "SET_SEARCH"
  | "SET_LOADED"
  | "SET_SELECTED_ID"
  | "SET_CHECKED_IDS"
  | "SET_LOADING"
  | "SET_LOADING_MORE"
  | "RESET_ALL"
  | "RELOAD_OVERVIEW"
  | "RELOAD_METAVIEW"
  | "RELOAD"
  | "ACTION_STARTED"
  | "ACTION_COMPLETED"
  | "ACTION_FAILED";

export const OVERVIEW_ACTIONS = {
  SET_SORT: "SET_SORT",
  SET_FILTER: "SET_FILTER",
  SET_SEARCH: "SET_SEARCH",
  SET_LOADED: "SET_LOADED",
  SET_SELECTED_ID: "SET_SELECTED_ID",
  SET_CHECKED_IDS: "SET_CHECKED_IDS",
  SET_LOADING: "SET_LOADING",
  SET_LOADING_MORE: "SET_LOADING_MORE",
  RESET_ALL: "RESET_ALL",
  RELOAD_OVERVIEW: "RELOAD_OVERVIEW",
  RELOAD_METAVIEW: "RELOAD_METAVIEW",
  RELOAD: "RELOAD",
  ACTION_STARTED: "ACTION_STARTED",
  ACTION_COMPLETED: "ACTION_COMPLETED",
  ACTION_FAILED: "ACTION_FAILED"
} as const satisfies Record<string, OVERVIEW_ACTION_TYPES>;

export interface OverviewContextFilter {
  readonly [key: string]: string | string[];
}

export interface OverviewContextSort {
  readonly [key: string]: "asc" | "desc" | undefined;
}

export interface SingleOverviewContext {
  readonly search: string;
  readonly filter: OverviewContextFilter;
  readonly sort: OverviewContextSort;
  readonly loaded: number;
  readonly chunkSize: number;
  readonly shadowLoading?: boolean;
  readonly action?: "started" | "completed" | "failed";
  readonly loading: boolean;
  readonly loadingMore: boolean;
  readonly selectedId: string | null;
  readonly checkedIds: string[];
  readonly indeterminateIds: string[];
  readonly reloadOverview: number | null;
  readonly reloadMetaview: number | null;
  readonly reload: {
    readonly shadowLoading?: boolean;
    readonly selectedId?: string | null;
    readonly checkedIds?: string[] | null;
    readonly indeterminateIds?: string[] | null;
    readonly reloadOverview: number | null;
    readonly reloadMetaview: number | null;
  };
}

export type OverviewContext = Record<COLLECTION_TYPES, SingleOverviewContext>;

export interface OverviewContextAction extends Partial<SingleOverviewContext> {
  readonly type: string;
  readonly collection?: COLLECTION_TYPES;
}

export type OverviewDispatch = (action: OverviewContextAction) => void;

const overviewReducer = (state: OverviewContext, action: OverviewContextAction): OverviewContext => {
  if (action.collection) {
    switch (action.type) {
      case OVERVIEW_ACTIONS.SET_SEARCH: {
        return {
          ...state,
          [action.collection]: { ...state[action.collection], search: action.search, selectedId: null }
        };
      }
      case OVERVIEW_ACTIONS.SET_FILTER: {
        const filterPayload = action.filter || {};
        if (Object.keys(filterPayload).length === 0) {
          return {
            ...state,
            [action.collection]: { ...state[action.collection], filter: {}, selectedId: null }
          };
        }

        const updatedFilter: OverviewContextFilter = {
          ...(state[action.collection]?.filter || {}),
          ...filterPayload
        };

        const updatedFilterWithoutEmpty: OverviewContextFilter = Object.entries(updatedFilter).reduce<{
          [key: string]: string | string[];
        }>((acc, [filterKey, filterValue]) => {
          if (Array.isArray(filterValue) && filterValue.length === 0) {
            return acc;
          }
          acc[filterKey] = filterValue;
          return acc;
        }, {});

        return {
          ...state,
          [action.collection]: { ...state[action.collection], filter: updatedFilterWithoutEmpty, selectedId: null }
        };
      }
      case OVERVIEW_ACTIONS.SET_SORT: {
        return {
          ...state,
          [action.collection]: { ...state[action.collection], sort: action.sort }
        };
      }
      case OVERVIEW_ACTIONS.SET_LOADED: {
        return { ...state, [action.collection]: { ...state[action.collection], loaded: action.loaded } };
      }
      case OVERVIEW_ACTIONS.RELOAD_OVERVIEW: {
        return {
          ...state,
          [action.collection]: {
            ...state[action.collection],
            reloadOverview: action.reloadOverview,
            checkedIds: []
          }
        };
      }
      case OVERVIEW_ACTIONS.RELOAD_METAVIEW: {
        return {
          ...state,
          [action.collection]: { ...state[action.collection], reloadMetaview: action.reloadMetaview }
        };
      }
      case OVERVIEW_ACTIONS.SET_SELECTED_ID: {
        return {
          ...state,
          [action.collection]: {
            ...state[action.collection],
            selectedId: action.selectedId
          }
        };
      }
      case OVERVIEW_ACTIONS.SET_CHECKED_IDS: {
        return {
          ...state,
          [action.collection]: {
            ...state[action.collection],
            checkedIds: action.checkedIds,
            interminateIds: action.indeterminateIds
          }
        };
      }
      case OVERVIEW_ACTIONS.RELOAD: {
        if (action.reload) {
          return {
            ...state,
            [action.collection]: {
              ...state[action.collection],
              shadowLoading:
                action.reload.shadowLoading === undefined
                  ? state[action.collection].shadowLoading
                  : action.reload.shadowLoading,
              selectedId:
                action.reload.selectedId === undefined ? state[action.collection].selectedId : action.reload.selectedId,
              checkedIds:
                action.reload.checkedIds === undefined ? state[action.collection].checkedIds : action.reload.checkedIds,
              reloadMetaview: action.reload.reloadMetaview,
              reloadOverview: action.reload.reloadOverview
            }
          };
        } else {
          return state;
        }
      }
      case OVERVIEW_ACTIONS.SET_LOADING: {
        return {
          ...state,
          [action.collection]: {
            ...state[action.collection],
            loading: Boolean(action.loading)
          }
        };
      }
      case OVERVIEW_ACTIONS.SET_LOADING_MORE: {
        return {
          ...state,
          [action.collection]: {
            ...state[action.collection],
            loadingMore: Boolean(action.loadingMore)
          }
        };
      }
      case OVERVIEW_ACTIONS.ACTION_STARTED: {
        return {
          ...state,
          [action.collection]: {
            ...state[action.collection],
            action: "started"
          }
        };
      }
      case OVERVIEW_ACTIONS.ACTION_COMPLETED: {
        return {
          ...state,
          [action.collection]: {
            ...state[action.collection],
            action: "completed"
          }
        };
      }
      case OVERVIEW_ACTIONS.ACTION_FAILED: {
        return {
          ...state,
          [action.collection]: {
            ...state[action.collection],
            action: "failed"
          }
        };
      }
      case OVERVIEW_ACTIONS.RESET_ALL: {
        return defaultOverviewContext;
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  } else {
    switch (action.type) {
      case OVERVIEW_ACTIONS.RESET_ALL: {
        return defaultOverviewContext;
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  }
};

const defaultOverviewContext: OverviewContext = Object.values(COLLECTIONS).reduce<Partial<OverviewContext>>(
  (accumulator, collectionType) => ({
    ...accumulator,
    [collectionType]: {
      sort: {},
      filter: {},
      search: "",
      loaded: 50,
      chunkSize: 50,
      loading: true,
      loadingMore: false,
      selectedId: null,
      checkedIds: [],
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    }
  }),
  {}
) as OverviewContext;

const OverviewStateContext = createContext<OverviewContext>({ ...defaultOverviewContext });
const OverviewDispatchContext = createContext<OverviewDispatch>(action => {
  /* do nothing */
});

export const OverviewProvider = ({ children }: { readonly children?: React.ReactNode }) => {
  const [state, dispatch] = useReducer(overviewReducer, { ...defaultOverviewContext });
  return (
    <OverviewStateContext.Provider value={state}>
      <OverviewDispatchContext.Provider value={dispatch}>{children}</OverviewDispatchContext.Provider>
    </OverviewStateContext.Provider>
  );
};

export const useOverviewState = (): OverviewContext => useContext(OverviewStateContext);
export const useOverviewDispatch = (): OverviewDispatch => useContext(OverviewDispatchContext);
