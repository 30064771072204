import { Overrides } from "@material-ui/core/styles/overrides";
import { suisseIntlFonts } from "theme/fonts/fonts";
import colors from "theme/palette/colors";
import palette from "../palette/palette";

const overrides: Overrides = {
  MuiOutlinedInput: {
    root: {
      "&$focused $notchedOutline": {
        borderWidth: 1
      }
    }
  },
  MuiTooltip: {
    tooltip: {
      fontFamily: "SuisseIntl"
    }
  },
  MuiChip: {
    root: {
      fontFamily: "SuisseIntl"
    },
    colorSecondary: {
      color: palette.text?.primary
    }
  },
  MuiTabs: {
    indicator: {
      fontFamily: "SuisseIntl"
    }
  },
  MuiTab: {
    root: {
      "&$selected": {
        fontFamily: "SuisseIntl"
      }
    }
  },
  MuiBottomNavigation: {
    root: {
      fontFamily: "SuisseIntl"
    }
  },
  MuiButtonBase: {
    root: {
      fontFamily: "SuisseIntl",
      textTransform: "none"
    }
  },
  MuiBottomNavigationAction: {
    root: {
      fontFamily: "SuisseIntl"
    },
    label: {
      fontFamily: "SuisseIntl"
    }
  },
  MuiCssBaseline: {
    "@global": {
      "@font-face": suisseIntlFonts
    }
  },
  MuiInputBase: {
    root: {
      "&.Mui-disabled": {
        color: colors.grey.grey400
      }
    }
  }
};

export default overrides;
