import { getAuditActionTasks } from "app/api/auditApi";
import { SWR_KEYS } from "app/swrKeys";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { useIsFeaturePresent } from "../../hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { getAnswersetActionTasks } from "app/api/assessmentApi";

export function useAuditActionTasks(auditId?: string, answersetId?: string) {
  const params = useParams();
  const documentId = params?.id;
  const isPublicAssessment = useIsFeaturePresent(FEATURES.PUBLIC_ASSESSMENT);

  const { data, isValidating, mutate } = useSWR(SWR_KEYS.auditActionTasks, () =>
    isPublicAssessment ? getAnswersetActionTasks(auditId, answersetId) : getAuditActionTasks(documentId)
  );
  return {
    auditActionTasksLoaded: data && !isValidating,
    auditActionTasks: data?.data || {},
    auditActionTasksReload: mutate
  };
}
