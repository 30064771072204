import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export const sx: { [key: string]: SxProps<Theme> } = {
  taskOverviewContainer: {
    border: "1px solid #C9C9C9",
    borderRadius: theme => theme.spacing(1),
    fontFamily: "SuisseIntl",
    padding: theme => theme.spacing(4)
  },
  taskOverviewTitle: {
    fontSize: "20px",
    fontWeight: 600,
    letterSpacing: "-0.5px",
    marginRight: theme => theme.spacing(2)
  },
  seeAllTasksButton: {
    marginTop: "5px",
    textAlign: "left",
    textTransform: "initial"
  },
  newTaskButton: {
    fontFamily: "SuisseIntl",
    position: "absolute",
    right: "95px",
    "& svg": {
      height: theme => theme.spacing(3),
      marginRight: theme => theme.spacing(1),
      width: theme => theme.spacing(2)
    }
  },
  taskOverview: {
    marginTop: theme => theme.spacing(4),
    height: "50vh"
  },
  taskDetailsOverlay: {
    bottom: theme => theme.spacing(3),
    overflow: "auto",
    position: "absolute",
    right: 0,
    top: theme => theme.spacing(3),
    zIndex: 1300,
    marginTop: "41px",
    width: "calc(33.33% - 41px);",
    minHeight: "93vh" // hack for now
  }
};
