import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButtonProps, ListItem, Tooltip } from "@material-ui/core";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import AuthenticationPageTemplate from "./AuthenticationPageTemplate";
import { useNavigate } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import CustomAlert from "components/CustomAlert/CustomAlert";
import IconButton from "@material-ui/core/IconButton";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { MeTenantDTO } from "../../api/user/userMeApiInterfaces";
import { ButtonProps } from "@material-ui/core/Button/Button";
import { clearRedirectFullPathSession, getRedirectFullPath } from "app/handlers/authentication/redirectStorage";
import { paReadPermissionExists } from "../../defaultRolePageAccess";
import { useTheme } from "@mui/material";

export default function Tenants() {
  const navigate = useNavigate();
  const { t } = useTranslation("tenant_page");
  const theme = useTheme();
  const { user: loggedInUser, changeTenantHook } = useAuthentication();

  const onClickCallback = useCallback(
    async (tenant: Required<MeTenantDTO>) => {
      await changeTenantHook(tenant.tenantId);
      const redirect = await getRedirectFullPath();
      await clearRedirectFullPathSession();
      if (!paReadPermissionExists(tenant.permissions)) {
        navigate("/tasks/my");
      } else {
        redirect ? navigate(redirect) : navigate("/processes");
      }
    },
    [changeTenantHook, navigate]
  );

  const navigateToLogout = useCallback(() => {
    navigate("/logout");
  }, [navigate]);

  const logoutButton = (
    <ButtonGroupIconButton data-qa="logout" aria-label="logout" size="small" onClick={navigateToLogout}>
      <PowerSettingsNewIcon />
    </ButtonGroupIconButton>
  );

  const allTenants = useMemo<(Required<MeTenantDTO> & { readonly blocked: boolean })[]>(() => {
    return [
      ...(loggedInUser?.tenants || []).map(it => ({
        ...it,
        blocked: false
      })),
      ...(loggedInUser?.blockedTenants || []).map(it => ({
        ...it,
        blocked: true
      }))
    ].sort((a, b) => a.tenantName.localeCompare(b.tenantName));
  }, [loggedInUser?.tenants, loggedInUser?.blockedTenants]);

  return (
    <AuthenticationPageTemplate additionalButtons={logoutButton}>
      <Box mt={2} mb={1}>
        <CustomAlert severity="info" style={{ background: theme.palette.blue[100] }}>
          {t("please_select")}
        </CustomAlert>
      </Box>
      <List>
        {allTenants.map((tenant, index) => (
          <React.Fragment key={tenant.tenantId}>
            {index === 0 && <Divider />}
            <TenantRow tenant={tenant} onClick={onClickCallback} blocked={tenant.blocked} />
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </AuthenticationPageTemplate>
  );
}

// https://github.com/mui/material-ui/issues/17226#issuecomment-672980848
const ButtonGroupIconButton = (props: ButtonProps) => {
  const { ...iconButtonProps } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconButton {...(iconButtonProps as IconButtonProps)} />;
};

const TenantRow = ({
  tenant,
  onClick,
  blocked
}: {
  tenant: Required<MeTenantDTO>;
  onClick: (tenant: Required<MeTenantDTO>) => Promise<void>;
  blocked: boolean;
}) => {
  const { t } = useTranslation("manage-user-page");
  const onClickCallback = useCallback(() => {
    return onClick(tenant);
  }, [onClick, tenant]);

  return (
    <Tooltip title={blocked ? t("blockedTenant") : ""} placement="right">
      <Box>
        <ListItem button onClick={onClickCallback} disabled={blocked}>
          <ListItemText primary={tenant.tenantName} secondary={t(tenant.userRoleName)} />
        </ListItem>
      </Box>
    </Tooltip>
  );
};
