import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { LoggedInUser } from "../../../handlers/authentication/authenticationHandlerInterfaces";
import { Avatar, Box, MenuItem, Link, Menu, useTheme, Divider, Typography } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LogoutIcon from "@mui/icons-material/Logout";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useDocumentName } from "app/contexts/document-name-context";

const useStyles = makeStyles(theme => ({
  link: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main
    }
  }
}));

export const UserAvatarTopbar = ({ userData }: { readonly userData: LoggedInUser | null }) => {
  const { t } = useTranslation("topbar");
  const navigate = useNavigate();
  const { clearDocumentName } = useDocumentName();
  const { tenantData } = useUserAndTenantData();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useOverviewDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const navigateToAccountSettings = useCallback(() => {
    clearDocumentName();
    navigate("/account-settings");
  }, [navigate, clearDocumentName]);
  const navigateToLogout = useCallback(() => {
    clearDocumentName();
    navigate("/logout");
  }, [navigate, clearDocumentName]);
  const avatarLabel = useMemo(
    () => (userData?.firstName || userData?.email || "").substring(1, 0).toUpperCase(),
    [userData]
  );
  const clickAwayCallback = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(event => {
    const currentTarget = event.currentTarget;
    setAnchorEl(anchorEl => (anchorEl ? null : currentTarget));
  }, []);

  const navigateToTenants = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.RESET_ALL
    });
    clearDocumentName();
    navigate("/tenants");
  }, [dispatch, navigate, clearDocumentName]);

  return (
    <>
      <Box display="flex" width="100%">
        <Box ml={1} sx={{ paddingLeft: "4px", alignSelf: "center" }}>
          <Link underline="none" className={classes.link} onClick={handleClick}>
            <Avatar
              aria-label="headerIcon"
              style={{ backgroundColor: theme.palette.primary.main, alignSelf: "center" }}
              sx={{ height: "32px", width: "32px" }}
            >
              {avatarLabel}
            </Avatar>
          </Link>
        </Box>
      </Box>
      <Menu id="account-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={clickAwayCallback}>
        <MenuItem onClick={navigateToAccountSettings}>
          <Typography variant="body2" style={{ color: theme.palette.grey[800] }}>
            {t("accountSettings")}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={navigateToTenants}>
          <SwapHorizIcon sx={{ mr: 1, color: theme.palette.grey[500] }} />{" "}
          <Typography variant="body2" style={{ color: theme.palette.grey[800] }} align="center" noWrap={true}>
            {tenantData?.name || ""}
          </Typography>
        </MenuItem>
        <MenuItem onClick={navigateToLogout}>
          <LogoutIcon sx={{ mr: 1, color: theme.palette.grey[500] }} />
          <Typography variant="body2" style={{ color: theme.palette.grey[800] }}>
            {t("logout")}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserAvatarTopbar;
