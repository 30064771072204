import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDataSubjectRequest } from "app/contexts/dsr-context";
import AttachmentsContainer from "components/AttachmentsOverview/AttachmentsContainer";

interface DSRAttachmentsProps {
  disabled?: boolean;
  defaultInfo: {
    title: string;
    text: string;
  };
}

// attachments component for data subject requests
const DSRAttachments = ({ disabled, defaultInfo }: DSRAttachmentsProps) => {
  const { t } = useTranslation("data_subject_requests_data_page");
  const { dataSubjectRequest } = useDataSubjectRequest();

  return (
    <Box>
      <Typography variant="subtitle1">{t("attachments")}</Typography>
      <Box mt={3}>
        <AttachmentsContainer
          disabled={disabled}
          docId={dataSubjectRequest?.id || ""}
          category="datasubjectrequests"
          defaultInfo={defaultInfo}
        />
      </Box>
    </Box>
  );
};

export default DSRAttachments;
