import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Box, Typography } from "@mui/material";
import { SxProps } from "@mui/system";

interface TextBody2Props {
  readonly text: string;
  readonly translationKey?: string;
  readonly parseHTML?: boolean;
  readonly sx?: SxProps;
}

const TextBody2 = ({ text, translationKey, parseHTML, sx }: TextBody2Props) => {
  const { t } = useTranslation(translationKey);

  if (parseHTML) {
    return (
      <Box sx={sx}>
        <span className="MuiTypography-root MuiTypography-body2">{parse(DOMPurify.sanitize(text))}</span>
      </Box>
    );
  }

  return (
    <Typography variant="body2" sx={sx}>
      {translationKey ? t(text) : text}
    </Typography>
  );
};

export default TextBody2;
