import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { t } from "i18next";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";

const OverviewBrickAdd = ({ hovered, item, onAdd, onBlur }: OverviewRowBrickProps) => {
  const disableActions = item.disableActions;
  const onClick = useCallback(
    event => {
      onBlur?.();
      stopEvent(event);
      onAdd?.();
    },
    [onAdd, onBlur]
  );

  if (!hovered || disableActions?.some(({ action }) => action === "add")) {
    return <></>;
  }
  return (
    <Tooltip title={t("genericDocView:add")}>
      <IconButton onClick={onClick} size="small">
        <Add />
      </IconButton>
    </Tooltip>
  );
};

export default OverviewBrickAdd;
