import { getDataSubjectRequestStatsApi } from "../api/dataSubjectRequestApi";
import {
  getYYYYMMDDRange,
  toStartOfMonth,
  toStartOfYear,
  toStartOfWeek,
  toYYYYMMDDInUTC,
  yyyyMMddTODate
} from "./utility/date-helper";
import { groupBy, keyBy, minBy, sortBy } from "lodash-es";
import { getDataBreachStatsApi } from "../api/dataBreachApi";
import pick from "lodash-es/pick";
import { getStatsApi } from "../api/paApi";
import { getExternalRecipientsStatsApi } from "../api/externalRecipientApi";
import { getAuditStats } from "../api/auditApi";

export const STATS_TYPE = {
  // this enum is not arbitrary as we still have cubejs, and the values are the time period ID in cube js
  ALL_TIME: null,
  THIS_YEAR: "This year",
  THIS_MONTH: "This month",
  THIS_WEEK: "This week",
  TODAY: "Today"
};

export const getDSRStatistic = async (statsType, orgUnitId, labelId) => {
  return processStats(await getDataSubjectRequestStatsApi(orgUnitId, labelId), statsType, DEFAULT_STATS.dsr);
};

export const getPAStats = async (statsType, orgUnitId, labelId, onlyDPIA) => {
  return processStats(await getStatsApi({ onlyDPIA, orgUnitId, labelId }), statsType, DEFAULT_STATS.pa);
};

const processStats = async (stats, statsType, defaultStatValue) => {
  if (!Array.isArray(stats) || stats.length === 0) {
    return [];
  }
  const statKeys = Object.keys(defaultStatValue);
  const filteredStats = stats.map(stat => ({
    ...stat,
    value: pick(stat.value, statKeys)
  }));
  const earliestStat = minBy(filteredStats, stat => stat.date);
  const statsWithMiddleDatesFilledIn = fillInDatesWithoutData(
    statsTypeToDates(statsType, yyyyMMddTODate(earliestStat.date)),
    filteredStats,
    defaultStatValue
  );

  switch (statsType) {
    case STATS_TYPE.ALL_TIME:
      return groupByMonth(statsWithMiddleDatesFilledIn, defaultStatValue);
    default:
      return statsWithMiddleDatesFilledIn;
  }
};

export const getDataBreachStatistic = async (statsType, orgUnitId, labelId) => {
  return processStats(await getDataBreachStatsApi(orgUnitId, labelId), statsType, DEFAULT_STATS.dataBreach);
};

export const getExternalRecipientStatistic = async (statsType, labelId, orgUnitId) => {
  const externalRecipientsStats = await getExternalRecipientsStatsApi(labelId, orgUnitId);
  const dateFormattedStats = externalRecipientsStats.map(stat => ({ ...stat, date: stat.date.split("T")[0] }));
  return processStats(dateFormattedStats, statsType, DEFAULT_STATS.externalRecipient);
};

export const getAuditStatistic = async (statsType, orgUnitId, labelId) => {
  return processStats(await getAuditStats({ orgUnitId, labelId }), statsType, DEFAULT_STATS.audit);
};

const DEFAULT_STATS = {
  dsr: { created: 0, done: 0, deleted: 0 },
  dataBreach: { reportable: 0, nonReportable: 0, noReportableStatus: 0, deleted: 0 },
  externalRecipient: { numberNotApproved: 0, numberApproved: 0 },
  pa: { edit: 0, review: 0, approved: 0 },
  audit: { edit: 0, draft: 0, review: 0, completed: 0 }
};

const statsTypeToDates = (statsType, firstDate) => {
  switch (statsType) {
    case STATS_TYPE.THIS_YEAR:
      return getYYYYMMDDRange(toStartOfYear(new Date()), new Date());
    case STATS_TYPE.THIS_MONTH:
      return getYYYYMMDDRange(toStartOfMonth(new Date()), new Date());
    case STATS_TYPE.THIS_WEEK:
      return getYYYYMMDDRange(toStartOfWeek(new Date()), new Date());
    case STATS_TYPE.TODAY:
      return [toYYYYMMDDInUTC(new Date())];
    case STATS_TYPE.ALL_TIME:
    default:
      return getYYYYMMDDRange(firstDate, new Date());
  }
};

const fillInDatesWithoutData = (dateRanges, inputStats, defaultStatValue) => {
  const dateToInputStats = keyBy(inputStats, stat => stat.date);

  return dateRanges.map(
    dateRange => dateToInputStats[dateRange] || { date: dateRange, value: { ...defaultStatValue } }
  );
};

const yyyyMMddToyyyyMMForStats = yyyyMMdd => {
  return yyyyMMdd.split("-").slice(0, 2).join("-");
};

const groupByMonth = (stats, defaultStatValue) => {
  const byMonthStats = groupBy(stats, stat => yyyyMMddToyyyyMMForStats(stat.date));

  const groupedByStats = Object.values(byMonthStats).map(monthlyStats => {
    return monthlyStats.reduce(
      (finalMonthlyStat, stat) => {
        const combineStatValue = (value, nextStatKey) => ({
          ...value,
          [nextStatKey]: value[nextStatKey] + (stat.value[nextStatKey] || 0)
        });

        return {
          date: stat.date,
          value: Object.keys(defaultStatValue).reduce(combineStatValue, finalMonthlyStat.value)
        };
      },
      { value: defaultStatValue }
    );
  });

  return sortBy(groupedByStats, stats => stats.date);
};
