import { Add } from "@material-ui/icons";
import { Box, Button } from "@mui/material";
import { COLLECTIONS } from "app/collections";
import { useMetaView } from "app/contexts/meta-view-context";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import Overview from "components/Overview/Overview";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OverviewBrickProps } from "components/Overview/controls/OverviewRow";
import { exportAssessmentResponsesExcel } from "app/export/createdExcelExportData";
import { useSnackbar } from "notistack";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";

const bricks = [
  { component: OverviewBrick.AnswersetStatus },
  { component: OverviewBrick.Title },
  { component: OverviewBrick.AssessmentContributors },
  { component: OverviewBrick.UpdatedAt },
  { component: OverviewBrick.Remove, position: "right" }
] as OverviewBrickProps[];

interface AssessmentResponsesOverviewProps {
  readonly auditId: string;
  readonly enterInfo: { readonly title: string; readonly text: string };
  readonly writePermission: boolean;

  readonly onNext: () => void;
  readonly onAddAnswerSet: () => void;
  readonly onRowClick: (item: OverviewItem, allAssessmentResponseIds: string[]) => void;
}
const AssessmentResponsesOverview = ({
  auditId,
  enterInfo,
  writePermission,

  onNext,
  onAddAnswerSet,
  onRowClick
}: AssessmentResponsesOverviewProps) => {
  const { t } = useTranslation("audit_details");
  const { setInfo } = useMetaView();
  const { enqueueSnackbar } = useSnackbar();
  const isAssessmentDownloadReportFeatureEnabled = useIsFeaturePresent(FEATURES.ASSESSMENT_REPORT);

  const [allAssessmentResponseIds, setAllAssessmentResponseIds] = useState<string[]>([]);

  const showEnterInfo = useCallback(() => {
    setInfo(enterInfo);
  }, [enterInfo, setInfo]);

  const toolbarActions = useMemo(
    () => [
      { action: "sort" },
      { action: "filter" },
      ...(isAssessmentDownloadReportFeatureEnabled ? [{ action: "export-xlsx", tooltip: t("common:exportAll") }] : [])
    ],
    [t, isAssessmentDownloadReportFeatureEnabled]
  );

  const selectionActions = useMemo(
    () => [
      ...(isAssessmentDownloadReportFeatureEnabled ? [{ action: "export-xlsx", tooltip: t("common:exportAll") }] : []),
      { action: "remove" }
    ],
    [t, isAssessmentDownloadReportFeatureEnabled]
  );

  const onRowClickCallback = useCallback(
    (item: OverviewItem) => {
      onRowClick(item, allAssessmentResponseIds);
    },
    [allAssessmentResponseIds, onRowClick]
  );

  const overviewToolbarAddControl = useMemo(() => {
    return (
      <Box display={"flex"} ml={2}>
        {writePermission && (
          <Button
            color="primary"
            onClick={onAddAnswerSet}
            startIcon={<Add fontSize="small" />}
            style={{ marginRight: "16px" }}
            variant="outlined"
          >
            {t("common:create")}
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={onNext}>
          {t("common:nextContinue")}
        </Button>
      </Box>
    );
  }, [onAddAnswerSet, onNext, t, writePermission]);

  const exportToXLSX = useCallback(
    async ids => {
      if (ids.length === 0) {
        enqueueSnackbar(t("audit_details:noResponsesAvailableForExport"), { variant: "error" });
        return;
      }
      try {
        const excelData = await exportAssessmentResponsesExcel(t, auditId, ids);
        if (excelData === null) {
          enqueueSnackbar(t("audit_details:noResponsesAvailableForExport"), { variant: "error" });
          return;
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t("common:error"), { variant: "error" });
      }
    },
    [t, auditId, enqueueSnackbar]
  );

  const collectionParams = useMemo(
    () => ({ auditId, setAllAssessmentResponseIds, exportToXLSX }),
    [auditId, exportToXLSX]
  );

  return (
    <Overview
      addActions={[]}
      bricks={bricks}
      checkable={true}
      collection={COLLECTIONS.ASSESSMENT_RESPONSE}
      collectionParams={collectionParams}
      selectionActions={selectionActions}
      toolbarActions={toolbarActions}
      toolbarAddControl={overviewToolbarAddControl}
      toolbarHeader={t("audit_details:responses")}
      toolbarMode={"tabs"}
      onAddClose={showEnterInfo}
      onRowClick={onRowClickCallback}
      onRowLeave={showEnterInfo}
    />
  );
};

export default AssessmentResponsesOverview;
