import { Chip, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useTranslation } from "react-i18next";
import colors from "theme/palette/colors";

const sx = {
  background: colors.grey.grey200,
  color: colors.grey.grey500,
  textTransform: "uppercase",
  fontWeight: 500,
  fontSize: "10px",
  height: "24px"
};

const OverviewBrickReadonly = ({ item }: OverviewRowBrickProps) => {
  const { t } = useTranslation();

  if (item.readonly || item.permission === "read") {
    return (
      <Tooltip title={t("common:readOnly")}>
        <Chip sx={sx} label={t("common:readOnly")} />
      </Tooltip>
    );
  }
  return <></>;
};

export default OverviewBrickReadonly;
