export interface RowPersonGroupDataCategoryDataType {
  readonly personGroupName: string;
  readonly dataCategoryName: string;
  readonly dataTypeName: string;
}

export const parseDataTypeCategoryPersonGroupLine = (input: string): RowPersonGroupDataCategoryDataType => {
  const defaultReturn: RowPersonGroupDataCategoryDataType = {
    dataTypeName: input,
    dataCategoryName: "uncategorized",
    personGroupName: "uncategorized"
  };
  const noDcOrPG = !input.includes("[");
  if (noDcOrPG) {
    return defaultReturn;
  }

  const insideBrackets = extractInsideBrackets(input);
  if (insideBrackets.length === 0) {
    return defaultReturn;
  }

  const dataCategoryNameIfExists = insideBrackets[insideBrackets.length - 2]?.trim();
  const personGroupNameIfExists = insideBrackets[insideBrackets.length - 1]?.trim();
  const dataTypeName = input.split(`[${dataCategoryNameIfExists || personGroupNameIfExists}]`)[0]?.trim();
  return {
    dataTypeName: dataTypeName,
    dataCategoryName: dataCategoryNameIfExists ? dataCategoryNameIfExists : "uncategorized",
    personGroupName: personGroupNameIfExists ? personGroupNameIfExists : "uncategorized"
  };
};

const bracketsExtractor = /\[(.*?)]/g;

export const extractInsideBrackets = (input: string): string[] => {
  const regexExtractResults = input.match(bracketsExtractor);
  return regexExtractResults?.map(it => it.slice(1, -1)) || [];
};
