import React, { useMemo } from "react";
import { useMetaView } from "app/contexts/meta-view-context";
import ArrayDisplay from "components/ArrayDisplay";
import InfoCard, { InfoCardType } from "components/InfoCard/InfoCard";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import colors from "theme/palette/colors";
import { Box, Typography } from "@mui/material";

const sx = {
  root: { display: "flex", flexDirection: "column", gap: "12px", minHeight: "0px" },
  label: {
    fontSize: "12px",
    marginBottom: "4px",
    color: colors.grey.grey500
  },
  value: {
    fontSize: "14px"
  }
};

interface MetaViewAssistantTabProps {
  readonly translationKey?: string;
  readonly editable?: boolean;
}

const MetaViewAssistantTab = ({ translationKey, editable }: MetaViewAssistantTabProps) => {
  const { info, meta, insight } = useMetaView();
  const { t, i18n } = useTranslation(translationKey);

  const infoEl = useMemo(() => {
    if (!meta && info) {
      return (
        <InfoCard
          editable={editable}
          infoCardType={InfoCardType.INFO}
          infoCardHeader={info?.title}
          infoCardContent={info?.text}
        />
      );
    } else return <></>;
  }, [info, meta, editable]);

  const insightEl = useMemo(() => {
    if (insight?.title && insight?.text && !meta) {
      return (
        <InfoCard infoCardType={InfoCardType.TIPP} infoCardHeader={insight.title} infoCardContent={insight?.text} />
      );
    } else return <></>;
  }, [insight, meta]);

  const metaEl = useMemo(() => {
    if (meta) {
      const header = i18n.exists("metadata_headline")
        ? t("metadata_headline")
        : t("processes_overview:metadata_headline");
      if (meta.title && meta.text) {
        return (
          <InfoCard
            infoCardHeader={t(meta.title)}
            infoCardContent={
              <Box>{typeof meta.text === "string" ? parse(DOMPurify.sanitize(meta.text)) : meta.text}</Box>
            }
          />
        );
      } else {
        return (
          <InfoCard
            infoCardType={InfoCardType.DETAILS}
            infoCardHeader={header}
            infoCardContent={
              <Box>
                {Object.entries(meta).map(([key, value]) => (
                  <Box key={key} my={2}>
                    <Typography sx={sx.label}>{t(key)}</Typography>
                    <Typography sx={sx.value}>
                      {Array.isArray(value) ? (
                        <ArrayDisplay elements={value} />
                      ) : React.isValidElement(value) ? (
                        value
                      ) : (
                        <span>{parse(DOMPurify.sanitize(String(value)))}</span>
                      )}
                    </Typography>
                  </Box>
                ))}
              </Box>
            }
          />
        );
      }
    } else return <></>;
  }, [meta, i18n, t]);

  return (
    <Box sx={sx.root}>
      {infoEl}
      {insightEl}
      {metaEl}
    </Box>
  );
};

export default MetaViewAssistantTab;
