import Box from "@material-ui/core/Box";
import CheckMarkIcon from "../../../../assets/images/tasks/check-mark.svg";
import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import { ClickAwayListener, Menu, MenuItem, Tooltip, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PRIORITIES, updateTaskInformation } from "app/handlers/tasksHandler";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import { useSnackbar } from "notistack";
import stopEvent from "tool/stopEvent";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import OverviewBrickColorChip, { OverviewBrickColorChipColors } from "../common/OverviewBrickColorChip";
import { pick } from "lodash-es";

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    padding: "6px 10px",
    fontWeight: 600,
    height: theme.spacing(3),
    letterSpacing: "1px",
    fontSize: "10px",
    whiteSpace: "nowrap"
  },
  low: {
    color: "#466CF2",
    backgroundColor: theme.palette.blue[50]
  },
  medium: {
    color: "#BF871F",
    backgroundColor: "#FFF0D4"
  },
  high: {
    color: "#C81228",
    backgroundColor: "#FDE9E9"
  },
  highest: {
    color: theme.palette.common.white,
    backgroundColor: "#F17A79"
  },
  done: {
    color: "#6C6C6C",
    backgroundColor: "#eeeeee"
  },
  menuItem: {
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  menuPaper: {
    overflow: "visible",
    boxShadow: "0px 2px 5px 0px #00000033",
    "&::before": {
      display: "block",
      content: '""',
      position: "absolute",
      top: -8,
      left: "28%",
      width: "16px",
      height: "16px",
      backgroundColor: theme.palette.background.paper,
      transform: "translate(-50%, 0) rotate(45deg)",
      boxShadow: "0px -3px 5px -3px #00000033",
      borderRadius: "2px"
    }
  }
}));

const chipColorMapper: Record<"low" | "medium" | "high" | "highest", OverviewBrickColorChipColors> = {
  low: "blue",
  medium: "orange",
  high: "pink",
  highest: "red"
};

interface TaskOverviewBrickPriorityMenuItemProps {
  readonly onChange: (priority: string, event: MouseEventHandler<HTMLLIElement> | undefined) => void;
  readonly priority: "low" | "medium" | "high" | "highest";
  readonly readOnly?: boolean;
  readonly selected: boolean;
}
const TaskOverviewBrickPriorityMenuItem = ({
  selected,
  priority,
  onChange,
  readOnly
}: TaskOverviewBrickPriorityMenuItemProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");

  const onClickCallback = useCallback(
    event => {
      stopEvent(event);
      onChange(priority, event);
    },
    [onChange, priority]
  );
  return (
    <MenuItem onClick={onClickCallback}>
      <Box className={`${cls.root} ${cls[priority]}`} style={!readOnly ? { cursor: "pointer" } : { cursor: "default" }}>
        <Box mx={1} flex={1}>
          {t("priority_" + priority)}
        </Box>
      </Box>
      <Box flex={1} mx={1} />
      <Box minWidth={6}>{selected ? <CheckMarkIcon /> : <></>}</Box>
    </MenuItem>
  );
};

const TaskOverviewBrickPriority = ({ item, onBlur, hovered }: OverviewRowBrickProps) => {
  const { id, priority, status, readOnly } = item;
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { auth } = useAuthentication();
  const { tenantId, uid } = auth || { tenantId: "", uid: "" };
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useOverviewDispatch();

  const [ref, setRef] = useState<Element | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const reload = useCallback(
    (taskId?: string | null) => {
      const reload = {
        selectedId: taskId,
        shadowLoading: true,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now()
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.TASKS, reload });
    },
    [dispatch]
  );

  const done = useMemo(() => status === "DONE", [status]);

  const openMenuCallback = useCallback(
    event => {
      stopEvent(event);
      onBlur?.();
      if (readOnly) {
        return;
      }
      setRef(event?.currentTarget);
      setOpen(true);
    },
    [onBlur, readOnly]
  );

  const closeMenuCallback = useCallback(() => {
    setRef(null);
    setOpen(false);
  }, []);

  const onChangePriorityCallback = useCallback(
    async (priority: string) => {
      closeMenuCallback();

      await updateTaskInformation(tenantId, uid, id || "", { priority }).catch(error => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
      reload();
    },
    [closeMenuCallback, enqueueSnackbar, id, reload, tenantId, uid]
  );

  if (!priority) {
    return <></>;
  }

  return (
    <ClickAwayListener onClickAway={closeMenuCallback}>
      <>
        <Tooltip title={t("changePriority")} disableHoverListener={readOnly}>
          <OverviewBrickColorChip
            color={
              done
                ? "grey"
                : (chipColorMapper[priority as "low" | "medium" | "high" | "highest"] as OverviewBrickColorChipColors)
            }
            label={t("priority_" + priority).toUpperCase()}
            hovered={hovered}
            tooltip={readOnly ? "" : t("changePriority")}
            onClick={openMenuCallback}
          />
          {/*
              <Box
            data-testid={`task-overview-row-priority`}
            className={`${cls.root} ${!done && cls[priority as "low" | "medium" | "high" | "highest"]} ${done && cls.done}`}
            onClick={openMenuCallback}
          >
            */}
        </Tooltip>

        <Menu
          open={open}
          anchorEl={ref}
          onClose={closeMenuCallback}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 38,
            horizontal: -16
          }}
          classes={{ paper: cls.menuPaper }}
        >
          {Object.values(PRIORITIES).map((value: string, index: number) => (
            <TaskOverviewBrickPriorityMenuItem
              key={index}
              onChange={onChangePriorityCallback}
              priority={value as "low" | "medium" | "high" | "highest"}
              readOnly={readOnly}
              selected={value === priority}
            />
          ))}
        </Menu>
      </>
    </ClickAwayListener>
  );
};

export default TaskOverviewBrickPriority;
