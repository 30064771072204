import React, { useCallback, useMemo, useState } from "react";
import { TaskStatus } from "app/api/taskApi";
import ToDoIcon from "../../../../assets/images/tasks/status/to-do.svg";
import InProgressIcon from "../../../../assets/images/tasks/status/in-progress.svg";
import CompletedIcon from "../../../../assets/images/tasks/status/completed.svg";
import CanceledIcon from "../../../../assets/images/tasks/status/canceled.svg";
import ActiveIcon from "../../../../assets/images/tasks/status/active.svg";
import InActiveIcon from "../../../../assets/images/tasks/status/inactive.svg";
import { Box, ClickAwayListener, Typography, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import { useSnackbar } from "notistack";
import { activateRecurringTask, deactivateRecurringTask, updateTaskInformation } from "app/handlers/tasksHandler";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import { updateGroupTaskApi } from "app/api/groupTaskApi";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import stopEvent from "tool/stopEvent";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

const defaultStatusIcons: Record<TaskStatus, React.ReactNode> = {
  TODO: <ToDoIcon />,
  OPEN: <InProgressIcon />,
  DONE: <CompletedIcon />,
  DELETED: <CanceledIcon />
};

const recurringStatusIcons: Record<"ACTIVE" | "INACTIVE", React.ReactNode> = {
  ACTIVE: <ActiveIcon />,
  INACTIVE: <InActiveIcon />
};

const sx = {
  cursor: "pointer",
  display: "flex",
  alignItems: "center"
};

const TaskOverviewBrickStatus = ({ item, onBlur }: OverviewRowBrickProps) => {
  const { id, detailType, status, showStatusText } = item;
  const { t } = useTranslation("task_details");
  const [ref, setRef] = useState<Element | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useOverviewDispatch();
  const { auth } = useAuthentication();

  const { tenantId, uid } = auth || { tenantId: "", uid: "" };

  const reload = useCallback(
    (taskId?: string | null) => {
      const reload = {
        selectedId: taskId,
        shadowLoading: true,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now()
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.TASKS, reload });
    },
    [dispatch]
  );

  const getCurrentPage = () => {
    if (window.location.pathname.indexOf("other") > -1) return "other";
    else return "my";
  };

  const openMenuCallback = useCallback(
    event => {
      stopEvent(event);
      onBlur?.();
      setRef(event?.currentTarget);
      setOpen(true);
    },
    [onBlur]
  );

  const closeMenuCallback = useCallback(() => {
    setRef(null);
    setOpen(false);
  }, []);

  /* STATUS */
  const onChangeStatusCallback = useCallback(
    async status => {
      closeMenuCallback();

      const endsWith = window.location.pathname.endsWith(id || "");
      if (detailType === "RECURRING") {
        if (status === "ACTIVE") {
          await activateRecurringTask(id || "");
        } else {
          await deactivateRecurringTask(id || "");
        }

        reload();
      } else if (detailType === "GROUP") {
        await updateGroupTaskApi(id || "", { status }).catch(error => {
          enqueueSnackbar(error.message, { variant: "error" });
        });

        if (status === "DONE") {
          enqueueSnackbar(t("taskSuccessfullyCompleted"), { variant: "success" });
          reload(endsWith ? null : undefined);
          if (endsWith) {
            window.history.pushState("", "", `/tasks/${getCurrentPage()}`);
          }
        } else {
          reload();
        }
      } else {
        await updateTaskInformation(tenantId, uid, id || "", { status }).catch(error => {
          enqueueSnackbar(error.message, { variant: "error" });
        });

        if (status === "DONE") {
          enqueueSnackbar(t("taskSuccessfullyCompleted"), { variant: "success" });
          reload(endsWith ? null : undefined);
          if (endsWith) {
            window.history.pushState("", "", `/tasks/${getCurrentPage()}`);
          }
        } else {
          reload();
        }
      }
    },
    [closeMenuCallback, detailType, enqueueSnackbar, id, reload, t, tenantId, uid]
  );

  const statusText = useMemo(() => {
    if (!showStatusText) {
      return "";
    }
    return status ? t("status_" + status) : t("status");
  }, [showStatusText, status, t]);

  const statusMenuItems = useMemo(() => {
    return Object.keys(detailType === "RECURRING" ? recurringStatusIcons : defaultStatusIcons)
      .filter(key => key !== "DELETED")
      .map(key => ({
        id: key,
        label: t("status_" + key),
        icon:
          detailType === "RECURRING"
            ? recurringStatusIcons[key as "ACTIVE" | "INACTIVE"]
            : defaultStatusIcons[key as TaskStatus],
        selected: key === status
      }));
  }, [detailType, status, t]);

  return (
    <ClickAwayListener onClickAway={closeMenuCallback}>
      <>
        <Tooltip title={t("status_" + status)}>
          <Box sx={sx} onClick={openMenuCallback} data-testid={`overview-row-task-status`}>
            {detailType === "RECURRING"
              ? recurringStatusIcons[status as "ACTIVE" | "INACTIVE"]
              : defaultStatusIcons[status as TaskStatus]}
            {showStatusText && (
              <Box ml={1}>
                <Typography>{statusText}</Typography>
              </Box>
            )}
          </Box>
        </Tooltip>
        <DropDownMenu
          open={open}
          anchorEl={ref}
          items={statusMenuItems}
          onChange={onChangeStatusCallback}
          onClose={closeMenuCallback}
        />
      </>
    </ClickAwayListener>
  );
};

export default TaskOverviewBrickStatus;
