import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../../Question";
import { useDataTypeTree, useDataTypeTreeManager } from "app/api/dataAssetApi";
import { useTranslation } from "react-i18next";
import { DataCategory, DataType } from "app/api/generated/asset-service";
import { Autocomplete, Box, Checkbox, Chip, TextField, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import colors from "theme/palette/colors";
import {
  tDataCategoryClassification,
  tDataTypeClassification,
  tDataTypeClassificationCategory
} from "app/handlers/dataTypeTranslatorHandler";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useResources } from "app/contexts/resource-context";
import { toLegacyCategoryTypeTuple } from "app/pages/questionnaires/utils/helper-functions";
import { debounce, isArray } from "lodash-es";
import stopEvent from "tool/stopEvent";
import { getBoldSearchText } from "tool/search";
import { naturalSortBy } from "app/utils/naturalSort";
import { CategoryTypeTupleModel } from "app/pages/questionnaires/CategoryTypeTupleModel";

const NEW_DATA_TYPE_ID = "new-data-type-id";

const sx = {
  popper: {
    background: colors.white,
    width: 400,
    maxHeight: 400,
    overflow: "auto",
    zIndex: 300
  },
  dataCategory: {
    cursor: "pointer",
    padding: "4px 8px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: colors.grey.grey100
    }
  },
  dataType: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "4px 0px 4px 40px",
    "&:hover": {
      background: colors.grey.grey100
    }
  }
};

interface DataTypeTagProps {
  readonly dataType: DataType;
  readonly personGroupId: string;
  readonly dataCategoryId: string;
  readonly onDelete: (id: string) => void;
}
const DataTypeTag = ({ dataType, personGroupId, dataCategoryId, onDelete }: DataTypeTagProps) => {
  const { t } = useTranslation("questionnaires");
  const dataTypeTree = useDataTypeTreeManager(true);
  const { resources } = useResources();

  const onDeleteCallback = useCallback(() => {
    onDelete(dataType.id);
  }, [dataType.id, onDelete]);

  const title = useMemo(() => {
    const categoryTypeTupleModel: CategoryTypeTupleModel = {
      id: dataType.id,
      type: dataType.id,
      personGroup: personGroupId,
      category: dataCategoryId,
      dataClassificationId: null
    };

    return tDataTypeClassificationCategory({
      t,
      dataType: toLegacyCategoryTypeTuple(categoryTypeTupleModel, dataTypeTree.dataById),
      classifications: resources[RESOURCE_TYPES.DATA_CLASSIFICATION]
    });
  }, [dataCategoryId, dataType.id, dataTypeTree.dataById, personGroupId, resources, t]);

  return <Chip sx={{ mr: 1, mb: 1 }} label={title} onDelete={onDeleteCallback} />;
};

interface NewDataTypeRowProps {
  readonly title: string;
  readonly onClick: (title: string) => void;
}
const NewDataTypeRow = ({ title, onClick }: NewDataTypeRowProps) => {
  const { t } = useTranslation();
  const onClickCallback = useCallback(
    event => {
      stopEvent(event);
      onClick(title);
    },
    [onClick, title]
  );
  return (
    <Box sx={sx.dataType} onClick={onClickCallback}>
      <Checkbox size="small" checked={false} />
      <Typography
        noWrap
      >{`${t("resources_lists_data_types_categories_person_groups:add_data_type_placeholder")} "${title}"`}</Typography>
    </Box>
  );
};

interface DataTypeRowProps {
  readonly dataType: DataType;
  readonly selectedIds: string[];
  readonly search: string;
  readonly dataTypeNameMap: Record<string, string>;
  readonly onChecked: (id: string) => void;
}
const DataTypeRow = ({ dataType, selectedIds, search, dataTypeNameMap, onChecked }: DataTypeRowProps) => {
  const onCheckedCallback = useCallback(
    event => {
      stopEvent(event);
      onChecked(dataType.id);
    },
    [dataType.id, onChecked]
  );

  const checked = useMemo<boolean>(() => {
    return selectedIds.some(id => id === dataType.id);
  }, [dataType.id, selectedIds]);

  const title = useMemo<string>(() => {
    return dataTypeNameMap[dataType.id || ""];
  }, [dataType.id, dataTypeNameMap]);
  return (
    <Box sx={sx.dataType} onClick={onCheckedCallback}>
      <Checkbox size="small" checked={checked} />
      <Typography noWrap>{getBoldSearchText({ title, search })}</Typography>
    </Box>
  );
};

interface DataCategoryRowProps {
  readonly dataCategory: DataCategory;
  readonly selectedIds: string[];
  readonly search: string;
  readonly dataTypeNameMap: Record<string, string>;
  readonly dataCategoryNameMap: Record<string, string>;
  readonly onCheckedDataType: (id: string) => void;
  readonly onCheckedDataCategory: (id: string, checked: boolean) => void;
}
const DataCategoryRow = ({
  dataCategory,
  selectedIds,
  search,
  dataTypeNameMap,
  dataCategoryNameMap,
  onCheckedDataType,
  onCheckedDataCategory
}: DataCategoryRowProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (search !== "") {
      setExpanded(true);
    }
  }, [search]);

  const checked = useMemo(
    () => Boolean(dataCategory.dataTypes.length && dataCategory.dataTypes.every(({ id }) => selectedIds.includes(id))),
    [dataCategory.dataTypes, selectedIds]
  );

  const indeterminate = useMemo(
    () => !checked && dataCategory.dataTypes.some(({ id }) => selectedIds.includes(id)),
    [checked, dataCategory.dataTypes, selectedIds]
  );

  const onCheckedDataCategoryCallback = useCallback(
    event => {
      stopEvent(event);
      onCheckedDataCategory(dataCategory.id || "", !checked);
    },
    [onCheckedDataCategory, dataCategory.id, checked]
  );

  const onRowClick = useCallback(() => {
    setExpanded(current => !current);
  }, []);

  const shevronEl = useMemo(() => {
    return expanded ? <ExpandMoreIcon /> : <ChevronRight />;
  }, [expanded]);

  const checkboxEl = useMemo(
    () => (
      <Checkbox size="small" checked={checked} indeterminate={indeterminate} onClick={onCheckedDataCategoryCallback} />
    ),
    [checked, indeterminate, onCheckedDataCategoryCallback]
  );

  const title = useMemo(() => dataCategoryNameMap[dataCategory.id || ""], [dataCategory.id, dataCategoryNameMap]);

  const lowercaseSearch = useMemo(() => search.toLowerCase(), [search]);

  const titleEl = useMemo(() => {
    return (
      <Typography noWrap sx={{ flex: 1 }}>
        {getBoldSearchText({ title, search })}
      </Typography>
    );
  }, [search, title]);

  const childrenEl = useMemo(() => {
    if (!expanded) return <></>;
    return (
      <>
        {naturalSortBy(dataCategory.dataTypes, [it => dataTypeNameMap[it.id || ""]])
          .filter(dataType => dataTypeNameMap[dataType.id || ""].toLowerCase().indexOf(lowercaseSearch) > -1)
          .map(dataType => (
            <DataTypeRow
              key={dataType.id}
              search={search}
              dataType={dataType}
              selectedIds={selectedIds}
              dataTypeNameMap={dataTypeNameMap}
              onChecked={onCheckedDataType}
            />
          ))}
      </>
    );
  }, [expanded, search, lowercaseSearch, dataCategory.dataTypes, dataTypeNameMap, selectedIds, onCheckedDataType]);

  return (
    <>
      <Box sx={sx.dataCategory} onClick={onRowClick}>
        {shevronEl}
        {checkboxEl}
        {titleEl}
      </Box>
      {childrenEl}
    </>
  );
};

const DataTypeTreeQuestion = ({
  questionName,
  value,
  disabled,
  multiSelectHiddenIds,
  personGroupId,
  error,
  helperText,
  onChange,
  onAdd
}: QuestionProps) => {
  const { t } = useTranslation("dataCategory");
  const { dataById } = useDataTypeTree();
  const dataTypeTree = useDataTypeTreeManager(true);
  const { resources } = useResources();

  const _value = useMemo(() => (isArray(value) ? value : [value]) as string[], [value]);

  const [search, setSearch] = useState<string>("");
  const [_search, _setSearch] = useState<string>("");

  const dataTypeNameMap = useMemo(() => {
    if (dataById && personGroupId && dataById.personGroups[personGroupId]) {
      return (dataById?.personGroups[personGroupId]?.dataCategories || []).reduce<Record<string, string>>(
        (acc, next) => {
          const res = next.dataTypes.reduce<Record<string, string>>((acc, next) => {
            const title = tDataTypeClassification({
              t,
              dataType: toLegacyCategoryTypeTuple(next, dataTypeTree.dataById),
              classifications: resources[RESOURCE_TYPES.DATA_CLASSIFICATION]
            });
            return { ...acc, [next.id]: title };
          }, {});

          return { ...acc, ...res };
        },
        {}
      );
    } else return {};
  }, [dataById, dataTypeTree.dataById, personGroupId, resources, t]);

  const dataCategoryMap = useMemo(() => {
    if (dataById && personGroupId && dataById.personGroups[personGroupId]) {
      return (dataById.personGroups[personGroupId].dataCategories || []).reduce<Record<string, DataCategory>>(
        (acc, next) => ({ ...acc, [next.id || ""]: next }),
        {}
      );
    } else return {};
  }, [dataById, personGroupId]);

  const dataCategoryNameMap = useMemo(() => {
    if (dataById && personGroupId && dataById?.personGroups[personGroupId]) {
      return (dataById.personGroups[personGroupId].dataCategories || []).reduce<Record<string, string>>(
        (acc, next) => ({
          ...acc,
          [next.id || ""]: tDataCategoryClassification({
            t,
            dataType: next,
            classifications: resources[RESOURCE_TYPES.DATA_CLASSIFICATION],
            dataTypeTree: dataTypeTree?.data
          })
        }),
        {}
      );
    } else return {};
  }, [dataById, dataTypeTree?.data, personGroupId, resources, t]);

  const dataCategoryOptionIds = useMemo(() => {
    if (dataById && personGroupId && dataById.personGroups[personGroupId]) {
      return naturalSortBy(dataById.personGroups[personGroupId].dataCategories || [], [
        it => dataCategoryNameMap[it.id || ""]
      ])
        .map(({ id }) => id)
        .filter(id => id && !(multiSelectHiddenIds || []).includes(id)) as string[];
    } else return [];
  }, [dataById, dataCategoryNameMap, multiSelectHiddenIds, personGroupId]);

  const onCheckedDataTypeCallback = useCallback(
    (id: string) => {
      if (_value.includes(id)) {
        onChange?.(_value.filter(_id => _id !== id));
      } else {
        onChange?.([..._value, id]);
      }
    },
    [_value, onChange]
  );

  const onCheckedDataCategoryCallback = useCallback(
    (id: string, checked: boolean) => {
      const ids = dataById?.dataCategories[id].dataTypes.map(({ id }) => id).filter(notNull => notNull) as string[];
      if (checked) {
        onChange?.([...new Set([..._value, ...ids])]);
      } else {
        onChange?.(_value.filter(id => !ids.includes(id)));
      }
    },
    [_value, dataById?.dataCategories, onChange]
  );

  const debouncedSearch = useMemo(
    () =>
      debounce(val => {
        _setSearch(val.toLowerCase().trim());
      }, 1000),
    []
  );
  const onSearchChange = useCallback(
    event => {
      setSearch(event.target.value);
      debouncedSearch(event.target.value);
    },
    [debouncedSearch]
  );
  const onDeleteChipCallback = useCallback(
    (id: string) => {
      onChange?.(_value.filter(_id => _id !== id));
    },
    [_value, onChange]
  );
  const renderTagsCallback = useCallback(() => {
    const tags = _value.map(id => {
      const dataType = dataById?.dataTypes[id];
      if (!dataType) return <></>;

      const category = Object.values(dataTypeTree.dataById?.dataCategories || {}).find(dataCategory =>
        dataCategory.dataTypes.find(({ id }) => id === dataType.id)
      );

      if (!category || !category.id) return <></>;
      return (
        <DataTypeTag
          key={dataType.id}
          dataType={dataType}
          personGroupId={personGroupId || ""}
          dataCategoryId={category.id}
          onDelete={onDeleteChipCallback}
        />
      );
    });

    return <>{tags}</>;
  }, [_value, dataById?.dataTypes, dataTypeTree.dataById?.dataCategories, onDeleteChipCallback, personGroupId]);
  const onAddNewDataType = useCallback(
    (title: string) => {
      setSearch("");
      _setSearch("");
      onAdd?.({ title });
    },
    [onAdd]
  );
  const renderOptionCallback = useCallback(
    (props, id: string) => {
      if (id === NEW_DATA_TYPE_ID) {
        return <NewDataTypeRow title={search} onClick={onAddNewDataType} />;
      }
      return (
        <DataCategoryRow
          key={id}
          dataCategory={dataCategoryMap[id]}
          selectedIds={_value || []}
          search={search}
          dataCategoryNameMap={dataCategoryNameMap}
          dataTypeNameMap={dataTypeNameMap}
          onCheckedDataType={onCheckedDataTypeCallback}
          onCheckedDataCategory={onCheckedDataCategoryCallback}
        />
      );
    },

    [
      _value,
      dataCategoryMap,
      dataCategoryNameMap,
      dataTypeNameMap,
      onAddNewDataType,
      onCheckedDataCategoryCallback,
      onCheckedDataTypeCallback,
      search
    ]
  );
  const renderInputCallback = useCallback(
    params => (
      <TextField
        error={error}
        helperText={helperText}
        {...params}
        onChange={onSearchChange}
        label={questionName || t("questionnaires:dataCategoryDataTypeLabel")}
        placeholder={t("common:search")}
      />
    ),
    [error, helperText, onSearchChange, questionName, t]
  );
  const filterOptionsCallback = useCallback(
    (ids: string[]) => {
      const res = ids.filter((id: string) => {
        if (_search === "") return true;
        if (dataCategoryNameMap[id].toLowerCase().indexOf(_search) > -1) return true;
        if (
          dataCategoryMap[id].dataTypes.some(
            dataType => dataTypeNameMap[dataType.id].toLowerCase().indexOf(_search) > -1
          )
        )
          return true;
        return false;
      });

      if (res.length > 0) return res;
      else return [NEW_DATA_TYPE_ID];
    },
    [_search, dataCategoryMap, dataCategoryNameMap, dataTypeNameMap]
  );
  const onChangeCallback = useCallback(
    (event, ids: string[]) => {
      onChange?.(ids);
    },
    [onChange]
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      freeSolo
      clearOnEscape={false}
      onChange={onChangeCallback}
      options={dataCategoryOptionIds}
      filterOptions={filterOptionsCallback}
      disabled={disabled}
      value={_value}
      disableCloseOnSelect
      inputValue={search}
      title={questionName || t("dataCategoryDataTypeLabel")}
      renderTags={renderTagsCallback}
      renderOption={renderOptionCallback}
      renderInput={renderInputCallback}
    />
  );
};

export default DataTypeTreeQuestion;
