import { IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import { GetApp } from "@material-ui/icons";
import { downloadDocumentFileApi } from "app/api/file-storage/documentApi";
import { useSnackbar } from "notistack";
import { t } from "i18next";
import stopEvent from "tool/stopEvent";

const DocumentOverviewBrickDownload = ({ item, hovered }: OverviewRowBrickProps) => {
  const isFolderItem = !item.fileType;

  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadClick = useCallback(
    async event => {
      stopEvent(event);
      if (!item.path || !item.fileType) {
        enqueueSnackbar("Invalid item for download", { variant: "error" });
        return;
      }
      try {
        await downloadDocumentFileApi({ filePath: item.path });
      } catch (error) {
        enqueueSnackbar("Failed to download file", { variant: "error" });
      }
    },
    [enqueueSnackbar, item.fileType, item.path]
  );

  if (!hovered || isFolderItem) {
    return <></>;
  }
  return (
    <Tooltip title={t("common:download")}>
      <IconButton onClick={handleDownloadClick} size="small">
        <GetApp />
      </IconButton>
    </Tooltip>
  );
};

export default DocumentOverviewBrickDownload;
