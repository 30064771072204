import { Box, Button, debounce, Typography } from "@mui/material";
import { ExternalRecipientOverviewItemDTO, usePaginatedExternalRecipientsApi } from "app/api/externalRecipientApi";
import { COLLECTIONS } from "app/collections";
import { useExternalRecipients } from "app/contexts/external-recipient-context";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useDataLocations } from "hook/useDataLocations";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import colors from "theme/palette/colors";
import { getBoldSearchText } from "tool/search";

const sort = [{ title: "asc" }];
const limit = 20;

const sx = {
  root: {
    maxHeight: "300px",
    overflow: "auto"
  },
  row: {
    borderBottom: `1px solid ${colors.divider}`,
    cursor: "pointer",
    padding: "8px 16px",
    alignItems: "center",
    "&:hover": {
      background: colors.grey.grey100
    }
  },
  title: {
    ml: 0.5,
    overflow: "hidden"
  },
  subTitle: {
    minWidth: 0,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "block",
    color: colors.grey.grey500,
    fontSize: "12px"
  }
};

interface ExternalRecipientRowProps {
  readonly item: ExternalRecipientOverviewItemDTO;
  readonly search: string;
  readonly onClick: (id: string) => void;
}
const ExternalRecipientRow = ({ item, search, onClick }: ExternalRecipientRowProps) => {
  const { id, title } = item;
  const onClickCallback = useCallback(() => {
    onClick(id);
  }, [id, onClick]);
  return (
    <Box sx={sx.row} onClick={onClickCallback}>
      <Typography variant="body2">{getBoldSearchText({ title, search })}</Typography>
      <Typography noWrap sx={sx.subTitle} variant="caption">
        {item.subTitle}
      </Typography>
    </Box>
  );
};

interface ExternalRecipientsListProps {
  readonly search: string;
  readonly blackListedIds?: string[];
  readonly allowAdd?: boolean;
  readonly onSelect: (id: string) => void;
  readonly onAdd: (id: string) => void;
}

const ExternalRecipientsList = ({ search, blackListedIds, allowAdd, onAdd, onSelect }: ExternalRecipientsListProps) => {
  const { t } = useTranslation("externalRecipientsPickerModal");
  const { loadSeenItemsOfUserHook } = useUserAndTenantData();
  const { dataLocationsReload } = useDataLocations();
  const { t: tErrorMessages } = useTranslation("error_messages");
  const { enqueueSnackbar } = useSnackbar();
  const { createExternalRecipientHook } = useExternalRecipients();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();

  const allERs = usePaginatedExternalRecipientsApi({
    limit,
    sort,
    filter: {
      title: {
        contains: search
      },
      ...(blackListedIds && blackListedIds.length ? { id: { notIn: blackListedIds } } : {})
    }
  });

  const onScroll = useCallback(
    event => {
      const target = event.target;
      if (target.scrollHeight - target.scrollTop - target.clientHeight < 10) {
        allERs.loadMore();
      }
    },
    [allERs]
  );
  const debouncedOnScroll = useMemo(() => debounce(onScroll, 100), [onScroll]);
  const onDebouncedOnScrollCallback = useCallback(
    event => {
      event.persist();
      debouncedOnScroll(event);
    },
    [debouncedOnScroll]
  );

  const onAddCallback = useCallback(async () => {
    try {
      const newItemId = await createExternalRecipientHook(
        {
          name: search,
          serviceType: "SERVICEPROVIDER"
        },
        false
      );

      if (newItemId) {
        await addToSeenItemsOfUserHook(COLLECTIONS.EXTERNAL_RECIPIENTS, newItemId);
        await loadSeenItemsOfUserHook();
        await dataLocationsReload();

        onAdd?.(newItemId);
      }
    } catch (error: any) {
      enqueueSnackbar(tErrorMessages(error?.message), { variant: "error" });
    }
  }, [
    addToSeenItemsOfUserHook,
    createExternalRecipientHook,
    dataLocationsReload,
    enqueueSnackbar,
    loadSeenItemsOfUserHook,
    onAdd,
    search,
    tErrorMessages
  ]);
  const addNewButtonEl = useMemo(() => {
    if (allowAdd && search && allERs.items.length === 0) {
      return (
        <Box textAlign={"center"} my={3}>
          <Button color="primary" variant="outlined" onClick={onAddCallback}>
            {t("addNewExternalRecipient")}
          </Button>
        </Box>
      );
    }

    return <></>;
  }, [allERs.items.length, allowAdd, onAddCallback, search, t]);

  return (
    <Box sx={sx.root} onScroll={onDebouncedOnScrollCallback}>
      {allERs.items.map(item => (
        <ExternalRecipientRow key={item.id} item={item} search={search} onClick={onSelect} />
      ))}
      {addNewButtonEl}
    </Box>
  );
};
export default ExternalRecipientsList;
