import { StepIconProps } from "@mui/material/StepIcon";
import React, { CSSProperties, useCallback } from "react";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useTranslation } from "react-i18next";
import { AutoAwesomeRounded, CircleOutlined } from "@mui/icons-material";
import colors from "../../theme/palette/colors";

export interface AIStepProps {
  readonly title: React.ReactNode;
  readonly subtitle?: React.ReactNode;
  readonly description?: React.ReactNode;
  readonly content?: React.ReactNode;
  readonly icon?: React.ElementType<StepIconProps>;
  readonly iconProps?: Partial<StepIconProps>;

  readonly onClick?: () => void;
}

export const AwesomePropIcon = (props: StepIconProps) => {
  const { className } = props;

  return <AutoAwesomeRounded className={className} color="primary" fontSize="small" />;
};

export const CircularProcessPropIcon = (props: StepIconProps) => {
  const { className } = props;

  return (
    <Box className={className} mx={0.5}>
      <CircularProgress size={16} />
    </Box>
  );
};

export const CircleOutlinedPropIcon = (props: StepIconProps) => {
  const { className } = props;

  return <CircleOutlined className={className} style={customGrayColor} fontSize="small" />;
};

const customGrayColor: CSSProperties = {
  color: colors.grey.grey400
};

export const InfoOutlinedPropIcon = (props: StepIconProps) => {
  const { className } = props;

  return <InfoOutlinedIcon className={className} style={customGrayColor} fontSize="small" />;
};

export const CheckCircleOutlinedPropIcon = (props: StepIconProps) => {
  const { className } = props;

  return <CheckCircleOutlineOutlinedIcon className={className} style={customGrayColor} fontSize="small" />;
};

export const noUppercase: CSSProperties = {
  textTransform: "none"
};

export const FeedbackInput = (props: {
  readonly value?: string;
  readonly placeholder?: string;
  readonly onClick?: () => void;
  readonly onChange?: (value: string) => void;
  readonly loading?: boolean;
}) => {
  const { placeholder, onClick, onChange, value } = props;
  const { t } = useTranslation();

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onClick?.();
      }
    },
    [onClick]
  );

  return (
    <Box>
      <Box>
        <TextField
          fullWidth={true}
          multiline={false}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          onKeyDown={onKeyDown}
          variant="outlined"
          color={"primary"}
          autoFocus={true}
          disabled={props.loading}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          size="small"
          variant="contained"
          onClick={onClick}
          style={noUppercase}
          disabled={props.loading || !value}
        >
          {props.loading && (
            <Box mr={1} mt={0.5}>
              <CircularProgress color="inherit" size={14} />
            </Box>
          )}
          {t("common:suggest")}
        </Button>
      </Box>
    </Box>
  );
};
