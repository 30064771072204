import useSWR from "swr";
import { AxiosInstance } from "axios";
import { defaultOTCAuthenticatedAxios } from "../../api/axios/loggedInAxiosProvider";
import { apiEndpoints } from "../../api/apiEndpoint";
import useSWRMutation from "swr/mutation";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useTranslation } from "react-i18next";
import Question from "../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../components/Question/QuestionTypes";
import isEqual from "lodash-es/isEqual";

export interface TenantIAMConfigProps {
  readonly tenantIAM: TenantIAMDTO;
  readonly onSave: (tenantIAM: TenantIAMDTO) => void;
  readonly onCancel: () => void;
}

export const TenantIAMConfig = ({ tenantIAM, onSave, onCancel }: TenantIAMConfigProps) => {
  const { t } = useTranslation("iamConfig");

  const [defaultRole, setDefaultRole] = useState<string | null>(null);
  const [defaultMainOrgUnitId, setDefaultMainOrgUnitId] = useState<string | null>(null);
  const [defaultFurtherOrgUnitIds, setDefaultFurtherOrgUnitIds] = useState<string[]>([]);
  const [groupIdMappingEnabled, setGroupIdMappingEnabled] = useState<boolean>(false);
  const [groupIdMappingCustomKeyForMain, setGroupIdMappingCustomKeyForMain] = useState<string | null>(null);
  const [groupIdMappingCustomKeyForFurther, setGroupIdMappingCustomKeyForFurther] = useState<string | null>(null);
  const [groupIdMappingFallbackMainOrgUnitId, setGroupIdMappingFallbackMainOrgUnitId] = useState<string | null>(null);
  const [groupIdMappingFallbackFurtherOrgUnitIds, setGroupIdMappingFallbackFurtherOrgUnitIds] = useState<string[]>([]);
  const [preserveFurtherOrgUnits, setPreserveFurtherOrgUnits] = useState<boolean>(false);

  useEffect(() => {
    setDefaultRole(tenantIAM.defaultRole);
    setDefaultMainOrgUnitId(tenantIAM.defaultMainOrgUnitId);
    setDefaultFurtherOrgUnitIds(tenantIAM.defaultFurtherOrgUnitIds);
    setGroupIdMappingEnabled(tenantIAM.groupIdMappingEnabled);
    setGroupIdMappingCustomKeyForMain(tenantIAM.groupIdMappingCustomKeyForMain);
    setGroupIdMappingCustomKeyForFurther(tenantIAM.groupIdMappingCustomKeyForFurther);
    setGroupIdMappingFallbackMainOrgUnitId(tenantIAM.groupIdMappingFallbackMainOrgUnitId);
    setGroupIdMappingFallbackFurtherOrgUnitIds(tenantIAM.groupIdMappingFallbackFurtherOrgUnitIds);
    setPreserveFurtherOrgUnits(tenantIAM.preserveFurtherOrgUnits ?? false);
  }, [
    tenantIAM.defaultFurtherOrgUnitIds,
    tenantIAM.defaultMainOrgUnitId,
    tenantIAM.defaultRole,
    tenantIAM.groupIdMappingCustomKeyForFurther,
    tenantIAM.groupIdMappingCustomKeyForMain,
    tenantIAM.groupIdMappingEnabled,
    tenantIAM.groupIdMappingFallbackFurtherOrgUnitIds,
    tenantIAM.groupIdMappingFallbackMainOrgUnitId,
    tenantIAM.preserveFurtherOrgUnits
  ]);

  const onSaveClicked = useCallback(() => {
    return onSave({
      tenantId: tenantIAM.tenantId,
      defaultRole,
      defaultMainOrgUnitId,
      defaultFurtherOrgUnitIds,
      groupIdMappingEnabled,
      groupIdMappingCustomKeyForMain,
      groupIdMappingCustomKeyForFurther,
      groupIdMappingFallbackMainOrgUnitId,
      groupIdMappingFallbackFurtherOrgUnitIds,
      preserveFurtherOrgUnits
    } satisfies TenantIAMDTO);
  }, [
    defaultFurtherOrgUnitIds,
    defaultMainOrgUnitId,
    defaultRole,
    groupIdMappingCustomKeyForFurther,
    groupIdMappingCustomKeyForMain,
    groupIdMappingEnabled,
    groupIdMappingFallbackFurtherOrgUnitIds,
    groupIdMappingFallbackMainOrgUnitId,
    onSave,
    preserveFurtherOrgUnits,
    tenantIAM.tenantId
  ]);

  const isDirty = useMemo(() => {
    return (
      defaultRole !== tenantIAM.defaultRole ||
      defaultMainOrgUnitId !== tenantIAM.defaultMainOrgUnitId ||
      !isEqual(defaultFurtherOrgUnitIds, tenantIAM.defaultFurtherOrgUnitIds) ||
      groupIdMappingEnabled !== tenantIAM.groupIdMappingEnabled ||
      groupIdMappingCustomKeyForMain !== tenantIAM.groupIdMappingCustomKeyForMain ||
      groupIdMappingCustomKeyForFurther !== tenantIAM.groupIdMappingCustomKeyForFurther ||
      groupIdMappingFallbackMainOrgUnitId !== tenantIAM.groupIdMappingFallbackMainOrgUnitId ||
      !isEqual(groupIdMappingFallbackFurtherOrgUnitIds, tenantIAM.groupIdMappingFallbackFurtherOrgUnitIds) ||
      preserveFurtherOrgUnits !== (tenantIAM.preserveFurtherOrgUnits ?? false)
    );
  }, [
    defaultFurtherOrgUnitIds,
    defaultMainOrgUnitId,
    defaultRole,
    groupIdMappingCustomKeyForFurther,
    groupIdMappingCustomKeyForMain,
    groupIdMappingEnabled,
    groupIdMappingFallbackFurtherOrgUnitIds,
    groupIdMappingFallbackMainOrgUnitId,
    preserveFurtherOrgUnits,
    tenantIAM.defaultFurtherOrgUnitIds,
    tenantIAM.defaultMainOrgUnitId,
    tenantIAM.defaultRole,
    tenantIAM.groupIdMappingCustomKeyForFurther,
    tenantIAM.groupIdMappingCustomKeyForMain,
    tenantIAM.groupIdMappingEnabled,
    tenantIAM.groupIdMappingFallbackFurtherOrgUnitIds,
    tenantIAM.groupIdMappingFallbackMainOrgUnitId,
    tenantIAM.preserveFurtherOrgUnits
  ]);

  const onPreserveFurtherOrgUnitsChange = useCallback((newValue: boolean) => {
    setPreserveFurtherOrgUnits(newValue);
  }, []);

  return (
    <Box>
      <QuestionnaireSubHeader text={t("basicSetting")} />
      <Box mt={-3} mb={2}>
        <Question
          qType={QUESTION_TYPE.ROLE}
          questionId={"defaultRole"}
          questionName={t("defaultRole")}
          value={defaultRole || ""}
          onChange={setDefaultRole}
        />
        <Question
          qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
          questionId={"defaultMainOrgUnitId"}
          questionName={t("defaultMainOrgUnitId")}
          value={defaultMainOrgUnitId || ""}
          onChange={setDefaultMainOrgUnitId}
          noDefaultValue={true}
        />
        <Question
          qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
          questionId={"defaultFurtherOrgUnitIds"}
          questionName={t("defaultFurtherOrgUnitIds")}
          value={defaultFurtherOrgUnitIds}
          onChange={setDefaultFurtherOrgUnitIds}
        />
      </Box>
      <QuestionnaireSubHeader text={t("mappingSetting")} />
      <Box mb={2}>
        <Question
          qType={QUESTION_TYPE.YES_NO}
          questionId={"groupIdMappingEnabled"}
          questionName={t("groupIdMappingEnabled")}
          title={t("groupIdMappingEnabled")}
          value={groupIdMappingEnabled}
          onChange={setGroupIdMappingEnabled}
        />
        {groupIdMappingEnabled ? (
          <Box mt={-3}>
            <Question
              qType={QUESTION_TYPE.TEXT_AREA}
              value={groupIdMappingCustomKeyForMain}
              questionName={t("groupIdMappingCustomKeyForMain")}
              onChange={setGroupIdMappingCustomKeyForMain}
            />
            <Question
              qType={QUESTION_TYPE.TEXT_AREA}
              value={groupIdMappingCustomKeyForFurther}
              questionName={t("groupIdMappingCustomKeyForFurther")}
              onChange={setGroupIdMappingCustomKeyForFurther}
            />
            <Question
              qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
              questionId={"groupIdMappingFallbackMainOrgUnitId"}
              questionName={t("groupIdMappingFallbackMainOrgUnitId")}
              value={groupIdMappingFallbackMainOrgUnitId || ""}
              onChange={setGroupIdMappingFallbackMainOrgUnitId}
              noDefaultValue={true}
            />
            <Question
              qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
              questionId={"groupIdMappingFallbackFurtherOrgUnitIds"}
              questionName={t("groupIdMappingFallbackFurtherOrgUnitIds")}
              value={groupIdMappingFallbackFurtherOrgUnitIds}
              onChange={setGroupIdMappingFallbackFurtherOrgUnitIds}
            />
            <Question
              qType={QUESTION_TYPE.YES_NO}
              questionId={"preserveFurtherOrgUnits"}
              questionName={t("preserveFurtherOrgUnits")}
              title={t("preserveFurtherOrgUnits")}
              value={preserveFurtherOrgUnits}
              onChange={onPreserveFurtherOrgUnitsChange}
              helperText={t("preserveFurtherOrgUnitsTooltip")}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Button onClick={onCancel} variant="text">
          {t("common:cancel")}
        </Button>
        <Button onClick={onSaveClicked} variant="contained" color="primary" disabled={!isDirty}>
          {t("common:save")}
        </Button>
      </Box>
    </Box>
  );
};

export const TenantIAMConfigWithSave = () => {
  const { data: tenantIAM, isLoading } = useTenantIAM();
  const { trigger: upsertIAM, isMutating: isUpsertingIAM } = useTenantIAMUpsert();

  const onSave = useCallback(
    (tenantIAM: TenantIAMDTO) => {
      const { tenantId, ...payload } = tenantIAM;
      upsertIAM(payload);
    },
    [upsertIAM]
  );

  const [componentId, setComponentId] = useState<string>(`${Date.now()}`);
  const onCancel = useCallback(() => {
    setComponentId(`${Date.now()}`);
  }, []);

  if (isLoading || !tenantIAM) {
    return <div>Loading...</div>;
  }

  if (isUpsertingIAM) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  return <TenantIAMConfig key={componentId} tenantIAM={tenantIAM} onSave={onSave} onCancel={onCancel} />;
};

const useTenantIAM = () => {
  return useSWR(
    "tenantIAM",
    async () => {
      const response = await axiosInstance.get<TenantIAMDTO>("/iams");
      return response.data;
    },
    {
      revalidateOnFocus: false
    }
  );
};

const useTenantIAMUpsert = () => {
  return useSWRMutation<TenantIAMDTO, any, string, UserUpdateTenantIAMDTO>("tenantIAM", async (key, options) => {
    const arg = options.arg;
    const response = await axiosInstance.post<TenantIAMDTO>("/iams", arg);
    return response.data;
  });
};

export interface TenantIAMDTO {
  readonly tenantId: string;
  readonly defaultRole: string | null;
  readonly defaultMainOrgUnitId: string | null;
  readonly defaultFurtherOrgUnitIds: string[];
  readonly groupIdMappingEnabled: boolean;
  readonly groupIdMappingCustomKeyForMain: string | null;
  readonly groupIdMappingCustomKeyForFurther: string | null;
  readonly groupIdMappingFallbackMainOrgUnitId: string | null;
  readonly groupIdMappingFallbackFurtherOrgUnitIds: string[];
  readonly preserveFurtherOrgUnits: boolean;
}

export type UserUpdateTenantIAMDTO = Omit<TenantIAMDTO, "tenantId">;

const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.userUrl}/api`
});
