export const toUTF8Base64 = (input: string) => {
  // we need unescape because it converts it to utf-8 which can then be used for base64 conversion
  // currently, there is no other way to generate utf-8 base64 encoded string in the browser
  // warning: the official decodeURI is not the same as unescape...
  // noinspection JSDeprecatedSymbols
  return window.btoa(unescape(encodeURIComponent(input)));
};

export const fromBase64ToBrowserBlob = (base64: string, mimeType: string) => {
  const byteCharacters = window.atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};
