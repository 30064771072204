import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import stopEvent from "../../../../tool/stopEvent";
import { OrganizationMergeModal } from "../../../../app/pages/organization/OrganizationMergeModal";
import { useCallback, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../../app/features";
import { Box } from "@material-ui/core";

const OrganizationOverviewBrickMerge = ({ item, hovered, checkedItems, onBlur }: OverviewRowBrickProps) => {
  const { t } = useTranslation("organisationManagement");

  const [selectedOrgUnitId, setSelectedOrgUnitId] = useState<string | null>(null);

  const onMergeAction = useCallback(
    event => {
      setSelectedOrgUnitId(item.id);
      stopEvent(event);
    },
    [item.id]
  );
  const onCancel = useCallback(() => {
    if (onBlur) {
      setSelectedOrgUnitId(null);
      onBlur();
    }
  }, [onBlur]);
  const onClose = useCallback(() => {
    if (onBlur) {
      setSelectedOrgUnitId(null);
      onBlur();
    }
  }, [onBlur]);

  const mergeFeatureEnabled = !useIsFeaturePresent(FEATURES.ORG_ACTION_MERGE_DISABLED);
  const itemHasNoMergeAction = item.disableActions?.find(action => action.action === "merge");
  const mergeDisabled = checkedItems && checkedItems?.length > 0;

  return (
    <>
      {hovered && !itemHasNoMergeAction && mergeFeatureEnabled && (
        <>
          <Tooltip title={mergeDisabled ? t("disableMergeTooltip") : t("common:merge")}>
            <Box>
              <IconButton disabled={mergeDisabled} onClick={onMergeAction} size="small">
                <CallMergeIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </>
      )}
      {selectedOrgUnitId && (
        <OrganizationMergeModal selectedOrgUnit={item} onCancel={onCancel} onClose={onClose} mode={"merge"} />
      )}
    </>
  );
};

export default OrganizationOverviewBrickMerge;
