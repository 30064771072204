import { AxiosError, AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { isAxiosErrorWithCode } from "./axios/axiosErrorHandler";
import { ANSWERSET_STATUS_TYPES, AUDIT_METHODOLOGY_TYPES } from "app/pages/audits/audit/AuditTypes";
import { AuditPayloadDTO, AuditQuestionWithAnswerAndRemarkDTO } from "./auditApi";
import { ExternalUserDTO } from "./user/userApi";

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.auditUrl}/api/assessments`
});

export interface AssessmentAnswersetWithAnswerDTO {
  readonly id: string;
  readonly type: ANSWERSET_TYPE;
  readonly title: string;
  readonly status: ANSWERSET_STATUS_TYPES;
  readonly auditId: string;
  readonly assigneeUID: string | null;
  readonly orgUnitId: string | null;
  readonly participantsUIDs: string[];
  readonly audit: AuditPayloadDTO;
  readonly questions: AuditQuestionWithAnswerAndRemarkDTO[];
  readonly createdBy: string;
  readonly updatedBy: string;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface AnswersetOverviewItem extends OverviewItem {
  status: ANSWERSET_STATUS_TYPES;
  updatedAt?: string;
}

export enum ANSWERSET_TYPE {
  INTERNAL = "internal",
  EXTERNAl = "external"
}

export interface AssessmentAnswerSet {
  readonly type: ANSWERSET_TYPE;
  readonly title: string;
  readonly assigneeUID: string;
  readonly orgUnitId?: string;
  readonly status?: string;
  readonly participantsUIDs?: string[];
}

export interface AssessmentAnswersetWithAnswerDTO {
  readonly id: string;
  readonly type: ANSWERSET_TYPE;
  readonly title: string;
  readonly status: ANSWERSET_STATUS_TYPES;
  readonly auditId: string;
  readonly assigneeUID: string | null;
  readonly orgUnitId: string | null;
  readonly participantsUIDs: string[];
  readonly audit: AuditPayloadDTO;
  readonly questions: AuditQuestionWithAnswerAndRemarkDTO[];
  readonly createdBy: string;
  readonly updatedBy: string;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface AssessmentAnswersetDTO {
  readonly id: string;
  readonly type: ANSWERSET_TYPE;
  readonly title: string;
  readonly status: ANSWERSET_STATUS_TYPES;
  readonly auditId: string;
  readonly auditMethodology: AUDIT_METHODOLOGY_TYPES;
  readonly assigneeUID: string | null;
  readonly orgUnitId: string | null;
  readonly participantsUIDs: string[];
  readonly createdBy: string;
  readonly updatedBy: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly auditMainOrgUnitId: string;
  readonly auditFurtherOrgUnitIds: string[];
}

export interface AssessmentAnswersetWithAnswerDTO {
  readonly id: string;
  readonly type: ANSWERSET_TYPE;
  readonly title: string;
  readonly status: ANSWERSET_STATUS_TYPES;
  readonly auditId: string;
  readonly assigneeUID: string | null;
  readonly orgUnitId: string | null;
  readonly participantsUIDs: string[];
  readonly audit: AuditPayloadDTO;
  readonly questions: AuditQuestionWithAnswerAndRemarkDTO[];
  readonly createdBy: string;
  readonly updatedBy: string;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface AssessmentAnswersetsDTO {
  readonly answersets: AssessmentAnswersetDTO[];
}

export const getAnswerSetsApi = async (input: {
  readonly auditId: string;
  readonly abortController?: AbortController;
}): Promise<AssessmentAnswersetDTO[] | null> => {
  try {
    const response = await axiosInstance.get<AssessmentAnswersetsDTO>(`/${input.auditId}/answersets`, {
      signal: input.abortController?.signal
    });
    return response.data.answersets;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const getSingleAnswerSetApi = async (input: {
  readonly auditId: string;
  readonly id: string;
}): Promise<AssessmentAnswersetWithAnswerDTO | null> => {
  try {
    const response = await axiosInstance.get<AssessmentAnswersetWithAnswerDTO | null>(
      `${input.auditId}/answerset/${input.id}`
    );
    return response.data || null;
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404, "NotFound")) {
      return null;
    }
    throw error;
  }
};

export const postAnswerSetApi = async (input: {
  readonly auditId: string;
  readonly payload: Partial<AssessmentAnswerSet>;
}): Promise<string> => {
  const response = await axiosInstance.post(`/${input.auditId}/answerset`, input.payload);
  return response.headers["x-resource-id"] || "";
};

export const postAnswerSetExternalUserAPi = async (input: {
  readonly auditId: string;
  readonly payload: ExternalUserDTO;
}): Promise<string> => {
  const response = await axiosInstance.post(`/${input.auditId}/answersets/invite`, input.payload);
  return response.headers["x-resource-id"] || "";
};

export const postContributorExternalUserAPi = async (input: {
  readonly auditId: string;
  readonly answersetId: string;
  readonly payload: ExternalUserDTO;
}): Promise<string> => {
  const { auditId, answersetId } = input;
  const response = await axiosInstance.post(`/${auditId}/answerset/${answersetId}/invite`, input.payload);
  return response.headers["x-resource-id"] || "";
};

export const patchAnswerSetApi = async (input: {
  readonly auditId: string;
  readonly id: string;
  readonly payload: Partial<AssessmentAnswerSet>;
}): Promise<string> => {
  const response = await axiosInstance.patch(`/${input.auditId}/answerset/${input.id}`, input.payload);
  return response.headers["x-resource-id"] || "";
};

export const deleteAnswerSetApi = async (input: {
  readonly auditId: string;
  readonly answerSetId: string;
  readonly abortController?: AbortController;
}): Promise<void> => {
  await axiosInstance.delete(`/${input.auditId}/answerset/${input.answerSetId}`, {
    signal: input.abortController?.signal
  });
};

export const postAnswerForAnswerSet = async (input: {
  readonly auditId: string;
  readonly answerSetId: string;
  readonly questionId: string;
  readonly payload: string | null;
  readonly abortController?: AbortController;
}): Promise<string> => {
  const response = await axiosInstance.post(
    `/${input.auditId}/answerset/${input.answerSetId}/questions/${input.questionId}/answers`,
    { value: input.payload },
    {
      signal: input.abortController?.signal
    }
  );
  return response.headers["x-resource-id"] || "";
};

export const postAnswersetRemark = async (input: {
  readonly auditId: string;
  readonly answersetId: string;
  readonly questionId: string;
  readonly payload: string | null;
  readonly abortController?: AbortController;
}): Promise<string> => {
  const response = await axiosInstance.post(
    `/${input.auditId}/answerset/${input.answersetId}/questions/${input.questionId}/remarks`,
    { value: input.payload },
    {
      signal: input.abortController?.signal
    }
  );
  return response.headers["x-resource-id"] || "";
};

export const deleteAnswersetRemark = async (input: {
  readonly auditId: string;
  readonly answersetId: string;
  readonly questionId: string;
  readonly abortController?: AbortController;
}) => {
  await axiosInstance.delete(`/${input.auditId}/answerset/${input.answersetId}/questions/${input.questionId}/remarks`, {
    signal: input.abortController?.signal
  });
};

export async function getAnswersetActionTasks(auditId?: string, answersetId?: string) {
  if (!auditId || !answersetId) {
    return null;
  }
  return await axiosInstance.get("/" + auditId + "/answerset/" + answersetId + "/actionTasks");
}

const getFileNameFromContentDispositionHeader = (header: string) => {
  return header.split(";")[1].split("=")[1].replace('"', "").replace('"', "");
};

export async function downloadAssessmentResponseReportWordDocumentFromAPI(
  auditId: string,
  answersetId: string,
  language: string
) {
  const response = await axiosInstance.get("/" + auditId + "/answerset/" + answersetId + "/reportDocument", {
    headers: { "Accept-Language": language },
    responseType: "blob"
  });
  return {
    data: new Blob([response.data]),
    fileName: getFileNameFromContentDispositionHeader(response.headers["content-disposition"])
  };
}

export const getAssessmentResponseExcelExport = async (auditId: string, assessmentResponseIds: string[]) => {
  try {
    const response = await axiosInstance.get("/export/assessmentResponses", {
      params: { auditId, assessmentResponseIds },
      responseType: "arraybuffer"
    });
    return new Blob([response.data]);
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response?.status === 400) {
      return null;
    }
    throw error;
  }
};
