import { Box, CircularProgress, Collapse, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { DefaultApi as AiBackendApi } from "../../app/api/generated/ai-service";
import { apiEndpoints } from "../../app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../app/api/axios/loggedInAxiosProvider";
import { useMetaView } from "../../app/contexts/meta-view-context";
import { COLLECTIONS } from "../../app/collections";
import {
  AIChoiceButtonProps,
  AIResultProps,
  AISpeakProps,
  AIUserInputProps,
  AllAIDialogItems,
  GenericAIDialogItem
} from "./AISpeak";
import TextField from "@mui/material/TextField";
import { FilledInputProps } from "@mui/material/FilledInput";
import { ArrowCircleUpOutlined } from "@mui/icons-material";
import { parse as bestEffortParse } from "best-effort-json-parser";
import { isAxiosErrorWithCode } from "../../app/api/axios/axiosErrorHandler";
import { isAxiosError } from "axios";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SuggestFieldMetaview = ({ onDone }: { onDone?: () => void }) => {
  const { questionId } = useMetaView();
  const { aiIntegrationFieldInput } = useMetaView();
  const [sessionId, setSessionId] = useState<string | null>(null);
  useEffect(() => {
    setSessionId(aiIntegrationFieldInput?.explicitSessionId || v4());
  }, [questionId, aiIntegrationFieldInput?.explicitSessionId]);

  if (!sessionId) {
    return <></>;
  }

  return <AISuggestField sessionId={sessionId} key={sessionId} onDone={onDone} />;
};

const AISuggestField = ({ sessionId, onDone }: { sessionId: string; onDone?: () => void }) => {
  const { t, i18n } = useTranslation("ai-metaview");
  const { aiIntegrationSuggestionCallback, aiIntegrationFieldInput } = useMetaView();

  const [feedbackInput, setFeedbackInput] = useState<string>("");
  const [feedbackInputLoading, setFeedbackInputLoading] = useState<boolean>(false);

  const [abortController, setAbortController] = useState(new AbortController());
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);
  const [partialResult, setPartialResult] = useState<Record<string, string>>({});

  const triggerFeedbackInputSubmission = useCallback(
    async (input: string) => {
      try {
        setFeedbackInputLoading(true);
        setPartialResult({});
        let lastParseMs = Date.now();
        let progressStarted = false;
        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        const response = await aiClient.suggestFields(
          COLLECTIONS.PROCESSES,
          sessionId,
          {
            prompt: input || "",
            generateFields: aiIntegrationFieldInput?.fields || [],
            tenantContext: aiIntegrationFieldInput?.tenantContext || undefined
          },
          true,
          {
            timeout: 0,
            headers: {
              "Accept-Language": i18n.language
            },
            signal: newAbortController.signal,
            onDownloadProgress: evt => {
              const currentMs = Date.now();
              const passedTimeMs = currentMs - lastParseMs;
              if (passedTimeMs < 50) {
                return;
              }
              lastParseMs = currentMs;
              const currentResponse = evt.event.target.responseText;
              const bestEffortData = bestEffortParse(currentResponse) as Record<string, string>;
              if (typeof bestEffortData === "object" && Object.entries(bestEffortData).length > 0) {
                if (!progressStarted) {
                  progressStarted = true;
                  setDialogItems(prev => {
                    return [
                      ...prev,
                      {
                        type: "speak",
                        label: t("blabla.result", { usecase: input })
                      } satisfies AISpeakProps
                    ];
                  });
                }
                setPartialResult(bestEffortData);
              }
            }
          }
        );
        const suggestionValue = response.data as unknown as Record<string, string>;
        setPartialResult(suggestionValue);
        setDialogItems(prev => {
          return [
            ...prev,
            {
              type: "result",
              result: suggestionValue,
              sessionId: sessionId || "",
              requestId: response.headers["x-caralegal-request-id"] || "",
              parseResult: aiIntegrationFieldInput?.parseOutput,
              onApply: (result: object) => {
                aiIntegrationSuggestionCallback?.(result, aiIntegrationFieldInput);
                onDone?.();
              },
              onRestart: () => {
                return triggerFeedbackInputSubmission(input);
              }
            } satisfies AIResultProps
          ];
        });
        setFeedbackInputLoading(false);
      } catch (error) {
        if (isAxiosError(error) && error.code === "ERR_CANCELED") {
          return;
        }

        setFeedbackInputLoading(false);

        if (
          isAxiosErrorWithCode(error, 400, "UnclearPromptError") ||
          isAxiosErrorWithCode(error, 400, "EmptyPromptError")
        ) {
          setDialogItems(prev => {
            return [
              // remove the last ai speak item for showing result
              ...prev.slice(0, -1),
              {
                type: "speak",
                label: t("error.too-short")
              } satisfies AISpeakProps
            ];
          });
        } else {
          setDialogItems(prev => {
            return [
              // remove the last ai speak item for showing result
              ...prev.slice(0, -1),
              {
                type: "speak",
                label: t("error.unknown")
              } satisfies AISpeakProps
            ];
          });
          throw error;
        }
      }
    },
    [aiIntegrationFieldInput, i18n.language, sessionId, aiIntegrationSuggestionCallback, t, onDone]
  );

  const [dialogItems, setDialogItems] = useState<AllAIDialogItems[]>([
    {
      type: "speak",
      label: t("suggest-field")
    }
  ]);
  useEffect(() => {
    setDialogItems([
      {
        type: "speak",
        label: t("suggest-field")
      }
    ]);
    // on session id change, then should reset dialog items
  }, [t, sessionId]);
  const dialogItemsWithDisabled = useMemo(() => {
    const shouldDisabled = (index: number) => {
      if (feedbackInputLoading) {
        return true;
      }

      const lastItem = index === dialogItems.length - 1;
      if (!lastItem) {
        const nextItemUntilLastAreChoices = dialogItems.slice(index + 1).every(item => item.type === "choice");
        if (!nextItemUntilLastAreChoices) {
          return true;
        }
      }

      return false;
    };
    return dialogItems.map((item, index) => {
      if (item.type === "choice") {
        return {
          ...item,
          disabled: shouldDisabled(index)
        } as AIChoiceButtonProps;
      }
      return item;
    });
  }, [dialogItems, feedbackInputLoading]);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    // Effect to scroll to the bottom of dialog items
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [dialogItems]);

  useEffect(() => {
    const initialSuggestions: string[] =
      aiIntegrationFieldInput &&
      "initialSuggestions" in aiIntegrationFieldInput &&
      Array.isArray(aiIntegrationFieldInput.initialSuggestions)
        ? aiIntegrationFieldInput.initialSuggestions
        : [];

    setDialogItems(prev => {
      const prevWithoutSuggestFields = prev.filter(item => item.type !== "choice");
      const suggestions = initialSuggestions.map(
        suggestion =>
          ({
            type: "choice",
            label: suggestion,
            onClick: triggerFeedbackInputSubmission
          }) satisfies AIChoiceButtonProps
      );
      return [prevWithoutSuggestFields[0], ...suggestions, ...prevWithoutSuggestFields.slice(1)];
    });
  }, [aiIntegrationFieldInput, triggerFeedbackInputSubmission]);

  const onFeedbackInputSubmission = useCallback(
    async (value: string) => {
      setFeedbackInput("");
      setDialogItems(prev => {
        return [
          ...prev,
          {
            type: "user-input",
            label: value
          } satisfies AIUserInputProps
        ];
      });
      return triggerFeedbackInputSubmission(value);
    },
    [triggerFeedbackInputSubmission]
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        sx={{
          overflowY: "auto"
        }}
        ref={messagesEndRef}
      >
        {dialogItemsWithDisabled.map((item, index) => (
          <Box key={index} mb={2}>
            {<GenericAIDialogItem itemProp={item} />}
          </Box>
        ))}
        <Collapse in={!!(feedbackInputLoading && Object.entries(partialResult).length)}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <AIResultProps
              type="result"
              result={partialResult}
              sessionId=""
              requestId=""
              parseResult={aiIntegrationFieldInput?.parseOutput}
            />
          </Box>
        </Collapse>
        <Collapse in={feedbackInputLoading}>
          <Box display="flex" alignItems="center" justifyContent="center" my={2}>
            <CircularProgress size={24} />
          </Box>
        </Collapse>
        <Box ref={messagesEndRef} />
      </Box>
      <Box flex={1} />
      <Box>
        <UserAskInputField value={feedbackInput} onChange={setFeedbackInput} onSubmit={onFeedbackInputSubmission} />
      </Box>
    </Box>
  );
};

export const UserAskInputField = ({
  value,
  onChange,
  onSubmit,
  loading
}: {
  value: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  loading?: boolean;
}) => {
  const { t } = useTranslation("ai-metaview");

  const onSubmitCallback = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  const inputProps = useMemo<Partial<FilledInputProps>>(() => {
    return {
      endAdornment: (
        <IconButton onClick={onSubmitCallback}>
          <ArrowCircleUpOutlined color="action" />
        </IconButton>
      )
    };
  }, [onSubmitCallback]);

  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const onEnterSubmit = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onSubmit(value);
      }
    },
    [onSubmit, value]
  );

  return (
    <Box>
      <TextField
        fullWidth={true}
        variant={"outlined"}
        size={"small"}
        placeholder={t("ask")}
        value={value}
        onChange={onChangeCallback}
        InputProps={inputProps}
        disabled={loading}
        onKeyUp={onEnterSubmit}
      />
    </Box>
  );
};
const aiClient = new AiBackendApi(undefined, apiEndpoints.aiUrl, defaultOTCAuthenticatedAxios());
