import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Divider, FormControl, Grid, ListItemIcon, MenuItem, Select } from "@material-ui/core";
import RenderIcon from "components/RenderIcon/RenderIcon";

const useStyles = makeStyles(theme => ({
  addDocumentIcon: {
    color: theme.palette.text.primary
  }
}));

/**
 * @deprecated
 */
export default function OverviewListItemPagination({
  toolTipNext,
  toolTipPrevious,
  setDisplayableChunk,
  setIndexOffset,
  list,
  numberDescriptionText = "........",
  numberOfPagesDescriptionText = "",
  onPageChange,
  onItemsPerPageChange,
  page,
  itemsPerPage
}) {
  const classes = useStyles();
  const [chunkLength, setChunkLength] = useState(itemsPerPage ?? 10);
  const [currentChunk, setCurrentChunk] = useState(page ?? 0);
  const [chunkedInput, setChunkedInput] = useState([[]]);
  const [initialized, setInitialized] = useState(false);

  const totalItems = list.length;
  const currentStartItemNumber = currentChunk * chunkLength + 1;
  const currentEndItemNumber =
    currentChunk * chunkLength + chunkLength < totalItems ? currentChunk * chunkLength + chunkLength : totalItems;

  const nextDisabled = currentChunk > chunkedInput.length - 2;
  const previousDisabled = currentChunk < 1;

  useEffect(() => {
    // for the cases when the page and items per page is updated outside of pagination
    if (!isNaN(page) && page !== currentChunk) {
      setCurrentChunk(page);
    }
    if (!isNaN(itemsPerPage) && itemsPerPage !== chunkLength) {
      setChunkLength(itemsPerPage);
    }
  }, [page, itemsPerPage]);

  useEffect(() => {
    if (onItemsPerPageChange && initialized && chunkLength !== itemsPerPage) {
      onItemsPerPageChange(chunkLength);
    }
  }, [chunkLength, initialized]);

  useEffect(() => {
    if (onPageChange && initialized && currentChunk !== page) {
      onPageChange(currentChunk);
    }
  }, [currentChunk, initialized]);

  const displayNextChunk = function () {
    const newChunk = currentChunk + 1;
    if (newChunk < chunkedInput.length) {
      setCurrentChunk(newChunk);
    }
  };

  const displayPreviousChunk = function () {
    const newChunk = currentChunk - 1;
    if (newChunk >= 0) {
      setCurrentChunk(newChunk);
    }
  };

  const adjustChunkLength = function (value) {
    setCurrentChunk(0);
    setChunkLength(value);
  };

  useEffect(() => {
    if (setIndexOffset) {
      setIndexOffset(currentChunk * chunkLength);
    }
  }, [currentChunk, chunkLength]);

  const createChunkedInput = function () {
    if (list.length === 0) {
      setDisplayableChunk([]);
      setChunkedInput([[]]);
      if (initialized) {
        // means the lists was reduced to 0
        setCurrentChunk(0);
      } else {
        // means that the lists might not have been loaded, retain chunk
        setCurrentChunk(page ?? 0);
      }
    }

    if (list.length > 0) {
      setInitialized(true);
      const newChunkedInput = [];

      for (var i = 0; i < totalItems; i = i + chunkLength) {
        const tempArray = list.slice(i, i + chunkLength);
        newChunkedInput.push(tempArray);
      }
      setChunkedInput(newChunkedInput);
      if (currentChunk > newChunkedInput.length - 1) {
        const lastChunk = newChunkedInput.length - 1;
        setCurrentChunk(lastChunk);
      }
    }
  };

  useEffect(() => {
    createChunkedInput();
  }, [chunkLength, list]);

  useEffect(() => {
    if (chunkedInput[currentChunk]) {
      setDisplayableChunk(chunkedInput[currentChunk]);
    } else {
      setDisplayableChunk([]);
    }
  }, [currentChunk, chunkedInput]);

  return (
    <>
      <Divider />
      {list.length > 0 && (
        <>
          <Box my={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <ListItemIcon className={classes.addDocumentIcon}>
                  <RenderIcon
                    icon={"ArrowBackIcon"}
                    iconSize={"small"}
                    toolTipTitle={toolTipPrevious}
                    callBackFunction={displayPreviousChunk}
                    disabled={previousDisabled}
                  />
                </ListItemIcon>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box mx={3}>
                      <b>{currentStartItemNumber} </b> - <b>{currentEndItemNumber}</b> {numberDescriptionText}{" "}
                      <b>{totalItems}</b>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mr={1}>{numberOfPagesDescriptionText}</Box>
                  </Grid>
                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <Select
                        value={chunkLength}
                        onChange={event => {
                          adjustChunkLength(event.target.value);
                        }}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <ListItemIcon className={classes.addDocumentIcon}>
                  <RenderIcon
                    icon={"ArrowForwardIcon"}
                    iconSize={"small"}
                    toolTipTitle={toolTipNext}
                    callBackFunction={displayNextChunk}
                    disabled={nextDisabled}
                  />
                </ListItemIcon>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </>
      )}
    </>
  );
}
