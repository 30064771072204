import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import DocView, { DocViewPageType } from "../../../components/DocView/DocView";
import { useTranslation } from "react-i18next";
import GeneralTab from "./GeneralTab/GeneralTab";
import { useNavigate, useParams } from "react-router-dom";
import { COLLECTIONS } from "../../collections";
import { useMetaView } from "app/contexts/meta-view-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useExternalRecipients } from "../../contexts/external-recipient-context";
import DataLocationOverview from "../datalocations/DataLocationOverview";
import ExternalRecipientProcessOverview from "./overview/processes/ExternalRecipientProcessOverview";
import ExternalRecipientAssetsOverview from "./overview/assets/ExternalRecipientAssetsOverview";
import { useResources } from "../../contexts/resource-context";

import { postCubeJsEvent } from "../../api/cubeJsHelperApi";
import { FEATURES } from "../../features";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import Stepper, { StepperItemProps } from "components/Stepper/Stepper";
import { ExternalRecipientDocumentDTO } from "app/api/externalRecipientApi";
import ComplianceTab from "./ComplianceTab/ComplianceTab";

type ExternalRecipientStep = "general" | "products" | "compliance" | "processes" | "assets";
const externalRecipientSteps = ["general", "products", "compliance", "processes", "assets"] as ExternalRecipientStep[];

interface ExternalRecipientProps {
  readonly documentId: string;
  readonly renderOnlyDetailsView?: boolean;
}

const ExternalRecipient = ({ renderOnlyDetailsView, documentId }: ExternalRecipientProps) => {
  const { t } = useTranslation("service_providers_overview");
  const { page } = useParams();

  const { setInfo, setMeta } = useMetaView();
  const navigate = useNavigate();
  const { loading: authLoading } = useAuthentication();
  const isShowRecentActivities = useIsFeaturePresent(FEATURES.DASHBOARD_RECENT_ACTIVITIES);
  const isERAssetFeaturePresent = useIsFeaturePresent(FEATURES.ASSET_ERS);
  const { refreshResources } = useResources();

  const [answers, setAnswers] = useState<ExternalRecipientDocumentDTO | null>(null);
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [modalStep, setModalStep] = useState<ExternalRecipientStep | null>(null);
  const [pageAccessPublished, setPageAccessPublished] = useState(false);

  const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

  const {
    initialized,
    loadExternalRecipientHook,
    externalRecipient,
    approveExternalRecipientHook,
    unApproveExternalRecipientHook
  } = useExternalRecipients();

  const loadServiceProviderData = useCallback(
    async function () {
      if (!documentId) {
        return;
      }
      await loadExternalRecipientHook(documentId).catch(error => {
        setDocumentNotFound(true);
        throw error;
      });
    },
    [documentId, loadExternalRecipientHook]
  );

  const updateAnswers = useCallback(
    field => {
      setAnswers(currentAnswers => ({ ...currentAnswers, ...field }));
    },
    [setAnswers]
  );

  const onApproveCallback = useCallback(
    async (approve: boolean) => {
      if (approve) {
        await approveExternalRecipientHook(documentId);
      } else {
        await unApproveExternalRecipientHook(documentId);
      }

      await loadServiceProviderData();
    },
    [approveExternalRecipientHook, documentId, loadServiceProviderData, unApproveExternalRecipientHook]
  );

  useEffect(() => {
    if (!authLoading && initialized) {
      loadServiceProviderData();
      refreshResources();
    }
  }, [authLoading, initialized, loadServiceProviderData, refreshResources]);

  useEffect(() => {
    if (initialized && externalRecipient) {
      setAnswers(externalRecipient);
    }
    return () => {
      setAnswers(null);
    };
  }, [externalRecipient, initialized, documentId]);

  useEffect(() => {
    // only publish once on first access
    const publishActivity = async function (activityType: string) {
      if (!isShowRecentActivities || !answers) {
        return;
      }
      const payload = {
        url: "/service-providers/" + documentId,
        docType: "serviceProvider",
        docTitle: answers.name,
        docId: documentId,
        activityType: activityType,
        tableId: "activities" as const
      };
      await postCubeJsEvent(payload);
    };
    if (answers?.name && pageAccessPublished === false) {
      publishActivity("pageAccess");
      setPageAccessPublished(true);
    }
  }, [answers, answers?.name, documentId, isShowRecentActivities, pageAccessPublished]);

  useEffect(() => {
    if (renderOnlyDetailsView && modalStep === null) {
      setModalStep("general");
    }
  }, [modalStep, renderOnlyDetailsView]);

  const stepsContentEl = useMemo<Record<string, React.ReactNode>>(() => {
    return {
      general: (
        <GeneralTab
          answers={answers}
          updateAnswers={updateAnswers}
          answersLoaded={answers !== null}
          documentId={documentId}
          disableComment={renderOnlyDetailsView}
        />
      ),
      products: <DataLocationOverview externalRecipientId={documentId} hideTitle />,
      compliance: (
        <ComplianceTab
          setMeta={setMeta}
          setInfo={setInfo}
          disableComment={renderOnlyDetailsView}
          answers={answers}
          updateAnswers={updateAnswers}
          onApprove={onApproveCallback}
        />
      ),
      processes: <ExternalRecipientProcessOverview externalRecipientId={documentId} />,
      assets: <ExternalRecipientAssetsOverview externalRecipientId={documentId} />
    };
  }, [answers, documentId, onApproveCallback, renderOnlyDetailsView, setInfo, setMeta, updateAnswers]);

  const stepEl = useMemo(() => {
    if (!renderOnlyDetailsView) {
      if (!page) return <></>;
      if (externalRecipientSteps.includes(page as ExternalRecipientStep)) {
        return stepsContentEl[page || "general"] || <></>;
      }
      // old logic with number
      else {
        return Object.values(stepsContentEl)[+page - 1] || <></>;
      }
    } else {
      if (!modalStep) return <></>;
      if (externalRecipientSteps.includes(modalStep)) {
        return stepsContentEl[modalStep || "general"] || <></>;
      }
    }
  }, [modalStep, page, renderOnlyDetailsView, stepsContentEl]);

  const steps = useMemo<StepperItemProps[]>(() => {
    return [
      {
        path: "general",
        label: t("overview_tab_title"),
        number: "1",
        selected: page === "general" || page === "1" || modalStep === "general"
      },
      {
        path: "products",
        number: "2",
        label: t("product_tab_title"),
        selected: page === "products" || page === "2" || modalStep === "products"
      },
      {
        path: "compliance",
        number: "3",
        label: t("compliance_tab_title"),
        selected: page === "compliance" || page === "3" || modalStep === "compliance"
      },
      !renderOnlyDetailsView && {
        path: "processes",
        label: t("processes_tab_title"),
        number: "4",
        selected: page === "processes" || page === "4" || modalStep === "processes"
      },
      isERAssetFeaturePresent && {
        path: "assets",
        number: renderOnlyDetailsView ? "4" : "5",
        label: t("assets_tab_title"),
        selected: page === "assets" || page === "5" || modalStep === "assets"
      }
    ].filter(notNull => notNull) as unknown as StepperItemProps[];
  }, [isERAssetFeaturePresent, modalStep, page, renderOnlyDetailsView, t]);

  const onStepClick = useCallback(
    ({ path }) => {
      if (!renderOnlyDetailsView) {
        navigate(`/external-recipients/${path}/${documentId}`);
      } else {
        setModalStep(path as ExternalRecipientStep);
      }
    },
    [documentId, navigate, renderOnlyDetailsView]
  );
  const stepsEl = useMemo(() => {
    return <Stepper steps={steps} onClick={onStepClick} />;
  }, [onStepClick, steps]);

  const docViewContentEl = useMemo(() => {
    if (!answers) return <></>;
    return (
      <DocView header={answers?.name || ""} pageType={page === "products" ? DocViewPageType.OVERVIEW : undefined}>
        {stepsEl}
        {stepEl}
      </DocView>
    );
  }, [answers, page, stepEl, stepsEl]);

  // if renderOnlyDetailsView is true, then only display the tabs and its individual contents else display the stand docview
  if (renderOnlyDetailsView && answers) {
    return (
      <>
        {stepsEl}
        {stepEl}
      </>
    );
  }

  return (
    <DocMetaView
      docViewContent={docViewContentEl}
      loading={!answers || !initialized || documentId !== answers?.id}
      notFound={documentNotFound}
      collection={COLLECTIONS.EXTERNAL_RECIPIENTS}
      metaViewContent={
        <MetaView
          tabs={metaViewTabIds}
          docName={answers?.name || ""}
          pageId={page}
          docId={documentId}
          collection={COLLECTIONS.EXTERNAL_RECIPIENTS}
        />
      }
    />
  );
};

export default ExternalRecipient;
