import { usePathName } from "../../../router/router-custom-hooks";
import { useCallback } from "react";
import { getLastOverviewType } from "../../processes/overview/paOverviewTypeStorage";

export const useProcessVariations = () => {
  const path = usePathName();

  const getProcessDetailSourceOverview = useCallback(async (path: string) => {
    if (!path.startsWith("/processes/")) {
      return "";
    }
    return getLastOverviewType();
  }, []);

  const isOnProcessPage = useCallback(async () => {
    if (path.endsWith("/processes")) {
      return true;
    }

    return (await getProcessDetailSourceOverview(path)) === "pa";
  }, [getProcessDetailSourceOverview, path]);
  const inOnBPPage = useCallback(async () => {
    if (path.includes("/bp")) {
      return true;
    }

    return (await getProcessDetailSourceOverview(path)) === "business";
  }, [getProcessDetailSourceOverview, path]);
  const isOnDPIAPage = useCallback(async () => {
    if (path.includes("/dpias")) {
      return true;
    }

    return (await getProcessDetailSourceOverview(path)) === "dpia";
  }, [getProcessDetailSourceOverview, path]);
  const isOnAIActPage = useCallback(async () => {
    if (path.includes("/aiacts")) {
      return true;
    }

    return (await getProcessDetailSourceOverview(path)) === "aiact";
  }, [getProcessDetailSourceOverview, path]);

  return { isOnProcessPage, inOnBPPage, isOnDPIAPage, isOnAIActPage, getProcessDetailSourceOverview };
};
