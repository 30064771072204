import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { convertToRaw, EditorState } from "draft-js";
import Comment from "../../../../components/Comment/Comment";
import BygoneTime from "../../../../components/BygoneTime";
import { Box, makeStyles } from "@material-ui/core";
import UserDataDisplay, { AutomaticUserDataDisplay } from "../../../../components/UserDataDisplay";
import MetaViewPagination from "components/MetaViewPagination/MetaViewPagination";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { EXTERNAL_USER_ROLE_NAME, useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { COLLECTIONS } from "../../../collections";
import { commentClient, useComments } from "app/api/commentApi";
import { CommentModelDB, CommentModelDTO } from "app/api/generated/comment-service";
import { getSingleUserApi, UserDTO } from "app/api/user/userApi";
import useSWR from "swr";
import { sendNotificationApi } from "../../../api/userNotificationApi";
import { getSingleAnswerSetApi } from "app/api/assessmentApi";
import { getAuditAffectedUsers, getAuditDetail } from "app/api/auditApi";
import { defaultExpertRolesWithoutGlobal, useUserRoles } from "app/contexts/role-context";
import { useUserDepartments } from "../../../contexts/department-context";

function noOp() {
  // no op
}

const useStyles = makeStyles(theme => ({
  highlighted: {
    animation: "$highlightFadeOut 3s forwards",
    paddingRight: theme.spacing(2),
    marginRight: -theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: -theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    marginLeft: -theme.spacing(1)
  },
  "@keyframes highlightFadeOut": {
    "0%": {
      backgroundColor: "rgba(235, 241, 255, 1)"
    },
    "100%": {
      backgroundColor: "transparent"
    }
  }
}));

function CommentsDisplay({
  assigneeUID,
  buttonTextCancel,
  buttonTextReply,
  buttonTextSave,
  clickOnCommentBadge,
  collection,
  docId,
  docName,
  groupId,
  handleAddMentions,
  hidePagination,
  newlyAddedCommentId,
  onCancelComment,
  onChangeParticipants,
  onSubmitComment,
  pageId,
  participants,
  preselectedCommentId,
  questionId,
  setNewlyAddedCommentId,
  taskDetailsContainerRef
}: {
  readonly assigneeUID?: string | null;
  readonly buttonTextCancel: string;
  readonly buttonTextReply: string;
  readonly buttonTextSave: string;
  readonly clickOnCommentBadge: boolean;
  readonly collection: string;
  readonly docId: string;
  readonly docName: string;
  readonly groupId?: string | null;
  readonly handleAddMentions?: (mentionedUsers: UserDTO[]) => void;
  readonly hidePagination?: boolean;
  readonly newlyAddedCommentId?: string;
  readonly onCancelComment: () => void;
  readonly onChangeParticipants?: (participants: string[]) => void;
  readonly onSubmitComment: () => void;
  readonly pageId: string;
  readonly participants?: string[];
  readonly preselectedCommentId?: string;
  readonly questionId?: string | null;
  readonly setNewlyAddedCommentId?: (commentId: string) => void;
  readonly taskDetailsContainerRef?: React.RefObject<HTMLDivElement | null>;
}) {
  const { t } = useTranslation("service_providers_overview");
  const cls = useStyles();
  const { getAllRolesWithReadPermission } = useUserRoles();
  const { expandDepartmentIds } = useUserDepartments();
  const previousCommentIdRef = useRef(newlyAddedCommentId);
  const [shouldScroll, setShouldScroll] = useState(false);

  const preselectedNamespace = useSWR(["comments", "preselected", preselectedCommentId], async () => {
    if (!preselectedCommentId) return null;
    return commentClient.getComment(preselectedCommentId).then(resp => resp.data.namespace || null);
  });

  const defaultNamespace = React.useMemo(() => {
    return preselectedNamespace.data && !questionId ? preselectedNamespace.data : questionId;
  }, [preselectedNamespace.data, questionId]);

  const isGroupComment = Boolean(groupId);
  // if questionId does not exist will get all comments of document
  const namespaceForComment = useMemo<{
    readonly namespace?: string;
    readonly namespacePrefix?: string;
  }>(() => {
    if (!groupId) {
      return {
        // query with just namespace
        namespace: defaultNamespace || undefined,
        namespacePrefix: undefined
      };
    }
    if (assigneeUID) {
      return {
        // query with namespace which contains assigneeUID
        namespace: `task groupTaskId ${groupId} assigneeUID ${assigneeUID}`,
        namespacePrefix: undefined
      };
    }
    return {
      // query with namespace prefix
      namespace: undefined,
      namespacePrefix: `task groupTaskId ${groupId}`
    };
  }, [groupId, assigneeUID, defaultNamespace]);

  const comments = useComments({
    documentId: docId,
    namespace: namespaceForComment.namespace,
    namespacePrefix: namespaceForComment.namespacePrefix
  });

  const reloadComments = React.useCallback(() => comments.mutate(), [comments]);
  /* STATE */
  const { auth, user } = useAuthentication();
  const { nonRootAdminTenantUsers, getUserNameHook, getUserHook } = useUserAndTenantData();

  /* useState */
  const [mentionedUsers, setMentionedUsers] = useState<(UserDTO & { checked?: boolean })[]>([]);
  const [availableMentionedUsersForAssessment, setAvailableMentionedUsersForAssessment] = useState<UserDTO[]>([]);
  // if comment opened via Notification we have to get the namespace from the preselected comment

  const [indexOffset, setIndexOffset] = useState(0);
  const relevantComments = React.useMemo(() => comments.data?.commentModels || [], [comments.data?.commentModels]);

  const allComments = useMemo(
    () => relevantComments.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
    [relevantComments]
  );

  const currentLoggedInUser = user;

  const expertUsersWithReadPermission = useCallback(
    async (orgUnitId: string, furtherOrgUnitIds: string[], assignedUserIds: string[]) => {
      const customRolesWithReadPermission = getAllRolesWithReadPermission(COLLECTIONS.AUDITS);
      const userIds: UserDTO[] = [];
      for (const user of nonRootAdminTenantUsers) {
        if (user.id && assignedUserIds.includes(user.id)) {
          userIds.push(user);
          continue;
        }
        if (
          !defaultExpertRolesWithoutGlobal.includes(user.userRole || "") &&
          !customRolesWithReadPermission.find(role => role.id === user.userRole)
        ) {
          // user not even have read only permission so can't be tag in comment
          continue;
        }

        const expandedUserOrgUnitIds = expandDepartmentIds([user.orgUnitId || "", ...(user.furtherOrgUnitIds || [])]);
        if (
          user.id &&
          (expandedUserOrgUnitIds.has(orgUnitId) || furtherOrgUnitIds.some(item => expandedUserOrgUnitIds.has(item)))
        ) {
          userIds.push(user);
        }
      }
      setAvailableMentionedUsersForAssessment(userIds);
    },
    [expandDepartmentIds, getAllRolesWithReadPermission, nonRootAdminTenantUsers]
  );

  const loadAnswerSet = useCallback(
    async (auditid: string, id: string) => {
      const data = await getSingleAnswerSetApi({ auditId: auditid || "", id: id || "" });
      if (data) {
        const assignedUserIds = [data.assigneeUID || "", ...data.participantsUIDs];
        await expertUsersWithReadPermission(
          data?.audit.mainOrgUnitId || "",
          data?.audit.associatedOrgUnitIds,
          assignedUserIds
        );
      }
    },
    [expertUsersWithReadPermission]
  );

  const loadAudit = useCallback(
    async (auditid: string) => {
      const affectedUsers = await getAuditAffectedUsers({ id: auditid });
      const auditDetail = await getAuditDetail({ id: auditid });
      if (auditDetail) {
        await expertUsersWithReadPermission(
          auditDetail.mainOrgUnitId || "",
          auditDetail.associatedOrgUnitIds || [],
          affectedUsers?.userIds || []
        );
      }
    },
    [expertUsersWithReadPermission]
  );

  useEffect(() => {
    if (collection === COLLECTIONS.AUDITS) {
      loadAudit(docId);
    } else if (collection === COLLECTIONS.ASSESSMENT_RESPONSE) {
      // docId will be `${auditId}/answerset/${currentParticipantId}`;
      const splitId = docId.split("/answerset/");
      const auditId = splitId[0];
      const answerSetId = splitId[1];
      loadAnswerSet(auditId, answerSetId);
    }
  }, [collection, docId, loadAnswerSet, loadAudit]);

  const availableMentions = useMemo(
    () =>
      availableMentionedUsersForAssessment.length > 0
        ? availableMentionedUsersForAssessment.map(user => ({
            ...user,
            name: getUserNameHook(user?.id || "")
          }))
        : nonRootAdminTenantUsers
            .filter(user => user.userRole !== EXTERNAL_USER_ROLE_NAME)
            .map(user => ({
              ...user,
              name: getUserNameHook(user?.id || "")
            })),
    [nonRootAdminTenantUsers, getUserNameHook, availableMentionedUsersForAssessment]
  );

  const handleNewCommentRef = (element: HTMLElement | null) => {
    if (element) {
      setShouldScroll(true);
    }
  };
  useEffect(() => {
    if (shouldScroll && newlyAddedCommentId && newlyAddedCommentId !== previousCommentIdRef.current) {
      const element = document.getElementById(newlyAddedCommentId);
      if (element && taskDetailsContainerRef?.current) {
        taskDetailsContainerRef.current.scrollTo({
          top: element.offsetTop - taskDetailsContainerRef.current.offsetTop,
          behavior: "smooth"
        });
        previousCommentIdRef.current = newlyAddedCommentId;
      }
      setShouldScroll(false);
    }
  }, [shouldScroll, newlyAddedCommentId, taskDetailsContainerRef]);

  // Mentions stuff
  const [suggestions, setSuggestions] = useState(availableMentions);

  const defaultSuggestionsFilter = React.useCallback((searchValue: string, suggestions: any[]) => {
    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        !value ||
        suggestion.email.toLowerCase().indexOf(value) > -1 ||
        suggestion.name.toLowerCase().indexOf(value) > -1
    );
    const length = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
    return filteredSuggestions.slice(0, length);
  }, []);

  const setSuggestionsOnSearchChange = React.useCallback(
    (value: string) => {
      if (!availableMentions) {
        return;
      }
      setSuggestions(defaultSuggestionsFilter(value, availableMentions));
    },
    [availableMentions, defaultSuggestionsFilter]
  );

  const getBasicNotificationObject = React.useCallback(
    function ({ origin }: { origin: Record<string, unknown> }) {
      if (!auth || !user) return;
      return {
        collection: collection || "",
        docId: docId,
        docName: docName,
        pageId: pageId,
        origin: origin || {}
      };
    },
    [auth, user, collection, docId, docName, pageId]
  );

  const createMentionNotification = React.useCallback(
    function (mentionedUsers: UserDTO[], message: string, origin: { type: string; commentId: string }) {
      const basicNotificationObject = getBasicNotificationObject({ origin });
      if (!basicNotificationObject) return;
      const notificationObj = {
        ...basicNotificationObject,
        receivers: mentionedUsers.map(user => user.id).filter(nonEmpty => nonEmpty) as string[],
        title: "comment_mention",
        message: message
      };
      return sendNotificationApi(notificationObj);
    },
    [getBasicNotificationObject]
  );

  const createCommentReplyNotification = React.useCallback(
    function (
      commentCreatorId: string,
      message: string,
      origin: {
        type: string;
        commentId: string;
        commentReplyId: string;
      }
    ) {
      const basicNotificationObject = getBasicNotificationObject({ origin });
      if (!basicNotificationObject) return;
      const notificationObj = {
        ...basicNotificationObject,
        title: "comment_reply",
        message: message,
        receivers: [commentCreatorId]
      };
      return sendNotificationApi(notificationObj);
    },
    [getBasicNotificationObject]
  );

  const addCommentToDB = React.useCallback(
    async (questionId: string, commentVal: EditorState) => {
      if (!auth) return;
      if (onSubmitComment) onSubmitComment();
      // submit the comment
      const contentState = commentVal.getCurrentContent();
      const raw = convertToRaw(contentState);
      const message: string[] = [];
      raw.blocks.forEach(item => {
        message.push(item.text);
      });
      const temp = { ...raw };
      Object.values(temp.entityMap).forEach((element, index) => {
        if (element.type === "mention" && element.data) {
          element.data.mention = { id: element.data.mention.id, name: element.data.mention.name };
        }
      });
      const textMessage = message.join("\n");
      // create tasks for assigned users
      const assignedUsers = mentionedUsers.filter(x => x.checked);
      // mentioned user -- assigned user == only mentioned user
      const usersMentionedOnly = mentionedUsers.filter(x => !assignedUsers.includes(x));
      const commentId = await comments.addComment({
        contentState: temp,
        value: textMessage,
        documentId: docId,
        namespace: isGroupComment ? `task groupTaskId ${groupId} assigneeUID ${assigneeUID}` : questionId,
        mentionedUsers: usersMentionedOnly.map(x => x.id).filter((id): id is string => !!id)
      });

      setNewlyAddedCommentId?.(commentId);

      const notificationsOrigin = { type: "comment", commentId: commentId || "" };
      // don't notify if mentioned user === creator of task
      const mentionedUsersWithoutCurrent = mentionedUsers.filter(mentionedUser => mentionedUser.id !== auth.uid);

      // add mentioned users as participants
      onChangeParticipants?.([
        ...new Set([
          ...(participants ? participants : []),
          ...mentionedUsers.map(user => user.id).filter((id): id is string => !!id)
        ])
      ]);

      createMentionNotification(mentionedUsersWithoutCurrent, textMessage, notificationsOrigin);
      if (handleAddMentions) handleAddMentions(mentionedUsersWithoutCurrent);
      setMentionedUsers([]);
      await reloadComments();
    },
    [
      auth,
      onSubmitComment,
      mentionedUsers,
      comments,
      docId,
      isGroupComment,
      groupId,
      assigneeUID,
      setNewlyAddedCommentId,
      onChangeParticipants,
      participants,
      createMentionNotification,
      handleAddMentions,
      reloadComments
    ]
  );

  // save new reply
  const addCommentToDBReply = React.useCallback(
    async (
      replyText: EditorState,
      comment: CommentModelDB,
      addComment: (comment: CommentModelDTO) => Promise<string>
    ) => {
      if (!auth) return;
      const contentState = replyText.getCurrentContent();
      const raw = convertToRaw(contentState);
      const message: string[] = [];
      raw.blocks.forEach(item => {
        message.push(item.text);
      });
      const textMessage = message.join("\n");

      const temp = { ...raw };
      Object.values(temp.entityMap).forEach((element, index) => {
        if (element.type === "mention") {
          element.data.mention = { id: element.data.mention.id, name: element.data.mention.name };
        }
      });

      const mentionedUsersWithoutCurrent = mentionedUsers.filter(mentionedUser => mentionedUser.id !== auth.uid);
      if (handleAddMentions) handleAddMentions(mentionedUsersWithoutCurrent);

      const replyId = await addComment({
        contentState: temp,
        mentionedUsers: mentionedUsersWithoutCurrent.map(user => user.id).filter((id): id is string => !!id),
        parentCommentId: comment.id,
        value: textMessage
      });

      setNewlyAddedCommentId?.(replyId);
      setTimeout(() => setNewlyAddedCommentId?.(""), 3000);

      const promises = [];
      const creatorId = comment.creatorUID;
      const creatorWillBeNotifiedForTaskOrMention = mentionedUsersWithoutCurrent.some(user => user.id === creatorId);

      const parentCommentId = comment.id;
      const notificationOrigin = {
        type: "commentReply",
        commentId: parentCommentId,
        commentReplyId: replyId
      };
      promises.push(createMentionNotification(mentionedUsersWithoutCurrent, textMessage, notificationOrigin));

      if (!creatorWillBeNotifiedForTaskOrMention && creatorId !== auth.uid) {
        promises.push(createCommentReplyNotification(creatorId, textMessage, notificationOrigin));
      }
      await Promise.all(promises);
      setMentionedUsers([]);
    },
    [
      auth,
      mentionedUsers,
      handleAddMentions,
      setNewlyAddedCommentId,
      createMentionNotification,
      createCommentReplyNotification
    ]
  );

  const [editedCommentId, setEditedCommentId] = useState<number[]>([]);

  // save updated comment text in db
  const onEdit = React.useCallback(
    async (questionId: string, commentText: EditorState) => {
      if (!auth) return;
      const contentState = commentText.getCurrentContent();
      const raw = convertToRaw(contentState);
      const message: string[] = [];
      raw.blocks.forEach(item => {
        message.push(item.text);
      });
      const textMessage = message.join("\n");
      const temp = { ...raw };
      Object.values(temp.entityMap).forEach((element, index) => {
        element.data.mention = { id: element.data.mention.id, name: element.data.mention.name };
      });
      const mentionedUsers = Object.values(temp.entityMap)
        .map(element => {
          if (!element.data.mention) return null;
          return element.data.mention.id;
        })
        .filter((id): id is string => !!id);
      const commentIndex = Array.isArray(editedCommentId) ? editedCommentId[0] : editedCommentId;
      const commentId = comments.data?.commentModels?.[commentIndex].id;
      if (!commentId) return;
      await comments.updateComment(commentId, {
        contentState: temp,
        value: textMessage,
        mentionedUsers
      });
      // reset comment id
      setEditedCommentId([]);
    },
    [comments, editedCommentId, auth]
  );

  // function triggered through ellipsis menu
  // initiates the editing process
  const startRenameComment = React.useCallback(async (index?: number) => {
    if (typeof index === "number") setEditedCommentId([index]);
    else setEditedCommentId([]);
  }, []);

  // function triggered through ellipsis menu
  // deletes process
  const deleteComment = React.useCallback(
    async (idx: number) => {
      const commentId = comments.data?.commentModels?.[idx].id;
      if (!commentId) return;
      // user might delete comment while editing it
      setEditedCommentId([]);
      await comments.deleteComment(commentId);
    },
    [comments]
  );

  const activeComment = useMemo(() => relevantComments?.[indexOffset], [relevantComments, indexOffset]);

  // this is a hack, we should have comment write all permissions to handle these things
  const isExpert = useMemo(() => auth?.permissions?.some(it => it.startsWith("pa_approve")), [auth?.permissions]);
  const ellipsisMenuData = useMemo(
    () =>
      [
        auth?.uid === activeComment?.creatorUID && {
          icon: "EditIcon",
          title: t("editComment"),
          callBackFunction: startRenameComment
        },
        (auth?.uid === activeComment?.creatorUID || isExpert) && {
          icon: "DeleteIcon",
          title: t("deleteComment"),
          callBackFunction: deleteComment
        }
      ].flatMap(it => (it ? [it] : [])),
    [activeComment?.creatorUID, auth?.uid, deleteComment, isExpert, startRenameComment, t]
  );

  // render the first letter of the user name or email
  const createAvatar = React.useCallback(
    (userId: string) => {
      const username = getUserNameHook(userId);
      if (!username) {
        return "n/a";
      }

      return username.substring(1, 0).toUpperCase();
    },
    [getUserNameHook]
  );

  // render BygoneTime
  const replySubHeader = React.useCallback((created: Date) => {
    return <BygoneTime timestamp={created} />;
  }, []);
  const getCommentIndex = React.useCallback(
    (comment: CommentModelDB) => {
      return relevantComments?.indexOf(comment);
    },
    [relevantComments]
  );

  const commentIsEdited = React.useCallback(
    (comment: CommentModelDB) => {
      if (Array.isArray(editedCommentId) && editedCommentId.length === 0) return false;

      let commentId: number;
      if (Array.isArray(editedCommentId)) commentId = editedCommentId[0];
      else commentId = editedCommentId;
      return relevantComments?.indexOf(comment) === commentId;
    },
    [editedCommentId, relevantComments]
  );

  const replyHeader = React.useCallback((userId: string) => {
    return <AutomaticUserDataDisplay uid={userId} />;
  }, []);

  const userData = useSWR(
    ["users", activeComment?.creatorUID],
    async () => {
      return getSingleUserApi(activeComment?.creatorUID || "");
    },
    {}
  );

  const newCommentsFormEl = clickOnCommentBadge && (
    <Comment
      subHeader
      isNew={true}
      questionId={defaultNamespace || ""}
      avatar={createAvatar(currentLoggedInUser?.id || "")}
      header={<UserDataDisplay userData={currentLoggedInUser} />}
      onSubmit={addCommentToDB}
      onCancel={onCancelComment}
      buttonTextReply={buttonTextReply}
      buttonTextCancel={buttonTextCancel}
      buttonTextSave={buttonTextSave}
      selectMentions={setMentionedUsers}
      replyHeader={replyHeader}
      suggestions={suggestions}
      setSuggestionsOnSearchChange={setSuggestionsOnSearchChange}
    />
  );

  const handlePreselectComment = React.useCallback(
    (comment: CommentModelDB) => comment.id === preselectedCommentId,
    [preselectedCommentId]
  );

  const commentsToDisplay = useMemo(
    () => (hidePagination ? allComments : relevantComments),
    [hidePagination, allComments, relevantComments]
  );

  const commentsWithPrefix = commentsToDisplay
    ? commentsToDisplay.map((activeComment, index) => (
        <div
          id={activeComment.id}
          key={index}
          ref={newlyAddedCommentId === activeComment.id ? handleNewCommentRef : null}
          className={newlyAddedCommentId === activeComment.id ? cls.highlighted : ""}
        >
          <Comment
            commentIndex={getCommentIndex(activeComment)}
            onEdit={onEdit}
            isNew={false}
            comment={activeComment}
            questionId={defaultNamespace || ""}
            avatar={createAvatar(activeComment.creatorUID || "")}
            header={
              <UserDataDisplay
                userData={
                  activeComment.creatorUID === "system"
                    ? { name: t("common:system") }
                    : { name: getUserNameHook(activeComment.creatorUID) }
                }
              />
            }
            subHeader={<BygoneTime timestamp={new Date(activeComment.createdAt)} />}
            buttonTextReply={buttonTextReply}
            buttonTextCancel={buttonTextCancel}
            buttonTextSave={buttonTextSave}
            ellipsisMenuData={ellipsisMenuData}
            selectMentions={setMentionedUsers}
            onReplySubmit={addCommentToDBReply}
            createAvatar={createAvatar}
            replySubHeader={replySubHeader}
            replyHeader={replyHeader}
            suggestions={suggestions}
            setSuggestionsOnSearchChange={setSuggestionsOnSearchChange}
            onCancel={noOp}
            onSubmit={addCommentToDB}
            isEdited={commentIsEdited(activeComment) ? editedCommentId : undefined}
            setIsEdited={setEditedCommentId}
            newlyAddedCommentId={newlyAddedCommentId}
          />
        </div>
      ))
    : null;

  const defaultComment = activeComment ? (
    <Comment
      commentIndex={getCommentIndex(activeComment)}
      onEdit={onEdit}
      isNew={false}
      comment={activeComment}
      questionId={defaultNamespace || ""}
      avatar={createAvatar(userData?.data?.id || "")}
      header={
        <UserDataDisplay
          userData={activeComment.creatorUID === "system" ? { name: t("common:system") } : userData?.data}
        />
      }
      subHeader={<BygoneTime timestamp={new Date(activeComment.createdAt)} />}
      buttonTextReply={buttonTextReply}
      buttonTextCancel={buttonTextCancel}
      buttonTextSave={buttonTextSave}
      ellipsisMenuData={ellipsisMenuData}
      selectMentions={setMentionedUsers}
      onReplySubmit={addCommentToDBReply}
      createAvatar={createAvatar}
      replySubHeader={replySubHeader}
      replyHeader={replyHeader}
      suggestions={suggestions}
      setSuggestionsOnSearchChange={setSuggestionsOnSearchChange}
      onCancel={noOp}
      onSubmit={addCommentToDB}
      isEdited={commentIsEdited(activeComment) ? editedCommentId : undefined}
      setIsEdited={setEditedCommentId}
    />
  ) : null;

  const allCommentsEl = !clickOnCommentBadge && (
    <>
      {!hidePagination && (
        <MetaViewPagination
          toolTipNext={t("next")}
          toolTipPrevious={t("previous")}
          list={relevantComments}
          setDisplayableChunk={noOp}
          setIndexOffset={setIndexOffset}
          numberDescriptionText={t("outOf")}
          selectedElementId={defaultNamespace || ""}
          preselectChunkFn={handlePreselectComment}
        />
      )}
      {!hidePagination && (isGroupComment ? (assigneeUID ? defaultComment : commentsWithPrefix) : defaultComment)}
      {hidePagination && commentsWithPrefix}
    </>
  );

  return <Box>{newCommentsFormEl || allCommentsEl}</Box>;
}

export default CommentsDisplay;
