import { createTheme as createMUI4Theme } from "@material-ui/core/styles";
import { createTheme as createMUI5Theme } from "@mui/material/styles";

import palette from "./palette/palette";
import typography from "./typography/typography";
import overrides from "./overrides/overrides";
import shape from "./shapes/shape";
import colors from "./palette/colors";
import breakpoints from "./breakpoints/breakpoints";

declare module "@material-ui/core/styles" {
  interface Theme {
    colors: typeof colors;
  }

  interface ThemeOptions {
    colors: typeof colors;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    colors: typeof colors;
  }

  interface ThemeOptions {
    colors: typeof colors;
  }
}

export default createMUI4Theme({
  colors,
  palette,
  typography,
  overrides,
  breakpoints,
  shape,
  props: {
    MuiChip: {
      color: "secondary"
    }
  }
});

export const mui5Theme = createMUI5Theme({
  colors,
  palette,
  typography,
  breakpoints,
  shape,
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          // chip does not defaults to the default font, so we have to set it
          fontFamily: "SuisseIntl"
        },
        // on secondary color chip, the text have to be black for more contrast
        colorSecondary: {
          color: palette.text?.primary
        }
      },
      defaultProps: {
        color: "secondary"
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: "contained", color: "brick" },
              style: {
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none"
                }
              }
            },
            {
              props: { variant: "contained", color: "brick", disabled: true },
              style: {
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none"
                },
                color: colors.grey.grey400
              }
            }
          ]
        }
      }
    }
  }
});
