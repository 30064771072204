import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, Grid, TextField, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useSnackbar } from "notistack";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DataSubjectRequestsPageButtons, DataSubjectRequestsPageStepper } from "./DataSubjectRequestsPagination";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { useMetaView } from "../../contexts/meta-view-context";
import MetaView from "../../../components/MetaView/MetaView";
import { useNavigate } from "react-router-dom";
import { DSRReceivedOnField, DSRRequestTypeField } from "./DataSubjectRequestsFields";
import { getAllTasksByDocumentId } from "app/handlers/tasksHandler";
import { sortTasks } from "./dsrTaskOrder";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import TextEditor from "../questionnaires/utils/TextEditor";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import Question from "components/Question/Question";
import AssignUsersMultiAutocomplete from "../questionnaires/utils/AssignUsersMultiAutocomplete/AssignUsersMultiAutocomplete";
import OrgunitsPathsAutocomplete from "components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import { DueDate } from "../../../components/DueDate";
import { COLLECTIONS } from "app/collections";
import { LabelField } from "components/LabelField";
import { useDataSubjectRequest } from "../../contexts/dsr-context";
import { QUESTION_TYPE } from "../../../components/Question/QuestionTypes";
import { useAuthentication } from "../../handlers/authentication/authentication-context";
import DoubleCheckIconSVG from "assets/images/icons/double-check.svg";
import { shouldShowEmailInputError } from "app/utils/emailFormatter";
import { useUserDepartments } from "app/contexts/department-context";
import { useDocumentName } from "app/contexts/document-name-context";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "20px"
  },
  datePickerBottom: {
    "& button": {
      color: theme.palette.primary.main,
      position: "relative",
      left: "17px"
    },
    "& .MuiFormControl-marginNormal": {
      margin: 0
    }
  },
  peoplesIcon: {
    color: theme.palette.primary.main
  },
  markAsDoneButton: {
    position: "absolute",
    bottom: "14px"
  },
  speedDialBadge: {
    marginTop: "20px"
  }
}));

export default function DataSubjectRequestsGeneralPage({ documentId }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t, i18n } = useTranslation("data_subject_requests_overview_general_page");
  const { getUserNameHook } = useUserAndTenantData();
  const { isPartOfUserDepartments } = useUserDepartments();
  const { setInfo } = useMetaView();
  const spacing = 6;
  const [pageLoaded, setPageLoaded] = useState(false);
  const [answers, setAnswers] = useState({});
  const [answersLoaded, setAnswersLoaded] = useState(false);
  const { auth } = useAuthentication();
  const [markAsDoneLoading, setMarkAsDoneLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { catchAsSnackbar } = useErrorSnackbar();
  const [focusedElement, setFocusedElement] = useState("");
  const [dsrTasks, setDsrTasks] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { setDocumentName } = useDocumentName();

  const tasksUnfinished = dsrTasks.some(task => task.status !== "DONE");

  const {
    initialized,
    dataSubjectRequest,
    renameHook,
    updateBasicDataHook,
    markAsDoneHook,
    changeDueDateHook,
    assignsToHook
  } = useDataSubjectRequest();

  const loadDSRTasks = useCallback(async () => {
    const requestTypes = dataSubjectRequest.inputData?.type || [];
    const tasks = await getAllTasksByDocumentId(dataSubjectRequest.id);
    const sortedTasks = sortTasks(requestTypes, tasks);
    setDsrTasks(sortedTasks);
  }, [dataSubjectRequest.id, dataSubjectRequest.inputData?.type]);

  useEffect(() => {
    if (pageLoaded) {
      return;
    }

    if (initialized && dataSubjectRequest) {
      setAnswers({ ...dataSubjectRequest, ...dataSubjectRequest.inputData });
      setDocumentName(dataSubjectRequest.inputData.title);
      loadDSRTasks();
      setPageLoaded(true);
    }
  }, [pageLoaded, initialized, dataSubjectRequest, loadDSRTasks, setDocumentName]);

  useEffect(() => {
    if (answers) {
      setAnswersLoaded(true);
    }
  }, [setAnswers, answers]);

  useEffect(() => {
    setDocumentName(answers.title);
  }, [answers.title, setDocumentName]);

  const setEnterCardInfo = useCallback(
    () =>
      setInfo({
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }),
    [setInfo, t]
  );

  useEffect(() => setEnterCardInfo(), [i18n.language, setInfo, t]);

  const noWritePermission = useMemo(() => {
    if (!dataSubjectRequest.inputData.orgUnitId) return false;
    if (dataSubjectRequest.inputData.assignedTo === auth.uid || auth?.permissions.includes("dsr_write_all"))
      return false;
    if (isPartOfUserDepartments(...[dataSubjectRequest.inputData.orgUnitId])) return false;
    return true;
  }, [dataSubjectRequest, isPartOfUserDepartments, auth]);

  // handle mark as done
  const markAsDoneButtonHandler = async () => {
    try {
      setMarkAsDoneLoading(true);
      await markAsDoneHook();
      enqueueSnackbar(t("data_subject_requests_overview:marked_as_done"), { variant: "success" });
      navigate("/data-subject-requests");
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      console.error("Failed to mark data subject request as done", error);
    } finally {
      setMarkAsDoneLoading(false);
    }
  };

  const onReceivedOnChange = useCallback(
    value => {
      updateBasicDataHook({
        receivedOn: value
      }).catch(catchAsSnackbar("failed to update received on"));
      const currentDate = new Date(value);
      const newDueDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
      changeDueDateHook(newDueDate).catch(catchAsSnackbar("failed to update due date"));
      setAnswers(lastAnswersState => ({
        ...lastAnswersState,
        receivedOn: value,
        dueDate: newDueDate.getTime()
      }));
    },
    [updateBasicDataHook, setAnswers, catchAsSnackbar, changeDueDateHook]
  );

  const onRequestTypeChange = useCallback(
    value => {
      setAnswers(lastAnswersState => ({ ...lastAnswersState, type: value }));
      updateBasicDataHook({ type: value }).catch(catchAsSnackbar("failed to update type"));
    },
    [updateBasicDataHook, setAnswers, catchAsSnackbar]
  );

  const onAssigneeChange = useCallback(
    userIds => {
      setAnswers(answers => ({ ...answers, assignedTo: userIds[0] || "" }));
      assignsToHook(userIds[0] || "").catch(catchAsSnackbar("failed to change assignment"));
      enqueueSnackbar(t("assigned_dsr"), { variant: "success" });
    },
    [assignsToHook, setAnswers, catchAsSnackbar, enqueueSnackbar, t]
  );

  const allowedOrgUnitIDs = useMemo(() => [answers.orgUnitId].filter(nonNull => nonNull), [answers.orgUnitId]);

  const confirmMarkAsDone = (
    <ConfirmationModal
      modalOpen={confirmModalOpen}
      modalTitle={t("confirm_modal_title")}
      modalText={t("confirm_modal_text")}
      onClose={() => {
        setConfirmModalOpen(false);
      }}
      buttons={[
        {
          confirmButton: false,
          title: t("common:no"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: () => {
            setConfirmModalOpen(false);
          }
        },
        {
          confirmButton: true,
          title: t("common:yes"),
          variant: "contained",
          color: "primary",
          size: "medium",
          onClick: async () => {
            await markAsDoneButtonHandler();
          }
        }
      ]}
    />
  );

  // content for doc views
  const docViewContent = (
    <DocView header={answers.title} pagination={<DataSubjectRequestsPageStepper />}>
      <QuestionnaireSubHeader text={t("general_information")} />
      {confirmMarkAsDone}
      <Grid container spacing={spacing} className={classes.container}>
        <Grid item xs={6}>
          <Question
            questionId={"request_title"}
            questionName={t("request_title")}
            translatable={answers.title}
            translationId={"request_title"}
          >
            <TextField
              id={"request_title"}
              required
              fullWidth={true}
              label={t("request_title")}
              variant="outlined"
              onFocus={() => {
                setInfo({
                  title: t("enteringInfoCardTitle"),
                  text: t("request_title_info_card_text")
                });
                setFocusedElement("request_title");
              }}
              value={answers.title || ""}
              disabled={noWritePermission}
              onChange={event => {
                setAnswers({ ...answers, title: event.target.value });
              }}
              onBlur={() => {
                renameHook(answers.title).catch(catchAsSnackbar("failed to rename"));
                setEnterCardInfo();
              }}
            />
          </Question>
        </Grid>
        <Grid item xs={6}>
          <Question questionName={t("request_id")}>
            <TextField
              id={"request_id"}
              fullWidth={true}
              label={t("request_id")}
              variant="outlined"
              disabled
              onFocus={() => {
                setInfo(null);
                setFocusedElement("");
              }}
              value={answers.id || ""}
              onChange={event => {
                setAnswers({ ...answers, id: event.target.value });
              }}
              onBlur={() => {
                updateBasicDataHook({ id: answers.id }).catch(catchAsSnackbar("failed to change request ID"));
                setEnterCardInfo();
              }}
            />
          </Question>
        </Grid>
      </Grid>
      <Box display="flex" width="100%">
        <Box mr={3} flex={1}>
          <Question questionName={t("fields:first_name")}>
            <TextField
              id={"first_name"}
              fullWidth={true}
              label={t("fields:first_name")}
              variant="outlined"
              value={answers.firstName || ""}
              disabled={noWritePermission}
              onChange={event => {
                setAnswers({ ...answers, firstName: event.target.value });
              }}
              onBlur={() => {
                updateBasicDataHook({ firstName: answers.firstName }).catch(
                  catchAsSnackbar("failed to change first name")
                );
              }}
            />
          </Question>
        </Box>
        <Box ml={3} flex={1}>
          <Question questionName={t("fields:last_name")}>
            <TextField
              id={"last_name"}
              fullWidth={true}
              label={t("fields:last_name")}
              variant="outlined"
              disabled={noWritePermission}
              value={answers.lastName || ""}
              onChange={event => {
                setAnswers({ ...answers, lastName: event.target.value });
              }}
              onBlur={() => {
                updateBasicDataHook({ lastName: answers.lastName }).catch(
                  catchAsSnackbar("failed to change last name")
                );
              }}
            />
          </Question>
        </Box>
      </Box>

      <Question questionName={t("fields:email")}>
        <TextField
          fullWidth={true}
          autoComplete="off"
          value={answers.email || ""}
          disabled={noWritePermission}
          onChange={event => {
            setAnswers({ ...answers, email: event.target.value });
          }}
          name="email"
          id="email"
          onBlur={() => {
            updateBasicDataHook({ email: answers.email }).catch(catchAsSnackbar("failed to change email"));
          }}
          error={shouldShowEmailInputError(answers.email)}
          helperText={shouldShowEmailInputError(answers.email) && t("manage-user-page:emailBadFormat")}
          label={t("fields:email")}
          type="email"
          variant="outlined"
        />
      </Question>

      <Question questionId={"org_unit"} questionName={t("org_unit")}>
        <OrgunitsPathsAutocomplete
          value={answers.orgUnitId || ""}
          onBlur={setEnterCardInfo}
          disabled={noWritePermission}
          onFocus={() => {
            setInfo({
              title: t("enteringInfoCardTitle"),
              text: t("orgUnitInfo")
            });
          }}
          onChange={orgUnitId => {
            setAnswers(answers => ({ ...answers, orgUnitId: orgUnitId }));
            updateBasicDataHook({ orgUnitId: orgUnitId }).catch(catchAsSnackbar("failed to change request ID"));
          }}
          label={t("org_unit")}
        />
      </Question>
      <Question
        qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
        questionId={"furtherOrgUnitIds"}
        questionName={t("furtherDepartments")}
        disabled={noWritePermission}
        onFocus={() => {
          setInfo({
            title: t("enteringInfoCardTitle"),
            text: t("furtherOrgUnitsInfo")
          });
        }}
        value={answers.furtherOrgUnitIds || []}
        onChange={furtherOrgUnitIds => {
          setAnswers(answers => ({ ...answers, furtherOrgUnitIds: furtherOrgUnitIds }));
          updateBasicDataHook({ furtherOrgUnitIds }).catch(catchAsSnackbar("failed to change request ID"));
        }}
        onBlur={setEnterCardInfo}
      />
      <Question questionId={"request_type"} questionName={t("request_type")} disabled={noWritePermission}>
        <DSRRequestTypeField
          disabled={noWritePermission}
          showSpeedDial={focusedElement === "description"}
          values={answers.type || []}
          onChanges={onRequestTypeChange}
          onFocus={() => {
            setInfo({ title: t("enteringInfoCardTitle"), text: t("request_type_info_card_text") });
            setFocusedElement("");
          }}
          furtherOrgUnitIds={answers.furtherOrgUnitIds}
          orgUnitId={answers.orgUnitId || ""}
        />
      </Question>
      {answersLoaded && (
        <Box mb={3}>
          <Question
            questionId={"description"}
            questionName={t("description")}
            translatable={answers.description}
            translationId={"description"}
            title={t("description")}
            disabled={noWritePermission}
          >
            <TextEditor
              onBlur={() => {
                updateBasicDataHook({ description: answers.description }).catch(
                  catchAsSnackbar("failed to update description")
                );
              }}
              disabled={noWritePermission}
              onChange={value => {
                setAnswers({ ...answers, description: value });
              }}
              inputValue={answers.description}
              onFocus={() => {
                setInfo({
                  title: t("enteringInfoCardTitle"),
                  text: t("request_description_info_card_text")
                });
                setFocusedElement("description");
              }}
            />
          </Question>
        </Box>
      )}

      <Grid container spacing={spacing} direction={"row"}>
        <Grid item xs={6}>
          <Question questionId={"received_on"} questionName={t("received_on")} disabled={noWritePermission}>
            <DSRReceivedOnField
              onFocus={() => {
                setInfo({
                  title: t("enteringInfoCardTitle"),
                  text: t("request_received_on_info_card_text")
                });
                setFocusedElement("");
              }}
              disabled={noWritePermission}
              onBlur={() => setInfo(null)}
              createdDate={answers.created || null}
              value={answers.receivedOn || null}
              onChange={onReceivedOnChange}
            />
          </Question>
        </Grid>
        <Grid item xs={6}>
          <Question questionId={"due_date"} questionName={t("due_date")} disabled={noWritePermission}>
            <div className={classes.datePickerBottom}>
              <DueDate
                date={answers.dueDate || null}
                onDateChange={value => {
                  changeDueDateHook(value).catch(catchAsSnackbar("failed to update due date"));
                  setAnswers({ ...answers, dueDate: value });
                }}
                label={t("due_date")}
                onFocus={() => {
                  setInfo({
                    title: t("enteringInfoCardTitle"),
                    text: t("request_due_date_info_card_text")
                  });
                  setFocusedElement("");
                }}
                onBlur={() => setInfo(null)}
                disabled={answers.status === "DONE" || noWritePermission}
                withTime={true}
              />
            </div>
          </Question>
        </Grid>
      </Grid>
      <Grid container spacing={spacing} direction={"row"}>
        <Grid item xs={6}>
          <Question questionId={"assignedTo"} questionName={t("assignedTo")} disabled={noWritePermission}>
            <AssignUsersMultiAutocomplete
              id={"assignedTo"}
              docOrgUnitIds={allowedOrgUnitIDs}
              docAssignedUserIds={answers.assignedTo ? [answers.assignedTo] : []}
              onDocAssignedUserIdsChanged={onAssigneeChange}
              variant="outlined"
              onFocus={() => {
                setInfo({
                  title: t("enteringInfoCardTitle"),
                  text: t("request_assignedTo_info_card_text")
                });
                setFocusedElement("");
              }}
              onBlur={() => {
                setInfo(null);
              }}
              disabled={noWritePermission}
              freeSolo={true}
              disableClearable={true}
              label={t("assignedTo")}
              excludedUserIds={[]}
              hasDefaultValue={false}
            />
          </Question>
        </Grid>
        <Grid item xs={6}>
          <Question questionName={t("createdBy")}>
            <TextField
              id={"createdBy"}
              fullWidth={true}
              label={t("createdBy")}
              variant="outlined"
              disabled={true}
              value={getUserNameHook(answers.createdBy)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PeopleAltIcon className={classes.peoplesIcon} />
                  </InputAdornment>
                )
              }}
            />
          </Question>
        </Grid>
      </Grid>
      <Grid container spacing={spacing} direction={"row"}>
        <Grid item xs={12}>
          <Question questionId={"labelIds"} questionName={t("labels")} disabled={noWritePermission}>
            <LabelField
              disabled={noWritePermission}
              selectedIDs={answers.labelIds}
              onSelectionChanged={value => {
                setAnswers(answers => ({ ...answers, labelIds: value || [] }));
                updateBasicDataHook({ labelIds: value || [] }).catch(catchAsSnackbar("failed to update labels"));
              }}
              orgUnitIds={answers ? [answers.orgUnitId, ...(answers.furtherOrgUnitIds || [])] : []}
            />
          </Question>
        </Grid>
      </Grid>
      <Grid container spacing={spacing} direction={"row"}>
        <Grid item xs={12}>
          <DataSubjectRequestsPageButtons
            leftArea={
              <Tooltip
                title={
                  (tasksUnfinished && t("done_open_tasks")) || (answers.status === "DONE" && t("done_already_done"))
                }
              >
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !answers || answers.status === "DONE" || markAsDoneLoading || tasksUnfinished || noWritePermission
                    }
                    onClick={() => setConfirmModalOpen(true)}
                  >
                    {answers.status === "DONE" && (
                      <Box sx={{ display: "flex", alignItems: "center", gap: 8 }}>
                        <DoubleCheckIconSVG fill="rgba(0, 0, 0, 0.38)" />
                        <span>{t("overdue:done")}</span>
                      </Box>
                    )}
                    {answers.status !== "DONE" && t("task_details:mark_done")}
                  </Button>
                </Box>
              </Tooltip>
            }
          />
        </Grid>
      </Grid>
    </DocView>
  );

  if (!answersLoaded) {
    return (
      <Grid container justifyContent="center" spacing={3}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <DocMetaView
      docViewContent={docViewContent}
      metaViewContent={
        <MetaView
          docId={documentId}
          tabs={["assistant", "comments", "todos"]}
          collection={COLLECTIONS.DATA_SUBJECT_REQUESTS}
          translationKey={"data_subject_requests_overview_general_page"}
        />
      }
    />
  );
}
