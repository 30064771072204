import { useTranslation } from "react-i18next";
import { useResources } from "../../contexts/resource-context";
import { useDataTypeTree } from "../../api/dataAssetApi";
import { RESOURCE_TYPES } from "../../handlers/resourceHandler";
import React, { useCallback, useMemo } from "react";
import { getPersonGroupCategoryTupleString, toLegacyCategoryTypeTuple } from "./utils/helper-functions";

import { CategoryTypeTupleModel } from "./CategoryTypeTupleModel";
import { Box, Chip } from "@mui/material";

export const PersonGroupCategoryChip = ({
  selected,
  categoryTypeTuple,
  onClick
}: {
  readonly selected: boolean;
  readonly categoryTypeTuple: CategoryTypeTupleModel;
  readonly onClick?: (val: CategoryTypeTupleModel) => void;
}) => {
  const { t } = useTranslation("questionnaires");
  const { resourcesLoaded, resources } = useResources();
  const { data: dataTypeTreeData, dataById } = useDataTypeTree();

  const dataClassifications = resources[RESOURCE_TYPES.DATA_CLASSIFICATION];
  const label = useMemo(() => {
    if (!resourcesLoaded) {
      return "";
    }
    return getPersonGroupCategoryTupleString(
      toLegacyCategoryTypeTuple(categoryTypeTuple, dataById),
      dataClassifications,
      dataTypeTreeData || [],
      t
    );
  }, [categoryTypeTuple, dataClassifications, dataTypeTreeData, dataById, resourcesLoaded, t]);

  const onClickCallback = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClick?.(categoryTypeTuple);
    },
    [categoryTypeTuple, onClick]
  );

  if (!resourcesLoaded) {
    return <></>;
  }

  return (
    <Box mr={1} my={1} maxWidth={"100%"}>
      <Chip
        clickable={true}
        key={categoryTypeTuple.id}
        color={selected ? "primary" : undefined}
        label={label}
        onClick={onClickCallback}
      />
    </Box>
  );
};
