import { IconButton } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { t } from "i18next";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";
import { useSnackbar } from "notistack";
import { triggerResetPasswordApi } from "app/api/user/userPasswordResetApi";

const UserOverviewBrickResetPassword = ({ item, hovered }: OverviewRowBrickProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const onCLickCallback = useCallback(
    async event => {
      stopEvent(event);

      if (!item.email) {
        enqueueSnackbar("Selected user have no email", { variant: "error" });
        throw new Error("Selected user have no email");
      }

      try {
        await triggerResetPasswordApi({ email: item.email });
        enqueueSnackbar(t("manage-user-page:user_resetted"), { variant: "success" });
      } catch (error) {
        console.error("failure on sending password reset email", error);
      }
    },
    [enqueueSnackbar, item.email]
  );

  if (!hovered || item?.disableActions?.some(it => it.action === "reset")) {
    return <></>;
  }

  return (
    <Tooltip title={t("manage-user-page:reset")}>
      <IconButton onClick={onCLickCallback} size="small">
        <VpnKeyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default UserOverviewBrickResetPassword;
