import { useAuthentication } from "../../handlers/authentication/authentication-context";
import DocView from "../../../components/DocView/DocView";
import { Box, Button, LinearProgress } from "@mui/material";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import React, { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { getHasuraClientSDK } from "../../api/hasuraApi";
import TextBody2 from "../../../components/TextBody2/TextBody2";
import { getLocalImportData } from "./localImporterData";

export const Importer = ({
  importAPIFn,
  localImport
}: {
  readonly importAPIFn: (params: { readonly base64Excel: string }) => Promise<{ readonly runId: string }>;
  readonly localImport?: boolean;
}) => {
  const { auth } = useAuthentication();

  const [fileContent, setFileContent] = useState<string>("");
  const onFileSelected = useCallback(event => {
    const inputFiles = event?.target?.files || [];
    const inputFile = inputFiles[0] || null;
    if (!inputFile) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onloadend = () => setFileContent(((fileReader.result as string) || "").split("base64,")[1] || "");
    fileReader.readAsDataURL(inputFile);
  }, []);

  const [runId, setRunId] = useState("");
  const [runningJob, setRunningJob] = useState(false);
  const importCallback = useCallback(async () => {
    const response = await importAPIFn({ base64Excel: fileContent });
    setRunId(response.runId);
    setRunningJob(true);
  }, [fileContent, importAPIFn]);

  const [lastMessage, setLastMessage] = useState("");
  const { data: tenantJobMessage } = useSWR(
    auth?.tenantId && runId ? [auth?.tenantId, runId] : null,
    async keys => {
      const [tenantId, runId] = keys;
      if (localImport) {
        return await getLocalImportData(runId);
      }
      const client = await getHasuraClientSDK();
      const response = await client.tenantJobGetLastLog({
        tenant_id: tenantId,
        job_id: runId
      });
      return response.frontend_access_tenant_job;
    },
    {
      refreshInterval: localImport ? 50 : 1000
    }
  );

  useEffect(() => {
    if (!tenantJobMessage) {
      return;
    }

    const infoData = tenantJobMessage[tenantJobMessage.length - 1];
    if (!infoData) {
      return;
    }

    setLastMessage(infoData.message || "");
    if (infoData.message === "success") {
      setRunningJob(false);
    }
    if (infoData.error_message) {
      console.error(infoData.error_message);
      setRunningJob(false);
    }
  }, [tenantJobMessage]);

  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"importer"} />}>
      <DocView header="Importer">
        <Box mb={4}>
          <QuestionnaireSubHeader text="Excel File" />
          <input accept={xlsxFormats.join(", ")} id="raised-button-file" onChange={onFileSelected} type="file" />
        </Box>
        {localImport ? (
          <Box mb={4}>
            <TextBody2 text={"This job runs locally in browser, so please keep the tab open until done."} />
          </Box>
        ) : (
          <></>
        )}
        <Button variant="contained" color="primary" onClick={importCallback} disabled={!fileContent}>
          Import
        </Button>
        <Box mt={4}>
          {runningJob && <LinearProgress />}
          <pre>{lastMessage}</pre>
        </Box>
      </DocView>
    </DocMetaView>
  );
};

const xlsxFormats = [".xls", ".xlsx"];
