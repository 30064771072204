import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import { AssetStatusEnum } from "app/api/generated/asset-service";
import { COLLECTIONS } from "app/collections";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { updateAsset } from "app/pages/assets/handler/assetHandler";
import { ASSET_STATUS, ASSET_STATUS_TYPES } from "app/pages/assets/types/AssetTypes";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import stopEvent from "tool/stopEvent";

const AssetOverviewBrickStatus = ({ item }: OverviewRowBrickProps) => {
  const { id, status, permission } = item;
  const disabled = permission === "read";

  const { t } = useTranslation();
  const dispatch = useOverviewDispatch();

  const [ref, setRef] = useState<Element | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const reload = useCallback(() => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.ASSETS, reload });
  }, [dispatch]);

  const openMenuCallback = useCallback(event => {
    stopEvent(event);
    setRef(event?.currentTarget);
    setOpen(true);
  }, []);

  const closeMenuCallback = useCallback(() => {
    setRef(null);
    setOpen(false);
  }, []);

  const onChangeCallback = useCallback(
    async (_status: string) => {
      closeMenuCallback();
      if (!_status) {
        return;
      }
      if (_status === status) {
        return;
      }
      if (!Object.values(ASSET_STATUS).includes(_status as ASSET_STATUS_TYPES)) {
        return;
      }
      await updateAsset(id, { status: _status as ASSET_STATUS_TYPES });

      reload();
    },
    [closeMenuCallback, id, reload, status]
  );

  const statuses = useMemo(
    () => [
      {
        id: AssetStatusEnum.Planned,
        icon: <LazySvgIcon name="Planned" />,
        label: t(`assets_overview:${AssetStatusEnum.Planned.toLocaleLowerCase()}`),
        status: AssetStatusEnum.Planned,
        selected: status === AssetStatusEnum.Planned
      },
      {
        id: AssetStatusEnum.Testing,
        icon: <LazySvgIcon name="Testing" />,
        label: t(`assets_overview:${AssetStatusEnum.Testing.toLocaleLowerCase()}`),
        status: AssetStatusEnum.Testing,
        selected: status === AssetStatusEnum.Testing
      },
      {
        id: AssetStatusEnum.Active,
        icon: <LazySvgIcon name="Completed" />,
        label: t(`assets_overview:${AssetStatusEnum.Active.toLocaleLowerCase()}`),
        status: AssetStatusEnum.Active,
        selected: status === AssetStatusEnum.Active
      },
      {
        id: AssetStatusEnum.Inactive,
        icon: <LazySvgIcon name="On_Halt" />,
        label: t(`assets_overview:${AssetStatusEnum.Inactive.toLocaleLowerCase()}`),
        status: AssetStatusEnum.Inactive,
        selected: status === AssetStatusEnum.Inactive
      }
    ],
    [status, t]
  );

  const currentStatus = useMemo(() => {
    return statuses.find(it => it.status === status);
  }, [statuses, status]);

  const statusEl = useMemo(
    () => (
      <Tooltip title={currentStatus?.label}>
        <Box sx={{ width: "24px", height: "24px" }} onClick={openMenuCallback}>
          {currentStatus?.icon}
        </Box>
      </Tooltip>
    ),
    [currentStatus?.icon, currentStatus?.label, openMenuCallback]
  );

  if (!status) {
    return <></>;
  }

  if (disabled) {
    return statusEl;
  }

  return (
    <ClickAwayListener onClickAway={closeMenuCallback}>
      <>
        {statusEl}
        <DropDownMenu
          open={open}
          anchorEl={ref}
          items={statuses}
          onChange={onChangeCallback}
          onClose={closeMenuCallback}
        />
      </>
    </ClickAwayListener>
  );
};

export default AssetOverviewBrickStatus;
