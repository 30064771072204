import { getTextFromTextEditorJsonString } from "../../questionnaires/utils/textEditorConverter";
import { jsonToXlsxHandler } from "../../../handlers/dataToXlsxHandler";
import { getDataSubjectRequest } from "../../../handlers/dataSubjectRequestHandler";
import { getDocumentTitle } from "../../../utils/get-title-for-exported-document";
import { getUserName } from "app/utils/get-user-name";
import { toJSDateObjectIfISO8601 } from "../../../handlers/utility/date-helper";
import { getUsers } from "../../../handlers/userAndTenant/userHandler";
import { getTenantInformation } from "../../../handlers/tenantHandler";
import { getAllResources, RESOURCE_TYPES, translateResourceById } from "../../../handlers/resourceHandler";
import { getOrgUnits } from "../../../handlers/departmentHandler";
import { chunk } from "lodash-es";
import { getAllTasksByDocumentId } from "../../../handlers/tasksHandler";
import { sortTasks } from "../dsrTaskOrder";

const objectKeysToTranslationKeyMapping = {
  requestTitle: "request_title",
  requestId: "request_id",
  requestType: "request_type",
  requestDescription: "description",
  affectedOrgUnit: "org_unit",
  receivedOn: "received_on",
  dueDate: "due_date",
  assignedTo: "assignedTo",
  createdBy: "createdBy",
  dsrCorrectlyVerified: "hasVerified",
  taskTitle: "task_title",
  status: "status",
  firstName: "firstName",
  lastName: "lastName",
  furtherOrgUnitName: "further_org_unit_name"
};

function getColumnDataAsJson(dsrs, t, i18n, users, orgUnits, resources) {
  if (!dsrs || !dsrs.length) {
    return "";
  }
  let rows = [];
  const rowsFirstTab = getColumnDataGeneralInformation(dsrs, t, i18n, users, orgUnits, resources);
  const rowsSecondTab = getColumnDataTaskTab(dsrs, t, i18n, users);
  const rowsThirdTab = getColumnDataFurtherOrgUnitTab(dsrs, t, i18n, orgUnits);
  rows.push(rowsFirstTab);
  rows.push(rowsSecondTab);
  rows.push(rowsThirdTab);
  return rows;
}

function getColumnDataTaskTab(dsrs, t, i18n, users) {
  const usersMap = new Map(users.map(user => [user.id, user]));
  return dsrs
    .map(dsr => {
      return (dsr.tasks || []).map(task => {
        const tasksOfDSR = {
          requestId: dsr?.inputData?.id,
          taskTitle: truncateTooLongStringForCell(task.title || ""),
          assignedTo: truncateTooLongStringForCell(getUserName(usersMap, task.assigneeUID) || ""),
          dueDate: task.dueAt ? new Date(task.dueAt) : "",
          status: task.status ? t("tasks_page:" + task.status) : ""
        };
        const dataWithTranslatedKeys = translateKeys(
          tasksOfDSR,
          "dsrExportData",
          i18n,
          t,
          objectKeysToTranslationKeyMapping
        );
        return dataWithTranslatedKeys;
      });
    })
    .flat();
}

function getColumnDataGeneralInformation(dsrs, t, i18n, users, orgUnits, resources) {
  const usersMap = new Map(users.map(user => [user.id, user]));
  return dsrs.map(dsr => {
    const description = truncateTooLongStringForCell(getTextFromTextEditorJsonString(dsr?.inputData?.description));
    const orgUnit = orgUnits.get(dsr?.inputData?.orgUnitId);
    const isDeleted = !orgUnit || orgUnit.deleted === true;
    const orgUnitName = isDeleted
      ? t("lists_data_types_categories_person_groups:deletedEntry")
      : orgUnit?.fullName || "";
    const data = {
      requestTitle: truncateTooLongStringForCell(dsr?.inputData?.title),
      requestId: dsr?.inputData?.id,
      requestType: dsr?.inputData?.type
        ? truncateTooLongStringForCell(
            dsr.inputData.type
              .map(type =>
                translateResourceById({
                  t,
                  resourceType: RESOURCE_TYPES.DSR_REQUEST_TYPE,
                  resourceId: type,
                  resources: Object.entries(resources).reduce((map, [type, resources]) => {
                    if (type === RESOURCE_TYPES.DSR_REQUEST_TYPE) {
                      let resourcesMap = (resources || []).reduce((acc, resource) => {
                        acc.set(resource.id, resource);
                        return acc;
                      }, new Map());
                      map.set(type, resourcesMap);
                    }
                    return map;
                  }, new Map())
                })
              )
              .join(", ")
          )
        : "",
      requestDescription: truncateTooLongStringForCell(description || ""),
      affectedOrgUnit: orgUnitName,
      receivedOn: toJSDateObjectIfISO8601(dsr?.inputData?.receivedOn)?.toLocaleDateString() || null,
      dueDate: toJSDateObjectIfISO8601(dsr?.inputData?.dueDate)?.toLocaleDateString() || null,
      assignedTo: dsr?.inputData?.assignedTo ? getUserName(usersMap, dsr.inputData.assignedTo) : "",
      createdBy: dsr?.createdBy ? getUserName(usersMap, dsr.createdBy) : "",
      dsrCorrectlyVerified: dsr?.inputData?.isDataSubjectVerified
        ? t("common:" + dsr.inputData.isDataSubjectVerified)
        : "",
      firstName: truncateTooLongStringForCell(dsr?.inputData?.firstName || ""),
      lastName: truncateTooLongStringForCell(dsr?.inputData?.lastName || "")
    };
    const dataWithTranslatedKeys = translateKeys(data, "dsrExportData", i18n, t, objectKeysToTranslationKeyMapping);
    return dataWithTranslatedKeys;
  });
}

function getColumnDataFurtherOrgUnitTab(dsrs, t, i18n, orgUnits) {
  return dsrs.flatMap(dsr =>
    dsr.inputData.furtherOrgUnitIds.map(orgUnitId => {
      const orgUnit = orgUnits.get(orgUnitId);
      const isDeleted = !orgUnit || orgUnit.deleted === true;
      const orgUnitName = isDeleted
        ? t("lists_data_types_categories_person_groups:deletedEntry")
        : orgUnit?.fullName || "";
      const furtherOrgUnitData = {
        requestId: dsr?.inputData?.id,
        requestTitle: truncateTooLongStringForCell(dsr?.inputData?.title),
        furtherOrgUnitName: orgUnitName
      };
      const dataWithTranslatedKeys = translateKeys(
        furtherOrgUnitData,
        "dsrExportData",
        i18n,
        t,
        objectKeysToTranslationKeyMapping
      );
      return dataWithTranslatedKeys;
    })
  );
}

export async function exportDataSubjectRequest(tenantId, t, i18n, dsrIds) {
  //get necessary data from db
  const [tenantData, users, orgUnits, resources] = await Promise.all([
    getTenantInformation(tenantId),
    getUsers(),
    getOrgUnits(),
    getAllResources({ showAllSetsIgnoringTenantFeatures: true })
  ]);

  //dsrId is passed if a single dsr is exported otherwise all dsrs are exported
  const dsrsToExport = [];
  const fetchDSRAndTask = async dsrId => {
    const dsr = await getDataSubjectRequest(dsrId);
    const requestTypes = dsr.inputData?.type || [];
    const tasks = await getAllTasksByDocumentId(dsr.id);
    const sortedTasks = sortTasks(requestTypes, tasks);
    return {
      ...dsr,
      tasks: sortedTasks
    };
  };
  for (const dsrIdsChunk of chunk(dsrIds || [], 10)) {
    const dsrs = await Promise.all(dsrIdsChunk.map(fetchDSRAndTask));
    dsrsToExport.push(...dsrs);
  }

  const orgUnitsMap = new Map(orgUnits.map(orgUnit => [orgUnit.id, orgUnit]));
  //generate document content
  const [dsrRows, dsrTaskRows, dsrFurtherOrgUnitRows] = getColumnDataAsJson(
    dsrsToExport,
    t,
    i18n,
    users,
    orgUnitsMap,
    resources
  );
  jsonToXlsxHandler(getDocumentTitle(tenantData.name, t("dsrExportData:export_heading")), [
    {
      rows: dsrRows,
      tabName: t("dsrExportData:export_heading_general"),
      columnWidths: [30, 20, 30, 30, 20, 20, 15, 20, 20, 30, 20, 20]
    },
    {
      rows: dsrTaskRows,
      tabName: t("dsrExportData:export_heading_tasks"),
      columnWidths: [15, 35, 30, 15, 15]
    },
    {
      rows: dsrFurtherOrgUnitRows,
      tabName: t("dsrExportData:export_heading_further_org_units"),
      columnWidths: [15, 40, 60]
    }
  ]);
}

function translateKeys(data, translationKey, i18n, t, objectKeysToTranslationKeyMapping) {
  let objectWithTranslatedKeys = {};
  Object.entries(data).forEach(([key, value]) => {
    const valueTranslationKey = objectKeysToTranslationKeyMapping[key];
    const translatedValue = i18n.exists(translationKey + ":" + valueTranslationKey)
      ? t(translationKey + ":" + valueTranslationKey)
      : key;
    objectWithTranslatedKeys[translatedValue] = value;
  });
  return objectWithTranslatedKeys;
}

function truncateTooLongStringForCell(string) {
  if (!string) {
    return string;
  }
  if (typeof string !== "string") {
    return string;
  }
  const maxLength = 32767;
  return string.length > maxLength ? string.slice(0, maxLength) : string;
}
