import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import QuestionTitle from "components/QuestionTitle/QuestionTitle";
import { useParams } from "react-router-dom";
import { getTextFromTextEditorJsonString } from "app/pages/questionnaires/utils/textEditorConverter";
import TranslatorMenu from "app/pages/shared/TranslatorMenu/TranslatorMenu";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useTranslator } from "app/contexts/translator-context";
import Popper from "@mui/material/Popper";
import { Box, IconButton, useTheme } from "@mui/material";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SxProps } from "@mui/system/styleFunctionSx";
import { PopperOwnProps } from "@mui/material/Popper/BasePopper.types";

interface TranslationQuestionProps {
  readonly title?: string;
  readonly questionId?: string;
  readonly questionName?: string;
  readonly disabled?: boolean;
  readonly translatable?: string;
  readonly translationId?: string;
  readonly sx?: SxProps;
  readonly onFocus?: () => void;
  readonly children?: React.ReactNode;
}

export const TranslationPopper = ({
  translationId,
  anchorTranslate,
  hasContentToTranslate,
  onSelectLanguage
}: {
  readonly translationId: string;
  readonly anchorTranslate: PopperOwnProps["anchorEl"];
  readonly hasContentToTranslate: boolean;
  readonly onSelectLanguage?: () => void;
}) => {
  const hasTranslationFeature = useIsFeaturePresent(FEATURES.DEEP_L);

  return hasTranslationFeature && hasContentToTranslate ? (
    <Popper open={!!anchorTranslate} anchorEl={anchorTranslate} placement={"right-start"} style={popperZIndex}>
      <Box ml={1}>
        <TranslatorMenu translationId={translationId} onSelectLanguage={onSelectLanguage} />
      </Box>
    </Popper>
  ) : (
    <></>
  );
};

// popper doesn't have z-index for philosophical reason
// https://github.com/mui/material-ui/issues/18905
// however, sometimes we open stuffs inside a modal, so it have to be higher
const zIndexHigherThanInsideOfAModal = 1301;
const popperZIndex: CSSProperties = { zIndex: zIndexHigherThanInsideOfAModal };

export const TranslationResult = ({
  translationId,
  contentToTranslate
}: {
  readonly translationId: string;
  readonly contentToTranslate: string;
}) => {
  const hasTranslationFeature = useIsFeaturePresent(FEATURES.DEEP_L);
  const { findTranslation, translateHook, setTextToTranslateHook } = useTranslator();
  const [translatedText, setTranslatedText] = useState("");

  useEffect(() => {
    setTextToTranslateHook(translationId, contentToTranslate);
    const newTranslation = { ...findTranslation(translationId), textToTranslate: contentToTranslate };
    translateHook(newTranslation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationId, contentToTranslate, setTextToTranslateHook, translateHook]);

  useEffect(() => {
    const foundTranslation = findTranslation(translationId);
    if (foundTranslation) {
      setTranslatedText(foundTranslation.translatedText || "");
    } else {
      setTranslatedText("");
    }
  }, [findTranslation, translationId]);

  const theme = useTheme();
  const boxResultEl = (
    <Box mt={1} p={2} borderRadius={6} bgcolor={theme.palette.deepPurple[50]}>
      <p data-testid="form-helper-text" style={translatedTextStyle} color={theme.palette.deepPurple[500]}>
        {translatedText}
      </p>
    </Box>
  );

  return hasTranslationFeature && translatedText ? boxResultEl : <></>;
};

const translatedTextStyle: CSSProperties = {
  width: "100%",
  whiteSpace: "pre-wrap",
  fontSize: 14
};

export const TranslationQuestion = ({ title, translatable, translationId, sx, children }: TranslationQuestionProps) => {
  const [focused, setFocused] = useState(false);
  const [anchorTranslate, setAnchorTranslate] = useState(null);
  const [contentToTranslate, setContentToTranslate] = useState("");
  const hasTranslationFeature = useIsFeaturePresent(FEATURES.DEEP_L);
  useEffect(() => {
    setContentToTranslate(getTextFromTextEditorJsonString(translatable || ""));
  }, [translatable]);

  const params = useParams();
  const documentId = params?.id;
  const id = `translationId_${documentId}_${translationId}`;

  const onOpen = useCallback(event => {
    setAnchorTranslate(event.currentTarget);
    setFocused(true);
  }, []);

  const onClose = useCallback(() => {
    setTimeout(() => {
      setAnchorTranslate(null);
      setFocused(false);
    }, 1);
  }, []);

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    // do
  }, []);

  const titleEl = title && <QuestionTitle>{title}</QuestionTitle>;

  const translateButtonEl = focused && (
    <IconButton
      color="primary"
      aria-label="translation"
      sx={title ? translateButtonWithTitle : translateButtonNoTitle}
      onClick={onOpen}
    >
      <TranslateOutlinedIcon fontSize="small" />
    </IconButton>
  );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box
        position="relative"
        pt={3}
        pr={3}
        mr={-3}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={1}
        sx={{ ...sxPointer, ...sx } as SxProps}
      >
        {titleEl}
        {children}
        {hasTranslationFeature && (
          <>
            {translateButtonEl}
            <TranslationPopper
              translationId={id}
              anchorTranslate={anchorTranslate}
              hasContentToTranslate={!!contentToTranslate}
              onSelectLanguage={onClose}
            />
            <TranslationResult translationId={id} contentToTranslate={contentToTranslate} />
          </>
        )}
      </Box>
    </ClickAwayListener>
  );
};

const sxPointer: SxProps = {
  cursor: "pointer"
};

const translateButtonNoTitle: SxProps = {
  position: "absolute",
  top: 16,
  right: -22
};

const translateButtonWithTitle: SxProps = {
  position: "absolute",
  top: 44,
  right: -22
};
