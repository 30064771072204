/* tslint:disable */
/* eslint-disable */
/**
 * Process Service API
 * Process Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddAssessmentMeasureRequest
 */
export interface AddAssessmentMeasureRequest {
    /**
     * 
     * @type {string}
     * @memberof AddAssessmentMeasureRequest
     */
    'measure': string;
    /**
     * 
     * @type {string}
     * @memberof AddAssessmentMeasureRequest
     */
    'priority': string;
}
/**
 * 
 * @export
 * @interface AiPreCheckResultDTO
 */
export interface AiPreCheckResultDTO {
    /**
     * 
     * @type {boolean}
     * @memberof AiPreCheckResultDTO
     */
    'aiCheckRequired'?: boolean;
    /**
     * 
     * @type {AiRiskLevel}
     * @memberof AiPreCheckResultDTO
     */
    'aiRiskLevel'?: AiRiskLevel | null;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'aiRiskLevelExplanation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AiPreCheckResultDTO
     */
    'isAiRiskLevelOverriddenByUser'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AiPreCheckResultDTO
     */
    'isAiRiskLevelExplanationOverriddenByUser'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AiPreCheckResultDTO
     */
    'shownQuestions'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isPotentiallyInadmissible'?: AiPreCheckResultDTOIsPotentiallyInadmissibleEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isUsedInBiometricIdentification'?: AiPreCheckResultDTOIsUsedInBiometricIdentificationEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isUsedInCriticalInfrastructure'?: AiPreCheckResultDTOIsUsedInCriticalInfrastructureEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isUsedInEducation'?: AiPreCheckResultDTOIsUsedInEducationEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isUsedInRecruitment'?: AiPreCheckResultDTOIsUsedInRecruitmentEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isUsedInPublicService'?: AiPreCheckResultDTOIsUsedInPublicServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isUsedInLawEnforcement'?: AiPreCheckResultDTOIsUsedInLawEnforcementEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isUsedInMigration'?: AiPreCheckResultDTOIsUsedInMigrationEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isUsedInJusticeProcess'?: AiPreCheckResultDTOIsUsedInJusticeProcessEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isMakingAutomatedDecision'?: AiPreCheckResultDTOIsMakingAutomatedDecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isRiskAnalysisMade'?: AiPreCheckResultDTOIsRiskAnalysisMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isMeasureTransparencyMade'?: AiPreCheckResultDTOIsMeasureTransparencyMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isMeasureHumanControlMade'?: AiPreCheckResultDTOIsMeasureHumanControlMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isMeasureBiasTestingMade'?: AiPreCheckResultDTOIsMeasureBiasTestingMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isMeasureDataProtectionMade'?: AiPreCheckResultDTOIsMeasureDataProtectionMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isMeasureAuditControlMade'?: AiPreCheckResultDTOIsMeasureAuditControlMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isMeasureEthicsGuideMade'?: AiPreCheckResultDTOIsMeasureEthicsGuideMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isGivingHumanImpression'?: AiPreCheckResultDTOIsGivingHumanImpressionEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isContentPerceivableAsReal'?: AiPreCheckResultDTOIsContentPerceivableAsRealEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isUsedInNonCriticalArea'?: AiPreCheckResultDTOIsUsedInNonCriticalAreaEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPreCheckResultDTO
     */
    'isVoluntarilyMonitored'?: AiPreCheckResultDTOIsVoluntarilyMonitoredEnum;
}

export const AiPreCheckResultDTOIsPotentiallyInadmissibleEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsPotentiallyInadmissibleEnum = typeof AiPreCheckResultDTOIsPotentiallyInadmissibleEnum[keyof typeof AiPreCheckResultDTOIsPotentiallyInadmissibleEnum];
export const AiPreCheckResultDTOIsUsedInBiometricIdentificationEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsUsedInBiometricIdentificationEnum = typeof AiPreCheckResultDTOIsUsedInBiometricIdentificationEnum[keyof typeof AiPreCheckResultDTOIsUsedInBiometricIdentificationEnum];
export const AiPreCheckResultDTOIsUsedInCriticalInfrastructureEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsUsedInCriticalInfrastructureEnum = typeof AiPreCheckResultDTOIsUsedInCriticalInfrastructureEnum[keyof typeof AiPreCheckResultDTOIsUsedInCriticalInfrastructureEnum];
export const AiPreCheckResultDTOIsUsedInEducationEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsUsedInEducationEnum = typeof AiPreCheckResultDTOIsUsedInEducationEnum[keyof typeof AiPreCheckResultDTOIsUsedInEducationEnum];
export const AiPreCheckResultDTOIsUsedInRecruitmentEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsUsedInRecruitmentEnum = typeof AiPreCheckResultDTOIsUsedInRecruitmentEnum[keyof typeof AiPreCheckResultDTOIsUsedInRecruitmentEnum];
export const AiPreCheckResultDTOIsUsedInPublicServiceEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsUsedInPublicServiceEnum = typeof AiPreCheckResultDTOIsUsedInPublicServiceEnum[keyof typeof AiPreCheckResultDTOIsUsedInPublicServiceEnum];
export const AiPreCheckResultDTOIsUsedInLawEnforcementEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsUsedInLawEnforcementEnum = typeof AiPreCheckResultDTOIsUsedInLawEnforcementEnum[keyof typeof AiPreCheckResultDTOIsUsedInLawEnforcementEnum];
export const AiPreCheckResultDTOIsUsedInMigrationEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsUsedInMigrationEnum = typeof AiPreCheckResultDTOIsUsedInMigrationEnum[keyof typeof AiPreCheckResultDTOIsUsedInMigrationEnum];
export const AiPreCheckResultDTOIsUsedInJusticeProcessEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsUsedInJusticeProcessEnum = typeof AiPreCheckResultDTOIsUsedInJusticeProcessEnum[keyof typeof AiPreCheckResultDTOIsUsedInJusticeProcessEnum];
export const AiPreCheckResultDTOIsMakingAutomatedDecisionEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsMakingAutomatedDecisionEnum = typeof AiPreCheckResultDTOIsMakingAutomatedDecisionEnum[keyof typeof AiPreCheckResultDTOIsMakingAutomatedDecisionEnum];
export const AiPreCheckResultDTOIsRiskAnalysisMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsRiskAnalysisMadeEnum = typeof AiPreCheckResultDTOIsRiskAnalysisMadeEnum[keyof typeof AiPreCheckResultDTOIsRiskAnalysisMadeEnum];
export const AiPreCheckResultDTOIsMeasureTransparencyMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsMeasureTransparencyMadeEnum = typeof AiPreCheckResultDTOIsMeasureTransparencyMadeEnum[keyof typeof AiPreCheckResultDTOIsMeasureTransparencyMadeEnum];
export const AiPreCheckResultDTOIsMeasureHumanControlMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsMeasureHumanControlMadeEnum = typeof AiPreCheckResultDTOIsMeasureHumanControlMadeEnum[keyof typeof AiPreCheckResultDTOIsMeasureHumanControlMadeEnum];
export const AiPreCheckResultDTOIsMeasureBiasTestingMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsMeasureBiasTestingMadeEnum = typeof AiPreCheckResultDTOIsMeasureBiasTestingMadeEnum[keyof typeof AiPreCheckResultDTOIsMeasureBiasTestingMadeEnum];
export const AiPreCheckResultDTOIsMeasureDataProtectionMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsMeasureDataProtectionMadeEnum = typeof AiPreCheckResultDTOIsMeasureDataProtectionMadeEnum[keyof typeof AiPreCheckResultDTOIsMeasureDataProtectionMadeEnum];
export const AiPreCheckResultDTOIsMeasureAuditControlMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsMeasureAuditControlMadeEnum = typeof AiPreCheckResultDTOIsMeasureAuditControlMadeEnum[keyof typeof AiPreCheckResultDTOIsMeasureAuditControlMadeEnum];
export const AiPreCheckResultDTOIsMeasureEthicsGuideMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsMeasureEthicsGuideMadeEnum = typeof AiPreCheckResultDTOIsMeasureEthicsGuideMadeEnum[keyof typeof AiPreCheckResultDTOIsMeasureEthicsGuideMadeEnum];
export const AiPreCheckResultDTOIsGivingHumanImpressionEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsGivingHumanImpressionEnum = typeof AiPreCheckResultDTOIsGivingHumanImpressionEnum[keyof typeof AiPreCheckResultDTOIsGivingHumanImpressionEnum];
export const AiPreCheckResultDTOIsContentPerceivableAsRealEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsContentPerceivableAsRealEnum = typeof AiPreCheckResultDTOIsContentPerceivableAsRealEnum[keyof typeof AiPreCheckResultDTOIsContentPerceivableAsRealEnum];
export const AiPreCheckResultDTOIsUsedInNonCriticalAreaEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsUsedInNonCriticalAreaEnum = typeof AiPreCheckResultDTOIsUsedInNonCriticalAreaEnum[keyof typeof AiPreCheckResultDTOIsUsedInNonCriticalAreaEnum];
export const AiPreCheckResultDTOIsVoluntarilyMonitoredEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type AiPreCheckResultDTOIsVoluntarilyMonitoredEnum = typeof AiPreCheckResultDTOIsVoluntarilyMonitoredEnum[keyof typeof AiPreCheckResultDTOIsVoluntarilyMonitoredEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const AiRiskLevel = {
    Low: 'low',
    Limited: 'limited',
    High: 'high',
    Unacceptable: 'unacceptable'
} as const;

export type AiRiskLevel = typeof AiRiskLevel[keyof typeof AiRiskLevel];


/**
 * 
 * @export
 * @interface AssessmentMeasureDTO
 */
export interface AssessmentMeasureDTO {
    /**
     * 
     * @type {string}
     * @memberof AssessmentMeasureDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMeasureDTO
     */
    'measure': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMeasureDTO
     */
    'priority': string;
}
/**
 * 
 * @export
 * @interface CategoryTypeTupleDTO
 */
export interface CategoryTypeTupleDTO {
    /**
     * 
     * @type {string}
     * @memberof CategoryTypeTupleDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryTypeTupleDTO
     */
    'personGroup': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryTypeTupleDTO
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryTypeTupleDTO
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryTypeTupleDTO
     */
    'dataClassificationId': string | null;
}
/**
 * 
 * @export
 * @interface ChangePageDTO
 */
export interface ChangePageDTO {
    /**
     * 
     * @type {UpToDateDescriptionFieldDTO}
     * @memberof ChangePageDTO
     */
    'dataCorrectness'?: UpToDateDescriptionFieldDTO;
    /**
     * 
     * @type {UpToDateDescriptionFieldDTO}
     * @memberof ChangePageDTO
     */
    'processTestRequired'?: UpToDateDescriptionFieldDTO;
    /**
     * 
     * @type {UpToDateDescriptionFieldDTO}
     * @memberof ChangePageDTO
     */
    'newDPIAEvaluation'?: UpToDateDescriptionFieldDTO;
}
/**
 * 
 * @export
 * @interface CreatePARequest
 */
export interface CreatePARequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePARequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreatePurposeRequest
 */
export interface CreatePurposeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePurposeRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DpiaPageDTO
 */
export interface DpiaPageDTO {
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'blackOrWhitelist'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'profiling'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'automaticDecisionMaking'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'systematicMonitoring'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'specialCategories'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'largeQuantities'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'setComparison'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'vulnerablePersons'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'innovativeUse'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'preventionOfRights'?: string;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'dpiaRequiredDecision'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DpiaPageDTO
     */
    'dpiaRequiredUsersChoice'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DpiaPageDTO
     */
    'dpiaRequiredStandardAnswerIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof DpiaPageDTO
     */
    'dpiaRequiredStandardAnswerExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DpiaPageDTO
     */
    'vulnerablePersonsAutomaticDecision'?: boolean;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface ExternalLinkDTO
 */
export interface ExternalLinkDTO {
    /**
     * 
     * @type {string}
     * @memberof ExternalLinkDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLinkDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FinalAssessmentPageDTO
 */
export interface FinalAssessmentPageDTO {
    /**
     * 
     * @type {Array<AssessmentMeasureDTO>}
     * @memberof FinalAssessmentPageDTO
     */
    'assessmentMeasures': Array<AssessmentMeasureDTO>;
    /**
     * 
     * @type {string}
     * @memberof FinalAssessmentPageDTO
     */
    'assessment': string;
    /**
     * 
     * @type {string}
     * @memberof FinalAssessmentPageDTO
     */
    'consultationDPADescription': string;
    /**
     * 
     * @type {string}
     * @memberof FinalAssessmentPageDTO
     */
    'consultationDPARequired': string;
}
/**
 * 
 * @export
 * @interface GetAiAssets200Response
 */
export interface GetAiAssets200Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAiAssets200Response
     */
    'aiSystemAssetIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetAiPrecheckStatus200Response
 */
export interface GetAiPrecheckStatus200Response {
    /**
     * 
     * @type {ProcessMetaDTO}
     * @memberof GetAiPrecheckStatus200Response
     */
    'processMeta': ProcessMetaDTO;
    /**
     * 
     * @type {AiPreCheckResultDTO}
     * @memberof GetAiPrecheckStatus200Response
     */
    'processPage': AiPreCheckResultDTO;
}
/**
 * 
 * @export
 * @interface GetAiTechDocsPrecheckStatus200Response
 */
export interface GetAiTechDocsPrecheckStatus200Response {
    /**
     * 
     * @type {AiRiskLevel}
     * @memberof GetAiTechDocsPrecheckStatus200Response
     */
    'aiRiskLevel'?: AiRiskLevel | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAiTechDocsPrecheckStatus200Response
     */
    'allAIAssetsHaveValidTechDocs'?: boolean;
}


/**
 * 
 * @export
 * @interface GetChange200Response
 */
export interface GetChange200Response {
    /**
     * 
     * @type {ProcessMetaDTO}
     * @memberof GetChange200Response
     */
    'processMeta': ProcessMetaDTO;
    /**
     * 
     * @type {ChangePageDTO}
     * @memberof GetChange200Response
     */
    'processPage': ChangePageDTO;
}
/**
 * 
 * @export
 * @interface GetDPIAProcessorInfo200Response
 */
export interface GetDPIAProcessorInfo200Response {
    /**
     * 
     * @type {ProcessMetaDTO}
     * @memberof GetDPIAProcessorInfo200Response
     */
    'processMeta': ProcessMetaDTO;
    /**
     * 
     * @type {GetDPIAProcessorInfo200ResponseProcessPage}
     * @memberof GetDPIAProcessorInfo200Response
     */
    'processPage': GetDPIAProcessorInfo200ResponseProcessPage;
}
/**
 * 
 * @export
 * @interface GetDPIAProcessorInfo200ResponseProcessPage
 */
export interface GetDPIAProcessorInfo200ResponseProcessPage {
    /**
     * 
     * @type {Array<ProcessorPADTO>}
     * @memberof GetDPIAProcessorInfo200ResponseProcessPage
     */
    'processorPAs': Array<ProcessorPADTO>;
}
/**
 * 
 * @export
 * @interface GetDpia200Response
 */
export interface GetDpia200Response {
    /**
     * 
     * @type {ProcessMetaDTO}
     * @memberof GetDpia200Response
     */
    'processMeta': ProcessMetaDTO;
    /**
     * 
     * @type {DpiaPageDTO}
     * @memberof GetDpia200Response
     */
    'processPage': DpiaPageDTO;
}
/**
 * 
 * @export
 * @interface GetFinalAssessment200Response
 */
export interface GetFinalAssessment200Response {
    /**
     * 
     * @type {ProcessMetaDTO}
     * @memberof GetFinalAssessment200Response
     */
    'processMeta': ProcessMetaDTO;
    /**
     * 
     * @type {FinalAssessmentPageDTO}
     * @memberof GetFinalAssessment200Response
     */
    'processPage': FinalAssessmentPageDTO;
}
/**
 * 
 * @export
 * @interface GetImpactedAssets200Response
 */
export interface GetImpactedAssets200Response {
    /**
     * 
     * @type {ProcessMetaDTO}
     * @memberof GetImpactedAssets200Response
     */
    'processMeta': ProcessMetaDTO;
    /**
     * 
     * @type {GetImpactedAssets200ResponseProcessPage}
     * @memberof GetImpactedAssets200Response
     */
    'processPage': GetImpactedAssets200ResponseProcessPage;
}
/**
 * 
 * @export
 * @interface GetImpactedAssets200ResponseProcessPage
 */
export interface GetImpactedAssets200ResponseProcessPage {
    /**
     * 
     * @type {Array<ImpactedAssetDTO>}
     * @memberof GetImpactedAssets200ResponseProcessPage
     */
    'impactedAssets': Array<ImpactedAssetDTO>;
}
/**
 * 
 * @export
 * @interface GetMeasureOptions200Response
 */
export interface GetMeasureOptions200Response {
    /**
     * 
     * @type {Array<GetMeasureOptions200ResponseTomsInner>}
     * @memberof GetMeasureOptions200Response
     */
    'toms'?: Array<GetMeasureOptions200ResponseTomsInner>;
}
/**
 * 
 * @export
 * @interface GetMeasureOptions200ResponseTomsInner
 */
export interface GetMeasureOptions200ResponseTomsInner {
    /**
     * 
     * @type {string}
     * @memberof GetMeasureOptions200ResponseTomsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMeasureOptions200ResponseTomsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMeasureOptions200ResponseTomsInner
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMeasureOptions200ResponseTomsInner
     */
    'protectionObjectiveIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMeasureOptions200ResponseTomsInner
     */
    'labelIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetMeasureOptions200ResponseTomsInner
     */
    'processSpecific'?: boolean;
}
/**
 * 
 * @export
 * @interface GetProcessing200Response
 */
export interface GetProcessing200Response {
    /**
     * 
     * @type {ProcessMetaDTO}
     * @memberof GetProcessing200Response
     */
    'processMeta': ProcessMetaDTO;
    /**
     * 
     * @type {ProcessingPageDTO}
     * @memberof GetProcessing200Response
     */
    'processPage': ProcessingPageDTO;
}
/**
 * 
 * @export
 * @interface GetProcessorPADTO
 */
export interface GetProcessorPADTO {
    /**
     * 
     * @type {GetProcessorPADTOProcessorPA}
     * @memberof GetProcessorPADTO
     */
    'processorPA'?: GetProcessorPADTOProcessorPA;
    /**
     * 
     * @type {Array<OrgUnitDTO>}
     * @memberof GetProcessorPADTO
     */
    'orgUnits': Array<OrgUnitDTO>;
    /**
     * 
     * @type {Array<ExternalLinkDTO>}
     * @memberof GetProcessorPADTO
     */
    'pas': Array<ExternalLinkDTO>;
    /**
     * 
     * @type {Array<ExternalLinkDTO>}
     * @memberof GetProcessorPADTO
     */
    'ers': Array<ExternalLinkDTO>;
    /**
     * 
     * @type {Array<ExternalLinkDTO>}
     * @memberof GetProcessorPADTO
     */
    'pcs': Array<ExternalLinkDTO>;
    /**
     * 
     * @type {Array<ExternalLinkDTO>}
     * @memberof GetProcessorPADTO
     */
    'dls': Array<ExternalLinkDTO>;
    /**
     * 
     * @type {Array<GetProcessorPADTOMesInner>}
     * @memberof GetProcessorPADTO
     */
    'mes': Array<GetProcessorPADTOMesInner>;
}
/**
 * 
 * @export
 * @interface GetProcessorPADTOMesInner
 */
export interface GetProcessorPADTOMesInner {
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOMesInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOMesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOMesInner
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProcessorPADTOMesInner
     */
    'processSpecific'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOMesInner
     */
    'protectionObjectiveIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOMesInner
     */
    'labelIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetProcessorPADTOMesInnerAllOf
 */
export interface GetProcessorPADTOMesInnerAllOf {
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOMesInnerAllOf
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProcessorPADTOMesInnerAllOf
     */
    'processSpecific'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOMesInnerAllOf
     */
    'protectionObjectiveIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOMesInnerAllOf
     */
    'labelIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetProcessorPADTOProcessorPA
 */
export interface GetProcessorPADTOProcessorPA {
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'permission'?: GetProcessorPADTOProcessorPAPermissionEnum;
    /**
     * 
     * @type {GetProcessorPADTOProcessorPAAllOfSuggestions}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'suggestions'?: GetProcessorPADTOProcessorPAAllOfSuggestions;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'paIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'processorOrgUnitId': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'assignToUserId': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'privacyExpertUserId': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'dpoUserId': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'controllerOrgUnitIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'controllerExternalRecipientIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'processingCategoryIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'processingCategoryDescription': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'thirdWorldDataStorageIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'thirdWorldOrgUnitIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'thirdWorldExternalRecipientIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'measureIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPA
     */
    'labelIds': Array<string>;
}

export const GetProcessorPADTOProcessorPAPermissionEnum = {
    Read: 'read',
    Write: 'write'
} as const;

export type GetProcessorPADTOProcessorPAPermissionEnum = typeof GetProcessorPADTOProcessorPAPermissionEnum[keyof typeof GetProcessorPADTOProcessorPAPermissionEnum];

/**
 * 
 * @export
 * @interface GetProcessorPADTOProcessorPAAllOf
 */
export interface GetProcessorPADTOProcessorPAAllOf {
    /**
     * 
     * @type {string}
     * @memberof GetProcessorPADTOProcessorPAAllOf
     */
    'permission'?: GetProcessorPADTOProcessorPAAllOfPermissionEnum;
    /**
     * 
     * @type {GetProcessorPADTOProcessorPAAllOfSuggestions}
     * @memberof GetProcessorPADTOProcessorPAAllOf
     */
    'suggestions'?: GetProcessorPADTOProcessorPAAllOfSuggestions;
}

export const GetProcessorPADTOProcessorPAAllOfPermissionEnum = {
    Read: 'read',
    Write: 'write'
} as const;

export type GetProcessorPADTOProcessorPAAllOfPermissionEnum = typeof GetProcessorPADTOProcessorPAAllOfPermissionEnum[keyof typeof GetProcessorPADTOProcessorPAAllOfPermissionEnum];

/**
 * 
 * @export
 * @interface GetProcessorPADTOProcessorPAAllOfSuggestions
 */
export interface GetProcessorPADTOProcessorPAAllOfSuggestions {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPAAllOfSuggestions
     */
    'controllerOrgUnitIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPAAllOfSuggestions
     */
    'controllerExternalRecipientIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPAAllOfSuggestions
     */
    'thirdWorldDataStorageIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPAAllOfSuggestions
     */
    'thirdWorldOrgUnitIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPAAllOfSuggestions
     */
    'thirdWorldExternalRecipientIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPAAllOfSuggestions
     */
    'measureIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProcessorPADTOProcessorPAAllOfSuggestions
     */
    'labelIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetProportionality200Response
 */
export interface GetProportionality200Response {
    /**
     * 
     * @type {ProcessMetaDTO}
     * @memberof GetProportionality200Response
     */
    'processMeta': ProcessMetaDTO;
    /**
     * 
     * @type {ProportionalityPageDTO}
     * @memberof GetProportionality200Response
     */
    'processPage': ProportionalityPageDTO;
}
/**
 * 
 * @export
 * @interface GetRights200Response
 */
export interface GetRights200Response {
    /**
     * 
     * @type {ProcessMetaDTO}
     * @memberof GetRights200Response
     */
    'processMeta': ProcessMetaDTO;
    /**
     * 
     * @type {RightsPageDTO}
     * @memberof GetRights200Response
     */
    'processPage': RightsPageDTO;
}
/**
 * 
 * @export
 * @interface ImpactedAssetDTO
 */
export interface ImpactedAssetDTO {
    /**
     * 
     * @type {string}
     * @memberof ImpactedAssetDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpactedAssetDTO
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpactedAssetDTO
     */
    'aiRoleId'?: string;
}
/**
 * 
 * @export
 * @interface OkResponse
 */
export interface OkResponse {
    /**
     * 
     * @type {string}
     * @memberof OkResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface OrgUnitDTO
 */
export interface OrgUnitDTO {
    /**
     * 
     * @type {string}
     * @memberof OrgUnitDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrgUnitDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrgUnitDTO
     */
    'parentId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrgUnitDTO
     */
    'deleted': boolean | null;
}
/**
 * 
 * @export
 * @interface PAPermissionsDTO
 */
export interface PAPermissionsDTO {
    /**
     * 
     * @type {string}
     * @memberof PAPermissionsDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PAPermissionsDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PAPermissionsDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof PAPermissionsDTO
     */
    'permission': PAPermissionsDTOPermissionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PAPermissionsDTO
     */
    'isExpert'?: boolean;
    /**
     * 
     * @type {PAPermissionsDTOPagesPermission}
     * @memberof PAPermissionsDTO
     */
    'pagesPermission': PAPermissionsDTOPagesPermission;
}

export const PAPermissionsDTOPermissionEnum = {
    Read: 'read',
    Write: 'write',
    None: 'none'
} as const;

export type PAPermissionsDTOPermissionEnum = typeof PAPermissionsDTOPermissionEnum[keyof typeof PAPermissionsDTOPermissionEnum];

/**
 * 
 * @export
 * @interface PAPermissionsDTOPagesPermission
 */
export interface PAPermissionsDTOPagesPermission {
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'general': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'processing': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'data-flow': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'data-retention': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'measure': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'pre-assessment': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'proportionality': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'risk': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'rights-of-data-subject': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'change-management': PagePermissionDTO;
    /**
     * 
     * @type {PagePermissionDTO}
     * @memberof PAPermissionsDTOPagesPermission
     */
    'final-assessment': PagePermissionDTO;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaStatusType = {
    Edit: 'edit',
    DpiaApproved: 'DPIAApproved',
    Review: 'review',
    Approved: 'approved'
} as const;

export type PaStatusType = typeof PaStatusType[keyof typeof PaStatusType];


/**
 * 
 * @export
 * @interface PagePermissionDTO
 */
export interface PagePermissionDTO {
    /**
     * 
     * @type {number}
     * @memberof PagePermissionDTO
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof PagePermissionDTO
     */
    'permission': PagePermissionDTOPermissionEnum;
    /**
     * 
     * @type {Array<PagePermissionDTOActionsInner>}
     * @memberof PagePermissionDTO
     */
    'actions': Array<PagePermissionDTOActionsInner>;
}

export const PagePermissionDTOPermissionEnum = {
    Read: 'read',
    Write: 'write',
    None: 'none'
} as const;

export type PagePermissionDTOPermissionEnum = typeof PagePermissionDTOPermissionEnum[keyof typeof PagePermissionDTOPermissionEnum];

/**
 * 
 * @export
 * @interface PagePermissionDTOActionsInner
 */
export interface PagePermissionDTOActionsInner {
    /**
     * 
     * @type {string}
     * @memberof PagePermissionDTOActionsInner
     */
    'action': PagePermissionDTOActionsInnerActionEnum;
    /**
     * 
     * @type {string}
     * @memberof PagePermissionDTOActionsInner
     */
    'disabledReason': string | null;
}

export const PagePermissionDTOActionsInnerActionEnum = {
    SetToEdit: 'setToEdit',
    SubmitForReview: 'submitForReview',
    Approve: 'approve',
    RequestEdit: 'requestEdit'
} as const;

export type PagePermissionDTOActionsInnerActionEnum = typeof PagePermissionDTOActionsInnerActionEnum[keyof typeof PagePermissionDTOActionsInnerActionEnum];

/**
 * 
 * @export
 * @interface PatchProcessorPADTO
 */
export interface PatchProcessorPADTO {
    /**
     * 
     * @type {string}
     * @memberof PatchProcessorPADTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProcessorPADTO
     */
    'paIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatchProcessorPADTO
     */
    'processorOrgUnitId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchProcessorPADTO
     */
    'assignToUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchProcessorPADTO
     */
    'privacyExpertUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchProcessorPADTO
     */
    'dpoUserId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProcessorPADTO
     */
    'controllerOrgUnitIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProcessorPADTO
     */
    'controllerExternalRecipientIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProcessorPADTO
     */
    'processingCategoryIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatchProcessorPADTO
     */
    'processingCategoryDescription'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProcessorPADTO
     */
    'thirdWorldDataStorageIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProcessorPADTO
     */
    'thirdWorldOrgUnitIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProcessorPADTO
     */
    'thirdWorldExternalRecipientIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProcessorPADTO
     */
    'measureIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProcessorPADTO
     */
    'labelIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PersonGroupDTO
 */
export interface PersonGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof PersonGroupDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PersonGroupDTO
     */
    'personGroup': string;
    /**
     * 
     * @type {string}
     * @memberof PersonGroupDTO
     */
    'dataOfMinors': PersonGroupDTODataOfMinorsEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonGroupDTO
     */
    'potentialAmountOfDataSubjects': string;
    /**
     * 
     * @type {Array<CategoryTypeTupleDTO>}
     * @memberof PersonGroupDTO
     */
    'categoryTypeTuples': Array<CategoryTypeTupleDTO>;
}

export const PersonGroupDTODataOfMinorsEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure',
    Empty: ''
} as const;

export type PersonGroupDTODataOfMinorsEnum = typeof PersonGroupDTODataOfMinorsEnum[keyof typeof PersonGroupDTODataOfMinorsEnum];

/**
 * 
 * @export
 * @interface ProcessMetaDTO
 */
export interface ProcessMetaDTO {
    /**
     * 
     * @type {string}
     * @memberof ProcessMetaDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessMetaDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessMetaDTO
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessMetaDTO
     */
    'assignUserIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessMetaDTO
     */
    'relativeOrgUnitIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ProcessingActivityDetailDTO
 */
export interface ProcessingActivityDetailDTO {
    /**
     * 
     * @type {string}
     * @memberof ProcessingActivityDetailDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingActivityDetailDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessingActivityDetailDTO
     */
    'assignedUsers': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessingActivityDetailDTO
     */
    'responsibleExpert': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessingActivityDetailDTO
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {PaStatusType}
     * @memberof ProcessingActivityDetailDTO
     */
    'status': PaStatusType;
    /**
     * 
     * @type {QuestionnaireDTO}
     * @memberof ProcessingActivityDetailDTO
     */
    'questionnaire': QuestionnaireDTO;
}


/**
 * 
 * @export
 * @interface ProcessingPageDTO
 */
export interface ProcessingPageDTO {
    /**
     * 
     * @type {string}
     * @memberof ProcessingPageDTO
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessingPageDTO
     */
    'assetIds': Array<string>;
    /**
     * 
     * @type {Array<PurposeDTO>}
     * @memberof ProcessingPageDTO
     */
    'purposes': Array<PurposeDTO>;
    /**
     * 
     * @type {Array<PersonGroupDTO>}
     * @memberof ProcessingPageDTO
     */
    'personGroups': Array<PersonGroupDTO>;
    /**
     * 
     * @type {Array<ImpactedAssetDTO>}
     * @memberof ProcessingPageDTO
     */
    'impactedAssets'?: Array<ImpactedAssetDTO>;
}
/**
 * 
 * @export
 * @interface ProcessorPADTO
 */
export interface ProcessorPADTO {
    /**
     * 
     * @type {string}
     * @memberof ProcessorPADTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessorPADTO
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcessorPADTO
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorPADTO
     */
    'paIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProcessorPADTO
     */
    'processorOrgUnitId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcessorPADTO
     */
    'assignToUserId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcessorPADTO
     */
    'privacyExpertUserId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProcessorPADTO
     */
    'dpoUserId': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorPADTO
     */
    'controllerOrgUnitIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorPADTO
     */
    'controllerExternalRecipientIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorPADTO
     */
    'processingCategoryIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProcessorPADTO
     */
    'processingCategoryDescription': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorPADTO
     */
    'thirdWorldDataStorageIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorPADTO
     */
    'thirdWorldOrgUnitIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorPADTO
     */
    'thirdWorldExternalRecipientIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorPADTO
     */
    'measureIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessorPADTO
     */
    'labelIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ProcessorsPADTO
 */
export interface ProcessorsPADTO {
    /**
     * 
     * @type {Array<ExternalLinkDTO>}
     * @memberof ProcessorsPADTO
     */
    'pas': Array<ExternalLinkDTO>;
    /**
     * 
     * @type {Array<ExternalLinkDTO>}
     * @memberof ProcessorsPADTO
     */
    'ers': Array<ExternalLinkDTO>;
    /**
     * 
     * @type {Array<ExternalLinkDTO>}
     * @memberof ProcessorsPADTO
     */
    'pcs': Array<ExternalLinkDTO>;
    /**
     * 
     * @type {Array<ExternalLinkDTO>}
     * @memberof ProcessorsPADTO
     */
    'dls': Array<ExternalLinkDTO>;
    /**
     * 
     * @type {Array<ExternalLinkDTO>}
     * @memberof ProcessorsPADTO
     */
    'mes': Array<ExternalLinkDTO>;
    /**
     * 
     * @type {Array<ProcessorPADTO>}
     * @memberof ProcessorsPADTO
     */
    'items': Array<ProcessorPADTO>;
    /**
     * 
     * @type {Array<OrgUnitDTO>}
     * @memberof ProcessorsPADTO
     */
    'orgUnits': Array<OrgUnitDTO>;
}
/**
 * 
 * @export
 * @interface ProportionalityPageDTO
 */
export interface ProportionalityPageDTO {
    /**
     * 
     * @type {string}
     * @memberof ProportionalityPageDTO
     */
    'proportionality': string;
}
/**
 * 
 * @export
 * @interface PurposeDTO
 */
export interface PurposeDTO {
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'purposeDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'legalBasisId': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbOtherLegalBasisValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbContractValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbLegalObligationValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbVerificationValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbVitalInterestsValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbControllerInterestValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbInterestOfTheDataSubjectValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbInterestBalancingValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbConsentContentValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbObtainingConsentValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbPublicInterestValue': string;
    /**
     * 
     * @type {string}
     * @memberof PurposeDTO
     */
    'lbDefaultValue': string;
}
/**
 * 
 * @export
 * @interface QuestionnaireDTO
 */
export interface QuestionnaireDTO {
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionnaireDTO
     */
    'assetIds': Array<string>;
    /**
     * 
     * @type {Array<PurposeDTO>}
     * @memberof QuestionnaireDTO
     */
    'purposes': Array<PurposeDTO>;
    /**
     * 
     * @type {Array<PersonGroupDTO>}
     * @memberof QuestionnaireDTO
     */
    'personGroups': Array<PersonGroupDTO>;
    /**
     * 
     * @type {Array<ImpactedAssetDTO>}
     * @memberof QuestionnaireDTO
     */
    'impactedAssets'?: Array<ImpactedAssetDTO>;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'blackOrWhitelist'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'profiling'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'automaticDecisionMaking'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'systematicMonitoring'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'specialCategories'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'largeQuantities'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'setComparison'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'vulnerablePersons'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'innovativeUse'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'preventionOfRights'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'dpiaRequiredDecision'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionnaireDTO
     */
    'dpiaRequiredUsersChoice'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireDTO
     */
    'dpiaRequiredStandardAnswerIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'dpiaRequiredStandardAnswerExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionnaireDTO
     */
    'vulnerablePersonsAutomaticDecision'?: boolean;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'informationRequirements'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'rightOfAccess'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'rightToRectification'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'rightToErasure'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'rightToRestriction'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'rightToDataPortability'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'rightToObject'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'rightToWithdraw'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {UpToDateDescriptionFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'dataCorrectness'?: UpToDateDescriptionFieldDTO;
    /**
     * 
     * @type {UpToDateDescriptionFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'processTestRequired'?: UpToDateDescriptionFieldDTO;
    /**
     * 
     * @type {UpToDateDescriptionFieldDTO}
     * @memberof QuestionnaireDTO
     */
    'newDPIAEvaluation'?: UpToDateDescriptionFieldDTO;
    /**
     * 
     * @type {Array<AssessmentMeasureDTO>}
     * @memberof QuestionnaireDTO
     */
    'assessmentMeasures': Array<AssessmentMeasureDTO>;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'assessment': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'consultationDPADescription': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'consultationDPARequired': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'proportionality': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'purposeDescription': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'legalBasisId': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbOtherLegalBasisValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbContractValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbLegalObligationValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbVerificationValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbVitalInterestsValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbControllerInterestValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbInterestOfTheDataSubjectValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbInterestBalancingValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbConsentContentValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbObtainingConsentValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbPublicInterestValue': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDTO
     */
    'lbDefaultValue': string;
}
/**
 * 
 * @export
 * @interface ResponseProcessFieldDTO
 */
export interface ResponseProcessFieldDTO {
    /**
     * 
     * @type {string}
     * @memberof ResponseProcessFieldDTO
     */
    'response': ResponseProcessFieldDTOResponseEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponseProcessFieldDTO
     */
    'process': string;
}

export const ResponseProcessFieldDTOResponseEnum = {
    Yes: 'yes',
    No: 'no',
    Empty: ''
} as const;

export type ResponseProcessFieldDTOResponseEnum = typeof ResponseProcessFieldDTOResponseEnum[keyof typeof ResponseProcessFieldDTOResponseEnum];

/**
 * 
 * @export
 * @interface RightsPageDTO
 */
export interface RightsPageDTO {
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof RightsPageDTO
     */
    'informationRequirements'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof RightsPageDTO
     */
    'rightOfAccess'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof RightsPageDTO
     */
    'rightToRectification'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof RightsPageDTO
     */
    'rightToErasure'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof RightsPageDTO
     */
    'rightToRestriction'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof RightsPageDTO
     */
    'rightToDataPortability'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof RightsPageDTO
     */
    'rightToObject'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof RightsPageDTO
     */
    'rightToWithdraw'?: ResponseProcessFieldDTO;
}
/**
 * 
 * @export
 * @interface SetAiRiskLevelRequest
 */
export interface SetAiRiskLevelRequest {
    /**
     * 
     * @type {AiRiskLevel}
     * @memberof SetAiRiskLevelRequest
     */
    'aiRiskLevel'?: AiRiskLevel | null;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'aiRiskLevelExplanation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isPotentiallyInadmissible'?: SetAiRiskLevelRequestIsPotentiallyInadmissibleEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isUsedInBiometricIdentification'?: SetAiRiskLevelRequestIsUsedInBiometricIdentificationEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isUsedInCriticalInfrastructure'?: SetAiRiskLevelRequestIsUsedInCriticalInfrastructureEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isUsedInEducation'?: SetAiRiskLevelRequestIsUsedInEducationEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isUsedInRecruitment'?: SetAiRiskLevelRequestIsUsedInRecruitmentEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isUsedInPublicService'?: SetAiRiskLevelRequestIsUsedInPublicServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isUsedInLawEnforcement'?: SetAiRiskLevelRequestIsUsedInLawEnforcementEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isUsedInMigration'?: SetAiRiskLevelRequestIsUsedInMigrationEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isUsedInJusticeProcess'?: SetAiRiskLevelRequestIsUsedInJusticeProcessEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isMakingAutomatedDecision'?: SetAiRiskLevelRequestIsMakingAutomatedDecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isRiskAnalysisMade'?: SetAiRiskLevelRequestIsRiskAnalysisMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isMeasureTransparencyMade'?: SetAiRiskLevelRequestIsMeasureTransparencyMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isMeasureHumanControlMade'?: SetAiRiskLevelRequestIsMeasureHumanControlMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isMeasureBiasTestingMade'?: SetAiRiskLevelRequestIsMeasureBiasTestingMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isMeasureDataProtectionMade'?: SetAiRiskLevelRequestIsMeasureDataProtectionMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isMeasureAuditControlMade'?: SetAiRiskLevelRequestIsMeasureAuditControlMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isMeasureEthicsGuideMade'?: SetAiRiskLevelRequestIsMeasureEthicsGuideMadeEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isGivingHumanImpression'?: SetAiRiskLevelRequestIsGivingHumanImpressionEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isContentPerceivableAsReal'?: SetAiRiskLevelRequestIsContentPerceivableAsRealEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isUsedInNonCriticalArea'?: SetAiRiskLevelRequestIsUsedInNonCriticalAreaEnum;
    /**
     * 
     * @type {string}
     * @memberof SetAiRiskLevelRequest
     */
    'isVoluntarilyMonitored'?: SetAiRiskLevelRequestIsVoluntarilyMonitoredEnum;
}

export const SetAiRiskLevelRequestIsPotentiallyInadmissibleEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsPotentiallyInadmissibleEnum = typeof SetAiRiskLevelRequestIsPotentiallyInadmissibleEnum[keyof typeof SetAiRiskLevelRequestIsPotentiallyInadmissibleEnum];
export const SetAiRiskLevelRequestIsUsedInBiometricIdentificationEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsUsedInBiometricIdentificationEnum = typeof SetAiRiskLevelRequestIsUsedInBiometricIdentificationEnum[keyof typeof SetAiRiskLevelRequestIsUsedInBiometricIdentificationEnum];
export const SetAiRiskLevelRequestIsUsedInCriticalInfrastructureEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsUsedInCriticalInfrastructureEnum = typeof SetAiRiskLevelRequestIsUsedInCriticalInfrastructureEnum[keyof typeof SetAiRiskLevelRequestIsUsedInCriticalInfrastructureEnum];
export const SetAiRiskLevelRequestIsUsedInEducationEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsUsedInEducationEnum = typeof SetAiRiskLevelRequestIsUsedInEducationEnum[keyof typeof SetAiRiskLevelRequestIsUsedInEducationEnum];
export const SetAiRiskLevelRequestIsUsedInRecruitmentEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsUsedInRecruitmentEnum = typeof SetAiRiskLevelRequestIsUsedInRecruitmentEnum[keyof typeof SetAiRiskLevelRequestIsUsedInRecruitmentEnum];
export const SetAiRiskLevelRequestIsUsedInPublicServiceEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsUsedInPublicServiceEnum = typeof SetAiRiskLevelRequestIsUsedInPublicServiceEnum[keyof typeof SetAiRiskLevelRequestIsUsedInPublicServiceEnum];
export const SetAiRiskLevelRequestIsUsedInLawEnforcementEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsUsedInLawEnforcementEnum = typeof SetAiRiskLevelRequestIsUsedInLawEnforcementEnum[keyof typeof SetAiRiskLevelRequestIsUsedInLawEnforcementEnum];
export const SetAiRiskLevelRequestIsUsedInMigrationEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsUsedInMigrationEnum = typeof SetAiRiskLevelRequestIsUsedInMigrationEnum[keyof typeof SetAiRiskLevelRequestIsUsedInMigrationEnum];
export const SetAiRiskLevelRequestIsUsedInJusticeProcessEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsUsedInJusticeProcessEnum = typeof SetAiRiskLevelRequestIsUsedInJusticeProcessEnum[keyof typeof SetAiRiskLevelRequestIsUsedInJusticeProcessEnum];
export const SetAiRiskLevelRequestIsMakingAutomatedDecisionEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsMakingAutomatedDecisionEnum = typeof SetAiRiskLevelRequestIsMakingAutomatedDecisionEnum[keyof typeof SetAiRiskLevelRequestIsMakingAutomatedDecisionEnum];
export const SetAiRiskLevelRequestIsRiskAnalysisMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsRiskAnalysisMadeEnum = typeof SetAiRiskLevelRequestIsRiskAnalysisMadeEnum[keyof typeof SetAiRiskLevelRequestIsRiskAnalysisMadeEnum];
export const SetAiRiskLevelRequestIsMeasureTransparencyMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsMeasureTransparencyMadeEnum = typeof SetAiRiskLevelRequestIsMeasureTransparencyMadeEnum[keyof typeof SetAiRiskLevelRequestIsMeasureTransparencyMadeEnum];
export const SetAiRiskLevelRequestIsMeasureHumanControlMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsMeasureHumanControlMadeEnum = typeof SetAiRiskLevelRequestIsMeasureHumanControlMadeEnum[keyof typeof SetAiRiskLevelRequestIsMeasureHumanControlMadeEnum];
export const SetAiRiskLevelRequestIsMeasureBiasTestingMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsMeasureBiasTestingMadeEnum = typeof SetAiRiskLevelRequestIsMeasureBiasTestingMadeEnum[keyof typeof SetAiRiskLevelRequestIsMeasureBiasTestingMadeEnum];
export const SetAiRiskLevelRequestIsMeasureDataProtectionMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsMeasureDataProtectionMadeEnum = typeof SetAiRiskLevelRequestIsMeasureDataProtectionMadeEnum[keyof typeof SetAiRiskLevelRequestIsMeasureDataProtectionMadeEnum];
export const SetAiRiskLevelRequestIsMeasureAuditControlMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsMeasureAuditControlMadeEnum = typeof SetAiRiskLevelRequestIsMeasureAuditControlMadeEnum[keyof typeof SetAiRiskLevelRequestIsMeasureAuditControlMadeEnum];
export const SetAiRiskLevelRequestIsMeasureEthicsGuideMadeEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsMeasureEthicsGuideMadeEnum = typeof SetAiRiskLevelRequestIsMeasureEthicsGuideMadeEnum[keyof typeof SetAiRiskLevelRequestIsMeasureEthicsGuideMadeEnum];
export const SetAiRiskLevelRequestIsGivingHumanImpressionEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsGivingHumanImpressionEnum = typeof SetAiRiskLevelRequestIsGivingHumanImpressionEnum[keyof typeof SetAiRiskLevelRequestIsGivingHumanImpressionEnum];
export const SetAiRiskLevelRequestIsContentPerceivableAsRealEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsContentPerceivableAsRealEnum = typeof SetAiRiskLevelRequestIsContentPerceivableAsRealEnum[keyof typeof SetAiRiskLevelRequestIsContentPerceivableAsRealEnum];
export const SetAiRiskLevelRequestIsUsedInNonCriticalAreaEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsUsedInNonCriticalAreaEnum = typeof SetAiRiskLevelRequestIsUsedInNonCriticalAreaEnum[keyof typeof SetAiRiskLevelRequestIsUsedInNonCriticalAreaEnum];
export const SetAiRiskLevelRequestIsVoluntarilyMonitoredEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure'
} as const;

export type SetAiRiskLevelRequestIsVoluntarilyMonitoredEnum = typeof SetAiRiskLevelRequestIsVoluntarilyMonitoredEnum[keyof typeof SetAiRiskLevelRequestIsVoluntarilyMonitoredEnum];

/**
 * 
 * @export
 * @interface SubmitProcess400Response
 */
export interface SubmitProcess400Response {
    /**
     * 
     * @type {string}
     * @memberof SubmitProcess400Response
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitProcess400Response
     */
    'missingFields'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SubmitProcess400ResponseAllOf
 */
export interface SubmitProcess400ResponseAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitProcess400ResponseAllOf
     */
    'missingFields'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpToDateDescriptionFieldDTO
 */
export interface UpToDateDescriptionFieldDTO {
    /**
     * 
     * @type {string}
     * @memberof UpToDateDescriptionFieldDTO
     */
    'isDataUpToDate': UpToDateDescriptionFieldDTOIsDataUpToDateEnum;
    /**
     * 
     * @type {string}
     * @memberof UpToDateDescriptionFieldDTO
     */
    'description': string;
}

export const UpToDateDescriptionFieldDTOIsDataUpToDateEnum = {
    Yes: 'yes',
    No: 'no',
    Empty: ''
} as const;

export type UpToDateDescriptionFieldDTOIsDataUpToDateEnum = typeof UpToDateDescriptionFieldDTOIsDataUpToDateEnum[keyof typeof UpToDateDescriptionFieldDTOIsDataUpToDateEnum];

/**
 * 
 * @export
 * @interface UpdateAssessmentMeasureRequest
 */
export interface UpdateAssessmentMeasureRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAssessmentMeasureRequest
     */
    'measure'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssessmentMeasureRequest
     */
    'priority'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAssetIdsDTO
 */
export interface UpdateAssetIdsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAssetIdsDTO
     */
    'assetIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateChangePageDTO
 */
export interface UpdateChangePageDTO {
    /**
     * 
     * @type {UpToDateDescriptionFieldDTO}
     * @memberof UpdateChangePageDTO
     */
    'dataCorrectness'?: UpToDateDescriptionFieldDTO;
    /**
     * 
     * @type {UpToDateDescriptionFieldDTO}
     * @memberof UpdateChangePageDTO
     */
    'processTestRequired'?: UpToDateDescriptionFieldDTO;
    /**
     * 
     * @type {UpToDateDescriptionFieldDTO}
     * @memberof UpdateChangePageDTO
     */
    'newDPIAEvaluation'?: UpToDateDescriptionFieldDTO;
}
/**
 * 
 * @export
 * @interface UpdateConsultationDataRequest
 */
export interface UpdateConsultationDataRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConsultationDataRequest
     */
    'consultationDPADescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateConsultationDataRequest
     */
    'consultationDPARequired'?: UpdateConsultationDataRequestConsultationDPARequiredEnum;
}

export const UpdateConsultationDataRequestConsultationDPARequiredEnum = {
    Yes: 'yes',
    No: 'no',
    Empty: ''
} as const;

export type UpdateConsultationDataRequestConsultationDPARequiredEnum = typeof UpdateConsultationDataRequestConsultationDPARequiredEnum[keyof typeof UpdateConsultationDataRequestConsultationDPARequiredEnum];

/**
 * 
 * @export
 * @interface UpdateDescriptionDTO
 */
export interface UpdateDescriptionDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateDescriptionDTO
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface UpdateDpiaPageDTO
 */
export interface UpdateDpiaPageDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'blackOrWhitelist'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'profiling'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'automaticDecisionMaking'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'systematicMonitoring'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'specialCategories'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'largeQuantities'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'setComparison'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'vulnerablePersons'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'innovativeUse'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'preventionOfRights'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'dpiaRequiredDecision'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDpiaPageDTO
     */
    'dpiaRequiredUsersChoice'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateDpiaPageDTO
     */
    'dpiaRequiredStandardAnswerIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDpiaPageDTO
     */
    'dpiaRequiredStandardAnswerExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDpiaPageDTO
     */
    'vulnerablePersonsAutomaticDecision'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateFinalAssessmentRequest
 */
export interface UpdateFinalAssessmentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFinalAssessmentRequest
     */
    'assessment': string;
}
/**
 * 
 * @export
 * @interface UpdatePersonGroupDTO
 */
export interface UpdatePersonGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonGroupDTO
     */
    'personGroup': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonGroupDTO
     */
    'dataOfMinors': UpdatePersonGroupDTODataOfMinorsEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonGroupDTO
     */
    'potentialAmountOfDataSubjects': string;
    /**
     * 
     * @type {Array<CategoryTypeTupleDTO>}
     * @memberof UpdatePersonGroupDTO
     */
    'categoryTypeTuples': Array<CategoryTypeTupleDTO>;
}

export const UpdatePersonGroupDTODataOfMinorsEnum = {
    Yes: 'yes',
    No: 'no',
    Unsure: 'unsure',
    Empty: ''
} as const;

export type UpdatePersonGroupDTODataOfMinorsEnum = typeof UpdatePersonGroupDTODataOfMinorsEnum[keyof typeof UpdatePersonGroupDTODataOfMinorsEnum];

/**
 * 
 * @export
 * @interface UpdateProportionalityDTO
 */
export interface UpdateProportionalityDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateProportionalityDTO
     */
    'proportionality': string;
}
/**
 * 
 * @export
 * @interface UpdatePurposeDTO
 */
export interface UpdatePurposeDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'purposeDescription': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'legalBasisId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbOtherLegalBasisValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbContractValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbLegalObligationValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbVerificationValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbVitalInterestsValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbControllerInterestValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbInterestOfTheDataSubjectValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbInterestBalancingValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbConsentContentValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbObtainingConsentValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbPublicInterestValue': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePurposeDTO
     */
    'lbDefaultValue': string;
}
/**
 * 
 * @export
 * @interface UpdateRightsPageDTO
 */
export interface UpdateRightsPageDTO {
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof UpdateRightsPageDTO
     */
    'informationRequirements'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof UpdateRightsPageDTO
     */
    'rightOfAccess'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof UpdateRightsPageDTO
     */
    'rightToRectification'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof UpdateRightsPageDTO
     */
    'rightToErasure'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof UpdateRightsPageDTO
     */
    'rightToRestriction'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof UpdateRightsPageDTO
     */
    'rightToDataPortability'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof UpdateRightsPageDTO
     */
    'rightToObject'?: ResponseProcessFieldDTO;
    /**
     * 
     * @type {ResponseProcessFieldDTO}
     * @memberof UpdateRightsPageDTO
     */
    'rightToWithdraw'?: ResponseProcessFieldDTO;
}
/**
 * 
 * @export
 * @interface UpdateStatusProcessorPADTO
 */
export interface UpdateStatusProcessorPADTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateStatusProcessorPADTO
     */
    'status'?: UpdateStatusProcessorPADTOStatusEnum;
}

export const UpdateStatusProcessorPADTOStatusEnum = {
    Edit: 'edit',
    Approved: 'approved'
} as const;

export type UpdateStatusProcessorPADTOStatusEnum = typeof UpdateStatusProcessorPADTOStatusEnum[keyof typeof UpdateStatusProcessorPADTOStatusEnum];

/**
 * 
 * @export
 * @interface VersionHistoriesDTO
 */
export interface VersionHistoriesDTO {
    /**
     * 
     * @type {Array<VersionHistoryDTO>}
     * @memberof VersionHistoriesDTO
     */
    'timelineEvents'?: Array<VersionHistoryDTO>;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoriesDTO
     */
    'nextCursor'?: string | null;
}
/**
 * 
 * @export
 * @interface VersionHistoryDTO
 */
export interface VersionHistoryDTO {
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTO
     */
    'eventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTO
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTO
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTO
     */
    'eventDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTO
     */
    'changerUID'?: string;
    /**
     * 
     * @type {VersionHistoryDTOTriggerEvent}
     * @memberof VersionHistoryDTO
     */
    'triggerEvent'?: VersionHistoryDTOTriggerEvent | null;
}
/**
 * 
 * @export
 * @interface VersionHistoryDTOTriggerEvent
 */
export interface VersionHistoryDTOTriggerEvent {
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTOTriggerEvent
     */
    'eventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTOTriggerEvent
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTOTriggerEvent
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTOTriggerEvent
     */
    'eventDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionHistoryDTOTriggerEvent
     */
    'changerUID'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add assessment measure
         * @summary Add assessment measure
         * @param {string} processId Process Id
         * @param {AddAssessmentMeasureRequest} addAssessmentMeasureRequest Assessment measure payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAssessmentMeasure: async (processId: string, addAssessmentMeasureRequest: AddAssessmentMeasureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('addAssessmentMeasure', 'processId', processId)
            // verify required parameter 'addAssessmentMeasureRequest' is not null or undefined
            assertParamExists('addAssessmentMeasure', 'addAssessmentMeasureRequest', addAssessmentMeasureRequest)
            const localVarPath = `/api/v1/pa/{processId}/pages/finalAssessment/assessmentMeasures`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAssessmentMeasureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new impacted asset to a processing activity
         * @summary Add Impacted Asset
         * @param {string} processId Process Id
         * @param {ImpactedAssetDTO} [impactedAssetDTO] Impacted asset payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addImpactedAsset: async (processId: string, impactedAssetDTO?: ImpactedAssetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('addImpactedAsset', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/impacted-assets`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(impactedAssetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Approve process
         * @summary Approve process
         * @param {string} processId Process Id
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveProcess: async (processId: string, dryRun?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('approveProcess', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/approve`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create processing activity
         * @summary Create processing activity
         * @param {CreatePARequest} createPARequest Create PA payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPA: async (createPARequest: CreatePARequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPARequest' is not null or undefined
            assertParamExists('createPA', 'createPARequest', createPARequest)
            const localVarPath = `/api/v1/pa/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPARequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create person group accordion
         * @summary Create person group accordion
         * @param {string} processId Process Id
         * @param {CreatePurposeRequest} createPurposeRequest create id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonGroup: async (processId: string, createPurposeRequest: CreatePurposeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('createPersonGroup', 'processId', processId)
            // verify required parameter 'createPurposeRequest' is not null or undefined
            assertParamExists('createPersonGroup', 'createPurposeRequest', createPurposeRequest)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/person-groups`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPurposeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create purpose accordion
         * @summary Create purpose accordion
         * @param {string} processId Process Id
         * @param {CreatePurposeRequest} createPurposeRequest create id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurpose: async (processId: string, createPurposeRequest: CreatePurposeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('createPurpose', 'processId', processId)
            // verify required parameter 'createPurposeRequest' is not null or undefined
            assertParamExists('createPurpose', 'createPurposeRequest', createPurposeRequest)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/purposes`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPurposeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete assessment measure
         * @summary Delete assessment measure
         * @param {string} processId Process Id
         * @param {string} assessmentMeasureId Assessment measure id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentMeasure: async (processId: string, assessmentMeasureId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('deleteAssessmentMeasure', 'processId', processId)
            // verify required parameter 'assessmentMeasureId' is not null or undefined
            assertParamExists('deleteAssessmentMeasure', 'assessmentMeasureId', assessmentMeasureId)
            const localVarPath = `/api/v1/pa/{processId}/pages/finalAssessment/assessmentMeasures/{assessmentMeasureId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)))
                .replace(`{${"assessmentMeasureId"}}`, encodeURIComponent(String(assessmentMeasureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete impacted asset
         * @summary Delete impacted asset
         * @param {string} processId Process Id
         * @param {string} impactedAssetId Impacted asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImpactedAsset: async (processId: string, impactedAssetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('deleteImpactedAsset', 'processId', processId)
            // verify required parameter 'impactedAssetId' is not null or undefined
            assertParamExists('deleteImpactedAsset', 'impactedAssetId', impactedAssetId)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/impacted-assets/{impactedAssetId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)))
                .replace(`{${"impactedAssetId"}}`, encodeURIComponent(String(impactedAssetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete person Group accordion
         * @summary Delete Person group accordion
         * @param {string} processId Process Id
         * @param {string} personGroupId Person group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersonGroup: async (processId: string, personGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('deletePersonGroup', 'processId', processId)
            // verify required parameter 'personGroupId' is not null or undefined
            assertParamExists('deletePersonGroup', 'personGroupId', personGroupId)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/person-groups/{personGroupId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)))
                .replace(`{${"personGroupId"}}`, encodeURIComponent(String(personGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete processor PA
         * @summary Delete processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcessorPA: async (processorPAId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processorPAId' is not null or undefined
            assertParamExists('deleteProcessorPA', 'processorPAId', processorPAId)
            const localVarPath = `/api/v1/processor-pa/overview/{processorPAId}`
                .replace(`{${"processorPAId"}}`, encodeURIComponent(String(processorPAId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete purpose accordion
         * @summary Delete purpose accordion
         * @param {string} processId Process Id
         * @param {string} purposeId Purpose id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePurpose: async (processId: string, purposeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('deletePurpose', 'processId', processId)
            // verify required parameter 'purposeId' is not null or undefined
            assertParamExists('deletePurpose', 'purposeId', purposeId)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/purposes/{purposeId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)))
                .replace(`{${"purposeId"}}`, encodeURIComponent(String(purposeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit process
         * @summary Edit process
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProcess: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('editProcess', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/edit`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The frontend needs to do special UI things on ai related assets
         * @summary Get Asset IDs eligible for AI check
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiAssets: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getAiAssets', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/precheck/ai/assets`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the AI precheck status for a processing activity
         * @summary Get AI Precheck Status
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiPrecheckStatus: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getAiPrecheckStatus', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/precheck/ai`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the AI Techdocs precheck status for a processing activity
         * @summary Get AI Techdocs Precheck Status
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiTechDocsPrecheckStatus: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getAiTechDocsPrecheckStatus', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/precheck/ai/techdocs/status`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Change Page
         * @summary Get Change Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChange: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getChange', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/changes`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get dpia processor pa info
         * @summary Get dpia processor pa info
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDPIAProcessorInfo: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getDPIAProcessorInfo', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/dpia/processor`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Dpia Page
         * @summary Get Dpia Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDpia: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getDpia', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/dpia`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get final assessment Page
         * @summary Get final assessment Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinalAssessment: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getFinalAssessment', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/finalAssessment`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of impacted assets for a processing activity
         * @summary Get Impacted Assets
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpactedAssets: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getImpactedAssets', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/impacted-assets`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the allowed measures to be used with pa
         * @summary Get measures options
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasureOptions: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getMeasureOptions', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/measures/options/measures`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get PA permissions
         * @summary Get PA permissions
         * @param {string} processId Process Id
         * @param {boolean} [forcePAMode] Whether to force pa mode for backwards compatibility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPAPermissions: async (processId: string, forcePAMode?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getPAPermissions', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/permissions`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forcePAMode !== undefined) {
                localVarQueryParameter['forcePAMode'] = forcePAMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get process detail
         * @summary Get process detail
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDetail: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getProcessDetail', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get processing Page
         * @summary Get processing Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessing: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getProcessing', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get processing activity histories
         * @summary Get processing activity histories
         * @param {string} processId Process Id
         * @param {string} [nextCursor] Pass in to query the next page
         * @param {number} [itemsCount] The target count of item requested. Not guaranteed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessingActivityHistories: async (processId: string, nextCursor?: string, itemsCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getProcessingActivityHistories', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/histories`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nextCursor !== undefined) {
                localVarQueryParameter['nextCursor'] = nextCursor;
            }

            if (itemsCount !== undefined) {
                localVarQueryParameter['itemsCount'] = itemsCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get processor PA
         * @summary Get single processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessorPA: async (processorPAId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processorPAId' is not null or undefined
            assertParamExists('getProcessorPA', 'processorPAId', processorPAId)
            const localVarPath = `/api/v1/processor-pa/overview/{processorPAId}`
                .replace(`{${"processorPAId"}}`, encodeURIComponent(String(processorPAId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all processor PAs
         * @summary Get all processor PAs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessorsPA: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/processor-pa/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get proportionality Page
         * @summary Get proportionality Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProportionality: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getProportionality', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/proportionality`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get rights Page
         * @summary Get rights Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRights: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('getRights', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/pages/rights`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request edit process
         * @summary Request edit process
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEditProcess: async (processId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('requestEditProcess', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/requestedit`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the AI risk level for a processing activity
         * @summary Set AI Risk Level
         * @param {string} processId Process Id
         * @param {SetAiRiskLevelRequest} setAiRiskLevelRequest AI risk level payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAiRiskLevel: async (processId: string, setAiRiskLevelRequest: SetAiRiskLevelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('setAiRiskLevel', 'processId', processId)
            // verify required parameter 'setAiRiskLevelRequest' is not null or undefined
            assertParamExists('setAiRiskLevel', 'setAiRiskLevelRequest', setAiRiskLevelRequest)
            const localVarPath = `/api/v1/pa/{processId}/pages/precheck/ai`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setAiRiskLevelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit process
         * @summary Submit process
         * @param {string} processId Process Id
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitProcess: async (processId: string, dryRun?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('submitProcess', 'processId', processId)
            const localVarPath = `/api/v1/pa/{processId}/submit`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update assessment measure
         * @summary Update assessment measure
         * @param {string} processId Process Id
         * @param {string} assessmentMeasureId Assessment measure id
         * @param {UpdateAssessmentMeasureRequest} updateAssessmentMeasureRequest Assessment measure payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentMeasure: async (processId: string, assessmentMeasureId: string, updateAssessmentMeasureRequest: UpdateAssessmentMeasureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateAssessmentMeasure', 'processId', processId)
            // verify required parameter 'assessmentMeasureId' is not null or undefined
            assertParamExists('updateAssessmentMeasure', 'assessmentMeasureId', assessmentMeasureId)
            // verify required parameter 'updateAssessmentMeasureRequest' is not null or undefined
            assertParamExists('updateAssessmentMeasure', 'updateAssessmentMeasureRequest', updateAssessmentMeasureRequest)
            const localVarPath = `/api/v1/pa/{processId}/pages/finalAssessment/assessmentMeasures/{assessmentMeasureId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)))
                .replace(`{${"assessmentMeasureId"}}`, encodeURIComponent(String(assessmentMeasureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAssessmentMeasureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update asset ids
         * @summary Update asset ids
         * @param {string} processId Process Id
         * @param {UpdateAssetIdsDTO} updateAssetIdsDTO Asset ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetIds: async (processId: string, updateAssetIdsDTO: UpdateAssetIdsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateAssetIds', 'processId', processId)
            // verify required parameter 'updateAssetIdsDTO' is not null or undefined
            assertParamExists('updateAssetIds', 'updateAssetIdsDTO', updateAssetIdsDTO)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/assets`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAssetIdsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Change page
         * @summary Update Change page
         * @param {string} processId Process Id
         * @param {UpdateChangePageDTO} updateChangePageDTO Change payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChange: async (processId: string, updateChangePageDTO: UpdateChangePageDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateChange', 'processId', processId)
            // verify required parameter 'updateChangePageDTO' is not null or undefined
            assertParamExists('updateChange', 'updateChangePageDTO', updateChangePageDTO)
            const localVarPath = `/api/v1/pa/{processId}/pages/changes`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChangePageDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update consultation data
         * @summary Update consultation data
         * @param {string} processId Process Id
         * @param {UpdateConsultationDataRequest} updateConsultationDataRequest Consultation data payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsultationData: async (processId: string, updateConsultationDataRequest: UpdateConsultationDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateConsultationData', 'processId', processId)
            // verify required parameter 'updateConsultationDataRequest' is not null or undefined
            assertParamExists('updateConsultationData', 'updateConsultationDataRequest', updateConsultationDataRequest)
            const localVarPath = `/api/v1/pa/{processId}/pages/finalAssessment/consultationData`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConsultationDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Description
         * @summary Update Description
         * @param {string} processId Process Id
         * @param {UpdateDescriptionDTO} updateDescriptionDTO Description field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDescription: async (processId: string, updateDescriptionDTO: UpdateDescriptionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateDescription', 'processId', processId)
            // verify required parameter 'updateDescriptionDTO' is not null or undefined
            assertParamExists('updateDescription', 'updateDescriptionDTO', updateDescriptionDTO)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/description`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDescriptionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Dpia page
         * @summary Update Dpia page
         * @param {string} processId Process Id
         * @param {UpdateDpiaPageDTO} updateDpiaPageDTO Dpia payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDpia: async (processId: string, updateDpiaPageDTO: UpdateDpiaPageDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateDpia', 'processId', processId)
            // verify required parameter 'updateDpiaPageDTO' is not null or undefined
            assertParamExists('updateDpia', 'updateDpiaPageDTO', updateDpiaPageDTO)
            const localVarPath = `/api/v1/pa/{processId}/pages/dpia`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDpiaPageDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update assessment
         * @summary Update assessment
         * @param {string} processId Process Id
         * @param {UpdateFinalAssessmentRequest} updateFinalAssessmentRequest Assessment payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinalAssessment: async (processId: string, updateFinalAssessmentRequest: UpdateFinalAssessmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateFinalAssessment', 'processId', processId)
            // verify required parameter 'updateFinalAssessmentRequest' is not null or undefined
            assertParamExists('updateFinalAssessment', 'updateFinalAssessmentRequest', updateFinalAssessmentRequest)
            const localVarPath = `/api/v1/pa/{processId}/pages/finalAssessment/assessment`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFinalAssessmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing impacted asset for a processing activity
         * @summary Update Impacted Asset
         * @param {string} processId Process Id
         * @param {string} impactedAssetId Impacted asset id
         * @param {ImpactedAssetDTO} impactedAssetDTO Impacted asset payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImpactedAsset: async (processId: string, impactedAssetId: string, impactedAssetDTO: ImpactedAssetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateImpactedAsset', 'processId', processId)
            // verify required parameter 'impactedAssetId' is not null or undefined
            assertParamExists('updateImpactedAsset', 'impactedAssetId', impactedAssetId)
            // verify required parameter 'impactedAssetDTO' is not null or undefined
            assertParamExists('updateImpactedAsset', 'impactedAssetDTO', impactedAssetDTO)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/impacted-assets/{impactedAssetId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)))
                .replace(`{${"impactedAssetId"}}`, encodeURIComponent(String(impactedAssetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(impactedAssetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update person group accordion
         * @summary Update person group accordion
         * @param {string} processId Process Id
         * @param {string} personGroupId Person group id
         * @param {UpdatePersonGroupDTO} updatePersonGroupDTO Person group payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonGroup: async (processId: string, personGroupId: string, updatePersonGroupDTO: UpdatePersonGroupDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updatePersonGroup', 'processId', processId)
            // verify required parameter 'personGroupId' is not null or undefined
            assertParamExists('updatePersonGroup', 'personGroupId', personGroupId)
            // verify required parameter 'updatePersonGroupDTO' is not null or undefined
            assertParamExists('updatePersonGroup', 'updatePersonGroupDTO', updatePersonGroupDTO)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/person-groups/{personGroupId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)))
                .replace(`{${"personGroupId"}}`, encodeURIComponent(String(personGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePersonGroupDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update processor PA
         * @summary Update processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {PatchProcessorPADTO} patchProcessorPADTO Processor PA payload
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcessorPA: async (processorPAId: string, patchProcessorPADTO: PatchProcessorPADTO, dryRun?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processorPAId' is not null or undefined
            assertParamExists('updateProcessorPA', 'processorPAId', processorPAId)
            // verify required parameter 'patchProcessorPADTO' is not null or undefined
            assertParamExists('updateProcessorPA', 'patchProcessorPADTO', patchProcessorPADTO)
            const localVarPath = `/api/v1/processor-pa/overview/{processorPAId}`
                .replace(`{${"processorPAId"}}`, encodeURIComponent(String(processorPAId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchProcessorPADTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Proportionality
         * @summary Update Proportionality
         * @param {string} processId Process Id
         * @param {UpdateProportionalityDTO} updateProportionalityDTO Proportionality field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProportionality: async (processId: string, updateProportionalityDTO: UpdateProportionalityDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateProportionality', 'processId', processId)
            // verify required parameter 'updateProportionalityDTO' is not null or undefined
            assertParamExists('updateProportionality', 'updateProportionalityDTO', updateProportionalityDTO)
            const localVarPath = `/api/v1/pa/{processId}/pages/proportionality`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProportionalityDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update purpose accordion
         * @summary Update purpose accordion
         * @param {string} processId Process Id
         * @param {string} purposeId Purpose id
         * @param {UpdatePurposeDTO} updatePurposeDTO Purpose payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePurpose: async (processId: string, purposeId: string, updatePurposeDTO: UpdatePurposeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updatePurpose', 'processId', processId)
            // verify required parameter 'purposeId' is not null or undefined
            assertParamExists('updatePurpose', 'purposeId', purposeId)
            // verify required parameter 'updatePurposeDTO' is not null or undefined
            assertParamExists('updatePurpose', 'updatePurposeDTO', updatePurposeDTO)
            const localVarPath = `/api/v1/pa/{processId}/pages/processing/purposes/{purposeId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)))
                .replace(`{${"purposeId"}}`, encodeURIComponent(String(purposeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePurposeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update rights page
         * @summary Update rights page
         * @param {string} processId Process Id
         * @param {UpdateRightsPageDTO} updateRightsPageDTO Rights payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRights: async (processId: string, updateRightsPageDTO: UpdateRightsPageDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateRights', 'processId', processId)
            // verify required parameter 'updateRightsPageDTO' is not null or undefined
            assertParamExists('updateRights', 'updateRightsPageDTO', updateRightsPageDTO)
            const localVarPath = `/api/v1/pa/{processId}/pages/rights`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRightsPageDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update processor PA status
         * @summary Approve or unapprove processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {UpdateStatusProcessorPADTO} updateStatusProcessorPADTO Processor PA payload
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusProcessorPA: async (processorPAId: string, updateStatusProcessorPADTO: UpdateStatusProcessorPADTO, dryRun?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processorPAId' is not null or undefined
            assertParamExists('updateStatusProcessorPA', 'processorPAId', processorPAId)
            // verify required parameter 'updateStatusProcessorPADTO' is not null or undefined
            assertParamExists('updateStatusProcessorPA', 'updateStatusProcessorPADTO', updateStatusProcessorPADTO)
            const localVarPath = `/api/v1/processor-pa/overview/{processorPAId}/status`
                .replace(`{${"processorPAId"}}`, encodeURIComponent(String(processorPAId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStatusProcessorPADTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Add assessment measure
         * @summary Add assessment measure
         * @param {string} processId Process Id
         * @param {AddAssessmentMeasureRequest} addAssessmentMeasureRequest Assessment measure payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAssessmentMeasure(processId: string, addAssessmentMeasureRequest: AddAssessmentMeasureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAssessmentMeasure(processId, addAssessmentMeasureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new impacted asset to a processing activity
         * @summary Add Impacted Asset
         * @param {string} processId Process Id
         * @param {ImpactedAssetDTO} [impactedAssetDTO] Impacted asset payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addImpactedAsset(processId: string, impactedAssetDTO?: ImpactedAssetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addImpactedAsset(processId, impactedAssetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Approve process
         * @summary Approve process
         * @param {string} processId Process Id
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveProcess(processId: string, dryRun?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveProcess(processId, dryRun, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create processing activity
         * @summary Create processing activity
         * @param {CreatePARequest} createPARequest Create PA payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPA(createPARequest: CreatePARequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPA(createPARequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create person group accordion
         * @summary Create person group accordion
         * @param {string} processId Process Id
         * @param {CreatePurposeRequest} createPurposeRequest create id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPersonGroup(processId: string, createPurposeRequest: CreatePurposeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPersonGroup(processId, createPurposeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create purpose accordion
         * @summary Create purpose accordion
         * @param {string} processId Process Id
         * @param {CreatePurposeRequest} createPurposeRequest create id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPurpose(processId: string, createPurposeRequest: CreatePurposeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPurpose(processId, createPurposeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete assessment measure
         * @summary Delete assessment measure
         * @param {string} processId Process Id
         * @param {string} assessmentMeasureId Assessment measure id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssessmentMeasure(processId: string, assessmentMeasureId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssessmentMeasure(processId, assessmentMeasureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete impacted asset
         * @summary Delete impacted asset
         * @param {string} processId Process Id
         * @param {string} impactedAssetId Impacted asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImpactedAsset(processId: string, impactedAssetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImpactedAsset(processId, impactedAssetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete person Group accordion
         * @summary Delete Person group accordion
         * @param {string} processId Process Id
         * @param {string} personGroupId Person group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePersonGroup(processId: string, personGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePersonGroup(processId, personGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete processor PA
         * @summary Delete processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProcessorPA(processorPAId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProcessorPA(processorPAId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete purpose accordion
         * @summary Delete purpose accordion
         * @param {string} processId Process Id
         * @param {string} purposeId Purpose id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePurpose(processId: string, purposeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePurpose(processId, purposeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit process
         * @summary Edit process
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editProcess(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editProcess(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The frontend needs to do special UI things on ai related assets
         * @summary Get Asset IDs eligible for AI check
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiAssets(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAiAssets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiAssets(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the AI precheck status for a processing activity
         * @summary Get AI Precheck Status
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiPrecheckStatus(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAiPrecheckStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiPrecheckStatus(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the AI Techdocs precheck status for a processing activity
         * @summary Get AI Techdocs Precheck Status
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiTechDocsPrecheckStatus(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAiTechDocsPrecheckStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiTechDocsPrecheckStatus(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Change Page
         * @summary Get Change Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChange(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChange200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChange(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get dpia processor pa info
         * @summary Get dpia processor pa info
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDPIAProcessorInfo(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDPIAProcessorInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDPIAProcessorInfo(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Dpia Page
         * @summary Get Dpia Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDpia(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDpia200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDpia(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get final assessment Page
         * @summary Get final assessment Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinalAssessment(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFinalAssessment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinalAssessment(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of impacted assets for a processing activity
         * @summary Get Impacted Assets
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImpactedAssets(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetImpactedAssets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImpactedAssets(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the allowed measures to be used with pa
         * @summary Get measures options
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasureOptions(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMeasureOptions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasureOptions(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get PA permissions
         * @summary Get PA permissions
         * @param {string} processId Process Id
         * @param {boolean} [forcePAMode] Whether to force pa mode for backwards compatibility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPAPermissions(processId: string, forcePAMode?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PAPermissionsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPAPermissions(processId, forcePAMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get process detail
         * @summary Get process detail
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessDetail(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessingActivityDetailDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessDetail(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get processing Page
         * @summary Get processing Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessing(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProcessing200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessing(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get processing activity histories
         * @summary Get processing activity histories
         * @param {string} processId Process Id
         * @param {string} [nextCursor] Pass in to query the next page
         * @param {number} [itemsCount] The target count of item requested. Not guaranteed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessingActivityHistories(processId: string, nextCursor?: string, itemsCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionHistoriesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessingActivityHistories(processId, nextCursor, itemsCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get processor PA
         * @summary Get single processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessorPA(processorPAId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProcessorPADTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessorPA(processorPAId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all processor PAs
         * @summary Get all processor PAs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessorsPA(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessorsPADTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessorsPA(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get proportionality Page
         * @summary Get proportionality Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProportionality(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProportionality200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProportionality(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get rights Page
         * @summary Get rights Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRights(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRights200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRights(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request edit process
         * @summary Request edit process
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestEditProcess(processId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestEditProcess(processId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the AI risk level for a processing activity
         * @summary Set AI Risk Level
         * @param {string} processId Process Id
         * @param {SetAiRiskLevelRequest} setAiRiskLevelRequest AI risk level payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAiRiskLevel(processId: string, setAiRiskLevelRequest: SetAiRiskLevelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAiRiskLevel(processId, setAiRiskLevelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit process
         * @summary Submit process
         * @param {string} processId Process Id
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitProcess(processId: string, dryRun?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitProcess(processId, dryRun, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update assessment measure
         * @summary Update assessment measure
         * @param {string} processId Process Id
         * @param {string} assessmentMeasureId Assessment measure id
         * @param {UpdateAssessmentMeasureRequest} updateAssessmentMeasureRequest Assessment measure payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssessmentMeasure(processId: string, assessmentMeasureId: string, updateAssessmentMeasureRequest: UpdateAssessmentMeasureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssessmentMeasure(processId, assessmentMeasureId, updateAssessmentMeasureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update asset ids
         * @summary Update asset ids
         * @param {string} processId Process Id
         * @param {UpdateAssetIdsDTO} updateAssetIdsDTO Asset ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssetIds(processId: string, updateAssetIdsDTO: UpdateAssetIdsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssetIds(processId, updateAssetIdsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Change page
         * @summary Update Change page
         * @param {string} processId Process Id
         * @param {UpdateChangePageDTO} updateChangePageDTO Change payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChange(processId: string, updateChangePageDTO: UpdateChangePageDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChange(processId, updateChangePageDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update consultation data
         * @summary Update consultation data
         * @param {string} processId Process Id
         * @param {UpdateConsultationDataRequest} updateConsultationDataRequest Consultation data payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConsultationData(processId: string, updateConsultationDataRequest: UpdateConsultationDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConsultationData(processId, updateConsultationDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Description
         * @summary Update Description
         * @param {string} processId Process Id
         * @param {UpdateDescriptionDTO} updateDescriptionDTO Description field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDescription(processId: string, updateDescriptionDTO: UpdateDescriptionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDescription(processId, updateDescriptionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Dpia page
         * @summary Update Dpia page
         * @param {string} processId Process Id
         * @param {UpdateDpiaPageDTO} updateDpiaPageDTO Dpia payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDpia(processId: string, updateDpiaPageDTO: UpdateDpiaPageDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDpia(processId, updateDpiaPageDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update assessment
         * @summary Update assessment
         * @param {string} processId Process Id
         * @param {UpdateFinalAssessmentRequest} updateFinalAssessmentRequest Assessment payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFinalAssessment(processId: string, updateFinalAssessmentRequest: UpdateFinalAssessmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFinalAssessment(processId, updateFinalAssessmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing impacted asset for a processing activity
         * @summary Update Impacted Asset
         * @param {string} processId Process Id
         * @param {string} impactedAssetId Impacted asset id
         * @param {ImpactedAssetDTO} impactedAssetDTO Impacted asset payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateImpactedAsset(processId: string, impactedAssetId: string, impactedAssetDTO: ImpactedAssetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateImpactedAsset(processId, impactedAssetId, impactedAssetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update person group accordion
         * @summary Update person group accordion
         * @param {string} processId Process Id
         * @param {string} personGroupId Person group id
         * @param {UpdatePersonGroupDTO} updatePersonGroupDTO Person group payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonGroup(processId: string, personGroupId: string, updatePersonGroupDTO: UpdatePersonGroupDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonGroup(processId, personGroupId, updatePersonGroupDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update processor PA
         * @summary Update processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {PatchProcessorPADTO} patchProcessorPADTO Processor PA payload
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProcessorPA(processorPAId: string, patchProcessorPADTO: PatchProcessorPADTO, dryRun?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProcessorPA(processorPAId, patchProcessorPADTO, dryRun, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Proportionality
         * @summary Update Proportionality
         * @param {string} processId Process Id
         * @param {UpdateProportionalityDTO} updateProportionalityDTO Proportionality field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProportionality(processId: string, updateProportionalityDTO: UpdateProportionalityDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProportionality(processId, updateProportionalityDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update purpose accordion
         * @summary Update purpose accordion
         * @param {string} processId Process Id
         * @param {string} purposeId Purpose id
         * @param {UpdatePurposeDTO} updatePurposeDTO Purpose payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePurpose(processId: string, purposeId: string, updatePurposeDTO: UpdatePurposeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePurpose(processId, purposeId, updatePurposeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update rights page
         * @summary Update rights page
         * @param {string} processId Process Id
         * @param {UpdateRightsPageDTO} updateRightsPageDTO Rights payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRights(processId: string, updateRightsPageDTO: UpdateRightsPageDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRights(processId, updateRightsPageDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update processor PA status
         * @summary Approve or unapprove processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {UpdateStatusProcessorPADTO} updateStatusProcessorPADTO Processor PA payload
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatusProcessorPA(processorPAId: string, updateStatusProcessorPADTO: UpdateStatusProcessorPADTO, dryRun?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatusProcessorPA(processorPAId, updateStatusProcessorPADTO, dryRun, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Add assessment measure
         * @summary Add assessment measure
         * @param {string} processId Process Id
         * @param {AddAssessmentMeasureRequest} addAssessmentMeasureRequest Assessment measure payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAssessmentMeasure(processId: string, addAssessmentMeasureRequest: AddAssessmentMeasureRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addAssessmentMeasure(processId, addAssessmentMeasureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new impacted asset to a processing activity
         * @summary Add Impacted Asset
         * @param {string} processId Process Id
         * @param {ImpactedAssetDTO} [impactedAssetDTO] Impacted asset payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addImpactedAsset(processId: string, impactedAssetDTO?: ImpactedAssetDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addImpactedAsset(processId, impactedAssetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Approve process
         * @summary Approve process
         * @param {string} processId Process Id
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveProcess(processId: string, dryRun?: string, options?: any): AxiosPromise<void> {
            return localVarFp.approveProcess(processId, dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * Create processing activity
         * @summary Create processing activity
         * @param {CreatePARequest} createPARequest Create PA payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPA(createPARequest: CreatePARequest, options?: any): AxiosPromise<void> {
            return localVarFp.createPA(createPARequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create person group accordion
         * @summary Create person group accordion
         * @param {string} processId Process Id
         * @param {CreatePurposeRequest} createPurposeRequest create id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonGroup(processId: string, createPurposeRequest: CreatePurposeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createPersonGroup(processId, createPurposeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create purpose accordion
         * @summary Create purpose accordion
         * @param {string} processId Process Id
         * @param {CreatePurposeRequest} createPurposeRequest create id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurpose(processId: string, createPurposeRequest: CreatePurposeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createPurpose(processId, createPurposeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete assessment measure
         * @summary Delete assessment measure
         * @param {string} processId Process Id
         * @param {string} assessmentMeasureId Assessment measure id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentMeasure(processId: string, assessmentMeasureId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAssessmentMeasure(processId, assessmentMeasureId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete impacted asset
         * @summary Delete impacted asset
         * @param {string} processId Process Id
         * @param {string} impactedAssetId Impacted asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImpactedAsset(processId: string, impactedAssetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteImpactedAsset(processId, impactedAssetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete person Group accordion
         * @summary Delete Person group accordion
         * @param {string} processId Process Id
         * @param {string} personGroupId Person group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersonGroup(processId: string, personGroupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePersonGroup(processId, personGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete processor PA
         * @summary Delete processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcessorPA(processorPAId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProcessorPA(processorPAId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete purpose accordion
         * @summary Delete purpose accordion
         * @param {string} processId Process Id
         * @param {string} purposeId Purpose id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePurpose(processId: string, purposeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePurpose(processId, purposeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit process
         * @summary Edit process
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProcess(processId: string, options?: any): AxiosPromise<void> {
            return localVarFp.editProcess(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * The frontend needs to do special UI things on ai related assets
         * @summary Get Asset IDs eligible for AI check
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiAssets(processId: string, options?: any): AxiosPromise<GetAiAssets200Response> {
            return localVarFp.getAiAssets(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the AI precheck status for a processing activity
         * @summary Get AI Precheck Status
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiPrecheckStatus(processId: string, options?: any): AxiosPromise<GetAiPrecheckStatus200Response> {
            return localVarFp.getAiPrecheckStatus(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the AI Techdocs precheck status for a processing activity
         * @summary Get AI Techdocs Precheck Status
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiTechDocsPrecheckStatus(processId: string, options?: any): AxiosPromise<GetAiTechDocsPrecheckStatus200Response> {
            return localVarFp.getAiTechDocsPrecheckStatus(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Change Page
         * @summary Get Change Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChange(processId: string, options?: any): AxiosPromise<GetChange200Response> {
            return localVarFp.getChange(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get dpia processor pa info
         * @summary Get dpia processor pa info
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDPIAProcessorInfo(processId: string, options?: any): AxiosPromise<GetDPIAProcessorInfo200Response> {
            return localVarFp.getDPIAProcessorInfo(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Dpia Page
         * @summary Get Dpia Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDpia(processId: string, options?: any): AxiosPromise<GetDpia200Response> {
            return localVarFp.getDpia(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get final assessment Page
         * @summary Get final assessment Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinalAssessment(processId: string, options?: any): AxiosPromise<GetFinalAssessment200Response> {
            return localVarFp.getFinalAssessment(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of impacted assets for a processing activity
         * @summary Get Impacted Assets
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpactedAssets(processId: string, options?: any): AxiosPromise<GetImpactedAssets200Response> {
            return localVarFp.getImpactedAssets(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the allowed measures to be used with pa
         * @summary Get measures options
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasureOptions(processId: string, options?: any): AxiosPromise<GetMeasureOptions200Response> {
            return localVarFp.getMeasureOptions(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get PA permissions
         * @summary Get PA permissions
         * @param {string} processId Process Id
         * @param {boolean} [forcePAMode] Whether to force pa mode for backwards compatibility
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPAPermissions(processId: string, forcePAMode?: boolean, options?: any): AxiosPromise<PAPermissionsDTO> {
            return localVarFp.getPAPermissions(processId, forcePAMode, options).then((request) => request(axios, basePath));
        },
        /**
         * Get process detail
         * @summary Get process detail
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDetail(processId: string, options?: any): AxiosPromise<ProcessingActivityDetailDTO> {
            return localVarFp.getProcessDetail(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get processing Page
         * @summary Get processing Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessing(processId: string, options?: any): AxiosPromise<GetProcessing200Response> {
            return localVarFp.getProcessing(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get processing activity histories
         * @summary Get processing activity histories
         * @param {string} processId Process Id
         * @param {string} [nextCursor] Pass in to query the next page
         * @param {number} [itemsCount] The target count of item requested. Not guaranteed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessingActivityHistories(processId: string, nextCursor?: string, itemsCount?: number, options?: any): AxiosPromise<VersionHistoriesDTO> {
            return localVarFp.getProcessingActivityHistories(processId, nextCursor, itemsCount, options).then((request) => request(axios, basePath));
        },
        /**
         * Get processor PA
         * @summary Get single processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessorPA(processorPAId: string, options?: any): AxiosPromise<GetProcessorPADTO> {
            return localVarFp.getProcessorPA(processorPAId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all processor PAs
         * @summary Get all processor PAs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessorsPA(options?: any): AxiosPromise<ProcessorsPADTO> {
            return localVarFp.getProcessorsPA(options).then((request) => request(axios, basePath));
        },
        /**
         * Get proportionality Page
         * @summary Get proportionality Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProportionality(processId: string, options?: any): AxiosPromise<GetProportionality200Response> {
            return localVarFp.getProportionality(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get rights Page
         * @summary Get rights Page
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRights(processId: string, options?: any): AxiosPromise<GetRights200Response> {
            return localVarFp.getRights(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<OkResponse> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
        /**
         * Request edit process
         * @summary Request edit process
         * @param {string} processId Process Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEditProcess(processId: string, options?: any): AxiosPromise<void> {
            return localVarFp.requestEditProcess(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the AI risk level for a processing activity
         * @summary Set AI Risk Level
         * @param {string} processId Process Id
         * @param {SetAiRiskLevelRequest} setAiRiskLevelRequest AI risk level payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAiRiskLevel(processId: string, setAiRiskLevelRequest: SetAiRiskLevelRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setAiRiskLevel(processId, setAiRiskLevelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit process
         * @summary Submit process
         * @param {string} processId Process Id
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitProcess(processId: string, dryRun?: string, options?: any): AxiosPromise<void> {
            return localVarFp.submitProcess(processId, dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * Update assessment measure
         * @summary Update assessment measure
         * @param {string} processId Process Id
         * @param {string} assessmentMeasureId Assessment measure id
         * @param {UpdateAssessmentMeasureRequest} updateAssessmentMeasureRequest Assessment measure payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentMeasure(processId: string, assessmentMeasureId: string, updateAssessmentMeasureRequest: UpdateAssessmentMeasureRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAssessmentMeasure(processId, assessmentMeasureId, updateAssessmentMeasureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update asset ids
         * @summary Update asset ids
         * @param {string} processId Process Id
         * @param {UpdateAssetIdsDTO} updateAssetIdsDTO Asset ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetIds(processId: string, updateAssetIdsDTO: UpdateAssetIdsDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateAssetIds(processId, updateAssetIdsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Change page
         * @summary Update Change page
         * @param {string} processId Process Id
         * @param {UpdateChangePageDTO} updateChangePageDTO Change payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChange(processId: string, updateChangePageDTO: UpdateChangePageDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateChange(processId, updateChangePageDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update consultation data
         * @summary Update consultation data
         * @param {string} processId Process Id
         * @param {UpdateConsultationDataRequest} updateConsultationDataRequest Consultation data payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsultationData(processId: string, updateConsultationDataRequest: UpdateConsultationDataRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateConsultationData(processId, updateConsultationDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Description
         * @summary Update Description
         * @param {string} processId Process Id
         * @param {UpdateDescriptionDTO} updateDescriptionDTO Description field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDescription(processId: string, updateDescriptionDTO: UpdateDescriptionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateDescription(processId, updateDescriptionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Dpia page
         * @summary Update Dpia page
         * @param {string} processId Process Id
         * @param {UpdateDpiaPageDTO} updateDpiaPageDTO Dpia payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDpia(processId: string, updateDpiaPageDTO: UpdateDpiaPageDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateDpia(processId, updateDpiaPageDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update assessment
         * @summary Update assessment
         * @param {string} processId Process Id
         * @param {UpdateFinalAssessmentRequest} updateFinalAssessmentRequest Assessment payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinalAssessment(processId: string, updateFinalAssessmentRequest: UpdateFinalAssessmentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateFinalAssessment(processId, updateFinalAssessmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing impacted asset for a processing activity
         * @summary Update Impacted Asset
         * @param {string} processId Process Id
         * @param {string} impactedAssetId Impacted asset id
         * @param {ImpactedAssetDTO} impactedAssetDTO Impacted asset payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImpactedAsset(processId: string, impactedAssetId: string, impactedAssetDTO: ImpactedAssetDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateImpactedAsset(processId, impactedAssetId, impactedAssetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update person group accordion
         * @summary Update person group accordion
         * @param {string} processId Process Id
         * @param {string} personGroupId Person group id
         * @param {UpdatePersonGroupDTO} updatePersonGroupDTO Person group payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonGroup(processId: string, personGroupId: string, updatePersonGroupDTO: UpdatePersonGroupDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updatePersonGroup(processId, personGroupId, updatePersonGroupDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update processor PA
         * @summary Update processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {PatchProcessorPADTO} patchProcessorPADTO Processor PA payload
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcessorPA(processorPAId: string, patchProcessorPADTO: PatchProcessorPADTO, dryRun?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateProcessorPA(processorPAId, patchProcessorPADTO, dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Proportionality
         * @summary Update Proportionality
         * @param {string} processId Process Id
         * @param {UpdateProportionalityDTO} updateProportionalityDTO Proportionality field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProportionality(processId: string, updateProportionalityDTO: UpdateProportionalityDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateProportionality(processId, updateProportionalityDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update purpose accordion
         * @summary Update purpose accordion
         * @param {string} processId Process Id
         * @param {string} purposeId Purpose id
         * @param {UpdatePurposeDTO} updatePurposeDTO Purpose payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePurpose(processId: string, purposeId: string, updatePurposeDTO: UpdatePurposeDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updatePurpose(processId, purposeId, updatePurposeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update rights page
         * @summary Update rights page
         * @param {string} processId Process Id
         * @param {UpdateRightsPageDTO} updateRightsPageDTO Rights payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRights(processId: string, updateRightsPageDTO: UpdateRightsPageDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateRights(processId, updateRightsPageDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Update processor PA status
         * @summary Approve or unapprove processor PA
         * @param {string} processorPAId Processor PA Id
         * @param {UpdateStatusProcessorPADTO} updateStatusProcessorPADTO Processor PA payload
         * @param {string} [dryRun] Dry run the command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusProcessorPA(processorPAId: string, updateStatusProcessorPADTO: UpdateStatusProcessorPADTO, dryRun?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateStatusProcessorPA(processorPAId, updateStatusProcessorPADTO, dryRun, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Add assessment measure
     * @summary Add assessment measure
     * @param {string} processId Process Id
     * @param {AddAssessmentMeasureRequest} addAssessmentMeasureRequest Assessment measure payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addAssessmentMeasure(processId: string, addAssessmentMeasureRequest: AddAssessmentMeasureRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addAssessmentMeasure(processId, addAssessmentMeasureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new impacted asset to a processing activity
     * @summary Add Impacted Asset
     * @param {string} processId Process Id
     * @param {ImpactedAssetDTO} [impactedAssetDTO] Impacted asset payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addImpactedAsset(processId: string, impactedAssetDTO?: ImpactedAssetDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addImpactedAsset(processId, impactedAssetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Approve process
     * @summary Approve process
     * @param {string} processId Process Id
     * @param {string} [dryRun] Dry run the command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public approveProcess(processId: string, dryRun?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).approveProcess(processId, dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create processing activity
     * @summary Create processing activity
     * @param {CreatePARequest} createPARequest Create PA payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPA(createPARequest: CreatePARequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPA(createPARequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create person group accordion
     * @summary Create person group accordion
     * @param {string} processId Process Id
     * @param {CreatePurposeRequest} createPurposeRequest create id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPersonGroup(processId: string, createPurposeRequest: CreatePurposeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPersonGroup(processId, createPurposeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create purpose accordion
     * @summary Create purpose accordion
     * @param {string} processId Process Id
     * @param {CreatePurposeRequest} createPurposeRequest create id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPurpose(processId: string, createPurposeRequest: CreatePurposeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPurpose(processId, createPurposeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete assessment measure
     * @summary Delete assessment measure
     * @param {string} processId Process Id
     * @param {string} assessmentMeasureId Assessment measure id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAssessmentMeasure(processId: string, assessmentMeasureId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteAssessmentMeasure(processId, assessmentMeasureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete impacted asset
     * @summary Delete impacted asset
     * @param {string} processId Process Id
     * @param {string} impactedAssetId Impacted asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteImpactedAsset(processId: string, impactedAssetId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteImpactedAsset(processId, impactedAssetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete person Group accordion
     * @summary Delete Person group accordion
     * @param {string} processId Process Id
     * @param {string} personGroupId Person group id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePersonGroup(processId: string, personGroupId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deletePersonGroup(processId, personGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete processor PA
     * @summary Delete processor PA
     * @param {string} processorPAId Processor PA Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProcessorPA(processorPAId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProcessorPA(processorPAId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete purpose accordion
     * @summary Delete purpose accordion
     * @param {string} processId Process Id
     * @param {string} purposeId Purpose id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePurpose(processId: string, purposeId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deletePurpose(processId, purposeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit process
     * @summary Edit process
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editProcess(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).editProcess(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The frontend needs to do special UI things on ai related assets
     * @summary Get Asset IDs eligible for AI check
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAiAssets(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAiAssets(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the AI precheck status for a processing activity
     * @summary Get AI Precheck Status
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAiPrecheckStatus(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAiPrecheckStatus(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the AI Techdocs precheck status for a processing activity
     * @summary Get AI Techdocs Precheck Status
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAiTechDocsPrecheckStatus(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAiTechDocsPrecheckStatus(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Change Page
     * @summary Get Change Page
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getChange(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getChange(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get dpia processor pa info
     * @summary Get dpia processor pa info
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDPIAProcessorInfo(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDPIAProcessorInfo(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Dpia Page
     * @summary Get Dpia Page
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDpia(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDpia(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get final assessment Page
     * @summary Get final assessment Page
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFinalAssessment(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFinalAssessment(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of impacted assets for a processing activity
     * @summary Get Impacted Assets
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getImpactedAssets(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getImpactedAssets(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the allowed measures to be used with pa
     * @summary Get measures options
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMeasureOptions(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMeasureOptions(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get PA permissions
     * @summary Get PA permissions
     * @param {string} processId Process Id
     * @param {boolean} [forcePAMode] Whether to force pa mode for backwards compatibility
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPAPermissions(processId: string, forcePAMode?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPAPermissions(processId, forcePAMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get process detail
     * @summary Get process detail
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProcessDetail(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProcessDetail(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get processing Page
     * @summary Get processing Page
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProcessing(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProcessing(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get processing activity histories
     * @summary Get processing activity histories
     * @param {string} processId Process Id
     * @param {string} [nextCursor] Pass in to query the next page
     * @param {number} [itemsCount] The target count of item requested. Not guaranteed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProcessingActivityHistories(processId: string, nextCursor?: string, itemsCount?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProcessingActivityHistories(processId, nextCursor, itemsCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get processor PA
     * @summary Get single processor PA
     * @param {string} processorPAId Processor PA Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProcessorPA(processorPAId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProcessorPA(processorPAId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all processor PAs
     * @summary Get all processor PAs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProcessorsPA(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProcessorsPA(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get proportionality Page
     * @summary Get proportionality Page
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProportionality(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProportionality(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get rights Page
     * @summary Get rights Page
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRights(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRights(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Health check
     * @summary Health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public health(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request edit process
     * @summary Request edit process
     * @param {string} processId Process Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public requestEditProcess(processId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).requestEditProcess(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the AI risk level for a processing activity
     * @summary Set AI Risk Level
     * @param {string} processId Process Id
     * @param {SetAiRiskLevelRequest} setAiRiskLevelRequest AI risk level payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setAiRiskLevel(processId: string, setAiRiskLevelRequest: SetAiRiskLevelRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setAiRiskLevel(processId, setAiRiskLevelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit process
     * @summary Submit process
     * @param {string} processId Process Id
     * @param {string} [dryRun] Dry run the command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public submitProcess(processId: string, dryRun?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).submitProcess(processId, dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update assessment measure
     * @summary Update assessment measure
     * @param {string} processId Process Id
     * @param {string} assessmentMeasureId Assessment measure id
     * @param {UpdateAssessmentMeasureRequest} updateAssessmentMeasureRequest Assessment measure payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAssessmentMeasure(processId: string, assessmentMeasureId: string, updateAssessmentMeasureRequest: UpdateAssessmentMeasureRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAssessmentMeasure(processId, assessmentMeasureId, updateAssessmentMeasureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update asset ids
     * @summary Update asset ids
     * @param {string} processId Process Id
     * @param {UpdateAssetIdsDTO} updateAssetIdsDTO Asset ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAssetIds(processId: string, updateAssetIdsDTO: UpdateAssetIdsDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAssetIds(processId, updateAssetIdsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Change page
     * @summary Update Change page
     * @param {string} processId Process Id
     * @param {UpdateChangePageDTO} updateChangePageDTO Change payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateChange(processId: string, updateChangePageDTO: UpdateChangePageDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateChange(processId, updateChangePageDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update consultation data
     * @summary Update consultation data
     * @param {string} processId Process Id
     * @param {UpdateConsultationDataRequest} updateConsultationDataRequest Consultation data payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateConsultationData(processId: string, updateConsultationDataRequest: UpdateConsultationDataRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateConsultationData(processId, updateConsultationDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Description
     * @summary Update Description
     * @param {string} processId Process Id
     * @param {UpdateDescriptionDTO} updateDescriptionDTO Description field
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateDescription(processId: string, updateDescriptionDTO: UpdateDescriptionDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateDescription(processId, updateDescriptionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Dpia page
     * @summary Update Dpia page
     * @param {string} processId Process Id
     * @param {UpdateDpiaPageDTO} updateDpiaPageDTO Dpia payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateDpia(processId: string, updateDpiaPageDTO: UpdateDpiaPageDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateDpia(processId, updateDpiaPageDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update assessment
     * @summary Update assessment
     * @param {string} processId Process Id
     * @param {UpdateFinalAssessmentRequest} updateFinalAssessmentRequest Assessment payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateFinalAssessment(processId: string, updateFinalAssessmentRequest: UpdateFinalAssessmentRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateFinalAssessment(processId, updateFinalAssessmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing impacted asset for a processing activity
     * @summary Update Impacted Asset
     * @param {string} processId Process Id
     * @param {string} impactedAssetId Impacted asset id
     * @param {ImpactedAssetDTO} impactedAssetDTO Impacted asset payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateImpactedAsset(processId: string, impactedAssetId: string, impactedAssetDTO: ImpactedAssetDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateImpactedAsset(processId, impactedAssetId, impactedAssetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update person group accordion
     * @summary Update person group accordion
     * @param {string} processId Process Id
     * @param {string} personGroupId Person group id
     * @param {UpdatePersonGroupDTO} updatePersonGroupDTO Person group payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePersonGroup(processId: string, personGroupId: string, updatePersonGroupDTO: UpdatePersonGroupDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updatePersonGroup(processId, personGroupId, updatePersonGroupDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update processor PA
     * @summary Update processor PA
     * @param {string} processorPAId Processor PA Id
     * @param {PatchProcessorPADTO} patchProcessorPADTO Processor PA payload
     * @param {string} [dryRun] Dry run the command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProcessorPA(processorPAId: string, patchProcessorPADTO: PatchProcessorPADTO, dryRun?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateProcessorPA(processorPAId, patchProcessorPADTO, dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Proportionality
     * @summary Update Proportionality
     * @param {string} processId Process Id
     * @param {UpdateProportionalityDTO} updateProportionalityDTO Proportionality field
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProportionality(processId: string, updateProportionalityDTO: UpdateProportionalityDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateProportionality(processId, updateProportionalityDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update purpose accordion
     * @summary Update purpose accordion
     * @param {string} processId Process Id
     * @param {string} purposeId Purpose id
     * @param {UpdatePurposeDTO} updatePurposeDTO Purpose payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePurpose(processId: string, purposeId: string, updatePurposeDTO: UpdatePurposeDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updatePurpose(processId, purposeId, updatePurposeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update rights page
     * @summary Update rights page
     * @param {string} processId Process Id
     * @param {UpdateRightsPageDTO} updateRightsPageDTO Rights payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateRights(processId: string, updateRightsPageDTO: UpdateRightsPageDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateRights(processId, updateRightsPageDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update processor PA status
     * @summary Approve or unapprove processor PA
     * @param {string} processorPAId Processor PA Id
     * @param {UpdateStatusProcessorPADTO} updateStatusProcessorPADTO Processor PA payload
     * @param {string} [dryRun] Dry run the command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateStatusProcessorPA(processorPAId: string, updateStatusProcessorPADTO: UpdateStatusProcessorPADTO, dryRun?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateStatusProcessorPA(processorPAId, updateStatusProcessorPADTO, dryRun, options).then((request) => request(this.axios, this.basePath));
    }
}


