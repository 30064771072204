import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import Question from "components/Question/Question";
import { useTranslation } from "react-i18next";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import AssignUsersMultiAutocomplete from "../../questionnaires/utils/AssignUsersMultiAutocomplete/AssignUsersMultiAutocomplete";
import { useUserDepartments } from "app/contexts/department-context";
import { DueDate } from "components/DueDate";
import { LabelField } from "components/LabelField";
import { useMetaView } from "app/contexts/meta-view-context";
import OrgunitsPathsAutocomplete from "components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { DateField } from "components/DateField";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";

import { BusinessProcessUpdateDTO, BusinessProcessWithPermission } from "app/api/businessProcessApi";
import { updateBusinessProcess } from "app/handlers/businessprocessHandler";

export const BusinessProcessGeneral = ({
  businessProcess,
  onUpdate,
  noWritePermission
}: {
  readonly businessProcess: Partial<BusinessProcessWithPermission> | null;
  readonly onUpdate: () => void;
  readonly noWritePermission: boolean;
}) => {
  const { t } = useTranslation("businessprocess_general");
  const { departmentsLoaded } = useUserDepartments();
  const { setInfo, setMeta } = useMetaView();
  const { catchAsSnackbar } = useErrorSnackbar();
  const [businessprocessData, setBusinessprocessData] = useState<Partial<BusinessProcessWithPermission> | null>(null);

  useEffect(() => {
    setBusinessprocessData(businessProcess);
  }, [businessProcess]);

  const infoCard: Record<string, { readonly title: string; readonly text: string }> = useMemo(
    () => ({
      entering: {
        title: t("business_process_overview:enteringInfoCardTitle"),
        text: t("business_process_overview:enteringInfoCardText")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  /* UPDATE BUSINESS PROCESS*/
  const updateData = useCallback(
    async (data: BusinessProcessUpdateDTO) => {
      if (businessprocessData && businessprocessData.id) {
        await updateBusinessProcess(businessprocessData.id, data).catch(catchAsSnackbar());
        onUpdate();
      }
    },
    [businessprocessData, onUpdate, catchAsSnackbar]
  );

  /* associatedOrgUnitIds */
  const onChangeAssociatedOrgUnitIds = useCallback(
    (furtherOrgUnitIds: string[] | undefined) => updateData({ furtherOrgUnitIds }),
    [updateData]
  );

  /* mainOrgUnitId */
  const onChangeMainOrgUnitId = useCallback((orgUnitId: string | null) => updateData({ orgUnitId }), [updateData]);

  /* DueDate */
  const setDueDateDataCollection = useCallback(dueAt => updateData({ dueAt }), [updateData]);
  /* AssigneeUID */
  const setAssigneeUID = useCallback(ids => updateData({ assigneeUID: ids[0] }), [updateData]);
  const assigneeUIDs = useMemo(
    () => (businessProcess?.assigneeUID ? [businessProcess.assigneeUID] : []),
    [businessProcess?.assigneeUID]
  );

  /* businessprocess Implementation date */
  const setImplementatedAt = useCallback(implementedAt => updateData({ implementedAt }), [updateData]);

  /* businessprocess End date */
  const setEndAt = useCallback(endAt => updateData({ endAt }), [updateData]);

  /* labelIds */
  const setLabelIds = useCallback(labels => updateData({ labelIds: labels }), [updateData]);

  const allowedOrgUnitIDs = useMemo(
    () =>
      [businessprocessData?.orgUnitId, ...(businessprocessData?.furtherOrgUnitIds || [])].filter(
        (nonNull): nonNull is string => !!nonNull
      ),
    [businessprocessData?.orgUnitId, businessprocessData?.furtherOrgUnitIds]
  );

  if (departmentsLoaded && businessProcess) {
    return (
      <>
        <QuestionnaireSubHeader text={t("general")} />
        <Question questionId="orgUnitId" questionName={t("orgUnitId")}>
          <OrgunitsPathsAutocomplete
            value={businessProcess.orgUnitId || ""}
            onChange={onChangeMainOrgUnitId}
            label={t("orgUnitId")}
            disabled={noWritePermission}
          />
        </Question>

        <Question
          qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
          questionName={t("furtherOrgUnitIds")}
          questionId="furtherOrgUnitIds"
          value={businessProcess.furtherOrgUnitIds}
          onChange={onChangeAssociatedOrgUnitIds}
          disabled={noWritePermission}
        />

        <Question questionName={t("assigneeUID")} questionId="assigneeUID">
          <AssignUsersMultiAutocomplete
            docOrgUnitIds={allowedOrgUnitIDs}
            id={"assigneeUID"}
            docAssignedUserIds={assigneeUIDs}
            onDocAssignedUserIdsChanged={setAssigneeUID}
            freeSolo={true}
            disableClearable={true}
            label={t("assigneeUID")}
            hasMultiSelect={false}
            hasDefaultValue={true}
            disabled={noWritePermission}
          />
        </Question>

        <Box display="flex">
          <Box flex={1} mr={3} overflow={"hidden"}>
            <Question questionName={t("implementationDate")} questionId="implementationDate" pt={0} pb={0}>
              <DateField
                date={businessProcess.implementedAt || null}
                onDateChange={setImplementatedAt}
                label={t("implementationDate")}
                disabled={noWritePermission}
              />
            </Question>
          </Box>
          <Box flex={1} mr={3} overflow={"hidden"}>
            <Question questionName={t("endDate")} questionId="endDate" pt={0} pb={0}>
              <DateField
                date={businessProcess.endAt || null}
                onDateChange={setEndAt}
                label={t("endDate")}
                disabled={noWritePermission}
              />
            </Question>
          </Box>
          <Box flex={1} overflow={"hidden"}>
            <Question questionName={t("dataCollectionDueDate")} questionId="dataCollectionDueDate" pt={0} pb={0}>
              <DueDate
                date={businessProcess.dueAt || null}
                onDateChange={setDueDateDataCollection}
                label={t("dataCollectionDueDate")}
                disabled={noWritePermission}
              />
            </Question>
          </Box>
        </Box>

        <Question questionName={t("labels:label")} questionId="labelIds">
          <LabelField
            selectedIDs={businessProcess.labels}
            onSelectionChanged={setLabelIds}
            disabled={noWritePermission}
          />
        </Question>
      </>
    );
  } else {
    return <></>;
  }
};

const cachedEmptyArrayToNotRerender: string[] = [];
