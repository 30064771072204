import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import i18n from "app/i18n";
import { MiniProcessingActivityDTO } from "./paApi";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.riskUrl}/api/risks`
});

export interface TreatmentDTO {
  readonly type: "measures" | "transfer" | "prevention" | "accept" | null;
  readonly measureIds: string[];
  readonly description: string | null;
  readonly version: number;
  readonly creatorUID: string;
  readonly updaterUID: string;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface AssessmentDTO {
  readonly occurrenceId: string | null;
  readonly damageExtendId: string | null;
  readonly impactDescription: string | null;
  readonly reasonDescription: string | null;
  readonly rating: number | null;
  readonly protectionObjectiveId: "main" | string;
  readonly version: number;
}

export interface PhaseAssessmentDTO {
  readonly individualAssessmentEnabled: boolean;
  readonly combinedAssessment?: AssessmentDTO;
  readonly individualAssessments?: AssessmentDTO[];
  readonly rating: number | null;
  readonly version: number;
}

export interface RiskDTO {
  readonly id: string;
  readonly tenantRiskId: number;
  readonly title: string;
  readonly description: string | null;
  readonly ownerUID: string | null;
  readonly orgUnitId: string | null;
  readonly privacyRelevant: boolean | null;
  readonly riskSourceIds: string[];
  readonly riskAssetIds: string[];
  readonly dataLocationIds: string[];
  readonly protectionObjectiveIds: string[];
  readonly implementedMeasureIds: string[];
  readonly furtherAffectedOrgUnitIds: string[];
  readonly type: "general" | "processing-activity" | "asset";
  readonly labelIds: string[];

  readonly rating: number | null;
  readonly ratingLevel: string | null;

  readonly treatment: TreatmentDTO | null;
  readonly assessments: Record<"first" | "second", PhaseAssessmentDTO>;

  readonly version: number;
  readonly creatorUID: string;
  readonly updaterUID: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly permission: "read" | "write";
}

export interface RisksDTO {
  readonly risks: RiskDTO[];
}

export interface RiskMatrixItemDTO {
  readonly id: string;
  readonly name: string;
  readonly title: string;
  readonly rating: number;
  readonly damageExtendValue: number;
  readonly occurrenceValue: number;
}

export interface RiskMatrixDTO {
  readonly items: RiskMatrixItemDTO[];
}

export async function getRisksApi(type?: "general" | "processing-activity" | "asset") {
  const response = await axiosInstance.get<RisksDTO>("/", { params: { type } });
  return response.data.risks;
}

export async function getRiskApi(id: string) {
  const response = await axiosInstance.get<RiskDTO>(`/${id}`);
  return response.data;
}

export interface RiskPayloadDTO {
  readonly title: string;
  readonly description: string | null;
  readonly ownerUID: string | null;
  readonly orgUnitId: string | null;
  readonly privacyRelevant: boolean | null;
  readonly riskSourceIds: string[];
  readonly riskAssetIds: string[];
  readonly dataLocationIds: string[];
  readonly protectionObjectiveIds: string[];
  readonly implementedMeasureIds: string[];
  readonly furtherAffectedOrgUnitIds: string[];
  readonly type: "general" | "processing-activity" | "asset";
  readonly labelIds: string[];
}

export async function getRiskTOMsApi(id: string) {
  const response = await axiosInstance.get<TOMOptionsDTO>(`/${id}/options/toms`);
  return response.data;
}

export interface TOMOptionsDTO {
  readonly toms: TOMOptionDTO[];
}

export interface TOMOptionDTO {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly protectionObjectiveIds: string[];
  readonly labelIds: string[];
  readonly processSpecific: boolean;
}

export async function createRiskApi(title: string, additionalPayload: Partial<Omit<RiskPayloadDTO, "title">>) {
  const response = await axiosInstance.post(`/`, {
    title,
    ...(additionalPayload || {})
  });
  return response.headers["x-resource-id"] || "";
}

export async function updateRiskApi(
  id: string,
  version: number,
  {
    title,
    type,
    orgUnitId,
    furtherAffectedOrgUnitIds,
    description,
    ownerUID,
    privacyRelevant,
    riskSourceIds,
    riskAssetIds,
    dataLocationIds,
    protectionObjectiveIds,
    implementedMeasureIds,
    labelIds
  }: Partial<RiskPayloadDTO> = {}
) {
  const response = await axiosInstance.patch(`/${id}`, {
    title,
    type,
    orgUnitId,
    furtherAffectedOrgUnitIds,
    description,
    ownerUID,
    privacyRelevant,
    riskSourceIds,
    riskAssetIds,
    dataLocationIds,
    protectionObjectiveIds,
    implementedMeasureIds,
    version,
    labelIds
  });
  return response.data;
}

export async function updateRiskTreatmentApi(
  id: string,
  version: number,
  {
    type,
    measureIds,
    description
  }: {
    type?: "measures" | "transfer" | "prevention" | "accept" | null;
    measureIds?: string[];
    description?: string | null;
  } = {}
) {
  const response = await axiosInstance.patch(`/${id}/treatments`, {
    type,
    measureIds,
    description,
    version
  });
  return response.data;
}

export async function updateRiskAssessmentSettingApi(
  id: string,
  phaseId: "first" | "second",
  version: number,
  {
    individualAssessmentEnabled
  }: {
    individualAssessmentEnabled: boolean;
  }
) {
  const response = await axiosInstance.patch(`/${id}/phases/${phaseId}`, {
    individualAssessmentEnabled,
    version
  });

  return response.data;
}

export async function updateRiskAssessmentApi(
  id: string,
  phaseId: "first" | "second",
  protectionObjectiveId: string,
  version: number,
  {
    occurrenceId,
    damageExtendId,
    impactDescription,
    reasonDescription
  }: {
    occurrenceId?: string | null;
    damageExtendId?: string | null;
    impactDescription?: string | null;
    reasonDescription?: string | null;
  } = {}
) {
  const response = await axiosInstance.patch(`/${id}/phases/${phaseId}/assessments/${protectionObjectiveId}`, {
    occurrenceId,
    damageExtendId,
    impactDescription,
    reasonDescription,
    version
  });

  return response.data;
}

export const getRisksExports = async (params: { riskIDs: string[] }) => {
  const response = await axiosInstance.post(
    `/exports`,
    {
      riskIDs: params.riskIDs
    },
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      },
      responseType: "blob",
      timeout: 300000
    }
  );
  return new Blob([response.data]);
};

export async function deleteRiskApi(id: string, version: number) {
  await axiosInstance.delete(`/${id}`, { params: { version } });
}

export async function copyRiskApi(id: string) {
  const response = await axiosInstance.post(`/${id}`);
  return response.headers["x-resource-id"] || "";
}

export const getRisksMatrixApi = async (filter?: Record<string, unknown>) => {
  const response = await axiosInstance.get<RiskMatrixDTO>("/overview/risks/matrix", {
    baseURL: `${apiEndpoints.riskUrl}/api`,
    params: filter
  });
  return response.data;
};

export const getRisksProcesses = async (id: string) => {
  const response = await axiosInstance.get<{
    pas: { id: string; name: string; orgUnit: string[]; status: string }[];
  }>(`/overview/risks/${id}/pas`, {
    baseURL: `${apiEndpoints.riskUrl}/api`
  });
  return response.data;
};

export async function linkToPAs(input: { readonly riskId: string; readonly paIds: string[] }) {
  const { riskId, paIds } = input;
  return await axiosInstance.post(`/${riskId}/link/pas`, { paIds });
}

export async function unlinkToPAs(input: { readonly riskId: string; readonly paIds: string[] }) {
  const { riskId, paIds } = input;
  return await axiosInstance.post(`/${riskId}/unlink/pas`, { paIds });
}

export const getPaOptions = async (id: string) => {
  const response = await axiosInstance.get<{
    readonly pas: MiniProcessingActivityDTO[];
  }>(`/${id}/options/pas`);
  return response ? response.data : null;
};
