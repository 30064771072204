import { Box, IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback, useState } from "react";
import { t } from "i18next";
import stopEvent from "tool/stopEvent";
import { useDocumentPermissions } from "hook/useDocumentPermissions";
import { Link } from "@material-ui/icons";
import {
  FolderMetaData,
  getDocumentFolderMetaDataApi,
  setDocumentFolderMetaDataApi
} from "app/api/file-storage/documentApi";
import OrgUnitPickerModal from "app/pages/shared/OrgUnitPickerModal/OrgUnitPickerModal";
import { useSnackbar } from "notistack";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";

const DocumentOverviewBrickLinkOrgUnit = ({ item, hovered }: OverviewRowBrickProps) => {
  const isFolderItem = !item.fileType;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useOverviewDispatch();

  const reloadOverview = useCallback(async () => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.DOCUMENTS, reload });
  }, [dispatch]);

  const { canEditCurrentFolder, userOrgUnitIds } = useDocumentPermissions(item);
  const [folderToOrgUnits, setFolderToOrgUnits] = useState<{
    readonly selectedOrgUnitIds: string[];
    readonly userOrgUnitIds?: string[];
    readonly allowedParentOrgUnitIds?: string[];
    readonly path: string;
  } | null>(null);

  const onClickCallback = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      stopEvent(event);
      if (item.path.includes("/")) {
        const parentPath = item.path.substring(0, item.path.lastIndexOf("/"));
        const parentMetaData: FolderMetaData = await getDocumentFolderMetaDataApi(parentPath);
        setFolderToOrgUnits({
          selectedOrgUnitIds: item.orgUnitIds,
          userOrgUnitIds: userOrgUnitIds,
          allowedParentOrgUnitIds: parentMetaData.orgUnitIds,
          path: item.path
        });
        // else root
      } else {
        setFolderToOrgUnits({
          selectedOrgUnitIds: item.orgUnitIds,
          userOrgUnitIds: userOrgUnitIds,
          allowedParentOrgUnitIds: userOrgUnitIds,
          path: item.path
        });
      }
    },
    [item.orgUnitIds, item.path, userOrgUnitIds]
  );

  const handleConfirmOrgUnitPicker = useCallback(
    async (orgUnitIds: string[]) => {
      try {
        await setDocumentFolderMetaDataApi(folderToOrgUnits?.path || "", orgUnitIds);
      } catch (e) {
        enqueueSnackbar("Can not update folder metadata", { variant: "error" });
      }
      reloadOverview();
    },
    [enqueueSnackbar, folderToOrgUnits?.path, reloadOverview]
  );

  const handleCloseOrgUnitPicker = useCallback(() => setFolderToOrgUnits(null), []);

  if (!hovered || !isFolderItem) {
    return <></>;
  }

  return (
    <>
      <Tooltip title={canEditCurrentFolder ? t("assets_overview:linkOrganizationText") : t("common:missingPermission")}>
        <Box>
          <IconButton onClick={onClickCallback} disabled={!canEditCurrentFolder} size="small">
            <Link />
          </IconButton>
        </Box>
      </Tooltip>
      <OrgUnitPickerModal
        visibleDepartmentIds={folderToOrgUnits?.userOrgUnitIds}
        enabledOrgUnitIds={folderToOrgUnits?.allowedParentOrgUnitIds}
        onClose={handleCloseOrgUnitPicker}
        onConfirm={handleConfirmOrgUnitPicker}
        open={Boolean(folderToOrgUnits)}
        selectedDepartmentIds={folderToOrgUnits?.selectedOrgUnitIds || []}
        required={true}
      />
    </>
  );
};

export default DocumentOverviewBrickLinkOrgUnit;
