import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import overviewControllerFabric from "components/Overview/controllers/overviewControllerFabric";
import { OverviewItem, OverviewNewItem } from "./controllers/overviewBaseController";
import { debounce } from "lodash-es";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import { OVERVIEW_ADD_TYPE } from "./constants/OverviewConstants";
import OverviewAdd from "./controls/OverviewAdd";
import { OverviewTemplateDialog } from "./controls/OverviewTemplateDialog";
import { useSnackbar } from "notistack";
import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import { OverviewAddButtonActionProps } from "./controls/OverviewAddButton";
import { OverviewPageProps } from "./controls/OverviewPagesMenu";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { isAxiosErrorWithCode } from "../../app/api/axios/axiosErrorHandler";
import { useSidebarSWR } from "../../app/pages/shared/Sidebar/useSidebarUnseen";
import { AccountNotice } from "../../app/pages/authentication/AccountNotice";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { CollectionParams, useOverviewData } from "hook/useOverviewData";
import { OverviewToolbar, OverviewToolbarActionProps, OverviewToolbarProps } from "./controls/OverviewToolbar";
import { findAncestors, findDescendants, getCheckableItemIds } from "./utils/overviewBaseController.traverse";
import { useNavigation } from "app/pages/shared/GoTo/GoTo";
import { OverviewBrickProps, OverviewRow, OverviewRowProps } from "./controls/OverviewRow";
import { styled } from "@mui/material/styles";
import OverviewNoItemsFound from "./controls/OverviewNoItemsFound";

export interface OverviewParams {
  readonly collection: COLLECTION_TYPES;
  readonly checkable?: boolean;
  readonly collectionParams?: CollectionParams;
  readonly deletable?: boolean;
  readonly dnd?: boolean;
  readonly dynamicIndentation?: boolean;
  readonly header?: string;
  readonly noEntriesCustomEl?: React.ReactNode;
  readonly noEntriesText?: string;
  readonly paginated?: boolean;
  readonly bricks?: OverviewBrickProps[];
  readonly isMultilingual?: boolean;
  readonly sectionActions?: OverviewToolbarActionProps[];
  readonly selectable?: boolean;
  readonly shadowLoading?: boolean;
  readonly subHeader?: React.ReactNode;

  /* TOOLBAR */
  readonly toolbarHeader?: string;
  readonly toolbarActions?: OverviewToolbarActionProps[];
  readonly toolbarMode?: "tabs" | "none";
  readonly translationDomainName?: string;
  readonly showSelectAll?: boolean;
  readonly toolbarAddControl?: React.ReactNode;
  readonly hideCount?: boolean;
  readonly hideNoEntries?: boolean;
  readonly hideSearch?: boolean;
  readonly pages?: OverviewPageProps[];
  readonly addActions?: OverviewAddButtonActionProps[];
  readonly selectionActions?: OverviewToolbarActionProps[];

  readonly onSelectAllHover?: (hovered: boolean) => void;
  readonly children?: React.ReactNode;
  readonly setItemToMove?: (item: OverviewItem | null) => void;
  readonly onAddClose?: () => void;
  readonly onAddOpen?: () => void;
  readonly onCheckedItems?: (items: OverviewItem[]) => void;
  readonly onPageChange?: (page: OverviewPageProps) => void;
  readonly onRowClick: (item: OverviewItem) => void;
  readonly onRowLeave?: () => void;
  readonly onRowOver?: (item: OverviewItem) => void;
  readonly setOpenMoveModal?: (value: boolean) => void;
}

const useStyles = makeStyles(theme => ({
  "@global": {
    "@keyframes slide": {
      from: {
        backgroundPositionX: "0"
      },
      to: {
        backgroundPositionX: "113px"
      }
    },
    ".MuiAutocomplete-tag": {
      backgroundColor: `${theme.palette.blue[100]} !important`
    }
  },
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "&.dragging": {
      pointerEvents: "none"
    },
    "&.minHeight": {
      minHeight: "400px"
    }
  },
  endOfList: {
    fontSize: "12px",
    color: theme.palette.grey[500]
  },
  header: {
    borderRadius: "8px 8px 0 0",
    background: theme.palette.background.paper,
    zIndex: 900,
    "&.scrolled": {
      boxShadow: "0 8px 6px -6px #ccc"
    }
  },
  titleText: {
    fontSize: "28px",
    transition: "font-size 0.3s ease-out",
    "&.scrolled": {
      transition: "font-size 0.3s ease-out",
      fontSize: "20px"
    }
  }
}));

const ScrollableBox = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: "auto",
  paddingRight: "12px", // reserve space for scrollbar
  "&::-webkit-scrollbar": {
    width: theme.spacing(1)
  },
  scrollbarWidth: "auto",
  scrollbarColor: `${theme.palette.grey[300]} ${theme.palette.background.paper}`,
  "&.empty": {
    flex: "0 0 auto" // don't grow when empty
  }
}));

const Overview = ({
  addActions,
  checkable,
  children,
  collection,
  collectionParams,
  deletable,
  dnd,
  dynamicIndentation,
  header,
  hideCount,
  hideNoEntries,
  hideSearch,
  noEntriesCustomEl,
  noEntriesText,
  pages,
  paginated,
  bricks,
  isMultilingual,
  selectable,
  selectionActions,
  shadowLoading = false,
  subHeader,
  toolbarActions,
  toolbarMode,
  translationDomainName,
  toolbarHeader,
  toolbarAddControl,
  showSelectAll = true,

  onSelectAllHover,
  setItemToMove,
  setOpenMoveModal,
  onAddClose,
  onAddOpen,
  onCheckedItems,
  onPageChange,
  onRowClick,
  onRowLeave,
  onRowOver
}: OverviewParams) => {
  const cls = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { sidebarNewItemsMutate } = useSidebarSWR();
  const { t } = useTranslation("overview");
  const navigateTo = useNavigation();
  const overviewSetup = useOverviewState()[collection];
  const dispatch = useOverviewDispatch();

  const [showAddEl, setShowAddEl] = useState(<></>);
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [dragItem, setDragItem] = useState<OverviewItem | null>(null);
  const [controller] = useState(overviewControllerFabric(collection, collectionParams));
  const checkedIds = overviewSetup.checkedIds;
  const [activeRow, setActiveRow] = useState<string | null>(null);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  // we display the checkboxes of all rows on hover of the super checkbox and when at least one item is checked
  const [showRowsSelectCheckbox, setShowRowsSelectCheckbox] = useState<boolean>(false);

  const { overviewData, fetchNextPaginatedPage, reload, fetchNextPage } = useOverviewData({
    collection,
    collectionParams,
    controller,
    dispatch,
    overviewSetup,
    paginated,
    shadowLoading
  });

  /* CONTROLLER ACTIONS */
  const handleAPIError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (error, payload) => {
      if (isAxiosErrorWithCode(error, 409)) {
        enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
        return;
      }
      if (isAxiosErrorWithCode(error, 403) && collection === COLLECTIONS.ROLE) {
        enqueueSnackbar(`${t("error_messages:role_is_used_cant_be_delete")}`, { variant: "error" });
        return;
      }
      if (error.response?.data?.message === "Not allowed to delete process") {
        enqueueSnackbar(`${t("error_messages:not_permission_delete_document")}`, { variant: "error" });
        return;
      }
      if (isAxiosErrorWithCode(error, 403)) {
        enqueueSnackbar(`${t("error_messages:no_permission")}`, { variant: "error" });
        return;
      }
      const errorMessage = error.response?.data?.message || error.message;
      enqueueSnackbar(errorMessage, { variant: "error" });
      throw error;
    },
    [enqueueSnackbar, t, collection]
  );

  const onAdd = useCallback(
    async (data: OverviewNewItem) => {
      const validationError = controller.validateItem?.(data);
      if (validationError) {
        enqueueSnackbar(validationError, { variant: "error" });
        return;
      }
      try {
        const id = await controller.addItem(data);
        reload(id, true);
      } catch (error) {
        handleAPIError(error, data.title || "");
      }
    },
    [controller, enqueueSnackbar, reload, handleAPIError]
  );

  const onAddAndGo = useCallback(async () => {
    const id = await controller.addItemAndGo();
    reload(id);
  }, [controller, reload]);

  const onCloseTemplateDialog = useCallback(() => setShowTemplateDialog(false), []);

  const onAddFromTemplates = useCallback(
    async templateIds => {
      if (!templateIds.length) {
        await onAddAndGo();
      } else {
        await controller.addItemsFromTemplates({ templateIds });
        reload();
        setShowTemplateDialog(false);
      }
    },
    [controller, reload, onAddAndGo]
  );

  const templateDialogEl = showTemplateDialog ? (
    <OverviewTemplateDialog
      collection={collection}
      passedTemplates={overviewData?.templates}
      getTemplates={controller.getTemplateItems}
      onClose={onCloseTemplateDialog}
      onConfirm={onAddFromTemplates}
    />
  ) : (
    <></>
  );

  useEffect(() => {
    if (checkedIds.length === 0) {
      onCheckedItems?.([]);
      setShowRowsSelectCheckbox(false);
    } else setShowRowsSelectCheckbox(true);
  }, [checkedIds, onCheckedItems]);

  const onDuplicateItems = useCallback(
    async orgUnitIds => {
      dispatch({
        type: OVERVIEW_ACTIONS.ACTION_STARTED,
        collection
      });
      try {
        await controller.copyItems({ ids: checkedIds, orgUnitIds });
        dispatch({
          type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
          collection: collection,
          checkedIds: []
        });
        dispatch({
          type: OVERVIEW_ACTIONS.ACTION_COMPLETED,
          collection
        });
        reload();
      } catch (error) {
        dispatch({
          type: OVERVIEW_ACTIONS.ACTION_FAILED,
          collection
        });
      }
    },
    [checkedIds, collection, controller, dispatch, reload]
  );

  const onDeleteItems = useCallback(async () => {
    try {
      if (controller.deleteItems) {
        await controller.deleteItems(checkedIds);
      } else {
        // we want to always have (15) requests running in parallel
        const numberParallelRequests = 15;
        const executing = new Set<Promise<unknown>>();
        for (const id of checkedIds) {
          const promise = controller.deleteItem(id);
          executing.add(promise);
          // Remove a promise from the set when it finishes
          promise.finally(() => executing.delete(promise));
          // If the limit is reached, wait for the first one to finish
          if (executing.size >= numberParallelRequests) {
            await Promise.race(executing);
          }
        }
        // Wait for any remaining promises to finish
        await Promise.all(executing);
      }

      await sidebarNewItemsMutate();

      // remove deleted items from checked ids
      const checkedIdsSet = new Set(checkedIds);
      dispatch({
        type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
        collection: collection,
        checkedIds: checkedIds.filter(id => !checkedIdsSet.has(id))
      });
    } catch (error) {
      handleAPIError(error, {});
    }
    reload();
  }, [reload, controller, sidebarNewItemsMutate, checkedIds, dispatch, collection, handleAPIError]);

  const onExportItems = useCallback(
    async format => {
      await controller.exportItems(format, checkedIds, overviewSetup);
    },
    [checkedIds, controller, overviewSetup]
  );

  const onExportAllItems = useCallback(
    async format => {
      await controller.exportAllItems(format, overviewSetup);
    },
    [controller, overviewSetup]
  );

  const onMarkAllAsRead = useCallback(async () => {
    if (!controller.markAllAsRead) return;
    await controller.markAllAsRead();
    reload();
  }, [controller, reload]);

  useEffect(() => {
    if (checkedIds.length === 0) {
      onCheckedItems?.([]);
      setShowRowsSelectCheckbox(false);
    } else setShowRowsSelectCheckbox(true);
  }, [checkedIds, onCheckedItems]);

  const onCheckRow = useCallback(
    (item, checked) => {
      let parentIds: string[] = [];
      // on uncheck we deselect the parents
      if (!checked) {
        parentIds = findAncestors(overviewData?.items || [], item);
      }
      // we automatically deselect/select the children
      const itemChildrenIds = findDescendants(item).map(item => item.id);
      const _checkedIds = checked
        ? [...new Set([...checkedIds, ...itemChildrenIds, item.id])]
        : [
            ...new Set(
              checkedIds.filter(id => id !== item.id && !itemChildrenIds.includes(id) && !parentIds.includes(id))
            )
          ];
      dispatch({
        type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
        collection: collection,
        checkedIds: _checkedIds
      });
      onCheckedItems?.(_checkedIds.map(id => controller.getById(id)).filter(notNull => notNull));
    },
    [checkedIds, collection, controller, dispatch, onCheckedItems, overviewData?.items]
  );

  const onResetChecked = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
      collection: collection,
      checkedIds: []
    });
  }, [collection, dispatch]);

  const onSelectAllInPagination = useCallback(
    async (checked: boolean) => {
      const items = overviewData?.items || [];
      const checkableItemIds = getCheckableItemIds(items);
      dispatch({
        type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
        collection: collection,
        checkedIds: checked ? checkableItemIds : []
      });
      onCheckedItems?.(checked ? items : []);
    },
    [collection, dispatch, onCheckedItems, overviewData?.items]
  );

  const isPaginatedApi = useMemo(
    () => controller.getItemsWithChildrenCount(overviewData?.allItems) !== overviewData?.count?.allCount,
    [controller, overviewData?.allItems, overviewData?.count?.allCount]
  );
  const onSelectAll = useCallback(async () => {
    let allItems = overviewData?.allItems || [];
    // if overview api does not provide all items (new paginated apis)
    if (isPaginatedApi) {
      allItems = (await controller.loadAllPaginatedPages(overviewSetup))?.allItems || [];
    }
    const checkableItems = allItems.filter(item => !item.disableActions?.find(({ action }) => action === "checkable"));
    const checkableItemIds = getCheckableItemIds(checkableItems);
    dispatch({
      type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
      collection: collection,
      checkedIds: checkableItemIds
    });
    onCheckedItems?.(allItems);
  }, [collection, controller, dispatch, isPaginatedApi, onCheckedItems, overviewData?.allItems, overviewSetup]);

  const onPatch = useCallback(
    async (id, data, options, url, originalItem) => {
      try {
        await controller.patchItem(id, data, options, url, originalItem);
      } catch (error) {
        handleAPIError(error, data);
      }
      reload(undefined, true);
    },
    [controller, reload, handleAPIError]
  );

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await controller.deleteItem(id);
      } catch (error) {
        handleAPIError(error, {});
      }
      await sidebarNewItemsMutate();
      reload();
    },
    [controller, reload, handleAPIError, sidebarNewItemsMutate]
  );

  const onAddSection = useCallback(
    async title => {
      setShowAddEl(<></>);
      await controller.addSection(title);
      reload();
    },
    [controller, reload]
  );

  const onSearch = useCallback(
    text => {
      dispatch({ type: OVERVIEW_ACTIONS.SET_SEARCH, collection: collection, search: text });
    },
    [collection, dispatch]
  );

  const onSort = useCallback(
    ({ field, type }) => {
      dispatch({ type: OVERVIEW_ACTIONS.SET_SORT, collection: collection, sort: { [field]: type } });
    },
    [collection, dispatch]
  );

  const onFilter = useCallback(
    filter => {
      dispatch({ type: OVERVIEW_ACTIONS.SET_FILTER, collection: collection, filter });
    },
    [collection, dispatch]
  );

  const onReset = useCallback(
    field => {
      const action = field === "sort" ? OVERVIEW_ACTIONS.SET_SORT : OVERVIEW_ACTIONS.SET_FILTER;
      dispatch({ type: action, collection: collection, [field]: {} });
    },
    [collection, dispatch]
  );

  useEffect(() => {
    dispatch({ type: OVERVIEW_ACTIONS.SET_LOADING, collection, loading: true });
  }, [collection, dispatch]);

  const onScroll = useCallback(
    event => {
      const target = event.target;
      if (
        (overviewData?.moreItemsExist || paginated) &&
        !overviewSetup.loading &&
        target.scrollHeight - target.scrollTop - target.clientHeight < 10
      ) {
        paginated ? fetchNextPaginatedPage() : fetchNextPage();
      }
    },
    [overviewData?.moreItemsExist, paginated, overviewSetup.loading, fetchNextPaginatedPage, fetchNextPage]
  );

  const debouncedOnScroll = useMemo(() => debounce(onScroll, 500), [onScroll]);

  const onDebouncedOnScrollCallback = useCallback(
    event => {
      if (!dragging) {
        event.persist();
        debouncedOnScroll(event);
      }
      setIsScroll(event.target.scrollTop !== 0);
    },
    [debouncedOnScroll, dragging]
  );

  const onAddCloseAndRemoveShowAdd = useCallback(() => {
    setShowAddEl(<></>);
    onAddClose?.();
  }, [onAddClose]);

  const onAddButtonClick = useCallback(
    async ({ action, placeholder, onBefore, onHandle, onAfter }) => {
      await onBefore?.();

      const addSingleWithValidateItem = action === OVERVIEW_ADD_TYPE.SINGLE && controller.validateItem;

      if (action === OVERVIEW_ADD_TYPE.MULTIPLE || addSingleWithValidateItem) {
        onAddOpen?.();
        setShowAddEl(
          <OverviewAdd
            placeholder={placeholder}
            onAdd={onAdd}
            onClose={onAddCloseAndRemoveShowAdd}
            onValidate={controller.validateItem}
          />
        );
      } else if (action === OVERVIEW_ADD_TYPE.CUSTOM) {
        onHandle?.();
      } else if (action === OVERVIEW_ADD_TYPE.CUSTOM_MULTIPLE) {
        onAddOpen?.();
        setShowAddEl(
          <OverviewAdd
            placeholder={placeholder}
            onAdd={onHandle}
            onClose={onAddCloseAndRemoveShowAdd}
            onValidate={controller.validateItem}
          />
        );
      } else if (action === OVERVIEW_ADD_TYPE.CUSTOM_SINGLE) {
        onHandle?.();
      } else if (action === OVERVIEW_ADD_TYPE.SINGLE && !controller.validateItem) {
        onAddAndGo?.();
      } else if (action === OVERVIEW_ADD_TYPE.SECTION) {
        setShowAddEl(
          <OverviewAdd
            placeholder={t("overview:new_section_placeholder")}
            onAdd={onAddSection}
            onClose={onAddCloseAndRemoveShowAdd}
          />
        );
      } else if (action === OVERVIEW_ADD_TYPE.TEMPLATE) {
        setShowTemplateDialog(true);
      }

      await onAfter?.();
    },
    [controller.validateItem, t, onAddOpen, onAdd, onAddCloseAndRemoveShowAdd, onAddAndGo, onAddSection]
  );

  const onOverviewRowClick = useCallback(
    async (item: OverviewItem) => {
      if (collectionParams?.openInNewTab) {
        navigateTo({
          collection,
          documentId: item.id,
          openInNewTab: true,
          pageId: "",
          questionId: "",
          taskId: ""
        });
      } else {
        if (item && item.id) {
          controller.goToItem(item.id, item);
        }
        onRowClick?.(item);
        if (selectable) {
          dispatch({
            type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
            collection,
            selectedId: item.id
          });
        }
      }
      const unseen = item.unseen === true || item.seen === false;
      if (unseen) {
        addToSeenItemsOfUserHook(collection, item.id).catch(err => {
          console.error(`Failed to set ${collection} with ${item.id} to seen.`, err);
        });
      }
    },
    [
      addToSeenItemsOfUserHook,
      collection,
      collectionParams?.openInNewTab,
      controller,
      dispatch,
      navigateTo,
      selectable,
      onRowClick
    ]
  );

  /* CONTROLLER ACTIONS */

  /* DND */
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  });
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const onDragStart = useCallback((event: DragStartEvent) => {
    setDragItem(event?.active?.data?.current?.item || null);
    setDragging(true);
  }, []);
  const onDragEnd = useCallback(
    async (event: DragEndEvent) => {
      setDragging(false);
      setDragItem(null);
      const draggableItem = event?.active?.data?.current?.item;
      const droppableItem = event?.over?.data?.current?.item;
      if (draggableItem && droppableItem) {
        await controller.onDragEnd?.(draggableItem, droppableItem);
      }
    },
    [controller]
  );

  /* MOUSEOVER / MOUSELEAVE */
  const over = useCallback(
    item => {
      if (!dragging) onRowOver?.(item);
    },
    [dragging, onRowOver]
  );
  const debouncedOver = useMemo(() => debounce(over, 150), [over]);

  const leave = useCallback(() => {
    if (!dragging) {
      onRowLeave?.();
      debouncedOver.cancel();
    }
  }, [debouncedOver, dragging, onRowLeave]);

  /* MOUSEOVER / MOUSELEAVE */
  const checkedItems = useMemo(
    () => checkedIds.map(id => controller.getById(id)).filter(notNull => notNull),
    [checkedIds, controller]
  );

  const onToolbarSelectAllHover = useCallback(
    (hovered: boolean) => {
      if (onSelectAllHover) onSelectAllHover(hovered);
      if (checkedItems.length === 0) setShowRowsSelectCheckbox(hovered);
    },
    [checkedItems.length, onSelectAllHover]
  );

  const numberTotalSelectableItems = useMemo(
    () =>
      isPaginatedApi ? overviewData?.count?.allCount || 0 : getCheckableItemIds(overviewData?.allItems || []).length,
    [isPaginatedApi, overviewData?.allItems, overviewData?.count?.allCount]
  );
  const numberSelectableItems = useMemo(
    () => getCheckableItemIds(overviewData?.items || []).length,
    [overviewData?.items]
  );

  const toolbarEl = (() => {
    if (toolbarMode === "none") return <></>;
    const ToolbarComponent = toolbarMode === "tabs" ? OverviewToolbar : OverviewToolbar;
    const toolbarProps: OverviewToolbarProps = {
      collection: collection,
      pages: pages,
      searchTerm: overviewSetup.search,
      filter: overviewSetup?.filter,
      sort: overviewSetup.sort,
      filters: overviewData?.filters || emptyArrayToAvoidRerender,
      sortings: overviewData?.sortings || emptyArrayToAvoidRerender,
      actions: toolbarActions,
      addActions: addActions,
      selectionActions: selectionActions,
      itemsCount: overviewData?.count,
      hideSearch,
      hideCount,
      checkedItems,
      toolbarHeader,
      toolbarAddControl,
      numberSelectableItems: numberSelectableItems || 0,
      numberTotalItems: overviewData?.count?.allCount || 0,
      numberTotalSelectableItems: numberTotalSelectableItems,
      showSelectAll: !overviewSetup.loading && numberTotalSelectableItems > 0 && showSelectAll,

      onDuplicate: onDuplicateItems,
      onDelete: onDeleteItems,
      onExport: onExportItems,
      onExportAll: onExportAllItems,
      onMarkAllAsRead: onMarkAllAsRead,
      onSelectAllCheckbox: onSelectAllInPagination,
      onSelectAllText: onSelectAll,
      onAdd: onAddButtonClick,
      onSearch: onSearch,
      onSort: onSort,
      onFilter: onFilter,
      onReset: onReset,
      onResetChecked,
      onPageChange,
      onToolbarSelectAllHover
    };
    return (
      <Box pb={toolbarMode === "tabs" ? 0 : 4} pt={isScroll ? 1.5 : 2} pl={4} pr={4}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ToolbarComponent {...toolbarProps} />
      </Box>
    );
  })();

  const getRowProps = (item: OverviewItem) =>
    ({
      bricks,
      activeRow,
      checkable,
      checkedItems: checkedItems,
      collection,
      deletable,
      dragItem,
      dynamicIndentation,
      item,
      isMultilingual,
      searchTerm: overviewSetup.search,
      setActiveRow,
      setItemToMove,
      setOpenMoveModal,
      translationDomainName,
      forceDisplayCheckbox: showRowsSelectCheckbox,

      onAdd,
      onCheckRow,
      onClick: onOverviewRowClick,
      onDelete,
      onDragOverController: controller.onDragOver,
      onMouseEnter: debouncedOver,
      onPatch,
      onValidate: controller.validateItem,
      onCustomRowClick: onRowClick
    }) satisfies OverviewRowProps;

  const listEl = (() => {
    if (overviewSetup.loading) {
      return <></>;
    }
    const itemsEl = (overviewData?.items || []).map((item, index) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <OverviewRow {...getRowProps(item)} key={`${item.id || item.title}-${index}`} />
    ));
    if (dnd) {
      return (
        <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} sensors={sensors}>
          {itemsEl}
        </DndContext>
      );
    }
    return itemsEl;
  })();

  const noEntriesEl =
    !hideNoEntries &&
    overviewData &&
    overviewData.items &&
    overviewData.items.length === 0 &&
    !overviewSetup.loading &&
    (noEntriesCustomEl ? noEntriesCustomEl : <OverviewNoItemsFound />);

  const fetchingEl = !overviewSetup.shadowLoading &&
    (overviewData?.moreItemsExist || overviewSetup.loading || overviewSetup.loadingMore || overviewData === null) && (
      <Box my={4} justifyContent={"center"} display="flex">
        <CircularProgress />
      </Box>
    );

  const endOfListEl = !overviewSetup.loading &&
    !overviewData?.moreItemsExist &&
    overviewData &&
    overviewData?.items?.length > 0 && (
      <Box my={4} justifyContent={"center"} display="flex" className={cls.endOfList}>
        {t("end_of_list")}
      </Box>
    );

  const headerEl = header && (
    <>
      <AccountNotice mt={2} mb={2} mr={2} ml={2} />
      <Box pt={4} px={4} display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h1" className={`${cls.titleText} ${isScroll ? "scrolled" : ""} `}>
            {header}
          </Typography>
        </Box>
      </Box>
    </>
  );

  const childrenEl = children && <Box px={6}>{children}</Box>;
  const subHeaderEl = subHeader && (
    <Box px={4} pt={2}>
      {subHeader}
    </Box>
  );

  const minHeight = useMemo<boolean>(
    () => Boolean(toolbarActions?.some(({ action }) => action === "sort" || action == "filter")),
    [toolbarActions]
  );

  const isListEmpty = useMemo(
    () => !overviewData?.items?.length && !overviewSetup.loading,
    [overviewData?.items?.length, overviewSetup.loading]
  );

  return (
    <Box
      className={`${cls.root} ${dragging ? "dragging" : ""} ${minHeight ? "minHeight" : ""}`.trim()}
      data-testid={`overview-root-${collection}`}
    >
      <Box
        className={`${cls.header} ${isScroll ? "scrolled" : ""}`}
        style={header ? { borderBottom: "1px solid #E0E0E0" } : {}}
      >
        {headerEl}
        {subHeaderEl}
        {toolbarEl}
      </Box>
      {showAddEl}
      <ScrollableBox
        onScroll={onDebouncedOnScrollCallback}
        onMouseLeave={leave}
        data-qa="overview_list"
        data-testid="overview-list"
        className={isListEmpty ? "empty" : ""}
        style={
          !header && overviewData?.items?.length
            ? {
                borderTop: "1px solid rgb(224, 224, 224)"
              }
            : {}
        }
      >
        {listEl}
        {noEntriesEl}
        {fetchingEl}
        {endOfListEl}
        {templateDialogEl}
      </ScrollableBox>
      {childrenEl}
    </Box>
  );
};

Overview.defaultProps = {
  selectionActions: undefined, // if undefined delete will be default,
  addActions: [],
  toolbarMode: "tabs",
  collectionParams: undefined,
  onRowClick: undefined,
  pages: undefined,
  onPageChange: undefined,
  dnd: undefined
};

export default Overview;

const emptyArrayToAvoidRerender: string[] = [];
