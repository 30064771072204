const oneDay = 1000 * 60 * 60 * 24;

export interface OverviewDueDateProps {
  readonly kind: "noDue" | "done" | "overdue" | "today" | "tomorrow" | "days" | "regular";
  readonly days?: number;
  readonly date?: string;
}

export const getDueDateType = (date?: string): OverviewDueDateProps => {
  if (!date) {
    return { kind: "noDue" };
  }

  const dueDate = new Date(date);
  const dueDateMs = Math.floor(dueDate.getTime() / oneDay);
  const currentDateMs = Math.floor(new Date().getTime() / oneDay);
  const diffDays = dueDateMs - currentDateMs;

  if (diffDays < 0) {
    return { kind: "overdue" };
  } else if (diffDays === 0) {
    return { kind: "today" };
  } else if (diffDays === 1) {
    return { kind: "tomorrow" };
  } else if (diffDays <= 5) {
    return { kind: "days", days: diffDays };
  } else {
    return {
      kind: "regular",
      date: dueDate.toLocaleDateString("de-de", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      })
    };
  }
};
