import React, { useCallback, useEffect, useMemo } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useMetaView } from "../../../contexts/meta-view-context";
import DateDisplay from "../../../../components/DateDisplay";
import { AutomaticUserDataDisplay } from "../../../../components/UserDataDisplay";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { businessProcessWritePermissions } from "../../../handlers/permissionHandler";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";
import { OverviewBrickProps } from "components/Overview/controls/OverviewRow";
import { useDocumentName } from "app/contexts/document-name-context";

const bricks = [
  { component: OverviewBrick.Title },
  { component: OverviewBrick.Remove, position: "right" }
] as OverviewBrickProps[];

const toolbarActions = [{ action: "sort" }, { action: "filter" }];
const addActions = [{ action: OVERVIEW_ADD_TYPE.SINGLE }];

const BusinessProcessOverview = () => {
  const { t } = useTranslation();
  const { setInfo, setMeta } = useMetaView();
  const { auth } = useAuthentication();
  const { clearDocumentName } = useDocumentName();
  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("business_process_overview:enteringInfoCardTitle"),
        text: t("business_process_overview:enteringInfoCardText")
      },
      creating: {
        title: t("business_process_overview:creatingInfoCardTitle"),
        text: t("business_process_overview:creatingInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard?.entering);
    clearDocumentName();
  }, [infoCard, setInfo, clearDocumentName]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);
  const onRowOver = useCallback(
    item => {
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updaterUID ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-"
      });
    },
    [setMeta]
  );

  const selectionActions = useMemo(() => [{ action: "remove" }], []);

  const showAddActions = auth?.permissions.find(permission => businessProcessWritePermissions.includes(permission));

  const docViewContent = (
    <Overview
      onAddClose={showEnterInfo}
      onRowOver={onRowOver}
      onRowLeave={showEnterInfo}
      toolbarActions={toolbarActions}
      header={t("business_process_overview:header")}
      collection={COLLECTIONS.BUSINESS_PROCESS}
      bricks={bricks}
      selectionActions={selectionActions}
      addActions={showAddActions ? addActions : undefined}
      checkable={true}
      toolbarMode={"tabs"}
    />
  );
  return (
    <DocMetaView
      docViewContent={docViewContent}
      metaViewContent={
        <MetaView translationKey={"business_process_overview"} collection={COLLECTIONS.BUSINESS_PROCESS} />
      }
    />
  );
};

export default React.memo(BusinessProcessOverview);
