import { IconButton } from "@material-ui/core";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";
import { Delete } from "@mui/icons-material";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "../../../../app/contexts/overview-context";
import { COLLECTIONS } from "../../../../app/collections";

const UserOverviewBrickDelete = ({ item, hovered }: OverviewRowBrickProps) => {
  const dispatch = useOverviewDispatch();
  const onCLickCallback = useCallback(
    async event => {
      stopEvent(event);
      dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.USER, selectedId: item.id });
    },
    [dispatch, item.id]
  );

  if (!hovered || item?.disableActions?.some(it => it.action === "remove")) {
    return <></>;
  }

  return (
    <>
      <IconButton onClick={onCLickCallback} size="small">
        <Delete />
      </IconButton>
    </>
  );
};

export default UserOverviewBrickDelete;
