/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import OverviewConfirmer from "./OverviewConfirmer";
import OverviewInView from "./OverviewInView";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { useOverviewState } from "app/contexts/overview-context";
import { useInView } from "react-intersection-observer";
import { findDescendants } from "../utils/overviewBaseController.traverse";
import { Box } from "@mui/material";
import stopEvent from "tool/stopEvent";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { OverviewItem, OverviewNewItem } from "../controllers/overviewBaseController";
import OverviewAdd from "./OverviewAdd";
import OverviewRenamer from "./OverviewRenamer";
import { useSnackbar } from "notistack";
import colors from "theme/palette/colors";
import { AxiosRequestConfig } from "axios";
import { COLLECTION_TYPES } from "app/collections";

export interface OverviewBrickProps<TOverviewItemExtras extends Record<string, any> = Record<string, any>> {
  readonly component: React.ComponentType<OverviewRowBrickProps<TOverviewItemExtras>>;
  readonly actionId?: string;
  readonly disabled?: boolean;
  readonly visible?: "always" | "hover" | "never";
  readonly position?: "left" | "right";
}

export interface OverviewRowBrickProps<TOverviewItemExtras extends Record<string, any> = Record<string, any>> {
  readonly item: OverviewItem<TOverviewItemExtras>;
  readonly searchTerm?: string;
  readonly hovered?: boolean;
  readonly checked?: boolean;
  readonly checkedItems?: OverviewItem[];
  readonly disabled?: boolean;
  readonly title?: string;

  readonly onDelete?: () => void;
  readonly onAdd?: (input?: { readonly placeholder?: string; readonly prefix?: string }) => void;
  readonly onRename?: () => void;
  readonly setItemToMove?: (item: OverviewItem | null) => void;
  readonly setOpenMoveModal?: (value: boolean) => void;
  readonly onBlur?: () => void;
  readonly onClick?: (id: string, item?: OverviewItem) => void | Promise<void>;
}

export interface OverviewRowProps {
  readonly bricks?: OverviewBrickProps[];
  readonly activeRow: string | null;
  readonly checkable: boolean | undefined;
  readonly deletable?: boolean;
  readonly checkedItems?: OverviewItem[];
  readonly collection: COLLECTION_TYPES;
  readonly dragItem?: OverviewItem | null;
  readonly dynamicIndentation?: boolean;
  readonly item: OverviewItem;
  readonly level?: number;
  readonly isMultilingual?: boolean;
  readonly searchTerm: string;
  readonly setActiveRow: Dispatch<SetStateAction<string | null>>;
  readonly setItemToMove?: (item: OverviewItem | null) => void;
  readonly setOpenMoveModal?: (value: boolean) => void;
  readonly translationDomainName?: string;
  readonly forceDisplayCheckbox?: boolean;

  readonly onAdd: (item: OverviewItem) => void;
  readonly onCheckRow: (val: any, checked: boolean) => void;
  readonly onClick: (item: OverviewItem, event: any) => void;
  readonly onDelete: (id: string) => Promise<void>;
  readonly onDragOverController?: (draggableItem: OverviewItem, droppableItem: OverviewItem) => boolean;
  readonly onMouseEnter?: (val: any) => void;
  readonly onPatch: (id: string, data: any, url?: string, options?: AxiosRequestConfig, item?: OverviewItem) => void;
  readonly onValidate?: (val: any) => string;
  readonly onCustomRowClick?: (item: any) => void;
}

export const OverviewRow = ({
  bricks,
  activeRow,
  checkable,
  checkedItems,
  collection,
  dragItem,
  dynamicIndentation,
  forceDisplayCheckbox,
  isMultilingual,
  item,
  level,
  searchTerm,
  setActiveRow,
  setItemToMove,
  setOpenMoveModal,
  translationDomainName,
  onAdd,
  onCheckRow,
  onClick,
  onDelete,
  onDragOverController,
  onMouseEnter,
  onPatch,
  onValidate
}: OverviewRowProps) => {
  const [t] = useTranslation("overview");
  const { selectedId } = useOverviewState()[collection];
  const { enqueueSnackbar } = useSnackbar();

  const hasChild = item.children !== undefined;
  const hasChildrenSelected = useMemo(() => {
    return findDescendants(item).some(i => checkedItems?.some(({ id }) => id === i.id));
  }, [checkedItems, item]);

  const [hovered, setHovered] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(Boolean(item.expanded || item.preExpanded));
  const [rowMode, setRowMode] = useState<"basic" | "edit" | "remove" | "add" | "done" | "progress" | "reset">("basic");
  const [allowDrop, setAllowDrop] = useState<boolean>(false);
  const [checkedItem, setCheckedItem] = useState(false);

  /* ADD */
  const [addPlaceholder, setAddPlaceholder] = useState<string>(t("new_item_placeholder"));
  const [addPrefix, setAddPrefix] = useState<string>("");

  const isDisableCheckable = item.disableActions?.some(i => i.action === "checkable");
  const isDisableSelectable = item.disableActions?.some(i => i.action === "selectable");
  const isDisableDraggable = item.disableActions?.some(i => i.action === "draggable");

  const { id, title } = item;

  const sx = {
    row: {
      minHeight: "58px",
      padding: `8px 12px 8px ${checkable ? 8 : 24}px`,
      display: "flex",
      alignItems: "center",
      backgroundColor: () => {
        if (rowMode === "edit" || selectedId === item.id) return colors.grey.grey100;
        else if (rowMode === "remove") return colors.red.red50;
        else return colors.white;
      },
      "&:hover": {
        backgroundColor: () => {
          if (rowMode === "edit") return colors.grey.grey100;
          else if (rowMode === "remove") return colors.red.red50;
          else return colors.grey.grey100;
        }
      },
      cursor: isDisableSelectable ? "default" : "pointer",
      borderBottom: `1px solid ${colors.divider}`
    },
    checkbox: {
      width: "36px",
      textAlign: "center",
      marginRight: "4px",
      visibility:
        !isDisableCheckable && rowMode !== "remove" && (hovered || forceDisplayCheckbox || rowMode === "edit")
          ? "visible"
          : "hidden"
    },
    chevron: {
      width: "36px",
      textAlign: "center",
      marginRight: "4px",
      visibility: !item.preExpanded && item.children?.length ? "visible" : "hidden"
    }
  };

  // to handle auto expand during search
  useEffect(() => {
    if (item.expanded || item.preExpanded) {
      setOpen(true);
    }
  }, [item.expanded, item.preExpanded]);

  useEffect(() => {
    return setCheckedItem(Boolean(checkedItems?.some(({ id }) => id === item.id)));
  }, [checkedItems, item.id]);

  useEffect(() => {
    if (activeRow !== item.id) {
      setRowMode("basic");
    }
  }, [activeRow, item.id]);

  /* DRAGGING */
  const {
    attributes,
    listeners,
    transform,
    setNodeRef: setNodeRefDraggable
  } = useDraggable({
    id: `draggable-${item.id}`,
    disabled: isDisableDraggable || rowMode !== "basic",
    data: {
      item
    }
  });

  const dragStyle: CSSProperties | undefined = useMemo(
    () =>
      transform
        ? {
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            background: "#fff",
            zIndex: 999,
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            position: "relative",
            width: "75%"
          }
        : undefined,
    [transform]
  );

  const {
    isOver,
    over,
    setNodeRef: setNoderefDroppable
  } = useDroppable({
    id: `droppable-${item.id}`,
    data: {
      item
    }
  });

  useEffect(() => {
    if (isOver && dragItem && over && onDragOverController) {
      const draggableItem = dragItem;
      const droppableItem = over.data?.current?.item;
      if (draggableItem && droppableItem) {
        const allow = onDragOverController(draggableItem, droppableItem);
        setAllowDrop(allow);
      }
    } else {
      setAllowDrop(false);
    }
  }, [dragItem, isOver, onDragOverController, over]);

  /* MOUSE ENTER/LEAVE */
  const onMouseEnterItemCallback = useCallback(() => {
    onMouseEnter?.(item);
    setHovered(true);
  }, [item, onMouseEnter]);
  const onMouseLeaveItemCallback = useCallback(() => {
    setHovered(false);
  }, []);

  /* RESET ROW MODE */
  const resetRowMode = useCallback(() => {
    setRowMode("basic");
    setActiveRow(null);
    setAddPlaceholder(t("new_item_placeholder"));
    setAddPrefix("");
  }, [setActiveRow, t]);

  /* ROW CLICK */
  const onRowClick = useCallback(
    event => {
      if (rowMode === "basic" && !item.preExpanded) {
        if (hasChild) {
          setOpen(current => !current);
        }
        if (!isDisableSelectable) {
          onClick(item, event);
        }
      }
    },
    [rowMode, hasChild, isDisableSelectable, onClick, item]
  );

  /* ADD ACTIONS */
  const onOpenAdd = useCallback((input?: { readonly placeholder?: string; readonly prefix?: string }) => {
    if (input?.placeholder) {
      setAddPlaceholder(input.placeholder);
    }

    if (input?.prefix) {
      setAddPrefix(input.prefix);
    }
    setRowMode("add");
    setOpen(true);
  }, []);

  const onAddCallback = useCallback(
    (data: OverviewNewItem) => {
      onAdd({
        id: "",
        title: addPrefix ? `${addPrefix}${data.title || ""}` : data.title || "",
        subTitle: "",
        parentId: id
      });
      resetRowMode();
    },
    [addPrefix, id, onAdd, resetRowMode]
  );

  const addItemEl = rowMode === "add" && (
    <OverviewAdd level={level || 0} placeholder={addPlaceholder} onAdd={onAddCallback} onClose={resetRowMode} />
  );

  /* DELETE ACTION */
  const onOpenRemove = useCallback(() => {
    setRowMode("remove");
    setActiveRow(item.id);
  }, [item.id, setActiveRow]);

  const onDeleteClick = useCallback(async () => {
    resetRowMode();
    onDelete(id || item.path);
  }, [id, item.path, resetRowMode, onDelete]);

  const removeEl = rowMode === "remove" && (
    <OverviewConfirmer
      title={t(`overview:delete_confirmation_${collection.toLowerCase()}`, t(`overview:delete_confirmation`))}
      onConfirm={onDeleteClick}
      onCancel={resetRowMode}
    />
  );

  /* RENAME ACTION */
  const onOpenRename = useCallback(() => {
    setRowMode("edit");
    setActiveRow(item.id);
  }, [item.id, setActiveRow]);

  const onCloseRename = useCallback(() => {
    setRowMode("basic");
    setActiveRow(null);
  }, [setActiveRow]);

  const onRenameConfirm = useCallback(
    async (newTitle: string) => {
      const data = { title: newTitle };
      const validationErrorText = onValidate?.(data);
      if (validationErrorText) {
        enqueueSnackbar(validationErrorText, { variant: "error" });
        resetRowMode();
        return;
      }
      onCloseRename();
      onPatch(id, data, undefined, undefined, item);
    },
    [enqueueSnackbar, id, item, onCloseRename, onPatch, onValidate, resetRowMode]
  );

  const editEl = rowMode === "edit" && (
    <OverviewRenamer title={item.editableTitle ?? title} onCancel={onCloseRename} onSave={onRenameConfirm} />
  );

  /* CHECKBOX */
  const onChecked = useCallback(
    event => {
      if (event.target.checked) {
        setCheckedItem(event.target.checked);
      }
      onCheckRow(item, event.target.checked);
    },
    [item, onCheckRow]
  );
  const checkBoxEl = checkable && (
    <Box sx={sx.checkbox} onClick={stopEvent} data-testid={`overview-row-checkbox`}>
      <Checkbox
        disabled={rowMode !== "basic"}
        checked={checkedItem}
        indeterminate={!checkedItem && hasChildrenSelected}
        onChange={onChecked}
        color="primary"
        size="small"
      />
    </Box>
  );

  /* CHEVRON */
  const onChevronClickCallback = useCallback(
    event => {
      stopEvent(event);
      if (hasChild && !item.preExpanded && rowMode === "basic") {
        setOpen(current => !current);
      }
    },
    [hasChild, item.preExpanded, rowMode]
  );
  const chevronEl = hasChild && (
    <Box
      // className={`rowChevron ${chevronVisibile ? "" : "hidden"} ${chevronEnable ? "" : "disabled"}`}
      sx={sx.chevron}
      onClick={onChevronClickCallback}
      data-testid={`overview-row-chevron`}
    >
      <IconButton disabled={rowMode !== "basic"} size="small">
        {open ? <ExpandMore /> : <ChevronRight />}
      </IconButton>
    </Box>
  );

  /* CHILD */
  const childEl = hasChild && open && item.children?.length !== 0 && (
    <Box>
      {item.children?.map(i => (
        <OverviewInView key={`${i.id || i.title}-${level || 0}`}>
          <OverviewRow
            bricks={bricks}
            activeRow={activeRow}
            checkable={checkable}
            checkedItems={checkedItems}
            collection={collection}
            dragItem={dragItem}
            dynamicIndentation={dynamicIndentation}
            item={i}
            level={(level || 0) + 1}
            isMultilingual={isMultilingual}
            searchTerm={searchTerm}
            setActiveRow={setActiveRow}
            setItemToMove={setItemToMove}
            setOpenMoveModal={setOpenMoveModal}
            translationDomainName={translationDomainName}
            onAdd={onAdd}
            onCheckRow={onCheckRow}
            onClick={onClick}
            onDelete={onDelete}
            onDragOverController={onDragOverController}
            onMouseEnter={onMouseEnter}
            onPatch={onPatch}
            onValidate={onValidate}
            forceDisplayCheckbox={forceDisplayCheckbox}
          />
        </OverviewInView>
      ))}
    </Box>
  );

  /* BRICKS */
  const bricksEl = useMemo(() => {
    const props: OverviewRowBrickProps = {
      item,
      searchTerm,
      hovered,
      checked: checkedItem,
      checkedItems: checkedItems,
      setItemToMove,
      setOpenMoveModal,
      onDelete: onOpenRemove,
      onAdd: onOpenAdd,
      onRename: onOpenRename,
      onBlur: onMouseLeaveItemCallback
    };

    const buildBrick = (brick: OverviewBrickProps, index: number) => (
      <React.Fragment key={`${item.id}-${index}`}>{React.createElement(brick.component, props)}</React.Fragment>
    );

    if (rowMode === "basic" || rowMode === "add") {
      return (
        <Box sx={{ display: "flex", width: "100%", overflow: "hidden" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", overflow: "hidden", gap: "12px", minWidth: "0px", flex: 1 }}
          >
            {bricks
              ?.filter(brick => brick.visible !== "never")
              .filter(brick => !brick.position || brick.position === "left")
              .map(buildBrick)}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", overflow: "hidden", minWidth: "0px", marginLeft: "4px" }}>
            {bricks
              ?.filter(brick => brick.visible !== "never")
              .filter(({ position }) => position === "right")
              .map(buildBrick)}
          </Box>
        </Box>
      );
    }
    return <></>;
  }, [
    bricks,
    checkedItem,
    checkedItems,
    hovered,
    item,
    onMouseLeaveItemCallback,
    onOpenAdd,
    onOpenRemove,
    onOpenRename,
    rowMode,
    searchTerm,
    setItemToMove,
    setOpenMoveModal
  ]);

  /* ROW */
  const [ref, inView] = useInView({ triggerOnce: true });
  const minHeight: CSSProperties = useMemo<CSSProperties>(
    () => ({
      minHeight: 58
    }),
    []
  );
  const maxLevelPadding = 10;
  const paddingLeft = (level && level > maxLevelPadding ? maxLevelPadding : level || 0) * 24 + 8;
  return (
    <div style={minHeight} ref={ref} data-testid={`overview-row`} key={item.id + item.title}>
      <div ref={hasChild ? setNoderefDroppable : undefined}>
        <div ref={setNodeRefDraggable} {...listeners} {...attributes} style={dragStyle}>
          {inView ? (
            <Box
              sx={{ ...sx.row, paddingLeft: `${paddingLeft}px` }}
              onClick={onRowClick}
              onMouseEnter={onMouseEnterItemCallback}
              onMouseLeave={onMouseLeaveItemCallback}
            >
              {checkBoxEl}
              {chevronEl}
              {bricksEl}
              {editEl}
              {removeEl}
            </Box>
          ) : null}
        </div>
      </div>
      {addItemEl}
      {childEl}
    </div>
  );
};
