import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid, Switch } from "@material-ui/core";
import TextBody2 from "../../../../components/TextBody2/TextBody2";
import { useTranslation } from "react-i18next";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import AccordionMultiField from "../../../../components/AccordionMultiField/AccordionMultiField";
import { OccurrenceAssessment } from "./RiskAssessment";
import { RiskAssessmentAutoSave } from "./RiskAssessmentAutoSave";
import { RiskRatingAlert } from "./RiskRating";
import CardWithTextButton from "../../../../components/CardWithButton/CardWithTextButton";
import { useNavigate } from "react-router-dom";
import { useRisk } from "../../../contexts/risk-context";
import { RiskHeatMap } from "./RiskHeatMap";
import PropTypes from "prop-types";
import { getAssessmentWithHighestRating } from "../../../handlers/risksHandler";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { useResources } from "../../../contexts/resource-context";

export const RiskPhaseAssessment = ({
  phaseId,
  enableIndividualAssessment,
  children
}: {
  readonly phaseId: "first" | "second";
  readonly enableIndividualAssessment: boolean;
  readonly children?: React.ReactNode;
}) => {
  const { t } = useTranslation("risk_assessment");
  const navigate = useNavigate();

  const { initialized, risk, toggleIndividualAssessmentHook, updateAllIndividualAssessmentsOccurrence, riskId } =
    useRisk();
  const { resourcesLoaded, translateById } = useResources();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [individualAssessment, setIndividualAssessment] = useState(false);

  useEffect(() => {
    const phaseAssessment = risk.assessments?.[phaseId] || null;
    if (!phaseAssessment) {
      return;
    }

    const individualAssessment = phaseAssessment.individualAssessmentEnabled;
    setIndividualAssessment(individualAssessment);
  }, [risk, phaseId]);

  const protectionObjectiveName = useCallback(
    protectionObjectiveId => {
      return translateById(RESOURCE_TYPES.PROTECTION_OBJECTIVE, protectionObjectiveId);
    },
    [translateById]
  );
  const [isChangingAllOccurrenceIds, setIsChangingAllOccurrenceIds] = useState(false);
  const changeAllOccurrenceIds = useCallback(
    async occurrenceId => {
      setIsChangingAllOccurrenceIds(true);
      try {
        await updateAllIndividualAssessmentsOccurrence(phaseId, { occurrenceId });
        setIsChangingAllOccurrenceIds(false);
      } catch (error) {
        setIsChangingAllOccurrenceIds(false);
        throw error;
      }
    },
    [phaseId, updateAllIndividualAssessmentsOccurrence]
  );
  const changeAllReasonDescriptions = useCallback(
    async reasonDescription => {
      await updateAllIndividualAssessmentsOccurrence(phaseId, { reasonDescription });
    },
    [phaseId, updateAllIndividualAssessmentsOccurrence]
  );

  const [isTogglingIndividualAssessment, setIsTogglingIndividualAssessment] = useState(false);
  const changeIndividualAssessment = useCallback(
    async event => {
      const enabled = event.target.checked;

      setIsTogglingIndividualAssessment(true);

      setIndividualAssessment(enabled);

      try {
        await toggleIndividualAssessmentHook(phaseId, enabled).catch(
          catchAsSnackbar(`failed to update individual assessment setting for ${phaseId}`)
        );
        setIsTogglingIndividualAssessment(false);
      } catch (error) {
        setIsTogglingIndividualAssessment(false);
        throw error;
      }
    },
    [toggleIndividualAssessmentHook, catchAsSnackbar, phaseId]
  );

  const navigateToGeneral = useCallback(() => {
    navigate(`/risks/${riskId}/general`);
  }, [navigate, riskId]);

  const getHighestRatingAssessment = useCallback(() => {
    return getAssessmentWithHighestRating(risk, phaseId);
  }, [phaseId, risk]);

  const disabled = useMemo(() => risk?.permission !== "write", [risk?.permission]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {enableIndividualAssessment && (
          <>
            <TextBody2 text={t("individualAssessment")} />
            {initialized && (
              <Switch
                data-testid="individualAssessmentSwitch"
                checked={individualAssessment}
                color="primary"
                onChange={changeIndividualAssessment}
                disabled={disabled}
              />
            )}
          </>
        )}
      </Grid>
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
      {isTogglingIndividualAssessment && (
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
      {initialized && individualAssessment === false && !isTogglingIndividualAssessment && (
        <Grid item xs={12}>
          <RiskAssessmentAutoSave phaseId={phaseId} protectionObjectiveId="main" disabled={disabled} />
        </Grid>
      )}
      {initialized && resourcesLoaded && individualAssessment === true && !isTogglingIndividualAssessment && (
        <>
          <Grid item xs={12}>
            <OccurrenceAssessment
              occurrenceId={
                risk.assessments[phaseId]?.individualAssessments
                  ?.map(assessment => assessment.occurrenceId)
                  .find(occurrenceId => occurrenceId) || null
              }
              onOccurrenceIdChange={changeAllOccurrenceIds}
              defaultReasonDescription={
                risk.assessments[phaseId]?.individualAssessments
                  ?.map(assessment => assessment.reasonDescription)
                  .find(reasonDescription => reasonDescription) || null
              }
              onReasonDescriptionChange={changeAllReasonDescriptions}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            {risk.protectionObjectiveIds.length === 0 && (
              <CardWithTextButton
                text={t("specifyProtectionObjective")}
                buttonText={t("goToPage1")}
                colorTheme={"blue"}
                onClick={navigateToGeneral}
              />
            )}
            {risk.protectionObjectiveIds.length > 0 &&
              risk.protectionObjectiveIds.map(protectionObjectiveId => (
                <AccordionMultiField
                  id={`accordion-${protectionObjectiveId}`}
                  key={protectionObjectiveId}
                  hasCancelAndSave={false}
                  hasDelete={false}
                  accordionsExpanded={true}
                  title={protectionObjectiveName(protectionObjectiveId)}
                  placeholder={undefined}
                  index={undefined}
                  field={undefined}
                  isNewMultiFiled={undefined}
                  deleteMultiField={undefined}
                  deleteButtonText={undefined}
                  deleteButtonHint={undefined}
                  cancelButtonText={undefined}
                  saveButtonText={undefined}
                  disableButton={undefined}
                  onClickSave={undefined}
                  onClickCancel={undefined}
                  onFocus={undefined}
                  setAccordionsExpanded={undefined}
                  editButtonText={undefined}
                  onClickEdit={undefined}
                  additionalLeftButton={undefined}
                  loading={undefined}
                >
                  {isChangingAllOccurrenceIds && (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  )}
                  {!isChangingAllOccurrenceIds && (
                    <RiskAssessmentAutoSave
                      phaseId={phaseId}
                      protectionObjectiveId={protectionObjectiveId}
                      disabled={disabled}
                    />
                  )}
                </AccordionMultiField>
              ))}
            <Grid direction={"row"} container style={{ marginTop: 8 }} justifyContent={"center"}>
              <RiskRatingAlert
                riskRating={risk.assessments[phaseId].rating}
                riskRatingIsLoading={isChangingAllOccurrenceIds}
              />
              <RiskHeatMap
                damageExtendId={getHighestRatingAssessment()?.damageExtendId}
                occurrenceId={getHighestRatingAssessment()?.occurrenceId}
                headerToRender={undefined}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

RiskPhaseAssessment.propTypes = {
  enableIndividualAssessment: PropTypes.bool,
  phaseId: PropTypes.string
};

RiskPhaseAssessment.defaultProps = {
  enableIndividualAssessment: true
};
