interface Storage {
  save(key: string, input: string): Promise<void>;
  get(key: string): Promise<string>;
  clearAll(): Promise<void>;
}

const paOverviewStorageNamespace = "caralegal::paoverview::";

const sessionStorageEngine: Storage = {
  async get(key: string): Promise<string> {
    const item = window.sessionStorage.getItem(`${paOverviewStorageNamespace}${key}`);
    return item || "";
  },
  async save(key: string, input: string): Promise<void> {
    window.sessionStorage.setItem(`${paOverviewStorageNamespace}${key}`, input);
  },
  async clearAll(): Promise<void> {
    window.sessionStorage.removeItem(`${paOverviewStorageNamespace}redirect`);
  }
};

export const getLastOverviewType = async (): Promise<string> => {
  return await sessionStorageEngine.get("overviewType");
};

export const storeLastOverviewType = async (type: string) => {
  await sessionStorageEngine.save("overviewType", type);
};

export const clearRedirectFullPathSession = sessionStorageEngine.clearAll;
