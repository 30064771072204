import { useTranslation } from "react-i18next";
import GeneralPageStepper from "../../../components/Pagination/GeneralPageStepper";
import React, { useEffect, useState } from "react";
import GeneralPageButtonPagination from "../../../components/Pagination/GeneralPageButtonPagination";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";

function usePaginationSteps() {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<
    {
      label: string;
      path: string;
    }[]
  >([]);
  const isAssetFeatureEnabled = useIsFeaturePresent(FEATURES.ASSETS);
  useEffect(() => {
    setSteps([
      {
        label: t("pagination:general"),
        path: "general"
      },
      {
        label: t("pagination:processes_page"),
        path: "risk-processes"
      },
      {
        label: t("pagination:risk_assessments_first"),
        path: "initial-assessment"
      },
      {
        label: t("pagination:risk_treatment"),
        path: "treatment"
      },
      {
        label: t("pagination:risk_assessments_second"),
        path: "treatment-assessment"
      },
      ...(isAssetFeatureEnabled ? [{ label: t("pagination:assets"), path: "assets" }] : [])
    ]);
  }, [isAssetFeatureEnabled, t]);

  return {
    steps
  };
}

export function RiskPageStepper() {
  const { steps } = usePaginationSteps();

  return <GeneralPageStepper steps={steps} />;
}

export function RiskPageButtons({ leftArea }: { readonly leftArea?: React.ReactNode }) {
  const { steps } = usePaginationSteps();

  return <GeneralPageButtonPagination steps={steps} leftArea={leftArea} />;
}
