import { Box, Chip, Tooltip } from "@mui/material";
import colors from "theme/palette/colors";

const sx = {
  root: {
    backHand: colors.grey.grey200,
    color: colors.grey.grey500,
    fontSize: "12px",
    height: "24px",
    border: "none",
    borderRadius: "20px",
    "&:hover": {
      color: "inherited",
      backgroundColor: "inherited"
    }
  },
  hovered: {
    grey: {
      color: colors.grey.grey500,
      backgroundColor: colors.grey.grey300
    },
    blue: {
      color: colors.blue.blue600,
      backgroundColor: colors.blue.blue200
    },
    green: {
      color: colors.green.green700,
      backgroundColor: colors.green.green100
    },
    orange: {
      color: colors.orange.orange800,
      backgroundColor: colors.yellow.yellow200
    },
    pink: {
      color: colors.red.red600,
      backgroundColor: colors.red.red100
    },
    red: {
      color: colors.white,
      backgroundColor: colors.red.red400
    },
    black: {
      color: colors.white,
      backgroundColor: colors.grey.grey900
    }
  },
  grey: {
    color: colors.grey.grey500,
    backgroundColor: colors.grey.grey200
  },
  blue: {
    color: colors.blue.blue600,
    backgroundColor: colors.blue.blue50
  },
  green: {
    color: colors.green.green700,
    backgroundColor: colors.green.green50
  },
  orange: {
    color: colors.orange.orange800,
    backgroundColor: colors.yellow.yellow100
  },
  pink: {
    color: colors.red.red600,
    backgroundColor: colors.red.red50
  },
  red: {
    color: colors.white,
    backgroundColor: colors.red.red300
  },
  black: {
    color: colors.white,
    backgroundColor: colors.grey.grey900
  }
};

export type OverviewBrickColorChipColors = "grey" | "blue" | "green" | "orange" | "pink" | "red" | "black";

interface OverviewBrickColorChipProps {
  readonly color: OverviewBrickColorChipColors;
  readonly label: string;
  readonly tooltip?: string;
  readonly hovered?: boolean;

  readonly onClick?: (event: any) => void;
}

const OverviewBrickColorChip = ({ color, label, tooltip, hovered, onClick }: OverviewBrickColorChipProps) => {
  const chipSx = { ...sx.root, ...(hovered ? sx.hovered[color] : sx[color]) };

  const chipEl = (
    <Box onClick={onClick}>
      <Chip sx={chipSx} label={label} variant="outlined" />
    </Box>
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{chipEl}</Tooltip>;
  }
  return chipEl;
};

export default OverviewBrickColorChip;
