import { Chip, ChipProps, Tooltip } from "@mui/material";
import React, { useCallback } from "react";

export const useNonDeletableChipRenderTags = ({
  getLabel,
  isNotDeletable,
  getTooltip,
  getNonDeletableTooltip,
  disabled,
  onClick
}: {
  readonly getLabel: (optionId: string) => string;
  readonly isNotDeletable?: (optionId: string) => boolean;
  readonly getTooltip?: (optionId: string) => string;
  readonly getNonDeletableTooltip?: (optionId: string) => string;
  readonly disabled?: boolean;
  readonly onClick?: (optionId: string) => void;
}) => {
  return useCallback(
    (selectedOptionIds, getDefaultTagProps) =>
      selectedOptionIds.map((optionId: string, index: number) => {
        const notDeletable = isNotDeletable?.(optionId);
        const label = getLabel(optionId);
        const toolTip = getTooltip?.(optionId) || "";
        const noDeleteTooltip = getNonDeletableTooltip?.(optionId) || "";
        const defaultProps = getDefaultTagProps({ index });

        return (
          <NonDeletableChip
            key={index}
            noDelete={notDeletable === true}
            label={label}
            tooltip={toolTip}
            noDeleteTooltip={noDeleteTooltip}
            optionId={optionId}
            onChipClick={onClick}
            disabled={disabled}
            {...defaultProps}
          />
        );
      }),
    [isNotDeletable, getLabel, getTooltip, getNonDeletableTooltip, onClick, disabled]
  );
};

const NonDeletableChip = ({
  children,
  noDelete,
  label,
  tooltip,
  noDeleteTooltip,
  optionId,
  onChipClick,
  onDelete,
  disabled,
  ...otherPropsRest
}: NonDeletableChipProps) => {
  const onClickCallback = useCallback(() => {
    onChipClick?.(optionId);
  }, [onChipClick, optionId]);

  const chipComponent = (
    <Chip
      {...otherPropsRest}
      disabled={disabled}
      label={label}
      onDelete={noDelete ? undefined : onDelete}
      onClick={onClickCallback}
    />
  );

  const toolTipText = noDelete === true ? noDeleteTooltip || tooltip : tooltip;

  if (!toolTipText) {
    return chipComponent;
  }
  return (
    <Tooltip
      title={
        <span style={{ whiteSpace: "pre-wrap" }} onClick={onClickCallback}>
          {toolTipText}
        </span>
      }
    >
      {chipComponent}
    </Tooltip>
  );
};

export interface NonDeletableChipProps extends ChipProps {
  readonly noDelete?: boolean;
  readonly label: string;
  readonly tooltip?: string;
  readonly noDeleteTooltip?: string;
  readonly optionId: string;
  readonly onChipClick: (optionId: string) => void;
}

NonDeletableChip.defaultProps = {
  noDelete: false,
  tooltip: "",
  noDeleteTooltip: ""
};
