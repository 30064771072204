import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import { ProcessesOverviewReadOnly } from "../../processes/overview/ProcessesOverviewReadOnly";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { Box, Button } from "@mui/material";
import ProcessesPickerModal from "components/Processes/ProcessesPickerModal";
import { linkToPAs, unlinkToPAs, useAssetPasOptions } from "app/api/assetApi";
import { useUserProcesses } from "hook/useUserProcesses";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { useSnackbar } from "notistack";

interface AssetsProcessesOverviewProps {
  readonly assetId: string;
  readonly readonly?: boolean;
}

const AssetsProcessesOverview = ({ assetId, readonly }: AssetsProcessesOverviewProps) => {
  const { t } = useTranslation("");
  const { setInfo, setMeta } = useMetaView();
  const { enqueueSnackbar } = useSnackbar();

  const [hoveredPaId, setHoveredPaId] = useState<string | null>(null);
  const [openPaPickerModal, setOpenPaPickerModal] = useState<boolean>(false);
  const [renderId, setRenderId] = useState<string>("");
  const [unlinkProcessId, setUnlinkProcessId] = useState<string | null>(null);

  const filter = useMemo(() => ({ assetIDs: [assetId] }), [assetId]);

  // getting linked PAs to current asset
  const {
    processes,
    processesLoaded,
    isValidating,
    mutate: mutateLinkedProcesses
  } = useUserProcesses({ assetIDs: [assetId] });

  // getting available and selectable PAs
  const {
    data,
    isLoading,
    mutate: mutateAssetPasOptions
  } = useAssetPasOptions({ documentId: !readonly && assetId ? assetId : "" });

  // all available PAs for user (all status, all permissions)
  const optionsPas = useMemo(() => {
    if (data && !isLoading) {
      return data.pas || [];
    }
    return [];
  }, [data, isLoading]);

  // only selectable PAs for user (status: review or edit, permission: write)
  const selectableIds = useMemo(() => {
    if (data && !isLoading) {
      return data.pas
        .filter(({ status, permission }) => permission === "write" && (status === "edit" || status === "review"))
        .map(({ id }) => id);
    }
    return [];
  }, [data, isLoading]);

  const selectedPaIds = useMemo<string[]>(() => {
    if (!processesLoaded || isValidating) return [];
    else return (processes || []).map(({ id }) => id);
  }, [isValidating, processes, processesLoaded]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("asset_details:enteringInfoCardTitle"),
        text: t("asset_details:enteringInfoCardTextProcessView")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const updateAll = useCallback(async () => {
    setRenderId(Date.now().toString());
    await mutateAssetPasOptions();
    await mutateLinkedProcesses();
  }, [mutateAssetPasOptions, mutateLinkedProcesses]);

  const handleRowOver = useCallback(
    item => {
      if (hoveredPaId === item.id) {
        return;
      }
      setHoveredPaId(item.id);
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} separator={undefined} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("processes_overview:status_" + item.status)
      });
    },
    [hoveredPaId, setMeta, t]
  );

  const onOpenUnlinkConfirmation = useCallback((id: string) => {
    setUnlinkProcessId(id);
  }, []);
  const onCloseUnlinkConfirmation = useCallback(() => {
    setUnlinkProcessId(null);
  }, []);
  const onOpenPaPickerModal = useCallback(() => {
    setOpenPaPickerModal(true);
  }, []);
  const onClosePaPickerModal = useCallback(() => {
    setOpenPaPickerModal(false);
  }, []);
  const onConfirmPaPickerModal = useCallback(
    async (paIds: string[]) => {
      const idsToUnlink = selectedPaIds.filter(id => !paIds.includes(id));
      const idsToLink = paIds.filter(id => !selectedPaIds.includes(id));

      try {
        if (idsToLink.length) {
          await linkToPAs({ assetId, paIds: idsToLink });
        }
        if (idsToUnlink.length) {
          await unlinkToPAs({ assetId, paIds: idsToUnlink });
        }
      } catch (e) {
        enqueueSnackbar("Error link/unlink Processing Activities", { variant: "error" });
      } finally {
        await updateAll();
        setOpenPaPickerModal(false);
      }
    },
    [assetId, enqueueSnackbar, selectedPaIds, updateAll]
  );
  const onAddPaPickerModal = useCallback(
    async (id: string) => {
      await linkToPAs({ assetId, paIds: [id] });
      await updateAll();
      setOpenPaPickerModal(false);
    },
    [assetId, updateAll]
  );
  const onUnlinkCallback = useCallback(
    (id: string) => {
      onOpenUnlinkConfirmation(id);
    },
    [onOpenUnlinkConfirmation]
  );
  const onUnlinkSingleProcess = useCallback(async () => {
    if (unlinkProcessId) {
      setUnlinkProcessId(null);
      await unlinkToPAs({ assetId, paIds: [unlinkProcessId] });
      await updateAll();
    }
  }, [assetId, unlinkProcessId, updateAll]);

  const unlinkConfirmationDialogEl = useMemo(
    () => (
      <ConfirmationModal
        variant="info"
        modalOpen={Boolean(unlinkProcessId)}
        onClose={onCloseUnlinkConfirmation}
        modalTitle={t("common:information")}
        modalText={t("processPickerModal:unlinkProcessConfirmation")}
        buttons={[
          {
            title: t("common:cancel"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: onCloseUnlinkConfirmation
          },
          {
            confirmButton: true,
            title: t("common:confirm"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: onUnlinkSingleProcess
          }
        ]}
      />
    ),
    [onCloseUnlinkConfirmation, onUnlinkSingleProcess, t, unlinkProcessId]
  );

  const linkButtonEl = useMemo(() => {
    if (readonly) {
      return <></>;
    }

    return (
      <Button variant="contained" onClick={onOpenPaPickerModal} disabled={Boolean(!optionsPas?.length)}>
        {t("processPickerModal:linkToProcess")}
      </Button>
    );
  }, [onOpenPaPickerModal, optionsPas?.length, readonly, t]);

  return (
    <Box mb={2}>
      <Box>
        {linkButtonEl}
        <ProcessesOverviewReadOnly
          onUnlink={!readonly ? onUnlinkCallback : undefined}
          filter={filter}
          renderId={renderId}
          onRowOver={handleRowOver}
        />
      </Box>
      {openPaPickerModal && (
        <ProcessesPickerModal
          open={openPaPickerModal}
          selectedIds={selectedPaIds}
          selectableIds={selectableIds}
          options={optionsPas}
          allowAdd={true}
          onCancel={onClosePaPickerModal}
          onConfirm={onConfirmPaPickerModal}
          onAdd={onAddPaPickerModal}
        />
      )}
      {unlinkConfirmationDialogEl}
    </Box>
  );
};

export default AssetsProcessesOverview;
