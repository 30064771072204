import { IconButton } from "@material-ui/core";
import { Add } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { DataAssetType } from "app/api/generated/asset-service";
import { FEATURES } from "app/features";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { t } from "i18next";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";

const tooltipMap: Record<DataAssetType, string> = {
  [DataAssetType.PersonGroup]: "dataCategory:addButtonTooltip",
  [DataAssetType.DataCategory]: "dataType:addButtonTooltip",
  [DataAssetType.DataType]: ""
};

const placeholderMap: Record<DataAssetType, string> = {
  [DataAssetType.PersonGroup]: "dataCategory:addButtonTooltip",
  [DataAssetType.DataCategory]: "dataType:addButtonTooltip",
  [DataAssetType.DataType]: ""
};

const PersonGroupOverviewBrickAdd = ({ item, hovered, onAdd, onClick, onBlur }: OverviewRowBrickProps) => {
  const isMultilingualEnabled = !!useIsFeaturePresent(FEATURES.MULTILINGUAL);

  const onClickCallback = useCallback(
    event => {
      onBlur?.();
      stopEvent(event);
      if (isMultilingualEnabled) {
        onClick?.(item.id, item);
      } else {
        onAdd?.({ placeholder: t(placeholderMap[item.assetType as DataAssetType]) });
      }
    },
    [isMultilingualEnabled, item, onAdd, onBlur, onClick]
  );

  if (!hovered || item.disableActions?.some(({ action }) => action === "add")) {
    return <></>;
  }

  return (
    <Tooltip title={t(tooltipMap[item.assetType as DataAssetType])}>
      <IconButton onClick={onClickCallback} size="small">
        <Add />
      </IconButton>
    </Tooltip>
  );
};

export default PersonGroupOverviewBrickAdd;
