import { RISK_LEVEL, RISK_TREATMENT_TYPES } from "app/handlers/risksHandler";
import { DONE_INTERVAL, PRIORITIES as TASK_PRIORITIES } from "app/handlers/tasksHandler";
import { RESOURCE_TYPE, RESOURCE_TYPES, translateResource } from "../../../handlers/resourceHandler";
import { ASSET_STATUS, ASSET_TYPE } from "app/pages/assets/types/AssetTypes";
import SERVICE_TYPES_KEYS from "../../service-providers/utils/serviceTypes";
import i18n from "../../../i18n";
import { getUserName } from "../../../utils/get-user-name";
import { USER_FEATURE_IDS } from "app/features";
import { COLLECTIONS } from "app/collections";
import { DataAssetType, PersonGroup } from "app/api/generated/asset-service";
import { AITechDocDTOStatusEnum } from "../../../api/generated/aitechdoc-service";
import { TFunction } from "i18next";
import { UserDTO } from "../../../api/user/userApi";
import { ResourceDTO } from "../../../api/resourceApi";
import { OverviewResult } from "../../../../components/Overview/controllers/overviewBaseController";

export const getProcessStatusFilter = (filterField = "status", t: TFunction) => {
  const statuses = ["edit", "review", "approved"];
  return {
    filterTree: [
      {
        checkable: false,
        name: t("processes_overview:status"),
        children: statuses.map(s => {
          return {
            name: t(`processes_overview:status_${s}`),
            id: s
          };
        })
      }
    ],
    filterField
  };
};

export const getProcessTaskFilter = (filterField = "openTasks", t: TFunction) => {
  const taskTree = [
    {
      name: t("filter_criteria:open_tasks"),
      id: "openTasks"
    },
    {
      name: t("filter_criteria:my_tasks"),
      id: "openUserTasks"
    },
    {
      name: t("filter_criteria:no_tasks"),
      id: "noOpenTasks"
    }
  ];
  return {
    filterTree: [
      {
        checkable: false,
        name: t("filter_criteria:open_tasks"),
        children: taskTree
      }
    ],
    filterField
  };
};

export const getResponsibleExpertFilter = (
  filterField = "responsibleExpertId",
  responsibleExpertIds: string[],
  tenantUsersMap: Map<string, Pick<UserDTO, "id" | "email" | "firstName" | "lastName">>,
  t: TFunction
) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("tasks_page:responsibleExpert"),
        children: responsibleExpertIds
          .map(responsibleExpertId => {
            return getUserName(tenantUsersMap, responsibleExpertId)
              ? { name: getUserName(tenantUsersMap, responsibleExpertId), id: responsibleExpertId }
              : undefined;
          })
          .filter(nonEmpty => nonEmpty)
      }
    ],
    filterField
  };
};

export const getDPOFilter = (
  filterField = "dpoUID",
  tenantUsersMap: Map<string, Pick<UserDTO, "id" | "email" | "firstName" | "lastName">>,
  dpoUIDs: string[],
  t: TFunction
) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("pa_general:dpo"),
        children: dpoUIDs
          .map(dpoUID => {
            return getUserName(tenantUsersMap, dpoUID)
              ? { name: getUserName(tenantUsersMap, dpoUID), id: dpoUID }
              : undefined;
          })
          .filter(nonEmpty => nonEmpty)
      }
    ],
    filterField
  };
};
export const getAssignedUserFilter = (
  filterField = "assignedUserId",
  assignedUserIds: string[],
  tenantUsersMap: Map<string, Pick<UserDTO, "id" | "email" | "firstName" | "lastName">>,
  t: TFunction
) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("filter_criteria:assigned_user"),
        children: assignedUserIds
          .map(assignedUserId => {
            return getUserName(tenantUsersMap, assignedUserId)
              ? { name: getUserName(tenantUsersMap, assignedUserId), id: assignedUserId }
              : undefined;
          })
          .filter(nonEmpty => nonEmpty)
      }
    ],
    filterField
  };
};

export const getDataBreachesStatusFilter = (filterField = "status", t: TFunction) => {
  const statuses = ["OPEN", "IN_PROGRESS", "PENDING_FEEDBACK", "CLOSED"];
  return {
    filterTree: [
      {
        checkable: false,
        name: t("task_details:status"),
        children: statuses.map(s => {
          return {
            name: t(`data_breaches_general_page:status_${s}`),
            id: s
          };
        })
      }
    ],
    filterField
  };
};

export const getDataBreachesIncidentFilter = (filterField = "reportIncident", t: TFunction) => {
  const incidentsTree = [
    {
      name: t("data_breaches_evaluation_page:incident_reported"),
      id: true
    },
    {
      name: t("data_breaches_evaluation_page:incident_not_reported"),
      id: false
    }
  ];
  return {
    filterTree: [
      {
        checkable: false,
        name: t("data_breaches_evaluation_page:evaluation"),
        children: incidentsTree
      }
    ],
    filterField
  };
};

export const getDataSubjectRequestCaseFilter = (
  filterField = "types",
  t: TFunction,
  resources: Record<string, ResourceDTO[]>
) => {
  return getResourcesFilter({
    t,
    filterField,
    filterName: t("filter_criteria:caseType"),
    resources,
    resourceType: RESOURCE_TYPES.DSR_REQUEST_TYPE
  });
};

export const getUsersFeaturesFilter = (filterField = "featureIds", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("fields:userFeatures"),
        children: Object.values(USER_FEATURE_IDS).map(l => ({ name: t(`userFeatures:${l}`), id: l }))
      }
    ],
    filterField
  };
};

export const getUsersUserRoleFilter = (
  filterField = "userRole",
  roleNamesAndIds: { name: string; id: string }[],
  t: TFunction
) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("manage-user-page:userRole"),
        children: roleNamesAndIds.map(role => {
          return {
            name: t(`manage-user-page:${role.name}`, role.name),
            id: role.id
          };
        })
      }
    ],
    filterField
  };
};

export const getDataSubjectRequestStatusFilter = (filterField = "status", t: TFunction) => {
  const statuses = ["OPEN", "DONE"];
  return {
    filterTree: [
      {
        checkable: false,
        name: t("task_details:status"),
        children: statuses.map(s => {
          return {
            name: t(s.toLocaleLowerCase()),
            id: s
          };
        })
      }
    ],
    filterField
  };
};

export const getRiskLevelFilter = (filterField = "riskLevel", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("risk_assessment:riskLevel"),
        children: Object.values(RISK_LEVEL).map(l => ({ name: t(`risk_level_short:${l}`), id: l }))
      }
    ],
    filterField
  };
};

export const getRiskTreatmentFilter = (filterField = "treatment", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("risk_treatment_page:subTitle"),
        children: Object.values(RISK_TREATMENT_TYPES).map(l => ({ name: t(`risk_treatment_page:${l}`), id: l }))
      }
    ],
    filterField
  };
};

export const getDataStorageLocationFilter = (
  filterField = "dataStorageIds",
  dataLocations: { id: string; nameKey: string }[],
  t: TFunction
) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("filter_criteria:data_locations"),
        children: (dataLocations || []).map(dataLocation => ({
          name: t(`lists_data_locations:${dataLocation.nameKey}`, dataLocation.nameKey),
          id: dataLocation.id
        }))
      }
    ],
    filterField
  };
};

export const getRiskPrivacyRelevantFilter = (filterField = "privacyRelevant", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("filter_criteria:privacy_relevant"),
        children: [
          {
            name: t("filter_criteria:approved"),
            id: true
          },
          {
            name: t("filter_criteria:notApproved"),
            id: false
          }
        ]
      }
    ],
    filterField
  };
};

export const getPersonGroupFilter = (
  filterField = "personGroupId",
  personGroups: {
    id?: string;
    personGroupKey?: string;
  }[],
  t: TFunction
) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("personGroup:questionTitle"),
        children: personGroups.map(personGroup => ({
          name: t(
            `lists_data_types_categories_person_groups:${personGroup.personGroupKey}`,
            personGroup.personGroupKey
          ),
          id: personGroup.id
        }))
      }
    ],
    filterField
  };
};

export const getDataAssetFilter = (
  filterField = "allDataTypeIds",
  personGroups: PersonGroup[],
  t: TFunction,
  uncheckParentForUncheckedChildrens: any
) => {
  const children = (personGroups || []).map(personGroup => {
    return {
      id: personGroup.id,
      name: t(`lists_data_types_categories_person_groups:${personGroup.personGroupKey}`, personGroup.personGroupKey),
      dataType: DataAssetType.DataType,
      checkable: true,
      children: personGroup.dataCategories.map(dataCategory => {
        return {
          id: dataCategory.id,
          name: t(
            `lists_data_types_categories_person_groups:${dataCategory.dataCategoryKey}`,
            dataCategory.dataCategoryKey
          ),
          dataType: DataAssetType.DataCategory,
          checkable: true,
          children: dataCategory.dataTypes.map(dataType => {
            return {
              id: dataType.id,
              name: t(`lists_data_types_categories_person_groups:${dataType.dataTypeKey}`, dataType.dataTypeKey),
              dataType: DataAssetType.DataType,
              checkable: true
            };
          })
        };
      })
    };
  });
  return {
    filterTree: [
      {
        checkable: false,
        name: t("personGroup:questionTitle"),
        children
      }
    ],
    filterField,
    uncheckParentForUncheckedChildrens
  };
};

export const getTaskDoneIntervalFilter = (filterField = "doneInterval", t: TFunction) => {
  return {
    filterTree: Object.values(DONE_INTERVAL).map(value => ({
      name: t(`task_details:${value}`),
      id: value
    })),
    filterField,
    singleSelect: true,
    switchControl: {
      label: t("tasks_page:completedTasks"),
      defaultValue: [DONE_INTERVAL.today]
    }
  };
};

export const getTaskPriorityFilter = (filterField = "priority", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("task_details:priority"),
        children: Object.values(TASK_PRIORITIES).map(value => ({
          name: t("task_details:priority_" + value),
          id: value
        }))
      }
    ],
    filterField
  };
};

export const getTaskStatusFilter = (filterField = "status", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("task_details:status"),
        children: ["TODO", "OPEN"].map(value => ({ name: t("task_details:status_" + value), id: value }))
      }
    ],
    filterField
  };
};

export const getLabelsFilter = (
  filterField = "labelIds",
  resources: Record<string, ResourceDTO[]>,
  t: TFunction,
  overviewData?: OverviewResult
) => {
  return getResourcesFilter({
    t,
    filterField,
    filterName: t("filter_criteria:label"),
    resources,
    resourceType: RESOURCE_TYPES.LABEL,
    overviewData
  });
};

export const getWebsiteGDPRRiskLevelFilter = (filterField = "riskLevel", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("filter_criteria:status"),
        children: Object.values(RISK_LEVEL).map(l => ({ name: t(`website_gdpr_risk:${l}`), id: l }))
      }
    ],
    filterField
  };
};

export const getAssetsStatusFilter = (filterField = "status", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("assets_overview:status"),
        children: Object.values(ASSET_STATUS).map(value => ({
          name: t("assets_overview:" + value.toLowerCase()),
          id: value
        }))
      }
    ],
    filterField
  };
};

export const getAssetsTypeFilter = (filterField = "type", resources: Record<string, ResourceDTO[]>, t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("assets_overview:type"),
        children: resources[RESOURCE_TYPES.ASSET_TYPE].map(value => ({
          name: t("assets_overview:" + value.nameKey, value.nameKey),
          id: value.id
        }))
      }
    ],
    filterField
  };
};

export const getServiceTypeFilter = (filterField = "serviceType", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("filter_criteria:serviceType"),
        children: SERVICE_TYPES_KEYS.map(value => ({
          name: t("filter_criteria:service_type_" + value),
          id: value
        }))
      }
    ],
    filterField
  };
};

export const getContractTypesFilter = (
  filterField = "contractTypes",
  t: TFunction,
  resources: Record<string, ResourceDTO[]>
) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("filter_criteria:contract_type"),
        children: resources[RESOURCE_TYPES.EXTERNAL_RECIPIENT_CONTRACT_TYPE].map(value => ({
          name: t("resources_external-recipient-contract-type:" + value.nameKey, value.nameKey),
          id: value.id
        }))
      }
    ],
    filterField
  };
};

export const getDataProcessingOutsideEUFilter = (filterField = "dpExcluded", t: TFunction) => {
  const dpExcludedTree = [
    {
      name: t("filter_criteria:processing_outside_excluded"),
      id: true
    },
    {
      name: t("filter_criteria:processing_outside_not_excluded"),
      id: false
    }
  ];
  return {
    filterTree: [
      {
        checkable: false,
        name: t("filter_criteria:processing_outside"),
        children: dpExcludedTree
      }
    ],
    filterField
  };
};

export const getSafeguardsFilter = (
  filterField = "safeguards",
  t: TFunction,
  resources: Record<string, ResourceDTO[]>
) => {
  return getResourcesFilter({
    t,
    filterField,
    filterName: t("filter_criteria:safeguards"),
    resources,
    resourceType: RESOURCE_TYPES.SAFEGUARD
  });
};

export const getResourcesFilter = ({
  filterField,
  t,
  filterName,
  resources,
  resourceType,
  overviewData
}: {
  filterField: string;
  t: TFunction;
  filterName: string;
  resources: Record<string, ResourceDTO[]>;
  resourceType: RESOURCE_TYPE;
  overviewData?: Pick<OverviewResult, "responseItems" | "allItems" | "items">;
}) => {
  const usedResourceIds = overviewData
    ? new Set(
        (overviewData.responseItems || overviewData.allItems || overviewData.items || [])
          .flatMap(item => item[filterField])
          .filter((it): it is string => !!it && typeof it === "string")
      )
    : undefined;

  return {
    filterTree: [
      {
        checkable: false,
        name: filterName,
        children: (resources[resourceType] || [])
          .filter(resource => !usedResourceIds || usedResourceIds.has(resource.id))
          .map(value => ({
            name: translateResource({ t, resourceType, nameKey: value.nameKey || "" }),
            id: value.id
          }))
      }
    ],
    filterField
  };
};

export const getExternalRecipientStatusFilter = (filterField = "approved", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("filter_criteria:status"),
        children: [
          {
            name: t("filter_criteria:status_approved"),
            id: true
          },
          {
            name: t("filter_criteria:status_not_approved"),
            id: false
          }
        ]
      }
    ],
    filterField
  };
};

export const getExternalRecipientSelectedInPAFilter = (filterField = "selectedInProcess", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("service_providers_overview:processingActivities"),
        children: [
          {
            name: t("filter_criteria:selected"),
            id: "true"
          },
          {
            name: t("filter_criteria:notSelected"),
            id: "false"
          }
        ]
      }
    ],
    filterField
  };
};

export const getAuditsStatusesTypeFilter = (filterField = "status", statusTypes: string[], t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("audits_overview:status"),
        children: statusTypes.map(status => ({
          name: t(`audit_status:${status}`),
          id: status
        }))
      }
    ],
    filterField
  };
};

export const getDataLocationConnectedToExternalRecipient = () => {
  return {
    filterTree: [
      {
        checkable: false,
        name: i18n.t("dashboardPage:serviceproviders"),
        children: [
          {
            name: i18n.t("filter_criteria:not_linked_to_edt"),
            id: false
          }
        ]
      }
    ],
    filterField: "connected"
  };
};

export const getTomStatusFilter = (filterField = "status", t: TFunction) => {
  const statuses = ["IMPLEMENTED", "PLANNED"];
  return {
    filterTree: [
      {
        checkable: false,
        name: t("task_details:status"),
        children: statuses.map(s => {
          return {
            name: t(`tom_description_tab:status_${s}`),
            id: s
          };
        })
      }
    ],
    filterField
  };
};

export const getTomProtectionObjectiveFilter = (
  filterField = "protectionObjectiveIds",
  resources: Record<string, ResourceDTO[]>,
  t: TFunction
) => {
  return getResourcesFilter({
    t,
    filterField,
    filterName: t("tom_overview:protectionObjective"),
    resources,
    resourceType: RESOURCE_TYPES.PROTECTION_OBJECTIVE
  });
};

export const getCollectionsFilter = (filterField = "collection", collections: string[], t: TFunction) => {
  const validCollections: string[] = [
    COLLECTIONS.AUDITS,
    COLLECTIONS.DATA_BREACHES,
    COLLECTIONS.EXTERNAL_RECIPIENTS,
    COLLECTIONS.TOM,
    COLLECTIONS.PROCESSES,
    COLLECTIONS.DATA_SUBJECT_REQUESTS
  ];

  // const collectionValues = Object.values(COLLECTIONS);
  const collectionsToFilter = collections.filter(i => validCollections.includes(i));
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return {
    filterTree: [
      {
        checkable: false,
        name: t("common:feature"),
        children: collectionsToFilter?.map(collection => {
          return {
            name: capitalizeFirstLetter(t(`collection:${collection}`)),
            id: collection
          };
        })
      }
    ],
    filterField
  };
};
export const getAITechDocStatusFilter = (filterField = "status", t: TFunction) => {
  return {
    filterTree: [
      {
        checkable: false,
        name: t("aitechdoc_overview:status"),
        children: Object.values(AITechDocDTOStatusEnum).map(value => ({
          name: t(`aitechdoc_overview:${value.toLowerCase()}`),
          id: value
        }))
      }
    ],
    filterField
  };
};

export const getLegalBasisFilter = (
  filterField = "allLegalBasisIds",
  resources: Record<string, ResourceDTO[]>,
  t: TFunction,
  overviewData?: OverviewResult
) => {
  return getResourcesFilter({
    t,
    filterField,
    filterName: t("filter_criteria:legal_basis"),
    resources,
    resourceType: RESOURCE_TYPES.LEGAL_BASIS,
    overviewData
  });
};
