import Box from "@material-ui/core/Box";
import { useCallback, useState } from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import i18n from "app/i18n";
import { getLocale } from "components/DateField";
import { STATUSES, updateTaskDeadline } from "app/handlers/tasksHandler";
import { createDueDateLabel, createRegularDateLabel } from "app/utils/create-due-date-label";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { COLLECTIONS } from "app/collections";
import { useSnackbar } from "notistack";
import stopEvent from "tool/stopEvent";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    padding: "6px 10px",
    fontWeight: 600,
    letterSpacing: "1px",
    fontSize: "10px",
    whiteSpace: "nowrap",
    height: "24px",
    "&.overdue": {
      color: theme.palette.common.white,
      backgroundColor: "#F17A79"
    },
    "&.today": {
      color: "#C81228",
      backgroundColor: "#FDE9E9"
    },
    "&.tomorrow": {
      color: "#C81228",
      backgroundColor: "#FDE9E9"
    },
    "&.five_days": {
      color: "#BF871F",
      backgroundColor: "#FFF0D4"
    },
    "&.regular": {
      color: "#6C6C6C",
      backgroundColor: "#eeeeee"
    }
  },
  addDueDateDashed: {
    height: "24px",
    border: "1px dashed",
    borderRadius: "22px",
    fontSize: "10px",
    letterSpacing: "0.5px",
    borderColor: theme.palette.grey[500],
    color: theme.palette.grey[500],
    "&:hover": {
      background: theme.palette.grey[200]
    }
  }
}));

const TaskOverviewBrickDueDate = ({ item }: OverviewRowBrickProps) => {
  const { id, dueDate, status, showAddDueDate, readOnly } = item;

  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { auth } = useAuthentication();
  const { tenantId, uid } = auth || { tenantId: "", uid: "" };
  const dispatch = useOverviewDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);

  const { dateLabel, dateVariant } =
    status === STATUSES.done ? createRegularDateLabel(dueDate, t) : createDueDateLabel(dueDate, t);

  const reload = useCallback(
    (taskId?: string | null) => {
      const reload = {
        selectedId: taskId,
        shadowLoading: true,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now()
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.TASKS, reload });
    },
    [dispatch]
  );

  const openMenuCallback = useCallback(
    event => {
      stopEvent(event);
      if (readOnly) {
        return;
      }
      setOpen(true);
    },
    [readOnly]
  );

  const closeMenuCallback = useCallback(() => {
    setOpen(false);
  }, []);

  const onChangeDueDateCallback = useCallback(
    async (dueAt: Date | null) => {
      if (!dueAt) {
        await updateTaskDeadline(tenantId, uid, id || "", null).catch(error => {
          enqueueSnackbar(error.message, { variant: "error" });
        });
      } else {
        const now = new Date();
        const endOfDayDateDue = new Date(new Date(dueAt).setHours(23, 59, 59, 999));
        // when click on "clear"
        const finalDueDate = now.getTime() > endOfDayDateDue.getTime() ? null : endOfDayDateDue;
        await updateTaskDeadline(tenantId, uid, id || "", finalDueDate).catch(error => {
          enqueueSnackbar(error.message, { variant: "error" });
        });
      }

      reload();
    },
    [enqueueSnackbar, id, reload, tenantId, uid]
  );

  const textFieldComponentCallback = useCallback(
    () =>
      dueDate ? (
        <Tooltip title={t("changeDueDate")} disableHoverListener={readOnly}>
          <Box
            className={`${cls.root} ${dateVariant}`}
            onClick={openMenuCallback}
            mr={2}
            style={!readOnly ? { cursor: "pointer" } : {}}
          >
            {dateLabel}
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={t("changeDueDate")} disableHoverListener={readOnly}>
          <Box
            className={`${cls.root} ${cls.addDueDateDashed}`}
            onClick={openMenuCallback}
            mr={2}
            style={!readOnly ? { cursor: "pointer" } : {}}
          >
            {`+ ${t("add_due_date")}`}
          </Box>
        </Tooltip>
      ),
    [cls.addDueDateDashed, cls.root, dateLabel, dateVariant, dueDate, openMenuCallback, readOnly, t]
  );

  if (!dueDate && !showAddDueDate) {
    return <></>;
  }

  return (
    <Box onClick={stopEvent}>
      <MuiPickersUtilsProvider locale={getLocale(i18n?.language || "")} utils={DateFnsUtils}>
        <KeyboardDatePicker
          disablePast={true}
          fullWidth
          disableToolbar
          variant="dialog"
          inputVariant="outlined"
          format="dd.MM.yyyy"
          margin="normal"
          id="deadline"
          value={dueDate}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          onChange={onChangeDueDateCallback}
          onClick={openMenuCallback}
          onClose={closeMenuCallback}
          open={open}
          label={t("due_date", { optional: "(optional)" })}
          minDateMessage={t("pa_general:pastDate")}
          clearable={true}
          InputProps={{
            readOnly: true
          }}
          cancelLabel={t("common:cancel")}
          clearLabel={t("common:clear")}
          okLabel={t("common:ok")}
          TextFieldComponent={textFieldComponentCallback}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export default TaskOverviewBrickDueDate;
