import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { createTasksBasedOnRequestType, DataSubjectRequest } from "app/handlers/dataSubjectRequestHandler";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DSRReceivedOnField } from "./components/DSRReceivedOnField";
import { DSRRequestTypeField } from "./components/DSRRequestTypeField";
import AssignUsersMultiAutocomplete from "../questionnaires/utils/AssignUsersMultiAutocomplete/AssignUsersMultiAutocomplete";
import { useDataSubjectRequest } from "app/contexts/dsr-context";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { useMetaView } from "app/contexts/meta-view-context";
import i18n from "app/i18n";
import { META_VIEW_TABS } from "components/MetaView/MetaView";
import { useTasks } from "app/contexts/tasks-context";

const sx = {
  root: {},
  userData: {
    display: "flex",
    gap: "16px"
  },
  hasVerified: {
    mt: 2,
    display: "flex",
    alignItems: "center",
    ".MuiFormControlLabel-label": {
      ml: 1
    }
  },
  dates: {
    display: "flex",
    gap: "16px"
  }
};

interface RequestsGeneralPageProps {
  readonly request: DataSubjectRequest;
  readonly disabled?: boolean;
}

const RequestGeneralPage = ({ request, disabled }: RequestsGeneralPageProps) => {
  const { t } = useTranslation("data_subject_requests_overview_general_page");
  const { setInfo, setSelectedTab, setQuestionId } = useMetaView();
  const { reloadDocRelatedTasks } = useTasks();

  const { updateBasicDataHook, assignsToHook, changeDueDateHook } = useDataSubjectRequest();
  const { catchAsSnackbar } = useErrorSnackbar();

  const info = useMemo(() => {
    return {
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      },
      title: {
        title: t("enteringInfoCardTitle"),
        text: t("request_title_info_card_text")
      },
      assignTo: {
        title: t("enteringInfoCardTitle"),
        text: t("request_assignedTo_info_card_text")
      },
      dueDate: {
        title: t("enteringInfoCardTitle"),
        text: t("request_due_date_info_card_text")
      },
      receivedOn: {
        title: t("enteringInfoCardTitle"),
        text: t("request_received_on_info_card_text")
      },
      requestType: { title: t("enteringInfoCardTitle"), text: t("request_type_info_card_text") },
      orgUnit: {
        title: t("enteringInfoCardTitle"),
        text: t("orgUnitInfo")
      },
      furtherOrgUnitIds: {
        title: t("enteringInfoCardTitle"),
        text: t("furtherOrgUnitsInfo")
      },
      description: {
        title: t("enteringInfoCardTitle"),
        text: t("request_description_info_card_text")
      }
    };
  }, [t]);

  const onFocus = useCallback(() => {}, []);
  const setEnterCardInfo = useCallback(() => setInfo(info.entering), [info.entering, setInfo]);

  useEffect(() => setEnterCardInfo(), [setEnterCardInfo]);

  const createAutomaticTasks = useCallback(async () => {
    if (!request.id) return;
    setSelectedTab(META_VIEW_TABS.TODOS);
    setQuestionId(null);
    await createTasksBasedOnRequestType(request.id, i18n.language);
    reloadDocRelatedTasks();
  }, [reloadDocRelatedTasks, request.id, setQuestionId, setSelectedTab]);

  const onUpdateFirstName = useCallback(
    firstName => {
      updateBasicDataHook({ firstName }).catch(catchAsSnackbar("failed to update first name"));
    },
    [catchAsSnackbar, updateBasicDataHook]
  );
  const onUpdateLastName = useCallback(
    lastName => {
      updateBasicDataHook({ lastName }).catch(catchAsSnackbar("failed to update last name"));
    },
    [catchAsSnackbar, updateBasicDataHook]
  );
  const onHasVerifiedChange = useCallback(() => {}, []);
  const onChangeDescription = useCallback(
    description => {
      updateBasicDataHook({ description }).catch(catchAsSnackbar("failed to update description"));
    },
    [catchAsSnackbar, updateBasicDataHook]
  );
  const onRequestTypeChange = useCallback(
    (type: string | string[] | null) => {
      const typeArray = Array.isArray(type) ? type : [];
      updateBasicDataHook({ type: typeArray }).catch(catchAsSnackbar("failed to update type"));
    },
    [catchAsSnackbar, updateBasicDataHook]
  );
  const onDueDateChange = useCallback(
    value => {
      changeDueDateHook(value).catch(catchAsSnackbar("failed to update due date"));
    },
    [catchAsSnackbar, changeDueDateHook]
  );
  const onReceivedOnChange = useCallback(
    value => {
      updateBasicDataHook({
        receivedOn: value
      }).catch(catchAsSnackbar("failed to update received on"));
      const currentDate = new Date(value);
      const newDueDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
      changeDueDateHook(newDueDate.toISOString()).catch(catchAsSnackbar("failed to update due date"));
    },
    [catchAsSnackbar, changeDueDateHook, updateBasicDataHook]
  );
  const onAssigneeChange = useCallback(
    assignedTo => {
      assignsToHook(assignedTo[0] || "").catch(catchAsSnackbar("failed to change assignment"));
    },
    [assignsToHook, catchAsSnackbar]
  );
  const onOrgUnitIdChanged = useCallback(
    (orgUnitId: string) => {
      updateBasicDataHook({ orgUnitId }).catch(catchAsSnackbar("failed to update Org Unit Id"));
    },
    [catchAsSnackbar, updateBasicDataHook]
  );
  const onFurtherOrgUnitIdsChanged = useCallback(
    (furtherOrgUnitIds: string[]) => {
      updateBasicDataHook({ furtherOrgUnitIds }).catch(catchAsSnackbar("failed to update Further Org Units"));
    },
    [catchAsSnackbar, updateBasicDataHook]
  );
  const onLabelIdsChanged = useCallback(
    (labelIds: string[]) => {
      updateBasicDataHook({ labelIds }).catch(catchAsSnackbar("failed to update labels"));
    },
    [catchAsSnackbar, updateBasicDataHook]
  );

  const isDataSubjectVerifiedChanged = useCallback(
    event => {
      const isDataSubjectVerified = event.target.value === "yes" ? "yes" : "no";
      if ((request.inputData?.isDataSubjectVerified || "") !== isDataSubjectVerified) {
        updateBasicDataHook({ isDataSubjectVerified })
          .catch(catchAsSnackbar("Failed to update verified status"))
          .then(() => createAutomaticTasks().catch(catchAsSnackbar("Failed to create automatic tasks")));
      }
    },
    [catchAsSnackbar, createAutomaticTasks, request.inputData?.isDataSubjectVerified, updateBasicDataHook]
  );

  const allowedOrgUnitIDs = useMemo<string[]>(
    () => [request?.inputData?.orgUnitId].filter(nonNull => nonNull) as string[],
    [request?.inputData?.orgUnitId]
  );

  return (
    <Box sx={sx.root}>
      <Typography sx={{ mb: 2 }} variant="h6">
        {t("general_information")}
      </Typography>
      <Typography variant="subtitle1">{t("dataSubject")}</Typography>
      <Box sx={sx.userData}>
        <Question
          sx={{ flex: 1 }}
          qType={QUESTION_TYPE.TEXT_AREA}
          questionName={t("fields:first_name")}
          value={request.inputData?.firstName}
          disabled={disabled}
          onChange={onUpdateFirstName}
          onBlur={setEnterCardInfo}
        />
        <Question
          sx={{ flex: 1 }}
          qType={QUESTION_TYPE.TEXT_AREA}
          questionName={t("fields:last_name")}
          value={request.inputData?.lastName}
          disabled={disabled}
          onChange={onUpdateLastName}
          onBlur={setEnterCardInfo}
        />
        <Question
          sx={{ flex: 1 }}
          qType={QUESTION_TYPE.EMAIL}
          questionName={t("fields:email")}
          value={request.inputData?.email}
          disabled={disabled}
          onBlur={setEnterCardInfo}
        />
      </Box>
      <Box sx={sx.hasVerified}>
        <Typography variant="body1">{t("data_subject_requests_tasks_page:hasVerified")}</Typography>
        <Box ml={6}>
          <RadioGroup row>
            <FormControlLabel
              label={t("common:yes")}
              value={"yes"}
              disabled={disabled}
              onChange={isDataSubjectVerifiedChanged}
              control={
                <Radio onClick={onHasVerifiedChange} checked={request.inputData?.isDataSubjectVerified === "yes"} />
              }
            />
            <Box mx={1} />
            <FormControlLabel
              label={t("common:no")}
              value={"no"}
              disabled={disabled}
              onChange={isDataSubjectVerifiedChanged}
              control={
                <Radio onClick={onHasVerifiedChange} checked={request.inputData?.isDataSubjectVerified === "no"} />
              }
            />
          </RadioGroup>
        </Box>
      </Box>
      <Typography sx={{ mt: 2 }} variant="subtitle1">
        {t("request")}
      </Typography>
      <Question
        qType={QUESTION_TYPE.TEXT_EDITOR}
        questionId={"description"}
        questionName={t("description")}
        translatable={request.inputData?.description}
        value={request.inputData?.description}
        translationId={"description"}
        info={info.description}
        onChange={onChangeDescription}
        onBlur={setEnterCardInfo}
        disabled={disabled}
      />
      <Question
        questionId={"request_type"}
        questionName={t("request_type")}
        disabled={disabled}
        info={info.requestType}
        onBlur={setEnterCardInfo}
      >
        <DSRRequestTypeField
          disabled={disabled || false}
          values={request.inputData?.type || []}
          onChanges={onRequestTypeChange}
          furtherOrgUnitIds={request.inputData?.furtherOrgUnitIds || []}
          orgUnitId={request.inputData?.orgUnitId || ""}
          onFocus={onFocus}
        />
      </Question>
      <Box sx={sx.dates}>
        <Question
          questionId={"received_on"}
          questionName={t("received_on")}
          disabled={disabled}
          info={info.receivedOn}
          onBlur={setEnterCardInfo}
          sx={{ flex: 1 }}
        >
          <DSRReceivedOnField
            disabled={disabled}
            createdDate={request?.created || null}
            value={request.inputData?.receivedOn || null}
            onChange={onReceivedOnChange}
          />
        </Question>
        <Question
          qType={QUESTION_TYPE.DATE_TIME}
          info={info.dueDate}
          questionId={"due_date"}
          questionName={t("due_date")}
          value={request.inputData?.dueDate || null}
          onChange={onDueDateChange}
          sx={{ flex: 1 }}
          disabled={disabled}
          onBlur={setEnterCardInfo}
        />
        <Question
          questionId={"assignedTo"}
          questionName={t("assignedTo")}
          disabled={disabled}
          info={info.assignTo}
          sx={{ flex: 1 }}
          onBlur={setEnterCardInfo}
        >
          <AssignUsersMultiAutocomplete
            id={"assignedTo"}
            docOrgUnitIds={allowedOrgUnitIDs}
            docAssignedUserIds={request.inputData?.assignedTo ? [request.inputData?.assignedTo] : []}
            onDocAssignedUserIdsChanged={onAssigneeChange}
            disabled={disabled}
            freeSolo={true}
            disableClearable={true}
            label={t("assignedTo")}
            excludedUserIds={[]}
            hasDefaultValue={false}
          />
        </Question>
      </Box>
      <Question
        qType={QUESTION_TYPE.AFFECTED_ORG_UNITS}
        questionId={"org_unit"}
        questionName={t("org_unit")}
        value={request.inputData?.orgUnitId || ""}
        info={info.orgUnit}
        onChange={onOrgUnitIdChanged}
        disabled={disabled}
        onBlur={setEnterCardInfo}
      />
      <Question
        qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
        questionId={"furtherOrgUnitIds"}
        questionName={t("furtherDepartments")}
        disabled={disabled}
        info={info.furtherOrgUnitIds}
        value={request.inputData?.furtherOrgUnitIds || []}
        onChange={onFurtherOrgUnitIdsChanged}
        onBlur={setEnterCardInfo}
      />
      <Question
        qType={QUESTION_TYPE.LABELS}
        questionId={"labelIds"}
        questionName={t("labels")}
        disabled={disabled}
        value={request?.labelIds}
        orgUnitIds={
          request?.inputData ? [request?.inputData.orgUnitId, ...(request?.inputData.furtherOrgUnitIds || [])] : []
        }
        onChange={onLabelIdsChanged}
        onBlur={setEnterCardInfo}
      />
    </Box>
  );
};

export default RequestGeneralPage;
