import React, { useCallback, useMemo } from "react";
import { QuestionProps } from "../../Question";
import MultiAutocomplete from "../../../MultiAutocomplete/MultiAutocomplete";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../../app/features";
import { defaultRolesWithoutGlobal, useUserRoles } from "../../../../app/contexts/role-context";
import { useTranslation } from "react-i18next";

const SelectRoleQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onChange,
  onBlur,
  onFocus
}: QuestionProps) => {
  const roleFeaturesEnabled = useIsFeaturePresent(FEATURES.USERROLES_FEATURES);
  const { roles } = useUserRoles();
  const roleIds = useMemo(() => {
    const defaultRoleIds: string[] = [...defaultRolesWithoutGlobal];
    const customRoleIds = roles.map(role => role.id).filter((it): it is string => !!it);
    if (!roleFeaturesEnabled) {
      return defaultRoleIds;
    }
    return [...defaultRoleIds, ...customRoleIds];
  }, [roleFeaturesEnabled, roles]);
  const { t } = useTranslation();
  const getRoleName = useCallback(
    (value: string) => {
      const isDefaultRole = defaultRolesWithoutGlobal.includes(value as string);
      if (isDefaultRole) {
        return t(`manage-user-page:${value}`);
      }
      const customRole = roles.find(role => role.id === value);
      return customRole?.name || value;
    },
    [roles, t]
  );

  const _value: string = Array.isArray(value) ? value[0] : (value as string);

  return (
    <MultiAutocomplete<string, false, true, false>
      label={questionName || ""}
      disabled={disabled}
      options={roleIds}
      id={"userRole"}
      onFocus={onFocus}
      onBlur={onBlur}
      hasMultiSelect={false}
      selected={_value}
      updateSelected={onChange}
      getOptionLabel={getRoleName}
      error={error}
      helperText={helperText}
    />
  );
};

export default SelectRoleQuestion;
