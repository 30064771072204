import React, { useCallback, useEffect, useMemo, useState } from "react";
import Overview from "../../../components/Overview/Overview";
import { COLLECTIONS } from "../../collections";
import { useTranslation } from "react-i18next";
import { OVERVIEW_ADD_TYPE } from "../../../components/Overview/constants/OverviewConstants";
import { useMetaView } from "../../contexts/meta-view-context";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "../../contexts/overview-context";
import { useUserAndTenantData } from "../../handlers/userAndTenant/user-tenant-context";
import LinkIcon from "@material-ui/icons/Link";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import { OverviewItem } from "../../../components/Overview/controllers/overviewBaseController";
import { DataLocationMergeItem, DataLocationMergeModal } from "./DataLocationMergeModal";
import { DataLocationLinkItem, DataLocationLinkModal } from "./DataLocationLinkModal";
import UnlinkIcon from "@material-ui/icons/LinkOff";
import { DataLocationUnlinkItem, DataLocationUnlinkModal } from "./DataLocationUnlinkModal";
import { CollectionParams } from "../../../hook/useOverviewData";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { externalRecipientWritePermissions } from "app/handlers/permissionHandler";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";
import { OverviewBrickProps, OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { exportDataLocationsExcel } from "./dataLocationExcelExportData";
import { GetApp } from "@mui/icons-material";

const DataLocationOverview = ({
  externalRecipientId,
  hideTitle,
  onDataLocationClicked,
  onReloadMetaview,
  dataLocationIds,
  hideActions
}: {
  externalRecipientId?: string;
  hideTitle?: boolean;
  dataLocationIds?: string[] | undefined;
  hideActions?: boolean;
  onDataLocationClicked?: (dataLocation: { readonly id: string }) => void;
  onReloadMetaview?: (reloadKey: { readonly reloadKey: string }) => void;
}) => {
  const { t } = useTranslation("service_providers_overview");
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { setInfo } = useMetaView();
  const dispatch = useOverviewDispatch();
  const { auth } = useAuthentication();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t(externalRecipientId ? "product_tab:infoTitle" : "enteringDataSourcesLocationOverviewInfoCardTitle"),
        text: t(externalRecipientId ? "product_tab:infoText" : "enteringDataSourcesLocationOverviewInfoCardText")
      }
    }),
    [t, externalRecipientId]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo]);

  const onExportAll = useCallback(async () => {
    if (!auth?.tenantId) {
      return;
    }
    return exportDataLocationsExcel({ tenantId: auth?.tenantId, t });
  }, [auth?.tenantId, t]);

  const toolbarActions = useMemo(
    () =>
      [
        { action: "sort" },
        { action: "filter" },
        { action: "add" },
        auth?.role === "tenantAdmin"
          ? [{ action: "export", tooltip: t("overview:download"), onHandle: onExportAll, icon: <GetApp /> }]
          : [],
        {
          action: "mark-all-as-read"
        }
      ].flat(2),
    [onExportAll, auth?.role, t]
  );
  const writePermission = useMemo(
    () => auth?.permissions.some(permission => externalRecipientWritePermissions.includes(permission)),
    [auth?.permissions]
  );
  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }], []);

  const onRowClick = useCallback(
    async (item: OverviewItem) => {
      if (onDataLocationClicked) {
        await onDataLocationClicked({ id: item.id });
      }

      const unseen = item.seen === false || item.unseen === true;

      if (!unseen) {
        dispatch({
          type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
          collection: COLLECTIONS.DATA_LOCATIONS,
          selectedId: item.id
        });
      } else {
        await addToSeenItemsOfUserHook(COLLECTIONS.DATA_LOCATIONS, item.id);
        dispatch({
          type: OVERVIEW_ACTIONS.RELOAD,
          collection: COLLECTIONS.DATA_LOCATIONS,
          reload: {
            shadowLoading: true,
            reloadOverview: Date.now(),
            reloadMetaview: Date.now(),
            selectedId: item.id
          }
        });
      }
    },
    [addToSeenItemsOfUserHook, dispatch, onDataLocationClicked]
  );

  const [toMergeItems, setToMergeItems] = useState<DataLocationMergeItem[]>([]);
  const [toLinkItems, setToLinkItems] = useState<DataLocationLinkItem[]>([]);
  const [toUnlinkItems, setToUnlinkItems] = useState<DataLocationUnlinkItem[]>([]);
  const onDataLocationMerges = useCallback((items: DataLocationOverviewItem[]) => {
    setToMergeItems(items);
  }, []);
  const onDataLocationLinks = useCallback((items: DataLocationOverviewItem[]) => {
    setToLinkItems(items);
  }, []);
  const onDataLocationUnlinks = useCallback((items: DataLocationOverviewItem[]) => {
    setToUnlinkItems(items);
  }, []);
  const onCancelDialog = useCallback(() => {
    setToMergeItems([]);
    setToLinkItems([]);
    setToUnlinkItems([]);
    if (onReloadMetaview) {
      onReloadMetaview({ reloadKey: Date.now().toLocaleString() });
    }
  }, [onReloadMetaview]);
  const onDataLocationMergesClose = useCallback(() => {
    onCancelDialog();
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.DATA_LOCATIONS,
      reloadOverview: Date.now()
    });
  }, [dispatch, onCancelDialog]);
  const onDataLocationLinksClose = useCallback(() => {
    onCancelDialog();
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.DATA_LOCATIONS,
      reloadOverview: Date.now()
    });
  }, [dispatch, onCancelDialog]);
  const onDataLocationUnlinksClose = useCallback(() => {
    onCancelDialog();
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.DATA_LOCATIONS,
      reloadOverview: Date.now()
    });
  }, [dispatch, onCancelDialog]);

  const selectionActions = useMemo(
    () => [
      {
        action: "dataLocationMerges",
        onHandle: onDataLocationMerges,
        title: t("common:merge"),
        icon: <MergeTypeIcon />
      },
      {
        action: "dataLocationLinks",
        onHandle: onDataLocationLinks,
        title: t("common:link"),
        icon: <LinkIcon />
      },
      {
        action: "dataLocationUnlinks",
        onHandle: onDataLocationUnlinks,
        title: t("common:unlink"),
        icon: <UnlinkIcon />
      },
      { action: "remove" }
    ],
    [t, onDataLocationMerges, onDataLocationLinks, onDataLocationUnlinks]
  );

  const rowUnlinkCallback = useCallback((itemId: string) => {
    setToUnlinkItems([{ id: itemId }]);
  }, []);
  const rowLinkCallback = useCallback((itemId: string) => {
    setToLinkItems([{ id: itemId }]);
  }, []);

  const collectionParams = useMemo(
    () => ({ filter: { externalRecipientId, dataLocationIds } }) satisfies CollectionParams,
    [externalRecipientId, dataLocationIds]
  );

  const bricks: OverviewBrickProps[] =
    hideActions || !writePermission
      ? ([{ component: OverviewBrick.Title }] as OverviewBrickProps[])
      : ([
          { component: OverviewBrick.Title },
          {
            component: (brickProps: OverviewRowBrickProps) => (
              <OverviewBrick.Link
                item={brickProps.item}
                hovered={brickProps.hovered}
                title={t("service_providers_overview:link_to_service_provider")}
                onClick={rowLinkCallback}
                onBlur={brickProps.onBlur}
              />
            ),
            position: "right"
          },
          {
            component: (brickProps: OverviewRowBrickProps) => (
              <OverviewBrick.Unlink
                item={brickProps.item}
                hovered={brickProps.hovered}
                onClick={rowUnlinkCallback}
                onBlur={brickProps.onBlur}
              />
            ),
            position: "right"
          },
          { component: OverviewBrick.Rename, position: "right" },
          { component: OverviewBrick.Remove, position: "right" }
        ] as OverviewBrickProps[]);

  return (
    <>
      <Overview
        addActions={hideActions || !writePermission ? [] : addActions}
        collection={COLLECTIONS.DATA_LOCATIONS}
        collectionParams={collectionParams}
        dnd={false}
        header={hideTitle ? undefined : t("service_providers_overview:data_tab_title")}
        onAddClose={undefined}
        onAddOpen={undefined}
        onRowClick={onRowClick}
        onRowLeave={undefined}
        onRowOver={undefined}
        selectionActions={selectionActions}
        toolbarActions={hideActions || !writePermission ? [] : toolbarActions}
        checkable={!hideActions && writePermission}
        toolbarMode={"tabs"}
        showSelectAll={!hideActions && writePermission}
        bricks={bricks}
      />
      <DataLocationMergeModal
        selectedDataLocations={toMergeItems}
        onClose={onDataLocationMergesClose}
        onCancel={onCancelDialog}
      />
      <DataLocationLinkModal
        selectedDataLocations={toLinkItems}
        onClose={onDataLocationLinksClose}
        onCancel={onCancelDialog}
      />
      <DataLocationUnlinkModal
        selectedDataLocations={toUnlinkItems}
        onClose={onDataLocationUnlinksClose}
        onCancel={onCancelDialog}
      />
    </>
  );
};

export interface DataLocationOverviewItem extends OverviewItem {
  readonly externalRecipientId: string | null;
}

export default DataLocationOverview;
