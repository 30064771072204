import { useEffect, useMemo, useState } from "react";
import OverviewHighlight from "../../controls/OverviewHighlight";
import { useTranslatedKey } from "app/api/multilingualApi";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import colors from "theme/palette/colors";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

const sx = {
  root: {
    minWidth: 0
  }
};

const OverviewBrickTitle = ({ item, searchTerm }: OverviewRowBrickProps) => {
  const { id, title, subTitle, isMultilingual, translationDomainName, seen, unseen } = item;
  const [t, i18n] = useTranslation("overview");

  const [rowTitle, setRowTitle] = useState(title);

  const translatedTitle = useTranslatedKey({
    translationKey: id || "",
    languageCode: i18n.language,
    disabled: !isMultilingual
  });
  const reloadTranslation = translatedTitle.mutate;

  useEffect(() => {
    if (!isMultilingual) {
      return;
    }
    reloadTranslation();
  }, [isMultilingual, title, reloadTranslation]);

  useEffect(() => {
    if (isMultilingual) {
      return;
    }
    if (!translationDomainName) {
      setRowTitle(title);
      return;
    }

    setRowTitle(t(`${translationDomainName}:${title}`, title));
  }, [t, title, translationDomainName, isMultilingual]);

  useEffect(() => {
    if (!translatedTitle.data) {
      return;
    }

    setRowTitle(translatedTitle.data);
  }, [translatedTitle.data]);

  const unseenEl = useMemo(() => {
    return seen === false || unseen === true ? (
      <Box
        data-testid={`overview-row-unssen`}
        bgcolor={colors.blue.blue500}
        borderRadius={"50%"}
        flexShrink={0}
        height={"8px"}
        width={"8px"}
      />
    ) : (
      <></>
    );
  }, [seen, unseen]);

  const titleTextElement = searchTerm ? <OverviewHighlight text={rowTitle} searchTerm={searchTerm} /> : rowTitle;
  return (
    <>
      {unseenEl}
      <Box sx={sx.root}>
        <Typography
          data-testid={`overview-row-title`}
          noWrap
          sx={{ fontWeight: seen === false || unseen === true ? "bold" : "normal", minWidth: "0px" }}
        >
          {titleTextElement}
        </Typography>
        {subTitle && (
          <Typography
            noWrap
            sx={{ color: colors.grey.grey500, minWidth: "0px", display: "block" }}
            variant="caption"
            data-testid={`overview-row-sub-title`}
          >
            {subTitle}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default OverviewBrickTitle;
