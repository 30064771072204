import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import stopEvent from "../../../../tool/stopEvent";
import { OrganizationMergeModal } from "../../../../app/pages/organization/OrganizationMergeModal";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../../app/features";

const OrganizationOverviewBrickDelete = ({ item, hovered, onBlur, checkedItems }: OverviewRowBrickProps) => {
  const { t } = useTranslation("organisationManagement");

  const [selectedOrgUnitId, setSelectedOrgUnitId] = useState<string | null>(null);

  const onDeleteAction = useCallback(
    event => {
      setSelectedOrgUnitId(item.id);
      stopEvent(event);
    },
    [item.id]
  );
  const onCancel = useCallback(() => {
    if (onBlur) {
      setSelectedOrgUnitId(null);
      onBlur();
    }
  }, [onBlur]);
  const onClose = useCallback(() => {
    if (onBlur) {
      setSelectedOrgUnitId(null);
      onBlur();
    }
  }, [onBlur]);

  const deleteFeatureDisabled = useIsFeaturePresent(FEATURES.ORG_ACTION_DELETE_DISABLED);
  const itemHasNoDeleteAction = item.disableActions?.find(action => action.action === "remove");
  const deleteDisabled = checkedItems && checkedItems?.length > 0;

  return (
    <>
      {hovered && !itemHasNoDeleteAction && !deleteFeatureDisabled && (
        <>
          <Tooltip title={deleteDisabled ? t("disableDeleteTooltip") : t("common:delete")}>
            <Box>
              <IconButton disabled={deleteDisabled} onClick={onDeleteAction} size="small">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </>
      )}
      {selectedOrgUnitId && (
        <OrganizationMergeModal selectedOrgUnit={item} onCancel={onCancel} onClose={onClose} mode={"delete"} />
      )}
    </>
  );
};

export default OrganizationOverviewBrickDelete;
