import { DataBreachStatus } from "app/api/dataBreachApi";
import { getDueDateType, OverviewDueDateProps } from "components/Overview/utils/overviewDueDate";
import OverviewBrickDueDate from "../common/OverviewBrickDueDate";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

interface DataBreachOverviewBrickDueDateProps {
  readonly status?: DataBreachStatus;
  readonly date?: string;
}

const getType = ({ status, date }: DataBreachOverviewBrickDueDateProps): OverviewDueDateProps => {
  if (status === "CLOSED") {
    return { kind: "done" };
  } else if (status === "PENDING_FEEDBACK") {
    return { kind: "noDue" };
  } else return getDueDateType(date);
};

const DataBreachOverviewBrickDueDate = ({ item, hovered }: OverviewRowBrickProps) => {
  const dueDateType = getType({ status: item.status, date: item.dueAt });
  return <OverviewBrickDueDate date={dueDateType} hovered={hovered} />;
};

export default DataBreachOverviewBrickDueDate;
