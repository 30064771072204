import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Question from "components/Question/Question";
import { ResourceField } from "components/ResourceField";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useCallback } from "react";
import { useUserProcesses } from "hook/useUserProcesses";
import { useDataSubjectRequest } from "app/contexts/dsr-context";

interface DSRInternalRecipientPickerProps {
  disabled?: boolean;
}

// internal recipient picker component for data subject requests
const DSRInternalRecipientPicker = ({ disabled }: DSRInternalRecipientPickerProps) => {
  const { t } = useTranslation("data_subject_requests_data_page");
  const { dataSubjectRequest } = useDataSubjectRequest();

  // fetch processes based on selected person groups
  const { processes, processesLoaded } = useUserProcesses({
    personGroupIDs: dataSubjectRequest?.inputData?.personGroups || []
  });

  // get all unique internal recipient ids from processes
  const internalRecipientIDs =
    processes?.reduce((acc: string[], process) => {
      const newRecipients = process.allInternalRecipientIds || [];
      return [...new Set([...acc, ...newRecipients])];
    }, []) || [];

  // prevent deletion of recipients - memoized to fix linter error
  const isNotDeletable = useCallback(() => true, []);

  // empty onChange as component is read-only - memoized to fix linter error
  const onChange = useCallback(() => {
    /* do nothing */
  }, []);

  return (
    <Box>
      <Question questionId={"dataRecipients:internal"} questionName={t("dataRecipients:internal")} disabled={disabled}>
        <ResourceField
          disabled={true}
          id="internal_recipients"
          value={internalRecipientIDs}
          resourceType={RESOURCE_TYPES.INTERNAL_RECIPIENT}
          label={t("dataRecipients:internal")}
          isNotDeletable={isNotDeletable}
          onChange={onChange}
          icon={!processesLoaded ? undefined : undefined}
        />
      </Question>
    </Box>
  );
};

export default DSRInternalRecipientPicker;
