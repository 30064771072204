import i18n from "../i18n";

export default function getTextTitle(task) {
  const t = i18n.t.bind(i18n);
  // format question indexes
  const getQuestionIdIndex = indexNumber => {
    if (isNaN(indexNumber)) {
      return "";
    }

    const translationKeyLastIndex = parseInt(indexNumber) + 1;
    const formatedTranslationKeyLastIndex = translationKeyLastIndex ? ` (${translationKeyLastIndex})` : "";
    return formatedTranslationKeyLastIndex;
  };

  switch (task.collection) {
    case "processes":
      if (task.questionId) {
        const questionId = task.questionId.split(" ");
        let translationKey = questionId[0];
        // page 8 of the questionnaire where you can answer all questions about rights
        const rightsPageId = 8;
        if (task.questionId === "name" || translationKey === "risks") translationKey = "processes_" + translationKey;
        let string = t("tasks_page:pa_check_comment_for_field") + task.name + " " + t("tasks_page:field") + " ";
        if (task.questionId.includes("rights") && task.pageId === rightsPageId) return string + t(task.questionId);
        else return string + t("tasks_page:" + translationKey) + getQuestionIdIndex(questionId[1]);
      } else if (task.type === "check_comment") {
        return t("tasks_page:pa_check_comment_for_field") + task.name;
      }
      return task.title;

    case "dpias":
      return t("tasks_page:task_name_dpia") + task.name;

    case "serviceProviders":
      if (task.questionId) {
        const questionId = task.questionId.split(" ");
        let translationKey = questionId[0];
        if (task.questionId === "name") translationKey = "service_provider_name";
        return (
          t("tasks_page:sp_check_comment_for_field") +
          task.name +
          " " +
          t("tasks_page:field") +
          " " +
          t("tasks_page:" + translationKey) +
          getQuestionIdIndex(questionId[1])
        );
      }
      return t("tasks_page:task_name_service_providers") + task.name;

    case "tom":
      if (task.questionId) {
        const questionId = task.questionId.split(" ");
        let translationKey = questionId[0];
        if (task.questionId === "risks") translationKey = "tom_risks";
        return (
          t("tasks_page:tom_check_comment_for_field") +
          task.name +
          " " +
          t("tasks_page:field") +
          " " +
          t("tasks_page:" + translationKey) +
          getQuestionIdIndex(questionId[1])
        );
      }
      return t("tasks_page:task_name_tom") + task.name;

    case "dataSubjectRequests":
      if (task.type === "check_comment" && !task.questionId) {
        return t("tasks_page:dsr_check_comment_for_field");
      }
      if (task.type === "check_comment" && task.questionId) {
        return (
          t("tasks_page:dsr_check_comment_for_field") +
          " " +
          t("tasks_page:field") +
          " " +
          translateDataSubjectRequestQuestionId(t, task.questionId)
        );
      }
      return t("tasks_page:task_name_dsr") + task.name;

    default:
      return task.name ?? "n/a";
  }
}

const translateDataSubjectRequestQuestionId = (translate, questionId) => {
  switch (questionId) {
    case "receivedOn":
      return translate("data_subject_requests_overview_general_page:received_on");
    case "dueDate":
      return translate("data_subject_requests_overview_general_page:due_date");
    case "isDataSubjectVerified":
      return translate("data_subject_requests_tasks_page:hasVerified");
    case "person_groups":
      return translate("personGroup:questionTitle");
    default:
      return translate("data_subject_requests_overview_general_page:" + questionId);
  }
};
