import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import MultiAutocomplete from "../../../components/MultiAutocomplete/MultiAutocomplete";
import { getDataLocationWithExternalRecipient } from "../../utils/getDataLocationWithExternalRecipient";
import OverviewList from "../../../components/LegacyOverview/OverviewList";
import OverviewListItemPagination from "../../../components/LegacyOverview/OverviewListItemPagination";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import Chip from "@mui/material/Chip";
import DateDisplay from "../../../components/DateDisplay";
import { AutomaticUserDataDisplay } from "../../../components/UserDataDisplay";
import { DataSubjectRequestsPageButtons, DataSubjectRequestsPageStepper } from "./DataSubjectRequestsPagination";
import MetaView, { META_VIEW_TABS_TYPES } from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import AttachmentsOverviewOBS from "../shared/Attachments/AttachmentsOverviewOBS";
import { DSRPersonGroupField } from "./DataSubjectRequestsFields";
import { useExternalRecipients } from "../../contexts/external-recipient-context";
import { useDataLocations } from "../../../hook/useDataLocations";
import { useUserDepartments } from "../../contexts/department-context";
import { getPersonGroupAndDataCategoryKeysFromDataTypeIds } from "../data-breaches/dataBreachesUtils";
import Question from "components/Question/Question";
import { COLLECTIONS } from "app/collections";
import { useDataSubjectRequest } from "../../contexts/dsr-context";
import { ResourceField } from "../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../handlers/resourceHandler";
import { useResources } from "../../contexts/resource-context";
import { useUserProcesses } from "../../../hook/useUserProcesses";
import { DataTypeTreeData, useDataTypeTree } from "app/api/dataAssetApi";
import { useAuthentication } from "../../handlers/authentication/authentication-context";
import { ProcessingActivityOverviewDTO } from "../../api/paApi";
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete/Autocomplete";
import Typography from "@mui/material/Typography";

export default function DataSubjectRequestsDataPage({ documentId }: { documentId: string }) {
  const { t, i18n } = useTranslation("data_subject_requests_data_page");
  const { resourcesLoaded, resources } = useResources();
  const { dataLocations, dataLocationsLoaded } = useDataLocations();
  const dataLocationIds = useMemo(() => dataLocations.map(location => location.id), [dataLocations]);
  const dataLocationsMap = useMemo(() => {
    return new Map(dataLocations.map(location => [location.id, location]));
  }, [dataLocations]);
  const { departmentsLoaded, getDepartmentName, isPartOfUserDepartments } = useUserDepartments();
  const [listOfProcesses, setListOfProcesses] = useState<
    {
      id: string;
      name: string;
      meta: Record<string, React.ReactNode>;
    }[]
  >([]);
  const [displayableProcesses, setDisplayableProcesses] = useState<
    {
      id: string;
      name: string;
      meta: Record<string, React.ReactNode>;
    }[]
  >([]);
  const [personGroups, setPersonGroups] = useState<string[]>([]);
  const [dataStorageIDs, setDataStorageIDs] = useState<string[]>([]);
  const [internalRecipientIDs, setInternalRecipientIDs] = useState<string[]>([]);
  const [dsrExternalRecipientsIDs, setDsrExternalRecipientsIDs] = useState<string[]>([]);
  const [title, setTitle] = useState("");
  const [pageInitialized, setPageInitialized] = useState(false);
  const [dataSourceIDs, setDataSourceIDs] = useState<string[]>([]);
  const { auth } = useAuthentication();

  const dataTypeTree = useDataTypeTree();
  const dataTypeCategoryPersonGroupList = useMemo(() => {
    return dataTypeTree.data || [];
  }, [dataTypeTree.data]);

  const { setMeta, setInfo } = useMetaView();

  const [pageIndex, setPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const {
    processes,
    processesLoaded: paLoaded,
    isValidating: paReloading
  } = useUserProcesses({
    personGroupIDs: personGroups
  });

  const { externalRecipientsDict, initialized: erInitialized } = useExternalRecipients();
  const externalRecipientIds = useMemo(() => Object.keys(externalRecipientsDict), [externalRecipientsDict]);

  const infoCardNoPersonGroup = useMemo(
    () => ({
      title: t("info_title"),
      text: t("info_no_person_group")
    }),
    [t]
  );
  const infoCardPersonGroup = useMemo(
    () => ({
      title: t("info_title"),
      text: t("info_person_group")
    }),
    [t]
  );

  // set Info depending on if person group selected
  useEffect(() => {
    if (personGroups?.length > 0) {
      setInfo(infoCardPersonGroup);
    } else {
      setInfo(infoCardNoPersonGroup);
    }
  }, [personGroups?.length, setInfo, infoCardPersonGroup, infoCardNoPersonGroup]);

  const {
    initialized: dataSubjectRequestInitialized,
    dataSubjectRequest,
    updateBasicDataHook
  } = useDataSubjectRequest();

  const noWritePermission = useMemo(() => {
    if (!dataSubjectRequest?.inputData?.orgUnitId) {
      return false;
    }
    if (dataSubjectRequest?.inputData?.assignedTo === auth?.uid || auth?.permissions.includes("dsr_write_all")) {
      return false;
    }
    if (isPartOfUserDepartments(dataSubjectRequest.inputData.orgUnitId)) {
      return false;
    }
    return true;
  }, [dataSubjectRequest, isPartOfUserDepartments, auth]);

  const addMetaDataToProcesses = useCallback(
    (data: ProcessingActivityOverviewDTO[]) => {
      if (!Array.isArray(data)) {
        setListOfProcesses([]);
        return;
      }

      const toMetaView = (processDetails: ProcessingActivityOverviewDTO) => {
        const metaObject = {
          [t("processes_overview:version")]: processDetails.version,
          [t("processes_overview:created")]: <DateDisplay timestamp={new Date(processDetails.createdAt)} />,
          [t("processes_overview:createdBy")]: <AutomaticUserDataDisplay uid={processDetails.createdBy} />,
          [t("processes_overview:department")]: getDepartmentName(processDetails.orgUnitIds[0]),
          [t("processes_overview:dueDate")]: processDetails.dueAt ? (
            <DateDisplay timestamp={new Date(processDetails.dueAt)} />
          ) : (
            "-"
          ),
          [t("processes_overview:status")]: t(
            `processes_overview:status_${processDetails.status}`,
            processDetails.status
          )
        };
        return {
          id: processDetails.id,
          name: processDetails.title,
          meta: metaObject
        };
      };
      const input = data.map(toMetaView);
      setListOfProcesses(input);
    },
    [getDepartmentName, t]
  );

  useEffect(() => {
    if (
      !(
        paLoaded &&
        externalRecipientsDict &&
        departmentsLoaded &&
        dataLocationsLoaded &&
        resourcesLoaded &&
        erInitialized
      )
    ) {
      return;
    }

    const allDataLocationIDs = dataLocations.map(location => location.id);
    const allInternalRecipientIDs = resources[RESOURCE_TYPES.INTERNAL_RECIPIENT].map(
      internalRecipient => internalRecipient.id
    );
    const relevantDataStorageIDs = new Set<string>();
    const relevantDataSourceIDs = new Set<string>();
    const relevantInternalRecipientIDs = new Set<string>();
    const relevantExternalRecipientIDs = new Set<string>();
    for (const processData of processes) {
      processData.allInternalRecipientIds
        .filter(recipientId => allInternalRecipientIDs.includes(recipientId))
        .forEach(id => relevantInternalRecipientIDs.add(id));
      processData.allExternalRecipientIds
        .filter(recipientId => externalRecipientsDict[recipientId])
        .forEach(id => relevantExternalRecipientIDs.add(id));
      processData.allDataSourceIds
        .filter(dataSourceId => allDataLocationIDs.includes(dataSourceId))
        .forEach(id => relevantDataSourceIDs.add(id));
      processData.allDataStorageIds
        .filter(dataStorageId => allDataLocationIDs.includes(dataStorageId))
        .forEach(id => relevantDataStorageIDs.add(id));
    }
    setDataStorageIDs([...relevantDataStorageIDs]);
    setDataSourceIDs([...relevantDataSourceIDs]);
    setInternalRecipientIDs([...relevantInternalRecipientIDs]);
    setDsrExternalRecipientsIDs([...relevantExternalRecipientIDs]);
    addMetaDataToProcesses(processes);
  }, [
    i18n.language,
    externalRecipientsDict,
    departmentsLoaded,
    addMetaDataToProcesses,
    dataLocationsLoaded,
    dataLocations,
    resourcesLoaded,
    resources,
    paLoaded,
    erInitialized,
    processes
  ]);

  // translate lists keys
  const getExternalDataRecipientName = useCallback(
    (id: string) => {
      return externalRecipientsDict[id]?.name || id;
    },
    [externalRecipientsDict]
  );

  useEffect(() => {
    if (!(dataSubjectRequestInitialized && !pageInitialized && dataTypeCategoryPersonGroupList?.length > 0)) {
      return;
    }

    setTitle(dataSubjectRequest?.inputData?.title || "");
    if (dataSubjectRequest?.inputData?.dataTypeIds?.length) {
      const { personGroupKeys } = getPersonGroupAndDataCategoryKeysFromDataTypeIds(
        dataSubjectRequest.inputData.dataTypeIds,
        dataTypeCategoryPersonGroupList
      );
      setPersonGroups(personGroupKeys);
    } else {
      setPersonGroups(dataSubjectRequest?.inputData?.personGroups || []);
    }
    setPageInitialized(true);
  }, [
    dataSubjectRequest?.inputData?.dataTypeIds,
    dataSubjectRequest?.inputData?.dataTypeIds?.length,
    dataSubjectRequest?.inputData?.personGroups,
    dataSubjectRequest?.inputData?.title,
    dataSubjectRequestInitialized,
    dataTypeCategoryPersonGroupList,
    pageInitialized
  ]);

  const getDataLocationWithExternalRecipientCallback = useCallback(
    (id: string) => getDataLocationWithExternalRecipient(id, dataLocationsMap, t),
    [dataLocationsMap, t]
  );

  const dsrPersonGroupOnChange = useCallback(
    (selectedPersonGroupKeys: string[]) => {
      setPersonGroups(selectedPersonGroupKeys);
      updateBasicDataHook({
        personGroups: selectedPersonGroupKeys,
        dataTypeIds: selectedPersonGroupKeys
          .flatMap(personGroupKey => getDataTypesIdsOfPersonGroup(personGroupKey, dataTypeCategoryPersonGroupList))
          .filter(nonNull => nonNull)
      });
    },
    [updateBasicDataHook, dataTypeCategoryPersonGroupList]
  );

  const renderDLTags = useCallback(
    (value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
      value.map((option: string, index: number) => (
        <Chip
          deleteIcon={<> </>}
          label={getDataLocationWithExternalRecipientCallback(option)}
          {...getTagProps({ index })}
          key={index}
        />
      )),
    [getDataLocationWithExternalRecipientCallback]
  );

  const renderERTags = useCallback(
    (value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
      value.map((option: string, index: number) => (
        <Chip
          deleteIcon={<> </>}
          label={getExternalDataRecipientName(option)}
          {...getTagProps({ index })}
          key={index}
        />
      )),
    [getExternalDataRecipientName]
  );

  const iconLoading = paReloading ? <CircularProgress size={16} /> : undefined;

  // content for doc view
  const docViewContent = (
    <DocView header={title} pagination={<DataSubjectRequestsPageStepper />}>
      <QuestionnaireSubHeader text={t("relevant_data")} />
      <Box>
        <Box mt={3}>
          <Box>
            <Question questionId={"createdBy"} questionName={t("createdBy")} disabled={noWritePermission}>
              <DSRPersonGroupField
                disabled={noWritePermission}
                onBlur={undefined}
                values={personGroups}
                onChanges={dsrPersonGroupOnChange}
              />
            </Question>
          </Box>
          {personGroups && personGroups.length > 0 && processes && (
            <>
              <Box>
                <Question
                  questionId={"relevant_datasources"}
                  questionName={t("datasource")}
                  disabled={noWritePermission}
                >
                  <MultiAutocomplete
                    id="data_sources"
                    selected={dataSourceIDs}
                    renderTags={renderDLTags}
                    disabled={true}
                    options={dataLocationIds}
                    getOptionLabel={getDataLocationWithExternalRecipientCallback}
                    hasMultiSelect={true}
                    label={t("data_subject_requests_data_page:datasource")}
                    icon={iconLoading}
                  />
                </Question>
              </Box>

              <Box>
                <Question
                  questionId={"relevant_locations"}
                  questionName={t("data_subject_requests_data_page:datalocation")}
                  disabled={noWritePermission}
                >
                  <MultiAutocomplete
                    id="data_storages"
                    selected={dataStorageIDs || []}
                    renderTags={renderDLTags}
                    disabled={true}
                    options={dataLocationIds}
                    getOptionLabel={getDataLocationWithExternalRecipientCallback}
                    hasMultiSelect={true}
                    label={t("data_subject_requests_data_page:datalocation")}
                    icon={iconLoading}
                  />
                </Question>
              </Box>

              <Box>
                <Question
                  questionId={"dataRecipients:internal"}
                  questionName={t("dataRecipients:internal")}
                  disabled={noWritePermission}
                >
                  <ResourceField
                    disabled={true}
                    id="internal_recipients"
                    value={internalRecipientIDs}
                    resourceType={RESOURCE_TYPES.INTERNAL_RECIPIENT}
                    label={t("dataRecipients:internal")}
                    isNotDeletable={alwaysNotDeletable}
                    onChange={doNothing}
                    icon={iconLoading}
                  />
                </Question>
              </Box>
              <Box>
                <Question
                  questionId={"dataRecipients:external"}
                  questionName={t("dataRecipients:external")}
                  disabled={noWritePermission}
                >
                  <MultiAutocomplete
                    id="external_data_recipients"
                    renderTags={renderERTags}
                    disabled={true}
                    options={externalRecipientIds}
                    selected={dsrExternalRecipientsIDs}
                    getOptionLabel={getExternalDataRecipientName}
                    hasMultiSelect={true}
                    label={t("dataRecipients:external")}
                    icon={iconLoading}
                  />
                </Question>
              </Box>
              <Box pt={2} display="flex" alignItems="center">
                <QuestionnaireSubHeader text={t("processing_activities")} />
                {paReloading ? (
                  <Box mb={1} ml={2}>
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
              <Box pb={3}>
                <OverviewList>
                  {displayableProcesses.map(process => (
                    <React.Fragment key={process.id}>
                      <Divider />
                      <SimpleRowToNotUseLegacyRow process={process} />
                    </React.Fragment>
                  ))}
                </OverviewList>

                <OverviewListItemPagination
                  toolTipNext={t("processes_tab:next")}
                  toolTipPrevious={t("processes_tab:previous")}
                  list={listOfProcesses}
                  setDisplayableChunk={setDisplayableProcesses}
                  numberDescriptionText={t("processes_tab:outOf")}
                  numberOfPagesDescriptionText={t("processes_tab:processesPerPage")}
                  onPageChange={setPageIndex}
                  onItemsPerPageChange={setItemsPerPage}
                  page={pageIndex}
                  itemsPerPage={itemsPerPage}
                  setIndexOffset={undefined}
                />
                {displayableProcesses.length === 0 && (
                  <Box mt={4} marginLeft="5%">
                    {t("no_processes")}
                  </Box>
                )}
              </Box>
            </>
          )}
          <Box>
            <QuestionnaireSubHeader text={t("attachments")} />
          </Box>
          <Box mt={3}>
            <Box>
              <AttachmentsOverviewOBS
                disabled={noWritePermission}
                docId={documentId}
                setMeta={setMeta}
                category={"datasubjectrequests"}
              />
            </Box>
          </Box>
          <Box>
            <DataSubjectRequestsPageButtons leftArea={undefined} />
          </Box>
        </Box>
      </Box>
    </DocView>
  );

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          docId={documentId}
          tabs={metaviewTabIds}
          collection={COLLECTIONS.DATA_SUBJECT_REQUESTS}
          translationKey={"data_subject_requests_overview_general_page"}
        />
      }
    >
      {docViewContent}
    </DocMetaView>
  );
}

const metaviewTabIds: META_VIEW_TABS_TYPES[] = ["assistant", "comments", "todos"];

const alwaysNotDeletable = () => true;
const doNothing = () => {
  /* do nothing */
};

const getDataTypesIdsOfPersonGroup = (
  personGroupNameKey: string,
  dataTypeCategoryPersonGroupList: DataTypeTreeData
) => {
  if (!dataTypeCategoryPersonGroupList?.length) {
    return [];
  }

  const dataTypeIds: string[] = [];
  for (const personGroup of dataTypeCategoryPersonGroupList) {
    if (personGroup.personGroupKey === personGroupNameKey) {
      for (const category of personGroup.dataCategories) {
        for (const type of category.dataTypes.filter(type => !type.mergedIntoId)) {
          dataTypeIds.push(type.id);
        }
      }
    }
  }
  return dataTypeIds;
};

const SimpleRowToNotUseLegacyRow = ({
  process
}: {
  process: { id: string; name: string; meta: Record<string, React.ReactNode> };
}) => {
  const { setMeta } = useMetaView();
  const resetMeta = useCallback(() => {
    setMeta(null);
  }, [setMeta]);

  // update the new meta to be displayed in the meta view content
  const updateMeta = useCallback(() => {
    setMeta(process?.meta);
  }, [process?.meta, setMeta]);

  const statusIcon = useMemo(() => {
    const processStatus = process?.meta?.status ? process.meta.status : "";

    switch (processStatus) {
      case "rejected":
        return <ErrorIcon color="error" sx={{ p: 0.5 }} />;
      case "approved":
        return <DoneIcon color="success" sx={{ p: 0.5 }} />;
      default:
        return <WarningIcon color="warning" sx={{ p: 0.5 }} />;
    }
  }, [process?.meta?.status]);

  const onClick = useCallback(() => {
    if (process.id) {
      window.open(`/processes/${process.id}/recipients`);
    }
  }, [process]);
  return (
    <Button variant="text" onClick={onClick} onMouseEnter={updateMeta} onMouseLeave={resetMeta} fullWidth={true}>
      <Box display="flex" alignItems="center" height={40} mx={2} width="100%">
        <Box display="flex" alignItems="center" pr={3}>
          {statusIcon}
        </Box>
        <Typography variant="body1">{process.name}</Typography>
      </Box>
    </Button>
  );
};
