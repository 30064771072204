import useSWR, { useSWRConfig } from "swr";
import { useCallback, useEffect } from "react";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import { DefaultApi, SetAiRiskLevelRequest } from "../generated/process-service";
import { AiRiskLevel } from "app/pages/questionnaires/assessment-page/AiRiskAssessment";
import { useUpdateQueues } from "../../../hook/useUpdateQueues";
import { useTranslation } from "react-i18next";
import { isPAPermissionsKey } from "../../pages/questionnaires/usePAPermission";
import { mutatePAAiTechDoc } from "../../pages/questionnaires/aitechdoc-page/processAITechDocApi";
import { useProcessPage } from "../../contexts/process-page-context";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

export const useAiPrecheck = (args: { documentId: string }) => {
  const { i18n } = useTranslation();
  const { mutate: globalMutate } = useSWRConfig();

  const { mutate, data } = useSWR(
    args.documentId ? [`ai_precheck_${args.documentId}`, i18n.language] : null,
    async () => {
      return processClient
        .getAiPrecheckStatus(args.documentId, {
          headers: {
            "Accept-Language": i18n?.language || "en"
          }
        })
        .then(resp => resp.data);
    }
  );

  const { onBeforeProcessUpdate, setProcessMeta } = useProcessPage();
  useEffect(() => {
    if (data?.processMeta) {
      setProcessMeta(data?.processMeta);
    }
  }, [data?.processMeta, setProcessMeta]);

  const mutateRelevantSWR = useCallback(() => {
    globalMutate(isPAPermissionsKey);
    mutatePAAiTechDoc(args.documentId);
  }, [args.documentId, globalMutate]);

  const generalUpdateProcessor = useCallback(
    async (payload: SetAiRiskLevelRequest) => {
      onBeforeProcessUpdate(async () => {
        await mutate(
          async data => {
            await processClient.setAiRiskLevel(args.documentId, payload);
            return data;
          },
          {
            populateCache: false,
            optimisticData: (saved, displayed) => {
              if (!displayed) {
                return displayed || saved;
              }

              return {
                ...displayed,
                processPage: {
                  ...displayed.processPage,
                  ...payload
                }
              } as any;
            }
          }
        );
        mutateRelevantSWR();
      });
    },
    [onBeforeProcessUpdate, mutate, mutateRelevantSWR, args.documentId]
  );

  const { queueUpdates } = useUpdateQueues<SetAiRiskLevelRequest, SetAiRiskLevelRequest>({
    triggerUpdate: generalUpdateProcessor
  });

  const updateAIPreCheck = useCallback(
    async (processID: string, aiRiskLevel: AiRiskLevel) => {
      await mutate(async data => {
        await processClient.setAiRiskLevel(args.documentId, { aiRiskLevel });
        return data;
      });
      mutateRelevantSWR();
    },
    [args.documentId, mutateRelevantSWR, mutate]
  );

  const mutateAIPreCheck = useCallback(async () => {
    await mutate();
    mutateRelevantSWR();
  }, [mutate, mutateRelevantSWR]);

  return {
    ...(data?.processPage || {}),
    aiCheckRequired: !!data?.processPage?.aiCheckRequired,
    aiRiskLevel: data?.processPage?.aiRiskLevel || null,
    aiRiskLevelExplanation: data?.processPage?.aiRiskLevelExplanation || null,
    isAiRiskLevelOverriddenByUser: !!data?.processPage?.isAiRiskLevelOverriddenByUser,
    isAiRiskLevelExplanationOverriddenByUser: !!data?.processPage?.isAiRiskLevelExplanationOverriddenByUser,
    shownQuestions: data?.processPage?.shownQuestions || [],
    actions: {
      updateAIPreCheck,
      queueAIPreCheckUpdate: queueUpdates,
      mutateAIPreCheck
    }
  };
};
