import { Tooltip } from "@mui/material";
import { getDueDateType, OverviewDueDateProps } from "components/Overview/utils/overviewDueDate";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import OverviewBrickColorChip, { OverviewBrickColorChipColors } from "./OverviewBrickColorChip";

interface OverviewBrickDueDateProps {
  readonly date?: string | OverviewDueDateProps;
  readonly hovered?: boolean;
}

const getType = (date?: string | OverviewDueDateProps): OverviewDueDateProps => {
  if (typeof date === "string" || date instanceof String) {
    return getDueDateType(date as string);
  } else {
    return date as OverviewDueDateProps;
  }
};

const OverviewBrickDueDate = ({ date: dueDate, hovered }: OverviewBrickDueDateProps) => {
  const { t } = useTranslation();

  const { kind, days, date } = getType(dueDate);

  const label = useMemo(() => {
    if (kind === "days") {
      return t("tasks_page:days_left", { days: days });
    } else if (kind === "regular") {
      return date || "";
    }
    return t(`tasks_page:${kind}`);
  }, [kind, t, days, date]);

  const color = useMemo<OverviewBrickColorChipColors>(() => {
    if (kind === "noDue" || kind === "regular") {
      return "blue";
    } else if (kind === "tomorrow" || kind === "today" || kind === "days") {
      return "orange";
    } else if (kind === "overdue") {
      return "red";
    } else if (kind === "done") {
      return "green";
    }
    return "blue";
  }, [kind]);

  if (!dueDate) {
    return <></>;
  }

  return (
    <Tooltip title={label}>
      <OverviewBrickColorChip color={color} label={label} hovered={hovered} />
    </Tooltip>
  );
};

export default OverviewBrickDueDate;
