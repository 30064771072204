import { UserDTO } from "app/api/user/userApi";
import { getI18n } from "../i18n";

export const getUserName = (
  usersMap: Map<string, Pick<UserDTO, "id" | "email" | "firstName" | "lastName">>,
  userId: string
) => {
  const userData = getUserIfNotDeleted(usersMap, userId);
  if (typeof userData === "string") {
    return userData;
  }

  if (userData.firstName && userData.lastName) {
    return `${userData.firstName} ${userData.lastName}`;
  }

  return userData.email || "";
};

const getUserIfNotDeleted = (
  usersMap: Map<string, Pick<UserDTO, "id" | "email" | "firstName" | "lastName">>,
  userId: string
): Pick<UserDTO, "id" | "email" | "firstName" | "lastName"> | string => {
  if (!userId) {
    return "";
  }
  if (userId === "system") {
    return getI18n().t("common:system");
  }

  const userData = usersMap.get(userId);
  if (!userData) {
    return getI18n().t("manage-user-page:deleted_user");
  }

  return userData;
};

export const getUserNameEmail = (
  usersMap: Map<string, Pick<UserDTO, "id" | "email" | "firstName" | "lastName">>,
  userId: string
) => {
  const userData = getUserIfNotDeleted(usersMap, userId);
  if (typeof userData === "string") {
    return userData;
  }

  return getUserNameEmailRaw(userData);
};

export const getUserNameEmailRaw = (user: Pick<UserDTO, "id" | "email" | "firstName" | "lastName">) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName} - ${user.email}`;
  }

  return user.email || "";
};

export const getUserFirstName = (
  usersMap: Map<string, Pick<UserDTO, "id" | "email" | "firstName" | "lastName">>,
  userId: string
) => {
  const userData = getUserIfNotDeleted(usersMap, userId);
  if (typeof userData === "string") {
    return userData;
  }

  return userData.firstName || userData.email || "";
};
