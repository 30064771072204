import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { isAxiosErrorWithCode } from "./axios/axiosErrorHandler";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.businessProcessUrl}/api/businessprocess`
});

export async function updateBusinessProcessApi(
  id: string,
  {
    title,
    orgUnitId,
    summaryDescription,
    dueAt,
    implementedAt,
    endAt,
    detailDescription,
    assigneeUID,
    labelIds,
    furtherOrgUnitIds
  }: BusinessProcessUpdateDTO = {}
): Promise<BusinessProcessDTO> {
  const response = await axiosInstance.patch<BusinessProcessDTO>(`/` + id, {
    title,
    orgUnitId,
    summaryDescription,
    dueAt,
    implementedAt,
    endAt,
    detailDescription,
    assigneeUID,
    labelIds,
    furtherOrgUnitIds
  });

  return response.data;
}

export const getBusinessProcessesApi = async (
  filter: BusinessProcessesFilterParameter
): Promise<{ readonly businessprocesses: BusinessProcessWithPermission[] }> => {
  const response = await axiosInstance.get(`/`, { params: filter });
  return response.data || null;
};

export const deleteBusinessProcessApi = async (businessProcessId: string) => {
  await axiosInstance.delete(`/` + businessProcessId);
};

export async function getBusinessProcessByIdFromApi(id: string): Promise<BusinessProcessWithPermission | null> {
  try {
    const response = await axiosInstance.get(`/${id}`).catch(error => error);
    return response ? response.data : null;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      // 404 is not found, just return null to determine that the data breach does not exists
      return null;
    }
    throw error;
  }
}

export interface BusinessProcessDTO {
  readonly id: string;
  readonly tenantId: string;
  readonly title: string | null;
  readonly orgUnitId: string | null;
  readonly summaryDescription: string | null;
  readonly detailDescription: string | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly implementedAt: Date | null;
  readonly endAt: Date | null;
  readonly dueAt: Date | null;
  readonly creatorUID: string;
  readonly assigneeUID: string | null;
  readonly labelIds: string[];
  readonly furtherOrgUnitIds: string[];
}

export interface BusinessProcessPayload {
  readonly id: string;
  readonly tenantId: string;
  readonly title: string | null;
  readonly orgUnitId: string | null;
  readonly summaryDescription: string | null;
  readonly detailDescription: string | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly implementedAt: Date | null;
  readonly endAt: Date | null;
  readonly dueAt: Date | null;
  readonly creatorUID: string;
  readonly assigneeUID: string | null;
  readonly labels: string[];
  readonly furtherOrgUnitIds: string[];
}

export interface BusinessProcessWithPermission extends BusinessProcessPayload {
  readonly permission: "read" | "write";
}

export type BusinessProcessUpdateDTO = Partial<Omit<BusinessProcessDTO, "id" | "tenantId">>;

export interface BusinessProcessesFilterParameter {
  readonly businessProcessIds?: string[];
  readonly orgUnitIds?: string[];
}
