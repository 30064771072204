import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResourceField } from "components/ResourceField";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useUserDepartments } from "app/contexts/department-context";
import { useResources } from "app/contexts/resource-context";

interface DSRRequestTypeFieldProps {
  onFocus: () => void;
  values: string[];
  onChanges: (values: string | string[] | null) => void;
  orgUnitId: string;
  disabled?: boolean;
  furtherOrgUnitIds?: string[];
}

export const DSRRequestTypeField: React.FC<DSRRequestTypeFieldProps> = ({
  onFocus,
  values = [],
  onChanges,
  orgUnitId,
  disabled = false,
  furtherOrgUnitIds = []
}) => {
  const { t } = useTranslation("data_subject_requests_overview_general_page");
  const { departments } = useUserDepartments();
  const { resources } = useResources();
  const requestTypes = resources[RESOURCE_TYPES.DSR_REQUEST_TYPE];
  const [whitelistedResourceTypeIDs, setWhitelistedResourceTypeIDs] = useState<string[]>([]);

  useEffect(() => {
    if (!orgUnitId) {
      setWhitelistedResourceTypeIDs([]);
      return;
    }

    setWhitelistedResourceTypeIDs((requestTypes || []).map(resource => resource.id));
  }, [departments, orgUnitId, requestTypes]);

  const docOrgUnitIds = useMemo(() => [orgUnitId, ...furtherOrgUnitIds], [orgUnitId, furtherOrgUnitIds]);

  return (
    <ResourceField
      id="request_type"
      value={values}
      onChange={onChanges}
      onFocus={onFocus}
      resourceType={RESOURCE_TYPES.DSR_REQUEST_TYPE}
      label={t("request_type")}
      whitelistedIDs={whitelistedResourceTypeIDs}
      disabled={disabled}
      docOrgUnitIds={docOrgUnitIds}
    />
  );
};
