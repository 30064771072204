import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BusinessProcessWithPermission } from "../../api/businessProcessApi";
import { useDocumentName } from "../../contexts/document-name-context";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { COLLECTIONS } from "../../collections";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import { BusinessprocessPageButtons, BusinessprocessPageStepper } from "./BusinessprocessPagination";
import { BusinessProcessGeneral } from "./pages/BPGeneral";
import { BusinessProcessProcessDescription } from "./pages/BPProcessDescription";
import DocumentNotFoundPage from "../shared/DocumentNotFound/DocumentNotFound";
import { Box } from "@mui/material";
import { getBusinessProcessById, updateBusinessProcess } from "app/handlers/businessprocessHandler";

export const BusinessprocessPage = () => {
  const { id, page } = useParams();
  const [docName, setDocName] = useState("");
  const { setDocumentName } = useDocumentName();
  const [businessProcess, setBusinessProcess] = useState<BusinessProcessWithPermission | null>(null);
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [readOnly, setReadOnly] = useState(true);

  const reloadBusinessProcessCallback = useCallback(async () => {
    if (!id) {
      setDocumentNotFound(true);
      return;
    }

    const data: BusinessProcessWithPermission | null = await getBusinessProcessById(id);
    if (!data) {
      setDocumentNotFound(true);
      return;
    }
    setDocumentNotFound(false);
    setBusinessProcess(data);
    setDocumentName(data.title || "");
    setDocName(data.title || "");
    setReadOnly(data.permission === "write" ? false : true);
    setIsLoading(false);
  }, [id, setDocumentName]);

  useEffect(() => {
    reloadBusinessProcessCallback();
  }, [reloadBusinessProcessCallback]);

  /* TITLE */
  const onTitleChange = useCallback(
    async title => {
      if (!id) return;
      await updateBusinessProcess(id, { title });
      setDocumentName(title);
      await reloadBusinessProcessCallback();
    },
    [id, reloadBusinessProcessCallback, setDocumentName]
  );

  if (documentNotFound) {
    return <DocumentNotFoundPage collection={COLLECTIONS.BUSINESS_PROCESS} />;
  }
  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          tabs={metaViewTabIds}
          docName={docName}
          pageId={page || "general"}
          docId={id}
          collection={COLLECTIONS.BUSINESS_PROCESS}
          translationKey={"businessprocess_overview"}
        />
      }
      loading={isLoading}
    >
      <BusinessprocessDocView docName={docName} onHeaderChange={onTitleChange}>
        <Box>
          <Box mt={4} mb={4}>
            {page === "general" && (
              <BusinessProcessGeneral
                businessProcess={businessProcess}
                onUpdate={reloadBusinessProcessCallback}
                noWritePermission={readOnly}
              />
            )}
            {page === "process-description" && (
              <BusinessProcessProcessDescription
                businessProcess={businessProcess}
                onUpdate={reloadBusinessProcessCallback}
                noWritePermission={readOnly}
              />
            )}
          </Box>
          <Box>
            <BusinessprocessPageButtons />
          </Box>
          <Box />
        </Box>
      </BusinessprocessDocView>
    </DocMetaView>
  );
};

export const BusinessprocessDocView = ({
  children,
  docName,
  onHeaderChange
}: {
  children: React.ReactNode;
  docName: string;
  onHeaderChange: (event?: any) => void;
}) => {
  return (
    <DocView header={docName} onHeaderChange={onHeaderChange} pagination={<BusinessprocessPageStepper />}>
      {children}
    </DocView>
  );
};

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];
