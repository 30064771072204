import { addToSeenItemsOfUser, removeFromSeenItemsOfUser } from "app/handlers/userAndTenant/userSeenItemsHandler";
import { ASSIGNABLE_USER_FIELD, SingleTenantNewUser, SingleTenantUpdateUser, UserDTO } from "app/api/user/userApi";
import { getTenantInformation, Tenant } from "app/handlers/tenantHandler";
import {
  EmailNotificationConfig,
  getEmailNotificationConfig,
  updateEmailNotificationConfig
} from "./userEmailNotificationHandler";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../authentication/authentication-context";
import { createUser, deleteUser, getUserData, getUsers, isCaralegalTenantAdmin, updateUser } from "./userHandler";
import { updateMeApi } from "app/api/user/userMeApi";
import { getUserFirstName, getUserName, getUserNameEmail, getUserNameEmailRaw } from "../../utils/get-user-name";
import { withAbortController } from "../../api/axios/axiosErrorHandler";
import { FEATURES } from "app/features";
import useSWR, { useSWRConfig } from "swr";
import { NotLoggedInError } from "../authentication/authenticationError";
import { isUnseenSwrKey } from "../../pages/shared/Sidebar/unseenSwrKey";

export const EXTERNAL_USER_ROLE_NAME = "externalUser";

export interface UserAndTenantDataContextType {
  readonly loading: boolean;
  readonly nonRootAdminTenantUsers: UserDTO[];
  readonly tenantData: Tenant | null;
  getUserNameHook: (id: string) => string;
  getUserFirstNameHook: (id: string) => string;
  getUserNameEmailHook: (id: string) => string;
  getUserHook: (userId: string) => UserDTO | null;
  createUserHook: (input: SingleTenantNewUser) => Promise<string>;
  updateUserHook: (userId: string, input: SingleTenantUpdateUser) => Promise<void>;
  deleteUserHook: (userId: string, replacementUserId?: string) => Promise<void>;
  getLatestUserHook: (userId: string) => Promise<UserDTO | null>;
  updateMyNameHook: (firstName?: string, lastName?: string) => Promise<void>;
  addToSeenItemsOfUserHook: (collection: string, newItemId: string) => Promise<void>;
  loadSeenItemsOfUserHook: () => Promise<void>;
  removeFromSeenItemsOfUserHook: (userId: string, collection: string, newItemId: string) => Promise<void>;
  getEmailNotificationConfigHook: () => Promise<EmailNotificationConfig>;
  updateEmailNotificationConfigHook: (input: Partial<EmailNotificationConfig>) => Promise<void>;
  getUsersByAssignableFieldHook: (assignmentType?: ASSIGNABLE_USER_FIELD) => Promise<UserDTO[]>;
  reloadTenantUsers: () => void;
  isExternalUser: (userId: string) => boolean;
}

export const UserAndTenantDataContext = createContext<UserAndTenantDataContextType>({
  loading: true,
  nonRootAdminTenantUsers: [],
  tenantData: null,
  getUserNameHook: id => "",
  getUserFirstNameHook: id => "",
  getUserNameEmailHook: id => "",
  createUserHook: async () => {
    throw new Error("Not implemented");
  },
  updateUserHook: async () => {
    throw new Error("Not implemented");
  },
  deleteUserHook: async () => {
    throw new Error("Not implemented");
  },
  getUserHook: () => {
    throw new Error("Not implemented");
  },
  getLatestUserHook: async () => {
    throw new Error("Not implemented");
  },
  updateMyNameHook: async () => {
    throw new Error("Not implemented");
  },
  addToSeenItemsOfUserHook: async () => {
    throw new Error("Not implemented");
  },
  removeFromSeenItemsOfUserHook: async () => {
    throw new Error("Not implemented");
  },
  getEmailNotificationConfigHook: async () => {
    throw new Error("Not implemented");
  },
  updateEmailNotificationConfigHook: async () => {
    throw new Error("Not implemented");
  },
  loadSeenItemsOfUserHook: async () => {
    throw new Error("Not implemented");
  },
  getUsersByAssignableFieldHook: async () => {
    throw new Error("Not implemented");
  },
  reloadTenantUsers: async () => {
    throw new Error("Not implemented");
  },
  isExternalUser: () => {
    throw new Error("Not implemented");
  }
});

export const UserAndTenantDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useAuthentication();
  const [tenantUsersWithTenantAdminAndExternals, setTenantUsersWithTenantAdminAndExternals] = useState<UserDTO[]>([]);
  const [tenantUsersMap, setTenantUsersMap] = useState<Map<string, UserDTO>>(new Map());
  const [users, setUsers] = useState<UserDTO[]>([]);

  const [usersWithoutExternals, setUsersWithoutExternals] = useState<UserDTO[]>([]);
  const [usersWithoutExternalsByAssignableFields, setUsersWithoutExternalsByAssignableFields] = useState<
    Map<string, UserDTO[]>
  >(new Map());
  const { data: tenantData } = useSWR<Tenant | null>(auth?.tenantId || null, (tenantId: string) =>
    getTenantInformation(tenantId)
  );
  const [loading, setLoading] = useState(true);

  const [abortReloadUserFn, setAbortReloadUserFn] = useState<() => void>(() => () => {
    /* empty */
  });
  const reloadTenantUsers = useCallback(() => {
    setAbortReloadUserFn(lastAbortReloadUser => {
      lastAbortReloadUser();
      return withAbortController({
        executeFn: abortController => getUsers({ abortController }).then(setTenantUsersWithTenantAdminAndExternals)
      });
    });
  }, []);

  useEffect(() => {
    const tenantUsersMap: Map<string, UserDTO> = new Map();
    const users: UserDTO[] = [];
    const usersWithoutExternals: UserDTO[] = [];
    const usersWithoutExternalsByAssignableFields: Map<string, UserDTO[]> = new Map();

    const sortedTenantUsers = tenantUsersWithTenantAdminAndExternals
      .map(it => ({
        ...it,
        sortName: getUserNameEmailRaw(it)
      }))
      .sort((a, b) => a.sortName.localeCompare(b.sortName))
      .map(({ sortName, ...originalDTO }) => originalDTO);

    for (const user of sortedTenantUsers) {
      tenantUsersMap.set(user.id || "", user);

      const isTenantAdminAndNotSelf = isCaralegalTenantAdmin(user) && user.id !== auth?.uid;
      if (isTenantAdminAndNotSelf) {
        continue;
      }
      users.push(user);

      const isExternalUser = user.userRole === EXTERNAL_USER_ROLE_NAME;
      if (isExternalUser) {
        continue;
      }
      usersWithoutExternals.push(user);
      for (const assignableFields of user.assignableFields || []) {
        const existingUsers = usersWithoutExternalsByAssignableFields.get(assignableFields) || [];
        existingUsers.push(user);
        usersWithoutExternalsByAssignableFields.set(assignableFields, existingUsers);
      }
    }

    setTenantUsersMap(tenantUsersMap);
    setUsers(users);
    setUsersWithoutExternals(usersWithoutExternals);
    setUsersWithoutExternalsByAssignableFields(usersWithoutExternalsByAssignableFields);
  }, [tenantUsersWithTenantAdminAndExternals, auth?.uid]);

  useEffect(() => {
    if (!auth?.tenantId || !auth?.uid) {
      return;
    }

    reloadTenantUsers();

    setLoading(false);
  }, [auth?.uid, auth?.tenantId, reloadTenantUsers]);

  const withReloadUsers = useCallback(
    async (changerFn: () => Promise<any>) => {
      setLoading(true);
      const result = await changerFn().catch((error: any) => {
        setLoading(false);
        throw error;
      });
      reloadTenantUsers();
      setLoading(false);
      return result;
    },
    [setLoading, reloadTenantUsers]
  );

  const getUsersByAssignableFieldHook = useCallback(
    async (assignmentType?: ASSIGNABLE_USER_FIELD) => {
      if (!assignmentType) {
        return usersWithoutExternals;
      }

      const users = usersWithoutExternalsByAssignableFields.get(assignmentType);
      return users || [];
    },
    [usersWithoutExternals, usersWithoutExternalsByAssignableFields]
  );

  const createUserHook = useCallback(
    (input: SingleTenantNewUser) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadUsers(() => createUser(auth?.tenantId, input));
    },
    [withReloadUsers, auth?.tenantId]
  );

  const updateUserHook = useCallback(
    (userId: string, input: SingleTenantUpdateUser) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadUsers(() => updateUser(userId, input));
    },
    [withReloadUsers, auth?.tenantId]
  );
  const deleteUserHook = useCallback(
    (userId: string, replacementUserId?: string) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadUsers(() => deleteUser(userId, replacementUserId));
    },
    [withReloadUsers, auth?.tenantId]
  );

  const updateMyNameHook = useCallback(
    (firstName?: string, lastName?: string) => {
      return withReloadUsers(() =>
        updateMeApi({
          firstName,
          lastName
        })
      );
    },
    [withReloadUsers]
  );
  const { mutate: globalMutate } = useSWRConfig();
  const mutateSeenItems = useCallback(() => {
    return globalMutate(isUnseenSwrKey);
  }, [globalMutate]);

  const addToSeenItemsOfUserHook = useCallback(
    async (collection: string, newItemId: string) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      await addToSeenItemsOfUser(auth.tenantId, auth.uid, collection, newItemId);
      await mutateSeenItems();
    },
    [auth?.tenantId, auth?.uid, mutateSeenItems]
  );

  const loadSeenItemsOfUserHook = useCallback(async () => {
    if (!auth?.tenantId) {
      throw new NotLoggedInError();
    }
    await mutateSeenItems();
  }, [auth?.tenantId, mutateSeenItems]);

  const removeFromSeenItemsOfUserHook = useCallback(
    async (userId: string, collection: string, itemId: string) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      await removeFromSeenItemsOfUser(auth.tenantId, userId, collection, itemId);
      await mutateSeenItems();
    },
    [auth?.tenantId, mutateSeenItems]
  );
  const getEmailNotificationConfigHook = useCallback(async () => {
    if (!auth?.tenantId) {
      throw new NotLoggedInError();
    }

    return getEmailNotificationConfig(auth.tenantId, auth.uid);
  }, [auth?.tenantId, auth?.uid]);

  const updateEmailNotificationConfigHook = useCallback(
    async (configData: Partial<EmailNotificationConfig>) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }

      await updateEmailNotificationConfig(auth.tenantId, auth.uid, configData);
    },
    [auth?.tenantId, auth?.uid]
  );

  const getUserHook = useCallback(
    (id: string) => {
      return tenantUsersMap.get(id) || null;
    },
    [tenantUsersMap]
  );

  const getUserNameHook = useCallback(
    (id: string) => {
      return getUserName(tenantUsersMap, id);
    },
    [tenantUsersMap]
  );

  const getUserFirstNameHook = useCallback(
    (userId: string) => {
      return getUserFirstName(tenantUsersMap, userId);
    },
    [tenantUsersMap]
  );

  const getUserNameEmailHook = useCallback(
    (id: string) => {
      return getUserNameEmail(tenantUsersMap, id);
    },
    [tenantUsersMap]
  );

  const getLatestUserHook = useCallback(
    (id: string) => {
      const groupFeaturesEnabled = tenantData?.features.includes(FEATURES.USERGROUPS_FEATURES);
      return getUserData(id, groupFeaturesEnabled ?? false);
    },
    [tenantData?.features]
  );

  const isExternalUser = useCallback(
    (userId: string) => {
      const user = getUserHook(userId);
      if (!user) {
        return false;
      }

      return user.userRole === EXTERNAL_USER_ROLE_NAME;
    },
    [getUserHook]
  );

  return (
    <UserAndTenantDataContext.Provider
      value={{
        loading,
        nonRootAdminTenantUsers: users,
        tenantData: tenantData ?? null,
        getUserNameHook,
        getUserFirstNameHook,
        getUserNameEmailHook,
        createUserHook,
        updateUserHook,
        deleteUserHook,
        getUserHook,
        getLatestUserHook,
        updateMyNameHook,
        addToSeenItemsOfUserHook,
        loadSeenItemsOfUserHook,
        removeFromSeenItemsOfUserHook,
        getEmailNotificationConfigHook,
        updateEmailNotificationConfigHook,
        getUsersByAssignableFieldHook,
        reloadTenantUsers,
        isExternalUser
      }}
    >
      {children}
    </UserAndTenantDataContext.Provider>
  );
};

export const useUserAndTenantData = () => useContext(UserAndTenantDataContext);
