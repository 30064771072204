import { OverviewResult, OverviewResultDecorator } from "../overviewBaseController";
import { getUsers } from "../../../../app/handlers/userAndTenant/userHandler";
import { UserDTO } from "../../../../app/api/user/userApi";

export const usersDecorator: OverviewResultDecorator<{ readonly _tenantUsersMap: Map<string, UserDTO> }> = {
  async decorate(overviewResult: OverviewResult): Promise<{ readonly _tenantUsersMap: Map<string, UserDTO> }> {
    if (overviewResult._tenantUsersMap) {
      return { _tenantUsersMap: overviewResult._tenantUsersMap };
    }

    const users = await getUsers({
      params: {
        slim: true
      }
    });
    const usersMap: Map<string, UserDTO> = new Map((users || []).map(user => [user.id || "", user]));
    return {
      _tenantUsersMap: usersMap
    };
  },
  type: "prefetch"
};
