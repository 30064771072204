import { useCallback, useEffect, useState } from "react";
import { downloadDocumentFileApi } from "../../api/file-storage/documentApi";
import { useNavigate, useParams } from "react-router-dom";
import DocumentNotFoundPage from "../shared/DocumentNotFound/DocumentNotFound";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import DocView from "../../../components/DocView/DocView";

export const DownloadFilePage = () => {
  const { t } = useTranslation("documentCenter");

  const params = useParams();
  const page = params.page;
  const navigate = useNavigate();
  const [canDownloadFile, setCanDownloadFile] = useState(true);

  const downloadFile = useCallback(async () => {
    if (page) {
      try {
        await downloadDocumentFileApi({ filePath: page });
        setTimeout(() => {
          window.close();
          navigate("/document-center"); // window.close only works when tap opened in same session -> will navigate to document center after download if link opened from outside the app
        }, 3000);
      } catch (error) {
        console.log(error);
        setCanDownloadFile(false);
      }
    }
  }, [navigate, page]);

  useEffect(() => {
    downloadFile();
  }, [downloadFile, navigate, page]);

  if (!canDownloadFile) {
    return <DocumentNotFoundPage collection={"documents"} />;
  }

  return (
    <DocMetaView
      docViewContent={
        <DocView>
          <Box mt={10}>
            <Typography variant={"h4"}> {t("fileDownload")}</Typography>
          </Box>
        </DocView>
      }
      metaViewContent={<MetaView> </MetaView>}
    />
  );
};
