import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import DocView from "../../../components/DocView/DocView";
import React from "react";
import { useAuthentication } from "../../handlers/authentication/authentication-context";
import { Box } from "@mui/material";
import TextBody2 from "../../../components/TextBody2/TextBody2";

export const DebugSettings = () => {
  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"debug_settings"} />}>
      <DocView header="Debug Mode">
        <Box>
          <CustomExportForCS />
        </Box>
      </DocView>
    </DocMetaView>
  );
};

export const CustomExportForCS = () => {
  const { auth } = useAuthentication();

  if (!auth?.permissions?.includes("super_admin")) {
    return <></>;
  }

  return (
    <Box>
      <TextBody2 text={"No experimental feature here."} />
    </Box>
  );
};
