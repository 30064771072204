import React, { useCallback, useEffect, useMemo, useState } from "react";
import Row from "components/Row/Row";
import InlineConfirmation from "components/InlineConfirmation/InlineConfirmation";
import { useTranslation } from "react-i18next";
import OverviewList from "components/LegacyOverview/OverviewList";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { ALLOWED_FILE_EXTENSIONS, getFileIcon, normaliseFileNameExtension } from "./fileAttachmentUtils";
import OverviewListItemFileUploadBar from "components/OverviewListItemFileUploadBar/OverviewListItemFileUploadBar";
import OverviewListItemPagination from "components/LegacyOverview/OverviewListItemPagination";
import InlineIcons from "../../../../components/InlineIcons/InlineIcons";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import {
  deleteAttachmentApi,
  downloadAttachmentApi,
  getAllAttachmentsApi,
  uploadAttachmentApi
} from "app/api/file-storage/attachmentsApi";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { isEqual } from "lodash-es";
import DateDisplay from "components/DateDisplay.tsx";

const useStyles = makeStyles(theme => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  searchField: {
    marginBottom: 7
  }
}));
export const AttachmentsOverviewOBS = ({
  setMeta,
  docId,
  getStyle,
  category,
  allowedFileExtensions,
  onPendingFiles,
  onSuccessfulFileDelete,
  onSuccessfulFileUpload,
  disabled,
  withPrefix,
  hidePagination,
  customUploadButton,
  showUploadButtonBelow,
  showDividers
}) => {
  const classes = useStyles();

  const { t } = useTranslation("file_upload");
  const { auth } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const [pageIndex, setPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { catchAsSnackbar } = useErrorSnackbar();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [localFiles, setLocalFiles] = useState([]);

  const nonUploadedFiles = useMemo(() => {
    return localFiles.filter(localFile => !uploadedFiles.some(uploadedFile => uploadedFile.name === localFile.name));
  }, [localFiles, uploadedFiles]);
  useEffect(() => {
    onPendingFiles([...nonUploadedFiles]);
  }, [nonUploadedFiles]);

  const currentFiles = useMemo(() => {
    return [...nonUploadedFiles, ...uploadedFiles];
  }, [uploadedFiles, nonUploadedFiles]);

  const [uploadStatus, setUploadStatus] = useState("idle");
  const [successfulDelete, setSuccessfulDelete] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [displayableAttachments, setDisplayableAttachments] = useState([]);

  const fileUploadSubFolderName = useMemo(() => category + "-" + docId, [category, docId]);
  const allowedFileTypesToUpload = useMemo(() => {
    return allowedFileExtensions ?? Object.values(ALLOWED_FILE_EXTENSIONS);
  }, [allowedFileExtensions]);

  const inlineIcons = useMemo(() => {
    return disabled
      ? []
      : [
          {
            icon: "DeleteIcon",
            toolTipTitle: t("delete")
          }
        ];
  }, [t, disabled]);

  const fileHasAllowedExtension = useCallback(
    fileName => allowedFileTypesToUpload.some(extension => fileName.endsWith(extension)),
    [allowedFileTypesToUpload]
  );

  const loadUploadedFiles = useCallback(async () => {
    const data = await getAllAttachmentsApi({ folderName: fileUploadSubFolderName, withPrefix });
    const dataSorted = data.sort((a, b) => b.timeCreated.localeCompare(a.timeCreated)); // ISO8601 strings
    if (!isEqual(dataSorted, uploadedFiles)) {
      setUploadedFiles(dataSorted);
      if (hidePagination) {
        setDisplayableAttachments(dataSorted);
      }
    }
  }, [fileUploadSubFolderName, setUploadedFiles, uploadedFiles]);

  const uploadFile = useCallback(
    file => {
      const uniqueFileName = Date.now() + "_" + file.name.replace(/\s+/g, "");
      if (!fileHasAllowedExtension(uniqueFileName)) {
        throw new Error("invalid-extension");
      }

      return uploadAttachmentApi({ file, folderName: fileUploadSubFolderName, path: "" });
    },
    [fileUploadSubFolderName, fileHasAllowedExtension]
  );

  const uploadFileIgnoringExtensionError = useCallback(
    async file => {
      try {
        await uploadFile(file);
      } catch (error) {
        if (error.message === "invalid-extension") {
          enqueueSnackbar(`${t("error_messages:not_allowed_file_extension")} ${file.name}`, {
            variant: "error"
          });
        } else {
          throw error;
        }
      }
    },
    [uploadFile, enqueueSnackbar, t]
  );

  const uploadFiles = useCallback(
    async files => {
      setUploadStatus("uploading");
      try {
        await Promise.all(files.map(uploadFileIgnoringExtensionError));
        setSuccessfulUpload(true);
        onSuccessfulFileUpload();
        setLocalFiles([]);
      } catch (error) {
        catchAsSnackbar(`failed to upload files.`)(error);
      } finally {
        setUploadStatus("idle");
      }
    },
    [uploadFileIgnoringExtensionError, onSuccessfulFileUpload, catchAsSnackbar]
  );

  const onSelectFilesToUpload = useCallback(files => {
    const filesWithFormattedNames = files.map(file => {
      const formattedFileName = normaliseFileNameExtension(file.name);
      return new File([file], formattedFileName);
    });

    setLocalFiles(existingLocalFiles => [...existingLocalFiles, ...filesWithFormattedNames]);
  }, []);

  useEffect(() => {
    if (!docId) {
      return;
    }
    if (nonUploadedFiles.length) {
      uploadFiles(nonUploadedFiles);
    }
  }, [docId, nonUploadedFiles]);

  const deleteFile = async file => {
    setUploadStatus("deleting");
    try {
      setLocalFiles(existingLocalFiles => existingLocalFiles.filter(localFile => localFile.name !== file.name));
      const folderName = withPrefix ? `${fileUploadSubFolderName}${file.uploadedBy}` : fileUploadSubFolderName;
      await deleteAttachmentApi({ fileName: file.name, folderName });
      // on success
      setSuccessfulDelete(true);
      setUploadStatus("idle");
      onSuccessfulFileDelete();
    } catch (e) {
      catchAsSnackbar(`Failed to delete file: ${file.name}}`);
    }
  };

  useEffect(() => {
    if (auth.uid && docId) {
      loadUploadedFiles();
    }
  }, [auth.uid, docId, loadUploadedFiles]);

  useEffect(() => {
    if (successfulDelete) {
      setMeta(null);
      setSuccessfulDelete(false);
      loadUploadedFiles();
    }
  }, [successfulDelete]);

  useEffect(() => {
    if (successfulUpload) {
      setSuccessfulUpload(false);
      loadUploadedFiles();
    }
  }, [loadUploadedFiles, successfulUpload]);

  return (
    <>
      <OverviewList>
        {showDividers && <Divider className={getStyle ? getStyle("attachments") : ""} />}
        {!disabled && !showUploadButtonBelow && (
          <OverviewListItemFileUploadBar
            title={t("uploadNewFile")}
            allowedFileTypes={allowedFileTypesToUpload}
            onSelectFilesForUpload={onSelectFilesToUpload}
            uploadStatus={uploadStatus}
            disabled={disabled}
            customUploadButton={customUploadButton}
            showDividers={showDividers}
          />
        )}
        {withPrefix && uploadedFiles.length <= 0 && (
          <Grid item style={{ paddingBottom: "10px", paddingTop: "10px" }}>
            <Typography variant="h5" component="span" style={{ fontWeight: 400 }}>
              {t("metaview_groupdialog:no_group_attachments")}
            </Typography>
          </Grid>
        )}
        {displayableAttachments.map((file, index) => {
          return (
            <React.Fragment key={`rowfragment-${index}-${file.name}`}>
              <Row
                index={index}
                determineStatusIcon={() => getFileIcon(file.name)}
                rowDataItemText={file.name}
                key={`row-${index}-${file.name}`}
                callOnMouseOver={() => {
                  const { timeCreated, ...meta } = file;
                  if (file.uploadedBy && timeCreated) {
                    setMeta({
                      ...meta,
                      uploadedBy: <AutomaticUserDataDisplay uid={file.uploadedBy} />,
                      timeCreated: <DateDisplay timestamp={new Date(timeCreated)} />
                    });
                  } else {
                    setMeta(null);
                  }
                }}
                callOnMouseLeave={() => {
                  setMeta(null);
                }}
                onClick={() => {
                  setUploadStatus("downloading");
                  const folderName = withPrefix
                    ? `${fileUploadSubFolderName}${file.uploadedBy}`
                    : fileUploadSubFolderName;
                  downloadAttachmentApi({
                    fileName: file.name,
                    folderName
                  }).finally(() => setUploadStatus("idle"));
                }}
                inlineIcons={inlineIcons}
                successfulDelete={successfulDelete}
                //Edit is currently disabled but will soon be implemented, then the following can be uncommented:
                //onEdit={(_, name) => updateFileName(file, name)}
                //editToolTipText={t("inline_icons_tooltips:editTooltip")}
                cancelToolTipText={t("inline_icons_tooltips:cancel_tooltip")}
                inlineDeletion={
                  <InlineConfirmation
                    startIcon={
                      <InlineIcons
                        iconSize={"medium"}
                        data={[
                          {
                            icon: "InfoIcon",
                            toolTipTitle: t("inline_icons_tooltips:deleteTooltip")
                          }
                        ]}
                      />
                    }
                    key={`InlineConfirmation-${index}-${file.name}`}
                    index={index}
                    value={file.name}
                    onConfirm={() => {
                      if (disabled) {
                        return;
                      }

                      return deleteFile(file);
                    }}
                    confirmToolTip={t("inline_icons_tooltips:deleteTooltip")}
                    cancelToolTip={t("inline_icons_tooltips:cancel_tooltip")}
                    confirmActionText={t("inline_icons_tooltips:confirmDelete")}
                  />
                }
              />
              {showDividers && <Divider key={`Divider-${index}-${file.name}`} />}
            </React.Fragment>
          );
        })}
        {!disabled && showUploadButtonBelow && (
          <OverviewListItemFileUploadBar
            title={t("uploadNewFile")}
            allowedFileTypes={allowedFileTypesToUpload}
            onSelectFilesForUpload={onSelectFilesToUpload}
            uploadStatus={uploadStatus}
            disabled={disabled}
            customUploadButton={customUploadButton}
            showDividers={showDividers}
          />
        )}
        {!hidePagination && (
          <OverviewListItemPagination
            toolTipNext={t("next")}
            toolTipPrevious={t("previous")}
            list={currentFiles}
            setDisplayableChunk={setDisplayableAttachments}
            numberDescriptionText={t("outOf")}
            numberOfPagesDescriptionText={t("filesPerPage")}
            onPageChange={setPageIndex}
            onItemsPerPageChange={setItemsPerPage}
            page={pageIndex}
            itemsPerPage={itemsPerPage}
          />
        )}
      </OverviewList>
    </>
  );
};

AttachmentsOverviewOBS.propTypes = {
  docId: PropTypes.string,
  category: PropTypes.string.isRequired,

  setMeta: PropTypes.func,
  getStyle: PropTypes.func,
  deleteAllFiles: PropTypes.func,
  onPendingFiles: PropTypes.func,
  onSuccessfulFileUpload: PropTypes.func,
  onSuccessfulFileDelete: PropTypes.func,

  allowedFileExtensions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ALLOWED_FILE_EXTENSIONS))),
  searchField: PropTypes.bool,
  disabled: PropTypes.bool,
  withPrefix: PropTypes.bool,
  hidePagination: PropTypes.bool,
  customUploadButton: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.shape({
      onClick: PropTypes.func.isRequired
    })
  ]),
  showUploadButtonBelow: PropTypes.bool,
  showDividers: PropTypes.bool
};

AttachmentsOverviewOBS.defaultProps = {
  setMeta: () => {},
  getStyle: () => {},
  deleteAllFiles: () => {},
  onPendingFiles: () => {},
  onSuccessfulFileUpload: () => {},
  onSuccessfulFileDelete: () => {},

  allowedFileExtensions: null,
  searchField: false,
  addVersionHistoryEntriesOnChange: false,
  disabled: false,
  withPrefix: false,
  hidePagination: false,
  customUploadButton: null,
  showUploadButtonBelow: false,
  showDividers: true
};

export default React.memo(AttachmentsOverviewOBS);
