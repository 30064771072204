import { Box, IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import { t } from "i18next";
import stopEvent from "tool/stopEvent";
import { useDocumentPermissions } from "hook/useDocumentPermissions";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

const DocumentOverviewBrickAddFolder = ({ item, hovered, onAdd }: OverviewRowBrickProps) => {
  const isFolderItem = !item.fileType;

  const onClickCallback = useCallback(
    event => {
      stopEvent(event);
      onAdd?.({ placeholder: t("documentCenter:enterFolderName"), prefix: `${item.path}/` });
    },
    [item.path, onAdd]
  );

  const { canCreateFolder } = useDocumentPermissions(item);

  if (!hovered || !isFolderItem || item.disableActions?.some(({ action }) => action === "add")) {
    return <></>;
  }

  return (
    <Tooltip title={canCreateFolder ? t("documentCenter:createFolder") : t("common:missingPermission")}>
      <Box>
        <IconButton onClick={onClickCallback} disabled={!canCreateFolder} size="small">
          <CreateNewFolderIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default DocumentOverviewBrickAddFolder;
