import { SxProps } from "@mui/system/styleFunctionSx";
import React from "react";
import { Accordion, AccordionSummary, Box, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails } from "@material-ui/core";

export interface AIAccordionProps {
  readonly title: string;
  readonly icon: React.ReactNode;
  readonly children: React.ReactNode;
  readonly sx?: SxProps;
}
export const AIStepAccordion = ({ title, children, sx, icon }: AIAccordionProps) => {
  return (
    <Box sx={sx}>
      <Accordion
        sx={{
          backgroundColor: "grey.100",
          boxShadow: "none",
          borderRadius: "8px !important"
        }}
      >
        <AccordionSummary
          expandIcon={
            <IconButton>
              <ExpandMoreIcon fontSize="small" />
            </IconButton>
          }
          sx={{
            "& .MuiAccordionSummary-content": {
              m: 0
            },
            "& .Mui-expanded": {
              margin: "0px !important"
            },
            p: 1
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="start">
            {icon ? (
              <Box display="flex" alignItems="center" justifyContent="start" mr={1}>
                {icon}
              </Box>
            ) : undefined}
            <Box display="flex" alignItems="center" justifyContent="start">
              <Typography typography="body2">{title}</Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            p={2}
            pt={1}
            sx={{
              borderTop: "1px solid",
              borderColor: "#393A321F"
            }}
          >
            {children}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
