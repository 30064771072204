const localImportData: Record<
  string,
  {
    error_message?: string;
    message?: string;
    status?: string;
  }[]
> = {};

export const insertLocalImportData = (
  runId: string,
  message: {
    error_message?: string;
    message?: string;
    status?: string;
  }
) => {
  console.log(runId, message?.message);
  // need to be array every time or it will mess up react state as react works only with immutable stuffs
  localImportData[runId] = [...(localImportData[runId] || []), message];
};

export const getLocalImportData = async (
  runId: string
): Promise<
  {
    error_message?: string;
    message?: string;
    status?: string;
  }[]
> => {
  return localImportData[runId] || [];
};
