import overviewBaseController, {
  OverviewItem,
  OverviewNewItem,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { COLLECTIONS } from "app/collections";
import {
  createTask,
  deleteRecurringTask,
  deleteTask,
  TYPES,
  updateRecurringTask,
  updateTaskInformation
} from "../../../handlers/tasksHandler";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import { resourcesDecorator } from "../../../../components/Overview/controllers/decorator/resourcesDecorator";
import { AxiosInstance } from "axios";
import { NewTask } from "app/api/taskApi";
import { deleteGroupTaskApi } from "app/api/groupTaskApi";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface TaskOverviewCollectionParams {
  readonly setSelectedRow: (row: OverviewItem | null) => void;
}

const HomeOverviewController = (axiosInstance: AxiosInstance, collectionParams: TaskOverviewCollectionParams) => {
  const { auth } = useAuthentication();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.HOME, undefined, [resourcesDecorator]);
  const dispatch = useOverviewDispatch();
  const { t } = useTranslation("homePage");
  const { enqueueSnackbar } = useSnackbar();

  const createTaskObject = (title: string): NewTask => ({
    type: TYPES.individual,
    dueAt: null,
    assigneeUID: auth?.uid || "",
    collection: "tasks",
    title: title || createOverviewItemDefaultName("tasks"),
    description: null,
    labels: [],
    priority: null,
    status: null
  });

  const getPaginatedOverview = async (setup: OverviewSetup) => {
    const data = await baseController.getPaginatedOverview(setup, `/paginated`);
    if (!data) {
      return null;
    }
    collectionParams?.setSelectedRow(data.selectedItem || null);
    return {
      ...data
    };
  };

  const updatePaginatedOverview = async (setup: OverviewSetup) => {
    const data = await baseController.updatePaginatedOverview(setup, `/paginated`);
    if (!data) {
      return null;
    }
    collectionParams?.setSelectedRow(data.selectedItem || null);
    return {
      ...data
    };
  };

  const addItem = async ({ title, parentId }: OverviewNewItem) => {
    const taskId = await createTask({
      tenantId: auth?.tenantId || "",
      task: createTaskObject(title || ""),
      sectionKey: parentId
    });
    if (taskId) {
      // COLLECTIONS.TASKS, not .HOME, because we share the same "seen" state for tasks and home
      await addToSeenItemsOfUserHook(COLLECTIONS.TASKS, taskId);
    }
    enqueueSnackbar(t("new_task_success"), { variant: "success" });
    return taskId;
  };

  const addItemAndGo = async (data?: OverviewNewItem) => {
    return addItem({ title: data?.title, parentId: undefined });
  };

  const patchItem = async (id: string, data: object) => {
    const title = ("title" in data && (data.title as string)) || "";
    const item = baseController.getById(id);
    if (item) {
      if (item.detailType === "RECURRING") {
        await updateRecurringTask(id, { title });
      } else if (item.detailType === "DEFAULT") {
        updateTaskInformation(auth?.tenantId || "", auth?.uid || "", id, { title });
      }
    }
  };

  const deleteItem = async (id: string) => {
    const item = baseController.getById(id);
    if (item) {
      if (item.detailType === "RECURRING") {
        await deleteRecurringTask(id);
      } else if (item.detailType === "GROUP") {
        await deleteGroupTaskApi(id);
      } else {
        await deleteTask(id);
      }

      if (window.location.pathname.endsWith(item.id)) {
        dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.HOME, selectedId: null });
      }
    }
  };

  const deleteItems = async (ids: string[]) => {
    for (const id of ids) {
      const item = baseController.getById(id);
      if (item) {
        if (item.detailType === "RECURRING") {
          await deleteRecurringTask(id);
        } else if (item.detailType === "GROUP") {
          await deleteGroupTaskApi(id);
        } else {
          await deleteTask(id);
        }
      }
    }
    if (ids.some(id => window.location.pathname.endsWith(id))) {
      dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.HOME, selectedId: null });
    }
  };

  const goToItem = () => {
    // do nothing
  };

  return {
    ...baseController,
    patchItem,
    deleteItem,
    deleteItems,
    goToItem,
    addItem,
    addItemAndGo,
    getPaginatedOverview,
    updatePaginatedOverview
  };
};

export default HomeOverviewController;
