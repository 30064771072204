import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { FEATURES } from "app/features";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { t } from "i18next";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";

const MultilingualRename = ({ item, hovered, onRename, onClick, onBlur }: OverviewRowBrickProps) => {
  const isMultilingualEnabled = !!useIsFeaturePresent(FEATURES.MULTILINGUAL);

  const onClickCallback = useCallback(
    event => {
      onBlur?.();
      stopEvent(event);
      if (isMultilingualEnabled) {
        onClick?.(item.id, item);
      } else {
        onRename?.();
      }
    },
    [isMultilingualEnabled, item, onBlur, onClick, onRename]
  );

  if (!hovered || item.disableActions?.some(({ action }) => action === "edit")) {
    return <></>;
  }

  return (
    <Tooltip title={t("common:edit")}>
      <IconButton onClick={onClickCallback} size="small">
        <Edit />
      </IconButton>
    </Tooltip>
  );
};

export default MultilingualRename;
