import { Box, IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { t } from "i18next";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";
import MergeTypeIcon from "@material-ui/icons/MergeType";

const OverviewBrickMerge = ({ item, disabled, hovered, onClick, onBlur }: OverviewRowBrickProps) => {
  const onClickCallback = useCallback(
    event => {
      onBlur?.();
      stopEvent(event);
      onClick?.(item.id, item);
    },
    [item, onBlur, onClick]
  );

  if (!hovered || item.disableActions?.some(({ action }) => action === "merge")) {
    return <></>;
  }
  return (
    <Tooltip title={t("common:merge")}>
      <Box display="flex" alignItems="center">
        <IconButton disabled={disabled} onClick={onClickCallback} size="small">
          <MergeTypeIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default OverviewBrickMerge;
