import { useTranslation } from "react-i18next";
import OverviewBrickColorChip, { OverviewBrickColorChipColors } from "../common/OverviewBrickColorChip";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";

type RiskLevel = "notApplicable" | "low" | "medium" | "high" | "veryHigh";

const badgeColor: Record<RiskLevel, OverviewBrickColorChipColors> = {
  notApplicable: "blue",
  low: "green",
  medium: "orange",
  high: "red",
  veryHigh: "red"
};
const RiskOverviewBrickLevel = ({ item, hovered }: OverviewRowBrickProps) => {
  const { t } = useTranslation();
  const label = t(`risk_level_short:${item.riskLevel}`);
  const tooltip = t(`risk_level_tooltip:${item.riskLevel}`);

  return (
    <OverviewBrickColorChip
      tooltip={tooltip}
      color={badgeColor[item.riskLevel as RiskLevel]}
      label={label}
      hovered={hovered}
    />
  );
};

export default RiskOverviewBrickLevel;
