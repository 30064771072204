import { COLLECTIONS } from "app/collections";
import { useMetaView } from "app/contexts/meta-view-context";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";
import { OverviewBrickProps } from "components/Overview/controls/OverviewRow";
import Overview from "components/Overview/Overview";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationMoveModal } from "./OrganizationMoveModal";
import { ToolbarMoveIcon } from "../resources/overview/person-groups/PersonGroupsOverview";
import { OverviewItem } from "../../../components/Overview/controllers/overviewBaseController";
import { Box } from "@material-ui/core";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../features";
import { useAuthentication } from "../../handlers/authentication/authentication-context";

const bricks: OverviewBrickProps[] = [
  { component: OverviewBrick.Title },
  { component: OverviewBrick.Rename, position: "right" },
  { component: OverviewBrick.Add, position: "right" },
  { component: OverviewBrick.OrganizationMove, position: "right" },
  { component: OverviewBrick.OrganizationMerge, position: "right" },
  { component: OverviewBrick.OrganizationDelete, position: "right" }
];

const OrganizationOverview = () => {
  const { t } = useTranslation("organisationManagement");
  const { auth } = useAuthentication();
  const superAdminPermission = useMemo(() => !!auth?.permissions?.includes("super_admin"), [auth?.permissions]);
  const toolbarActions = useMemo(
    () =>
      [{ action: "sort" }, { action: "filter" }, superAdminPermission ? { action: "export-xlsx" } : null].filter(
        (it): it is { action: string } => !!it
      ),
    [superAdminPermission]
  );
  const { setInfo } = useMetaView();

  useEffect(() => {
    setInfo({ title: t("metaViewInfoCardCreateFirstTitle"), text: t("metaViewInfoCardCreateFirstText") });
  }, [setInfo, t]);
  const [selectedItems, setSelectedItems] = useState<OverviewItem[]>([]);

  const onMoveIconClick = useCallback((selectedItems: OverviewItem[]) => {
    setSelectedItems(selectedItems);
  }, []);

  const onMoveClose = useCallback(() => {
    setSelectedItems([]);
  }, []);

  const selectionActions = useMemo(
    () => [
      {
        action: "move",
        onHandle: onMoveIconClick,
        title: t("documentCenter:move"),
        icon: (
          <Box pt={0.9}>
            <ToolbarMoveIcon />
          </Box>
        )
      }
    ],
    [onMoveIconClick, t]
  );

  const moveFeatureEnabled = !useIsFeaturePresent(FEATURES.ORG_ACTION_MOVE_DISABLED);

  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"attachmentsOverviewMetaView"} />}>
      <>
        <Overview
          toolbarActions={toolbarActions}
          collection={COLLECTIONS.ORGANIZATIONS}
          header={t("sidebar:organization_management")}
          showSelectAll={false}
          bricks={bricks}
          checkable={!!moveFeatureEnabled}
          selectable={true}
          selectionActions={selectionActions}
        />
        {selectedItems.length > 0 && (
          <OrganizationMoveModal selectedItems={selectedItems} onClose={onMoveClose} onCancel={onMoveClose} />
        )}
      </>
    </DocMetaView>
  );
};

export default OrganizationOverview;
