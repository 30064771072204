import { IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import LinkIcon from "@mui/icons-material/Link";

export const DocumentOverviewBrickCopyURL = ({ item, hovered }: OverviewRowBrickProps) => {
  const { t } = useTranslation("documentCenter");

  const isFolderItem = !item.fileType;

  const { enqueueSnackbar } = useSnackbar();

  const copyURL = useCallback(async () => {
    const origin = window.location.origin;
    const plainURL = `${origin}/file/${encodeURIComponent(item.path)}/`;
    const htmlLink = `<a href="${plainURL}" target="_blank" rel="noopener noreferrer">${plainURL}</a>`;

    const data = [
      new ClipboardItem({
        "text/plain": new Blob([plainURL], { type: "text/plain" }),
        "text/html": new Blob([htmlLink], { type: "text/html" })
      })
    ];
    await navigator.clipboard.write(data);

    enqueueSnackbar(t("copyURLSuccess"), { variant: "success" });
  }, [enqueueSnackbar, item.path, t]);

  if (!hovered || isFolderItem) {
    return <></>;
  }
  return (
    <Tooltip title={t("copyURL")}>
      <IconButton onClick={copyURL} size="small">
        <LinkIcon />
      </IconButton>
    </Tooltip>
  );
};
