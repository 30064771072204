import React, { CSSProperties, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ContactSupportIcon from "@mui/icons-material/ContactSupportOutlined";
import { MenuItem, MenuList, Paper, ClickAwayListener, useTheme, IconButton } from "@mui/material";
import { Popper } from "@material-ui/core";
import { topbarZIndex } from "./topbarZindex";

export const HelpSidebar = ({ language }: { readonly language: string }) => {
  const { t } = useTranslation("sidebar");
  const theme = useTheme();
  const onPrivacyClick = useCallback(() => {
    window.open(
      language === "de" ? "https://caralegal.eu/app/datenschutzerklaerung" : "https://caralegal.eu/app/privacynotice"
    );
    setAnchorEl(null);
  }, [language]);
  const onSupportClick = useCallback(() => {
    window.open("https://intercom-help.eu/caralegal-help-center/de/");
    setAnchorEl(null);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback(event => {
    const currentTarget = event.currentTarget;
    setAnchorEl(anchorEl => (anchorEl ? null : currentTarget));
  }, []);
  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        aria-label="support-selector"
        onClick={handleClick}
        sx={{ color: theme.palette.grey[500], justifyContent: "center" }}
      >
        <ContactSupportIcon sx={{ height: "20px", width: "20px" }} />
      </IconButton>

      <Popper open={anchorEl !== null} anchorEl={anchorEl} placement="bottom-end" transition style={popperZIndex}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper>
            <MenuList id="composition-menu" aria-labelledby="composition-button">
              {[
                { name: t("support"), onClick: onSupportClick },
                { name: t("private_notice"), onClick: onPrivacyClick }
              ]
                .flatMap(it => (it ? [it] : []))
                .map(i => (
                  <MenuItem key={i.name} onClick={i.onClick}>
                    {t(i.name)}
                  </MenuItem>
                ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const popperZIndex: CSSProperties = { zIndex: topbarZIndex + 1 };

export default HelpSidebar;
