import React, { useMemo } from "react";
import { Box } from "@mui/material";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useGetTOMApi } from "../../../api/tomApi";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "../../../collections";
import Overview from "../../../../components/Overview/Overview";
import { OverviewProvider } from "../../../contexts/overview-context";
import { CollectionParams } from "../../../../hook/useOverviewData";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";

const bricks = [{ component: OverviewBrick.Title }];

const TomRisks = ({ id }: { readonly id: string; readonly readonly?: boolean }) => {
  const { t } = useTranslation("tom");
  const { data: tom } = useGetTOMApi({ documentId: id || "" });

  const overviewToolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }], []);
  const riskOverviewParams = useMemo<CollectionParams>(() => ({ id, openInNewTab: true }), [id]);

  if (!tom) {
    return <></>;
  }

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("pagination:risk")} />
      </Box>
      <OverviewProvider>
        <Overview
          bricks={bricks}
          showSelectAll={false}
          toolbarActions={overviewToolbarActions}
          collectionParams={riskOverviewParams}
          collection={COLLECTIONS.TOM_RISKS}
        />
      </OverviewProvider>
    </Box>
  );
};

export default TomRisks;
