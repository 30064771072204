import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Question from "components/Question/Question";
import { DSRDataStorageField } from "./DSRDataStorageField";
import { useDataSubjectRequest } from "app/contexts/dsr-context";
import { useDataLocations } from "hook/useDataLocations";
import { useUserProcesses } from "hook/useUserProcesses";

interface DSRDataStoragePickerProps {
  readonly disabled?: boolean;
}

// displays data storage locations from processes related to selected person groups
const DSRDataStoragePicker = ({ disabled }: DSRDataStoragePickerProps) => {
  const { t } = useTranslation("data_subject_requests_data_page");
  const { dataSubjectRequest } = useDataSubjectRequest();
  const { dataLocations, dataLocationsLoaded } = useDataLocations();

  // track data storage locations found in processes
  const [dataStorageIDs, setDataStorageIDs] = useState<string[]>([]);

  // get processes based on selected person groups
  const { processes, processesLoaded } = useUserProcesses({
    personGroupIDs: dataSubjectRequest?.inputData?.personGroups || []
  });

  // update data storage locations when processes change
  useEffect(() => {
    if (!processesLoaded || !dataLocationsLoaded) return;

    // get all valid data location IDs
    const allDataLocationIDs = dataLocations.map(location => location.id);

    // collect relevant data storage IDs from processes
    const relevantDataStorageIDs = new Set<string>();
    for (const processData of processes) {
      processData.allDataStorageIds
        .filter(dataStorageId => allDataLocationIDs.includes(dataStorageId))
        .forEach(id => relevantDataStorageIDs.add(id));
    }

    setDataStorageIDs([...relevantDataStorageIDs]);
  }, [processes, processesLoaded, dataLocations, dataLocationsLoaded]);

  // only show field if person groups are selected
  const shouldShow = (dataSubjectRequest?.inputData?.personGroups || []).length > 0;

  if (!shouldShow) return null;

  return (
    <Question questionId="dataStorages" questionName={t("datalocation")} disabled={disabled}>
      <DSRDataStorageField values={dataStorageIDs} loading={!processesLoaded} />
    </Question>
  );
};

export default DSRDataStoragePicker;
