import React, { useMemo } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AccountNotice } from "../../app/pages/authentication/AccountNotice";
import { useTranslation } from "react-i18next";
import DocTitle, { DocProperty, DocStatus } from "components/DocTitle/DocTitle";

export enum DocViewPageType {
  GENERAL,
  OVERVIEW
}

interface DocViewProps {
  readonly children?: React.ReactNode;
  readonly disableCancel?: boolean;
  readonly disableSave?: boolean;
  readonly disabled?: boolean;
  readonly docProperties?: DocProperty[];
  readonly header?: string;
  readonly icons?: React.ReactNode;
  readonly pagination?: React.ReactNode;
  readonly saving?: boolean;
  readonly statusId?: string;
  readonly statuses?: DocStatus[];
  readonly pageType?: DocViewPageType;
  readonly onStatusChange?: (status: string) => void;
  readonly onHeaderChange?: (val: string) => void;
  readonly onSave?: () => void;
  readonly onCancel?: () => void;
  readonly onHeaderFocus?: (event?: any) => void;
}

export default function DocView({
  children,
  disableCancel,
  disableSave,
  disabled,
  docProperties,
  header,
  icons,
  pagination,
  saving,
  statusId,
  statuses,
  pageType,
  onSave,
  onCancel,
  onHeaderChange,
  onStatusChange,
  onHeaderFocus
}: DocViewProps) {
  const { t } = useTranslation("");
  const theme = useTheme();

  const buttonsEl = useMemo(() => {
    return (
      (onSave || onCancel) && (
        <Box display="flex" mt={3}>
          <Box flex={1} />
          <Box display="flex">
            {onCancel && (
              <Button variant="outlined" onClick={onCancel} disabled={disableCancel}>
                {t("common:cancel")}
              </Button>
            )}
            {onSave && (
              <>
                <Box mx={1} />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSave}
                  disabled={disableSave}
                  startIcon={saving && <CircularProgress size={14} />}
                >
                  {t("common:save")}
                </Button>
              </>
            )}
          </Box>
        </Box>
      )
    );
  }, [disableCancel, disableSave, onCancel, onSave, saving, t]);

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        overflowY: pageType === DocViewPageType.OVERVIEW ? "hidden" : "auto",
        overflowX: "hidden",
        pb: 4,
        px: 2,
        bgcolor: theme.palette.background.paper // set background to paper color
      }}
    >
      <AccountNotice mt={2} />
      <Box
        sx={{
          px: 4,
          ...(pageType === DocViewPageType.OVERVIEW ? { display: "flex", flexDirection: "column", height: "100%" } : {})
        }}
      >
        <Box>
          {(header || icons) && (
            <>
              <Box
                sx={{
                  display: "flex",
                  mt: 4,
                  alignItems: "center",
                  height: pageType === DocViewPageType.OVERVIEW ? "100%" : "auto"
                }}
              >
                <Box flex={1}>
                  <DocTitle
                    title={header || ""}
                    statusId={statusId}
                    statuses={statuses}
                    disabled={disabled}
                    properties={docProperties}
                    onTitleChange={onHeaderChange}
                    onStatusChange={onStatusChange}
                    onFocus={onHeaderFocus}
                  />
                </Box>
                <Box>{icons}</Box>
              </Box>
            </>
          )}
          {pagination}
        </Box>
        {children}
        <Box px={0}>{buttonsEl}</Box>
      </Box>
    </Box>
  );
}
