import React from "react";
import { IconButton, Tooltip, useTheme } from "@mui/material";

interface OverviewIconButtonProps {
  readonly tooltip?: React.ReactNode;
  readonly nonEmpty?: boolean;
  readonly disabled?: boolean;
  readonly onClick?: (val: unknown) => void;
  readonly children: React.ReactNode;
}

const OverviewIconButton = React.forwardRef<HTMLButtonElement, OverviewIconButtonProps>(
  ({ tooltip, nonEmpty, disabled, children, onClick }, ref) => {
    const theme = useTheme();

    const btnEl = (
      <IconButton
        ref={ref}
        aria-controls="simple-menu"
        aria-haspopup="true"
        disabled={disabled}
        sx={{
          width: "36px",
          height: "36px",
          "&:hover": {
            backgroundColor: theme.palette.blue[50],
            color: theme.palette.primary.main
          },
          backgroundColor: nonEmpty ? theme.palette.blue[50] : undefined,
          color: nonEmpty ? theme.palette.primary.main : undefined
        }}
        onClick={onClick}
      >
        {children}
      </IconButton>
    );

    if (tooltip) {
      return (
        <Tooltip title={tooltip} disableInteractive>
          {btnEl}
        </Tooltip>
      );
    } else return btnEl;
  }
);
OverviewIconButton.displayName = "OverviewIconButton";
export default OverviewIconButton;
