import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardContent, Divider, ListItemButton, MenuList } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { languageToggles } from "app/handlers/languageHandler";
import { TranslationProps, useTranslator } from "../../../contexts/translator-context";
import { TFunction } from "i18next";

export default function TranslatorMenu({
  translationId,
  onSelectLanguage
}: {
  readonly translationId: string;
  readonly onSelectLanguage?: (language: string) => void;
}) {
  const [hiddenMenu, setHiddenMenu] = useState("sourceLanguage");
  const {
    findTranslation,
    hideTranslationHook,
    loading,
    setSelectedSourceLanguageHook,
    setTargetLanguageHook,
    translateHook
  } = useTranslator();
  const [translation, setTranslation] = useState<TranslationProps | null>(null);

  useEffect(() => {
    setTranslation(findTranslation(translationId));
  }, [findTranslation, translationId]);

  const shouldUpdateTranslation = useMemo(
    () =>
      translation?.targetLanguage
        ? [translation?.targetLanguage, translation?.selectedSourceLanguage, translation.textToTranslate].join("-")
        : "",
    [translation]
  );

  useEffect(() => {
    if (shouldUpdateTranslation && translation) {
      translateHook(translation);
    }
    // we need to handle this manually, otherwise, it will just keep re-translating as translation is updated after translate hook call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateTranslation, translateHook]);

  const hideSourceLanguageMenu = useCallback(() => {
    setHiddenMenu("sourceLanguage");
  }, []);

  const hideTranslatedLanguageMenu = useCallback(() => {
    setHiddenMenu("translateLanguage");
  }, []);

  const hideTranslationCallback = useCallback(() => {
    hideTranslationHook(translationId);
  }, [hideTranslationHook, translationId]);

  const updateSelectedTargetLanguage = useCallback(
    language => {
      setTargetLanguageHook(translationId, language);
      onSelectLanguage?.(language);
    },
    [onSelectLanguage, setTargetLanguageHook, translationId]
  );

  const updateSelectedSourceLanguage = useCallback(
    language => {
      setSelectedSourceLanguageHook(translationId, language);
    },
    [setSelectedSourceLanguageHook, translationId]
  );

  return (
    <div data-testid={"translator_menu"}>
      <div hidden={hiddenMenu === "translateLanguage"}>
        <TargetLanguageMenu
          detectedSourceLanguage={translation?.detectedSourceLanguage || ""}
          hideLanguageDisabled={loading || !translation?.translatedText}
          hideTranslation={hideTranslationCallback}
          selectedLanguage={translation?.targetLanguage || ""}
          showDetected={!translation?.selectedSourceLanguage && !!translation?.detectedSourceLanguage}
          updateHiddenMenu={hideTranslatedLanguageMenu}
          updateSelectedTargetLanguage={updateSelectedTargetLanguage}
        />
      </div>
      <div hidden={hiddenMenu === "sourceLanguage"}>
        <SourceLanguageMenu
          sourceLanguage={translation?.selectedSourceLanguage || ""}
          updateHiddenMenu={hideSourceLanguageMenu}
          updateSelectedSourceLanguage={updateSelectedSourceLanguage}
        />
      </div>
    </div>
  );
}

function TargetLanguageMenu({
  detectedSourceLanguage,
  hideLanguageDisabled,
  hideTranslation,
  selectedLanguage,
  showDetected,
  updateHiddenMenu,
  updateSelectedTargetLanguage
}: {
  readonly detectedSourceLanguage: string;
  readonly hideLanguageDisabled: boolean;
  readonly hideTranslation: () => void;
  readonly selectedLanguage: string;
  readonly showDetected: boolean;
  readonly updateHiddenMenu: () => void;
  readonly updateSelectedTargetLanguage: (language: string) => void;
}) {
  const { t, i18n } = useTranslation("deepL_menu");
  const [sortedTargetLanguages, setSortedTargetLanguages] = useState<
    {
      translation: string;
      langCode: string;
    }[]
  >([]);

  useEffect(() => {
    setSortedTargetLanguages(
      translateAndSortDeepLLanguageCodes(["DE", "EN-US", "EN-GB"], Object.keys(languageToggles), t)
    );
  }, [i18n.language, t]);

  return (
    <Card data-testid={"target_language_menu"} sx={sxLanguageMenu}>
      <CardContent>
        <MenuList>
          <ListItemButton
            data-testid={"hide_translation"}
            disableRipple
            disabled={hideLanguageDisabled}
            hidden={!selectedLanguage}
            onClick={hideTranslation}
          >
            <span>
              <b>{t("hide_translation")}</b>
            </span>
          </ListItemButton>
          <ListItemButton disableRipple onClick={updateHiddenMenu} data-testid={"switch_source_menu"}>
            <span>
              {t("detected_source_language")} <b>{t("deepL_language:" + detectedSourceLanguage)}</b>{" "}
              {showDetected ? t("detected") : ""}
            </span>
            <ArrowRightOutlined />
          </ListItemButton>
          <Divider sx={{ marginBottom: 1 }} />
          <ListItemButton disableRipple>
            <span>
              <b>{t("translate_to")}</b>
            </span>
          </ListItemButton>
          {sortedTargetLanguages.map(language => (
            <ListItemButton
              data-testid={"target_language_" + language.langCode}
              disableRipple
              key={language.langCode}
              selected={language.langCode === selectedLanguage /* eslint-disable-next-line react/jsx-no-bind */}
              onClick={() => updateSelectedTargetLanguage(language.langCode)}
            >
              <span>{language.translation}</span>
            </ListItemButton>
          ))}
        </MenuList>
      </CardContent>
    </Card>
  );
}

function SourceLanguageMenu({
  updateHiddenMenu,
  updateSelectedSourceLanguage,
  sourceLanguage
}: {
  readonly updateHiddenMenu: () => void;
  readonly updateSelectedSourceLanguage: (language: string) => void;
  readonly sourceLanguage: string;
}) {
  const { t, i18n } = useTranslation("deepL_menu");
  const [sortedSourceLanguages, setSortedSourceLanguages] = useState<
    {
      translation: string;
      langCode: string;
    }[]
  >([]);

  useEffect(() => {
    setSortedSourceLanguages(translateAndSortDeepLLanguageCodes(["DE", "EN"], Object.keys(languageToggles), t));
  }, [i18n.language, t]);

  return (
    <Card data-testid={"source_language_menu"} sx={sxLanguageMenu}>
      <CardContent>
        <MenuList>
          <ListItemButton disableRipple onClick={updateHiddenMenu} data-testid={"switch_target_menu"}>
            <ArrowLeftOutlined sx={{ marginLeft: -1 }} />
            <span>Select Source Language</span>
          </ListItemButton>
          <Divider sx={{ marginBottom: 1 }} />
          {sortedSourceLanguages.map(language => (
            <ListItemButton
              data-testid={"source_language_" + language.langCode}
              disableRipple
              key={language.langCode}
              selected={sourceLanguage === language.langCode /* eslint-disable-next-line react/jsx-no-bind */}
              onClick={() => updateSelectedSourceLanguage(language.langCode)}
            >
              <span>{language.translation}</span>
            </ListItemButton>
          ))}
        </MenuList>
      </CardContent>
    </Card>
  );
}

const sxLanguageMenu = {
  width: 250,
  maxHeight: 350,
  overflow: "auto"
};

const translateAndSortDeepLLanguageCodes = (exceptions: string[], languages: string[], t: TFunction) => {
  const translatedLanguages = languages.map(langCode => ({
    translation: t(`sidebar:${langCode}`),
    langCode
  }));
  return translatedLanguages.sort((a, b) => {
    if (exceptions.includes(a.langCode) && exceptions.includes(b.langCode)) {
      return a.langCode.localeCompare(b.langCode);
    }
    if (exceptions.includes(a.langCode)) return -1;
    if (exceptions.includes(b.langCode)) return 1;
    return a.translation.localeCompare(b.translation);
  });
};
