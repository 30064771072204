import { mode1 as designColorTokens } from "./designColorTokens.json" assert { type: "object" };

const colors = {
  white: "#FFFFFF",
  black: "#000000",
  divider: "#E0E0E0",
  grey: {
    grey50: designColorTokens.grey50,
    grey100: designColorTokens.grey100,
    grey200: designColorTokens.grey200,
    grey300: designColorTokens.grey300,
    grey400: designColorTokens.grey400,
    grey500: designColorTokens.grey500,
    grey600: designColorTokens.grey600,
    grey700: designColorTokens.grey700,
    grey800: designColorTokens.grey800,
    grey900: designColorTokens.grey900
  },
  blue: {
    blue50: designColorTokens.blue50,
    blue100: designColorTokens.blue100,
    blue200: designColorTokens.blue200,
    blue300: designColorTokens.blue300,
    blue400: designColorTokens.blue400,
    blue500: designColorTokens.blue500,
    blue600: designColorTokens.blue600,
    blue700: designColorTokens.blue700
  },
  red: {
    red50: designColorTokens.red50,
    red100: designColorTokens.red100,
    red200: designColorTokens.red200,
    red300: designColorTokens.red300,
    red400: designColorTokens.red400,
    red500: designColorTokens.red500,
    red600: designColorTokens.red600,
    red700: designColorTokens.red700
  },
  yellow: {
    yellow50: designColorTokens.yellow50,
    yellow100: designColorTokens.yellow100,
    yellow200: designColorTokens.yellow200,
    yellow300: designColorTokens.yellow300,
    yellow400: designColorTokens.yellow400,
    yellow500: designColorTokens.yellow500,
    yellow600: designColorTokens.yellow600,
    yellow700: designColorTokens.yellow700
  },
  green: {
    green50: designColorTokens.green50,
    green100: designColorTokens.green100,
    green200: designColorTokens.green200,
    green300: designColorTokens.green300,
    green400: designColorTokens.green400,
    green500: designColorTokens.green500,
    green600: designColorTokens.green600,
    green700: designColorTokens.green700
  },
  orange: {
    orange50: designColorTokens.orange50,
    orange100: designColorTokens.orange100,
    orange200: designColorTokens.orange200,
    orange300: designColorTokens.orange300,
    orange400: designColorTokens.orange400,
    orange500: designColorTokens.orange500,
    orange600: designColorTokens.orange600,
    orange700: designColorTokens.orange700,
    orange800: designColorTokens.orange800
  },
  indigo: {
    indigo50: designColorTokens.indigo50,
    indigo100: designColorTokens.indigo100,
    indigo200: designColorTokens.indigo200,
    indigo300: designColorTokens.indigo300,
    indigo400: designColorTokens.indigo400,
    indigo500: designColorTokens.indigo500,
    indigo600: designColorTokens.indigo600,
    indigo700: designColorTokens.indigo700
  },
  deepPurple: {
    deepPurple50: designColorTokens.deepPurple50,
    deepPurple100: designColorTokens.deepPurple100,
    deepPurple200: designColorTokens.deepPurple200,
    deepPurple300: designColorTokens.deepPurple300,
    deepPurple400: designColorTokens.deepPurple400,
    deepPurple500: designColorTokens.deepPurple500,
    deepPurple600: designColorTokens.deepPurple600,
    deepPurple700: designColorTokens.deepPurple700
  }
};

export default colors;
