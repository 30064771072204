import { Box, Button, IconButton } from "@mui/material";
import {
  AutoAwesomeRounded,
  CopyAllOutlined,
  ReplayOutlined,
  ThumbDownOutlined,
  ThumbDownRounded,
  ThumbUpOutlined,
  ThumbUpRounded
} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { AIStepButton } from "./AIStepButton";
import React, { useCallback, useMemo, useState } from "react";
import { SxProps } from "@mui/system/styleFunctionSx";
import { useTranslation } from "react-i18next";
import { DefaultApi as AiBackendApi } from "../../app/api/generated/ai-service";
import { apiEndpoints } from "../../app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../app/api/axios/loggedInAxiosProvider";

export interface AIDialogItem {
  readonly type: string;
}

export type AllAIDialogItems = AISpeakProps | AIChoiceButtonProps | AIResultProps | AIUserInputProps;

export interface AISpeakProps extends AIDialogItem {
  readonly type: "speak";
  readonly label: string;
}
export const AISpeak = ({ label }: AISpeakProps) => {
  return (
    <Box p={1} display="flex" justifyContent="start" alignItems="top">
      <Box mr={1}>
        <AutoAwesomeRounded color="primary" fontSize="small" />
      </Box>
      <Box display="flex" justifyContent="start" alignItems="top" flex={1}>
        <Typography variant="body1" textTransform="none" textAlign="start">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export interface AIChoiceButtonProps extends AIDialogItem {
  readonly type: "choice";
  readonly label: string;
  readonly onClick: (suggestion: string) => void;
  readonly disabled?: boolean;
  readonly startIcon?: React.ReactNode;
  readonly sx?: SxProps;
}

export const AIChoiceButton = ({ label, onClick, disabled, startIcon, sx }: AIChoiceButtonProps) => {
  const onClickCallback = useCallback(() => {
    onClick(label);
  }, [label, onClick]);
  return (
    <AIStepButton onClick={onClickCallback} startIcon={startIcon} disabled={disabled} sx={sx}>
      <Typography variant="body1" textTransform="none" textAlign="start" color="inherit">
        {label}
      </Typography>
    </AIStepButton>
  );
};

export interface AIResultProps extends AIDialogItem {
  readonly type: "result";
  readonly result: object;
  readonly sessionId: string;
  readonly requestId: string;
  readonly parseResult?: (result: object) => React.ReactNode;
  readonly onApply?: (result: object) => void;
  readonly onRestart?: () => void;
}

export const AIResultProps = ({ result, onApply, onRestart, parseResult, requestId, sessionId }: AIResultProps) => {
  const { t } = useTranslation();
  const applyCallback = useCallback(() => {
    onApply?.(result);
  }, [result, onApply]);

  const parseResultCallback = useCallback(
    (result: object) => {
      if (parseResult) {
        return parseResult(result);
      }
      return Object.entries(result)
        .sort(([key1], [key2]) => key1.localeCompare(key2))
        .map(([, value]) => (typeof value === "string" ? value : String(value)))
        .join("\n");
    },
    [parseResult]
  );

  const resultDisplay = useMemo(() => parseResultCallback(result), [result, parseResultCallback]);

  const copyToClipboard = useCallback(() => {
    if (typeof resultDisplay === "string") {
      navigator?.clipboard?.writeText?.(resultDisplay);
    }
  }, [resultDisplay]);

  const [thumbsUp, setThumbsUp] = useState<"" | "up" | "down">("");
  const thumbsUpPositive = useCallback(() => {
    setThumbsUp(prev => (prev === "up" ? "" : "up"));
    aiClient.thumbsUp(sessionId, requestId);
  }, [requestId, sessionId]);
  const thumbsUpNegative = useCallback(() => {
    setThumbsUp(prev => (prev === "down" ? "" : "down"));
    aiClient.thumbsDown(sessionId, requestId);
  }, [requestId, sessionId]);

  return (
    <Box bgcolor="grey.100" p={2}>
      <Box mb={2}>
        <Typography variant="body1" textTransform="none" textAlign="start">
          {resultDisplay}
        </Typography>
      </Box>
      {onRestart ? (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={3}>
            <Box display="flex" alignItems="center" justifyContent="start">
              {typeof resultDisplay === "string" ? (
                <IconButton>
                  <CopyAllOutlined fontSize="small" onClick={copyToClipboard} />
                </IconButton>
              ) : (
                <></>
              )}
              <IconButton>
                <ReplayOutlined fontSize="small" onClick={onRestart} />
              </IconButton>
            </Box>
            {requestId ? (
              <Box display="flex" alignItems="center" justifyContent="end">
                <IconButton onClick={thumbsUpPositive}>
                  {thumbsUp === "up" ? (
                    <ThumbUpRounded fontSize="small" color="primary" />
                  ) : (
                    <ThumbUpOutlined fontSize="small" />
                  )}
                </IconButton>
                <IconButton onClick={thumbsUpNegative}>
                  {thumbsUp === "down" ? (
                    <ThumbDownRounded fontSize="small" color="primary" />
                  ) : (
                    <ThumbDownOutlined fontSize="small" />
                  )}
                </IconButton>
              </Box>
            ) : (
              <Box />
            )}
          </Box>
          {thumbsUp === "" ? (
            <></>
          ) : (
            <Box mt={1}>
              <Typography component="div" variant="body2" textTransform="none" textAlign="end">
                {t("ai-metaview:feedback.thanks")}
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
      {onApply ? (
        <Box display="flex" alignItems="center" justifyContent="end" mt={2}>
          <Button variant="contained" color="primary" onClick={applyCallback} size="small">
            {t("ai-metaview:apply")}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
const aiClient = new AiBackendApi(undefined, apiEndpoints.aiUrl, defaultOTCAuthenticatedAxios());

export interface AIUserInputProps extends AIDialogItem {
  readonly type: "user-input";
  readonly label: string;
}

export const AIUserInput = ({ label }: AIUserInputProps) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Box py={1} pr={1} pl={3} display="inline-block" bgcolor="blue.50" borderRadius={1}>
        <Typography variant="body1" textTransform="none" textAlign="end">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export const isAISpeak = (item: AIDialogItem): item is AISpeakProps => {
  return item.type === "speak";
};

export const isAIChoiceButton = (item: AIDialogItem): item is AIChoiceButtonProps => {
  return item.type === "choice";
};

export const isAIResult = (item: AIDialogItem): item is AIResultProps => {
  return item.type === "result";
};

export const isAIUserInput = (item: AIDialogItem): item is AIUserInputProps => {
  return item.type === "user-input";
};

export const GenericAIDialogItem = ({ itemProp }: { itemProp: AllAIDialogItems }) => {
  if (isAISpeak(itemProp)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <AISpeak {...itemProp} />;
  }
  if (isAIChoiceButton(itemProp)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <AIChoiceButton {...itemProp} />;
  }
  if (isAIResult(itemProp)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <AIResultProps {...itemProp} />;
  }
  if (isAIUserInput(itemProp)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <AIUserInput {...itemProp} />;
  }
  return <></>;
};
