import React from "react";
import { Box, Typography } from "@mui/material";

interface QuestionnaireSubHeaderProps {
  readonly text: string;
}
const QuestionnaireSubHeader = ({ text }: QuestionnaireSubHeaderProps) => {
  return (
    <Box mb={2}>
      <Typography variant="subtitle1" sx={sx.typograpy}>
        {text}
      </Typography>
    </Box>
  );
};

const sx = {
  typograpy: {
    wordBreak: "break-all"
  }
};

export default QuestionnaireSubHeader;
