import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Tabs, Tab, Tooltip, useTheme } from "@mui/material";
import { MetaViewTabProps, META_VIEW_TABS_TYPES, META_VIEW_TABS } from "components/MetaView/MetaView";

// utility function to convert text to sentence case
const toSentenceCase = (text: string): string => {
  if (!text) return "";
  if (text === "AI") return text;
  // if text is all uppercase, convert to sentence case
  if (text === text.toUpperCase()) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
  // otherwise return the original text (already in sentence case or custom format)
  return text;
};

interface MetaViewTabsProps {
  readonly tabs: MetaViewTabProps[];
  readonly selected: META_VIEW_TABS_TYPES;
  readonly children?: React.ReactNode;
  readonly onTabChange: (tabId: META_VIEW_TABS_TYPES) => void;
}

export default function MetaViewTabs({ tabs, selected, children, onTabChange }: MetaViewTabsProps) {
  const [tab, setTab] = useState<MetaViewTabProps | null>(null);
  const theme = useTheme();

  // pre-transform tab titles using useMemo
  const transformedTabs = useMemo(
    () =>
      tabs.map(tab => ({
        ...tab,
        displayTitle: toSentenceCase(tab.title)
      })),
    [tabs]
  );

  useEffect(() => {
    const currentTab = tabs.find(tab => tab.tabId === selected);
    if (currentTab) {
      setTab(currentTab);
    }
  }, [selected, tabs]);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, value: number) => {
      const selectedTab = tabs[value];
      setTab(selectedTab);
      onTabChange(selectedTab.tabId);
    },
    [onTabChange, tabs]
  );

  // find the index of the selected tab
  const selectedIndex = tabs.findIndex(t => t.tabId === selected);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        overflow: "hidden"
      }}
    >
      {/* tab content panel - displays selected tab content */}
      <Box
        sx={{
          flex: 1,
          paddingTop: 3,
          paddingRight: 2,
          paddingBottom: 3,
          paddingLeft: 2,
          overflow: selected === META_VIEW_TABS.ASSISTANT ? "hidden" : "auto",
          display: "flex",
          flexDirection: "column"
        }}
        role="tabpanel"
        id={`metaview-tabpanel-${selectedIndex}`}
        aria-labelledby={`metaview-tab-${selectedIndex}`}
      >
        {tab?.content}
        {children}
      </Box>

      {/* vertical tabs navigation panel */}
      <Box
        sx={{
          borderLeft: 1,
          borderColor: "divider", // uses theme.palette.divider
          bgcolor: theme.palette.background.paper,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "52px"
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedIndex !== -1 ? selectedIndex : 0}
          onChange={handleTabChange}
          aria-label="Meta view tabs"
          sx={{
            mt: 3,
            "& .MuiTab-root": {
              justifyContent: "center",
              minHeight: "38px",
              minWidth: "52px",
              padding: 0,
              textAlign: "center"
            },
            "& .MuiTabs-indicator": {
              left: 0,
              right: "auto",
              width: "2px"
            },
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px"
            }
          }}
        >
          {/* map tabs to tab components with tooltips */}
          {transformedTabs.map((tab, index) => (
            <Tooltip
              key={index}
              title={tab.displayTitle}
              placement="left"
              disableInteractive
              slotProps={{
                popper: {
                  sx: {
                    marginRight: "-12px !important" // adjust tooltip position
                  }
                }
              }}
            >
              <Tab
                aria-controls={`metaview-tabpanel-${index}`}
                aria-label={tab.title}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                icon={tab.icon as any}
                id={`metaview-tab-${index}`}
              />
            </Tooltip>
          ))}
        </Tabs>
      </Box>
    </Box>
  );
}
