import { Box, IconButton, Tooltip } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback, useMemo, useRef } from "react";
import { t } from "i18next";
import stopEvent from "tool/stopEvent";
import { Publish } from "@mui/icons-material";
import { useDocumentPermissions } from "hook/useDocumentPermissions";
import Uploader from "app/pages/documents/Uploader";
import { useDocumentUpload } from "hook/useDocumentUpload";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";

const DocumentOverviewBrickUpload = ({ item, hovered }: OverviewRowBrickProps) => {
  const isFolderItem = !item.fileType;

  const dispatch = useOverviewDispatch();
  const uploaderRef = useRef<{ triggerFileInput: () => void } | null>(null);
  const { userHasUploadPermission, canUploadFile } = useDocumentPermissions(item);

  const reloadOverview = useCallback(async () => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.DOCUMENTS, reload });
  }, [dispatch]);

  const { onUploadStart, onUploadEnd } = useDocumentUpload(reloadOverview);

  const handleUploadToFolderClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    stopEvent(event);
    uploaderRef.current?.triggerFileInput();
  }, []);

  const buttonEl = useMemo(() => {
    if (!hovered || !isFolderItem) {
      return <></>;
    } else
      return (
        <Tooltip title={canUploadFile ? t("documentCenter:upload") : t("common:missingPermission")}>
          <Box>
            <IconButton onClick={handleUploadToFolderClick} disabled={!canUploadFile} size="small">
              <Publish />
            </IconButton>
          </Box>
        </Tooltip>
      );
  }, [canUploadFile, handleUploadToFolderClick, hovered, isFolderItem]);

  return (
    <>
      {buttonEl}
      <Uploader
        disabled={!userHasUploadPermission}
        id={`file-upload-input-${item.title}`}
        onUploadEnd={onUploadEnd}
        onUploadStart={onUploadStart}
        path={item.path}
        ref={uploaderRef}
      />
    </>
  );
};

export default DocumentOverviewBrickUpload;
