import {
  createRoleApi,
  deleteRoleApi,
  getRolesApi,
  getSingleRoleApi,
  RoleDTO,
  SingleNewRole,
  updateRoleApi
} from "app/api/roleApi";
import { retryUntil } from "app/handlers/utility/retry";
import { WithAbortController } from "../api/axios/axiosErrorHandler";
import { isEqual } from "lodash-es";

export const getRoles = (options: WithAbortController = {}) => getRolesApi(options);

/**
 * This api fetch the latest data of the role.
 * It also works for default role in case you want to check default role permissions.
 *
 * The getRoles api in comparison will not return default roles.
 *
 * @param id The id of the role.
 */
export const getRoleData = (id: string) => getSingleRoleApi(id);

export const deleteRole = async (tenantId: string, id: string) => {
  await deleteRoleApi(id);
  await retryUntil(async () => {
    const createdRole = await getRoleData(id);
    if (createdRole) {
      throw new Error("Timeout on delete Role");
    }
  });
};

export const createRole = async (tenantId: string, payload: SingleNewRole): Promise<RoleDTO> => {
  const createdRoleId = await createRoleApi(payload);
  return await retryUntil(async () => {
    const createdRole = await getRoleData(createdRoleId);
    if (!createdRole) {
      throw new Error("Timeout on create Role");
    }
    return createdRole;
  });
};

export const updateRole = async (tenantId: string, id: string, payload: SingleNewRole): Promise<RoleDTO> => {
  await updateRoleApi(id, payload);

  return await retryUntil(async () => {
    const updatedRole = await getRoleData(id);
    const [changedKey, changedValue] = Object.entries(payload)[0];
    if (!updatedRole || !isEqual((updatedRole as any)[changedKey], changedValue)) {
      throw new Error("Timeout on update role");
    }
    return updatedRole;
  });
};
