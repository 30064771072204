/* tslint:disable */
/* eslint-disable */
/**
 * AI Tech Docs API
 * API for managing AI Tech Docs
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AITechDocDTO
 */
export interface AITechDocDTO {
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'creatorUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'updaterUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'assigneeUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'ownerUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'aiExpertUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'status'?: AITechDocDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'mainOrgUnitId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AITechDocDTO
     */
    'furtherOrgUnitIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AITechDocDTO
     */
    'labelIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'hardwareRequirements'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AITechDocDTO
     */
    'hasMultipleConfigs'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'hasMultipleConfigsExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AITechDocDTO
     */
    'hasAdminSettingHyperParameters'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'hasAdminSettingHyperParametersExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'energyConsumptionExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AITechDocDTO
     */
    'isTrainedOrFineTuned'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AITechDocDTO
     */
    'trainingMethodIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'trainingMethodExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'trainingMethodDecisionExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'trainingDataSelectionExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'trainingDataModificationExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AITechDocDTO
     */
    'isTrainingDataAnonymized'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'trainingDataAnonymizationExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AITechDocDTO
     */
    'isTrainingDataUsingSyntheticData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'trainingDataSyntheticDataExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AITechDocDTO
     */
    'permission'?: AITechDocDTOPermissionEnum;
}

export const AITechDocDTOStatusEnum = {
    Edit: 'edit',
    Review: 'review',
    Approved: 'approved'
} as const;

export type AITechDocDTOStatusEnum = typeof AITechDocDTOStatusEnum[keyof typeof AITechDocDTOStatusEnum];
export const AITechDocDTOPermissionEnum = {
    Read: 'read',
    Write: 'write'
} as const;

export type AITechDocDTOPermissionEnum = typeof AITechDocDTOPermissionEnum[keyof typeof AITechDocDTOPermissionEnum];

/**
 * 
 * @export
 * @interface CreateAITechDocDTO
 */
export interface CreateAITechDocDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'assigneeUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'ownerUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'aiExpertUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'status'?: CreateAITechDocDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'mainOrgUnitId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAITechDocDTO
     */
    'furtherOrgUnitIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAITechDocDTO
     */
    'labelIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'hardwareRequirements'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAITechDocDTO
     */
    'hasMultipleConfigs'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'hasMultipleConfigsExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAITechDocDTO
     */
    'hasAdminSettingHyperParameters'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'hasAdminSettingHyperParametersExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'energyConsumptionExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAITechDocDTO
     */
    'isTrainedOrFineTuned'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAITechDocDTO
     */
    'trainingMethodIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'trainingMethodExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'trainingMethodDecisionExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'trainingDataSelectionExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'trainingDataModificationExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAITechDocDTO
     */
    'isTrainingDataAnonymized'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'trainingDataAnonymizationExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAITechDocDTO
     */
    'isTrainingDataUsingSyntheticData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAITechDocDTO
     */
    'trainingDataSyntheticDataExplanation'?: string;
}

export const CreateAITechDocDTOStatusEnum = {
    Edit: 'edit',
    Review: 'review',
    Approved: 'approved'
} as const;

export type CreateAITechDocDTOStatusEnum = typeof CreateAITechDocDTOStatusEnum[keyof typeof CreateAITechDocDTOStatusEnum];

/**
 * 
 * @export
 * @interface ErrorDTO
 */
export interface ErrorDTO {
    /**
     * 
     * @type {string}
     * @memberof ErrorDTO
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorDTO
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ExportAITechDocsAsExcelRequest
 */
export interface ExportAITechDocsAsExcelRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportAITechDocsAsExcelRequest
     */
    'aiTechDocIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OverviewDisabledActionDTO
 */
export interface OverviewDisabledActionDTO {
    /**
     * 
     * @type {string}
     * @memberof OverviewDisabledActionDTO
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface OverviewItemDTO
 */
export interface OverviewItemDTO {
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'status'?: OverviewItemDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'mainOrgUnitId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OverviewItemDTO
     */
    'furtherOrgUnitIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OverviewItemDTO
     */
    'trainingMethodIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OverviewItemDTO
     */
    'labelIds'?: Array<string>;
    /**
     * 
     * @type {Array<OverviewDisabledActionDTO>}
     * @memberof OverviewItemDTO
     */
    'disableActions'?: Array<OverviewDisabledActionDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof OverviewItemDTO
     */
    'seen'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'permission'?: OverviewItemDTOPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'creatorUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewItemDTO
     */
    'updatedBy'?: string;
}

export const OverviewItemDTOStatusEnum = {
    Edit: 'edit',
    Review: 'review',
    Approved: 'approved'
} as const;

export type OverviewItemDTOStatusEnum = typeof OverviewItemDTOStatusEnum[keyof typeof OverviewItemDTOStatusEnum];
export const OverviewItemDTOPermissionEnum = {
    Read: 'read',
    Write: 'write'
} as const;

export type OverviewItemDTOPermissionEnum = typeof OverviewItemDTOPermissionEnum[keyof typeof OverviewItemDTOPermissionEnum];

/**
 * 
 * @export
 * @interface OverviewItemsDTO
 */
export interface OverviewItemsDTO {
    /**
     * 
     * @type {Array<OverviewItemDTO>}
     * @memberof OverviewItemsDTO
     */
    'items'?: Array<OverviewItemDTO>;
}
/**
 * 
 * @export
 * @interface UnseenCountDTO
 */
export interface UnseenCountDTO {
    /**
     * 
     * @type {number}
     * @memberof UnseenCountDTO
     */
    'unseenCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UnseenCountDTO
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface UpdateAITechDocDTO
 */
export interface UpdateAITechDocDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'assigneeUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'ownerUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'aiExpertUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'status'?: UpdateAITechDocDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'mainOrgUnitId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAITechDocDTO
     */
    'furtherOrgUnitIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAITechDocDTO
     */
    'labelIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'hardwareRequirements'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAITechDocDTO
     */
    'hasMultipleConfigs'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'hasMultipleConfigsExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAITechDocDTO
     */
    'hasAdminSettingHyperParameters'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'hasAdminSettingHyperParametersExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'energyConsumptionExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAITechDocDTO
     */
    'isTrainedOrFineTuned'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAITechDocDTO
     */
    'trainingMethodIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'trainingMethodExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'trainingMethodDecisionExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'trainingDataSelectionExplanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'trainingDataModificationExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAITechDocDTO
     */
    'isTrainingDataAnonymized'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'trainingDataAnonymizationExplanation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAITechDocDTO
     */
    'isTrainingDataUsingSyntheticData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAITechDocDTO
     */
    'trainingDataSyntheticDataExplanation'?: string;
}

export const UpdateAITechDocDTOStatusEnum = {
    Edit: 'edit',
    Review: 'review',
    Approved: 'approved'
} as const;

export type UpdateAITechDocDTOStatusEnum = typeof UpdateAITechDocDTOStatusEnum[keyof typeof UpdateAITechDocDTOStatusEnum];


/**
 * AITechDocsApi - axios parameter creator
 * @export
 */
export const AITechDocsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new AI Tech Doc
         * @param {CreateAITechDocDTO} createAITechDocDTO 
         * @param {string} [wait] Wait for the resource to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAITechDoc: async (createAITechDocDTO: CreateAITechDocDTO, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAITechDocDTO' is not null or undefined
            assertParamExists('createAITechDoc', 'createAITechDocDTO', createAITechDocDTO)
            const localVarPath = `/api/aitechdocs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAITechDocDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete AI Tech Doc by ID
         * @param {string} aiTechDocId 
         * @param {string} [wait] Wait for the resource to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAITechDoc: async (aiTechDocId: string, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiTechDocId' is not null or undefined
            assertParamExists('deleteAITechDoc', 'aiTechDocId', aiTechDocId)
            const localVarPath = `/api/aitechdocs/{aiTechDocId}`
                .replace(`{${"aiTechDocId"}}`, encodeURIComponent(String(aiTechDocId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export AI Tech Docs as Excel
         * @param {ExportAITechDocsAsExcelRequest} exportAITechDocsAsExcelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAITechDocsAsExcel: async (exportAITechDocsAsExcelRequest: ExportAITechDocsAsExcelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportAITechDocsAsExcelRequest' is not null or undefined
            assertParamExists('exportAITechDocsAsExcel', 'exportAITechDocsAsExcelRequest', exportAITechDocsAsExcelRequest)
            const localVarPath = `/api/aitechdocs/overview/exports/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportAITechDocsAsExcelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get AI Tech Doc by ID
         * @param {string} aiTechDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAITechDoc: async (aiTechDocId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiTechDocId' is not null or undefined
            assertParamExists('getAITechDoc', 'aiTechDocId', aiTechDocId)
            const localVarPath = `/api/aitechdocs/{aiTechDocId}`
                .replace(`{${"aiTechDocId"}}`, encodeURIComponent(String(aiTechDocId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the count of unseen AI Tech Docs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAITechDocUnseenCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/aitechdocs/overview/unseen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an overview of AI Tech Docs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewOfAITechDocs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/aitechdocs/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update AI Tech Doc by ID
         * @param {string} aiTechDocId 
         * @param {UpdateAITechDocDTO} updateAITechDocDTO 
         * @param {string} [wait] Wait for the resource to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAITechDoc: async (aiTechDocId: string, updateAITechDocDTO: UpdateAITechDocDTO, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiTechDocId' is not null or undefined
            assertParamExists('updateAITechDoc', 'aiTechDocId', aiTechDocId)
            // verify required parameter 'updateAITechDocDTO' is not null or undefined
            assertParamExists('updateAITechDoc', 'updateAITechDocDTO', updateAITechDocDTO)
            const localVarPath = `/api/aitechdocs/{aiTechDocId}`
                .replace(`{${"aiTechDocId"}}`, encodeURIComponent(String(aiTechDocId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAITechDocDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AITechDocsApi - functional programming interface
 * @export
 */
export const AITechDocsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AITechDocsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new AI Tech Doc
         * @param {CreateAITechDocDTO} createAITechDocDTO 
         * @param {string} [wait] Wait for the resource to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAITechDoc(createAITechDocDTO: CreateAITechDocDTO, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAITechDoc(createAITechDocDTO, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete AI Tech Doc by ID
         * @param {string} aiTechDocId 
         * @param {string} [wait] Wait for the resource to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAITechDoc(aiTechDocId: string, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAITechDoc(aiTechDocId, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export AI Tech Docs as Excel
         * @param {ExportAITechDocsAsExcelRequest} exportAITechDocsAsExcelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAITechDocsAsExcel(exportAITechDocsAsExcelRequest: ExportAITechDocsAsExcelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAITechDocsAsExcel(exportAITechDocsAsExcelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get AI Tech Doc by ID
         * @param {string} aiTechDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAITechDoc(aiTechDocId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AITechDocDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAITechDoc(aiTechDocId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the count of unseen AI Tech Docs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAITechDocUnseenCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnseenCountDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAITechDocUnseenCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an overview of AI Tech Docs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverviewOfAITechDocs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverviewItemsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverviewOfAITechDocs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update AI Tech Doc by ID
         * @param {string} aiTechDocId 
         * @param {UpdateAITechDocDTO} updateAITechDocDTO 
         * @param {string} [wait] Wait for the resource to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAITechDoc(aiTechDocId: string, updateAITechDocDTO: UpdateAITechDocDTO, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAITechDoc(aiTechDocId, updateAITechDocDTO, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AITechDocsApi - factory interface
 * @export
 */
export const AITechDocsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AITechDocsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new AI Tech Doc
         * @param {CreateAITechDocDTO} createAITechDocDTO 
         * @param {string} [wait] Wait for the resource to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAITechDoc(createAITechDocDTO: CreateAITechDocDTO, wait?: string, options?: any): AxiosPromise<object> {
            return localVarFp.createAITechDoc(createAITechDocDTO, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete AI Tech Doc by ID
         * @param {string} aiTechDocId 
         * @param {string} [wait] Wait for the resource to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAITechDoc(aiTechDocId: string, wait?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAITechDoc(aiTechDocId, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export AI Tech Docs as Excel
         * @param {ExportAITechDocsAsExcelRequest} exportAITechDocsAsExcelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAITechDocsAsExcel(exportAITechDocsAsExcelRequest: ExportAITechDocsAsExcelRequest, options?: any): AxiosPromise<File> {
            return localVarFp.exportAITechDocsAsExcel(exportAITechDocsAsExcelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get AI Tech Doc by ID
         * @param {string} aiTechDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAITechDoc(aiTechDocId: string, options?: any): AxiosPromise<AITechDocDTO> {
            return localVarFp.getAITechDoc(aiTechDocId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the count of unseen AI Tech Docs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAITechDocUnseenCount(options?: any): AxiosPromise<UnseenCountDTO> {
            return localVarFp.getAITechDocUnseenCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an overview of AI Tech Docs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewOfAITechDocs(options?: any): AxiosPromise<OverviewItemsDTO> {
            return localVarFp.getOverviewOfAITechDocs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update AI Tech Doc by ID
         * @param {string} aiTechDocId 
         * @param {UpdateAITechDocDTO} updateAITechDocDTO 
         * @param {string} [wait] Wait for the resource to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAITechDoc(aiTechDocId: string, updateAITechDocDTO: UpdateAITechDocDTO, wait?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateAITechDoc(aiTechDocId, updateAITechDocDTO, wait, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AITechDocsApi - object-oriented interface
 * @export
 * @class AITechDocsApi
 * @extends {BaseAPI}
 */
export class AITechDocsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new AI Tech Doc
     * @param {CreateAITechDocDTO} createAITechDocDTO 
     * @param {string} [wait] Wait for the resource to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AITechDocsApi
     */
    public createAITechDoc(createAITechDocDTO: CreateAITechDocDTO, wait?: string, options?: AxiosRequestConfig) {
        return AITechDocsApiFp(this.configuration).createAITechDoc(createAITechDocDTO, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete AI Tech Doc by ID
     * @param {string} aiTechDocId 
     * @param {string} [wait] Wait for the resource to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AITechDocsApi
     */
    public deleteAITechDoc(aiTechDocId: string, wait?: string, options?: AxiosRequestConfig) {
        return AITechDocsApiFp(this.configuration).deleteAITechDoc(aiTechDocId, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export AI Tech Docs as Excel
     * @param {ExportAITechDocsAsExcelRequest} exportAITechDocsAsExcelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AITechDocsApi
     */
    public exportAITechDocsAsExcel(exportAITechDocsAsExcelRequest: ExportAITechDocsAsExcelRequest, options?: AxiosRequestConfig) {
        return AITechDocsApiFp(this.configuration).exportAITechDocsAsExcel(exportAITechDocsAsExcelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get AI Tech Doc by ID
     * @param {string} aiTechDocId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AITechDocsApi
     */
    public getAITechDoc(aiTechDocId: string, options?: AxiosRequestConfig) {
        return AITechDocsApiFp(this.configuration).getAITechDoc(aiTechDocId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the count of unseen AI Tech Docs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AITechDocsApi
     */
    public getAITechDocUnseenCount(options?: AxiosRequestConfig) {
        return AITechDocsApiFp(this.configuration).getAITechDocUnseenCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an overview of AI Tech Docs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AITechDocsApi
     */
    public getOverviewOfAITechDocs(options?: AxiosRequestConfig) {
        return AITechDocsApiFp(this.configuration).getOverviewOfAITechDocs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update AI Tech Doc by ID
     * @param {string} aiTechDocId 
     * @param {UpdateAITechDocDTO} updateAITechDocDTO 
     * @param {string} [wait] Wait for the resource to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AITechDocsApi
     */
    public updateAITechDoc(aiTechDocId: string, updateAITechDocDTO: UpdateAITechDocDTO, wait?: string, options?: AxiosRequestConfig) {
        return AITechDocsApiFp(this.configuration).updateAITechDoc(aiTechDocId, updateAITechDocDTO, wait, options).then((request) => request(this.axios, this.basePath));
    }
}


