import React from "react";
import { Box } from "@mui/material";
import DocView from "components/DocView/DocView";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import { sx } from "./HomeStyles";
import { useHomeLogic } from "./useHomeLogic";
import HomeOverview from "./overview/HomeOverview";
import HomeHeader from "./HomeHeader";
import HomeTaskDetailsOverlay from "./details/HomeTaskDetailsOverlay";

export default function Home() {
  const {
    handleNewTaskButtonClick,
    handleRowClick,
    handleSeeAllTasksButtonClick,
    isSlidedIn,
    name,
    noEntriesEl,
    pages,
    reload,
    selectedRow,
    setIsSlidedIn,
    t
  } = useHomeLogic();

  return (
    <>
      <DocMetaView
        docViewContent={
          <DocView header={t("page_title", { name })}>
            <HomeHeader subtitle={t("page_subtitle")} />
            <Box sx={sx.taskOverviewContainer}>
              <HomeOverview
                noEntriesEl={noEntriesEl}
                pages={pages}
                title={t("you_next_tasks")}
                onNewTask={handleNewTaskButtonClick}
                onRowClick={handleRowClick}
                onSeeAllTasks={handleSeeAllTasksButtonClick}
              />
            </Box>
          </DocView>
        }
        metaViewContent={<Box />}
      />
      <HomeTaskDetailsOverlay
        isSlidedIn={isSlidedIn}
        reload={reload}
        selectedRow={selectedRow}
        setIsSlidedIn={setIsSlidedIn}
        setSelectedRow={handleRowClick}
      />
    </>
  );
}
