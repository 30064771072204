import { MetaViewAIInputProps, useMetaView } from "../../app/contexts/meta-view-context";
import { Box, Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AutoAwesomeRounded } from "@mui/icons-material";
import { useCallback } from "react";
import { v4 } from "uuid";

export interface AIAskAIButtonProps {
  readonly fieldInput?: MetaViewAIInputProps;
  readonly onSuggestionCallback?: (suggestions: object, originalInput: MetaViewAIInputProps) => void;
}

export const AIAskAIButton = ({ fieldInput, onSuggestionCallback }: AIAskAIButtonProps) => {
  const { setAIIntegrationMode, setAIIntegrationFieldInput, setAIIntegrationSuggestionCallback, setSelectedTab } =
    useMetaView();
  const { t } = useTranslation();
  const onClick = useCallback(() => {
    setSelectedTab("ai-integration");
    setAIIntegrationMode("suggest-field");
    if (fieldInput) {
      setAIIntegrationFieldInput({
        ...fieldInput,
        explicitSessionId: v4() // making sure clicking the button resets the session always
      });
    }
    if (onSuggestionCallback) {
      setAIIntegrationSuggestionCallback(() => onSuggestionCallback);
    }
  }, [
    fieldInput,
    onSuggestionCallback,
    setAIIntegrationFieldInput,
    setAIIntegrationMode,
    setAIIntegrationSuggestionCallback,
    setSelectedTab
  ]);

  const theme = useTheme();

  const greyBGWithTransparencyForTextReadability = `${theme.palette.grey["100"]}cc`;
  return (
    <Box bgcolor={greyBGWithTransparencyForTextReadability} borderRadius={1}>
      <Button color="primary" variant="text" startIcon={<AutoAwesomeRounded />} onClick={onClick}>
        {t("ai-metaview:ask")}
      </Button>
    </Box>
  );
};
