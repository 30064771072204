import { useMemo } from "react";
import { COLLECTIONS } from "app/collections";
import Overview, { OverviewParams } from "components/Overview/Overview";
import { ProcessesOverviewCollectionParams, ProcessFilterOptions } from "./ProcessesOverviewController";
import { OverviewProvider } from "../../../contexts/overview-context";
import OverviewBrick from "components/Overview/bricks/OverviewBrick";
import { OverviewBrickProps } from "components/Overview/controls/OverviewRow";
import { OverviewAddButtonActionProps } from "components/Overview/controls/OverviewAddButton";

export interface ProcessesOverviewReadOnlyProps
  extends Pick<OverviewParams, "onRowOver" | "onRowLeave" | "noEntriesText" | "header"> {
  readonly filter: Partial<ProcessFilterOptions>;
  readonly renderId?: string;
  readonly addActions?: OverviewAddButtonActionProps[];
  readonly onUnlink?: (id: string) => void;
}

export const ProcessesOverviewReadOnly = (props: ProcessesOverviewReadOnlyProps) => {
  const collectionParams = useMemo<ProcessesOverviewCollectionParams>(
    () => ({
      exportToAttachments: Promise.reject,
      exportToPdfDialog: Promise.reject,
      exportToXLSX: Promise.reject,
      filter: props.filter,
      openInNewTab: true
    }),
    [props.filter]
  );

  const briks = useMemo<OverviewBrickProps[]>(() => {
    const defaultBricks: OverviewBrickProps[] = [
      { component: OverviewBrick.ProcessStatus },
      { component: OverviewBrick.Title },
      { component: OverviewBrick.Readonly }
    ];
    if (props.onUnlink) {
      return [
        ...defaultBricks,
        {
          component: brickProps => (
            <OverviewBrick.Unlink item={brickProps.item} hovered={brickProps.hovered} onClick={props.onUnlink} />
          ),
          position: "right" as "left" | "right"
        }
      ];
    } else return defaultBricks;
  }, [props.onUnlink]);

  return (
    // this new overview provider is so that it would not share the same state as the main pa overview e.g. applied filter
    <OverviewProvider>
      <Overview
        key={props.renderId}
        collection={COLLECTIONS.PROCESSES}
        collectionParams={collectionParams}
        header={props.header}
        noEntriesText={props.noEntriesText}
        onRowLeave={props.onRowLeave}
        onRowOver={props.onRowOver}
        bricks={briks}
        toolbarMode={"tabs"}
        showSelectAll={false}
      />
    </OverviewProvider>
  );
};
