import { useCallback, useEffect, useMemo, useState, MouseEvent } from "react";
import { Box, makeStyles, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import AssignGroupsMultiAutocomplete from "app/pages/questionnaires/utils/AssignGroupsMultiAutocomplete";
import AssignToGroupIconComponent from "../../../../assets/images/tasks/assign-to-group.svg";
import ChipsStack, { ChipVariant, type ChipProps } from "components/ChipsStack/ChipsStack";
import CancelIcon from "@mui/icons-material/Cancel";
import { useUserGroups } from "app/contexts/group-context";
import { GroupChipInfo } from "app/pages/tasks/controls/TaskGroupChipInfo";
import { GroupTask } from "app/api/groupTaskApi";
import { getAllGroupStatusByTaskId } from "app/handlers/tasksHandler";
import { useSnackbar } from "notistack";
import stopEvent from "tool/stopEvent";
import { useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles(theme => ({
  groupIconContainer: {
    height: "24px",
    width: "24px",
    textTransform: "uppercase",
    cursor: "pointer",
    fontSize: "10px",
    color: theme.palette.common.white
  },
  addGroupDashed: {
    border: "1px dashed",
    fontWeight: 600,
    fontSize: "10px",
    letterSpacing: "0.5px",
    borderRadius: "12px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[400]
  }
}));

interface TaskOverviewRowAssignGroupsProps {
  readonly taskId?: string;
  readonly groupIds: string[];
  readonly onChange: (groupIds: string[]) => void;
  readonly showGroups?: boolean;
  readonly showAddGroup?: boolean;
}
const TaskOverviewRowAssignGroups = ({
  taskId,
  groupIds,
  onChange,
  showGroups = false,
  showAddGroup = false
}: TaskOverviewRowAssignGroupsProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { getGroupNameHook } = useUserGroups();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [groupInfoModalOpen, setGroupInfoModalOpen] = useState<boolean>(false);
  const [selectedGroupsProgress, setSelectedGroupsProgress] = useState<GroupTask[]>([]);
  const [allGroupsProgress, setAllGroupsProgress] = useState<GroupTask[]>([]);
  const [selectedGroupName, setSelectedGroupName] = useState<string>("");

  useEffect(() => {
    setSelectedIds(groupIds || []);
  }, [groupIds]);

  const openMenuCallback = useCallback(() => {
    setOpen(true);
  }, []);

  const closeMenuCallback = useCallback(() => {
    setOpen(false);
  }, []);

  const onSaveCallback = useCallback(() => {
    onChange(selectedIds);
    closeMenuCallback();
  }, [closeMenuCallback, onChange, selectedIds]);

  const onChangeCallback = useCallback(async (ids: string[]): Promise<void> => {
    setSelectedIds(ids);
  }, []);

  const closeGroupInfoModal = useCallback(() => {
    setGroupInfoModalOpen(false);
  }, []);

  const loadGroupsData = useCallback(async () => {
    let groupsData;
    if (!taskId) {
      return;
    }
    try {
      groupsData = await getAllGroupStatusByTaskId(taskId);
    } catch (error) {
      enqueueSnackbar("Error in fetching group progress", { variant: "error" });
      console.error("Error in fetching task", error);
    }
    setAllGroupsProgress(groupsData);
  }, [enqueueSnackbar, taskId]);

  useEffect(() => {
    if (groupIds?.length > 0 && taskId) {
      loadGroupsData();
    }
  }, [groupIds, loadGroupsData, taskId]);

  const onGroupChipClicked = useCallback(
    (groupId: string) => {
      const name = getGroupNameHook(groupId);
      const specificGroupDetail = allGroupsProgress.filter(task => task.groupId === groupId);
      setSelectedGroupName(name);
      setSelectedGroupsProgress(specificGroupDetail);
      setGroupInfoModalOpen(true);
    },
    [allGroupsProgress, getGroupNameHook]
  );

  const assignTaskModalBody = (
    <AssignGroupsMultiAutocomplete
      docAssignedGroupIds={selectedIds}
      onDocAssignedGroupIdsChanged={onChangeCallback}
      freeSolo={false}
      disableClearable={true}
      label={t("groups_overview:title")}
      hasMultiSelect={true}
    />
  );

  const assignTaskModalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: closeMenuCallback
      },
      {
        confirmButton: true,
        disabled: false,
        title: t("save"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: onSaveCallback
      }
    ],
    [closeMenuCallback, onSaveCallback, t]
  );

  const groupsData = useMemo(() => {
    return groupIds?.map(groupId => {
      return { id: groupId, name: getGroupNameHook(groupId) };
    });
  }, [getGroupNameHook, groupIds]);

  const groupChips: ChipProps[] = useMemo(
    () => [
      ...(groupsData
        ? groupsData.map(group => ({
            key: group.id,
            label: group.name,
            leftIcon: AssignToGroupIconComponent as React.ElementType,
            onClick: () => {
              onGroupChipClicked(group.id);
            },
            onRightIconClick: (event: MouseEvent<Element>) => {
              stopEvent(event);
              onChange(groupIds.filter(id => id !== group.id));
            },
            rightIcon: CancelIcon,
            rightIconToolTip: t("remove_group"),
            styles: {
              backgroundColor: theme.palette.grey[200],
              borderRadius: "22px",
              color: "rgba(31, 31, 31, 1)",
              fontSize: "14px",
              fontWeight: 450,
              height: "24px",
              leftIconMarginLeft: "0",
              leftIconMarginRight: "10px",
              letterSpacing: "0.15px",
              noTextTransform: true,
              rightIconColor: "rgba(112, 116, 119, 0.6)",
              rightIconMarginLeft: "6px",
              rightIconMarginRight: "0",
              "& svg": {
                width: "16px",
                height: "16px"
              }
            },
            type: "label",
            variant: ChipVariant.CONTAINED
          }))
        : []),
      ...(showAddGroup
        ? [
            {
              key: "add-group",
              label: `${t("add_group").toUpperCase()}`,
              onClick: openMenuCallback,
              leftIcon: AddIcon,
              styles: {
                borderRadius: "22px",
                color: theme.palette.grey[400],
                height: "24px"
              },
              type: "label",
              variant: ChipVariant.DASHED
            }
          ]
        : [])
    ],
    [groupIds, groupsData, onChange, onGroupChipClicked, openMenuCallback, showAddGroup, t, theme]
  );

  return (
    <>
      <Tooltip title={t("changeAssignee")}>
        {showGroups ? (
          <Box>
            <ChipsStack chips={groupChips} />
          </Box>
        ) : (
          <Box className={cls.groupIconContainer} onClick={openMenuCallback}>
            <AssignToGroupIconComponent />
          </Box>
        )}
      </Tooltip>

      <ConfirmationModal
        modalOpen={open}
        onClose={closeMenuCallback}
        modalTitle={t("add_group")}
        modalBody={assignTaskModalBody}
        buttons={assignTaskModalButtons}
        variant="info"
        modalText={t("add_group_modal_text")}
      />

      {groupInfoModalOpen && (
        <GroupChipInfo
          onClose={closeGroupInfoModal}
          groupDetails={selectedGroupsProgress}
          selectedGroupName={selectedGroupName}
        />
      )}
    </>
  );
};

export default TaskOverviewRowAssignGroups;
