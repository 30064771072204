import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

// Context to store the document name
const DocumentNameContext = createContext<{
  documentName: string;
  setDocumentName: (name: string) => void;
  clearDocumentName: () => void;
} | null>(null);

const documentNameProviderNamespace = "caralegal::breadcrumbs::";

// Provider to wrap the app
export const DocumentNameProvider = ({ children }: { children: React.ReactNode }) => {
  const [documentName, setDocumentNameState] = useState<string>(""); // Default to an empty name
  useEffect(() => {
    // Retrieve document name from sessionStorage. on initial load
    const storedName = sessionStorage.getItem(`${documentNameProviderNamespace}documentName`);
    if (storedName) {
      setDocumentNameState(storedName);
    }
  }, []);

  const setDocumentName = useCallback((name: string) => {
    setDocumentNameState(name);
    sessionStorage.setItem(`${documentNameProviderNamespace}documentName`, name);
  }, []);

  const clearDocumentName = useCallback(() => {
    setDocumentNameState("");
    sessionStorage.removeItem(`${documentNameProviderNamespace}documentName`);
  }, []);

  const value = useMemo(
    () => ({ documentName, setDocumentName, clearDocumentName }),
    [documentName, setDocumentName, clearDocumentName]
  );

  return <DocumentNameContext.Provider value={value}>{children}</DocumentNameContext.Provider>;
};

// Hook to use the document name
export const useDocumentName = () => {
  const context = useContext(DocumentNameContext);
  if (!context) {
    throw new Error("useDocumentName must be used within a DocumentNameProvider");
  }
  return context;
};
