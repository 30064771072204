export const getBoldSearchText = (input: { readonly title: string; readonly search?: string }) => {
  const { title, search } = input;
  if (search) {
    const searchMatchPosition = title.toLowerCase().indexOf(search.toLowerCase());
    if (searchMatchPosition !== -1) {
      const beforeMatch = title.substring(0, searchMatchPosition);
      const match = title.substring(searchMatchPosition, searchMatchPosition + search.length);
      const afterMatch = title.substring(searchMatchPosition + search.length);
      return (
        <>
          {beforeMatch}
          <strong>{match}</strong>
          {afterMatch}
        </>
      );
    }
  }
  return title;
};
