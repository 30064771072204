import React, { useCallback, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";

/**
 * @deprecated
 */
export default function OverviewSearchTextField({
  searchKeyword,
  setSearchKeyword,
  size,
  placeholder,
  variant,
  fullWidth = false
}) {
  const inputProps = useMemo(() => {
    return {
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      )
    };
  }, []);

  const onChange = useCallback(
    event => {
      setSearchKeyword(event.target.value);
    },
    [setSearchKeyword]
  );

  return (
    <TextField
      fullWidth={fullWidth}
      id="overview-search-input"
      variant={variant}
      size={size}
      placeholder={placeholder}
      value={searchKeyword}
      onChange={onChange}
      InputProps={inputProps}
    />
  );
}
