import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import colors from "theme/palette/colors";

const sx = {
  py: 2,
  px: 6,
  mx: 6,
  mt: 6,
  background: colors.blue.blue50,
  color: colors.grey.grey500,
  borderRadius: "6px"
};

interface OverviewNoItemsFoundProps {
  readonly text?: string;
  readonly align?: "left" | "center" | "right";
}
const OverviewNoItemsFound = ({ text, align }: OverviewNoItemsFoundProps) => {
  const { t } = useTranslation("overview");
  return <Box sx={{ ...sx, ...(align ? { textAlign: align } : {}) }}>{text || t("no_entries_found")}</Box>;
};

export default OverviewNoItemsFound;
