import useSWR from "swr";
import { AxiosInstance } from "axios";
import { defaultOTCAuthenticatedAxios } from "../../api/axios/loggedInAxiosProvider";
import { apiEndpoints } from "../../api/apiEndpoint";
import useSWRMutation from "swr/mutation";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useTranslation } from "react-i18next";
import isEqual from "lodash-es/isEqual";
import Question from "../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../components/Question/QuestionTypes";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { RemoveCircleOutlined } from "@mui/icons-material";
import { useMappingFiles } from "./useMappingFiles";

export interface TenantIAMMappingProps {
  readonly mappingData: ExternalMappingDataDTO;
  readonly onSave: (tenantIAMMapping: ExternalMappingDataDTO) => void;
  readonly onCancel: () => void;
}

export const TenantIAMMapping = ({ mappingData, onSave, onCancel }: TenantIAMMappingProps) => {
  const { t } = useTranslation("iamConfig");
  const [currentMappingData, setCurrentMappingData] = useState<{ externalId: string; orgUnitIds: string[] }[]>([]);
  useEffect(() => {
    const currentMappingData = Object.entries(mappingData.mappingData)
      .map(([externalId, orgUnitIds]) => ({
        externalId,
        orgUnitIds
      }))
      .sort((a, b) => a.externalId.localeCompare(b.externalId));
    setCurrentMappingData(currentMappingData);
  }, [mappingData.mappingData]);

  const currentMappingDataAsRecord = useMemo(
    () =>
      currentMappingData.reduce(
        (acc, { externalId, orgUnitIds }) => {
          acc[externalId] = orgUnitIds;
          return acc;
        },
        {} as Record<string, string[]>
      ),
    [currentMappingData]
  );

  const isDirty = useMemo(
    () => !isEqual(currentMappingDataAsRecord, mappingData.mappingData),
    [currentMappingDataAsRecord, mappingData.mappingData]
  );

  const onSaveClicked = useCallback(() => {
    onSave({
      tenantId: mappingData.tenantId,
      mappingType: mappingData.mappingType,
      mappingData: currentMappingDataAsRecord
    } satisfies ExternalMappingDataDTO);
  }, [currentMappingDataAsRecord, onSave, mappingData.tenantId, mappingData.mappingType]);

  const [newGroupId, setNewGroupId] = useState<string>("");
  const [newGroupIdOrgUnitIds, setNewGroupIdOrgUnitIds] = useState<string[]>([]);
  const onNewGroupIdAdded = useCallback(() => {
    const normalizedGroupId = newGroupId.trim();
    if (!normalizedGroupId || newGroupIdOrgUnitIds.length === 0) {
      return;
    }
    setCurrentMappingData(currentMappingData => [
      ...currentMappingData,
      {
        externalId: normalizedGroupId,
        orgUnitIds: newGroupIdOrgUnitIds
      }
    ]);
    setNewGroupId("");
    setNewGroupIdOrgUnitIds([]);
  }, [newGroupId, newGroupIdOrgUnitIds, setCurrentMappingData]);

  const { downloadMappingFile, parseMappingFile } = useMappingFiles();
  const onExportClicked = useCallback(() => {
    downloadMappingFile(currentMappingData);
  }, [currentMappingData, downloadMappingFile]);
  const onImportClicked = useCallback(
    (event: any) => {
      const inputFiles = event?.target?.files || [];
      const inputFile = inputFiles[0] || null;
      if (!inputFile) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        const parsedFile = await parseMappingFile(((fileReader.result as string) || "").split("base64,")[1] || "");
        setCurrentMappingData(parsedFile);
      };
      fileReader.readAsDataURL(inputFile);
    },
    [parseMappingFile]
  );

  return (
    <Box>
      <QuestionnaireSubHeader text={t("mappingSetting")} />
      <Box>
        {currentMappingData.map(({ externalId, orgUnitIds }, i) => (
          <Box key={`mapping-data-${i}`} display="flex" justifyContent="space-between" gap={1} alignItems="center">
            <Box flex={1}>
              <Question qType={QUESTION_TYPE.TEXT_AREA} value={externalId} questionName={t("newGroupId")} disabled />
            </Box>
            <Box flex={2}>
              <Question
                qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
                questionId={"orgUnitIds"}
                questionName={t("newGroupIdOrgUnitIds")}
                value={orgUnitIds}
                disabled
              />
            </Box>
            <Box display="flex" alignItems="center">
              <IconButton /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() =>
                  setCurrentMappingData(currentMappingData => currentMappingData.filter((_, j) => i !== j))
                }
              >
                <RemoveCircleOutlined color="error" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
      <Box>
        <Box display="flex" justifyContent="space-between" gap={1} alignItems="center">
          <Box flex={1}>
            <Question
              qType={QUESTION_TYPE.TEXT_AREA}
              value={newGroupId}
              questionName={t("newGroupId")}
              onChange={setNewGroupId}
            />
          </Box>
          <Box flex={2}>
            <Question
              qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
              questionId={"newGroupIdOrgUnitIds"}
              questionName={t("newGroupIdOrgUnitIds")}
              value={newGroupIdOrgUnitIds}
              onChange={setNewGroupIdOrgUnitIds}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton onClick={onNewGroupIdAdded}>
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Button onClick={onCancel} variant="text">
          {t("common:cancel")}
        </Button>
        <Button onClick={onExportClicked} variant="outlined">
          {t("common:export")}
        </Button>
        <Button onClick={onImportClicked} variant="outlined" component="label">
          {t("questionnaireImport:import_button")}
          <input type="file" hidden onChange={onImportClicked} accept={".xlsx"} />
        </Button>
        <Button onClick={onSaveClicked} variant="contained" color="primary" disabled={!isDirty}>
          {t("common:save")}
        </Button>
      </Box>
    </Box>
  );
};

export const TenantIAMMappingWithSave = () => {
  const { data: tenantIAMMapping, isLoading } = useTenantGroupIdMappingData();
  const { trigger: upsertIAMMapping, isMutating: isUpsertingIAMMapping } = useTenantGroupIdMappingUpsert();

  const onSave = useCallback(
    (tenantIAMMapping: ExternalMappingDataDTO) => {
      const { tenantId, mappingType, ...payload } = tenantIAMMapping;
      upsertIAMMapping(payload);
    },
    [upsertIAMMapping]
  );

  const [componentId, setComponentId] = useState<string>(`${Date.now()}`);
  const onCancel = useCallback(() => {
    setComponentId(`${Date.now()}`);
  }, []);

  if (isLoading || !tenantIAMMapping) {
    return <div>Loading...</div>;
  }

  if (isUpsertingIAMMapping) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  return <TenantIAMMapping key={componentId} mappingData={tenantIAMMapping} onSave={onSave} onCancel={onCancel} />;
};

const useTenantGroupIdMappingData = () => {
  return useSWR(
    "tenantIAMGroupMapping",
    async () => {
      const response = await axiosInstance.get<ExternalMappingDataDTO>("/iams/external-mappings/groupId");
      return response.data;
    },
    {
      revalidateOnFocus: false
    }
  );
};

const useTenantGroupIdMappingUpsert = () => {
  return useSWRMutation<ExternalMappingDataDTO, any, string, UpdateExternalMappingDataDTO>(
    "tenantIAMGroupMapping",
    async (key, options) => {
      const arg = options.arg;
      const response = await axiosInstance.post<ExternalMappingDataDTO>("/iams/external-mappings/groupId", arg);
      return response.data;
    }
  );
};

export interface ExternalMappingDataDTO {
  readonly tenantId: string;
  readonly mappingType: string;
  readonly mappingData: Record<string, string[]>;
}

export type UpdateExternalMappingDataDTO = Omit<ExternalMappingDataDTO, "mappingType" | "tenantId">;

const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.userUrl}/api`
});
