import { DataAssetType } from "../../../../api/generated/asset-service";
import { orderBy } from "lodash-es";

export interface SelectedPGItem {
  readonly id: string;
  readonly assetType: "PERSON_GROUP" | "DATA_CATEGORY" | "DATA_TYPE";
  readonly children?: SelectedPGItem[];
  readonly parentId?: string | null;
}

export const removeChildrenIfParentsAlreadySelected = (input: { readonly selectedItems: SelectedPGItem[] }) => {
  const itemsSortedByAssetTypeParentAndId = orderBy(input.selectedItems, [
    item => ["PERSON_GROUP", "DATA_CATEGORY", "DATA_TYPE"].indexOf(item.assetType),
    item => item.parentId,
    item => item.id
  ]);

  const filteredItems: SelectedPGItem[] = [];

  const alreadyIncludedItemIds = new Set<string>();
  for (const item of itemsSortedByAssetTypeParentAndId) {
    const parentId = item.parentId;
    if (parentId && alreadyIncludedItemIds.has(parentId)) {
      alreadyIncludedItemIds.add(item.id);
    } else {
      filteredItems.push(item);
      alreadyIncludedItemIds.add(item.id);
    }
  }

  return filteredItems;
};

export const expandSelectedPGItems = (input: { readonly selectedItems: SelectedPGItem[] }) => {
  const pgIds: Set<string> = new Set();
  const dtIds: Set<string> = new Set();
  for (const item of input.selectedItems) {
    switch (item.assetType) {
      case DataAssetType.PersonGroup:
        pgIds.add(item.id);
        (item.children || [])
          .flatMap(it => it.children || [])
          .map(it => it.id)
          .forEach(id => dtIds.add(id));
        break;
      case DataAssetType.DataCategory:
        (item.children || []).map(it => it.id).forEach(id => dtIds.add(id));
        break;
      case DataAssetType.DataType:
        dtIds.add(item.id);
        break;
    }
  }
  return {
    pgIds: [...pgIds],
    dtIds: [...dtIds]
  };
};
