import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import AttachmentsOverview from "./AttachmentsOverview";
import AttachmentsList from "./AttachmentsList";
import { useErrorSnackbar } from "hook/errorSnackbar";
import {
  deleteAttachmentApi,
  downloadAttachmentApi,
  getAllAttachmentsApi,
  uploadAttachmentApi
} from "app/api/file-storage/attachmentsApi";
import { normaliseFileNameExtension } from "app/pages/shared/Attachments/fileAttachmentUtils";

interface AttachmentsContainerProps {
  docId: string;
  category: string;
  disabled?: boolean;
  withPrefix?: boolean;
  defaultInfo?: {
    title: string;
    text: string;
  };
}

interface AttachmentFile {
  name: string;
  uploadedBy?: string;
  timeCreated?: string;
  size?: number;
  status: "loading" | "complete";
}

// container component that handles file upload and display logic
const AttachmentsContainer = ({ docId, category, disabled, withPrefix, defaultInfo }: AttachmentsContainerProps) => {
  const [files, setFiles] = useState<AttachmentFile[]>([]);
  const [uploadStatus, setUploadStatus] = useState<"idle" | "uploading" | "downloading">("idle");
  const { catchAsSnackbar } = useErrorSnackbar();

  const fileUploadSubFolderName = `${category}-${docId}`;

  // load existing files
  const loadFiles = useCallback(async () => {
    try {
      const data = await getAllAttachmentsApi({ folderName: fileUploadSubFolderName, withPrefix });
      const sortedData = data.sort((a, b) => b.timeCreated.localeCompare(a.timeCreated));
      setFiles(sortedData.map(file => ({ ...file, status: "complete" })));
    } catch (error) {
      catchAsSnackbar("Failed to load files")(error);
    }
  }, [fileUploadSubFolderName, withPrefix, catchAsSnackbar]);

  // handle file upload
  const handleFileSelect = useCallback(
    async (selectedFiles: File[]) => {
      setUploadStatus("uploading");
      try {
        // Add files to list immediately with loading status
        const newFiles = selectedFiles.map(file => ({
          name: normaliseFileNameExtension(file.name.replace(/\s+/g, "")),
          size: file.size,
          status: "loading" as const
        }));
        setFiles(prevFiles => [...newFiles, ...prevFiles]);

        const uploadPromises = selectedFiles.map(file => {
          return uploadAttachmentApi({ file, folderName: fileUploadSubFolderName, path: "" });
        });

        await Promise.all(uploadPromises);
        await loadFiles();
      } catch (error) {
        catchAsSnackbar("Failed to upload files")(error);
      } finally {
        setUploadStatus("idle");
      }
    },
    [fileUploadSubFolderName, loadFiles, catchAsSnackbar]
  );

  // handle file download
  const handleDownload = useCallback(
    async (file: AttachmentFile) => {
      setUploadStatus("downloading");
      try {
        const folderName = withPrefix ? `${fileUploadSubFolderName}${file.uploadedBy}` : fileUploadSubFolderName;
        await downloadAttachmentApi({ fileName: file.name, folderName });
      } catch (error) {
        catchAsSnackbar(`Failed to download file: ${file.name}`)(error);
      } finally {
        setUploadStatus("idle");
      }
    },
    [fileUploadSubFolderName, withPrefix, catchAsSnackbar]
  );

  // handle file deletion
  const handleDelete = useCallback(
    async (file: AttachmentFile) => {
      setUploadStatus("idle");
      try {
        const folderName = withPrefix ? `${fileUploadSubFolderName}${file.uploadedBy}` : fileUploadSubFolderName;
        await deleteAttachmentApi({ fileName: file.name, folderName });
        await loadFiles();
      } catch (error) {
        catchAsSnackbar(`Failed to delete file: ${file.name}`)(error);
      }
    },
    [fileUploadSubFolderName, withPrefix, loadFiles, catchAsSnackbar]
  );

  // load files on mount
  useEffect(() => {
    if (docId) {
      loadFiles();
    }
  }, [docId, loadFiles]);

  return (
    <Box sx={{ width: "100%" }}>
      <AttachmentsOverview disabled={disabled || uploadStatus !== "idle"} onFileSelect={handleFileSelect} />
      <Box sx={{ mt: 2 }}>
        <AttachmentsList
          files={files}
          disabled={disabled || uploadStatus !== "idle"}
          onDownload={handleDownload}
          onDelete={handleDelete}
          defaultInfo={defaultInfo}
        />
      </Box>
    </Box>
  );
};

export default AttachmentsContainer;
