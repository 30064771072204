import { Box, Tooltip } from "@mui/material";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useTranslation } from "react-i18next";

const ExternalRecipientOverviewBrickStatus = ({ item }: OverviewRowBrickProps) => {
  const approved = item.approved;
  const { t } = useTranslation();
  const status = approved ? "approved" : "notApproved";
  return (
    <Tooltip title={t(`service_providers_overview:${status}`)}>
      <Box>{approved ? <LazySvgIcon name="Completed" /> : <LazySvgIcon name="In_Progress" />}</Box>
    </Tooltip>
  );
};

export default ExternalRecipientOverviewBrickStatus;
