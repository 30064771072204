import { apiEndpoints } from "app/api/apiEndpoint";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import i18n from "app/i18n";
import useSWRInfinite from "swr/infinite";
import { useCallback, useMemo } from "react";

const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.externalRecipientUrl}/api`
});

export type ServiceType =
  | "SERVICEPROVIDER"
  | "PARENTCOMPANY"
  | "SUBSIDIARY"
  | "AFFILIATE"
  | "PARTNER"
  | "CUSTOMER"
  | "OTHER";

export type ExternalRecipientsDTO = { externalRecipients: ExternalRecipientDTO[] };

export interface ExternalRecipientDTO extends ExternalRecipientDocumentFieldsDTO {
  readonly tenantId: string;
}

export interface ExternalRecipientDocumentFieldsDTO {
  readonly id: string;
  readonly name: string;
  readonly serviceType: ServiceType;
  readonly street: string | null;
  readonly ZIP: string | null;
  readonly city: string | null;
  readonly country: string | null;
  readonly phone: string | null;
  readonly externalContactName: string | null;
  readonly externalContactEmail: string | null;
  readonly externalContactPhone: string | null;
  readonly internalContactName: string | null;
  readonly internalContactEmail: string | null;
  readonly internalContactPhone: string | null;
  readonly url: string | null;
  readonly labels: string[];
  readonly generalNotes: string | null;
  readonly responsibleOrgUnitId: string | null;
  readonly furtherOrgUnitIds: string[];
  readonly contractTypes: string[];
  readonly contractStartDate: string | null;
  readonly contractEndDate: string | null;
  readonly noContractRequiredReason?: string | null;
  readonly safeguards: string[];
  readonly derogations: string[];
  readonly dpLocations: string[];
  readonly dpExcludedDPOutsideEU: boolean | null;
  readonly dpEnsuredAllServicesProvided: boolean | null;
  readonly dpNotExcludableLocations: string[];
  readonly spUsage: boolean | null;
  readonly spRequirementApproval: boolean | null;
  readonly spListedInContract: boolean | null;
  readonly spPAgreementsConcluded: boolean | null;
  readonly spExcludedDPOutsideEU: boolean | null;
  readonly spSscComplianceObligation: boolean | null;
  readonly transferAssessment: boolean | null;
  readonly encryptions: string[];
  readonly pseudonymizations: string[];
  readonly tomsProvided: boolean | null;
  readonly complianceNotes: string | null;
  readonly approvedBy: string | null;
  readonly approvedAt: string | null;
  readonly dpaLegalReview: boolean | null;
  readonly permission: "read" | "write";
}

export interface ComplianceDisplayFields {
  readonly contractTypes?: boolean;
  readonly contractStartDate?: boolean;
  readonly contractEndDate?: boolean;
  readonly noContractRequiredReason?: boolean;
  readonly safeguards?: boolean;
  readonly derogations?: boolean;
  readonly dpLocations?: boolean;
  readonly dpExcludedDPOutsideEU?: boolean;
  readonly dpEnsuredAllServicesProvided?: boolean;
  readonly dpNotExcludableLocations?: boolean;
  readonly spUsage?: boolean;
  readonly spRequirementApproval?: boolean;
  readonly spListedInContract?: boolean;
  readonly spPAgreementsConcluded?: boolean;
  readonly spExcludedDPOutsideEU?: boolean;
  readonly spSscComplianceObligation?: boolean;
  readonly transferAssessment?: boolean;
  readonly encryptions?: boolean;
  readonly pseudonymizations?: boolean;
  readonly tomsProvided?: boolean;
  readonly complianceNotes?: boolean;
}

export interface ExternalRecipientDocumentDTO extends ExternalRecipientDocumentFieldsDTO {
  readonly displayFields: ComplianceDisplayFields;
}

export interface Badge {
  readonly seen?: boolean;
  readonly date?: string;
  readonly days?: number;
  readonly kind: string;
}

export interface ExternalRecipientOverviewItemDTO {
  readonly id: string;
  readonly title: string;
  readonly subTitle: string;
  readonly serviceType?: string;
  readonly labels?: string[];
  readonly safeguards: string[];
  readonly contractTypes: string[];
  readonly country?: string | null;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly badge?: Badge;
  readonly approved?: boolean;
  readonly approvedBy?: string;
  readonly dpExcluded?: boolean | null;
  readonly dpLocations?: string[];
  readonly numberOfProcesses?: number;
  readonly selectedInProcess?: boolean;
  readonly openTasks: string;
}

export interface LabelDTO {
  readonly id: string;
  readonly nameKey: string;
}

export interface ExternalRecipientOverviewDTO {
  readonly items: ExternalRecipientOverviewItemDTO[];
  readonly labels: LabelDTO;
}

export interface DataLocationOverviewItem {
  readonly id: string;
  readonly title: string;
  readonly createdAt: string;
  readonly externalRecipientId: string | null;
}

export interface DataLocationOverviewDTO {
  readonly items: DataLocationOverviewItem[];
}

export interface ProcessOverviewItemDTO {
  readonly id: string;
  readonly title: string;
  readonly status?: string;
  readonly version?: number;
  readonly creatorUID?: string;
  readonly createdAt?: string;
  readonly department?: string;
  readonly dueAt?: string | null;
}

export interface ProcessOverviewDTO {
  readonly items: ProcessOverviewItemDTO[];
}

export type ExternalRecipientPayload = Omit<
  Partial<ExternalRecipientDocumentFieldsDTO>,
  "approvedAt" | "approvedBy" | "id"
>;

export async function getExternalRecipientApi(externalRecipientId: string): Promise<ExternalRecipientDocumentDTO> {
  const axiosResponse = await axiosInstance.get<void, AxiosResponse<ExternalRecipientDocumentDTO>>(
    `/external-recipients/${externalRecipientId}`
  );
  return axiosResponse.data;
}

export async function createExternalRecipientApi(
  payload: ExternalRecipientPayload
): Promise<{ id: string; response: AxiosResponse }> {
  const axiosResponse = await axiosInstance.post(`/external-recipients`, payload, {
    params: { wait: true }
  });
  return { id: axiosResponse.headers["x-resource-id"], response: axiosResponse };
}

export async function deleteExternalRecipientApi(externalRecipientId: string): Promise<void> {
  await axiosInstance.delete(`/external-recipients/${externalRecipientId}`);
}

export async function updateExternalRecipientApi(
  externalRecipientId: string,
  payload: ExternalRecipientPayload
): Promise<void> {
  await axiosInstance.patch(`/external-recipients/${externalRecipientId}`, payload);
  return;
}

export async function approveExternalRecipientApi(externalRecipientId: string): Promise<void> {
  await axiosInstance.patch(`/external-recipients/approve/${externalRecipientId}`);
  return;
}

export async function unApproveExternalRecipientApi(externalRecipientId: string): Promise<void> {
  await axiosInstance.patch(`/external-recipients/unapprove/${externalRecipientId}`);
  return;
}

export interface DataLocationDTO {
  readonly id: string;
  readonly nameKey: string;
  readonly externalRecipientId: string | null;
  readonly externalRecipientName: string | null;
  readonly creatorUID: string;
  readonly updaterUID: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export async function getDataLocationsApi(
  options: {
    readonly abortController?: AbortController;
  } = {}
): Promise<{ readonly dataLocations: DataLocationDTO[] }> {
  const axiosResponse = await axiosInstance.get<{ readonly dataLocations: DataLocationDTO[] }>(`/data-locations`, {
    signal: options.abortController?.signal
  });
  return axiosResponse.data;
}

export async function getDataLocationsOverviewApi(
  options: {
    readonly abortController?: AbortController;
  } = {}
): Promise<DataLocationOverviewDTO> {
  const axiosResponse = await axiosInstance.get<DataLocationOverviewDTO>(`/data-locations/overview`, {
    signal: options.abortController?.signal
  });
  return axiosResponse.data;
}

export interface CreateDataLocationDTO {
  readonly nameKey: string;
  readonly externalRecipientId?: string;
}

export async function createDataLocationApi(
  payload: CreateDataLocationDTO,
  config?: AxiosRequestConfig
): Promise<string> {
  const axiosResponse = await axiosInstance.post(`/data-locations`, payload, config);
  return axiosResponse.headers["x-resource-id"] || "";
}

export async function renameDataLocationApi(dataLocationId: string, newName: string): Promise<void> {
  const axiosResponse = await axiosInstance.patch(`/data-locations/${dataLocationId}`, {
    name: newName
  });
  return axiosResponse.data;
}

export async function deleteDataLocationApi(dataLocationId: string): Promise<void> {
  const axiosResponse = await axiosInstance.delete(`/data-locations/${dataLocationId}`);
  return axiosResponse.data;
}

export interface DataLocationMultiMergeDTO {
  readonly toMergeIDs: string[];
  readonly nameKey: string;
  readonly externalRecipientId: string | null;
}

export async function mergeDataLocationApi(
  payload: DataLocationMultiMergeDTO,
  params?: { readonly wait: boolean }
): Promise<string | null> {
  const axiosResponse = await axiosInstance.post(`/data-locations/merges`, payload, {
    params
  });
  return axiosResponse.headers["x-resource-id"] || null;
}

export interface MergeExternalRecipientsDTO {
  readonly toMergeERIDs: string[];
  readonly mergeIntoERId: string | null;
}

export async function mergeExternalRecipientApi(
  payload: MergeExternalRecipientsDTO,
  params?: { readonly wait: boolean }
): Promise<string | null> {
  const axiosResponse = await axiosInstance.post(`/external-recipients/merge`, payload, {
    params
  });
  return axiosResponse.headers["x-resource-id"] || null;
}
export interface DataLocationMultiLinkDTO {
  readonly toLinkIDs: string[];
  readonly externalRecipientId: string | null;
}

export async function linkDataLocationApi(
  payload: DataLocationMultiLinkDTO,
  params?: { readonly wait: boolean }
): Promise<void> {
  const axiosResponse = await axiosInstance.post(`/data-locations/links`, payload, {
    params
  });
  return axiosResponse.data;
}

export async function getExternalRecipientsApi(): Promise<ExternalRecipientsDTO> {
  const axiosResponse = await axiosInstance.get<ExternalRecipientsDTO>("/external-recipients");
  return axiosResponse.data;
}

type PaginatedExternalRecipients = {
  readonly items: ExternalRecipientOverviewItemDTO[];
  readonly totalCount: number;
  readonly cursors: {
    readonly next: string | null;
    readonly previous: string | null;
  };
};

export interface PaginatedExternalRecipientsApiFilter {
  readonly title?: {
    readonly contains?: string;
    readonly operator?: "OR" | "AND";
  };
  readonly id?: {
    readonly in?: string[];
    readonly notIn?: string[];
    readonly operator?: "OR" | "AND";
  };
  readonly allOrgUnitIds?: {
    readonly in?: string[];
    readonly operator?: "OR" | "AND";
  };
  readonly labels?: {
    readonly in?: string[];
    readonly operator?: "OR" | "AND";
  };
  readonly serviceType?: {
    readonly in?: string[];
    readonly operator?: "OR" | "AND";
  };
}

export function usePaginatedExternalRecipientsApi({
  limit,
  sort,
  filter
}: {
  limit?: number;
  sort?: Record<string, any>[];
  filter?: PaginatedExternalRecipientsApiFilter;
}) {
  const getKey = useCallback(
    (pageIndex: number, previousPageData: PaginatedExternalRecipients) => {
      if (limit === 0) {
        return null;
      }
      if (previousPageData && !previousPageData.cursors.next) {
        return null;
      }
      const body: Record<string, any> = {
        limit: limit || 10
      };
      if (filter) {
        body["filter"] = filter;
      }
      if (sort) {
        body["sort"] = sort;
      }
      if (previousPageData?.cursors.next) {
        body.cursor = previousPageData.cursors.next;
      }
      return body;
    },
    [limit, sort, filter]
  );

  const paginated = useSWRInfinite(
    getKey,
    body =>
      axiosInstance
        .post<PaginatedExternalRecipients>("/external-recipients/overview/paginated", body)
        .then(res => res.data),
    {
      keepPreviousData: true
    }
  );

  const hasMore = useMemo(() => {
    return paginated.data && paginated.data[paginated.data.length - 1].cursors.next !== null;
  }, [paginated.data]);

  const loadMore = useCallback(() => {
    if (hasMore) {
      // this just triggers a load of the next page
      paginated.setSize(paginated.size + 1);
    }
  }, [paginated, hasMore]);

  const items = useMemo(() => {
    return paginated.data?.flatMap(page => page.items) || [];
  }, [paginated.data]);

  const totalCount = useMemo(() => {
    return paginated.data && paginated.data[paginated.data.length - 1]?.totalCount;
  }, [paginated.data]);

  return {
    items,
    loadMore,
    hasMore,
    totalCount,
    isValidating: paginated.isValidating
  };
}

export async function getExternalRecipientsStatsApi(labelId?: string, orgUnitId?: string) {
  const response = await axiosInstance.get(`/external-recipients/stats`, {
    params: { labelId: labelId, orgUnitId: orgUnitId }
  });
  return response.data.stats || [];
}

export async function getNumberUnseenExternalRecipientsOfUserApi(
  httpOptions: {
    abortController?: AbortController;
  } = {}
): Promise<number> {
  const response = await axiosInstance.get("/external-recipients/unseen", {
    signal: httpOptions.abortController?.signal
  });
  return response.data.unseenCount;
}

export async function getNumberUnseenDataLocationsOfUserApi(
  httpOptions: {
    abortController?: AbortController;
  } = {}
): Promise<number> {
  const response = await axiosInstance.get("/data-locations/unseen", { signal: httpOptions.abortController?.signal });
  return response.data.unseenCount;
}

export const getERExports = async (params: { readonly erIDs: string[] }): Promise<Blob> => {
  const response = await axiosInstance.post(
    `/external-recipients/overview/exports`,
    {
      erIDs: params.erIDs
    },
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      },
      responseType: "blob",
      timeout: 300000 // 5 minutes
    }
  );
  return new Blob([response.data]);
};

export const importERJob = async (params: { readonly base64Excel: string }): Promise<{ readonly runId: string }> => {
  const response = await axiosInstance.post<{ readonly runId: string }>(`external-recipients/imports`, {
    base64Excel: params.base64Excel
  });
  return response.data;
};

export const getERIds = async (input: { filter?: PaginatedExternalRecipientsApiFilter }): Promise<{ ids: string }> => {
  const response = await axiosInstance.post(`/external-recipients/overview/ids`, { filter: input.filter });
  return response.data;
};
