import { mode1 as designColorTokens } from "./designColorTokens.json" assert { type: "object" };
import { Color, PaletteColor, PaletteOptions } from "@mui/material";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    /**
     * @deprecated color should not be accessible from palette
     */
    green: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    blue: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    red: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    yellow: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    orange: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    deepPurple: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    blueGrey: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    indigo: Color;
    /**
     * @deprecated this is old color scheme, should not be used anymore.
     */
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
  }

  interface PaletteOptions {
    /**
     * @deprecated color should not be accessible from palette
     */
    green: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    blue: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    red: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    yellow: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    orange: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    deepPurple: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    blueGrey: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    indigo: Color;
    /**
     * @deprecated this is old color scheme, should not be used anymore.
     */
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    brick: PaletteColor;

    /**
     * @deprecated color should not be accessible from palette
     */
    green: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    blue: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    red: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    yellow: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    orange: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    deepPurple: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    blueGrey: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    indigo: Color;

    /**
     * @deprecated this is old color scheme, should not be used anymore.
     */
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
  }

  interface PaletteOptions {
    brick: PaletteColor;

    /**
     * @deprecated color should not be accessible from palette
     */
    green: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    blue: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    red: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    yellow: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    orange: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    deepPurple: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    blueGrey: Color;
    /**
     * @deprecated color should not be accessible from palette
     */
    indigo: Color;
    /**
     * @deprecated this is old color scheme, should not be used anymore.
     */
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    brick: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    brick: true;
  }
}

const common = {
  black: "#000000de",
  white: "#ffffff"
};

const palette: PaletteOptions = {
  common,
  primary: {
    light: designColorTokens.indigo400,
    main: designColorTokens.indigo500,
    dark: designColorTokens.indigo600,
    contrastText: common.white
  },
  action: {
    active: designColorTokens.grey500,
    hover: designColorTokens.grey100,
    selected: designColorTokens.grey200,
    focus: designColorTokens.grey300,
    disabled: designColorTokens.grey400,
    disabledBackground: designColorTokens.grey200
  },
  secondary: {
    light: designColorTokens.indigo50,
    main: designColorTokens.indigo50,
    dark: designColorTokens.indigo200,
    contrastText: designColorTokens.indigo700
  },
  brick: {
    light: designColorTokens.grey400,
    main: designColorTokens.grey100,
    dark: designColorTokens.grey200,
    contrastText: common.black
  },
  info: {
    light: designColorTokens.blue300,
    main: designColorTokens.blue500,
    dark: designColorTokens.blue800,
    contrastText: common.white
  },
  error: {
    light: designColorTokens.red400,
    main: designColorTokens.red600,
    dark: designColorTokens.red800,
    contrastText: common.white
  },
  warning: {
    light: designColorTokens.orange400,
    main: designColorTokens.orange700,
    dark: designColorTokens.orange900,
    contrastText: common.white
  },
  success: {
    light: designColorTokens.green300,
    main: designColorTokens.green500,
    dark: designColorTokens.green700,
    contrastText: common.white
  },
  text: {
    primary: designColorTokens.grey800,
    secondary: designColorTokens.grey500,
    disabled: designColorTokens.grey400
  },
  background: {
    default: common.white,
    paper: common.white
  },
  alert: {
    warning: designColorTokens.deepOrange500,
    warningBackground: designColorTokens.deepOrange50,
    error: designColorTokens.red600,
    errorBackground: designColorTokens.red50,
    info: designColorTokens.blue500,
    infoBackground: designColorTokens.blueA800,
    success: designColorTokens.green500,
    successBackground: designColorTokens.greenA300
  },
  grey: {
    50: designColorTokens.grey50,
    100: designColorTokens.grey100,
    200: designColorTokens.grey200,
    300: designColorTokens.grey300,
    400: designColorTokens.grey400,
    500: designColorTokens.grey500,
    600: designColorTokens.grey600,
    700: designColorTokens.grey700,
    800: designColorTokens.grey800,
    900: designColorTokens.grey900
  },
  green: {
    50: designColorTokens.green50,
    100: designColorTokens.green100,
    200: designColorTokens.green200,
    300: designColorTokens.green300,
    400: designColorTokens.green400,
    500: designColorTokens.green500,
    600: designColorTokens.green600,
    700: designColorTokens.green700,
    800: designColorTokens.green800,
    900: designColorTokens.green900,
    A100: designColorTokens.greenA100,
    A200: designColorTokens.greenA200,
    A400: designColorTokens.greenA400,
    A700: designColorTokens.greenA700
  },
  blue: {
    50: designColorTokens.blue50,
    100: designColorTokens.blue100,
    200: designColorTokens.blue200,
    300: designColorTokens.blue300,
    400: designColorTokens.blue400,
    500: designColorTokens.blue500,
    600: designColorTokens.blue600,
    700: designColorTokens.blue700,
    800: designColorTokens.blue800,
    900: designColorTokens.blue900,
    A100: designColorTokens.blueA100,
    A200: designColorTokens.blueA200,
    A400: designColorTokens.blueA400,
    A700: designColorTokens.blueA700
  },
  red: {
    50: designColorTokens.red50,
    100: designColorTokens.red100,
    200: designColorTokens.red200,
    300: designColorTokens.red300,
    400: designColorTokens.red400,
    500: designColorTokens.red500,
    600: designColorTokens.red600,
    700: designColorTokens.red700,
    800: designColorTokens.red800,
    900: designColorTokens.red900,
    A100: designColorTokens.redA100,
    A200: designColorTokens.redA200,
    A400: designColorTokens.redA400,
    A700: designColorTokens.redA700
  },
  yellow: {
    50: designColorTokens.yellow50,
    100: designColorTokens.yellow100,
    200: designColorTokens.yellow200,
    300: designColorTokens.yellow300,
    400: designColorTokens.yellow400,
    500: designColorTokens.yellow500,
    600: designColorTokens.yellow600,
    700: designColorTokens.yellow700,
    800: designColorTokens.yellow800,
    900: designColorTokens.yellow900,
    A100: designColorTokens.yellowA100,
    A200: designColorTokens.yellowA200,
    A400: designColorTokens.yellowA400,
    A700: designColorTokens.yellowA700
  },
  orange: {
    50: designColorTokens.orange50,
    100: designColorTokens.orange100,
    200: designColorTokens.orange200,
    300: designColorTokens.orange300,
    400: designColorTokens.orange400,
    500: designColorTokens.orange500,
    600: designColorTokens.orange600,
    700: designColorTokens.orange700,
    800: designColorTokens.orange800,
    900: designColorTokens.orange900,
    A100: designColorTokens.orangeA100,
    A200: designColorTokens.orangeA200,
    A400: designColorTokens.orangeA400,
    A700: designColorTokens.orangeA700
  },
  deepPurple: {
    50: designColorTokens.deepPurple50,
    100: designColorTokens.deepPurple100,
    200: designColorTokens.deepPurple200,
    300: designColorTokens.deepPurple300,
    400: designColorTokens.deepPurple400,
    500: designColorTokens.deepPurple500,
    600: designColorTokens.deepPurple600,
    700: designColorTokens.deepPurple700,
    800: designColorTokens.deepPurple800,
    900: designColorTokens.deepPurple900,
    A100: designColorTokens.deepPurpleA100,
    A200: designColorTokens.deepPurpleA200,
    A400: designColorTokens.deepPurpleA400,
    A700: designColorTokens.deepPurpleA700
  },
  indigo: {
    50: designColorTokens.indigo50,
    100: designColorTokens.indigo100,
    200: designColorTokens.indigo200,
    300: designColorTokens.indigo300,
    400: designColorTokens.indigo400,
    500: designColorTokens.indigo500,
    600: designColorTokens.indigo600,
    700: designColorTokens.indigo700,
    800: designColorTokens.indigo800,
    900: designColorTokens.indigo900,
    A100: designColorTokens.indigoA100,
    A200: designColorTokens.indigoA200,
    A400: designColorTokens.indigoA400,
    A700: designColorTokens.indigoA700
  },
  blueGrey: {
    50: designColorTokens.blueGrey50,
    100: designColorTokens.blueGrey100,
    200: designColorTokens.blueGrey200,
    300: designColorTokens.blueGrey300,
    400: designColorTokens.blueGrey400,
    500: designColorTokens.blueGrey500,
    600: designColorTokens.blueGrey600,
    700: designColorTokens.blueGrey700,
    800: designColorTokens.blueGrey800,
    900: designColorTokens.blueGrey900,
    A100: designColorTokens.blueGreyA100,
    A200: designColorTokens.blueGreyA200,
    A400: designColorTokens.blueGreyA400,
    A700: designColorTokens.blueGreyA700
  }
};

export default palette;
