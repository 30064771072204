import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { OverviewRowBrickProps } from "components/Overview/controls/OverviewRow";
import { useCallback } from "react";
import stopEvent from "tool/stopEvent";

const OverviewBrickRemove = ({ item, hovered, onDelete }: OverviewRowBrickProps) => {
  const onClick = useCallback(
    event => {
      stopEvent(event);
      onDelete?.();
    },
    [onDelete]
  );

  if (!hovered || item.disableActions?.some(({ action }) => action === "remove")) {
    return <></>;
  }
  return (
    <IconButton onClick={onClick} size="small">
      <Delete />
    </IconButton>
  );
};

export default OverviewBrickRemove;
