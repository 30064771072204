import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BrowserNotSupportedOutlinedIcon from "@mui/icons-material/BrowserNotSupportedOutlined";
import { SuggestTitlesMetaview } from "./AIIntegrationGeneratePA";
import { useMetaView } from "../../app/contexts/meta-view-context";
import { SuggestFieldMetaview } from "./AIIntegrationSuggestFieldMetaview";
import { AutoAwesomeRounded } from "@mui/icons-material";
import { AIStepButton } from "./AIStepButton";

export const AIIntegrationMetaview = () => {
  const { t } = useTranslation("ai-metaview");
  const { aiIntegrationMode } = useMetaView();

  const [aiIntegrationStarted, setAiIntegrationStarted] = useState(false);
  useEffect(() => {
    setAiIntegrationStarted(false);
  }, [aiIntegrationMode]);

  const onAIIntegrationStart = useCallback(() => {
    setAiIntegrationStarted(true);
  }, []);

  const onAIIntegrationReset = useCallback(() => {
    setAiIntegrationStarted(false);
  }, []);

  if (aiIntegrationStarted && aiIntegrationMode === "suggest-process") {
    return <SuggestTitlesMetaview onDone={onAIIntegrationReset} />;
  }

  if (aiIntegrationMode === "suggest-field") {
    return <SuggestFieldMetaview onDone={onAIIntegrationReset} />;
  }

  return (
    <Box>
      <Typography variant="subtitle1" mb={2}>
        {t("suggestion")}
      </Typography>
      {!aiIntegrationMode ? (
        <SuggestionBox icon="disabled" title={t("no-use-case")} />
      ) : (
        <AIStepButton onClick={onAIIntegrationStart} startIcon={<AutoAwesomeRounded color="primary" />}>
          <Typography variant="body1" textTransform="none" textAlign="start" fontWeight={500}>
            {t(aiIntegrationMode || "")}
          </Typography>
        </AIStepButton>
      )}
    </Box>
  );
};

const SuggestionBox = (props: {
  readonly icon: "awesome" | "disabled";
  readonly title: string;
  readonly onClick?: () => void;
}) => {
  const icon =
    props.icon === "awesome" ? (
      <AutoAwesomeRounded color="primary" />
    ) : props.icon === "disabled" ? (
      <BrowserNotSupportedOutlinedIcon />
    ) : (
      <AutoAwesomeRounded color="primary" />
    );

  return (
    <Button fullWidth={true} onClick={props.onClick} disabled={!props.onClick} variant="text">
      <Box width="100%" display="flex" gap={1.5} justifyContent="flex-start">
        {icon}
        <Box flex={1}>
          <Typography variant="body2" textTransform="none" textAlign="start">
            {props.title}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};
